import { CountModel } from 'api-models';
import { ImpressionDailyQuery, ImpressionsTotalQuery } from 'api-queries';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import { ByAssignmentTypeOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByAssignmentType/types';
import { OverTimeOutput } from 'components/DataLibrary/Graphs/GraphComponents/OverTime/types';
import { TotalOutput } from 'components/DataLibrary/Graphs/GraphComponents/Total/types';
import { INSTAGRAM_POST, INSTAGRAM_REEL, INSTAGRAM_STORY, TIKTOK_VIDEO } from 'constants/data-library';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

export const getImpressionTotal = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<ImpressionsTotalQuery>(filter);
	const { result, error } = manager.showImpressionsTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		value: result.attributes.count,
		currency: undefined,
	};

	return { result: output };
};

export const getImpressionTotalByPlatforms = (
	manager: StatisticsManager,
	filter: DashboardFilters,
): { result: ByAssignmentTypeOutput | undefined; error?: Error } => {
	const results = new Map<string, CountModel | undefined>();
	const errors = new Map<string, Error | undefined>();

	for (const type of [INSTAGRAM_POST, INSTAGRAM_STORY, INSTAGRAM_REEL, TIKTOK_VIDEO]) {
		const qb = convertFilterToQueryBuilder<ImpressionsTotalQuery>(filter).withFilter('assignmentTypes', type);
		const { result, error } = manager.showImpressionsTotal(qb);

		results.set(type, result);
		errors.set(type, error);
	}

	for (const [type, error] of errors) {
		if (results.get(type) === undefined || error) {
			return { result: undefined, error };
		}
	}

	const output: ByAssignmentTypeOutput = {
		total: 0,
		types: new Map<string, { label: string; value: number }>(),
	};

	for (const [type, result] of results) {
		output.total += result?.attributes.count || 0;
		output.types.set(type, {
			label: type,
			value: result?.attributes.count || 0,
		});
	}

	return { result: output };
};

export const getImpressionOverTime = (manager: StatisticsManager, filter: DashboardFilters): { result: OverTimeOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<ImpressionDailyQuery>(filter);
	const { result, error } = manager.listImpressionsDaily(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = result.map((node) => {
		return {
			date: node.attributes.date,
			count: node.attributes.count,
		};
	});

	return { result: output };
};
