/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { CreatePreSignUrlPayload } from 'api-payloads';
import { UserUploadPresignUrlResponse, UserUploadResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import UserUploadApiClientInterface from './UserUploadApiClientInterface';

@injectable()
export default class UserUploadApiClient implements UserUploadApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Create pre sign url
	 */
	async createPreSignUrl(payload: CreatePreSignUrlPayload, params: { include?: string }): Promise<UserUploadPresignUrlResponse> {
		const response = await this.client.post<UserUploadPresignUrlResponse>(`/user-uploads/presign-urls`, payload, { params });
		return response.data;
	}

	/**
	 * View user upload
	 */
	async view(id: string, params: { include?: string }): Promise<UserUploadResponse> {
		const response = await this.client.get<UserUploadResponse>(`/user-uploads/${id}`, { params });
		return response.data;
	}

	/**
	 * Get presign URL.
	 *
	 * Use this nice method to get a presign URL.
	 */
	async createGetPresignUrl(id: string, params: { include?: string }): Promise<UserUploadPresignUrlResponse> {
		const response = await this.client.post<UserUploadPresignUrlResponse>(`/user-uploads/${id}/presign-urls`, undefined, { params });
		return response.data;
	}
}
