import { inject, injectable } from 'inversify';

import { ToolUrlOgMetaResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ResourceManager from 'utils/Repository/ResourceManager';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';

import type ToolsApiClientInterface from 'services/ApiClient/ToolsApiClientInterface';

@injectable()
class ToolsManager extends ApiManager {
	private readonly client: ToolsApiClientInterface;

	constructor(@inject(ApiCacheManager) cacheManager: ApiCacheManager, @inject(ApiClientService.ToolsApiClientInterface) client: ToolsApiClientInterface) {
		super(cacheManager, new ResourceManager());
		this.client = client;
	}

	public getMeta(url: string | null) {
		const key = `getMeta_${url}`;
		const fetcher = () => (url === null || url === '' ? null : this.client.ogMeta({ url }));

		return this.swr<ToolUrlOgMetaResponse>(key, fetcher);
	}
}

export default ToolsManager;
