import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgFlipHorizontal(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.25 9.39915L4.55 6.21078C4.84 5.92974 5.32 5.92974 5.61 6.21078C5.76 6.35615 5.83 6.54028 5.83 6.72441C5.83 6.90854 5.76 7.09267 5.61 7.23804L3.51 9.27317L4.46849 9.27317C4.46898 9.27317 4.46948 9.27317 4.46997 9.27317L16.49 9.27317L14.39 7.23804C14.1 6.957 14.1 6.49182 14.39 6.21078C14.68 5.92974 15.16 5.92974 15.45 6.21078L18.75 9.40884C18.82 9.47668 18.88 9.58328 18.93 9.69957C18.97 9.79649 19 9.8934 19 10L19 10.0194C19 10.1163 18.98 10.2035 18.94 10.2907C18.89 10.407 18.83 10.5136 18.75 10.5912L15.45 13.7892C15.16 14.0703 14.68 14.0703 14.39 13.7892C14.24 13.6439 14.17 13.4597 14.17 13.2756C14.17 13.0915 14.24 12.9073 14.39 12.762L16.49 10.7268L15.53 10.7268L4.46997 10.7268L3.51 10.7268L5.61 12.762C5.9 13.043 5.9 13.5082 5.61 13.7892C5.32 14.0703 4.84 14.0703 4.55 13.7892L1.25 10.5815C1.18 10.5136 1.12 10.407 1.07 10.2907C1.03 10.1938 1 10.0969 1 9.99031L1 9.97093C1 9.87402 1.02 9.7868 1.06 9.69958C1.11 9.58328 1.17 9.47668 1.25 9.39915Z'
				fill='#333333'
			/>
		</svg>
	);
}
