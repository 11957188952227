import styled, { DefaultThemeV2 } from 'styled-components';

import colors from 'styles/theme/colors';
import { devices } from 'styles/variables/media-queries';

type T = { theme: DefaultThemeV2 };

const ClientCard = styled.div`
	display: flex;
	width: 100%;
	height: fit-content;
	height: 250px;
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 1.5rem;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	cursor: pointer;
	opacity: 0.7;
	transition: opacity 0.2s;
	&:hover {
		opacity: 1;
	}
	@media screen and (${devices.lg}) {
		width: calc((100% - 16px) / 3);
	}
`;

const ClientCardTop = styled.div`
	width: 100%;
	margin-top: -55px;
`;

const BrandContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`;

const Brand = styled.div<{ logo?: string }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 50px;
	font-size: 0.625rem;
	border-radius: ${(props: T) => props.theme.radius.default};
	background-image: url(${(p) => p.logo});
	background-color: ${(p) => (p.logo ? 'transparent' : colors.ash)};
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	cursor: pointer;
	&:hover {
		border: 2px solid ${colors.skyBlue};
	}
`;

const Content = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
`;

const IconContainer = styled.div`
	font-size: 1rem;
	text-transform: uppercase;
	padding-top: 3px;
`;

const CalculateRest = styled.div`
	display: flex;
	align-items: center;
`;

const ContainerWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

const ClientLogo = styled.div<{ logo?: string }>`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 80px;
	width: 80px;
	font-size: 0.625rem;
	border-radius: ${(props: T) => props.theme.radius.default};
	background-image: url(${(p) => p.logo});
	background-color: ${(p) => (p.logo ? 'transparent' : colors.ash)};
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	margin-bottom: 8px;
	.icon {
		margin-bottom: 16px;
	}
	span {
		font-size: 0.75rem;
	}
`;

const ClientName = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
`;

const AddIcon = styled.div`
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${colors.ash};
	border-radius: ${(props: T) => props.theme.radius.small};
`;

const AddSection = styled.div`
	cursor: pointer;
	display: flex;
	gap: 8px;
	align-items: center;
	p {
		margin-bottom: 0;
		color: ${colors.smoke};
		font-weight: 600;
	}
`;

export default {
	AddSection,
	AddIcon,
	ClientName,
	ClientLogo,
	ClientCard,
	ClientCardTop,
	BrandContainer,
	Brand,
	Content,
	IconContainer,
	CalculateRest,
	ContainerWrapper,
};
