import { useEffect } from 'react';

import CMContainer from 'components/ContentManagement/CMContainer';
import PageHeader from 'components/PageHeader';

import Styled from './ContentManagement.style';

const ContentManagement = () => {
	useEffect(() => {
		document.body.classList.add('content-management');
		document.title = `Content management | Collabs`;

		return () => {
			document.body.classList.remove('content-management');
		};
	}, []);

	return (
		<Styled.Wrapper>
			<PageHeader headline='Content Management' />
			<CMContainer />
		</Styled.Wrapper>
	);
};

export default ContentManagement;
