import { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ModalHeader, ModalBody, ModalFooter } from './Modal.style';
import ModalPortal from './ModalPortal';
import { IModalProps } from './types';

/**
 * Modal
 * @param {IModalProps} props
 * @returns {JSX.Element}
 */
const Modal = (props: IModalProps) => {
	// För att hantera body-scroll baserat på modalens öppna tillstånd
	useEffect(() => {
		if (props.open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [props.open]);

	useEffect(() => {
		return () => {
			document.body.classList.remove('disable-scroll');
			document.body.style.overflow = 'unset';
		};
	}, []);

	return (
		<CSSTransition
			in={props.open} // Kontrollerar om modalen ska visas eller inte
			timeout={200} // Matcha timeout med CSS-animationstiden
			classNames='modal' // Klass för animation
			unmountOnExit // Detta gör att modalen tas bort från DOM när den är stängd
		>
			<ModalPortal
				open={props.open}
				handleClose={props.handleClose}
				onShown={props.onShown}
				size={props.size}
				className={props.className}
				role={props.role}
				onAfterClose={props.onAfterClose}
				domNode={props.domNode}
				dataTestId={props.dataTestId}
			>
				{props.children}
			</ModalPortal>
		</CSSTransition>
	);
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
