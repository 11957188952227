import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgTrashBin(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M17.3196 2.90145H13.7674V0.850425C13.7674 0.430215 13.4171 0 12.7467 0H7.24328C6.57286 0 6.22264 0.430215 6.22264 0.850425V2.89145H2.69043C2.31019 2.89145 2 3.23162 2 3.64182C2 4.05203 2.31019 4.3922 2.69043 4.3922H3.00063V18.6593C3.00063 19.3997 3.57098 20 4.26141 20H15.7386C16.439 20 16.9994 19.3997 16.9994 18.6593V4.4022H17.3096C17.6898 4.4022 18 4.06203 18 3.65183C18 3.24162 17.6898 2.90145 17.3096 2.90145H17.3196ZM7.73358 1.50075H12.2664V2.90145H7.73358V1.50075ZM15.5885 18.5093H4.41151V4.4022H15.5985V18.5093H15.5885Z'
				fill='#333333'
			/>
			<path d='M7 6.5C6.72 6.5 6.5 6.72 6.5 7V16C6.5 16.28 6.72 16.5 7 16.5C7.28 16.5 7.5 16.28 7.5 16V7C7.5 6.72 7.28 6.5 7 6.5Z' fill='#333333' />
			<path d='M10 6.5C9.72 6.5 9.5 6.72 9.5 7V16C9.5 16.28 9.72 16.5 10 16.5C10.28 16.5 10.5 16.28 10.5 16V7C10.5 6.72 10.28 6.5 10 6.5Z' fill='#333333' />
			<path
				d='M12.5 7V16C12.5 16.28 12.72 16.5 13 16.5C13.28 16.5 13.5 16.28 13.5 16V7C13.5 6.72 13.28 6.5 13 6.5C12.72 6.5 12.5 6.72 12.5 7Z'
				fill='#333333'
			/>
		</svg>
	);
}
