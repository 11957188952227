import Skeleton from 'react-loading-skeleton';

import { ActiveCampaign } from 'components/InfluencerDashboard/utils';
import { isMobile } from 'shared/utils/navigator';

import CampaignItem from './CampaignItem';
import Styled from './Campaigns.style';

type CampaignProps = {
	campaigns: ActiveCampaign[];
	displaySkeletonLoader: boolean;
};
/**
 * @returns {JSX.Element}
 */
const Campaigns = ({ campaigns, displaySkeletonLoader }: CampaignProps): JSX.Element => {
	return (
		<Styled.Wrapper>
			{isMobile() ? (
				<Styled.Column>
					{campaigns.map((campaign) =>
						displaySkeletonLoader ? <Skeleton height='500px' width='600px' key={campaign.id} /> : <CampaignItem key={campaign.id} campaign={campaign} />,
					)}
				</Styled.Column>
			) : (
				<>
					<Styled.Column>
						{campaigns
							.filter((_, index) => index % 2 === 0)
							.map((campaign) =>
								displaySkeletonLoader ? <Skeleton height='500px' width='600px' key={campaign.id} /> : <CampaignItem key={campaign.id} campaign={campaign} />,
							)}
					</Styled.Column>
					<Styled.Column>
						{campaigns
							.filter((_, index) => index % 2 !== 0)
							.map((campaign) =>
								displaySkeletonLoader ? <Skeleton height='500px' width='600px' key={campaign.id} /> : <CampaignItem key={campaign.id} campaign={campaign} />,
							)}
					</Styled.Column>
				</>
			)}
		</Styled.Wrapper>
	);
};

export default Campaigns;
