export const FakeData = {
	total: 128291756,
	ages: [
		{
			label: '45-54',
			value: 19585932,
		},
		{
			label: '13-17',
			value: 19041278,
		},
		{
			label: '25-34',
			value: 18688744,
		},
		{
			label: '65+',
			value: 18391876,
		},
		{
			label: '55-64',
			value: 17969372,
		},
		{
			label: '35-44',
			value: 17616082,
		},
		{
			label: '18-24',
			value: 16998472,
		},
	],
};
