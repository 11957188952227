import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgFolder(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M19.05 2.69H7.64L6.25 1.28C6.07 1.1 5.83 1 5.58 1H0.95C0.42 1 0 1.43 0 1.96V18.04C0 18.57 0.42 19 0.95 19H19.05C19.58 19 20 18.57 20 18.05V3.65C20 3.12 19.58 2.69 19.05 2.69ZM18.5 17.5H10.81C10.81 17.5 10.77 17.49 10.74 17.49H1.49V6.63H16.34C16.62 6.63 16.84 6.41 16.84 6.13C16.84 5.85 16.62 5.63 16.34 5.63H1.49V2.51H5.35L6.74 3.92C6.92 4.1 7.16 4.2 7.41 4.2H18.5V7.99C18.5 7.99 18.5 8.02 18.5 8.03V17.49V17.5Z'
				fill='#333333'
			/>
		</svg>
	);
}
