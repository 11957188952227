import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';

/**
 * @returns {JSX.Element}
 */
const ReviewStatus = (): JSX.Element => {
	return (
		<Styled.ConnnectedMesssage>
			<span>The assignment is still under review.</span>
		</Styled.ConnnectedMesssage>
	);
};

export default ReviewStatus;
