import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Model, Store } from 'json-api-models';

import { MercureNotification } from 'services/Mercure/types';

export type Notifications = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	notifications: any;
};

export const initialState: Notifications = {
	notifications: [],
};

const UserNotificationsSlice = createSlice({
	name: 'userNotifications',
	initialState,
	reducers: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setNotifications(state, action: PayloadAction<Model | any>) {
			state.notifications = action.payload;
			return state;
		},

		receiveNotification(state, action: PayloadAction<MercureNotification>) {
			if (!Array.isArray(state.notifications)) {
				state.notifications = [];
			}

			// We need to wrap this in a Model. We can stop doing this when we updated the NotificationsMenu component
			state.notifications.unshift(new Model(action.payload, new Store()));

			return state;
		},
	},
});

export const { setNotifications, receiveNotification } = UserNotificationsSlice.actions;
export default UserNotificationsSlice.reducer;
