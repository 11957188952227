import ListsService from 'services/Lists/Lists.service';

const useListData = () => {
	const getAllFoldersAndLists = () => {
		return ListsService.getFolders();
	};

	const getLists = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return ListsService.getLists().then((res: any) => {
			const result: { id: string; itemName: string }[] = res.data.map((data: { type: string; id: string; attributes: { name: string } }) => {
				return { id: data.id, itemName: data.attributes.name };
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			result.sort((a: any, b: any) => Number(b.id) - Number(a.id));
			return result;
		});
	};

	const addInfluencersToList = (listId: string, influencers: string[]) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return ListsService.addInfluencerToList(influencers, listId).then((res: any) => {
			return res;
		});
	};

	return {
		getAllFoldersAndLists,
		getLists,
		addInfluencersToList,
	};
};

export default useListData;
