import axios, { AxiosError } from 'axios';
import { Store } from 'json-api-models';
import { useEffect, useState } from 'react';

import AffiliateLinkGenerator from 'components/ContentManagement/Components/AffiliateLinkGenerator';
import TrackingLink from 'components/ContentManagement/Components/TrackingLink/TrackingLink';
import useContentManagementData from 'components/ContentManagement/hooks';
import { CampaignItemType } from 'components/ContentManagement/types';
import { getErrorMessageOnFetch, getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import { UserType } from 'reducers/UserReducers/types';
import toast from 'services/Toast';

import Styled from './Affiliate.style';

type AffiliateProps = {
	id: string;
	activeCampaign: CampaignItemType;
	user: UserType;
};

/**
 * Affiliate
 * @param {AffiliateProps} props
 * @returns {JSX.Element}
 */
const Affiliate = ({ id, activeCampaign, user }: AffiliateProps): JSX.Element => {
	const { getAffiliateLink, postAffiliateLink } = useContentManagementData();
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [errorMessages, setErrorMessages] = useState<Array<{ source: { message: string } }>>([]);
	const [affiliateLink, setAffiliateLink] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLinkVisible, setIsLinkVisible] = useState<boolean>(false);

	const errorMessageText = 'Cannot get the link, please contact the manager';
	const campaignInstagramOwner = activeCampaign.campaignInstagramOwners[0];

	const _getAffiliateLink = () => {
		setIsLoading(true);

		if (!campaignInstagramOwner?.links.affiliateLink) {
			setIsLoading(false);
			setErrorMessage(errorMessageText);
			return '';
		}

		getAffiliateLink(campaignInstagramOwner?.links.affiliateLink)
			.then((data) => {
				const model = new Store();
				model.sync(data);

				const links = model.findAll('link');
				if (links.length > 0) {
					setAffiliateLink(links[0].link);
					setIsLinkVisible(true);
				}
			})
			.catch((e) => {
				setErrorMessage(errorMessageText);
				setIsLinkVisible(false);
				console.error(e);
				toast.error(getErrorMessageOnFetch('The affiliate link'));
			})
			.finally(() => setIsLoading(false));
	};

	const onClickGenerate = (value: string) => {
		setErrorMessages([]);
		setIsLoading(true);
		setIsLinkVisible(false);

		postAffiliateLink(campaignInstagramOwner?.links.affiliateLink, value)
			.then((data) => {
				const model = new Store();
				model.sync(data);

				const links = model.findAll('link');
				if (links.length > 0) {
					setAffiliateLink(links[0].link);
					setIsLinkVisible(true);
				}
			})
			.catch((e: AxiosError) => {
				setIsLinkVisible(false);
				const errorData = e.response?.data as { errors: Array<{ source: { message: string } }> };
				if (axios.isAxiosError(e) && Array.isArray(errorData)) {
					errorData.forEach((error: { source: { message: string } }) => {
						setErrorMessages((prev) => [...prev, error]);
					});
				} else {
					toast.error(getErrorMessageOnPost('generating the link'));
				}
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		_getAffiliateLink();

		return () => {
			setErrorMessage('');
			setAffiliateLink('');
			setIsLoading(false);
		};
	}, [id]);

	return (
		<Styled.Wrapper>
			<Styled.HelpTextContainer>
				<h2>How affiliate campaigns work</h2>
				<p>
					1. <strong>Share your affiliate link</strong> with your followers through your social media channels. Whenever someone clicks on your link and makes a
					purchase, you&apos;ll earn a commission.
				</p>
				<p>
					2. <strong>Earn commission</strong> with our competitive commission rates. You&apos;ll earn a percentage of every sale made through your affiliate
					link. Track your earnings in real-time through your affiliate dashboard.
				</p>
			</Styled.HelpTextContainer>
			{!user.permissions.isInfluencer ? (
				<TrackingLink
					headline={`${user.permissions.isInfluencer ? 'Your' : 'The unique'} affiliate link`}
					url={affiliateLink}
					errorMessage={errorMessage}
					isLoading={isLoading}
				/>
			) : (
				<AffiliateLinkGenerator
					headline='Your affiliate link'
					url={affiliateLink}
					onClickSubmit={onClickGenerate}
					errorMessages={errorMessages}
					isLoading={isLoading}
					showLink={isLinkVisible}
				/>
			)}
		</Styled.Wrapper>
	);
};

export default Affiliate;
