import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import styled from 'styled-components';

import PageNotFound from 'components/ErrorPages/PageNotFound';
import LoadingSpinner from 'components/LoadingSpinner';
import EmptyLayout from 'layouts/empty';

import { lazyRetry } from './utils';

// @ts-ignore
const CreateCampaignContainer = lazy(() => lazyRetry(() => import('components/CreateCampaign/CreateCampaignContainer')));
// @ts-ignore
const CampaignDashboard = lazy(() => lazyRetry(() => import('views/Campaign/Dashboard/CampaignDashboard')));

export const LoadingContainer = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

/**
 * Campaign routes
 * Routes for campaign dashboard etc.
 * @returns {Array<RouteObject>}
 */
const routes: Array<RouteObject> = [
	{
		path: '',
		element: (
			<Suspense
				fallback={
					<LoadingContainer>
						<LoadingSpinner position='center' />
					</LoadingContainer>
				}
			>
				<EmptyLayout />
			</Suspense>
		),
		children: [
			{ index: true, element: <Navigate to='/dashboard' /> },
			{ path: 'create', element: <CreateCampaignContainer /> },
			{ path: ':campaignId', element: <CampaignDashboard /> },
			{
				path: ':campaignId/commissions/:commissionId/copy',
				element: <CampaignDashboard />,
			},
			{
				path: ':campaignId/commissions/:commissionId/add',
				element: <CampaignDashboard />,
			},
			{
				path: ':campaignId/commissions/:commissionId',
				element: <CampaignDashboard />,
			},
			{
				path: ':campaignId/assignments/:assignmentId/copy',
				element: <CampaignDashboard />,
			},
			{
				path: ':campaignId/assignments/:assignmentId/add',
				element: <CampaignDashboard />,
			},
			{
				path: ':campaignId/assignments/:assignmentId',
				element: <CampaignDashboard />,
			},
			{
				path: ':campaignId/products/:productId/copy',
				element: <CampaignDashboard />,
			},
			{
				path: ':campaignId/products/:productId/add',
				element: <CampaignDashboard />,
			},
			{
				path: ':campaignId/products/:productId',
				element: <CampaignDashboard />,
			},
			{
				path: ':campaignId/segments/:segmentId/add',
				element: <CampaignDashboard />,
			},
			{
				path: ':campaignId/segments/:segmentId',
				element: <CampaignDashboard />,
			},

			{ path: ':campaignId/edit', element: <CreateCampaignContainer /> },
		],
	},
	{ path: '*', element: <PageNotFound /> },
];

export default routes;
