import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgAssignmentPost(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.5 1.5V18.5H18.5V1.5H1.5ZM1 0C0.447715 0 0 0.447716 0 1V19C0 19.5523 0.447716 20 1 20H19C19.5523 20 20 19.5523 20 19V1C20 0.447715 19.5523 0 19 0H1Z'
				fill='#333333'
			/>
			<path
				d='M10.4 6.4C10.4 6.17909 10.2209 6 10 6C9.77909 6 9.6 6.17909 9.6 6.4V9.6H6.4C6.17909 9.6 6 9.77909 6 10C6 10.2209 6.17909 10.4 6.4 10.4H9.6V13.6C9.6 13.8209 9.77909 14 10 14C10.2209 14 10.4 13.8209 10.4 13.6V10.4H13.6C13.8209 10.4 14 10.2209 14 10C14 9.77909 13.8209 9.6 13.6 9.6H10.4V6.4Z'
				fill='#333333'
			/>
		</svg>
	);
}
