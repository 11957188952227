import classNames from 'classnames';
import { JsonApiDocument, Model, Store } from 'json-api-models';
import React, { useState, useEffect } from 'react';

import EmptyStateFolderList from 'assets/img/app/empty-state-folder-list.svg';
import { ButtonSecondary, DarkButton } from 'components/Button';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import ListsService from 'services/Lists';

import BackButton from './Components/BackButton';
import CloseButton from './Components/CloseButton';
import Styled from './DiscoveryDropdown.style';

const FolderListMenuItems = (props: {
	items: { folders: JsonApiDocument; lists: JsonApiDocument } | null;
	onCancel?: () => void;
	onClick: (url: string, listId: string, listName: string) => void;
	isCancellable?: boolean;
	onClose: () => void;
	updateFoldersAndLists?: () => void;
	isSaving: boolean;
	selectedItems: string[];
}) => {
	const [rootItems, setRootItems] = useState<{ folders: Model[]; lists: Model[] }>({ folders: [], lists: [] });
	const [subItems, setSubItems] = useState<{ folders: Model[]; lists: Model[] }>({ folders: [], lists: [] });
	const [isAddingNewList, setIsAddingNewList] = useState(false);
	const [newListName, setNewListName] = useState('');
	const [isAddingNewFolder, setIsAddingNewFolder] = useState(false);

	const [newFolderName, setNewFolderName] = useState('');
	const [openedFolder, setOpenedFolder] = useState<Model | null>(null);
	const [selectedList, setSelectedList] = useState<Model | null>(null);
	const [isSelectionEnabled, setIsSelectionEnabled] = useState(true);

	const setAddNewType = (type: 'folder' | 'list' | 'close') => {
		if (type === 'folder') {
			setNewListName('');
			setNewFolderName('');
			setIsAddingNewFolder(true);
			setIsAddingNewList(false);
		} else if (type === 'list') {
			setNewListName('');
			setNewFolderName('');
			setIsAddingNewFolder(false);
			setIsAddingNewList(true);
		} else if (type === 'close') {
			setNewListName('');
			setNewFolderName('');
			setIsAddingNewFolder(false);
			setIsAddingNewList(false);
		}
	};

	const addSelectionToListHandler = (url: string, listId: string, listName: string) => {
		props.onClick(url, listId, listName);
		setAddNewType('close');
		setSelectedList(null);
	};

	const folderChangedHandler = (targetFolder: Model) => {
		let newSubFolders: Model[] = [];
		let newSubLists: Model[] = [];

		if (props.items) {
			const listsModel = new Store();
			listsModel.sync(props.items.lists);
			newSubLists = listsModel.findAll('list').filter((list: Model) => list.folder !== null && list.folder.id === targetFolder.id);

			const foldersModel = new Store();
			foldersModel.sync(props.items.folders);
			newSubFolders = foldersModel.findAll('folder').filter((folder: Model) => folder.parent && folder.parent.id === targetFolder.id);
		}

		setSubItems({ folders: newSubFolders, lists: newSubLists });
		setOpenedFolder(targetFolder);
		setAddNewType('close');
		setSelectedList(null);
	};

	useEffect(() => {
		if (openedFolder !== null) {
			// when using the back button in a nested folder, make sure that the lists are updated
			folderChangedHandler(openedFolder);
		}
		setAddNewType('close');
	}, [openedFolder]);

	useEffect(() => {
		if (props.items) {
			const foldersModel = new Store();
			const listsModel = new Store();

			foldersModel.sync(props.items.folders);
			listsModel.sync(props.items.lists);

			const rootFolders = foldersModel.findAll('folder').filter((folder) => folder.parent === null);
			const rootLists = listsModel
				.findAll('list')
				.filter((list) => list.folder === null || !foldersModel.findAll('folder').some((folder) => folder.id === list.folder.id));

			setRootItems({ folders: rootFolders, lists: rootLists });
		}
	}, []);

	const closeAddingNewListInput = () => {
		setNewListName('');
		setIsAddingNewList(false);
	};

	const closeAddingNewFolderInput = () => {
		setNewFolderName('');
		setIsAddingNewFolder(false);
	};

	const changeNewFolderName = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			createNewFolder();
		}
	};

	const createNewFolder = () => {
		const newFoldersModel = new Store();
		const parentFolderId = openedFolder ? openedFolder.id : '';
		ListsService.createFolder(newFolderName, parentFolderId).then((res) => {
			props.updateFoldersAndLists && props.updateFoldersAndLists();

			closeAddingNewFolderInput();

			if (res) {
				// @ts-ignore
				newFoldersModel.sync(res.data);
				const newOriginalFolder = openedFolder ? [] : newFoldersModel.findAll('folder').filter((folder) => folder.parent === null);
				const newSubFolder = openedFolder ? newFoldersModel.findAll('folder').filter((folder) => folder.parent && folder.parent.id === openedFolder.id) : [];

				if (newOriginalFolder.length > 0) {
					setRootItems((prev) => {
						const { folders } = prev;
						return { ...prev, folders: [...folders, ...newOriginalFolder] };
					});
				}

				if (newSubFolder.length > 0) {
					setSubItems((prev) => {
						const { folders } = prev;
						return { ...prev, folders: [...folders, ...newSubFolder] };
					});
				}

				closeAddingNewFolderInput();

				// @ts-ignore
				const { id: createdFolderId } = res?.data?.data ?? { id: null };
				const createdFolder = newFoldersModel
					.findAll('folder')
					.filter((folder) => folder?.id === createdFolderId)
					?.at(0);
				if (createdFolder) {
					setOpenedFolder(createdFolder);
				}
			}
		});
	};

	const changeNewListName = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			createNewList();
		}
	};

	const createNewList = () => {
		const newListsModel = new Store();
		const parentFolderId = openedFolder ? openedFolder.id : '';
		ListsService.createList(newListName, parentFolderId).then((res) => {
			props.updateFoldersAndLists && props.updateFoldersAndLists();

			closeAddingNewListInput();

			if (res) {
				newListsModel.sync(res as JsonApiDocument);
				const newList = openedFolder ? newListsModel.findAll('list') : [];

				if (newList.length > 0) {
					setSubItems((prev) => {
						const { lists } = prev;
						return { ...prev, lists: [...lists, ...newList] };
					});
				}

				closeAddingNewListInput();
			}
		});
	};

	const renderFoldersAndLists = () => {
		const selectedFolderItems = !openedFolder ? rootItems : subItems;
		if (selectedFolderItems.folders.length === 0 && selectedFolderItems.lists.length === 0) {
			return (
				<Styled.EmptyState className='grow-1'>
					<picture>
						<img src={EmptyStateFolderList} alt='No results' />
					</picture>
					<p>Create a folder or list to start organizing your influencers.</p>
				</Styled.EmptyState>
			);
		}

		return (
			<Styled.MenuItemsWrapper className='grow-1'>
				{selectedFolderItems.folders.map((folder) => (
					<Styled.DropdownItemInnerWrapper
						key={folder.id}
						onClick={() => {
							if (isSelectionEnabled) {
								folderChangedHandler(folder);
							}
						}}
					>
						<Styled.NameWrapper>
							<Icon name='folder' size='16' />
							<Styled.Name content={folder.name} positionStrategy='fixed' delayShow={1200}>
								{folder.name}
							</Styled.Name>
						</Styled.NameWrapper>
						<Icon name='chevron-right' size='16' />
					</Styled.DropdownItemInnerWrapper>
				))}
				{selectedFolderItems.lists.map((list) => (
					<Styled.DropdownItemInnerWrapper
						key={list.id}
						onClick={() => {
							if (isSelectionEnabled) {
								setSelectedList(list);
							}
						}}
						onDoubleClick={() => {
							setIsSelectionEnabled(false);
							addSelectionToListHandler('', selectedList!.id, selectedList!.name);
							setSelectedList(null);
							setIsSelectionEnabled(true);
						}}
						data-testid={`select-${list.name}`}
						className={classNames({ 'selected-list-item': selectedList?.id === list.id }, 'list')}
					>
						<div>
							<Icon name='list-influencers' size='16' color='#333' />
							<Styled.Name>{list.name}</Styled.Name>
						</div>
					</Styled.DropdownItemInnerWrapper>
				))}
			</Styled.MenuItemsWrapper>
		);
	};

	return (
		<Styled.CustomMenu>
			<Styled.MenuHeader>
				{props.isCancellable && props.onCancel && (
					<BackButton
						innerText={openedFolder?.name}
						onCancel={() => {
							if ((!openedFolder || openedFolder === null) && props.onCancel) {
								props.onCancel();
							} else if (openedFolder !== null) {
								setOpenedFolder(openedFolder.parent ?? null);
							}
						}}
					/>
				)}
				<CloseButton onClose={props.onClose} />
			</Styled.MenuHeader>

			<Styled.MenuItemsWrapper>
				<Styled.CreateNewWrapper>
					<ButtonSecondary onClick={() => setAddNewType('folder')} type='button' size='sm'>
						<span>New folder</span>
						<Icon name='folder' size='16' />
					</ButtonSecondary>
					<ButtonSecondary onClick={() => setAddNewType('list')} type='button' size='sm'>
						<span>New list</span>
						<Icon name='list-influencers' size='16' />
					</ButtonSecondary>
				</Styled.CreateNewWrapper>
			</Styled.MenuItemsWrapper>

			{isAddingNewFolder && (
				<Styled.DropdownItemInnerWrapper className={classNames('create-list list', { 'input-mode': isAddingNewFolder })}>
					<Icon name='folder' size='16' />
					<Styled.Input
						autoFocus
						value={newFolderName}
						onKeyDown={changeNewFolderName}
						onChange={(e) => {
							setNewFolderName(e.target.value);
						}}
					/>
					<Styled.ActionIconWrapper onClick={() => setAddNewType('close')} tabIndex={0}>
						<Icon name='cross' size='8' />
					</Styled.ActionIconWrapper>
					<Styled.ActionIconWrapper onClick={createNewFolder} tabIndex={0}>
						<Icon name='checkmark' size='8' />
					</Styled.ActionIconWrapper>
				</Styled.DropdownItemInnerWrapper>
			)}

			{isAddingNewList && (
				<Styled.DropdownItemInnerWrapper className={classNames('create-list list', { 'input-mode': isAddingNewList })}>
					<Icon name='list-influencers' size='16' />
					<Styled.Input
						autoFocus
						value={newListName}
						onKeyDown={changeNewListName}
						onChange={(e) => {
							setNewListName(e.target.value);
						}}
					/>
					<Styled.ActionIconWrapper onClick={() => setAddNewType('close')}>
						<Icon name='cross' size='8' />
					</Styled.ActionIconWrapper>
					<Styled.ActionIconWrapper onClick={createNewList}>
						<Icon name='checkmark' size='10' />
					</Styled.ActionIconWrapper>
				</Styled.DropdownItemInnerWrapper>
			)}

			{renderFoldersAndLists()}

			<Styled.MenuItemsWrapper>
				<Styled.MenuFooter>
					<ButtonSecondary
						type='button'
						size='sm'
						disabled={selectedList === null}
						onClick={() => {
							setSelectedList(null);
						}}
					>
						<span>Cancel</span>
					</ButtonSecondary>
					<DarkButton
						type='button'
						size='sm'
						disabled={selectedList === null || !isSelectionEnabled}
						onClick={() => addSelectionToListHandler('', selectedList!.id, selectedList!.name)}
					>
						{props.isSaving ? <LoadingSpinner size='sm' /> : <span>Add {props.selectedItems.length} profiles</span>}
					</DarkButton>
				</Styled.MenuFooter>
			</Styled.MenuItemsWrapper>
		</Styled.CustomMenu>
	);
};

export default FolderListMenuItems;
