import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgLockCheckmark(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg fill='none' {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.56522 6C5.56522 6.42783 5.21043 6.78261 4.78261 6.78261C4.35478 6.78261 4 6.42783 4 6C4 2.69217 6.69217 0 10 0C13.3078 0 16 2.69217 16 6C16 6.42783 15.6452 6.78261 15.2174 6.78261C14.7896 6.78261 14.4348 6.42783 14.4348 6C14.4348 3.55826 12.4417 1.56522 10 1.56522C7.55826 1.56522 5.56522 3.55826 5.56522 6ZM1.86952 8H18.1205H18.1305C18.6102 8 19 8.38437 19 8.8625V19.1375C19 19.6156 18.6102 20 18.1305 20H1.86952C1.38978 20 1 19.6156 1 19.1375V8.8625C1 8.38437 1.38978 8 1.86952 8ZM2.41921 18.5844H17.5708V9.40625H2.41921V18.5844Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.885 12.1553C14.0523 12.3459 14.035 12.6377 13.8464 12.8068L10.4156 15.8837C10.2499 16.0324 10.0025 16.0392 9.82894 15.9L7.17282 13.7699C6.9753 13.6115 6.94219 13.3212 7.09888 13.1215C7.25558 12.9218 7.54273 12.8883 7.74025 13.0467L10.0963 14.9362L13.2405 12.1163C13.4291 11.9471 13.7177 11.9646 13.885 12.1553Z'
				fill='#4CA867'
			/>
		</svg>
	);
}
