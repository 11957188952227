import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgListSharedWithMe(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 2.48999C7.3418 2.07578 7.67758 1.73999 8.0918 1.73999H19.0918C19.506 1.73999 19.8418 2.07578 19.8418 2.48999C19.8418 2.9042 19.506 3.23999 19.0918 3.23999H8.0918C7.67758 3.23999 7.3418 2.9042 7.3418 2.48999Z'
				fill='#4D4B4C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 9.98999C7.3418 9.57578 7.67758 9.23999 8.0918 9.23999H12.0918C12.506 9.23999 12.8418 9.57578 12.8418 9.98999C12.8418 10.4042 12.506 10.74 12.0918 10.74H8.0918C7.67758 10.74 7.3418 10.4042 7.3418 9.98999Z'
				fill='#4D4B4C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 17.49C7.3418 17.0758 7.67758 16.74 8.0918 16.74L11.75 16.74C12.1642 16.74 12.5 17.0758 12.5 17.49C12.5 17.9042 12.1642 18.24 11.75 18.24H8.0918C7.67758 18.24 7.3418 17.9042 7.3418 17.49Z'
				fill='#4D4B4C'
			/>
			<path
				d='M14.8586 12.1054L13.1309 13.6996C13.089 13.7383 13.0576 13.7916 13.0314 13.8498C13.0105 13.8934 13 13.937 13 13.9855L13 13.9952C13 14.0485 13.0157 14.0969 13.0366 14.1454C13.0628 14.2035 13.0942 14.2568 13.1309 14.2907L14.8586 15.8946C15.0105 16.0351 15.2618 16.0351 15.4136 15.8946C15.5654 15.7541 15.5654 15.5215 15.4136 15.381L14.3141 14.3634L19.6073 14.3634C19.822 14.3634 20 14.1987 20 14C20 13.8013 19.822 13.6366 19.6073 13.6366L14.3141 13.6366L15.4136 12.619C15.4921 12.5463 15.5288 12.4543 15.5288 12.3622C15.5288 12.2701 15.4921 12.1781 15.4136 12.1054C15.2618 11.9649 15.0105 11.9649 14.8586 12.1054Z'
				fill='#4D4B4C'
			/>
			<rect x='0.75' y='0.75' width='3.5' height='3.5' rx='1.75' stroke='#4D4B4C' strokeWidth='1.5' />
			<rect x='0.75' y='8.25' width='3.5' height='3.5' rx='1.75' stroke='#4D4B4C' strokeWidth='1.5' />
			<rect x='0.75' y='15.75' width='3.5' height='3.5' rx='1.75' stroke='#4D4B4C' strokeWidth='1.5' />
		</svg>
	);
}
