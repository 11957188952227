import { Helmet } from 'react-helmet';

import fallbackLogotype from 'assets/img/logo/collabs-logo-dark.svg';

import Styled from './Login.style';
import Footer from './components/Footer';
import LoginForm from './components/LoginForm';

/**
 * LoginView
 * @todo tests
 * @returns {JSX.Element}
 */
const LoginView = (): JSX.Element => (
	<Styled.Wrapper>
		<Helmet>
			<title>Collabs</title>
		</Helmet>
		<Styled.Inner>
			<Styled.Section>
				<img src={fallbackLogotype} alt='Collabs' width='110px' />
				<LoginForm />
			</Styled.Section>
		</Styled.Inner>
		<Footer />
	</Styled.Wrapper>
);

export default LoginView;
