import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { CountryModel } from 'api-models';
import { getInfluencerCountryOptions } from 'components/DataLibrary/AllRequests/DataFilterOptions';
import FilterSectionAccordionItem from 'components/DataLibrary/FilterSection/FilterSectionAccordionItem';
import { FilterOptionProps } from 'components/DataLibrary/FilterSection/types';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboardFilterCountries } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const InfluencerCountryOptions = (props: FilterOptionProps): JSX.Element => {
	const [selectedInfluencerCountries, setSelectedInfluencerCountries] = useState<Array<string>>([]);
	const [clearThisFilter, setClearThisFilter] = useState<boolean>(false);

	const manager = useInjection<StatisticsManager>(StatisticsManager);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const dispatch = useAppDispatch();

	let influencerCountries: CountryModel[] = [];
	let isSearching = true;

	if (dashboard.id) {
		const { result, error } = getInfluencerCountryOptions(manager, dashboardFilter);
		if (error) {
			console.error(error.message);
		}
		isSearching = !result;
		if (result) {
			influencerCountries = result;
		}
	}

	const onSelect = (id: string) => {
		props.getClearFilter(false);
		setClearThisFilter(false);
		if (selectedInfluencerCountries.includes(id)) {
			const update = selectedInfluencerCountries.filter((i) => i !== id);
			setSelectedInfluencerCountries(update);
		} else {
			setSelectedInfluencerCountries([...selectedInfluencerCountries, id]);
		}
	};

	useEffect(() => {
		dispatch(setDashboardFilterCountries(selectedInfluencerCountries));
	}, [selectedInfluencerCountries]);

	const checkDashboardFilter = () => {
		if (dashboard.metaData.dashboardFilter) {
			const savedCountries = dashboard.metaData.dashboardFilter.countries ?? [];
			if (!isEqual(savedCountries, selectedInfluencerCountries)) {
				setSelectedInfluencerCountries(savedCountries);
			}
		} else {
			setSelectedInfluencerCountries([]);
		}
	};
	useEffect(() => {
		checkDashboardFilter();
	}, [props.dashboardId, dashboard]);

	useEffect(() => {
		checkDashboardFilter();
		return () => {
			setSelectedInfluencerCountries([]);
			setClearThisFilter(false);
		};
	}, []);

	useEffect(() => {
		if (clearThisFilter || props.clearFilter) {
			setSelectedInfluencerCountries([]);
		}
	}, [props.clearFilter, clearThisFilter]);

	return (
		<FilterSectionAccordionItem
			setClearThisFilter={setClearThisFilter}
			title={<>Influencer countries {selectedInfluencerCountries?.length > 0 && <>({selectedInfluencerCountries?.length})</>} </>}
			label={'Search for influencer countries'}
			placeholder={'i.e. Sweden'}
			data={influencerCountries.map((item) => ({
				id: item.id,
				name: item.attributes.name,
			}))}
			selectedData={selectedInfluencerCountries}
			onSelect={onSelect}
			isSearching={isSearching}
		/>
	);
};
export default InfluencerCountryOptions;
