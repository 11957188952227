import styled from 'styled-components';

import { KIND } from 'components/EditableImage/types/types';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const EditableImageToolBarWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 0 auto;
`;
const EditableImageToolBar = styled.div<{ inEditMode: boolean; canCrop: boolean; kind?: KIND }>`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	overflow: hidden;
	gap: ${spacing[0].px};
	margin-bottom: 8px;

	& button {
		color: ${colors.mainBlack};
		border: none;
		margin: ${spacing[0].px};
	}

	> div {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		max-width: 350px;
		transition: transform 0.2s ease-in-out;
		transform: ${({ inEditMode, canCrop, kind }) =>
			!inEditMode ? (canCrop ? 'translateX(235px)' : `translateX(${kind === KIND.CAMPAIGN_COVER_IMAGE ? '175px' : '185px'})`) : 'translateX(0)'};
	}
`;

const EditableImageToolBarCTA = styled.div<{ disabled?: boolean; inEditMode: boolean; kind?: KIND }>`
	display: flex;
	padding: ${spacing[0].rem};
	color: ${({ disabled }) => (disabled ? colors.gray8 : colors.info)};
	pointer-events: ${({ disabled }) => disabled && 'none'};
	width: fit-content;
	font-size: ${({ kind }) => (kind === KIND.CAMPAIGN_COVER_IMAGE ? '20px' : '16px')};
	cursor: pointer;
	&.edit {
		width: ${({ inEditMode, kind }) => (inEditMode ? '115px' : kind === KIND.CAMPAIGN_COVER_IMAGE ? '190px' : '93px')};
	}
	&.delete {
		color: ${colors.errorRed};
		width: ${({ kind }) => (kind === KIND.CAMPAIGN_COVER_IMAGE ? '70px' : '60px')};
	}
	&.crop {
		width: ${({ kind }) => (kind === KIND.CAMPAIGN_COVER_IMAGE ? '60px' : '50px')};
	}
	&.replace {
		width: ${({ kind }) => (kind === KIND.CAMPAIGN_COVER_IMAGE ? '94px' : '76px')};
	}
	&.save {
		width: ${({ kind }) => (kind === KIND.CAMPAIGN_COVER_IMAGE ? '60px' : '50px')};
	}

	&.cancel {
		color: ${colors.info};
		width: ${({ kind }) => (kind === KIND.CAMPAIGN_COVER_IMAGE ? '82px' : '64px')};
	}

	&:hover {
		font-weight: ${({ disabled }) => !disabled && 600};
	}
`;

const Styled = {
	EditableImageToolBarWrapper,
	EditableImageToolBar,
	EditableImageToolBarCTA,
};

export default Styled;
