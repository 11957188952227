import { useEffect, useState } from 'react';

import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import { StatisticsFormValues } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import useContentManagementData from 'components/ContentManagement/hooks';
import { InputText } from 'components/Form/FormikElements';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import { AssignmentType } from 'shared/helpers/Assigment/types';

type AddContentUrlProps = {
	contentType: AssignmentType;
	inEditMode?: boolean;
	disabled?: boolean;
	values?: StatisticsFormValues;
	profileConnected?: boolean;
	setUrlAdded?: (added: boolean) => void;
	instagramLinkCheck?: string;
	onInput?: () => void;
};

const contentUrlPlaceholder = (contentType: AssignmentType) => {
	switch (contentType) {
		case AssignmentType.INSTAGRAM_STORY:
			return 'https://www.instagram.com/stories/[username]/***';
		case AssignmentType.INSTAGRAM_REEL:
			return 'https://www.instagram.com/reel/***';
		case AssignmentType.TIKTOK_VIDEO:
			return 'https://www.tiktok.com/***';
		default:
			return 'https://www.instagram.com/p/***';
	}
};

/**
 * AddContentUrl
 * @returns {JSX.Element}
 */
const AddContentUrl = ({
	contentType,
	values,
	profileConnected,
	setUrlAdded,
	disabled,
	instagramLinkCheck,
	onInput = () => {},
}: AddContentUrlProps): JSX.Element => {
	const { checkIfInstagramUrlIsValid } = useContentManagementData();
	const [urlIsValid, setUrlIsValid] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);

	const placeholder = contentUrlPlaceholder(contentType);

	useEffect(() => {
		if (values && values.url && values.url?.length > 0) {
			// For profiles that are connected to IG, we first validate that we can fetch data for that url before saving the statistics.
			if (profileConnected && instagramLinkCheck) {
				setUrlIsValid(true);
				setIsLoading(true);
				checkIfInstagramUrlIsValid(instagramLinkCheck, values.url)
					.then(() => {
						setUrlIsValid(true);
						setUrlAdded && setUrlAdded(true);
					})
					.catch(() => {
						setUrlIsValid(false);
					})
					.finally(() => {
						setIsLoading(false);
					});
			} else {
				setUrlAdded && setUrlAdded(true);
			}
		} else {
			setUrlAdded && setUrlAdded(false);
		}
	}, [values]);

	return (
		<Styled.UrlWrapper>
			<Styled.InsightsText>
				{!disabled && (
					<>
						<h5>Add URL to post</h5>
						{!values?.url && profileConnected && <p>Insights not showing? Try adding the URL of your post.</p>}
					</>
				)}
			</Styled.InsightsText>
			<Styled.InputGroupRow>
				<Styled.InputGroup className='input--url'>
					<InputText
						label='URL'
						id='url'
						name='url'
						placeholder={placeholder}
						disabled={disabled}
						onInput={onInput}
						error={
							profileConnected &&
							!urlIsValid &&
							values &&
							values.url &&
							`No insights found for this URL, please make sure the link is correct and starts with: ${placeholder}`
						}
					/>
					{isLoading ? (
						<Styled.SpinnerWrapper>
							<LoadingSpinner size='sm' />
						</Styled.SpinnerWrapper>
					) : (
						urlIsValid && values && values.url && <Icon name='checkmark' size='16' />
					)}
				</Styled.InputGroup>
			</Styled.InputGroupRow>
		</Styled.UrlWrapper>
	);
};

export default AddContentUrl;
