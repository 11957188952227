import styled from 'styled-components';

import { breakpoints, mediaQueries } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	background-color: ${({ theme }) => theme.threeCol.sidebarBackground};
	transition:
		transform 0.2s ease-in-out,
		opacity 0.4s ease-in-out;
	transform: translateX(100%);
	height: 0;
	opacity: 0;
	padding: 1rem;
	&.visible {
		transform: translateX(0);
		opacity: 1;
		height: auto;
	}
`;

const InnerWrapper = styled.div`
	overflow-y: auto;
	height: calc(100% - 220px);
	${mediaQueries.xl`
			height: 100vh;
	`};
`;

const ListWrapper = styled.ul`
	padding: 0;
	list-style-type: none;
	padding-bottom: 3rem;
	.no-assignments {
		text-align: center;
		font-weight: 700;
		font-size: 0.875rem;
	}
`;

const Divider = styled.div`
	@media (max-width: ${breakpoints.sm}) {
		margin-left: 65px;
		height: 1px;
	}
`;

const Styled = {
	Wrapper,
	InnerWrapper,
	ListWrapper,
	Divider,
};

export default Styled;
