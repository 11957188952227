import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgCheckCircleFilled(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z' fill='#333333' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.4803 6.49994C15.7732 6.79284 15.7732 7.26771 15.4803 7.5606L9.48028 13.5606C9.18739 13.8535 8.71251 13.8535 8.41962 13.5606L4.91962 10.0606C4.62673 9.76771 4.62673 9.29284 4.91962 8.99994C5.21251 8.70705 5.68739 8.70705 5.98028 8.99994L8.94995 11.9696L14.4196 6.49994C14.7125 6.20705 15.1874 6.20705 15.4803 6.49994Z'
				fill='#F2F2F2'
			/>
		</svg>
	);
}
