/**
 * Grid variables
 */
export const gridCols = 12;
export const gridGap = '1rem';

/**
 * PageHeader variables
 */
export const pageHeaderHeight = '100px';
