import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgInfluencer(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.5 7.5C10.1569 7.5 11.5 6.15685 11.5 4.5C11.5 2.84315 10.1569 1.5 8.5 1.5C6.84315 1.5 5.5 2.84315 5.5 4.5C5.5 6.15685 6.84315 7.5 8.5 7.5ZM8.5 9C10.9853 9 13 6.98528 13 4.5C13 2.01472 10.9853 0 8.5 0C6.01472 0 4 2.01472 4 4.5C4 6.98528 6.01472 9 8.5 9ZM13.569 19.8723C13.3707 19.7419 13.2826 19.5312 13.3156 19.3105L13.6461 17.4946L12.1922 16.2205C12.016 16.07 11.961 15.8392 12.027 15.6386C12.1041 15.4279 12.2914 15.2774 12.5337 15.2473L14.5382 14.9764L15.4303 13.321C15.5404 13.1304 15.7497 13 15.992 13C16.2343 13 16.4546 13.1204 16.5537 13.321L17.4568 14.9664L19.4613 15.2272C19.7036 15.2573 19.8908 15.4078 19.9679 15.6185C20.045 15.8292 19.979 16.0499 19.8138 16.2004L18.371 17.4946L18.7234 19.3105C18.7674 19.5212 18.6683 19.7419 18.4811 19.8723C18.2828 20.0027 18.0295 20.0228 17.8203 19.9225L16.025 19.0697L14.2408 19.9325C14.0315 20.0328 13.7782 20.0228 13.58 19.8924L13.569 19.8723ZM13.1064 15.8693L14.2408 16.8625C14.4611 17.0532 14.5602 17.3341 14.5051 17.5949L14.2408 19.0095L15.6395 18.3373C15.9039 18.2069 16.2233 18.2069 16.4876 18.3373L17.8863 18.9995L17.611 17.5849C17.5559 17.314 17.6551 17.0431 17.8753 16.8425L18.9987 15.8392L17.4348 15.6386C17.1374 15.5984 16.8731 15.4279 16.7409 15.1871L16.036 13.9029L15.3422 15.1971C15.21 15.4479 14.9567 15.6185 14.6483 15.6586L13.0844 15.8693H13.1064ZM2.7305 14.2781C1.70826 15.5322 1.4397 17.0778 1.5138 18.1853L1.51546 18.2102V19.2332C1.51546 19.6466 1.17914 19.9817 0.764267 19.9817C0.349394 19.9817 0.0130735 19.6466 0.0130735 19.2332V18.2592C-0.0740588 16.8733 0.255816 14.9396 1.56431 13.3342C2.90356 11.6911 5.17869 10.5006 8.76131 10.5H8.76374C11.3896 10.5 12.8699 10.9937 14.2493 11.9971C14.5843 12.2409 14.6577 12.7092 14.4131 13.0431C14.1685 13.377 13.6985 13.4501 13.3634 13.2063C12.2935 12.4281 11.1426 11.9971 8.7638 11.9971C5.53711 11.9971 3.72976 13.0521 2.7305 14.2781Z'
				fill='#333333'
			/>
		</svg>
	);
}
