import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgNotification(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M11.11 2.2C10.83 2.2 10.61 1.97 10.61 1.68C10.61 1.33 10.34 1.04 10 1.04C9.66 1.04 9.39 1.33 9.39 1.68C9.39 1.97 9.17 2.2 8.89 2.2C8.61 2.2 8.39 1.97 8.39 1.68C8.39 0.75 9.11 0 10 0C10.89 0 11.6 0.75 11.6 1.68C11.6 1.97 11.38 2.2 11.1 2.2H11.11Z'
				fill='#333333'
			/>
			<path
				d='M10 20C8.81 20 7.84 18.98 7.84 17.74C7.84 17.45 8.06 17.22 8.34 17.22C8.62 17.22 8.84 17.45 8.84 17.74C8.84 18.41 9.36 18.96 10.01 18.96C10.66 18.96 11.18 18.41 11.18 17.74C11.18 17.45 11.4 17.22 11.68 17.22C11.96 17.22 12.18 17.45 12.18 17.74C12.18 18.99 11.21 20 10.02 20H10Z'
				fill='#333333'
			/>
			<path
				d='M10.12 16.52H9.87C8.67 16.52 4.08 16.41 2.56 14.84C2.19 14.46 2 13.99 2 13.5C2 13.18 2.19 12.89 2.47 12.77C2.57 12.73 5.11 11.54 5.11 6.4C5.09 5.14 5.51 4 6.33 3.13C7.24 2.17 8.57 1.61 10 1.61C11.43 1.61 12.76 2.16 13.67 3.13C14.49 4 14.91 5.13 14.89 6.4C14.89 11.54 17.43 12.72 17.54 12.77C17.82 12.89 18 13.18 18 13.49C18 13.98 17.81 14.45 17.44 14.83C15.92 16.41 11.33 16.51 10.12 16.51V16.52ZM3.7 13.81C4.67 14.62 7.92 14.97 9.99 14.95C11.93 14.95 15.28 14.66 16.29 13.81C15.25 13.03 13.39 11 13.39 6.4C13.4 5.54 13.13 4.8 12.6 4.23C11.97 3.56 11.02 3.18 10 3.18C8.98 3.18 8.03 3.56 7.4 4.23C6.87 4.79 6.6 5.54 6.61 6.39C6.61 11.01 4.75 13.04 3.71 13.82L3.7 13.81Z'
				fill='#333333'
			/>
		</svg>
	);
}
