import classNames from 'classnames';
import { useContext } from 'react';

import Accordion from 'components/Accordion';
import Checkbox from 'components/Checkbox';
import DiscoveryContext from 'contexts/Discovery';

const AudienceAgeFilter = () => {
	const updateAudienceAgeRange = useContext(DiscoveryContext).updateAudienceAgeRangeHandler;
	const filter = useContext(DiscoveryContext).filter;

	const audienceAgeRanges = {
		'13-17': false,
		'18-24': false,
		'25-34': false,
		'35-44': false,
		'45-54': false,
		'55-64': false,
		'65+': false,
	};

	const genderAudienceAgeSelected = (ageRange: string) => {
		if (!filter?.audienceAgeRanges) {
			return false;
		}

		if (ageRange === '65+' && filter.audienceAgeRanges.includes('65')) {
			return true;
		}
		// Regular check for other age ranges
		return filter.audienceAgeRanges.includes(ageRange);
	};

	return (
		<Accordion title='Audience age' className={classNames({ active: filter?.audienceAgeRanges })}>
			{Object.keys(audienceAgeRanges).map((ageRange) => {
				return (
					<div key={ageRange} className='checkbox-container'>
						<Checkbox className='textFirst' label={ageRange} onChange={() => updateAudienceAgeRange(ageRange)} checked={genderAudienceAgeSelected(ageRange)} />
					</div>
				);
			})}
		</Accordion>
	);
};

export default AudienceAgeFilter;
