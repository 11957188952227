/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { UpdateInfluencerListItemReactionPayload, AddReactionOnInfluencerListItemPayload } from 'api-payloads';
import { UpdateInfluencerListItemReactionQuery, AddReactionOnInfluencerListItemQuery } from 'api-queries';
import { InfluencerListListItemReactionResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import InfluencerListItemReactionApiClientInterface from './InfluencerListItemReactionApiClientInterface';

@injectable()
export default class InfluencerListItemReactionApiClient implements InfluencerListItemReactionApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Update influencer list item reaction
	 */
	async update(
		itemId: string,
		reactionId: string,
		payload: UpdateInfluencerListItemReactionPayload,
		params: UpdateInfluencerListItemReactionQuery,
	): Promise<InfluencerListListItemReactionResponse> {
		const response = await this.client.patch<InfluencerListListItemReactionResponse>(`/items/${itemId}/reactions/${reactionId}`, payload, { params });
		return response.data;
	}

	/**
	 * Add reaction on influencer list item
	 */
	async addReactionOnInfluencerListItem(
		listId: string,
		itemId: string,
		payload: AddReactionOnInfluencerListItemPayload,
		params: AddReactionOnInfluencerListItemQuery,
	): Promise<InfluencerListListItemReactionResponse> {
		const response = await this.client.post<InfluencerListListItemReactionResponse>(`/lists/${listId}/items/${itemId}/reaction`, payload, { params });
		return response.data;
	}
}
