import styled from 'styled-components';

import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import { spacing } from 'styles/variables/spacings';

const DropZoneWrapper = styled.div`
	display: flex;
	width: 100%;
`;

const CustomGhostButton = styled.div`
	width: fit-content;
	padding: 0.25rem 0.5rem;
	border-radius: 4px;
	margin: auto;
	border: 2px solid ${colors.slate};
	color: ${colors.slate};
`;

const DropZone = styled.div`
	cursor: pointer;
	width: 100%;
	min-width: 204px;
	height: 190px;
	border: transparent;
	background-color: ${colors.ash};
	transition: background-color 0.2s ease-in-out;

	border-radius: ${borderRadius.m};
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		transition: color 0.2s ease-in-out;
	}

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: ${colors.slate};
		transition: color 0.2s ease-in-out;

		.icon {
			margin-bottom: 0.5rem;
			padding-top: 1rem;

			path {
				transition: fill 0.2s ease-in-out;
			}
		}
	}
	p {
		color: ${colors.slate};
	}
	&:hover {
		background: ${colors.black};
		p {
			color: ${colors.white};
		}
		.icon path {
			fill: ${colors.white};
		}
		${CustomGhostButton} {
			border: 2px solid ${colors.white};
			color: ${colors.white};
		}
	}
`;

const CustomDropdown = styled(Dropdown)`
	display: flex;
	justify-content: flex-end;
`;

const CustomDropDownMenu = styled(DropdownMenu)`
	transform: translate3d(0px, 34px, 0px) !important;
	min-width: 205px !important;
	border-radius: 4px;
`;

const CustomDropDownMenuItem = styled(DropdownItem)`
	padding: 0.2rem 0.5rem;
	.icon {
		margin-top: 6px;
		margin-right: 8px;
	}
`;

const IconMargin = styled.div`
	margin-left: 5px;
`;

const InputWrapper = styled.div`
	margin-bottom: ${spacing[2].px};
	width: 100%;
	display: flex;
	flex-direction: column;
	&.w-50 {
		width: 60%;
	}
`;

const ShowFullSizeButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	position: absolute;
	bottom: 10px;
	right: 10px;
	background-color: ${colors.steel};
	cursor: pointer;
	border-radius: 2px;
	padding: 0.5rem;

	.icon {
		line-height: 0;

		svg path {
			fill: ${colors.white};
		}
	}
`;

const LogoWrapper = styled.div`
	position: relative;
	height: 190px;
	width: 100%;
`;

const Logo = styled.div`
	position: relative;
	max-height: 125px;
	width: auto;
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
`;

const Image = styled.img`
	height: auto;
	width: auto;
	max-width: 190px;
	max-height: 190px;
	display: block;
	margin-right: 16px;
`;

const Placeholder = styled.div`
	width: 204px;
	height: 190px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${colors.ash};
	border-radius: ${borderRadius.m};
	text-align: center;
	padding: 1rem;
`;

const Styled = {
	Image,
	CustomGhostButton,
	LogoWrapper,
	DropZone,
	DropZoneWrapper,
	Logo,
	CustomDropDownMenu,
	CustomDropDownMenuItem,
	IconMargin,
	InputWrapper,
	CustomDropdown,
	ShowFullSizeButton,
	Placeholder,
};
export default Styled;
