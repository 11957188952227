import styled from 'styled-components';

import Grid from 'styles/grid/grid';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { fontWeights } from 'styles/variables/font-weights';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints, devices } from 'styles/variables/media-queries';

const GridColumn = Grid.Column;
const Wrapper = styled(GridColumn)``;

const InnerWrapper = styled.div`
	width: 100%;
	height: 100%;
	padding: ${guttersWithRem.m};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: ${borderRadius.m};
	background-color: ${colors.gray13};
`;

const CampaignInfoWrapper = styled.div`
	display: flex;
	column-gap: ${guttersWithRem.m};
	margin-bottom: ${guttersWithRem.xxl};
	padding-bottom: 2rem;
	border-bottom: 1px solid ${newColors.ash};

	@media (max-width: ${breakpoints.sm}) {
		flex-direction: column;
		row-gap: ${guttersWithRem.m};
	}
`;

const CampaignImage = styled.div`
	& > img {
		width: 100%;
		height: 100%;
		border-radius: ${borderRadius.s}
		object-fit: cover;
	}
`;

const CampaignInfo = styled.div`
	padding-top: ${guttersWithRem.xs};
	flex: 1;
	display: flex;
	flex-direction: column;

	& > div {
		&.title {
			font-size: 1.0625rem;
			font-weight: 600;
			@media screen and (${devices.md}) {
				font-size: 1.1875rem;
				font-weight: 700;
			}
		}

		&.brand {
			margin-top: 5px;
			margin-bottom: 16px;
		}

		&.info-box {
			flex: 1;
			display: flex;
			align-items: center;
			text-align: center;

			padding: ${guttersWithRem.xs};
			background-color: ${colors.mainWhite};
			border: 1px solid ${colors.lightGray};
			border-radius: 4px;
			margin-top: 16px;

			& > div {
				flex: 1;

				& > div {
					&.commission,
					&.date {
						display: flex;
						align-items: center;
						justify-content: center;

						font-weight: 700;
					}
				}

				&.divider {
					flex: none;
					width: 2px;
					height: 100%;
					border-radius: 10px;

					background-color: ${colors.lightGray};
				}
			}
		}
	}
`;

const Dot = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;

	&.isVisible {
		border: 1px solid ${colors.influencerDashboard.campaign.dotBorder};
		background-color: ${colors.influencerDashboard.campaign.dotBackground};
		margin-right: ${guttersWithRem.xs};
	}
`;

const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${guttersWithRem.xs};

	& > button {
		flex: 1;

		padding-top: ${guttersWithRem.xs};
		padding-bottom: ${guttersWithRem.xs};

		&.bright {
			background-color: ${colors.transparent};
		}

		&.dark {
			background-color: ${colors.influencerDashboard.campaign.darkButton.background};
			color: ${colors.influencerDashboard.campaign.darkButton.color};
		}
	}
`;

const Text = styled.div`
	line-height: 1.2;

	&.title {
		max-width: 250px;
		font-weight: 700;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@media (max-width: ${breakpoints.sm}) {
			max-width: 150px;
		}
	}

	&.assignment-title {
		margin-left: 8px;
		font-weight: 700;
		margin-bottom: 8px;
	}

	&.brand {
	}

	&.commission-label {
	}

	&.commission-value {
		font-weight: 700;
	}
`;

const AssignmentLink = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	padding: 0.5rem;
	font-weight: ${fontWeights.semiBold};
	margin-bottom: 4px;
	border-radius: ${borderRadius.s};
	cursor: pointer;
	&:hover {
		background-color: ${newColors.ash};
	}
`;

const FlexDiv = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	@media screen and (${devices.md}) {
		flex-direction: row;
		flex-direction: row-reverse;
	}
`;

const Styled = {
	Text,
	Wrapper,
	InnerWrapper,
	CampaignInfoWrapper,
	CampaignImage,
	CampaignInfo,
	ButtonsWrapper,
	Dot,
	AssignmentLink,
	FlexDiv,
};

export default Styled;
