import { FormikErrors } from 'formik';
import { FormEvent } from 'react';

export type CurrancyAndAmount = { amount: number; currency: string };

export enum ExternalAccountType {
	BANK_ACCOUNT = 'bank_account',
	IBAN = 'iban',
	SWISH = 'swish',
}

export enum VerifyWith {
	BANK_ID = 'bankid_se_qr',
	OTHER_ID = 'online_id_scan',
}

export enum PinebucketVerificationIdStatus { // Ta bort detta
	PENDING = 'pending',
	INVALID = 'invalid',
	VERIFIED = 'verified',
	ERROR = 'error',
}

export enum IdVerificationStatus {
	NOT_VERIFIED = 'not_verified',
	INITIALIZED = 'initialized',
	ANALYSING_STARTED = 'analyzing_started',
	VERIFIED = 'verified',
	VERIFICATION_FAILED = 'verification_failed',
}

export enum PaymentLocalStorage {
	USER_VERIFIED = 'userIsVerified',
	ID_TO_VERIFY = 'idVerification',
	PINEBUCKET_TOKEN = 'pinebucket',
}

export type ExternalAccountIdentifier = {
	iban: string;
	clearingNumber: string;
	accountNumber: string;
};

export type PineBucketFormValues = {
	country: string;
	vatId: string;
	orgNumber?: string;
	clearingNumber: string;
	accountNumber: string;
	iban: string;
	currency: SupportedCurrencies;
	bic?: string;
	user: {
		name: string;
		companyName: string;
		email: string;
		address: string;
		city: string;
		country: string;
		postalCode: number | string | null;
		vatId: string;
	};
	bank_account: {
		currency: SupportedCurrencies;
		country: string;
		type: ExternalAccountType;
		identifier: ExternalAccountIdentifier;
		bic: string;
	};
};

export type FormProps = {
	values: PineBucketFormValues;
	handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
	resetForm: () => void;
	setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<PineBucketFormValues>>;
};

export const CountryCodesEU = [
	'SWE',
	'CHE',
	'NOR',
	'GBR',
	'AUT',
	'BEL',
	'BGR',
	'HRV',
	'CYP',
	'CZE',
	'DNK',
	'EST',
	'FIN',
	'FRA',
	'DEU',
	'GRC',
	'HUN',
	'IRL',
	'ITA',
	'LVA',
	'LTU',
	'LUX',
	'MLT',
	'NLD',
	'POL',
	'PRT',
	'ROU',
	'SVK',
	'SVN',
	'ESP',
];

export enum SupportedCurrencies {
	EUR = 'EUR',
	SEK = 'SEK',
	NOK = 'NOK',
	USD = 'USD',
	AUD = 'AUD',
	DKK = 'DKK',
}

export const allSupportedCurrencies: SupportedCurrencies[] = [
	SupportedCurrencies.EUR,
	SupportedCurrencies.SEK,
	SupportedCurrencies.NOK,
	SupportedCurrencies.USD,
	SupportedCurrencies.AUD,
	SupportedCurrencies.DKK,
];

export type BackendError = {
	source: { parameter: string; message: string };
	status: string;
	title: string;
};
