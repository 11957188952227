import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgTodo(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.84089 1.28033C5.13749 0.987437 5.13749 0.512563 4.84089 0.21967C4.54429 -0.0732233 4.0634 -0.0732233 3.7668 0.21967L1.77221 2.18934L1.29659 1.71967C0.999994 1.42678 0.519111 1.42678 0.222511 1.71967C-0.0740889 2.01256 -0.0740889 2.48744 0.222511 2.78033L1.23517 3.78033C1.53177 4.07322 2.01265 4.07322 2.30925 3.78033L4.84089 1.28033ZM8.00657 1.74023C7.58378 1.74023 7.24103 2.07602 7.24103 2.49023C7.24103 2.90445 7.58378 3.24023 8.00657 3.24023H19.2346C19.6574 3.24023 20.0001 2.90445 20.0001 2.49023C20.0001 2.07602 19.6574 1.74023 19.2346 1.74023H8.00657ZM8.00657 9.24023C7.58378 9.24023 7.24103 9.57602 7.24103 9.99023C7.24103 10.4044 7.58378 10.7402 8.00657 10.7402H19.2346C19.6574 10.7402 20.0001 10.4044 20.0001 9.99023C20.0001 9.57602 19.6574 9.24023 19.2346 9.24023H8.00657ZM7.24103 17.4902C7.24103 17.076 7.58378 16.7402 8.00657 16.7402H19.2346C19.6574 16.7402 20.0001 17.076 20.0001 17.4902C20.0001 17.9044 19.6574 18.2402 19.2346 18.2402H8.00657C7.58378 18.2402 7.24103 17.9044 7.24103 17.4902ZM4.84089 8.21967C5.13749 8.51256 5.13749 8.98744 4.84089 9.28033L2.30925 11.7803C2.01265 12.0732 1.53177 12.0732 1.23517 11.7803L0.222511 10.7803C-0.0740889 10.4874 -0.0740889 10.0126 0.222511 9.71967C0.519111 9.42678 0.999994 9.42678 1.29659 9.71967L1.77221 10.1893L3.7668 8.21967C4.0634 7.92678 4.54429 7.92678 4.84089 8.21967ZM4.84089 17.2803C5.13749 16.9874 5.13749 16.5126 4.84089 16.2197C4.54429 15.9268 4.0634 15.9268 3.7668 16.2197L1.77221 18.1893L1.29659 17.7197C0.999994 17.4268 0.519111 17.4268 0.222511 17.7197C-0.0740889 18.0126 -0.0740889 18.4874 0.222511 18.7803L1.23517 19.7803C1.53177 20.0732 2.01265 20.0732 2.30925 19.7803L4.84089 17.2803Z'
				fill='#333333'
			/>
		</svg>
	);
}
