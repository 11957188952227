import { AxiosError } from 'axios';
import { FormikErrors } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import { StatisticsFormValues } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import useContentManagementData from 'components/ContentManagement/hooks';
import Icon from 'components/Icon';
import toast from 'services/Toast';

type AddScreenShotsProps = {
	files: File[];
	setFiles: (files: File[]) => void;
	url?: string;
	refresh: () => Promise<void>;
	inEditMode?: boolean;
	setFieldValue: (field: string, value: File, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<StatisticsFormValues>>;
};
/**
 * Add screenshots component
 * @returns {JSX.Element}
 */
const AddScreenShots = ({ url, setFiles, refresh, setFieldValue, files }: AddScreenShotsProps) => {
	const { uploadStatisticsScreenshot } = useContentManagementData();

	const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
	const [uploadedFiles, setUploadedFiles] = useState<File[]>(files);

	const postMedia = (url: string, medias: Array<File>) => {
		return Promise.all(
			medias.map((media) => {
				uploadStatisticsScreenshot(url, media);
			}),
		)
			.then(() => {
				refresh();
				toast.success(`${medias?.length} file${medias?.length > 1 ? 's' : ''} uploaded`);
				setFieldValue('screenshot', medias[0]);
				setFiles([]);
			})
			.catch((e: AxiosError) => {
				console.error(e);
			})
			.finally(() => {
				refresh();
				setFiles([]);
			});
	};

	const onDrop = useCallback(async (acceptedFiles: File[]) => {
		setAcceptedFiles(acceptedFiles);
		if (url) {
			await postMedia(url, acceptedFiles); // On already saved statistics
		}
	}, []);

	useEffect(() => {
		const allFiles = uploadedFiles.concat(acceptedFiles);
		!url && setFiles(allFiles);
	}, [acceptedFiles]);

	useEffect(() => {
		Array.isArray(files) && setUploadedFiles(files);
	}, [files]);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			'image/jpeg': [],
			'image/jpg': [],
			'image/png': [],
		},
	});

	return (
		<Styled.AddScreenShotsSection>
			<Styled.DropZone {...getRootProps({ className: 'dropzone' })} data-testid='dropzone'>
				<input {...getInputProps()} type='file' data-testid='dropzone-input' />
				{!isDragActive && (
					<Styled.DropZoneText>
						<Icon name='add-circle' />
						<Styled.Title>Add screenshots</Styled.Title>
						<Styled.HelpText data-testid='helptext'>
							Supported formats <br />
							jpeg, jpg and png
						</Styled.HelpText>
					</Styled.DropZoneText>
				)}
			</Styled.DropZone>
		</Styled.AddScreenShotsSection>
	);
};
export default AddScreenShots;
