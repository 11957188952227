import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20 ',
	fill: 'none',
};

export function SvgDLCpr(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg fill='none' {...svgProps}>
			<rect x='14.8157' y='14.6509' width='0.499983' height='3.7699' transform='rotate(-60 14.8157 14.6509)' fill='#333333' />
			<rect x='14.8201' y='8.8501' width='0.499983' height='4.98069' transform='rotate(-60 14.8201 8.8501)' fill='#333333' />
			<rect x='14.3308' y='13.2158' width='0.499983' height='4.90719' transform='rotate(-60 14.3308 13.2158)' fill='#333333' />
			<rect x='15.2432' y='7.84668' width='0.499983' height='3.85363' transform='rotate(-60 15.2432 7.84668)' fill='#333333' />
			<rect x='14' y='11.8701' width='0.499983' height='5.86656' transform='rotate(-60 14 11.8701)' fill='#333333' />
			<rect x='14.7629' y='11.1562' width='0.499983' height='5.56284' transform='rotate(-60 14.7629 11.1562)' fill='#333333' />
			<rect x='14.6663' y='9.94531' width='0.499983' height='5.52801' transform='rotate(-60 14.6663 9.94531)' fill='#333333' />
			<rect x='0.815674' y='14.6509' width='0.499983' height='3.7699' transform='rotate(-60 0.815674 14.6509)' fill='#333333' />
			<rect x='0.330811' y='13.2158' width='0.499983' height='4.90719' transform='rotate(-60 0.330811 13.2158)' fill='#333333' />
			<rect y='11.8701' width='0.499983' height='5.86656' transform='rotate(-60 0 11.8701)' fill='#333333' />
			<rect x='1.97437' y='11.8555' width='0.499983' height='4.16393' transform='rotate(-60 1.97437 11.8555)' fill='#333333' />
			<path
				d='M19.4479 0H14.8034C14.5633 0 14.3653 0.190614 14.3653 0.421661C14.3653 0.652708 14.5633 0.843321 14.8034 0.843321H18.5298L12.6593 6.57473L8.10514 3.0787C7.93112 2.91119 7.65508 2.91119 7.48706 3.0787L1.13052 9.27798C0.956495 9.44549 0.956495 9.71119 1.13052 9.8787C1.21453 9.95957 1.32854 10 1.44256 10C1.55657 10 1.66458 9.95957 1.75459 9.8787L7.7991 3.97978L12.3533 7.47581C12.5273 7.64332 12.8034 7.64332 12.9714 7.47581L19.1239 1.47292V5.01949C19.1239 5.25054 19.3219 5.44116 19.5619 5.44116C19.802 5.44116 20 5.25054 20 5.01949V0.54296C20 0.248376 19.748 0.00577603 19.4419 0.00577603L19.4479 0Z'
				fill='#333333'
			/>
		</svg>
	);
}
