import styled from 'styled-components';

import { ButtonContainer, CancelButton } from 'components/Lists/ListsContainer.style';
import { Flex } from 'styles/utils/UtilityClasses';

const Wrapper = styled.section`
	${Flex};
	width: 100%;
	scroll-behavior: smooth;
	overflow: hidden;
`;

const Styled = {
	Wrapper,
	ButtonContainer,
	CancelButton,
};

export default Styled;
