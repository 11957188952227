import { useEffect } from 'react';

import ContentBody from 'components/DataLibrary/ContentBody/ContentBody';
import ContentHeader from 'components/DataLibrary/ContentHeader/ContentHeader';
import FilterSection from 'components/DataLibrary/FilterSection/FilterSection';
import { UserType } from 'reducers/UserReducers/types';
import Grid from 'styles/grid/grid';
import Styled from 'views/DataLibrary/DataLibrary.style';
import { useAppDispatch } from 'views/DataLibrary/hooks';
import { clearDashboardFilter } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { clearDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { setShowFilter } from 'views/DataLibrary/reducers/FilterSlice';

/**
 * Main component with 3 column design
 * @returns {JSX.Element}
 */
const DataLibrary = (props: { user: UserType }): JSX.Element => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearDashboard());
			dispatch(clearDashboardFilter());
			dispatch(setShowFilter(false));
		};
	}, []);

	return (
		<Grid.Container gap={'0'}>
			<Styled.CustomColumn md={12} xl={12}>
				<Styled.ContentContainer>
					<ContentHeader user={props.user} />
					<ContentBody />
				</Styled.ContentContainer>
			</Styled.CustomColumn>
			<FilterSection />
		</Grid.Container>
	);
};
export default DataLibrary;
