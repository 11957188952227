import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgStats(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_7447_32216)'>
				<path
					d='M4.53965 10.8602C5.17965 10.8602 5.70965 10.3302 5.70965 9.68023C5.70965 9.52024 5.67965 9.37023 5.61965 9.23023L6.98965 7.84023C7.16965 7.95023 7.36965 8.03023 7.58965 8.03023C7.82965 8.03023 8.03965 7.94023 8.22965 7.81023L10.2896 10.3602C10.2696 10.4502 10.2396 10.5302 10.2396 10.6202C10.2396 11.2702 10.7596 11.8002 11.4096 11.8002C12.0596 11.8002 12.5796 11.2702 12.5796 10.6202C12.5796 10.4902 12.5496 10.3802 12.5096 10.2702L15.2596 6.81023C15.3296 6.82023 15.3996 6.85023 15.4796 6.85023C16.1196 6.85023 16.6496 6.32023 16.6496 5.67023C16.6496 5.02023 16.1296 4.49023 15.4796 4.49023C14.8296 4.49023 14.3096 5.02023 14.3096 5.67023C14.3096 5.91023 14.3996 6.13023 14.5296 6.31023L11.9396 9.58023C11.7796 9.50023 11.6096 9.44023 11.4196 9.44023C11.1996 9.44023 11.0096 9.52023 10.8396 9.62023L8.73965 7.02023C8.73965 6.96023 8.77965 6.90023 8.77965 6.84023C8.77965 6.19023 8.25965 5.66023 7.60965 5.66023C6.95965 5.66023 6.43965 6.19023 6.43965 6.84023C6.43965 6.93023 6.46965 7.01023 6.48965 7.09023L5.01965 8.59023C4.87965 8.53023 4.71965 8.49023 4.55965 8.49023C3.91965 8.49023 3.38965 9.02023 3.38965 9.67023C3.38965 10.3202 3.90965 10.8502 4.55965 10.8502L4.53965 10.8602Z'
					fill='#333333'
				/>
				<path
					d='M19.8 1.2H10.5V0.5C10.5 0.22 10.28 0 10 0C9.72 0 9.5 0.23 9.5 0.5V1.2H0.2C0.09 1.2 0 1.29 0 1.4V14.89C0 15 0.09 15.09 0.2 15.09H9.5V15.88L6.27 19.14C6.07 19.34 6.07 19.66 6.27 19.85C6.37 19.95 6.5 20 6.62 20C6.74 20 6.88 19.95 6.97 19.85L9.49 17.3V17.86C9.49 18.14 9.71 18.36 9.99 18.36C10.27 18.36 10.49 18.13 10.49 17.86V17.46L12.77 19.76C12.97 19.96 13.28 19.96 13.48 19.76C13.68 19.56 13.68 19.24 13.48 19.05L10.49 16.04V15.09H19.79C19.9 15.09 19.99 15 19.99 14.89V1.4C19.99 1.29 19.9 1.2 19.79 1.2H19.8ZM19 14.08H1V2.21H19V14.08Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_7447_32216'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
