import { useContext, useState, useCallback, memo, useEffect } from 'react';

import MessageContent from 'components/Discovery/Components/MessageContent/MessageContent';
import useIntegratedInboxData from 'components/IntegratedInbox/hooks';
import { SideDrawer } from 'components/SideDrawer';
import DiscoveryContext from 'contexts/Discovery';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import IntegratedInboxService from 'services/IntegratedInbox/IntegratedInbox.service';
import toast from 'services/Toast';

import { MessageModalProps } from './types';

const MessageModal = memo(({ open, onClose, influencer, isList, isOnCampaign }: MessageModalProps) => {
	const { messageTargetInfluencer } = useContext(DiscoveryContext);
	const { getAllConversations } = useIntegratedInboxData();
	const { mutation: conversationsMutation } = getAllConversations();
	const [message, setMessage] = useState('');
	const [subject, setSubject] = useState<string | null>(null);
	const [publisherId, setPublisherId] = useState<string | null>(null);

	const [isSending, setIsSending] = useState(false);

	/**
	 * POST /influencers/{id}/conversation-messages requires influencer id (not collabsId or userId)
	 * but messageTargetInfluencer's id is collabsId because discovery view uses discovery api.
	 * So when the user sends the message, it will send the request to search the influencer id with collabsId first
	 * and then call createNewConversation method to send the message
	 */
	const sendIntegratedInboxMessage = useCallback(
		async (subject: string | null, message: string, publisherId?: string | null): Promise<boolean> => {
			setIsSending(true); // Start the spinner

			try {
				let influencerId = influencer?.id;

				// Get influencer ID if required
				if (!influencerId && messageTargetInfluencer) {
					const influencerData = await IntegratedInboxService.getInfluencer(messageTargetInfluencer.id);
					influencerId = influencerData.id;
				}

				// Ensure influencer ID is available
				if (!influencerId) {
					throw new Error('Influencer ID is missing');
				}

				// Send the message
				await IntegratedInboxService.createDirectMessage({ message, subject, influencerId, publisherId });

				toast.success(`Message has been sent`);
				onClose();
				conversationsMutation.refresh();

				return true; // Success
			} catch (error) {
				console.error(error);
				toast.error(getErrorMessageOnPost('sending this message'));
				return false; // Failure
			} finally {
				setIsSending(false); // Always stop the spinner
			}
		},
		[influencer, messageTargetInfluencer, conversationsMutation, isList, isOnCampaign],
	);

	useEffect(() => {
		return () => {
			setIsSending(false);
		};
	}, []);

	return (
		<SideDrawer
			sidebarIsOpen={open}
			onClose={onClose}
			dataTestId={''}
			title='Private message'
			isExpandable
			isSubmitting={isSending}
			isDisabled={message.trim().length === 0}
			saveButtonText='Send'
			onSave={() => sendIntegratedInboxMessage(subject, message, publisherId)}
		>
			<MessageContent
				onSend={sendIntegratedInboxMessage}
				onCloseModal={onClose}
				messageTargetInfluencer={messageTargetInfluencer ?? undefined}
				influencer={influencer}
				isList={isList || isOnCampaign}
				getMessage={(subject, message, publisherId) => {
					setMessage(message);
					setSubject(subject);
					setPublisherId(publisherId);
				}}
			/>
		</SideDrawer>
	);
});

export default MessageModal;
