import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 24 24',
};

export function SvgChevronUp(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g id='Group_3025' data-name='Group 3025' transform='translate(412 632) rotate(180)'>
				<rect id='Rectangle_1876' data-name='Rectangle 1876' className='cls-1' width='24' height='24' transform='translate(388 608)' fill='none' />
				<path
					id='Path_2254'
					data-name='Path 2254'
					className='cls-2'
					d='M12.014,17.257,4.589,9.832,5.3,9.125l6.718,6.718,6.718-6.718.707.707Z'
					transform='translate(387.986 606.875)'
					fill='#333'
				/>
			</g>
		</svg>
	);
}
