import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	width: 100%;
	margin: 0 24px 0;
	border-top: 1px solid #333333;
	height: 73px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2rem 0;
	a,
	p {
		font-size: 0.75rem;
		color: ${colors.mainBlack};
		text-decoration: none;
		border-bottom: none !important;
		height: 12px;
		margin-top: 0;
		${mediaQueries.medium`
			margin: 0 48px 0 0;
		`}
	}
	p {
		${mediaQueries.medium`
			margin-bottom: 5px;
		`}
	}

	${mediaQueries.medium`
		justify-content: initial;
	`}
`;

const Styled = {
	Wrapper,
};

export default Styled;
