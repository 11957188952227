import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export const SvgCircleCross = (props: ISvgProps) => {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...svgProps}>
			<path
				id='Union'
				fillRule='evenodd'
				d='M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM14.5298 5.46941C14.8227 5.7623 14.8227 6.23718 14.5298 6.53007L11.0609 9.99897L14.5308 13.4689C14.8237 13.7618 14.8237 14.2367 14.5308 14.5296C14.238 14.8225 13.7631 14.8225 13.4702 14.5296L10.0003 11.0596L6.53032 14.5296C6.23743 14.8225 5.76255 14.8225 5.46966 14.5296C5.17677 14.2367 5.17677 13.7618 5.46966 13.4689L8.93959 9.99897L5.47069 6.53006C5.1778 6.23717 5.1778 5.7623 5.47069 5.4694C5.76358 5.17651 6.23846 5.17651 6.53135 5.4694L10.0003 8.93831L13.4692 5.46941C13.762 5.17652 14.2369 5.17652 14.5298 5.46941Z'
				fill='#333333'
			/>
		</svg>
	);
};
