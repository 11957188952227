import { useEffect, useState } from 'react';

import useNumberGrowEffect from 'hooks/Chart/useNumberGrowEffect';

import { INumber } from './types';

/**
 *
 * @param props
 * @returns JSX.Element
 */
const Number = (props: INumber) => {
	const [valueToDisplay, setValueToDisplay] = useState<number | null | string>(null);
	const { withoutFormat, displayRealValue, value, isAmount } = props;
	const numberGrowing = useNumberGrowEffect(withoutFormat);

	useEffect(() => {
		const compare = isAmount ? withoutFormat / 100 : withoutFormat;
		if (compare > numberGrowing) {
			setValueToDisplay(numberGrowing);
		} else {
			setValueToDisplay(value);
		}
	}, [numberGrowing]);

	useEffect(() => {
		return () => {
			setValueToDisplay(null);
		};
	}, []);

	return <h5>{displayRealValue ? withoutFormat : valueToDisplay}</h5>;
};

export default Number;
