import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgAMCampaign(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_2740_64732)'>
				<path
					d='M19.0699 5.1499H11.3299C10.9299 5.1499 10.5999 5.4799 10.5999 5.8799C10.5999 6.2799 10.9299 6.6099 11.3299 6.6099H17.5399L11.0899 13.0699L8.49993 10.4799C8.20993 10.1899 7.74993 10.1899 7.46993 10.4799L0.209932 17.7499C-0.0800683 18.0399 -0.0800683 18.4999 0.209932 18.7899C0.349932 18.9299 0.539932 18.9999 0.729932 18.9999C0.919932 18.9999 1.09993 18.9299 1.24993 18.7899L7.98993 12.0399L10.5799 14.6299C10.8699 14.9199 11.3299 14.9199 11.6099 14.6299L18.5299 7.6999V13.8399C18.5299 14.2399 18.8599 14.5699 19.2599 14.5699C19.6599 14.5699 19.9899 14.2399 19.9899 13.8399V6.0899C19.9899 5.5799 19.5699 5.1599 19.0599 5.1599L19.0699 5.1499Z'
					fill='#333333'
				/>
				<path
					d='M0.8 7.69C1.05 7.85 1.33 7.96 1.65 8.04C1.86 8.09 2.07 8.12 2.29 8.14V9.27H3.02V8.13C3.24 8.11 3.46 8.11 3.66 8.06C3.98 7.99 4.27 7.88 4.52 7.73C4.78 7.58 4.98 7.39 5.13 7.16C5.28 6.93 5.35 6.65 5.35 6.32C5.35 6.01 5.28 5.75 5.14 5.54C5 5.33 4.81 5.16 4.58 5.03C4.35 4.9 4.09 4.79 3.81 4.72C3.54 4.64 3.28 4.59 3.02 4.54V3.22C3.08 3.23 3.15 3.22 3.22 3.25C3.39 3.3 3.53 3.38 3.64 3.49C3.75 3.6 3.8 3.74 3.8 3.91V3.98H5.26V3.89C5.26 3.49 5.14 3.16 4.9 2.88C4.66 2.6 4.34 2.39 3.95 2.24C3.66 2.13 3.35 2.07 3.02 2.04V1H2.29V2.02C1.97 2.05 1.66 2.1 1.37 2.2C0.98 2.34 0.66 2.54 0.43 2.81C0.19 3.08 0.08 3.41 0.08 3.8C0.08 4.1 0.15 4.35 0.28 4.55C0.42 4.76 0.6 4.93 0.82 5.07C1.05 5.21 1.3 5.32 1.58 5.4C1.81 5.47 2.05 5.52 2.29 5.56V6.97C2.1 6.94 1.93 6.9 1.8 6.82C1.58 6.7 1.47 6.52 1.47 6.27V6.17H0V6.3C0 6.6 0.07 6.87 0.21 7.11C0.35 7.34 0.55 7.54 0.79 7.7L0.8 7.69ZM3.56 5.86C3.8 5.96 3.92 6.13 3.92 6.36C3.92 6.51 3.86 6.63 3.75 6.72C3.64 6.81 3.49 6.88 3.3 6.93C3.21 6.95 3.11 6.95 3.02 6.96V5.7C3.24 5.75 3.42 5.8 3.56 5.86ZM2.23 4.35C2.01 4.29 1.84 4.21 1.72 4.12C1.6 4.03 1.54 3.9 1.54 3.76C1.54 3.62 1.6 3.48 1.72 3.39C1.84 3.3 1.99 3.24 2.16 3.2C2.21 3.2 2.25 3.2 2.3 3.19V4.37C2.3 4.37 2.25 4.37 2.23 4.36V4.35Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2740_64732'>
					<rect width='20' height='18' fill='white' transform='translate(0 1)' />
				</clipPath>
			</defs>
		</svg>
	);
}
