import { FormikErrors } from 'formik/dist/types';
import moment from 'moment';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';

import Calendar from 'components/Calendar/DateRangeCalendar';
import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import { Scenario } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import Field from 'components/Forms/Field';
import Grid from 'styles/grid/grid';

type SelectDateProps = {
	selectedDate?: DateRange;
	setSelectedDate: (date?: DateRange) => void;
	profileConnected: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>;
	scenario: Scenario | undefined;
	disabled?: boolean;
	campaignStartDate: string;
	isStory?: boolean;
};
/**
 * @returns {JSX.Element}
 */
const SelectDate = ({
	setSelectedDate,
	profileConnected,
	setFieldValue,
	selectedDate,
	scenario,
	disabled,
	campaignStartDate,
	isStory,
}: SelectDateProps): JSX.Element => {
	const renderHelpText = () => {
		switch (scenario) {
			case Scenario.MANUAL_UPLOAD_UPDATE:
			case Scenario.MANUAL_UPLOAD_SAVE: {
				if (isStory) {
					return 'Add the date or date range of posting.';
				} else {
					return 'Add the date of posting.';
				}
			}
			case Scenario.STORY_SELECTOR: {
				return 'Find and select your Story frames by adding the date or date range of posting.';
			}
			default:
				return '';
		}
	};
	return (
		<Styled.DateSection>
			{!disabled && (
				<Styled.DateTextWrapper>
					<Styled.Heading>
						<div>
							<h5>Select date</h5>
							<p>{renderHelpText()}</p>
						</div>
					</Styled.Heading>
				</Styled.DateTextWrapper>
			)}
			<Grid.Container>
				<Grid.Column lg={5} md={6} sm={12}>
					<Field label={profileConnected ? 'Date' : 'Date posted'} disabled={disabled}>
						<Calendar
							disabled={disabled}
							defaultValue={selectedDate}
							oneTap={!profileConnected || !isStory}
							placeholder='Posting date'
							onSelectDateRange={(value: [Date, Date]) => {
								setSelectedDate(value);
							}}
							onSelectDate={(value?: DateRange) => {
								setFieldValue('postedAt', value ? moment(value && value[0]).format('YYYY-MM-DD') : undefined), setSelectedDate(value);
							}}
							maxDate={new Date()}
							minDate={new Date(campaignStartDate)}
						/>
					</Field>
				</Grid.Column>
			</Grid.Container>
			<hr />
		</Styled.DateSection>
	);
};

export default SelectDate;
