import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Model } from 'json-api-models';

type EntityDefinition = {
	type: string;
	name: string;
	role: string;
};

export type PermissionsType = {
	entities?: Record<string, EntityDefinition>;
	is_agent: boolean;
	is_impersonating: boolean;
	is_influencer: boolean;
	is_super_admin: boolean;
};

export const initialState: PermissionsType = {
	is_agent: false,
	is_impersonating: false,
	is_influencer: false,
	is_super_admin: false,
};

const UserPermissionsSlice = createSlice({
	name: 'userPermissions',
	initialState,
	reducers: {
		setPermissions(state, action: PayloadAction<Model>) {
			const payload = action.payload;
			state = {
				entities: payload.attributes.permissions.entities,
				is_agent: payload.attributes.permissions.is_agent,
				is_impersonating: payload.attributes.permissions.is_impersonating,
				is_influencer: payload.attributes.permissions.is_influencer,
				is_super_admin: payload.attributes.permissions.is_super_admin,
			};

			return state;
		},
		clearPermissions(state) {
			state = initialState;
			return state;
		},
	},
});

export const { setPermissions, clearPermissions } = UserPermissionsSlice.actions;

export default UserPermissionsSlice.reducer;
