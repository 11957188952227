import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div<{ isOpen: boolean }>`
	width: 100%;
	height: ${(props) => (props.isOpen ? 'fit-content' : '84px')};
	padding: ${guttersWithRem.m};

	background-color: ${colors.mainWhite};

	border: 1px solid ${colors.lightGray};
	border-radius: ${borderRadius.s};

	&.assignments-info {
		margin-bottom: ${guttersWithRem.xs};
	}

	&.products-info {
		margin-bottom: ${guttersWithRem.l};
	}
`;

const InnerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;

	& div > div {
		&.label {
			display: flex;
			align-items: center;
			column-gap: ${guttersWithRem.xs};

			margin-bottom: ${guttersWithRem.xs};
			font-weight: 700;

			& > span {
				&:first-letter {
					text-transform: uppercase;
				}
			}
		}

		& > i {
			display: flex;
			line-height: 0;
		}
	}
`;

const IconWrapper = styled.div`
	cursor: pointer;
	& i {
		line-height: 0;
	}

	&:hover,
	&.isOpen {
		svg {
			border-radius: ${borderRadius.s};
			background-color: ${colors.lightGray};
		}
	}
`;

const Details = styled.div`
	margin-top: ${guttersWithRem.xs};
	display: flex;
	flex-direction: column;
	row-gap: ${guttersWithRem.xs};
`;

const DetailItemWrapper = styled.div`
	width: 100%;
	padding: ${guttersWithRem.m};
	border: 1px solid ${colors.influencerDashboard.campaign.border};
	border-radius: 2px;

	& > div {
		&.title {
			font-weight: 500;
		}

		&.date,
		&.value {
			color: ${colors.influencerDashboard.campaign.assignmentDate};
		}
	}
`;

const ProductDetailInnerWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	& > div {
		&.title {
			font-weight: 500;
		}
	}
`;

const Styled = {
	Wrapper,
	InnerWrapper,
	IconWrapper,
	Details,
	DetailItemWrapper,
	ProductDetailInnerWrapper,
};

export default Styled;
