import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import Icon from 'components/Icon';

import Styled from './Navigation.style';

type MenuItem = {
	icon: string;
	title: string | JSX.Element;
	url: string;
	disabled: boolean;
	testId: string;
};

export type NavigationProps = {
	testId?: string;
	Menu: Array<MenuItem | undefined>;
};

/**
 * Left side page navigation.
 * @returns {JSX.Element}
 */
const Navigation = ({ testId, Menu }: NavigationProps): JSX.Element => {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<Styled.NavigationWrapper>
			<Styled.Navigation data-testid={testId}>
				{Menu.map((item: MenuItem | undefined, i: number) => {
					if (item) {
						return (
							<li
								data-testid={item.testId}
								key={i}
								onClick={() => {
									!item.disabled && navigate(item.url);
								}}
								className={classNames({ selected: location.pathname === item.url, disabled: item.disabled })}
							>
								<Icon name={item.icon} />
								<p>{item.title}</p>
							</li>
						);
					}
				})}
			</Styled.Navigation>
		</Styled.NavigationWrapper>
	);
};

export default Navigation;
