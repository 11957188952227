import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgUrlLink(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.1713 0.599467L19.3972 3.82855V3.83957C20.1899 4.62205 20.2009 5.88944 19.4302 6.66089L14.685 11.4108C13.9143 12.1823 12.6482 12.1603 11.8665 11.3778L11.4151 10.9259L12.6592 9.68058L13.0115 10.0332C13.1216 10.1435 13.2978 10.1435 13.4079 10.0332L18.065 5.37146C18.1751 5.26125 18.1751 5.08492 18.065 4.97471L14.9602 1.86686C14.8502 1.75665 14.674 1.75665 14.5639 1.86686L9.90676 6.52864C9.79666 6.63885 9.79666 6.81518 9.90676 6.92539L10.3251 7.34418L9.08103 8.58953L8.62963 8.13768C7.84793 7.3552 7.82591 6.09883 8.5966 5.32738L13.3528 0.566405C14.1235 -0.205049 15.3896 -0.183008 16.1713 0.599467ZM6.87948 13.9016L13.8817 6.8924C14.1019 6.67198 14.1019 6.33034 13.8817 6.10992C13.6615 5.88951 13.3202 5.88951 13.1 6.10992L6.09779 13.1191C5.87759 13.3395 5.87759 13.6812 6.09779 13.9016C6.31799 14.122 6.65929 14.122 6.87948 13.9016ZM11.3705 11.8625L10.9191 11.4106L9.67498 12.656L10.0934 13.0748C10.2035 13.185 10.2035 13.3613 10.0934 13.4715L5.42521 18.1443C5.31511 18.2545 5.13896 18.2545 5.02886 18.1443L1.9241 15.0365C1.814 14.9263 1.814 14.7499 1.9241 14.6397L6.58124 9.97794C6.69133 9.86773 6.86749 9.86773 6.97759 9.97794L7.3299 10.3306L8.57401 9.08525L8.12261 8.6334C7.34091 7.85093 6.07479 7.82889 5.3041 8.60034L0.569898 13.3503C-0.200787 14.1217 -0.189777 15.3891 0.602927 16.1716L3.82879 19.4007C4.61049 20.1832 5.87661 20.2052 6.6473 19.4338L11.4035 14.6728C12.1742 13.9013 12.1522 12.645 11.3705 11.8625Z'
				fill='#333333'
			/>
		</svg>
	);
}
