import { Model } from 'json-api-models';

import Summary from 'components/ContentManagement/Components/Views/Summary';
import { CampaignItemType } from 'components/ContentManagement/types';

type SummaryProps = {
	isActive: boolean;
	activeCampaign: CampaignItemType;
	selectedCIO: Model;
	CIOAS: Model[];
	refresh: () => Promise<void>;
};

const SummaryView = ({ isActive, activeCampaign, selectedCIO, CIOAS, refresh }: SummaryProps) => {
	if (!isActive) {
		return <></>;
	}
	return <Summary activeCampaign={activeCampaign} selectedCIO={selectedCIO} CIOAS={CIOAS} refresh={refresh} />;
};

export default SummaryView;
