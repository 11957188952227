import axios, { AxiosError } from 'axios';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { SummaryQuery, UpdateDashboardQuery } from 'api-queries';
import { Button, DarkButton } from 'components/Button';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import convertDashboardModelToDashboardType from 'components/DataLibrary/ConvertDashboardModelToDashboardType';
import ExplanationAccordition from 'components/DataLibrary/Graphs/GraphComponents/ExplanationAccordion';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import { CALCULATION_FORMULA } from 'constants/data-library';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import toast from 'services/Toast';
import { formatNumber, formatPercent } from 'shared/helpers/Chart/chart-util';
import Grid from 'styles/grid/grid';
import { formatAmount } from 'utils/formatters';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import { useAppSelector } from 'views//DataLibrary/hooks';
import { setDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';
import { store } from 'views/DataLibrary/store';

import Styled from './Summary.style';

interface StatisticsSummary {
	influencers: number;
	followers: number;
	mediaObjects: number;
	assignments: number;
	platforms: string[];
	impressions: number;
	grossReach: number;
	estimatedUniqueReach: number;
	engagements: number;
	reachRate: number;
	engagementRate: number;
	actualEngagementRate: number;
	clicks: number;
	conversions: number;
	invoicedAmount: string;
	currency: string;
	costPerReach: number;
	viewThroughRate: number;
	clickThroughRate: number;
	costPerEngagement: number;
	uniqueInfluencers: number;
}

type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T][];
type Entry<T> = { [K in keyof T]: [K, T[K]] }[keyof T];

const updateInfluencersValue = (dashboard: DashboardType, data: Entries<StatisticsSummary>): Entries<StatisticsSummary> => {
	const summary = [...data];
	for (let i = 0; i < summary.length; i++) {
		if (summary[i][0] === 'influencers') {
			summary[i][1] = dashboard.uniqueInfluencers ?? summary[i][1];
			break;
		}
	}

	return summary;
};

/**
 * This component displays the summaryIcons without the toggle in the dashboard preview.
 * @returns {JSX.Element}
 */
const Summary = (props: { inDataLibrary?: boolean }): JSX.Element => {
	const [paidDataIncluded, setPaidDataIncluded] = useState<boolean>(false);
	const [displayRealValue, setDisplayRealValue] = useState<boolean>(false);
	const [selectedValue, setSelectedValue] = useState<number | null>(null);

	const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
	const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false);

	const [activeItem, setActiveItem] = useState<{ label: string; value: string }>({ label: '', value: '' });
	const [errorMessages, setErrorMessages] = useState<Array<{ source: { message: string } }>>([]);

	const [displayAll, setDisplayAll] = useState<boolean>(false);

	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);

	const statisticsManager = useInjection<StatisticsManager>(StatisticsManager);
	const dashboardManager = useInjection<DataLibraryManager>(DataLibraryManager);

	const filter = paidDataIncluded ? { ...dashboardFilter, paidMediaDashboardId: dashboard.id } : dashboardFilter;
	const qb = convertFilterToQueryBuilder<SummaryQuery>(filter);

	let summaryData: Entries<StatisticsSummary> | undefined;
	let isLoading = true;

	if (dashboard.id) {
		const { result, error } = statisticsManager.showSummary(qb);
		if (error) {
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			const excludedItems = ['currency', 'mediaObjects', 'engagementRate', 'invoicedAmount'];
			const data = Object.entries(result.attributes) as Entries<StatisticsSummary>;
			let currency = null;
			let invoicedAmount = null;

			data?.forEach((summaryItem) => {
				if (summaryItem[0] === 'invoicedAmount') {
					invoicedAmount = summaryItem[1];
				} else if (summaryItem[0] === 'currency') {
					currency = summaryItem[1];
				}
			});

			const tmpData = data?.filter((summaryItem) => !excludedItems.includes(summaryItem[0]));
			if (invoicedAmount && currency) {
				tmpData.push(['invoicedAmount', formatAmount(invoicedAmount, currency)]);
			}

			summaryData = updateInfluencersValue(dashboard, tmpData);
		}
	}

	const onClickUpdateValue = () => {
		setIsLoadingEdit(true);
		const qb = RequestQueryBuilder.create<UpdateDashboardQuery>(['createPaidMediaPresignUrl', 'createDashboardUser', 'createPaidMedia'])
			.withInclude('users')
			.withInclude('dashboardUsers')
			.withInclude('paidMedia');
		dashboardManager
			.update(dashboard.id, { uniqueInfluencers: +activeItem.value }, qb)
			.then((result) => {
				if (result) {
					const { dashboard } = convertDashboardModelToDashboardType(dashboardManager.getRepository(), result);
					store.dispatch(setDashboard(dashboard));
					toast.success(`${getTitle(activeItem.label)} saved`);
					onCloseEditModal();
				}
			})
			.catch((e: Error | AxiosError) => {
				if (axios.isAxiosError(e) && Array.isArray(e.response?.data.errors)) {
					const errors = e.response?.data.errors;
					errors.forEach((error: { source: { message: string } }) => {
						setErrorMessages((prev) => {
							1;
							const uniqueErrors = new Set(prev.map((prevError) => prevError.source.message));
							if (!uniqueErrors.has(error.source.message)) {
								return [...prev, error];
							}
							return prev;
						});
					});
				} else {
					toast.error(getErrorMessageOnPost('updating the value'));
				}
			})
			.finally(() => {
				setIsLoadingEdit(false);
			});
	};

	useEffect(() => {
		if (typeof summaryData !== 'undefined') {
			summaryData = updateInfluencersValue(dashboard, summaryData);
		}
	}, [dashboard.uniqueInfluencers]);

	const getIcon = (item: string): string => {
		switch (item) {
			case 'influencers':
				return 'dl-influencers';
			case 'followers':
				return 'audience';
			case 'platforms':
				return 'list';
			case 'impressions':
				return 'impressions';
			case 'grossReach':
				return 'gross-reach';
			case 'engagements':
				return 'engagement';
			case 'actualEngagementRate':
				return 'reach';
			case 'assignments':
				return 'dl-summary-content';
			case 'clicks':
				return 'click';
			case 'conversions':
				return 'conversions';
			case 'costPerEngagement':
				return 'cpe';
			case 'costPerReach':
				return 'cpr';
			case 'viewThroughRate':
				return 'dl-vtr';
			case 'clickThroughRate':
				return 'click';
			case 'invoicedAmount':
				return 'file';
			case 'reachRate':
				return 'reach-rate';
			default:
				return '';
		}
	};
	const getTitle = (item: string): string => {
		switch (item) {
			case 'influencers':
				return 'Influencers';
			case 'followers':
				return 'Followers';
			case 'platforms':
				return 'Platforms';
			case 'impressions':
				return 'Impressions';
			case 'grossReach':
				return 'Gross reach';
			case 'engagements':
				return 'Engagements/Interactions';
			case 'actualEngagementRate':
				return 'Engagement rate';
			case 'assignments':
				return 'Activations';
			case 'clicks':
				return 'Clicks';
			case 'conversions':
				return 'Conversions';
			case 'costPerEngagement':
				return 'CPE';
			case 'costPerReach':
				return 'CPR';
			case 'viewThroughRate':
				return 'VTR';
			case 'clickThroughRate':
				return 'CTR';
			case 'invoicedAmount':
				return 'Invoiced amount';
			case 'reachRate':
				return 'Reach rate';
			default:
				return '';
		}
	};

	const shouldBeDisplayedInPercent = (title: string) => {
		switch (title) {
			case 'engagementRate':
				return true;
			case 'actualEngagementRate':
				return true;
			case 'reachRate':
				return true;
			case 'clickThroughRate':
				return true;
			case 'viewThroughRate':
				return true;
			default:
				return false;
		}
	};

	// Platforms are returned as array
	// Rates need to be converted to percent.
	const displayValue = (item: Entry<StatisticsSummary>, i: number) => {
		const title = item[0];
		const value = item[1];
		if (Array.isArray(value)) {
			return value?.length > 0 ? value?.length : 0;
		}
		if (shouldBeDisplayedInPercent(title) && Number(value) > 0) {
			return formatPercent(value);
		}
		return displayRealValue && i === selectedValue ? value : formatNumber(value, true);
	};

	const hideValues = (title: string) => {
		switch (title) {
			case 'estimatedUniqueReach':
				return true;
			case 'invoicedAmount':
				return props.inDataLibrary ? false : true;
			case 'costPerReach':
				return props.inDataLibrary ? false : true;
			case 'costPerEngagement':
				return props.inDataLibrary ? false : true;
			case 'conversions':
				return props.inDataLibrary ? false : true;
			default:
				return false;
		}
	};

	const canEdit = (item: Entry<StatisticsSummary>) => {
		const editableItems = ['influencers'];
		return props.inDataLibrary && item && editableItems.includes(item[0]);
	};

	const onClickOpenEditModal = (item: Entry<StatisticsSummary>) => {
		setActiveItem({
			label: item[0],
			value: item[1].toString(),
		});

		setIsFormModalOpen(true);
	};

	const onCloseEditModal = () => {
		setActiveItem({ label: '', value: '0' });
		setIsFormModalOpen(false);
		setErrorMessages([]);
	};

	return (
		<Styled.SummaryContainer>
			<Styled.Wrapper className={classNames({ collapsed: !displayAll, inPublic: !props.inDataLibrary })} aria-expanded={displayAll || !props.inDataLibrary}>
				<Styled.Header>
					<Styled.Title>Summary</Styled.Title>
					{dashboard?.hasPaidMedia && (
						<Styled.ToggleWrapper>
							<p>Paid media {paidDataIncluded ? 'included' : 'excluded'}</p>
							<Styled.ToggleSwitch>
								<input
									type='checkbox'
									className={classNames({ checked: paidDataIncluded })}
									checked={paidDataIncluded}
									onChange={() => setPaidDataIncluded(!paidDataIncluded)}
								/>
								<Styled.Slider className={classNames({ checked: paidDataIncluded })} />
							</Styled.ToggleSwitch>
						</Styled.ToggleWrapper>
					)}
				</Styled.Header>
				<Styled.Body>
					{typeof summaryData === 'undefined' || summaryData.length < 1 ? (
						<Styled.SpinnerWrapper>
							<LoadingSpinner />
						</Styled.SpinnerWrapper>
					) : (
						<Grid.Container>
							{summaryData.map((item: Entry<StatisticsSummary>, i: number) => {
								const title = item[0];
								const value = item[1];
								if (hideValues(title)) {
									return;
								}
								const hasValue =
									(Array.isArray(value) && value?.length > 0) ||
									(!Array.isArray(value) && Number(value) > 0) ||
									(typeof value === 'string' && value?.length > 0);
								return (
									<Grid.Column key={i} xs={6} md={4} lg={4} xxl={props.inDataLibrary ? 4 : 3}>
										<Styled.SummaryContainerItem key={i} className={classNames({ unAvailable: !hasValue })}>
											<Styled.SummaryContainerItemBox>
												{isLoading ? (
													<LoadingSpinner size='sm' />
												) : (
													<div data-testid='item-box-icon-container' className={classNames({ editable: canEdit(item), editing: activeItem.label === title })}>
														<Icon name={getIcon(item[0])} size='20' />
														{canEdit(item) && (
															<Styled.EditButtonContainer>
																<button data-testid='edit-value-button' aria-label='Edit value' title='Edit' onClick={() => onClickOpenEditModal(item)}>
																	<Icon name='pen' size='20' />
																</button>
															</Styled.EditButtonContainer>
														)}
													</div>
												)}
											</Styled.SummaryContainerItemBox>
											<Styled.SummaryContainerItemData>
												<span>{getTitle(title)}</span>
												<div
													onMouseEnter={() => {
														setSelectedValue(i), setDisplayRealValue(true);
													}}
													onMouseLeave={() => {
														setSelectedValue(null), setDisplayRealValue(false);
													}}
												>
													<span data-testid={`${title}-value`}>{displayValue(item, i)}</span>
												</div>
											</Styled.SummaryContainerItemData>
										</Styled.SummaryContainerItem>
									</Grid.Column>
								);
							})}
						</Grid.Container>
					)}
				</Styled.Body>
				<ExplanationAccordition
					className='summary-explanation'
					chartExplanation={{
						formula: (
							<>
								<div>- Engagement : {CALCULATION_FORMULA.ENGAGEMENT_TOTAL}</div>
								<div>- CTR : {CALCULATION_FORMULA.CTR}</div>
								<div>- CPE : {CALCULATION_FORMULA.CPE}</div>
								<div>- Reach Rate: {CALCULATION_FORMULA.SUMMARY.REACH_RATE}</div>
								<div>- Engagement Rate: {CALCULATION_FORMULA.SUMMARY.ACTUAL_ENGAGEMENT_RATE}</div>
							</>
						),
					}}
				/>
			</Styled.Wrapper>

			{props.inDataLibrary ? (
				<Styled.SummaryToggle>
					<Styled.SummarToggleButton className={classNames({ expand: displayAll })} onClick={() => setDisplayAll(!displayAll)}>
						<span>
							{displayAll ? 'See less' : 'See all'} <Icon name='arrow-down' size='12' />
						</span>
					</Styled.SummarToggleButton>
				</Styled.SummaryToggle>
			) : null}

			<Styled.CustomModal open={isFormModalOpen} size='xs' handleClose={onCloseEditModal}>
				<Styled.EditModalForm data-testid='edit-modal'>
					<label htmlFor='edit-value'>
						{' '}
						{getTitle(activeItem.label)}
						<Styled.TextField
							type='number'
							id='edit-value'
							min={0}
							value={activeItem.value}
							onChange={(e) => setActiveItem((prevState) => ({ ...prevState, value: e.target.value }))}
						/>
					</label>
					{errorMessages.length > 0 && (
						<Styled.ErrorMessages>
							{errorMessages.map((e, index: number) => (
								<Styled.ErrorMessageItem key={index}>{e.source.message}</Styled.ErrorMessageItem>
							))}
						</Styled.ErrorMessages>
					)}
					<Styled.ButtonGroup>
						<Button size='sm' onClick={() => onCloseEditModal()} disabled={isLoadingEdit}>
							Cancel
						</Button>
						<DarkButton size='sm' onClick={onClickUpdateValue} disabled={isLoadingEdit}>
							Save
						</DarkButton>
					</Styled.ButtonGroup>
				</Styled.EditModalForm>
			</Styled.CustomModal>
		</Styled.SummaryContainer>
	);
};

export default Summary;
