import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState = {
	isFetching: false,
};

const fetchingDashbaordsSlice = createSlice({
	name: 'isFetching',
	initialState,
	reducers: {
		IsFetchingDashboards(state, action: PayloadAction<boolean>) {
			const payload = action.payload;
			state.isFetching = payload;
			return state;
		},
	},
});

export const { IsFetchingDashboards } = fetchingDashbaordsSlice.actions;
export default fetchingDashbaordsSlice.reducer;
