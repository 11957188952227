import styled from 'styled-components';

import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const HeadingContainer = styled.div`
	position: relative;
`;

const Heading = styled.h3<{ isActive: boolean }>`
	display: block;
	width: 100%;
	font-size: 1.2rem;
	color: ${(props) =>
		({ theme }) =>
			!props.isActive ? theme.TogglePanel.HeadingColor : theme.TogglePanel.active.HeadingColor};
	margin-bottom: 0.625rem;
	cursor: pointer;
	transition: color 0.2s ease-in-out;

	&:hover {
		color: ${({ theme }) => theme.TogglePanel.hover.HeadingColor};
	}

	& > span {
		&.required {
			font-size: 0.625rem;
			color: ${colors.red};
			margin-left: ${guttersWithRem.xxs};
			font-weight: 500;
		}
	}
`;

const ComponentContainer = styled.div`
	max-height: 0;
	transition:
		max-height 0.2s ease-out,
		opacity 0.2s ease-out;
	overflow: hidden;
	opacity: 0;
`;

const Tooltip = styled.div`
	position: absolute;
	top: 1px;
	right: 0;
	cursor: pointer;
`;

const Wrapper = styled.div`
	position: relative;
	padding-bottom: 1rem;
	cursor: pointer;

	&.selected {
		${ComponentContainer} {
			max-height: 500px;
			${scrollbarY};
			opacity: 1;
			transition: max-height 0.2s ease-in;
		}
	}
`;

const Styled = {
	Wrapper,
	HeadingContainer,
	Heading,
	ComponentContainer,
	Tooltip,
};

export default Styled;
