import { InfluencerTotalQuery } from 'api-queries';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import { ByInfluencerOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByInfluencer/types';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

export const getInfluencerTotal = (manager: StatisticsManager, filter: DashboardFilters): { result: ByInfluencerOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<InfluencerTotalQuery>(filter);
	const { result, error } = manager.listInfluencerTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	return {
		result: { influencers: result },
	};
};
