import { useEffect, useState } from 'react';

import { getGraphColors } from 'components/DataLibrary/AllRequests/GraphColors';
import Styled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { formatNumber } from 'shared/helpers/Chart/chart-util';

import { BarItem, Bar } from './BarItem';

const BarChart = (props: { items: Array<{ label: string; value: number }>; total: number; sign?: number | string; gridTemplateColumns?: string }) => {
	const [displayTopThree, setDisplayTopThree] = useState<boolean>(true);
	const [sortedData, setSortedData] = useState<Array<{ label: string; value: number; containerColor: string; fillerColor: string }>>([]);

	useEffect(() => {
		let dataToDisplay = [];
		if (displayTopThree) {
			dataToDisplay = props.items.slice(0, 3);
		} else {
			dataToDisplay = props.items;
		}

		const newSortedData = dataToDisplay
			.sort((a: { value: number }, b: { value: number }) => b.value - a.value)
			.filter((item: { value: number }) => item.value / props.total > 0.01)
			.slice(0, 10);
		const dataWithColorAndSorting = newSortedData.map((item: { label: string; value: number }, index: number) => {
			return { ...item, ...getGraphColors(index) };
		});
		setSortedData(dataWithColorAndSorting);
	}, [props.items, displayTopThree]);

	useEffect(() => {
		return () => {
			setDisplayTopThree(true);
			setSortedData([]);
		};
	}, []);

	return (
		<>
			{sortedData?.length > 0 ? (
				sortedData.map((item: { label: string; value: number; containerColor: string; fillerColor: string }) => {
					const percent = `${Math.floor((item.value / props.total) * 100)}%`;
					let value: string | number = 0;
					if (props.sign === 'K') {
						value = Math.floor(item.value / 1000);
					} else if (props.sign === '%') {
						value = Math.floor((item.value / props.total) * 100);
					} else {
						value = formatNumber(item.value, true);
					}

					const label = item.label?.length > 25 ? item.label.slice(0, 25 - 1) + '...' : item.label;

					return (
						<BarItem gridTemplateColumns={props.gridTemplateColumns} label={label} value={value} sign={props.sign} key={label}>
							<Bar percent={percent} containerBg={item.containerColor} fillerBg={item.fillerColor} />
						</BarItem>
					);
				})
			) : (
				<Styled.InnerCenteredWrapper>
					<span>N/A</span>
				</Styled.InnerCenteredWrapper>
			)}
			{props.items?.length > 3 && (
				<Styled.SeeAll
					onClick={() => {
						setDisplayTopThree(!displayTopThree);
					}}
				>
					{displayTopThree ? 'see all' : 'see less'}
				</Styled.SeeAll>
			)}
		</>
	);
};

export default BarChart;
