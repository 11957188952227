import styled from 'styled-components';

import { LinkButton } from 'components/Button';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing[1].px};
`;

const CustomLinkButton = styled(LinkButton)`
	gap: 4px;
	border: none;
	justify-content: start;
	width: fit-content;
	& span {
		position: relative;
		&:after {
			position: absolute;
			left: 0;
			bottom: -2px;
			content: '';
			height: 1px;
			width: 100%;
			background-color: ${colors.integratedInbox.detailSection.campaignInfo.card.border};
			opacity: 0;
			transition: opacity 0.2s ease-in-out;
		}
	}
	&:hover {
		span:after {
			opacity: 1;
		}
	}
`;

const SpinnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const Styled = {
	Wrapper,
	CustomLinkButton,
	SpinnerWrapper,
};

export default Styled;
