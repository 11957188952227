import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgPlatformToggle(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg fill='none' {...svgProps}>
			<path
				d='M18.0588 0.335344L0.335344 18.0588C-0.381022 18.7751 0.126337 20 1.13943 20H18.8628C19.4909 20 20 19.4909 20 18.8628V1.13943C20 0.126337 18.7751 -0.381022 18.0588 0.335344Z'
				fill='#333333'
			/>
		</svg>
	);
}
