import classNames from 'classnames';
import { Model } from 'json-api-models';
import moment from 'moment';
import { RefObject, useEffect, useState } from 'react';

import ChatBubble from 'components/IntegratedInbox/Components/ChatBubble';
import { MessageItem } from 'components/IntegratedInbox/Components/ChatMessages/MessageItem';
import { customFormatDate } from 'components/IntegratedInbox/Components/ChatMessages/types';
import { FileItem } from 'components/IntegratedInbox/types';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { UserType } from 'reducers/UserReducers/types';

import Styled from './ChatMessages.style';

type Props = {
	publisher?: Model;
	messages: Model[];
	onRead: (conversationMessageId: string) => Promise<void>;
	tempValue: { message: string; files: Array<FileItem> };
	messagesRef: RefObject<HTMLDivElement>;
	messageEndRef: RefObject<HTMLDivElement>;
	onScrollToBottom: () => void;
	isInfluencer?: boolean;
	fileUploadProgress: { [key: string]: number };
};

/**
 * @todo seen mark
 */
const ChatMessages = ({ publisher, messages, onRead, tempValue, messagesRef, messageEndRef, onScrollToBottom, isInfluencer, fileUploadProgress }: Props) => {
	const [messageItems, setMessageItems] = useState<MessageItem[]>([]);
	const [tempMessage, setTempMessage] = useState<{ message: string; files: Array<FileItem> }>(tempValue);
	const [uniqueDates, setUniqueDates] = useState<string[]>([]);

	const user = useAppSelector((state) => state.user);
	const selectedProfileId = user.influencer.id;

	const { userLastMessage, influencerLastMessage } = getSendersLastMessage(messageItems);
	const dateMessagesObjects: { [key: string]: MessageItem[] } = {};

	uniqueDates.forEach((date) => {
		dateMessagesObjects[date] = messageItems.filter((message) => date === formatDateDDMMMYYYY(message.createdAt));
	});

	const isMessageFromCurrentUser = (message: MessageItem, user: UserType) => {
		if (message.senderInfo?.type === 'influencer') {
			return user?.influencer?.id === message.senderInfo?.id;
		} else {
			return user?.id === message.senderInfo?.id;
		}
	};

	const markAdReadHandler = (message: MessageItem) => {
		const isInfluencerMessage = !!message.messageModel.influencer;
		const isUserMessage = !!message.messageModel.user;
		if (!user.permissions.isInfluencer && isInfluencerMessage) {
			onRead(message.id);
		} else if (user.permissions.isInfluencer && isUserMessage) {
			onRead(message.id);
		}
	};

	useEffect(() => {
		setMessageItems(
			messages?.map((message) => {
				return new MessageItem(message, publisher);
			}),
		);
	}, [messages, selectedProfileId]);

	useEffect(() => {
		setUniqueDates(
			messages
				?.map((message) => {
					return formatDateDDMMMYYYY(message.createdAt);
				})
				.filter((date, index, newArr) => newArr.indexOf(date) === index),
		);
		setTimeout(onScrollToBottom, 200);
	}, [messages]);

	useEffect(() => {
		if (tempValue) {
			setTimeout(onScrollToBottom, 200);
		}
		setTempMessage(tempValue);
	}, [tempValue]);

	return (
		<Styled.Wrapper ref={messagesRef}>
			{messageItems.length > 0 && (
				<Styled.FirstLineWrapper>
					<div>{customFormatDate(messageItems[0].createdAt, 'DD MMM, HH:mm')}</div>
					<div>This is the start of your conversation.</div>
				</Styled.FirstLineWrapper>
			)}
			{uniqueDates.map((date) => {
				return (
					<div key={date} data-testid='date-messages' className='date-messages'>
						<Styled.DateWrapper>{isToday(date) ? 'Today' : isYesterday(date) ? 'Yesterday' : date}</Styled.DateWrapper>
						{dateMessagesObjects[date].map((message) => {
							// Select which items to display seen text on
							const lastMessageSeen = dateMessagesObjects[date].filter((message) => message.displaySeen && isMessageFromCurrentUser(message, user)).slice(-1);
							return (
								<div key={message.id} className={message.isUnread ? 'unread' : ''}>
									<ChatBubble
										lastMessageSeen={lastMessageSeen[0]}
										markAsRead={markAdReadHandler}
										message={message}
										className={classNames({ right: isMessageFromCurrentUser(message, user) }, { left: !isMessageFromCurrentUser(message, user) })}
										isLastMessage={false}
										messageFromCurrentUser={isMessageFromCurrentUser(message, user)}
										fileUploadProgress={fileUploadProgress}
									/>
								</div>
							);
						})}
					</div>
				);
			})}
			{tempMessage && (tempMessage.message !== '' || tempMessage.files.length > 0) ? (
				<div className='unread'>
					{isInfluencer ? (
						<ChatBubble
							message={
								{
									name: user.name,
									profileImage: user.influencers.length > 0 ? user.influencer.links?.profilePictureUrl : undefined,
									...influencerLastMessage,
									id: 'fake-it-until-make-it',
									message: tempMessage.message,
									messageHtml: tempMessage.message,
									files: tempMessage.files,
									createdAt: new Date().toISOString(),
									isUnread: true,
								} as MessageItem
							}
							className={classNames({ right: true })}
							isLastMessage={true}
							fileUploadProgress={fileUploadProgress}
							messageFromCurrentUser={true}
						/>
					) : (
						<ChatBubble
							message={
								{
									name: '',
									...userLastMessage,
									id: 'fake-it-until-make-it',
									message: tempMessage.message,
									messageHtml: tempMessage.message,
									files: tempMessage.files,
									createdAt: new Date().toISOString(),
									isUnread: true,
								} as MessageItem
							}
							className={classNames({ right: true })}
							isLastMessage={true}
							fileUploadProgress={fileUploadProgress}
							messageFromCurrentUser={true}
						/>
					)}
				</div>
			) : null}
			<div ref={messageEndRef} />
		</Styled.Wrapper>
	);
};

export const getSendersLastMessage = (messages: MessageItem[]) => {
	if (messages.length > 0) {
		const userMessages = messages.filter((message) => message.senderInfo?.type !== 'influencer');
		const influencerMessages = messages.filter((message) => message.senderInfo?.type === 'influencer');

		return {
			userLastMessage: userMessages.length > 0 ? userMessages[userMessages.length - 1] : null,
			influencerLastMessage: influencerMessages.length > 0 ? influencerMessages[influencerMessages.length - 1] : null,
		};
	} else {
		return {
			userLastMessage: null,
			influencerLastMessage: null,
		};
	}
};

export const formatDateDDMMMYYYY = (createdAt: string) => {
	return moment(createdAt).format('DD MMM YYYY');
};

export const isToday = (createdAt: string) => {
	const today = new Date(new Date().toDateString());
	const targetDate = new Date(new Date(createdAt).toDateString());

	return today.getTime() - targetDate.getTime() === 0;
};

export const isYesterday = (createdAt: string) => {
	const today = new Date(new Date().toDateString());
	const targetDate = new Date(new Date(createdAt).toDateString());

	return today.getTime() - targetDate.getTime() === 60 * 60 * 24 * 1000;
};

export default ChatMessages;
