import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgCreditCard(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M19.02 3H0.98C0.44 3 0 3.4197 0 3.94932V16.0507C0 16.5703 0.44 17 0.98 17H19.02C19.56 17 20 16.5703 20 16.0507V3.94932C20 3.42969 19.56 3 19.02 3ZM18.45 4.49893V6.28765H1.55V4.49893H18.45ZM1.55 15.4911V8.28622H18.45V15.4911H1.55Z'
				fill='#333333'
			/>
			<path
				d='M11.21 9.82861H3.74005C3.63005 9.82861 3.55005 9.94288 3.55005 10.1V10.9284C3.55005 11.0713 3.64005 11.1998 3.74005 11.1998H11.21C11.32 11.1998 11.4 11.0856 11.4 10.9284V10.1C11.4 9.95716 11.31 9.82861 11.21 9.82861Z'
				fill='#333333'
			/>
		</svg>
	);
}
