import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgProduct(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				id='Path_4939'
				data-name='Path 4939'
				d='M63.814,268.084H56.547a.483.483,0,0,0-.021-.093l1.738-.883-.455-.891L55.5,267.4c-.083-.016-.182-.027-.279-.039l1.192-2.24-.883-.469-1.263,2.374L53,264.648l-.883.469,1.19,2.24c-.107.013-.217.026-.307.042l-2.283-1.137-.446.9,1.722.858c0,.025-.013.05-.015.071H44.712a.7.7,0,0,0-.7.7v3.4a.7.7,0,0,0,.7.7h.078v11.538a.7.7,0,0,0,.7.7H63.018a.7.7,0,0,0,.7-.7V272.884h.1a.7.7,0,0,0,.7-.7v-3.4a.7.7,0,0,0-.7-.7m-.3,3.8h-8.75v-2.8h8.75Zm-18.5-2.8h8.752v2.8H45.012Zm.778,3.8h7.974v11.237H45.79Zm16.928,11.238H54.764V272.884h7.954Z'
				transform='translate(-44.012 -264.648)'
			/>
		</svg>
	);
}
