import { css } from 'styled-components';

export const Input = css`
	.rs-input-group.rs-input-group-inside,
	.rs-input-group,
	.rs-input {
		background-color: ${({ theme }) => theme.colors.inputs};
		min-height: 44px;
	}

	.rs-input {
		border: none;
		width: 100%;
		color: ${({ theme }) => theme.colors.text};
		font-size: 1rem;
		padding: 0.9375rem 0 0.675rem 0;
		min-height: 44px;
	}

	.rs-input-group {
		border-radius: ${({ theme }) => theme.radius.small};
		border: 1px solid ${({ theme }) => theme.borders.secondary};
		transition: border-color 0.2s ease-in-out;
		padding: 0 0.625rem;

		&:not(.rs-input-group-disabled):hover {
			border-color: ${({ theme }) => theme.borders.hover};
		}

		&:focus,
		&:focus-within {
			border-color: ${({ theme }) => theme.borders.focus} !important;
			outline: none !important;
		}
	}
`;
