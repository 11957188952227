import { get } from 'lodash';

import { ResourceObject } from 'services/ApiClient/types';

export function getCompareFn(sortProperty: string) {
	return (a: ResourceObject, b: ResourceObject) => {
		const valueA = get(a.attributes, sortProperty);
		const valueB = get(b.attributes, sortProperty);

		if (valueA === valueB) {
			return 0;
		}

		if (valueA === null) {
			return 1;
		}

		if (valueB === null) {
			return -1;
		}

		return valueA < valueB ? -1 : 1;
	};
}
