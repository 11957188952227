import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExternalAccountType, SupportedCurrencies } from 'components/Payment/types';
import { UserPaymentType } from 'reducers/UserReducers/types';
import { ICollabsResponse } from 'services/Response.types';

const initialState = {
	vatIsValid: false,
	sidebarIsOpen: false,
	displayForm: false,
	editUser: false,
	isRedirecting: null,
	switchToIndividual: false,
	paymentTypeOption: UserPaymentType.NULL,
	selectedCurrency: '',
	ftax: false,
	formSubmitted: false,
	initialPineBucketFormValues: {
		country: '',
		orgNumber: '',
		vatId: '',
		clearingNumber: '',
		accountNumber: '',
		iban: '',
		currency: SupportedCurrencies.SEK,
		user: {
			name: ``,
			companyName: '',
			email: '',
			address: '',
			city: '',
			country: '',
			postalCode: null,
			vatId: '',
			region: 'region',
		},
		bank_account: {
			currency: SupportedCurrencies.SEK,
			country: '',
			type: ExternalAccountType.BANK_ACCOUNT,
			identifier: { clearingNumber: '', accountNumber: '', iban: '' },
			bic: '',
			region: 'region',
		},
	},
};

const sidebarSlice = createSlice({
	name: 'sidebarIsOpen',
	initialState,
	reducers: {
		setSidebarIsOpen(state, action: PayloadAction<boolean>) {
			state.sidebarIsOpen = action.payload;
			return state;
		},
		setPaymentTypeOption(state, action: PayloadAction<UserPaymentType>) {
			state.paymentTypeOption = action.payload;
			return state;
		},
		setSelectedCurrency(state, action: PayloadAction<string>) {
			state.selectedCurrency = action.payload;
			return state;
		},
		setEditUser(state, action: PayloadAction<boolean>) {
			state.editUser = action.payload;
			return state;
		},
		setInitialPineBucketFormValuesBankAccount(state, action: PayloadAction<ICollabsResponse>) {
			const attributes = action.payload.data[0]?.attributes;
			return {
				...state,
				initialPineBucketFormValues: {
					...state.initialPineBucketFormValues,
					clearingNumber: attributes.identifier.clearingNumber ?? '',
					accountNumber: attributes.identifier.accountNumber ?? '',
					iban: attributes.identifier.iban ?? '',
					currency: attributes.currency ?? '',
					bic: attributes.bic ?? '',
					bank_account: {
						...state.initialPineBucketFormValues.bank_account,
						currency: attributes.currency ?? '',
						country: attributes.country ?? '',
						type: attributes.type ?? '',
						identifier: {
							...state.initialPineBucketFormValues.bank_account.identifier,
							clearingNumber: attributes.identifier.clearingNumber ?? '',
							accountNumber: attributes.identifier.accountNumber ?? '',
							iban: attributes.identifier.iban ?? '',
						},
						name: attributes.name ?? 'name',
						region: attributes.name ?? 'region',
						bic: attributes.bic ?? '',
					},
				},
			};
		},
		setInitialPineBucketFormValuesUser(state, action: PayloadAction<ICollabsResponse>) {
			const attributes = action.payload.data.attributes;
			return {
				...state,
				ftax: attributes.country === 'SWE' ? true : false,
				initialPineBucketFormValues: {
					...state.initialPineBucketFormValues,
					vatId: attributes.vatId,
					country: attributes.country,
					orgNumber: attributes.vatId?.slice(2, -2),
					user: {
						...state.initialPineBucketFormValues.user,
						name: attributes.name ?? '',
						companyName: attributes.companyName ?? '',
						email: attributes.email ?? '',
						address: attributes.address ?? '',
						city: attributes.city ?? '',
						country: attributes.country ?? '',
						postalCode: attributes.postalCode ?? '',
						vatId: attributes.vatId ?? '',
					},
				},
			};
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setInitialPineBucketFormValuesUserFromUser(state, action: PayloadAction<any>) {
			return {
				...state,
				initialPineBucketFormValues: {
					...state.initialPineBucketFormValues,
					country: action.payload.country,
					user: {
						...state.initialPineBucketFormValues.user,
						name: action.payload.name ?? '',
						country: action.payload.country,
					},
				},
			};
		},
		setFtax(state, action: PayloadAction<boolean>) {
			state.ftax = action.payload;
			return state;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setIsRedirecting(state, action: PayloadAction<any>) {
			state.isRedirecting = action.payload;
			return state;
		},
		switchToIndividual(state, action: PayloadAction<boolean>) {
			state.switchToIndividual = action.payload;
			return state;
		},
		setVatIsValid(state, action: PayloadAction<boolean>) {
			state.vatIsValid = action.payload;
			return state;
		},
		setDisplayForm(state, action: PayloadAction<boolean>) {
			state.displayForm = action.payload;
			return state;
		},
		setFormSubmitted(state, action: PayloadAction<boolean>) {
			state.formSubmitted = action.payload;
			return state;
		},
		clearState() {
			return initialState;
		},
	},
});
export const {
	setSidebarIsOpen,
	setPaymentTypeOption,
	setSelectedCurrency,
	setEditUser,
	setInitialPineBucketFormValuesBankAccount,
	setInitialPineBucketFormValuesUser,
	setInitialPineBucketFormValuesUserFromUser,
	setFtax,
	setIsRedirecting,
	switchToIndividual,
	setVatIsValid,
	setDisplayForm,
	setFormSubmitted,
	clearState,
} = sidebarSlice.actions;
export default sidebarSlice.reducer;
