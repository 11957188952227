import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { DarkButton } from 'components/Button';
import IconButton from 'components/Discovery/Components/IconButton';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import Tooltip from 'components/Tooltip/V2';
import usePermissions from 'hooks/usePermissions';
import toast from 'services/Toast';
import { isValidUrl } from 'shared/utils/url';
import { ASSIGNMENT_TYPE } from 'types/AssignmentTypes';

import Styled from './TrackingLink.style';

type TrackingLinkProps = {
	isLoading?: boolean;
	errorMessage?: string;
	originalUrl: string;
	trackingUrl: string;
	className?: string;
	canEdit: boolean;
	onClick?: (value: string) => void;
	assignmentType: ASSIGNMENT_TYPE;
	hideField?: boolean;
};

/**
 * TrackingLink
 * Component to show the tracking/affiliate link for the influencer
 * @param {string} url
 * @returns {JSX.Element}
 */
const TrackingLink = ({
	isLoading = false,
	errorMessage = '',
	originalUrl,
	trackingUrl,
	className,
	canEdit,
	onClick,
	assignmentType,
	hideField = false,
}: TrackingLinkProps): JSX.Element => {
	const [inputValue, setInputValue] = useState(originalUrl);
	const [validationMessage, setValidationMessage] = useState('');
	const { isInfluencer } = usePermissions();

	useEffect(() => {
		setInputValue(originalUrl);
	}, [originalUrl]);

	const onClickGenerate = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault();

			if (isValidUrl(inputValue)) {
				onClick?.(inputValue);
			} else {
				setValidationMessage('The URL is not valid');
			}
		},
		[inputValue, onClick],
	);

	const onClickTrackingUrl = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault();

			if (navigator.clipboard) {
				navigator.clipboard
					.writeText(trackingUrl)
					.then(() => toast.success('Tracking link copied to your clipboard'))
					.catch((error) => {
						console.error('Failed to copy:', error);
						toast.error('Could not copy the tracking link');
					});
			} else {
				const textArea = document.createElement('textarea');
				textArea.value = trackingUrl;
				document.body.appendChild(textArea);
				textArea.select();
				try {
					document.execCommand('copy');
					toast.success('Tracking link copied to your clipboard');
				} catch (error) {
					console.error('Copy command failed', error);
					toast.error('Could not copy the tracking link');
				} finally {
					document.body.removeChild(textArea);
				}
			}
		},
		[trackingUrl],
	);

	const onChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setInputValue(e.target.value);

			if (validationMessage && isValidUrl(e.target.value)) {
				setValidationMessage('');
			}
		},
		[validationMessage],
	);

	const getDescriptionText = useCallback(() => {
		switch (assignmentType) {
			case ASSIGNMENT_TYPE.INSTAGRAM_STORY:
				return 'Add the tracking URL to your Instagram story to track campaign results.';
			case ASSIGNMENT_TYPE.INSTAGRAM_POST:
			case ASSIGNMENT_TYPE.INSTAGRAM_REEL:
				return 'Add the tracking URL to your Instagram bio to track campaign results.';
			case ASSIGNMENT_TYPE.TIKTOK_VIDEO:
				return 'Add the tracking URL to your TikTok video or bio to track campaign results.';
			default:
				return 'Add the tracking URL to your bio to track campaign results.';
		}
	}, [assignmentType]);

	const renderInfluencerField = () => {
		return (
			<Styled.InputContainer className='tracking-link__input-container align-center'>
				<Field readOnly>
					<Input
						id='tracking-url'
						placeholder='Product link'
						name='tracking-url'
						value={trackingUrl || originalUrl}
						contentBefore={<Icon name='url-link' size='16' />}
					/>
				</Field>
				<Tooltip content='Copy link'>
					<IconButton onClick={onClickTrackingUrl} iconName='copy' />
				</Tooltip>
			</Styled.InputContainer>
		);
	};

	const renderManagerField = () => {
		return (
			<>
				{!hideField && (
					<Styled.InputContainer className='tracking-link__input-container'>
						<Field readOnly={!canEdit} validationMessage={validationMessage} validationState={validationMessage ? 'error' : undefined}>
							<Input
								id='product-url'
								placeholder='Product link'
								name='product-url'
								onChange={onChange}
								value={inputValue}
								contentBefore={<Icon name='url-link' size='16' />}
							/>
						</Field>
						{canEdit && (
							<DarkButton data-testid='generate-link' size='sm' onClick={onClickGenerate} disabled={isLoading}>
								{isLoading ? <LoadingSpinner size='sm' /> : 'Generate link'}
							</DarkButton>
						)}
					</Styled.InputContainer>
				)}

				{errorMessage ? (
					<p className='text-red-500'>{errorMessage}</p>
				) : (
					<Styled.TrackingLink className={classNames({ 'w-border': hideField })}>
						{hideField && <Icon name='url-link' size='16' />}
						<span onClick={onClickTrackingUrl}>{trackingUrl || originalUrl}</span>
					</Styled.TrackingLink>
				)}
			</>
		);
	};

	return (
		<Styled.Wrapper className={className}>
			<Styled.Inner className='tracking-link__link-container'>
				<Styled.Description>
					<label htmlFor='product-url'>Tracking URL</label>
					<p>{getDescriptionText()}</p>
				</Styled.Description>
				{!isInfluencer() ? renderManagerField() : renderInfluencerField()}
			</Styled.Inner>
		</Styled.Wrapper>
	);
};

export default TrackingLink;
