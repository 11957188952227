import axios from 'axios';
import { useContext, useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import ForbiddenPage from 'components/ErrorPages/Forbidden';
import PageNotFound from 'components/ErrorPages/PageNotFound';
import SomethingWentWrong from 'components/ErrorPages/SomethingWentWrong';
import { BugsnagContext } from 'contexts';

// See: https://reactrouter.com/en/main/route/error-element
const ErrorElement = () => {
	const error = useRouteError();
	const status = axios.isAxiosError(error) ? error.response?.status : undefined;
	const { client } = useContext(BugsnagContext);

	useEffect(() => {
		if (client && status && status >= 500) {
			client.notify(error);
		}
	}, [status, client]);

	if (403 === status) {
		return <ForbiddenPage />;
	}
	if (404 === status) {
		return <PageNotFound />;
	}

	return <SomethingWentWrong />;
};

export default ErrorElement;
