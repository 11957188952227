import { Navigate } from 'react-router-dom';

import PageNotFound from 'components/ErrorPages/PageNotFound';
import usePermissions from 'hooks/usePermissions';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { isConnected } from 'reducers/UserReducers/helpers';
import ReferralUrlService from 'services/ReferralUrlService';

type ProtectedRouteProps = {
	influencerView?: boolean;
	children: JSX.Element;
};

/**
 * ProtectedRoute
 * Route container to check the user is logged in or not. If not logged in, navigate to the login page and set the referral url.
 * @param {ProtectedRouteProps} props
 * @returns {JSX.Element}
 */
export const ProtectedRoute = ({ children, influencerView }: ProtectedRouteProps): JSX.Element => {
	const isLoggedIn = useAppSelector(isConnected);
	const user = useAppSelector((state) => state.user);
	const { isInfluencer, isSuperAdmin } = usePermissions();

	if (!isLoggedIn) {
		ReferralUrlService.set(window.location.href);
		return <Navigate to='/login' />;
	}

	if (influencerView) {
		if (!isInfluencer() && !isSuperAdmin()) {
			return <PageNotFound />;
		}
	}

	if (isInfluencer() && user.needsEmailVerification) {
		return <Navigate to='/verify-email' />;
	}

	return children;
};
