import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { PrePaymentItemType } from 'components/AdminPrePayments/types';

export type FormattedDataItem = {
	influencerUsername: string | null;
	campaign: string;
	amount: string | number;
	currency: string;
	createdAt: string;
	inPayment: boolean;
	scheduledAt: string;
	paidAt: string;
	kind: string;
	companyName: string;
	vatNumber: string | number;
	fortnoxId: string | number;
};

export type UsePrePaymentsToCsvType = {
	csvData: {
		formattedData: Array<FormattedDataItem>;
		csvHeaders: Array<string>;
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getCsvData: (event: any, done: any) => void;
	hasPrepaymentData: boolean;
};

export type CsvHeadersType = Array<keyof FormattedDataItem>;

export type CSVDataType = {
	formattedData: Array<FormattedDataItem>;
	csvHeaders: CsvHeadersType;
};

const usePrePaymentsToCSV = (isStoreLoading: boolean, prepaymentModels: Array<PrePaymentItemType>): UsePrePaymentsToCsvType => {
	const [prepaymentData, setPrepaymentData] = useState<Array<PrePaymentItemType> | []>([]);
	const [csvData, setCsvData] = useState<CSVDataType>({
		formattedData: [],
		csvHeaders: [],
	});

	const hasPrepaymentData = useRef(false); //use this to prevent loops when modeldata is changing

	const csvHeaders: CsvHeadersType = useMemo(
		() => [
			'influencerUsername',
			'campaign',
			'amount',
			'currency',
			'createdAt',
			'inPayment',
			'scheduledAt',
			'paidAt',
			'kind',
			'companyName',
			'vatNumber',
			'fortnoxId',
		],
		[],
	);

	const getCsvData = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(_event: any, done: any) => {
			if (!prepaymentData || prepaymentData.length === 0) {
				done(false);
				return;
			}

			try {
				const enrichedData = prepaymentData.map(({ influencer, ...rest }) => {
					return {
						...rest,
						influencerUsername: influencer?.username,
					};
				});

				const formattedData: Array<FormattedDataItem> = enrichedData.map((item) => ({
					influencerUsername: item.influencerUsername || null,
					campaign: item.name || '',
					amount: item.amount || '',
					currency: item.currency,
					createdAt: item.createdAt || '',
					inPayment: item.inPayment,
					scheduledAt: item.scheduledAt || '',
					paidAt: item.paidAt || '',
					kind: item.kind || '',
					companyName: item.companyName || '',
					vatNumber: item.vatNumber || '',
					fortnoxId: item.fortnoxId || '',
				}));

				setCsvData({ csvHeaders, formattedData });
				done(true);
			} catch (error) {
				done(false);
			}
		},
		[prepaymentData],
	);

	useEffect(() => {
		if (!isStoreLoading) {
			if (prepaymentModels.length > 0 && hasPrepaymentData.current === false) {
				setPrepaymentData(prepaymentModels);
				hasPrepaymentData.current = true;
			}
		}
	}, [isStoreLoading, prepaymentModels, hasPrepaymentData]);

	return {
		csvData,
		hasPrepaymentData: hasPrepaymentData.current,
		getCsvData,
	};
};

export default usePrePaymentsToCSV;
