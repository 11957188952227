import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgArrowLargeLeft(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_1991_27454)'>
				<path
					d='M0.3 10.72L6.34 16.76C6.66 17.08 7.17 17.08 7.48 16.76C7.8 16.44 7.8 15.93 7.48 15.62L2.61 10.75L19.25 10.75C19.66 10.75 20 10.41 20 10C20 9.59 19.66 9.25 19.25 9.25L2.61 9.25L7.48 4.38C7.8 4.06 7.8 3.55 7.48 3.24C7.32 3.08 7.12 3 6.91 3C6.7 3 6.5 3.08 6.34 3.24L0.3 9.28C-0.1 9.68 -0.1 10.33 0.3 10.73L0.3 10.72Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1991_27454'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
