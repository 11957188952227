import { inject, injectable } from 'inversify';

import { InfluencerListFolderModel, InfluencerListListModel } from 'api-models';
import { CreateInfluencerListFolderPayload, CreateInfluencerListPayload, UpdateInfluencerListFolderPayload, UpdateInfluencerListPayload } from 'api-payloads';
import {
	CreateInfluencerListFolderQuery,
	CreateInfluencerListQuery,
	ListInfluencerListFoldersQuery,
	ListInfluencerListsQuery,
	UpdateInfluencerListFolderQuery,
	UpdateInfluencerListQuery,
	ViewInfluencerListFolderQuery,
	ViewInfluencerListQuery,
} from 'api-queries';
import {
	InfluencerListFolderCollectionResponse,
	InfluencerListFolderResponse,
	InfluencerListListCollectionResponse,
	InfluencerListListResponse,
} from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import ResourceManager from 'utils/Repository/ResourceManager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, DeleteConfig, UpdateConfig } from './types';

import type InfluencerListApiClientInterface from 'services/ApiClient/InfluencerListApiClientInterface';

type FolderRepository = ModelRepository<InfluencerListFolderModel>;
type ListRepository = ModelRepository<InfluencerListListModel>;

@injectable()
class InfluencerListManager extends ApiManager {
	private readonly client: InfluencerListApiClientInterface;
	private readonly folderRepository: FolderRepository;
	private readonly listRepository: ListRepository;

	constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.InfluencerListApiClientInterface) client: InfluencerListApiClientInterface,
	) {
		const resourceManager = new ResourceManager();
		super(cacheManager, resourceManager);
		this.client = client;
		this.folderRepository = new ModelRepository<InfluencerListFolderModel>('folder', resourceManager);
		this.listRepository = new ModelRepository<InfluencerListListModel>('list', resourceManager);
	}

	public listInfluencerFolders(queryBuilder = RequestQueryBuilder.create<ListInfluencerListFoldersQuery>()) {
		const key = `listInfluencerFolders::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listFolders(queryBuilder.toQuery());

		return this.swr<InfluencerListFolderCollectionResponse, FolderRepository>(key, fetcher, { repository: this.folderRepository });
	}

	public listInfluencerLists(queryBuilder = RequestQueryBuilder.create<ListInfluencerListsQuery>()) {
		const key = `listInfluencerLists::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listInfluencerLists(queryBuilder.toQuery());

		return this.swr<InfluencerListListCollectionResponse, ListRepository>(key, fetcher, { repository: this.listRepository });
	}

	public createFolder(
		payload: CreateInfluencerListFolderPayload,
		queryBuilder = RequestQueryBuilder.create<CreateInfluencerListFolderQuery>(),
		config: CreateConfig<InfluencerListFolderModel> = {},
	): Promise<InfluencerListFolderModel> {
		return this.createModel<InfluencerListFolderModel>(this.client.createFolder(payload, queryBuilder.toQuery()), config); // No need to return, we have a special repository for this
	}

	public updateFolder(
		id: string,
		payload: UpdateInfluencerListFolderPayload,
		queryBuilder = RequestQueryBuilder.create<UpdateInfluencerListFolderQuery>(),
		config: UpdateConfig<InfluencerListFolderModel> = {},
	): Promise<InfluencerListFolderModel> {
		return this.updateModel<InfluencerListFolderModel>(this.client.updateFolder(id, payload, queryBuilder.toQuery()), config); // No need to return, we have a special repository for this
	}

	public async deleteFolder(id: string, config: DeleteConfig = {}): Promise<FolderRepository> {
		await this.deleteModel(this.client.deleteFolder(id), 'folder', id, config); // No need to return, we have a special repository for this
		return this.folderRepository;
	}

	public createList(
		payload: CreateInfluencerListPayload,
		queryBuilder = RequestQueryBuilder.create<CreateInfluencerListQuery>(),
		config: CreateConfig<InfluencerListListModel> = {},
	): Promise<InfluencerListListModel> {
		return this.createModel<InfluencerListListModel>(this.client.create(payload, queryBuilder.toQuery()), config); // No need to return, we have a special repository for this
	}

	public getList(id: string, queryBuilder = RequestQueryBuilder.create<ViewInfluencerListQuery>()) {
		const key = `getList::${id}_${queryBuilder.toHash()}`;
		const fetcher = () => this.client.view(id, queryBuilder.toQuery());

		return this.swr<InfluencerListListResponse>(key, fetcher);
	}

	public getFolder(id: string, queryBuilder = RequestQueryBuilder.create<ViewInfluencerListFolderQuery>()) {
		const key = `getFolder::${id}_${queryBuilder.toHash()}`;
		let fetcher;
		if (id === '') {
			// Hack because I am not smart enough to not make the request if I don't have an ID
			fetcher = () => Promise.resolve(null);
		} else {
			fetcher = () => this.client.viewFolder(id, queryBuilder.toQuery());
		}

		return this.swr<InfluencerListFolderResponse>(key, fetcher);
	}

	public updateList(
		id: string,
		payload: UpdateInfluencerListPayload,
		queryBuilder = RequestQueryBuilder.create<UpdateInfluencerListQuery>(),
		config: UpdateConfig<InfluencerListListModel> = {},
	): Promise<InfluencerListListModel> {
		return this.updateModel<InfluencerListListModel>(this.client.update(id, payload, queryBuilder.toQuery()), config); // No need to return, we have a special repository for this
	}

	public async deleteList(id: string, config: DeleteConfig = {}): Promise<ListRepository> {
		await this.deleteModel(this.client.delete(id), 'list', id, config); // No need to return, we have a special repository for this

		return this.listRepository;
	}

	/**
	 * Specifying folderId as 'null' will return the root folder and its children.
	 */
	public getFolderAndChildren(folderId: string) {
		const key = `getFolderAndChildren::${folderId}`;
		const fetcher = () => {
			const foldersQb = RequestQueryBuilder.create<ListInfluencerListFoldersQuery>(['delete', 'addUsers', 'edit'])
				.withFilter('sort', 'name.asc')
				.withFilter('parent', folderId);
			const listsQb = RequestQueryBuilder.create<ListInfluencerListsQuery>(['delete', 'addUsers', 'edit'])
				.withFilter('sort', 'name.asc')
				.withFilter('parent', folderId)
				.withInclude('items:maxItems(5)', ['profilePictureUrl']);

			const promises = [];
			promises.push(this.client.listFolders(foldersQb.toQuery()));
			promises.push(this.client.listInfluencerLists(listsQb.toQuery()));

			if ('null' !== folderId) {
				const folderQb = RequestQueryBuilder.create<ViewInfluencerListFolderQuery>(['delete', 'addUsers', 'edit']).withInclude('parent:relationOnly()');
				promises.push(this.client.viewFolder(folderId, folderQb.toQuery()));
			}

			return Promise.all(promises);
		};

		return this.swr(key, fetcher, { multipleApiResponses: true });
	}
}

export default InfluencerListManager;
