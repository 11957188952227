import { DarkButton, LinkButton } from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modals/Modal';

import Styled from './ApproveModal.style';

type ApproveModalProps = {
	isOpen: boolean;
	isLoading: boolean;
	onClickApprove: () => void;
	handleClose: () => void;
};

/**
 * ApproveModal
 * @param {ApproveModalProps} props
 * @returns {JSX.Element}
 */
const ApproveModal = ({ onClickApprove, isOpen, handleClose, isLoading }: ApproveModalProps): JSX.Element => {
	return (
		<Modal size='xs' open={isOpen} handleClose={() => handleClose()}>
			<Modal.Header>
				<h2 className='text-center'>Are you sure?</h2>
			</Modal.Header>
			<Modal.Body>
				<Styled.ModalInner>
					<Styled.Content>
						<p>Are you sure you want to approve this content?</p>
					</Styled.Content>
					<Styled.ButtonGroup>
						<DarkButton onClick={() => onClickApprove()} size='sm' disabled={isLoading}>
							{isLoading ? <LoadingSpinner size='sm' /> : 'Approve'}
						</DarkButton>
						<LinkButton onClick={() => handleClose()} size='sm' disabled={isLoading}>
							Cancel
						</LinkButton>
					</Styled.ButtonGroup>
				</Styled.ModalInner>
			</Modal.Body>
		</Modal>
	);
};

export default ApproveModal;
