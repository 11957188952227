/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { SetInfluencersPreferencesPayload, UpdateApiTokenPayload } from 'api-payloads';
import { ListInfluencersQuery, DiscoveryQuery, ListInstagramStoriesQuery, GetInfluencerPreferencesQuery } from 'api-queries';
import {
	InfluencerCollectionResponse,
	InfluencerResponse,
	InstagramStoryCollectionResponse,
	InfluencerAffiliateInfluencerPreferencesResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import InfluencerApiClientInterface from './InfluencerApiClientInterface';

@injectable()
export default class InfluencerApiClient implements InfluencerApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List influencers
	 */
	async listInfluencers(params: ListInfluencersQuery): Promise<InfluencerCollectionResponse> {
		const response = await this.client.get<InfluencerCollectionResponse>(`/influencers`, { params });
		return response.data;
	}

	/**
	 * Discovery
	 */
	async discovery(collabsId: string, params: DiscoveryQuery): Promise<InfluencerResponse> {
		const response = await this.client.get<InfluencerResponse>(`/influencers/discovery/${collabsId}`, { params });
		return response.data;
	}

	/**
	 * Set influencers preferences
	 */
	async setInfluencersPreferences(payload: SetInfluencersPreferencesPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/influencers/preferences`, payload, { params });
		return response.data;
	}

	/**
	 * Update api token
	 */
	async updateApiToken(id: string, payload: UpdateApiTokenPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/influencers/${id}/api-tokens`, payload, { params });
		return response.data;
	}

	/**
	 * Delete api token
	 */
	async deleteApiToken(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/influencers/${id}/api-tokens`);
		return response.data;
	}

	/**
	 * Create email request
	 */
	async createEmailRequest(id: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/influencers/${id}/email-requests`, undefined, { params });
		return response.data;
	}

	/**
	 * List instagram stories
	 */
	async listInstagramStories(id: string, params: ListInstagramStoriesQuery): Promise<InstagramStoryCollectionResponse> {
		const response = await this.client.get<InstagramStoryCollectionResponse>(`/influencers/${id}/instagram-stories`, { params });
		return response.data;
	}

	/**
	 * Get influencer preferences
	 */
	async getPreferences(id: string, params: GetInfluencerPreferencesQuery): Promise<InfluencerAffiliateInfluencerPreferencesResponse> {
		const response = await this.client.get<InfluencerAffiliateInfluencerPreferencesResponse>(`/influencers/${id}/preferences`, { params });
		return response.data;
	}

	/**
	 * Claim influencer
	 */
	async claim(influencerId: string, inviteToken: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/influencers/${influencerId}/claims/${inviteToken}`, undefined, { params });
		return response.data;
	}

	/**
	 * View influencer by instagram owner id
	 */
	async viewByInstagramOwnerId(instagramOwnerId: string, params: { include?: string }): Promise<void> {
		const response = await this.client.get<void>(`/instagram-owners/${instagramOwnerId}`, { params });
		return response.data;
	}
}
