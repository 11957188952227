import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';

import AutoCompleteList from 'components/Discovery/Components/AutoCompleteList';
import { AutoCompleteOption } from 'components/Discovery/Components/AutoCompleteList/AutoCompleteList';
import IconButton from 'components/Discovery/Components/IconButton';
import Styled from 'components/Discovery/DiscoveryContainer.style';
import { extractUsernameAndNetwork, mapAutoCompleteOptions } from 'components/Discovery/helpers';
import SearchInput from 'components/SearchInput';
import { Network } from 'constants/socialMedia';
import DiscoveryContext from 'contexts/Discovery';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import DiscoveryAuthService from 'services/Authentication/Discovery-api';
import DiscoveryService from 'services/DiscoveryApi';
import { ICollabsResponse, StatusCode } from 'services/Response.types';

type SearchSectionProps = {
	setShowFilter: (prev: boolean) => void;
	isSearching: boolean;
	handleSelectOption: (id: string, label: string, network: Network) => void;
	setIsSearching: (isSearching: boolean) => void;
	showFilter: boolean;
	setDidYouMeanOptions: (options: AutoCompleteOption[]) => void;
};
const SearchSection = ({ setShowFilter, isSearching, handleSelectOption, setIsSearching, showFilter, setDidYouMeanOptions }: SearchSectionProps) => {
	const { searchText, updateNetworkHandler, setFilter, filter, changeSearchValueHandler } = useContext(DiscoveryContext);

	const [cursor, setCursor] = useState(-1);
	const [inputValue, setInputValue] = useState<string | null>(null);
	const [debouncedInputValue, setDebouncedInputValue] = useState('');
	const [autoCompleteOptions, setAutoCompleteOptions] = useState<AutoCompleteOption[]>([]);
	const [fuzzySearchOptions, setFuzzySearchOptions] = useState<AutoCompleteOption[]>([]);
	const [isFetchingDropdownItems, setIsFetchingDropdownItems] = useState(false);

	const CancelSignal = new AbortController();
	const cancel = useRef<AbortController | null>(null);

	const fetchAutoCompleteData = async () => {
		if (!inputValue || inputValue.length < 2 || !isSearching) {
			setAutoCompleteOptions([]);
			setFuzzySearchOptions([]);
			return;
		}
		setIsFetchingDropdownItems(true);
		cancel.current = CancelSignal;
		try {
			const [autoCompleteRes, fuzzySearchRes] = await Promise.all([
				DiscoveryService.autoCompleteSearch(inputValue, cancel.current.signal),
				DiscoveryService.fuzzySearch(inputValue, cancel.current.signal),
			]);

			const handleResponse = (res: ICollabsResponse, setOptions: (options: AutoCompleteOption[]) => void) => {
				const newOptions = mapAutoCompleteOptions(res.data);
				setOptions(newOptions);
				setDidYouMeanOptions(newOptions);
				setDebouncedInputValue(inputValue);
			};

			handleResponse(autoCompleteRes, setAutoCompleteOptions);
			handleResponse(fuzzySearchRes, setFuzzySearchOptions);

			cancel.current = null;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			if (err.response) {
				if (err.response.status === StatusCode.UNAUTHORIZED) {
					DiscoveryAuthService.requestDiscoveryToken();
				}
				const error = err.response.data.errors?.[0];
				toast.error(error ? error.title : getSomethingWentWrongMessage());
			}
		} finally {
			setIsFetchingDropdownItems(false);
		}
	};

	useEffect(() => {
		setCursor(-1);
		if (inputValue && inputValue.length === 0) {
			setAutoCompleteOptions([]);
		}
		if (cancel.current !== null) {
			cancel.current.abort();
			cancel.current = null;
		}
		if (inputValue && inputValue.length >= 2) {
			const timerId = setTimeout(() => {
				fetchAutoCompleteData();
			}, 300);
			return () => clearTimeout(timerId);
		}
	}, [inputValue]);

	useEffect(() => {
		setInputValue(searchText ?? null);
	}, [searchText]);

	const changeHandler = (searchWord: string) => {
		if (inputValue !== searchWord) {
			const { network, username } = extractUsernameAndNetwork(searchWord);

			if (username && network) {
				// If a valid URL is pasted, update the network and search value
				setInputValue(`@${username}`);
				updateNetworkHandler(network);
				setFilter({ ...filter, networks: network });
				changeSearchValueHandler({ text: `@${username}` });
			} else {
				// Otherwise, treat it as a regular search
				setInputValue(searchWord);
			}
			setIsSearching(true);
		}
	};

	const keyboardNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const combinedOptions = [...autoCompleteOptions, ...fuzzySearchOptions];

		if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (combinedOptions.length > 0) {
				setCursor((prev) => {
					return prev < combinedOptions.length - 1 ? prev + 1 : prev;
				});
			}
		}

		if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (combinedOptions.length > 0) {
				setCursor((prev) => {
					return prev > 0 ? prev - 1 : 0;
				});
			}
		}

		if (e.key === 'Escape') {
			setCursor(-1);
			setAutoCompleteOptions([]);
			setFuzzySearchOptions([]);
		}

		if (e.key === 'Enter') {
			if (cursor === -1 && inputValue) {
				changeSearchValueHandler({ text: `${inputValue}` });
			} else if (cursor >= 0 && cursor < combinedOptions.length) {
				const selectedOption = combinedOptions[cursor];
				if (selectedOption) {
					changeSearchValueHandler({ text: `@${selectedOption.label}` });
					setInputValue(`@${selectedOption.label}`);
				}
			}
			setIsSearching(false);
		}
	};

	const clearSearch = () => {
		changeSearchValueHandler({
			text: '',
		});
		setInputValue('');
		setIsSearching(false);
		setAutoCompleteOptions([]);
		setFuzzySearchOptions([]);
	};

	return (
		<Styled.SearchInputSection>
			<Styled.SearchInputWrapper>
				<SearchInput
					value={inputValue ? inputValue : ''}
					onChange={(e) => {
						changeHandler(e.target.value);
						if (e.target.value.length === 0) {
							clearSearch();
						}
					}}
					onKeyDown={(e) => {
						keyboardNavigation(e);
					}}
					onReset={() => {
						changeHandler('');
						clearSearch();
					}}
					placeholder='Search keyword or profile with @'
				/>
				<Styled.IconContainer>
					<IconButton
						iconName='filters'
						onClick={() => {
							setShowFilter(!showFilter);
						}}
					/>
				</Styled.IconContainer>
			</Styled.SearchInputWrapper>
			{isSearching && (
				<AutoCompleteList
					inputValue={debouncedInputValue}
					cursor={cursor}
					options={autoCompleteOptions}
					isFetchingDropdownItems={isFetchingDropdownItems}
					fuzzySearchOptions={fuzzySearchOptions}
					onSelect={handleSelectOption}
					onClickOutside={() => {
						setIsSearching(false);
					}}
				/>
			)}
		</Styled.SearchInputSection>
	);
};

export default SearchSection;
