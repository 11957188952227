import moment from 'moment';

// eslint-disable-next-line import/no-named-as-default-member
moment.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		// s: "a few seconds",
		s: (number, withoutSuffix, key, isFuture) => (isFuture ? 'a few seconds' : `${number}s`),
		// ss: "%d seconds",
		ss: (number, withoutSuffix, key, isFuture) => (isFuture ? `${number} seconds` : `${number}s`),
		// m: "a minute",
		m: (number, withoutSuffix, key, isFuture) => (isFuture ? 'a minute' : `${number}m`),
		// mm: "%d minutes",
		mm: (number, withoutSuffix, key, isFuture) => (isFuture ? `${number} minutes` : `${number}m`),
		// h: "an hour",
		h: (number, withoutSuffix, key, isFuture) => (isFuture ? 'an hour' : `${number}h`),
		// hh: "%d hours",
		hh: (number, withoutSuffix, key, isFuture) => (isFuture ? `${number} hours` : `${number}h`),
		// d: "a day",
		d: (number, withoutSuffix, key, isFuture) => (isFuture ? 'a day' : `${number}d`),
		// dd: "%d days",
		dd: (number, withoutSuffix, key, isFuture) => (isFuture ? `${number} days` : `${number}d`),
		// M: "a month",
		M: (number, withoutSuffix, key, isFuture) => (isFuture ? 'a month' : `${number}m`),
		// MM: "%d months",
		MM: (number, withoutSuffix, key, isFuture) => (isFuture ? `${number} months` : `${number}m`),
		// y: "a year",
		y: (number, withoutSuffix, key, isFuture) => (isFuture ? 'a year' : `${number}y`),
		// yy: "%d years",
		yy: (number, withoutSuffix, key, isFuture) => (isFuture ? `${number} years` : `${number}y`),
	},
});
