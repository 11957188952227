import { css } from 'styled-components';

import typography from 'styles/variables/typography';

export const RichText = css`
	font-family: ${typography.BaseFontFamiliy};

	// Core text styling
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.5;
		margin-bottom: 12px;
	}

	h1 {
		font-size: ${({ theme }) => theme.fontSizes.headings.h5};
		font-weight: ${({ theme }) => theme.fontWeights.bold};
	}
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: ${({ theme }) => theme.fontSizes.headings.h6};
		font-weight: ${({ theme }) => theme.fontWeights.bold};
	}

	p,
	li {
		font-size: ${({ theme }) => theme.fontSizes.text};
		line-height: 1.5;
	}

	// Link styling
	a {
		color: ${({ theme }) => theme.colors.userMenu.link.normal};
		text-decoration: none;
		&:hover {
			color: ${({ theme }) => theme.colors.text};
			text-decoration: underline;
		}
	}

	// List items
	ul,
	ol {
		padding-left: 1rem;
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style-type: decimal;
	}

	// Extra margin for headers after paragraph
	p + h1,
	p + h2,
	p + h3,
	p + h4,
	p + h5,
	p + h6 {
		margin-top: 20px;
	}
`;
