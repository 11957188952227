import { AxiosError } from 'axios';

import Icon from 'components/Icon';
import { UserUploadObj } from 'components/NewBriefPage/types';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';

import Styled from './TermsAndConditions.style';

type Props = {
	userUpload: UserUploadObj;
};

const DownloadPDFLink = ({ userUpload }: Props) => {
	const isAgreement = userUpload.attributes.originalName.includes('agreement');
	const client = createClient();

	//@TODO: make it as a util
	const downloadPrivitUserUpload = async (hateoasLink: string) => {
		return await client
			.post(hateoasLink)
			.then((res) => {
				const download = document.createElement('a');
				download.href = res.data.data.attributes.url;
				download.setAttribute('download', userUpload.attributes.originalName);
				document.body.appendChild(download);
				download.click();
			})
			.catch((error: AxiosError) => {
				console.error(error);
				toast.error('Failed to download agreement. Please try later');
			});
	};

	if (isAgreement) {
		return (
			<Styled.AgreementDownload data-testid='pdf-link' onClick={() => downloadPrivitUserUpload(userUpload.links?.createDownload)}>
				<div className='d-flex'>
					<Icon name='pdf-file' />
					<span>Download Agreement</span>
				</div>
				<Icon name='download' size='16' />
			</Styled.AgreementDownload>
		);
	}

	return (
		<Styled.PdfDownload data-testid='pdf-link' href={userUpload.links?.url} download rel='noopener noreferrer' target='_blank'>
			<div className='d-flex'>
				<Icon name='pdf-file' />
				<span>Download extended terms and conditions</span>
			</div>
			<Icon name='download' size='16' />
		</Styled.PdfDownload>
	);
};

export default DownloadPDFLink;
