import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div``;

const Header = styled.div`
	& > h3 {
		margin-bottom: ${spacing[3].px};
	}
	line-height: 150%;
`;

const ProfileSelectorWrapper = styled.div`
	& > h6 {
		&.title {
			font-size: 0.875rem;
			font-weight: 600;
			margin-top: ${spacing[2].px};
			margin-bottom: ${spacing[2].px};
		}
	}

	& > div {
		&.error-wrapper {
			margin-top: ${spacing[2].px};
			p {
				margin-top: 0;
			}
		}
		&.copy-button-wrapper {
			margin-top: ${spacing[2].px};
		}
	}
`;

const CopyProfileButton = styled.button`
	background-color: transparent;
	border: none;
	color: ${colors.info};
	font-weight: 500;
	font-size: 1rem;
`;

const Styled = {
	Wrapper,
	Header,
	ProfileSelectorWrapper,
	CopyProfileButton,
};

export default Styled;
