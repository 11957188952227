import styled, { DefaultTheme } from 'styled-components';

import { Scale } from 'styles/theme/types';

const Label = styled.label<{ theme: DefaultTheme; scale: Scale; disabled: boolean }>`
	display: flex;
	align-items: center;
	gap: 8px;
	line-height: 1.5;
	color: ${(props) => (!props.disabled ? props.theme.colors.label : props.theme.colors.textDisabled)};
	font-size: ${({ scale }) => {
		switch (scale) {
			case 'small':
				return '0.75rem';
			case 'medium':
				return '0.875rem';
			case 'large':
				return '1.25rem';
			default:
				return '1rem';
		}
	}};
`;

const TooltipContainer = styled.div`
	margin-top: 5px;
`;

const Styled = {
	Label,
	TooltipContainer,
};

export default Styled;
