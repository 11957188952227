import { JsonApiDocument, Store } from 'json-api-models';
import useSWR from 'swr';

import { GET_INFLUENCER_ONBOARDING_DATA } from 'constants/hateoas-keys.ts';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import { createClient } from 'shared/ApiClient/ApiClient';

export default function useOnboardingData() {
	const client = createClient();

	const fetcher = async (url: string) => {
		const { data } = await client.get<JsonApiDocument>(url, {});
		return data;
	};

	const getCategories = () => {
		const models = new Store();
		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', ':hateoas(false)');

		const url = `/affiliate-categories?${params}`;
		const { data, error } = useSWR(url, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			isLoading: !data && !error,
			error: error,
		};
	};

	const getBrands = (categoryIds: string[], isNeedRandomBrands: boolean) => {
		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', ':hateoas(false),category:hateoas(false)');
		params.append('categories', categoryIds.join(','));
		params.append('limit', '200');
		params.append('random', `${isNeedRandomBrands}`);

		const url = `/affiliate/brands?${params}`;
		const models = new Store();
		const { data, error, mutate } = useSWR(url, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			isLoading: !data && !error,
			error: error,
			loadNext: () => {
				mutate();
			},
		};
	};

	const getInfluencerOnboardingData = async (hateoasLink: string) => {
		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', ':hateoas(false),categories:hateoas(false),brands:hateoas(false),brands.category:hateoas(false)');

		const url = `${hateoasLink}?${params}`;
		return await client.get(url);
	};

	const saveInfluencerOnboardingData = async (data: {
		influencerIds: string[];
		collaborationTypes?: string[];
		categoryIds?: string[];
		brandIds?: string[];
	}) => {
		const url = '/influencers/preferences';
		return await client.post(url, {
			collaborationTypes: data.collaborationTypes,
			influencers: data.influencerIds,
			categoryIds: data.categoryIds,
			brandIds: data.brandIds,
		});
	};

	const getProfilesData = async (authService: CollabsAuthService) => {
		let influencers: Array<{ id: string; links: Record<string, string> }> = [];
		const models = new Store();

		await authService
			.me([`influencers:${GET_INFLUENCER_ONBOARDING_DATA}`])
			.then((result) => {
				models.sync(result);
				influencers = models.findAll('user')[0].influencers ?? [];
			})
			.catch((e) => {
				console.error(e);
			});

		return influencers;
	};

	return {
		getCategories,
		getBrands,
		getInfluencerOnboardingData,
		saveInfluencerOnboardingData,
		getProfilesData,
	};
}
