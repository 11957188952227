import { AxiosError, isAxiosError } from 'axios';
import { Model, Store } from 'json-api-models';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { InfluencerListFolderModel, InfluencerListListItemModel, InfluencerListListModel } from 'api-models';
import PageNotFound from 'components/ErrorPages/PageNotFound';
import InfluencerManagement from 'components/InfluencerManagement/InfluencerManagement';
import PageHeader from 'components/PageHeader';
import useInjection from 'hooks/useInjection';
import { pathInfluencerManagementFolder } from 'routing/PathLookup';
import InfluencerListManager from 'services/ApiManager/InfluencerList.manager';
import { ICollabsResponse } from 'services/Response.types';
import UserService from 'services/User';

import Styled from './InfluencerManagementContainer.style';

type RouteParams = {
	folderId: string;
};

function getParentId(item?: InfluencerListFolderModel | null): string | null {
	return item?.relationships?.parent?.data?.id ?? null;
}

/**
 * Influencer management container.
 * @returns {JSX.Element}
 */
const InfluencerManagementContainer = (): JSX.Element => {
	const [users, setUsers] = useState<Array<Model>>([]);

	useEffect(() => {
		// Need to update this later with new manager
		UserService.fetchAllUsers(
			`publisherPrivileges,publisherPrivileges.publisher,clientPrivileges,clientPrivileges.client,brandPrivileges,brandPrivileges.brand`,
		)
			.then((res: ICollabsResponse) => {
				const models = new Store();
				models.sync(res.data);
				const users = models.findAll('user');
				setUsers(users);
			})
			.catch((err: AxiosError) => {
				console.error(err.message);
			});
	}, []);

	const manager = useInjection<InfluencerListManager>(InfluencerListManager);
	const { folderId } = useParams<RouteParams>();

	const { repository, isLoading, error, mutate } = manager.getFolderAndChildren(folderId ?? 'null');
	const currentFolder = repository.find<InfluencerListFolderModel>('folder', folderId ?? 'nope');
	const parentId = getParentId(currentFolder);

	// Im not sure happy with the 404.. but it nicer for the user
	const { result: parentFolder } = manager.getFolder(parentId ?? '');

	const previousUrl = pathInfluencerManagementFolder(parentId);
	const folders = repository.findAll<InfluencerListFolderModel>('folder').filter(({ id }) => id !== folderId && id !== parentId) ?? [];
	const lists = repository.findAll<InfluencerListListModel>('list') ?? [];

	const getItemsFromList = (list: InfluencerListListModel): InfluencerListListItemModel[] => {
		return repository.findByRelation<InfluencerListListItemModel, InfluencerListListModel>(list, 'items');
	};

	if (error) {
		if (isAxiosError(error) && error.response?.status === 404) {
			return <PageNotFound previousUrl={previousUrl} />;
		}
		throw error;
	}

	return (
		<Styled.Wrapper>
			<PageHeader headline='Influencer Management' />
			<InfluencerManagement
				currentFolder={currentFolder}
				parentFolder={parentFolder || undefined}
				previousUrl={previousUrl}
				isLoading={isLoading}
				mutate={mutate}
				folders={folders}
				lists={lists}
				itemsFromListFn={getItemsFromList}
				allUsers={users}
			/>
		</Styled.Wrapper>
	);
};

export default InfluencerManagementContainer;
