import styled from 'styled-components';

import { Button } from 'components/Button';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { fontWeights } from 'styles/variables/font-weights';
import { devices } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	margin-bottom: 32px;
	width: 100%;
`;

const Navigation = styled.nav`
	width: 100%;
`;

const NavigationInner = styled.div`
	width: 100%;
`;

const NavigationItems = styled.div`
	display: flex;
	gap: 8px;
`;

const NavigationItem = styled(Button)`
	position: relative;
	border: none;
	min-width: 64px;
	font-size: 0.875rem !important;
	font-weight: ${fontWeights.semiBold};
	padding: 1.1rem 1rem 1rem;
	height: 44px;
	color: ${newColors.smoke};
	border-radius: ${borderRadius.m};
	border: 1px solid ${newColors.ash};
	flex: 1;
	&.selected {
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
		border: 1px solid transparent;
	}
	&:hover {
		background-color: ${colors.mainWhite};
	}
	&:disabled {
		border: 1px solid ${newColors.ash};
	}
	@media screen and (${devices.md}) {
		width: fit-content;
		flex: unset;
	}
`;

const ShareAssignment = styled.div`
	display: flex;
	margin-left: auto;

	button {
		margin-left: auto;
		font-size: 0.875rem;

		&:disabled {
			border: 0;
			padding-right: 0;
		}
	}
`;

const ShareTime = styled.span`
	display: block;
	font-size: 0.75rem;
	text-align: right;
`;

const ShareInner = styled.div``;

const ShareTimestamps = styled.div`
	padding-top: 1rem;

	.timestamp {
		font-weight: 700;
	}
`;

const NavigationWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 8px;
	@media screen and (${devices.md}) {
		flex-direction: row;
	}
`;

const Styled = {
	Wrapper,
	Navigation,
	NavigationInner,
	NavigationItems,
	NavigationItem,
	ShareAssignment,
	ShareTime,
	ShareInner,
	ShareTimestamps,
	NavigationWrapper,
};

export default Styled;
