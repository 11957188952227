import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgNewMessage(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M17.0168 11.1765C16.607 11.1765 16.2672 11.5163 16.2672 11.9261V18.4908H2.45408V4.66771H8.98882C9.39861 4.66771 9.73844 4.32788 9.73844 3.91808C9.73844 3.50829 9.39861 3.16846 8.98882 3.16846H1.85438C1.35463 3.16846 0.954834 3.58825 0.954834 4.10799V19.0605C0.954834 19.5802 1.36463 20 1.85438 20H16.8569C17.3566 20 17.7564 19.5802 17.7564 19.0605V11.9261C17.7564 11.5163 17.4166 11.1765 17.0068 11.1765H17.0168Z'
				fill='#575556'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.3873 0.707137C16.9967 0.316613 16.3636 0.316613 15.973 0.707137L15.2281 1.45207C15.2243 1.45591 15.2205 1.45977 15.2167 1.46366L6.61407 10.0663L6.61395 10.0662L6.61395 10.0664L5.85237 10.828C5.64372 11.0366 5.53788 11.3268 5.56315 11.6208L5.78706 14.2253C5.82873 14.7099 6.21309 15.0943 6.69773 15.1359L9.30225 15.3598C9.59623 15.3851 9.88637 15.2793 10.095 15.0706L19.6604 5.50526C19.6637 5.5019 19.6671 5.49851 19.6704 5.49509L20.2157 4.94978C20.6062 4.55925 20.6062 3.92609 20.2157 3.53556L17.3873 0.707137ZM17.6784 5.31659L15.6064 3.2446L7.6994 11.1516L9.77139 13.2236L17.6784 5.31659Z'
				fill='#575556'
			/>
		</svg>
	);
}
