import { inject, injectable } from 'inversify';
import { Dispatch } from 'redux';
import { mutate } from 'swr';

import { clearPermissions } from 'reducers/UserReducers/UserPermissionsSlice';
import { clearUser, completeLogout, startLogout } from 'reducers/UserReducers/UserSlice';
import ApiCacheManager from 'services/ApiManager/ApiCacheManager';
import TokenStorageRegistry from 'services/Authentication/TokenStorageRegistry';
import { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

@injectable()
export default class LogoutService {
	private readonly storageRegistry: TokenStorageRegistry;
	private readonly apiCacheManager: ApiCacheManager;

	constructor(@inject(TokenStorageRegistry) storageRegistry: TokenStorageRegistry, @inject(ApiCacheManager) apiCacheManager: ApiCacheManager) {
		this.storageRegistry = storageRegistry;
		this.apiCacheManager = apiCacheManager;

		this.storageRegistry.create(StorageType.LOCAL);
		this.storageRegistry.create(StorageType.SESSION);
	}

	private dispatchLogoutActions(dispatch: Dispatch): void {
		dispatch(startLogout());
		dispatch(clearUser());
		dispatch(clearPermissions());
		dispatch({ type: 'DISCONNECT_FROM_MERCURE' });
		dispatch(completeLogout());
	}

	private async clearSwrCache(): Promise<void> {
		// https://swr.vercel.app/docs/mutation#mutate-multiple-items
		await mutate(() => true, undefined, { revalidate: false });
	}

	async logout(dispatch: Dispatch): Promise<void> {
		this.dispatchLogoutActions(dispatch);
		await this.clearSwrCache();
		this.apiCacheManager.clear();
		this.storageRegistry.clearAll();
	}

	panicLogout(): void {
		this.storageRegistry.clearAll();
		window.location.href = '/login';
	}
}
