import classNames from 'classnames';
import 'cropperjs/dist/cropper.css'; // eslint-disable-line import/no-unassigned-import
import { useEffect, useState } from 'react';
import { Cropper } from 'react-cropper';
import { useDropzone } from 'react-dropzone';
import { Button } from 'rsuite';

import EmptyState from 'assets/img/empty-state-upload-image.svg';
import { GhostButton } from 'components/Button';
import FullscreenModal from 'components/ContentManagement/Components/FullscreenModal';
import Icon from 'components/Icon';

import Styled from './EditableImage.style';
import { CropperToolbar } from './components/CropperToolbar';
import { EditableImageToolbar } from './components/EditableImageToolbar';
import Loading from './components/Loading/Loading';
import useEditableImage from './hooks/useEditableImage';
import { EditableImageProps } from './types/types';
import { ALLOWED_MEDIA } from './utils/utils';

/**
 *  intitially we get 3 images, either images uploaded from before or fallbackimages (Astronaut)
 * 	- if we want to edit images from before, we need to create a file from the existing image-url
 */

/**
 * EditableImage
 * - component used to add an image and be able to crop and save it
 * - uses dropzone and react-cropper to handle adding image and cropping
 *
 * @returns {JSX.Element}
 *
 */

const EditableImage = (props: EditableImageProps): JSX.Element => {
	const {
		coverImages,
		coverImage,
		grantedForEditing,
		className,
		cropperOptions,
		kind,
		moodboardImageUrl,
		deleteImage,
		isDeletingImage,
		deleteImageWithToast,
		isUploading,
		sortOrder,
		total,
		getBriefData,
		loadingBriefData,
		isVisible = true,
		onHide,
		modalTestId,
	} = props;

	const [ImageUrl, setImageUrl] = useState('');
	const [hideCoverImage, setHideCoverImage] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { cropState, imageState, editState, briefFunctions, isLoading } = useEditableImage(ImageUrl, kind, getBriefData, sortOrder, total);
	const { isEditMode, setIsEditMode } = editState;

	const { image, isReplacedImage, setIsReplacedImage } = imageState;

	const { cropDimensions, cropperRef, shouldCrop, setShouldCrop, resetCrop, resetCroppedImage } = cropState;
	const { onDrop, useCrop, postCropDimensionsAndImage } = briefFunctions;
	const { open } = useDropzone({ noClick: true, disabled: !grantedForEditing, accept: ALLOWED_MEDIA, onDrop });

	const isFallback = ImageUrl === '';
	const canCrop = grantedForEditing && image && shouldCrop && !isLoading && isEditMode;
	const canUploadImage = grantedForEditing && isFallback && !isReplacedImage && !isLoading && !isUploading;

	useEffect(() => {
		const Image = coverImages?.large || coverImages?.original || coverImage || '';
		setImageUrl(Image);
	}, [coverImages, coverImage]);

	useEffect(() => {
		moodboardImageUrl && setImageUrl(moodboardImageUrl);
	}, [moodboardImageUrl]);

	useEffect(() => {
		setHideCoverImage(!isVisible);
	}, [isVisible]);

	const onFullSizePreview = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsModalOpen(!isModalOpen);
	};

	return (
		<>
			{!hideCoverImage ? (
				<Styled.EditableImageWrapper className={className ? className : ''}>
					<EditableImageToolbar
						cropDimensions={cropDimensions}
						isLoading={isLoading}
						kind={kind}
						disabled={isLoading}
						authed={grantedForEditing}
						shouldCrop={shouldCrop}
						resetCroppedImage={resetCroppedImage}
						resetCrop={resetCrop}
						setShouldCrop={setShouldCrop}
						setIsEditMode={setIsEditMode}
						isEditMode={isEditMode}
						postCropDimensionsAndImage={postCropDimensionsAndImage}
						addImage={open}
						image={image}
						deleteImage={deleteImage}
						deleteImageWithToast={deleteImageWithToast}
						setIsReplacedImage={setIsReplacedImage}
						canSave={Boolean(isReplacedImage || cropDimensions)}
					/>
					{isDeletingImage && <Loading title='Deleting image...' />}
					{isLoading && <Loading title='Saving image...' />}
					{loadingBriefData && <Loading title='Saving image...' />}
					{isUploading && <Loading title='Uploading image...' />}

					{canUploadImage && (
						<Styled.Layer>
							<Styled.ClickArea onClick={() => open()}>
								<img src={EmptyState} />
								<div className='cta-container'>
									<GhostButton>
										<Icon name='upload' /> Upload Image
									</GhostButton>
									<GhostButton
										onClick={(e) => {
											e.stopPropagation();
											setHideCoverImage(true);
											onHide && onHide();
										}}
									>
										<Icon name='hide' /> Hide cover image
									</GhostButton>
								</div>
							</Styled.ClickArea>
						</Styled.Layer>
					)}
					<Styled.EditableImageContentWrapper>
						{canCrop && (
							<>
								<Cropper className='cropper' ref={cropperRef} src={image} {...cropperOptions} />
								<CropperToolbar cropperRef={cropperRef}>
									<p>Crop by creating a box</p>
									<Button size='xs' className='save-crop' onClick={useCrop}>
										Crop
									</Button>
								</CropperToolbar>
							</>
						)}
						{isReplacedImage ? (
							<Styled.EditableImageImage className={className} src={image} shouldHide={shouldCrop}></Styled.EditableImageImage>
						) : isFallback ? (
							<Styled.Fallback />
						) : (
							<>
								<picture>
									{!moodboardImageUrl && (
										<>
											<source media='(max-width:768px)' srcSet={coverImages?.medium ?? coverImage} />
											<source media='(max-width:992px)' srcSet={coverImages?.medium ?? coverImage} />
										</>
									)}
									<Styled.EditableImageImage
										data-testid={!moodboardImageUrl ? 'campaign-img' : 'mood-board-img'}
										alt={moodboardImageUrl ? 'Moodboard image in campaign' : 'Cover image in campaign'}
										className={classNames({ isFallback: isFallback }, className)}
										src={ImageUrl}
										shouldHide={shouldCrop}
									/>
									<Styled.ShowFullSizeButton data-testid='play-button' onClick={onFullSizePreview}>
										<Icon name='zoom' size='18' />
									</Styled.ShowFullSizeButton>
								</picture>
								<FullscreenModal
									isOpen={isModalOpen}
									handleClose={() => {
										setIsModalOpen(false);
									}}
									file={ImageUrl}
									dataTestId={modalTestId}
								/>
							</>
						)}
					</Styled.EditableImageContentWrapper>
				</Styled.EditableImageWrapper>
			) : (
				<></>
			)}
		</>
	);
};

export default EditableImage;
