import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { Styled } from './Modal.style';
import { IModalProps } from './types';

const ModalPortal = ({ dataTestId = 'modal', ...props }: IModalProps) => {
	const modalDialogRef = useRef<HTMLDivElement>(null);
	const OPEN_CSS_CLASS = 'show';

	const HandleClose = () => {
		if (props.handleClose) {
			props.handleClose();
		}

		if (props.onAfterClose) {
			props.onAfterClose();
		}
	};

	const HandleKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case 'Escape':
				HandleClose();
				break;
			default:
				break;
		}
	};

	const isMultipleModalsOpen = () => {
		if (document) {
			const allModals = document.querySelectorAll('.modal.show');
			const nrOfOpenModals = allModals && allModals.length;
			return !!(nrOfOpenModals > 1);
		}
		return false;
	};

	const HandleClickOutside = (event: TouchEvent | MouseEvent) => {
		if (isMultipleModalsOpen()) {
			return;
		}
		if (modalDialogRef.current && !modalDialogRef.current.contains(event.target as Node)) {
			HandleClose();
		}
	};

	useEffect(() => {
		if (!props.open) {
			return;
		}

		document.addEventListener('keyup', HandleKeyUp);
		document.addEventListener('mousedown', HandleClickOutside);

		return () => {
			if (!props.open) {
				return;
			}
			document.removeEventListener('keyup', HandleKeyUp);
			document.removeEventListener('mousedown', HandleClickOutside);
		};
	}, [props, props.open]);

	useEffect(() => {
		if (props.open) {
			document.body.classList.add('disable-scroll');
			modalDialogRef.current && modalDialogRef.current.focus();
			props.onShown && props.onShown();
		} else {
			document.body.classList.remove('disable-scroll');
		}
	}, [props.open]);

	function RenderModal() {
		return (
			<Styled.Modal
				tabIndex={-1}
				role={props.role}
				className={`modal ${props.className || ''} ${props.open ? OPEN_CSS_CLASS : ''}`}
				data-testid={dataTestId}
				aria-hidden={!props.open}
				size={props.size || 'md'}
			>
				<Styled.ModalDialog role='document' ref={modalDialogRef} size={props.size || 'md'} tabIndex={props.open ? 0 : -1}>
					<Styled.ModalContent>{props.children}</Styled.ModalContent>
				</Styled.ModalDialog>
			</Styled.Modal>
		);
	}

	if (typeof document === 'undefined') {
		return <></>;
	}

	return createPortal(RenderModal(), props.domNode || document.body);
};

ModalPortal.defaultProps = {
	shouldCloseOnEsc: true,
	shouldCloseOnOverlayClick: true,
	role: 'dialog',
};

export default ModalPortal;
