import styled from 'styled-components';

import { mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	max-width: 586px;
	margin: auto;
	margin-top: 10%;
	${mediaQueries.medium`
		padding: 2.25rem;
	`};
	img {
		display: block;
		margin-bottom: 40px;
		margin-left: auto;
		margin-right: auto;
	}
	text-align: center;
`;

const Headline = styled.div`
	h2,
	span {
		text-align: center;
		${typography.headings.h5};
	}

	span {
		display: block;
		text-align: left;

		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
`;

const Text = styled.div``;

const Steps = styled.div``;

const Step = styled.div`
	display: flex;
	gap: 8px;

	&:not(:last-child) {
		margin-bottom: 8px;
	}
`;

const StepBox = styled.div`
	background-color: #fafafa;
	border: 1px solid #c1c1c1;
	border-radius: 4px;
	padding: 1.7rem;
	width: 100%;
`;

const StepHeadline = styled.div`
	font-weight: 600;
	margin-bottom: 4px;
`;

const StepText = styled.div`
	p {
		margin: 0;
		font-size: 0.875rem;
		line-height: 24px;
	}
`;

const Number = styled.div`
	display: flex;
	font-weight: 700;
	font-size: 2rem;
	color: #c1c1c1;

	justify-content: right;
	width: 45px;
`;

const SkeletonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
`;

const Styled = {
	SkeletonWrapper,
	Wrapper,
	Headline,
	Text,
	Steps,
	Step,
	StepHeadline,
	StepText,
	StepBox,
	Number,
};

export default Styled;
