import Styled from 'components/DataLibrary/ContentHeader/ContentHeader.style';
import { useAppSelector } from 'views/DataLibrary/hooks';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import CreateDashboard from './Create/CreateDashboard';
import DeleteDashboard from './Delete/DeleteDashboard';
import EditDashboard from './Edit/EditDashboard';

const MainDropDown = () => {
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);

	return (
		<Styled.CustomDropdownMenu>
			<CreateDashboard />
			{dashboard.id && (
				<>
					<CreateDashboard copyDashboard />
					<EditDashboard />
					<DeleteDashboard />
				</>
			)}
		</Styled.CustomDropdownMenu>
	);
};
export default MainDropDown;
