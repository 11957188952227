import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
	isOpen: false,
};

const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		setModalIsOpen(state, action: PayloadAction<boolean>) {
			state.isOpen = action.payload;
			return state;
		},
	},
});
export const { setModalIsOpen } = modalSlice.actions;
export default modalSlice.reducer;
