import { GraphItems } from 'components/DataLibrary/Graphs/Categories/types';
import { ByInfluencerContainer, ByAssignmentType, Total, OverTime } from 'components/DataLibrary/Graphs/GraphComponents';
import { IMPRESSIONS } from 'constants/data-library';
import colors from 'styles/variables/colors';

const ImpressionGraphs = (): GraphItems => {
	const graphItems = {
		category: 'Impressions',
		items: [
			{
				title: IMPRESSIONS.TOTAL_TITLE,
				icon: IMPRESSIONS.TOTAL_ICON,
				id: IMPRESSIONS.TOTAL,
				component: <Total title={IMPRESSIONS.TOTAL_TITLE} chartType={IMPRESSIONS.TOTAL} />,
			},
			{
				title: IMPRESSIONS.BY_ASSIGNMENT_TYPE_TITLE,
				icon: IMPRESSIONS.BY_ASSIGNMENT_TYPE_ICON,
				id: IMPRESSIONS.BY_ASSIGNMENT_TYPE,
				component: <ByAssignmentType displayBar title={IMPRESSIONS.BY_ASSIGNMENT_TYPE_TITLE} chartType={IMPRESSIONS.BY_ASSIGNMENT_TYPE} />,
			},
			{
				title: IMPRESSIONS.OVERTIME_TITLE,
				icon: IMPRESSIONS.OVERTIME_ICON,
				id: IMPRESSIONS.OVERTIME,
				component: <OverTime title={IMPRESSIONS.OVERTIME_TITLE} color={colors.chartLineImpressions} isHoverable={true} chartType={IMPRESSIONS.OVERTIME} />,
			},
			{
				title: IMPRESSIONS.BY_INFLUENCER_TITLE,
				icon: IMPRESSIONS.BY_ASSIGNMENT_TYPE_ICON,
				id: IMPRESSIONS.BY_INFLUENCER,
				component: (
					<ByInfluencerContainer title={IMPRESSIONS.BY_INFLUENCER_TITLE} isTopThree={true} chartType={IMPRESSIONS.BY_INFLUENCER} valueType='impressions' />
				),
			},
		],
	};
	return graphItems;
};
export default ImpressionGraphs;
