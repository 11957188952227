import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgChevronLeftDouble(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.6233 16.8769C16.2429 17.1087 15.7368 17.0035 15.4929 16.6419L11.1294 10.1708C10.951 9.90625 10.9576 9.5656 11.1462 9.30746L15.5097 3.3342C15.7675 2.98138 16.2773 2.89399 16.6484 3.139C17.0196 3.38402 17.1115 3.86865 16.8538 4.22147L12.8017 9.76837L16.8705 15.8024C17.1144 16.164 17.0037 16.6451 16.6233 16.8769Z'
				fill='#333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.62327 16.8769C8.24286 17.1087 7.73679 17.0035 7.49293 16.6419L3.12937 10.1708C2.95096 9.90625 2.95758 9.5656 3.14615 9.30746L7.50971 3.3342C7.76745 2.98138 8.27727 2.89399 8.64841 3.139C9.01956 3.38402 9.11149 3.86865 8.85375 4.22147L4.80165 9.76837L8.87053 15.8024C9.11438 16.164 9.00368 16.6451 8.62327 16.8769Z'
				fill='#333'
			/>
		</svg>
	);
}
