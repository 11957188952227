import Styled from './BarItem.style';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BarItem = (props: any) => {
	return (
		<Styled.Wrapper gridTemplateColumns={props.gridTemplateColumns}>
			<Styled.Section fontWeight={700}>{props.label ?? 'Unknown'}</Styled.Section>
			<Styled.Section>{props.children}</Styled.Section>
			<Styled.Section fontWeight={700} textAlign='right'>
				{props.value}
				{props.sign}
			</Styled.Section>
		</Styled.Wrapper>
	);
};

export default BarItem;
