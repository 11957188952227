import { isNil } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';

import { InvoiceItemType } from 'components/AdminInvoices/types';
import StatusTag from 'components/StatusTag';
import TableComponent, { Column } from 'components/Table/V2/Table';
import { formatAmount } from 'utils/formatters';

import Styled from './InvoicesTable.style';

type InvoicesTableProps = {
	items: Array<InvoiceItemType>;
};

/**
 * InvoicesTable
 * @param {InvoicesTableProps} props
 * @returns {JSX.Element}
 */
const InvoicesTable = (props: InvoicesTableProps): JSX.Element => {
	// sort after paidAt
	const items = props.items;

	const columns: Column<InvoiceItemType>[] = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
			},
			{
				Header: 'Campaign',
				accessor: 'campaign',
				minWidth: 300,
				Cell: ({ row }) => row.original.campaign.name,
			},
			{
				Header: 'Amount',
				accessor: 'amount',
				width: 100,
				Cell: ({ row }) => formatAmount(row.original.amount),
			},
			{
				Header: 'Currency',
				accessor: 'currency',
				maxWidth: 70,
			},
			{
				Header: 'Status',
				accessor: 'status',
				maxWidth: 85,
				Cell: ({ row }) => (!isNil(row.original.paidAt) ? <StatusTag status='paid' /> : <StatusTag status='unpaid' />),
			},
			{
				Header: 'Paid at',
				accessor: 'paidAt',
				maxWidth: 150,
				Cell: ({ row }) => (!isNil(row.original.paidAt) ? moment(row.original.paidAt).format('YYYY-MM-DD') : '-'),
			},
			{
				Header: 'FortnoxId',
				accessor: 'fortnoxId',
				maxWidth: 100,
				Cell: ({ row }) => row.original.fortnoxId ?? '-',
			},
		],
		[],
	);

	return (
		<Styled.Wrapper data-testid='invoices-table'>
			<TableComponent showSearch columns={columns} data={items} enableSorting={true} />
		</Styled.Wrapper>
	);
};

export default InvoicesTable;
