import styled from 'styled-components';

import { px } from 'styles/variables/spacings';

interface Wrapper {
	width?: string;
	height?: string;
}

const Wrapper = styled.div<Wrapper>`
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || 'auto'};

	display: grid;
`;

const BarWrapper = styled.div`
	margin: ${px[0]}px 0;
`;

const SpinnerWrapper = styled.div`
	margin-left: 1.5rem;
`;

const Styled = {
	Wrapper,
	BarWrapper,
	SpinnerWrapper,
};

export default Styled;
