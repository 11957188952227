import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgArrowDown(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.462463 4.47928C1.07908 3.84024 2.07881 3.84024 2.69543 4.47928L10 12.0495L17.3046 4.47928C17.9212 3.84024 18.9209 3.84024 19.5375 4.47928C20.1542 5.11832 20.1542 6.15441 19.5375 6.79345L11.1165 15.5207C10.4999 16.1598 9.50013 16.1598 8.88352 15.5207L0.462463 6.79345C-0.154154 6.15441 -0.154154 5.11832 0.462463 4.47928Z'
				fill='#333'
			/>
		</svg>
	);
}
