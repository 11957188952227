import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgListAdd(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.5 1.5V3.5H3.5V1.5H1.5ZM1 0C0.447754 0 0 0.447754 0 1V4C0 4.55225 0.447754 5 1 5H4C4.55225 5 5 4.55225 5 4V1C5 0.447754 4.55225 0 4 0H1ZM7.3418 2.48999C7.3418 2.07568 7.67749 1.73999 8.0918 1.73999H19.0918C19.5061 1.73999 19.8418 2.07568 19.8418 2.48999C19.8418 2.9043 19.5061 3.23999 19.0918 3.23999H8.0918C7.67749 3.23999 7.3418 2.9043 7.3418 2.48999ZM7.3418 9.98999C7.3418 9.57568 7.67749 9.23999 8.0918 9.23999H12.25C12.6643 9.23999 13 9.57568 13 9.98999C13 10.4043 12.6643 10.74 12.25 10.74H8.0918C7.67749 10.74 7.3418 10.4043 7.3418 9.98999ZM8.0918 16.74C7.67749 16.74 7.3418 17.0757 7.3418 17.49C7.3418 17.9043 7.67749 18.24 8.0918 18.24H9.75C10.1643 18.24 10.5 17.9043 10.5 17.49C10.5 17.0757 10.1643 16.74 9.75 16.74H8.0918ZM1.5 11V9H3.5V11H1.5ZM0 8.5C0 7.94775 0.447754 7.5 1 7.5H4C4.55225 7.5 5 7.94775 5 8.5V11.5C5 12.0522 4.55225 12.5 4 12.5H1C0.447754 12.5 0 12.0522 0 11.5V8.5ZM1.5 16.5V18.5H3.5V16.5H1.5ZM1 15C0.447754 15 0 15.4478 0 16V19C0 19.5522 0.447754 20 1 20H4C4.55225 20 5 19.5522 5 19V16C5 15.4478 4.55225 15 4 15H1ZM15.5 11C13.02 11 11 13.02 11 15.5C11 17.98 13.02 20 15.5 20C17.98 20 20 17.98 20 15.5C20 13.02 17.98 11 15.5 11ZM15.5 18.9502C13.6001 18.9502 12.05 17.4001 12.05 15.5C12.05 13.6001 13.6001 12.05 15.5 12.05C17.3999 12.05 18.95 13.6001 18.95 15.5C18.95 17.4001 17.3999 18.9502 15.5 18.9502ZM16.0698 15.03V12.9299H15.02V15.03H12.9299V16.0798H15.02V18.0701H16.0698V16.0798H18.0798V15.03H16.0698Z'
				fill='#333333'
			/>
		</svg>
	);
}
