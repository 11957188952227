import { AxiosInstance } from 'axios';
import { JsonApiDocument } from 'json-api-models';

import { createClient } from 'shared/ApiClient/ApiClient';

import { INotificationsService } from './types';

/**
 * @deprecated use NotificationsManager instead. The NotificationsManager is part of our new ApiManager pattern.
 */
class NotificationsService implements INotificationsService {
	ENDPOINT_URL: string;
	private client: AxiosInstance;

	constructor() {
		this.ENDPOINT_URL = '/notifications';
		this.client = createClient();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async getNotifications(signal?: any) {
		return await this.client.get<JsonApiDocument>(`${this.ENDPOINT_URL}`, { signal: signal });
	}

	async updateNotificationAsRead(id: string, readAt: string) {
		return await this.client.patch<JsonApiDocument>(`${this.ENDPOINT_URL}/${id}`, { readAt: readAt });
	}
}

export default new NotificationsService();
