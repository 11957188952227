export const getErrorMessageOnFetch = (type: string): string => {
	return `${type} could not be loaded, please try again later.`;
};

export const getErrorMessageOnPost = (type: string): string => {
	return `There was an error when ${type}, please try again later.`;
};

export const getSomethingWentWrongMessage = (): string => {
	return `Something went wrong`;
};
