export enum StatusCode {
	OK = 200,
	CREATED = 201,
	NO_CONTENT = 204,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	TOO_MANY_REQUESTS = 429,
	INTERNAL_SERVER_ERROR = 500,
	NEED_TERMS_AND_CONDITIONS = 111,
}
export const isSuccessfulResponse = (status: number) => [StatusCode.OK, StatusCode.CREATED, StatusCode.NO_CONTENT].includes(status);

export interface ICollabsResponse {
	data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
	included?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
	errors?: ICollabsError[];
	status?: number;
}

export interface ICollabsData {
	id?: string;
	type?: string;
	attributes?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
	relationships?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
	links?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface ICollabsError {
	status: string;
	title: string;
	code: string;
	meta: ICollabsErrorMeta;
}

interface ICollabsErrorMeta {
	message: string;
	subErrorCode: number;
}

export interface ErrorResponse {
	errors: Error[];
}

export interface Error {
	status: string;
	title: string;
	source: Source;
}

export interface Source {
	parameter: string;
	message: string;
}
