import DIContainer from 'DependencyInjection';
import HttpClientFactory from 'shared/ApiClient/HttpClientFactory';

/**
 * @internal Should only be used by API Clients making requests to api.collabs.app
 * @deprecated use HttpClientFactory instead
 */
export default function useHttpClient() {
	return DIContainer.get(HttpClientFactory).create();
}
