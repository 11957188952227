import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgItemSharedWithMe(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M14.8586 16.1054L13.1309 17.6996C13.089 17.7383 13.0576 17.7916 13.0314 17.8498C13.0105 17.8934 13 17.937 13 17.9855L13 17.9952C13 18.0485 13.0157 18.0969 13.0366 18.1454C13.0628 18.2035 13.0942 18.2568 13.1309 18.2907L14.8586 19.8946C15.0105 20.0351 15.2618 20.0351 15.4136 19.8946C15.5654 19.7541 15.5654 19.5215 15.4136 19.381L14.3141 18.3634L19.6073 18.3634C19.822 18.3634 20 18.1987 20 18C20 17.8013 19.822 17.6366 19.6073 17.6366L14.3141 17.6366L15.4136 16.619C15.4921 16.5463 15.5288 16.4543 15.5288 16.3622C15.5288 16.2701 15.4921 16.1781 15.4136 16.1054C15.2618 15.9649 15.0105 15.9649 14.8586 16.1054Z'
				fill='#4D4B4C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.17365 4.91987C7.17601 4.91987 7.94211 4.12887 7.94211 3.20994C7.94211 2.291 7.17601 1.5 6.17365 1.5C5.17128 1.5 4.40518 2.291 4.40518 3.20994C4.40518 4.12887 5.17128 4.91987 6.17365 4.91987ZM6.17365 6.41987C7.97877 6.41987 9.44211 4.98274 9.44211 3.20994C9.44211 1.43714 7.97877 0 6.17365 0C4.36852 0 2.90518 1.43714 2.90518 3.20994C2.90518 4.98274 4.36852 6.41987 6.17365 6.41987ZM1.09951 12.9719C1.04569 12.1819 1.24075 11.0794 1.98323 10.1848C2.70796 9.31155 4.01791 8.55995 6.35511 8.55774L6.36547 8.55784C8.09331 8.55784 8.92925 8.86523 9.70635 9.42039C9.94974 9.59426 10.2911 9.54213 10.4687 9.30394C10.6464 9.06575 10.5931 8.73171 10.3497 8.55784C9.34978 7.84347 8.27679 7.49125 6.37629 7.48992L6.36542 7.48982C3.7622 7.48982 2.10916 8.33917 1.1362 9.5115C0.185806 10.6566 -0.0537908 12.036 0.00949561 13.0246V13.7194C0.00949561 14.0143 0.253774 14.2533 0.555107 14.2533C0.856439 14.2533 1.10072 14.0143 1.10072 13.7194V12.9896L1.09951 12.9719ZM14.5994 8.6667C15.6017 8.6667 16.3678 7.8757 16.3678 6.95676C16.3678 6.03783 15.6017 5.24683 14.5994 5.24683C13.597 5.24683 12.8309 6.03783 12.8309 6.95676C12.8309 7.8757 13.597 8.6667 14.5994 8.6667ZM14.5994 10.1667C16.4045 10.1667 17.8678 8.72956 17.8678 6.95676C17.8678 5.18396 16.4045 3.74683 14.5994 3.74683C12.7942 3.74683 11.3309 5.18396 11.3309 6.95676C11.3309 8.72956 12.7942 10.1667 14.5994 10.1667ZM9.52533 16.7186C9.47151 15.9286 9.66657 14.8261 10.4091 13.9315C11.134 13.058 12.4446 12.3062 14.7831 12.3045L14.7913 12.3045C16.5192 12.3045 17.3551 12.6119 18.1322 13.1671C18.3756 13.341 18.7169 13.2888 18.8946 13.0506C19.0723 12.8124 19.019 12.4784 18.7756 12.3045C17.7752 11.5899 16.7018 11.2377 14.7999 11.2366L14.7912 11.2365C12.188 11.2365 10.535 12.0859 9.56202 13.2582C8.61163 14.4034 8.37203 15.7827 8.43531 16.7714V17.4661C8.43531 17.761 8.67959 18 8.98093 18C9.28226 18 9.52654 17.761 9.52654 17.4661V16.7364L9.52533 16.7186Z'
				fill='#4D4B4C'
			/>
		</svg>
	);
}
