import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Icon from 'components/Icon';
import { isMobile } from 'shared/utils/navigator';

import MenuLink from './Components/MenuLink';
import Styled from './MainNavigation.style';
import { IMainNavigationProps, menuItem } from './types';

/**
 * Main navigation
 * Menu that supports only two levels.
 * @param {IMainNavigationProps} props
 * @returns {JSX.Element}
 */
const MainNavigation = (props: IMainNavigationProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const [activeChildMenu, setActiveChildMenu] = useState<number>(-1);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const location = useLocation();

	const closeMenu = () => {
		setIsOpen(false);
		setActiveChildMenu(-1);
	};

	const handleChildrenClick = (idx: number) => {
		if (idx === activeChildMenu) {
			setActiveChildMenu(-1);
		} else {
			setActiveChildMenu(idx);
		}
	};

	const handleClickOutside = (e: MouseEvent): void => {
		if (wrapperRef.current && !wrapperRef.current.contains((e as { target: Node | null }).target)) {
			closeMenu();
		}
	};

	const handleEscapeKey = (e: KeyboardEvent): void => {
		if (wrapperRef.current && e.key === 'Escape') {
			closeMenu();
		}
	};

	const handleHamburgerClick = (): void => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		document.addEventListener('keyup', handleEscapeKey, true);

		return () => {
			document.removeEventListener('click', handleClickOutside, true);
			document.removeEventListener('keyup', handleEscapeKey, true);
		};
	}, []);

	return isMobile() ? (
		<div ref={wrapperRef}>
			<Styled.hamburgerMenu onClick={handleHamburgerClick} className={classNames({ open: isOpen })}>
				<span />
				<span />
				<span />
			</Styled.hamburgerMenu>

			<Styled.Wrapper className={isOpen ? 'open' : ''}>
				<Styled.MenuList>
					{props.menuItems &&
						props.menuItems.map((item: menuItem, idx: number) => {
							if (item.condition && !item.condition()) {
								return null;
							}

							return (
								<Styled.MenuItem
									key={idx}
									data-testid={`menu-${item.title}`}
									className={classNames({ expanded: activeChildMenu === idx, 'active-parent': location && location.pathname.includes(item.url) })}
									level={0}
									hasChildren={item.children !== undefined && item.children.length > 0}
								>
									<Styled.MenuItemRow onClick={() => handleChildrenClick(idx)}>
										<MenuLink
											isTopParent={item.children && item.children.length > 0}
											url={item.url}
											title={item.title}
											iconName={item.icon}
											tabIndex={0}
											onClick={() => !item.children && closeMenu()}
										/>
										{item.children && item.children.length > 0 && (
											<Styled.ToggleIcon>
												<Icon name='chevron-right' />
											</Styled.ToggleIcon>
										)}
									</Styled.MenuItemRow>
									{item.children && item.children.length > 0 && (
										<Styled.MenuList className={activeChildMenu === idx ? 'open' : ''}>
											{item.children &&
												item.children.map((childItem: menuItem) => {
													if (childItem.condition && !childItem.condition()) {
														return null;
													}
													return (
														<Styled.MenuItem level={1} hasChildren={false} key={`1_${childItem.title}_${idx}`}>
															<MenuLink url={childItem.url} title={childItem.title} iconName={childItem.icon} tabIndex={0} onClick={() => closeMenu()} />
														</Styled.MenuItem>
													);
												})}
										</Styled.MenuList>
									)}
								</Styled.MenuItem>
							);
						})}
				</Styled.MenuList>
			</Styled.Wrapper>
		</div>
	) : (
		<Styled.RowMenu>
			{props.menuItems &&
				props.menuItems.map((item: menuItem, idx: number) => {
					if (item.condition && !item.condition()) {
						return null;
					}
					if (item.title === 'Home') return null;
					return (
						<Link to={item.url} key={idx} className={classNames({ active: location.pathname.includes(item.url) })}>
							{item.title}
						</Link>
					);
				})}
		</Styled.RowMenu>
	);
};

export default MainNavigation;
