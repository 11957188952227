import { useState, useEffect } from 'react';

import { Doughnut } from 'components/Chart';
import colors from 'styles/variables/colors';

/**
 * @returns {JSX.Element}
 */
const CircleChart = (props: { total: number; items: Array<{ label: string; value: number }> }): JSX.Element => {
	const [innerText, setInnerText] = useState<number | string>(0);
	const [datasets, setDatasets] = useState<{ total: number; data: number[]; labels: string[] }>({
		total: 0,
		labels: ['Unknown'],
		data: [0],
	});

	useEffect(() => {
		if (props.items) {
			const labels: Array<string> = [];
			const data: Array<number> = [];
			props.items.forEach((item: { label: string; value: number }) => {
				if (item.value) {
					labels.push(item.label);
					data.push(item.value);
				}
			});
			setDatasets({
				total: props.total,
				labels: labels,
				data: data,
			});
			setInnerText(props.total);
		}
	}, [props.items]);

	useEffect(() => {
		return () => {
			setDatasets({
				total: 0,
				labels: ['Unknown'],
				data: [0],
			});
			setInnerText(0);
		};
	}, []);

	return (
		<Doughnut
			labels={datasets.labels}
			outsideLabel={true}
			legendDisplay={false}
			backgroundColors={[colors.chartPrimary, colors.chartSecondary, colors.chartTertiary]}
			data={datasets.data}
			innerText={innerText}
			cutout='75%'
			spacing={0}
			borderWidth={0}
			innerFontSize='1em'
		/>
	);
};

export default CircleChart;
