import { injectable } from 'inversify';
import { isNil } from 'lodash';
import useSWR, { Key, Middleware, SWRHook } from 'swr';

import { ApiCollectionResponse, ApiResponse, ApiSingleResponse, ResourceObject } from 'services/ApiClient/types';
import IRepository from 'utils/Repository/IRepository';

import ApiCacheManager from './ApiCacheManager';
import { CreateConfig, DeleteConfig, UpdateConfig, SWRExtendedResponse, SWRRepositoryResponse, SWRConfigurationInternalForManagers } from './types';

/**
 * The APIManager is a base class to make sure we use SWR correctly.
 *
 * When extending this class you may provide a default repository type. Ie the
 * PublisherManager will use the ModelRepository<PublisherModel>. But a more
 * generic manager (like the OrganizationManager) will use the default IRepository.
 *
 * <code>
 *   class PublisherManager extends ApiManager<ModelRepository<PublisherModel>> {...}
 *   class OrganizationManager extends ApiManager {...}
 * </code>
 *
 * A method may override the default repository by providing a custom one in the
 * config to the swr method. They should also specify the type of the repository.
 *
 * <code>
 *   return this.swr<InfluencerListFolderCollectionResponse, FolderRepository>(key, fetcher, { repository: this.folderRepository });
 * </code>
 *
 * If a `fetcher` returns an array of ApiResponse, the `multipleApiResponses` option
 * must be true. It is also recommended not to specify the type (ie use "undefined") to the swr method.
 * That will make the method return a SWRRepositoryResponse.
 *
 * <code>
 *   return this.swr(key, fetcher, { multipleApiResponses: true });
 * </code>
 *
 * The methods that are using the `swr` method don't need to specify any return value,
 * it is inferred from the swr method.
 *
 * This is a very typical method:
 *
 * <code>
 *   public listBrands(queryBuilder = RequestQueryBuilder.create<ListBrandsQuery>()) {
 * 	    const key = `listBrands::${queryBuilder.toHash()}`;
 * 	    const fetcher = () => this.client.listBrands(queryBuilder.toQuery());
 *
 * 	    return this.swr<BrandCollectionResponse>(key, fetcher);
 *   }
 * </code>
 */
@injectable()
abstract class ApiManager<RepositoryType extends IRepository = IRepository> {
	private repository: RepositoryType;
	private cacheManager: ApiCacheManager;
	private strategy;

	public constructor(cacheManager: ApiCacheManager, repository: RepositoryType) {
		this.repository = repository;
		this.cacheManager = cacheManager;
		this.strategy = _swr;
	}

	/**
	 * This method is a way to mock the API manager's SWR without involving react hooks.
	 */
	public mockStrategy() {
		// @ts-ignore
		this.strategy = _fetch;
	}

	/**
	 * This will remove all items in the repository.
	 * This is generally only used in tests.
	 */
	public clearRepository() {
		return this.repository.clear();
	}

	/**
	 * Ask the API to get the model again
	 */
	protected refetch(resourceName: string, id: string) {
		this.cacheManager.updateModel({ type: resourceName, id });
	}

	public getRepository(): IRepository {
		return this.repository;
	}

	protected createModel<Model>(apiCreate: Promise<ApiSingleResponse>, config: CreateConfig<ResourceObject>): Promise<Model> {
		if (config.optimisticModel === undefined || !config.mutateFn) {
			return apiCreate.then((response) => {
				this.repository.mergeWith(response);
				// The mutate function will revalidate, we must do it here too
				if (config.mutateFn) {
					config.mutateFn(undefined, { revalidate: true, populateCache: false });
				}

				return response.data as Model;
			});
		}

		const options = {
			rollbackOnError: true,
			populateCache: false,
			// @ts-ignore config.optimisticModel is never undefined here.
			optimisticData: this.createOptimisticDataFunction(config),
		};

		return config.mutateFn(apiCreate, options).then((response): Model => {
			if (response && response.data) {
				return response.data as Model;
			}

			throw new Error('Optimistic create failed, did not get a response back');
		});
	}

	protected updateModel<Model>(apiUpdate: Promise<ApiSingleResponse>, config: UpdateConfig<ResourceObject>): Promise<Model> {
		if (config.optimisticModel === undefined) {
			// No optimistic updates..
			return apiUpdate.then((response) => {
				this.repository.mergeWith(response);
				this.cacheManager.updateModel(response.data);

				return response.data as Model;
			});
		}

		// Be optimistic
		const options = {
			rollbackOnError: true,
			populateCache: false,
			// @ts-ignore config.optimisticModel is never undefined here.
			optimisticData: this.createOptimisticDataFunction(config),
		};

		return this.cacheManager.optimisticUpdateModel(config.optimisticModel.type, config.optimisticModel.id, apiUpdate, options).then((response): Model => {
			if (response && response.data) {
				return response.data as Model;
			}

			throw new Error('Optimistic update failed, did not get a response back');
		});
	}

	protected deleteModel(apiDelete: Promise<void>, resourceName: string, id: string, config: DeleteConfig): Promise<RepositoryType> {
		if (config.optimistic !== undefined && !config.optimistic) {
			// No optimistic delete..
			return apiDelete.then(() => {
				this.repository.delete(resourceName, id);
				this.cacheManager.deleteModel(resourceName, id);

				return this.repository;
			});
		}

		const deleteFromApiResponse = (apiResponse: ApiResponse) => {
			if (Array.isArray(apiResponse.data)) {
				// We only care about collection responses (single responses cannot be just "null")
				for (const key in apiResponse.data) {
					if (apiResponse.data[key].id === id && apiResponse.data[key].type === resourceName) {
						apiResponse.data.splice(Number(key), 1);
					}
				}
			}

			// Do we have includes?
			if (!Array.isArray(apiResponse.included)) {
				return;
			}

			for (const index in apiResponse.included) {
				// Single response
				if (apiResponse.included[index].id === id && apiResponse.included[index].type === resourceName) {
					apiResponse.included.splice(Number(index), 1);
					return;
				}
			}
		};

		// Be optimistic
		const options = {
			rollbackOnError: true,
			populateCache: false,
			optimisticData: (currentData: ApiResponse | ApiResponse[] | undefined): ApiResponse | ApiResponse[] => {
				if (currentData === undefined) {
					return { data: { id: '', type: 'deleted' } };
				}
				// Clone the original data
				const clone = structuredClone(currentData);
				if (!Array.isArray(clone)) {
					if (clone.data !== undefined) {
						deleteFromApiResponse(clone);
					}
				} else {
					for (const apiResponse of clone) {
						if (apiResponse.data !== undefined) {
							deleteFromApiResponse(apiResponse);
						}
					}
				}

				return clone;
			},
		};

		return this.cacheManager.optimisticDeleteModel(resourceName, id, apiDelete, options).then(() => this.repository);
	}

	/**
	 * Override SWR to get correct return values.
	 * This will add "repository" and "result" by using a middleware.
	 */
	/* eslint-disable @typescript-eslint/no-explicit-any */
	protected swr<FetcherType extends ApiResponse | undefined, MethodRepositoryType extends IRepository = RepositoryType>(
		key: any,
		fetcher: any,
		customConfig: SWRConfigurationInternalForManagers = {},
	): FetcherType extends ApiResponse
		? SWRExtendedResponse<FetcherType, FetcherType extends ApiCollectionResponse ? FetcherType['data'][number][] : FetcherType['data'], MethodRepositoryType>
		: SWRRepositoryResponse {
		const repositoryMiddleware: Middleware =
			(useSWRNext: SWRHook) =>
			<FetcherType, Error>(key: Key, fetcher: any, config: any) => {
				const output = useSWRNext<FetcherType, Error, Key>(key, fetcher, config);

				let result: any | undefined = undefined;
				if (!isNil(output.data)) {
					if (customConfig.multipleApiResponses) {
						for (const apiResponse of output.data as ApiResponse[]) {
							this.repository.mergeWith(apiResponse);
							this.addResponseToCacheManager(apiResponse, key);
						}
					} else {
						const apiResponse = output.data as ApiResponse;
						result = apiResponse.data;
						if (isNil(result)) {
							throw new Error('ApiResponse does not have a "data" property. Check your configuration and your SWR Fetcher.');
						}
						this.repository.mergeWith(apiResponse);
						this.addResponseToCacheManager(apiResponse, key);
					}
				}

				return { ...output, result: result, repository: customConfig.repository ?? this.repository };
			};

		// @ts-ignore
		return this.strategy<FetcherType, Error>(`${this.constructor.name}::${key}`, fetcher, { ...customConfig, use: [repositoryMiddleware] });
	}

	private addResponseToCacheManager(apiResponse: ApiResponse, key: Key) {
		if (Array.isArray(apiResponse.data)) {
			for (const item of apiResponse.data) {
				this.cacheManager.addSWRKey(item.type, item.id, `${key}`);
			}
		} else {
			this.cacheManager.addSWRKey(apiResponse.data.type, apiResponse.data.id, `${key}`);
		}

		for (const item of apiResponse.included ?? []) {
			this.cacheManager.addSWRKey(item.type, item.id, `${key}`);
		}
	}

	/**
	 * Return a function to be used with SWR's "optimisticData".
	 */
	private createOptimisticDataFunction(config: UpdateConfig<ResourceObject> & { optimisticModel: ResourceObject }) {
		return (currentData: ApiResponse | ApiResponse[] | undefined): ApiResponse | ApiResponse[] => {
			if (currentData === undefined) {
				return { data: config.optimisticModel };
			}

			// Clone the original data
			const clone = structuredClone(currentData);

			if (!Array.isArray(clone)) {
				this.injectModelInApiResponse(clone, config.optimisticModel);
			} else {
				for (const apiResponse of clone) {
					this.injectModelInApiResponse(apiResponse, config.optimisticModel);
				}
			}

			return clone;
		};
	}

	private injectModelInApiResponse(apiResponse: ApiResponse, optimisticModel: ResourceObject): void {
		// If multiple api response, ignore "first" model
		// TODO document that.. ^^

		if (apiResponse.data === undefined) {
			// We dont have a "previous" response,
			return;
		}

		if (!Array.isArray(apiResponse.data)) {
			// Single response (Update only)
			if (apiResponse.data.id === optimisticModel.id && apiResponse.data.type === optimisticModel.type) {
				apiResponse.data = optimisticModel;
			}
		} else {
			// Collection response
			for (const key in apiResponse.data) {
				if ((optimisticModel.id === '' || apiResponse.data[key].id === optimisticModel.id) && apiResponse.data[key].type === optimisticModel.type) {
					if (optimisticModel.id === '') {
						apiResponse.data.unshift(optimisticModel);
					} else {
						apiResponse.data[key] = optimisticModel;
					}
					break;
				}
			}
		}

		// Do we have includes?
		if (!Array.isArray(apiResponse.included) || optimisticModel.id === '') {
			return;
		}

		for (const index in apiResponse.included) {
			// Single response
			if (apiResponse.included[index].id === optimisticModel.id && apiResponse.included[index].type === optimisticModel.type) {
				apiResponse.included[index] = optimisticModel;
				return;
			}
		}
	}
}

/**
 * Use a small abstraction to be able to opt-out of the SWR hook in tests.
 */
const _swr = <T, E>(key: any, fetcher: any, config: any) => {
	return useSWR<T, E>(key, fetcher, config);
};

/**
 * For testing purposes, do a plain fetch without the SWR hook.
 */
const _fetch = async (_key: any, fetcher: any, config: any) => {
	const swrResponse = {
		data: await Promise.resolve(fetcher()),
		error: undefined,
		isLoading: false,
		isValidating: false,
		mutate: () => {},
	};

	const fn = () => {
		return swrResponse;
	};

	// Call middleware
	return config.use[0](fn);
};

export default ApiManager;
