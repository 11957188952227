/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { ListInvoicesQuery } from 'api-queries';
import { PaymentInvoiceCollectionResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import InvoiceApiClientInterface from './InvoiceApiClientInterface';

@injectable()
export default class InvoiceApiClient implements InvoiceApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List invoices
	 */
	async listInvoices(params: ListInvoicesQuery): Promise<PaymentInvoiceCollectionResponse> {
		const response = await this.client.get<PaymentInvoiceCollectionResponse>(`/invoices`, { params });
		return response.data;
	}
}
