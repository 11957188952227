import { inject, injectable } from 'inversify';

import { CampaignCommissionModel } from 'api-models';
import { CreateCommissionPayload, UpdateCommissionPayload } from 'api-payloads';
import { CreateCommissionQuery, ListCommissionsQuery, UpdateCommissionQuery } from 'api-queries';
import { CampaignCommissionCollectionResponse, CampaignCommissionSummaryResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, DeleteConfig, UpdateConfig, SWRConfiguration } from './types';

import type CampaignApiClientInterface from 'services/ApiClient/CampaignApiClientInterface';

type Repository = ModelRepository<CampaignCommissionModel>;

@injectable()
class CampaignCommissionManager extends ApiManager<Repository> {
	private readonly client: CampaignApiClientInterface;

	public constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.CampaignApiClientInterface) client: CampaignApiClientInterface,
	) {
		super(cacheManager, new ModelRepository<CampaignCommissionModel>('commission'));
		this.client = client;
	}

	public listCommissions(campaignId: string, queryBuilder = RequestQueryBuilder.create<ListCommissionsQuery>(), config: SWRConfiguration = {}) {
		const key = `listCommissions::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listCommissions(campaignId, queryBuilder.toQuery());

		return this.swr<CampaignCommissionCollectionResponse>(key, fetcher, config);
	}

	public getSummary(campaignId: string, config: SWRConfiguration = {}) {
		const key = `getSummary::${campaignId}`;
		const fetcher = () => this.client.viewCommissionSummary(campaignId, { include: ':hateoas(false)' });

		return this.swr<CampaignCommissionSummaryResponse>(key, fetcher, config);
	}

	public create(
		campaignId: string,
		payload: CreateCommissionPayload,
		queryBuilder = RequestQueryBuilder.create<CreateCommissionQuery>(),
		config: CreateConfig<CampaignCommissionModel> = {},
	): Promise<CampaignCommissionModel> {
		return this.createModel<CampaignCommissionModel>(this.client.createCommission(campaignId, payload, queryBuilder.toQuery()), config);
	}

	public update(
		campaignId: string,
		productId: string,
		payload: UpdateCommissionPayload,
		queryBuilder = RequestQueryBuilder.create<UpdateCommissionQuery>(),
		config: UpdateConfig<CampaignCommissionModel> = {},
	): Promise<CampaignCommissionModel> {
		return this.updateModel<CampaignCommissionModel>(this.client.updateCommission(campaignId, productId, payload, queryBuilder.toQuery()), config);
	}

	public delete(campaignId: string, productId: string, config: DeleteConfig = {}): Promise<Repository> {
		return this.deleteModel(this.client.deleteCommission(campaignId, productId), 'commission', productId, config);
	}
}

export default CampaignCommissionManager;
