import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgMessageEmpty(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_1626_38756)'>
				<path
					d='M3.81 19C3.49 19 3.19 18.83 3.03 18.55C2.84 18.21 2.88 17.79 3.14 17.49C3.68 16.89 3.96 16.16 4.11 15.61H0.9C0.4 15.61 0 15.2 0 14.71V1.9C0 1.4 0.41 1 0.9 1H19.09C19.59 1 19.99 1.41 19.99 1.9V14.71C19.99 15.21 19.58 15.61 19.09 15.61H8.73C7.57 18.03 5.04 18.77 3.98 18.98C3.92 18.99 3.86 19 3.81 19ZM1.43 14.18H4.76C5.03 14.18 5.28 14.3 5.45 14.5C5.62 14.7 5.7 14.97 5.66 15.24C5.6 15.62 5.44 16.37 5.05 17.18C6.01 16.78 7.03 16.05 7.55 14.76C7.69 14.42 8.01 14.19 8.39 14.19H18.57V2.43H1.43V14.18Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1626_38756'>
					<rect width='19.99' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
