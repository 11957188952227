import styled from 'styled-components';

import colors from 'styles/theme/colors';

const Wrapper = styled.div`
	width: 100%;
	margin: 16px auto 45px auto;
`;

const Content = styled.div`
	margin: 0 auto 32px auto;
	ol {
		list-style-type: unset !important;
	}

	.help-text {
		padding-left: 1rem;
		margin-bottom: 28px;

		li {
			line-height: 1.5;
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}
`;

const TrackingLinkContainer = styled.div`
	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;

const CampaignCodeContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	width: fit-content;
	padding: 1rem;
	border-radius: 10px;
	background-color: ${colors.mist};
	div {
		&.code {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}
`;

const Styled = {
	Wrapper,
	Content,
	TrackingLinkContainer,
	CampaignCodeContainer,
};

export default Styled;
