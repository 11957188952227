import { isAxiosError } from 'axios';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DarkButton } from 'components/Button';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import { FacebookPage } from 'components/Settings/Profiles/types';
import { FEATURE_FLAG_SIGNUP_REQUIRE_NETWORK } from 'constants/feature-flag-keys';
import { ApiStatus, Network } from 'constants/socialMedia';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { InfluencerType, UserType } from 'reducers/UserReducers/types';
import FacebookAuthService from 'services/FacebookApi/Facebook-auth.service';
import toast from 'services/Toast';
import { updateUser } from 'shared/User/User.helpers';
import { gtagEvent } from 'utils/ga';

import FacebookConnector from './Components/Facebook';
import ConnectedFacebookProfiles from './Components/Facebook/ConnectedFacebookProfiles';
import SuccessMessage from './Components/SuccessMessage';
import ConnectedTikTokProfiles from './Components/TikTok/ConnectedTikTokProfiles';
import TikTokConnector from './Components/TikTok/TikTokConnector';
import Styled from './SocialProfileConnector.style';
import { SOCIAL_MEDIA_TYPES, socialMediaPatforms } from './Utils';
import { useTikTokConnector } from './hooks';

type SocialProfileConnectorProps = {
	hasInviteToken?: boolean;
	isSignUp?: boolean;
	isForceConnect?: boolean;
	disableTikTokConnector?: boolean;
	disableFacebookConnector?: boolean;
	onClickCreateAccount?: () => void;
};

/**
 * SocialProfileConnector
 * @param {SocialProfileConnectorProps} props
 * @returns {JSX.Element}
 */
const SocialProfileConnector = ({
	hasInviteToken,
	isSignUp = false,
	isForceConnect = false,
	onClickCreateAccount,
	disableTikTokConnector,
	disableFacebookConnector,
}: SocialProfileConnectorProps): JSX.Element => {
	const [selectedTab, setSelectedTab] = useState<SOCIAL_MEDIA_TYPES | null>(null);
	const [platforms, setPlatforms] = useState<typeof socialMediaPatforms>(socialMediaPatforms);
	const [connectedToFacebook, setConnectedToFacebook] = useState<boolean>(false);

	const [FbPagesIsLoading, setFbPagesIsLoading] = useState<boolean>(false);
	const [FbPages, setFbPages] = useState<Array<FacebookPage>>([]);
	const [FbPagesWithInstagramAccount, setFbPagesWithInstagramAccount] = useState<Array<FacebookPage>>([]);
	const [FbPagesWithoutInstagramAccount, setFbPagesWithoutInstagramAccount] = useState<Array<FacebookPage>>([]);

	const [isTikTokLoading, setIsTikTokLoading] = useState<boolean>(false);

	const [successMessage, setSuccessMessage] = useState<string>('');

	const [isEnabled] = useFeatureToggle();
	const location = useLocation();

	const DISPLAY_INSTAGRAM = location.pathname === '/influencer/settings/instagram';
	const DISPLAY_TIKTOK = location.pathname === '/influencer/settings/tiktok';

	const user: UserType = useAppSelector((state) => state.user);
	const { updateApiToken } = useTikTokConnector();

	const POLL_INTERVAL = 1000;
	let getFacebookIPagesInterval: ReturnType<typeof setTimeout>;

	const connectedTikTokProfiles = user.influencers.filter((x) => x.network === Network.TIKTOK && x.apiStatus === ApiStatus.CONNECTED);

	const navigate = useNavigate();

	const metaConnectionSucceeded = (getPages: boolean) => {
		setConnectedToFacebook(true);
		setFbPagesIsLoading(true);
		setSelectedTab(null);
		updateUser()
			.then(() => {
				if (getPages) {
					// Poll pages because until backend completes the connection
					getFacebookIPagesInterval = setInterval(() => {
						getConnectedFacebookPages();
						setSuccessMessage('Instagram connected');
					}, POLL_INTERVAL);
				}
			})
			.catch((error) => {
				console.error(error);
				toast.error(getSomethingWentWrongMessage());
				setFbPagesIsLoading(false);
			});
	};

	const metaDisconnectSucceeded = () => {
		setFbPages([]);
		setFbPagesWithInstagramAccount([]);
		setFbPagesWithoutInstagramAccount([]);
		setConnectedToFacebook(false);
		setSuccessMessage('');
	};

	const getConnectedFacebookPages = () => {
		setFbPagesIsLoading(true);
		FacebookAuthService.getBusinessAccounts()
			.then((res) => {
				if (res) {
					// Check for instagram accounts with collabs Id.
					const fbPageWithAccounts = res.filter((page: FacebookPage) =>
						!isNil(page.attributes.instagramAccounts) ? page.attributes.instagramAccounts.filter((account) => account.collabsId !== null).length > 0 : false,
					);
					const fbPageWithOutAccounts = res.filter(
						(page: FacebookPage) =>
							page.attributes.instagramAccounts?.length === 0 || page.attributes.instagramAccounts?.filter((account) => account.collabsId === null).length > 0,
					);

					setFbPagesWithInstagramAccount(fbPageWithAccounts);
					setFbPagesWithoutInstagramAccount(fbPageWithOutAccounts);

					setConnectedToFacebook(true);
					setFbPages(res);
					setFbPagesIsLoading(false);
					clearInterval(getFacebookIPagesInterval);
					setSelectedTab(null);
				}
			})
			.catch(() => {
				gtagEvent('FB-connect-failed-error');
				const connectedAccounts = user.influencers?.filter((influencer: InfluencerType) => influencer.apiStatus === ApiStatus.CONNECTED);
				if (connectedAccounts.length > 0) {
					toast.error('Connected pages could not be fetched. Try again later');
				}
				setFbPagesIsLoading(false);
			});
	};

	const tikTokConnectionSucceeded = async (code: string, _params: URLSearchParams, url: string) => {
		setSelectedTab(null);
		setIsTikTokLoading(true);

		try {
			await updateApiToken(url, code);
			await updateUser();
			setSuccessMessage('TikTok connected');
		} catch (error) {
			console.error('TikTok Connection Error:', error); // More detailed logging
			gtagEvent('TikTok-connect-failed-error'); // Log specific errors

			if (isAxiosError(error) && error?.response && error?.response?.data?.errors.length > 0) {
				error.response.data.errors.map((error: { code: string; status: string; title: string }) => {
					if (error.code === '1110') return toast.error('This TikTok account is already connected. Please contact the support');
				});
			}

			toast.error('Error connecting the account. Please try again later.');
		} finally {
			setIsTikTokLoading(false);
		}
	};

	const RenderConnectors = (type: SOCIAL_MEDIA_TYPES | null) => {
		switch (type) {
			case SOCIAL_MEDIA_TYPES.TIKTOK:
				return <TikTokConnector onCode={tikTokConnectionSucceeded} />;
			case SOCIAL_MEDIA_TYPES.INSTAGRAM:
				return <FacebookConnector connectionSucceeded={metaConnectionSucceeded} />;
			default:
				return null;
		}
	};

	const onClickButton = (tab: SOCIAL_MEDIA_TYPES) => {
		setSuccessMessage('');
		if (tab === selectedTab) {
			setSelectedTab(null);
		} else {
			setSelectedTab(tab);
		}
	};

	const getFeatureFlagByType = (type: SOCIAL_MEDIA_TYPES) => {
		switch (type) {
			case SOCIAL_MEDIA_TYPES.TIKTOK:
				return disableTikTokConnector ?? false;
			default:
				return disableFacebookConnector ?? false;
		}
	};

	const enabledPlatforms = () => {
		return socialMediaPatforms.map((item) => {
			return { ...item, disabled: getFeatureFlagByType(item.type) };
		});
	};

	useEffect(() => {
		if (user.connectedToFacebook) {
			getConnectedFacebookPages();
		}

		setPlatforms(enabledPlatforms());
	}, []);

	return DISPLAY_INSTAGRAM ? (
		<>
			{!user.connectedToFacebook && RenderConnectors(SOCIAL_MEDIA_TYPES.INSTAGRAM)}
			<Styled.ConnectedProfiles>
				{(user.connectedToFacebook || !FbPagesIsLoading) && (
					<ConnectedFacebookProfiles
						connectedPages={FbPages}
						FbPagesWithInstagramAccount={FbPagesWithInstagramAccount}
						FbPagesWithoutInstagramAccount={FbPagesWithoutInstagramAccount}
						connectedToFacebook={connectedToFacebook}
						FbPagesIsLoading={FbPagesIsLoading}
						disconnectSucceeded={metaDisconnectSucceeded}
						isSignUp={isSignUp}
					/>
				)}
			</Styled.ConnectedProfiles>
		</>
	) : DISPLAY_TIKTOK ? (
		<>
			{RenderConnectors(SOCIAL_MEDIA_TYPES.TIKTOK)}
			{successMessage !== '' && <SuccessMessage text={successMessage} />}
			{successMessage && !isTikTokLoading && connectedTikTokProfiles.length > 0 && <TikTokConnector onCode={tikTokConnectionSucceeded} dispayAsLink />}
			<Styled.ConnectedProfiles>
				<ConnectedTikTokProfiles connectedProfiles={connectedTikTokProfiles} isLoading={isTikTokLoading} />
			</Styled.ConnectedProfiles>
		</>
	) : (
		<Styled.Wrapper data-testid='select-platform'>
			<Styled.SocialMediaPlatforms>
				<Styled.Headline>Select platform</Styled.Headline>
				<ul className='select-platform'>
					{platforms
						.filter((x) => !x.disabled)
						.map((platform, index) => {
							return (
								<li key={index}>
									<Styled.SocialMediaPlatformButton
										onClick={() => onClickButton(platform.type)}
										className={classNames({ active: selectedTab === platform.type })}
										data-testid={`connect-${platform.label}`}
									>
										<Icon name={platform.iconName} /> {platform.label}
									</Styled.SocialMediaPlatformButton>
								</li>
							);
						})}
				</ul>
			</Styled.SocialMediaPlatforms>
			<Styled.ConnectorWrapper>
				{successMessage && !isTikTokLoading && connectedTikTokProfiles.length > 0 && <TikTokConnector onCode={tikTokConnectionSucceeded} dispayAsLink />}
				{RenderConnectors(selectedTab)}
			</Styled.ConnectorWrapper>

			<Styled.ConnectedProfiles>
				{(FbPagesIsLoading || isTikTokLoading) && <LoadingSpinner position='center' />}
				{(user.connectedToFacebook || !FbPagesIsLoading) && (
					<ConnectedFacebookProfiles
						connectedPages={FbPages}
						FbPagesWithInstagramAccount={FbPagesWithInstagramAccount}
						FbPagesWithoutInstagramAccount={FbPagesWithoutInstagramAccount}
						connectedToFacebook={connectedToFacebook}
						FbPagesIsLoading={FbPagesIsLoading}
						disconnectSucceeded={metaDisconnectSucceeded}
						isSignUp={isSignUp}
					/>
				)}
				<ConnectedTikTokProfiles connectedProfiles={connectedTikTokProfiles} isLoading={isTikTokLoading} />
			</Styled.ConnectedProfiles>
			{isSignUp && (
				<Styled.SignUpButtonWrapper>
					<DarkButton
						onClick={() => onClickCreateAccount && onClickCreateAccount()}
						disabled={!hasInviteToken && !connectedToFacebook && isEmpty(connectedTikTokProfiles) && isEnabled(FEATURE_FLAG_SIGNUP_REQUIRE_NETWORK)}
					>
						{hasInviteToken ? 'Skip' : 'Create account'}
					</DarkButton>
				</Styled.SignUpButtonWrapper>
			)}
			{isForceConnect && (
				<Styled.SignUpButtonWrapper>
					<DarkButton
						onClick={() => navigate('/influencer/dashboard')}
						disabled={!connectedToFacebook && isEmpty(connectedTikTokProfiles) && isEnabled(FEATURE_FLAG_SIGNUP_REQUIRE_NETWORK)}
					>
						Proceed
					</DarkButton>
				</Styled.SignUpButtonWrapper>
			)}
		</Styled.Wrapper>
	);
};

export default SocialProfileConnector;
