import { ReactNode } from 'react';

import Styled from './BarItem.style';

type Props = {
	label: string;
	children: ReactNode;
	value: string | number;
	sign?: 'K' | '%';
};

const BarItem = ({ label, children, value, sign }: Props) => {
	return (
		<Styled.Wrapper>
			<Styled.Section>{label}</Styled.Section>
			<Styled.Section>{children}</Styled.Section>
			<Styled.Section fontWeight={700} textAlign='right'>
				{`${value} ${sign}`}
			</Styled.Section>
		</Styled.Wrapper>
	);
};

export default BarItem;
