import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgSocialProfile(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.6473 17.1182C13.3117 17.992 11.7152 18.5 10 18.5C8.13998 18.5 6.4195 17.9026 5.01998 16.8891C7.95779 14.6947 11.6627 14.8376 14.6473 17.1182ZM15.8551 16.1619C12.2502 13.2193 7.51494 12.9697 3.86612 15.8844C2.40064 14.3571 1.5 12.2837 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10C18.5 12.4252 17.4843 14.6133 15.8551 16.1619ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.0001 10.5C11.3808 10.5 12.5001 9.38071 12.5001 8C12.5001 6.61929 11.3808 5.5 10.0001 5.5C8.61934 5.5 7.50006 6.61929 7.50006 8C7.50006 9.38071 8.61934 10.5 10.0001 10.5ZM10.0001 12C12.2092 12 14.0001 10.2091 14.0001 8C14.0001 5.79086 12.2092 4 10.0001 4C7.79092 4 6.00006 5.79086 6.00006 8C6.00006 10.2091 7.79092 12 10.0001 12Z'
				fill='#333333'
			/>
		</svg>
	);
}
