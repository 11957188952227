import styled from 'styled-components';

import { ButtonSecondary } from 'components/Button';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div``;

const OptionsWrapper = styled.div`
	display: flex;
	column-gap: ${spacing[1].px};
	row-gap: ${spacing[1].px};
	margin-top: ${spacing[4].px};

	flex-wrap: wrap;
`;

const Option = styled.div`
	height: 40px;
	border-radius: 10px;
	border: 1px solid ${colors.onboarding.option.border};
	padding: ${spacing[1].px} ${spacing[2].px};
	cursor: pointer;

	display: flex;
	align-items: center;
	column-gap: ${spacing[1].px};

	&.selected {
		background-color: ${colors.onboarding.option.selected.background};
		color: ${colors.onboarding.option.selected.color};

		.checkbox {
			background-color: transparent;
			border-color: ${colors.onboarding.option.selected.color};

			overflow: hidden;
		}
	}

	&.error {
		border-color: ${colors.onboarding.option.error.border};
	}
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${colors.onboarding.divider};

	margin-top: ${spacing[3].px};
	margin-bottom: ${spacing[3].px};
`;

const Button = styled(ButtonSecondary)`
	display: flex;
	width: 100%;
	height: 44px;

	font-size: 1rem;
	flex: 1;
	margin-bottom: ${spacing[2].px};

	&.select-button {
		&:not(:disabled) {
			border-color: ${colors.onboarding.button.hover.background};
			background-color: ${colors.onboarding.button.hover.background};
			color: ${colors.onboarding.button.hover.color};
		}
	}
`;

const Styled = {
	Wrapper,
	OptionsWrapper,
	Option,
	Divider,
	Button,
};

export default Styled;
