import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgInfoCircle(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps} fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.5873 4.33333C10.3757 4.54497 10.2698 4.80423 10.2698 5.11111C10.2698 5.41799 10.3757 5.68254 10.5873 5.90476C10.8095 6.1164 11.0741 6.22222 11.381 6.22222C11.6878 6.22222 11.9471 6.1164 12.1587 5.90476C12.381 5.68254 12.4921 5.41799 12.4921 5.11111C12.4921 4.80423 12.381 4.54497 12.1587 4.33333C11.9471 4.11111 11.6878 4 11.381 4C11.0741 4 10.8095 4.11111 10.5873 4.33333ZM9.96825 8.57143C10.0212 8.6455 10.037 8.73545 10.0159 8.84127L8.84127 13.746C8.79894 13.9153 8.77778 14.0847 8.77778 14.254C8.77778 14.6984 8.91005 15.0265 9.1746 15.2381C9.43915 15.4497 9.75132 15.5556 10.1111 15.5556C10.4815 15.5556 10.836 15.4444 11.1746 15.2222L13 13.9841L12.3651 13.0476L10.5397 14.2857C10.455 14.3386 10.3757 14.3651 10.3016 14.3651C10.2063 14.3651 10.127 14.328 10.0635 14.254C10.0106 14.1799 10 14.0794 10.0317 13.9524L11.2063 9.04762C11.2487 8.87831 11.2698 8.70899 11.2698 8.53968C11.2698 8.09524 11.1376 7.7672 10.873 7.55556C10.6085 7.34391 10.2963 7.23809 9.93651 7.23809C9.55556 7.23809 9.20106 7.34921 8.87302 7.57143L7 8.84127L7.63492 9.77778L9.49206 8.50794C9.56614 8.46561 9.6455 8.44444 9.73016 8.44444C9.84656 8.44444 9.92593 8.48677 9.96825 8.57143Z'
				fill='#333333'
			/>
		</svg>
	);
}
