import { Helmet } from 'react-helmet';

import fallbackLogotype from 'assets/img/logo/collabs-logo-dark.svg';

import Styled from './Login.style';
import Footer from './components/Footer';
import ForgotPasswordForm from './components/ForgotPasswordForm';

/**
 * LoginForgotView
 * @todo tests
 * @returns {JSX.Element}
 */
const LoginForgotView = (): JSX.Element => (
	<Styled.Wrapper>
		<Helmet>
			<title>Forgot password | Collabs</title>
		</Helmet>
		<Styled.Inner>
			<Styled.Section>
				<img src={fallbackLogotype} alt='Collabs' width='110px' height='auto' />
				<ForgotPasswordForm />
			</Styled.Section>
		</Styled.Inner>
		<Footer />
	</Styled.Wrapper>
);

export default LoginForgotView;
