import { inject, injectable } from 'inversify';

import { AssignmentModel } from 'api-models';
import { CreateAssignmentPayload, UpdateAssignmentPayload } from 'api-payloads';
import { CreateAssignmentQuery, ListAssignmentsQuery, UpdateAssignmentQuery, ViewAssignmentQuery } from 'api-queries';
import { AssignmentCollectionResponse, AssignmentResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, DeleteConfig, UpdateConfig, SWRConfiguration } from './types';

import type AssignmentApiClientInterface from 'services/ApiClient/AssignmentApiClientInterface';

type Repository = ModelRepository<AssignmentModel>;

@injectable()
class AssignmentManager extends ApiManager<Repository> {
	private readonly client: AssignmentApiClientInterface;

	public constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.AssignmentApiClientInterface) client: AssignmentApiClientInterface,
	) {
		super(cacheManager, new ModelRepository<AssignmentModel>('assignment'));
		this.client = client;
	}

	/**
	 * This is an influencer action
	 */
	public listAssignments(queryBuilder = RequestQueryBuilder.create<ListAssignmentsQuery>(), config: SWRConfiguration = {}) {
		const key = `listAssignments::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listAssignments(queryBuilder.toQuery());

		return this.swr<AssignmentCollectionResponse>(key, fetcher, config);
	}

	public get(campaignId: string, assignmentId: string | null, queryBuilder = RequestQueryBuilder.create<ViewAssignmentQuery>()) {
		const key = `get::${campaignId}_${assignmentId}_${queryBuilder.toHash()}`;
		const fetcher = () => (assignmentId === null ? null : this.client.view(campaignId, assignmentId, queryBuilder.toQuery()));

		return this.swr<AssignmentResponse>(key, fetcher);
	}

	public create(
		campaignId: string,
		payload: CreateAssignmentPayload,
		queryBuilder = RequestQueryBuilder.create<CreateAssignmentQuery>(),
		config: CreateConfig<AssignmentModel> = {},
	): Promise<AssignmentModel> {
		return this.createModel<AssignmentModel>(this.client.create(campaignId, payload, queryBuilder.toQuery()), config);
	}

	public update(
		campaignId: string,
		assignmentId: string,
		payload: UpdateAssignmentPayload,
		queryBuilder = RequestQueryBuilder.create<UpdateAssignmentQuery>(),
		config: UpdateConfig<AssignmentModel> = {},
	): Promise<AssignmentModel> {
		return this.updateModel<AssignmentModel>(this.client.update(campaignId, assignmentId, payload, queryBuilder.toQuery()), config);
	}

	public delete(campaignId: string, assignmentId: string, config: DeleteConfig = {}): Promise<Repository> {
		return this.deleteModel(this.client.delete(campaignId, assignmentId), 'assignment', assignmentId, config);
	}
}

export default AssignmentManager;
