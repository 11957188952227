import styled from 'styled-components';

import { GhostButton } from 'components/Button';
import { detailItem, detailText, detailTitle } from 'components/NewBriefPage/NewBreifPage.style';
import colors from 'styles/variables/colors';

const cm = colors.contentManagement;

const Wrapper = styled.div`
	opacity: 0;
	visibility: hidden;
	transition:
		opacity 200ms ease-in-out,
		visibility 200ms ease-in-out;
	max-height: 100%;

	&.visible {
		opacity: 1;
		visibility: visible;
	}
`;

const Container = styled.div`
	margin-bottom: 20px;
	padding: 0 1.5rem 1rem 1.5rem;
	line-height: 1.5;
	button {
		display: block;
	}
`;

const Heading = styled.h3`
	font-size: 1rem;
	color: ${cm.briefInfo.headingColor};
	margin-bottom: 16px;
`;

const Background = styled.div`
	p {
		color: ${cm.briefInfo.background.color};
	}
`;

const TagsContainer = styled.div`
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const Tags = styled.div`
	display: flex;
	flex-flow: wrap;
`;

const Tag = styled.div`
	padding: 0.5rem;
	border: 1px solid ${cm.briefInfo.tag.borderColor};
	border-radius: 2px;
	background-color: ${cm.briefInfo.tag.background};
	max-height: 28px;
	line-height: 0.6;
	margin-bottom: 8px;

	&:not(:last-child) {
		margin-right: 8px;
	}

	span {
		font-size: 0.875rem;
		color: ${cm.briefInfo.tag.color};
	}
`;

const AccordionContent = styled.div`
	position: relative;
	max-height: 150px;
	overflow: hidden;
	opacity: 0.75;
	visibility: visible;
	transition:
		max-height 150ms ease-in-out,
		padding-top 150ms ease-in-out,
		opacity 150ms ease-in-out,
		visibility 150ms ease-in-out;
	padding-top: 1.25rem;

	p {
		white-space: pre-line;
		margin-top: 0;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const CustomAccordion = styled.div`
	border-bottom: 1px solid ${cm.briefInfo.accordion.borderBottomColor};
	margin-bottom: 24px;
	padding-bottom: 1.5rem;
	cursor: pointer;

	${Heading} {
		cursor: pointer;
		color: ${cm.briefInfo.accordion.headingColor};
		line-height: 1;
		margin-bottom: 0;
		transition: color 200ms ease-in-out;
	}

	&.selected,
	&:hover {
		${Heading} {
			color: ${cm.briefInfo.accordion.selected.headingColor};
		}
	}

	&:not(.selected) {
		-webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
		mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
	}

	&.selected {
		${AccordionContent} {
			visibility: visible;
			max-height: 9999px;
			opacity: 1;
		}
	}
`;

const DescriptionList = styled.div`
	padding: 0;

	ul {
		margin: 0;
		padding-left: 1rem;

		li {
			white-space: pre-line;
			line-height: 1.5;
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}
	}
`;

const BriefButton = styled(GhostButton)`
	display: block;
	width: 100%;
	border-color: transparent;
	max-height: 44px;
	border: 2px solid ${colors.mainBlack};
	font-size: 1rem;
	&:hover:not(:disabled),
	&:focus {
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
	}
`;

const TagsWrapper = styled.div`
	padding: 0 1.5rem;
`;

const CTAContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${cm.briefInfo.CTAContainerBorderColor};
	padding: 0.5rem 0 0.8125rem 0;
	margin-bottom: 20px;
	gap: 16px;
`;

const DetailItem = styled(detailItem)``;
const DetailTitle = styled(detailTitle)`
	font-size: 1rem;
	color: ${({ theme }) => theme.colors.placeholder};
`;
const DetailText = styled(detailText)`
	color: ${({ theme }) => theme.colors.placeholder};
`;

export const Styled = {
	Wrapper,
	Container,
	Heading,
	Background,
	TagsContainer,
	Tags,
	Tag,
	CustomAccordion,
	AccordionContent,
	DescriptionList,
	BriefButton,
	TagsWrapper,
	CTAContainer,
	DetailItem,
	DetailTitle,
	DetailText,
};
