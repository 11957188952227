import React, { useEffect, useState, memo } from 'react';

import BrandAffiliations from 'components/Discovery/Components/BrandAffiliations/BrandAffiliations';
import CountrySummaryList from 'components/Discovery/Components/CountrySummaryList';
import GenderData from 'components/Discovery/Components/GenderData';
import InfluencerAudienceGraph from 'components/Discovery/Components/InfluencerAudienceGraph/InfluencerAudienceGraph';
import ProfileCard from 'components/Discovery/Components/ProfileCard';
import Icon from 'components/Icon';
import colors from 'styles/variables/colors';

import Styled from './InfluencerDetailCard.style';
import { extractCountryData, extractGenderData } from './helpers';
import { InfluencerDetailCardProps } from './types';

const InfluencerDetailCardDiscovery = memo(({ influencer, whyDoIGetLink, setIsExpanded, showReasonHandler, isExpanded }: InfluencerDetailCardProps) => {
	const [datasets, setDatasets] = useState<{ total: number; data: number[]; labels: string[] }>({
		total: 0,
		labels: ['Women', 'Men'],
		data: [100, 200],
	});
	const [countryData, setCountryData] = useState<{ name: string; followers: number }[]>([]);

	useEffect(() => {
		if (influencer.audienceDetail) {
			const { genders, countries } = influencer.audienceDetail;

			if (genders) {
				const { data, labels } = extractGenderData(influencer);
				setDatasets({
					total: influencer.followersCount,
					labels,
					data,
				});
			}

			if (countries) {
				const countryData = extractCountryData(influencer);
				setCountryData(countryData);
			}
		}
	}, [influencer]);

	return (
		<Styled.Wrapper
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<Styled.Section className='influencer-info'>
				<Styled.Div className='divide-line'>
					<Styled.InfluencerData className='label'>Gender</Styled.InfluencerData>
					<Styled.InfluencerData className='value'>{influencer.gender ? influencer.gender : 'unknown'}</Styled.InfluencerData>
				</Styled.Div>
				<Styled.Div className='divide-line'>
					<Styled.InfluencerData className='label'>Age</Styled.InfluencerData>
					<Styled.InfluencerData className='value'>{influencer.age ? influencer.age : 'unknown'}</Styled.InfluencerData>
				</Styled.Div>
				<Styled.Div>
					<Styled.InfluencerData className='label'>Country</Styled.InfluencerData>
					<Styled.InfluencerData className='value'>{influencer.country ? influencer.country.toUpperCase() : 'unknown'}</Styled.InfluencerData>
				</Styled.Div>
			</Styled.Section>
			{whyDoIGetLink && (
				<Styled.ActionButtonWithText
					className={isExpanded ? 'selected' : ''}
					data-testid='edit-budget-button'
					onClick={() => {
						// @ts-ignore
						setIsExpanded(!isExpanded);
						showReasonHandler(whyDoIGetLink!);
					}}
				>
					<p>
						<strong>Why do I get this result</strong>
					</p>
					<Icon name={isExpanded ? 'cross' : 'arrow-right'} size='12' />
				</Styled.ActionButtonWithText>
			)}
			{/*
					Add this again when Jon know where we get it from
					{influencer.related.length > 0 ? (
						<Styled.Section>
							<PlatformTab
								relatedAccounts={influencer.related.map((account) => {
									if (account.direction === 'outgoing') {
										return account.to;
									} else if (account.direction === 'incoming') {
										return account.from;
									}
								})}
								defaultInfluencerData={influencer}
								onSelectPlatform={(id: string) => onFetchInfluencerData(id)}
							/>
						</Styled.Section>
					) : null} */}
			<Styled.Section>
				<Styled.DataContainer>
					<Styled.DataBlockTitle>Statistics</Styled.DataBlockTitle>
					<ProfileCard data={influencer} />
				</Styled.DataContainer>
			</Styled.Section>
			<Styled.Section>
				<Styled.DataContainer>
					<Styled.DataBlockTitle>Age span of followers</Styled.DataBlockTitle>
					{influencer.audienceDetail && <InfluencerAudienceGraph audienceAge={influencer.audienceDetail.ages} />}
				</Styled.DataContainer>
			</Styled.Section>
			<Styled.Section>
				<Styled.DataContainer>
					<Styled.DataBlockTitle>Top 3 countries</Styled.DataBlockTitle>
					<CountrySummaryList items={countryData} totalFollowers={influencer.followersCount} sign='%' isTopThree />
				</Styled.DataContainer>
			</Styled.Section>
			<Styled.Section>
				<Styled.DataContainer>
					<Styled.DataBlockTitle>Gender of followers</Styled.DataBlockTitle>
					<Styled.GenderDataContainer>
						<GenderData data={datasets} colors={[colors.chartPrimary, colors.chartSecondary, colors.chartTertiary]} />
					</Styled.GenderDataContainer>
				</Styled.DataContainer>
			</Styled.Section>
			<Styled.Section>
				<BrandAffiliations influencer={influencer} />
			</Styled.Section>
		</Styled.Wrapper>
	);
});

export default InfluencerDetailCardDiscovery;
