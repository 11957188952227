import { useEffect, useState } from 'react';

import LoadingSpinner from 'components/LoadingSpinner';
import ConfirmModal from 'components/Modals/ConfirmModal';
import Styled from 'components/SocialProfileConnector/Components/Facebook/FacebookConnector.style';
import InteractiveProfileBadge from 'components/SocialProfileConnector/Components/InteractiveProfileBadge';
import { useTikTokConnector } from 'components/SocialProfileConnector/hooks';
import { DISCONNECT } from 'constants/hateoas-keys';
import { InfluencerType } from 'reducers/UserReducers/types';
import toast from 'services/Toast';
import { updateUser } from 'shared/User/User.helpers';

type ConnectedTikTokProfiles = {
	connectedProfiles: Array<InfluencerType>;
	isLoading: boolean;
};

/**
 * ConnectedTikTokProfiles
 * @param {ConnectedTikTokProfiles} props
 * @returns {JSX.Element}
 */
const ConnectedTikTokProfiles = (props: ConnectedTikTokProfiles): JSX.Element => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [selectedProfile, setSelectedProfile] = useState<InfluencerType | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { disconnect } = useTikTokConnector();

	const onDisconnect = (influencer: InfluencerType) => {
		setSelectedProfile(influencer);
		setIsModalOpen(true);
	};

	const disconnectProfileFromTikTok = async () => {
		if (!selectedProfile || !selectedProfile.links || !selectedProfile.links[DISCONNECT]) {
			return;
		}

		setIsLoading(true);

		try {
			await disconnect(selectedProfile.links[DISCONNECT]);
			setIsModalOpen(false);
			await updateUser();
			toast.success('You have disconnected from Instagram');
		} catch (error) {
			if (error) {
				console.error(error);
			}
			toast.error(`Error disconnecting @${selectedProfile.username}. Please try again later.`);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!isModalOpen) {
			setSelectedProfile(null);
		}
	}, [isModalOpen]);

	useEffect(() => {
		return () => {
			setSelectedProfile(null);
			setIsModalOpen(false);
			setIsLoading(false);
		};
	}, []);

	return (
		<>
			{props.isLoading && <LoadingSpinner theme='dark' position='center' />}
			{!props.isLoading && props.connectedProfiles.length > 0 && (
				<>
					<Styled.ToggleTitleWrapper>
						<h5>TikTok connections</h5>
					</Styled.ToggleTitleWrapper>
					<p>You can add as many TikTok accounts as you need.</p>
					<ul>
						{props.connectedProfiles.map((influencer) => {
							return <InteractiveProfileBadge key={influencer.id} influencer={influencer} onDisconnect={onDisconnect} />;
						})}
					</ul>
					<ConfirmModal
						title={`Disconnect @${selectedProfile?.username ?? 'username'} from TikTok`}
						isFetching={isLoading}
						action={() => disconnectProfileFromTikTok()}
						isModalOpen={isModalOpen}
						toggleModal={() => setIsModalOpen(!isModalOpen)}
						buttonText='Disconnect'
						confirmButtonAsDanger
					>
						<p>
							Disconnecting your TikTok account <strong>will reduce</strong> campaign accuracy and <strong>require manual</strong> stat entry after each
							campaign.
						</p>
					</ConfirmModal>
				</>
			)}
		</>
	);
};
export default ConnectedTikTokProfiles;
