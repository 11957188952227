import classNames from 'classnames';
import React, { useState } from 'react';

import Styled from './Textarea.style';
import { TextareaProps } from './types';

/**
 * Textarea
 * Textarea component
 * @param param0
 * @returns {JSX.Element}
 */
const Textarea = ({ scale = 'medium', isWarning, isError, disabled, readOnly, onFocus, onBlur, ...props }: TextareaProps): JSX.Element => {
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
		setIsFocused(true);
		onFocus?.(e);
	};

	const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
		setIsFocused(false);
		onBlur?.(e);
	};

	return (
		<Styled.InputContainer
			className={classNames(
				{
					'has-warning': isWarning,
					'has-error': isError,
					disabled: disabled,
					readonly: readOnly,
					'has-focus': isFocused,
				},
				'input-container',
			)}
			scale={scale}
		>
			<Styled.Textarea data-testid='textarea' scale={scale} onFocus={handleFocus} onBlur={handleBlur} disabled={disabled} readOnly={readOnly} {...props} />
		</Styled.InputContainer>
	);
};

export default Textarea;
