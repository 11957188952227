import { DeleteListProps } from 'components/InfluencerManagement/Lists/types';
import DeleteModal from 'components/Modals/DeleteModal';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import InfluencerListManager from 'services/ApiManager/InfluencerList.manager';
import toast from 'services/Toast';

/**
 * Delete list component
 * @param DeleteListProps
 * @returns {JSX.Element}
 */
const DeleteList = ({ itemToDelete, isDeleteModalOpen, setIsDeleteModalOpen }: DeleteListProps): JSX.Element => {
	const manager = useInjection<InfluencerListManager>(InfluencerListManager);

	const deleteItem = () => {
		if (itemToDelete) {
			manager
				.deleteList(itemToDelete.id)
				.then(() => {
					setIsDeleteModalOpen(false);
					toast.success('List deleted');
				})
				.catch((error) => {
					setIsDeleteModalOpen(true);
					toast.error(getSomethingWentWrongMessage());
					console.error('Error deleting folder', error);
				});
		}
	};

	return (
		<DeleteModal
			title={`Delete list: ${itemToDelete?.attributes.name}`}
			isModalOpen={isDeleteModalOpen}
			toggleModal={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
			action={deleteItem}
		/>
	);
};

export default DeleteList;
