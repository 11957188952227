import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { InfluencerListFolderModel } from 'api-models';
import { ShareFolderProps } from 'components/InfluencerManagement/Folders/types';
import ShareDrawer from 'components/ShareDrawer';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import InfluencerListManager from 'services/ApiManager/InfluencerList.manager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

/**
 * Share Folder component
 *
 * When sharing a subfolder the folder will always inherit the users added to the parent folder. These users cant be removed on this level and need to be removed from the parent.
 *
 * @param ShareFolderProps
 * @returns {JSX.Element}
 */
const ShareFolder = ({ sidebarIsOpen, setSidebarIsOpen, folderToShare, allUsers }: ShareFolderProps): JSX.Element => {
	const [selectedIds, setSelectedIds] = useState<string[]>([]);

	const user = useAppSelector((state) => state.user);

	const manager = useInjection<InfluencerListManager>(InfluencerListManager);

	const queryBuilder = RequestQueryBuilder.create().withInclude('users').withInclude('parentUsers');
	const { repository, mutate } = manager.getFolder(folderToShare.id, queryBuilder);

	const folder = repository.find<InfluencerListFolderModel>('folder', folderToShare.id);
	const sharedWithUsers = folder ? repository.findByRelation<InfluencerListFolderModel, InfluencerListFolderModel>(folder, 'users') : [];
	const parentIsSharedWithUsers = folder ? repository.findByRelation<InfluencerListFolderModel, InfluencerListFolderModel>(folder, 'parentUsers') : [];

	const onSave = () => {
		return manager
			.updateFolder(folderToShare.id, { users: selectedIds }, undefined, {})
			.then(() => {
				toast.success(
					selectedIds.length > 0 ? `${folderToShare.attributes.name} is now shared with ${selectedIds.length - 1} users` : 'Folder not shared with anyone',
				);
				setSidebarIsOpen(false);
				mutate();
			})
			.catch((error) => {
				toast.error('Error sharing the folder');
				console.error('Error sharing the folder', error);
			});
	};

	useEffect(() => {
		const uniqueUsers = uniq(sharedWithUsers.concat(parentIsSharedWithUsers).map((user: InfluencerListFolderModel) => user.id));
		setSelectedIds(uniqueUsers);
	}, [sharedWithUsers?.length, sidebarIsOpen, parentIsSharedWithUsers?.length]);

	useEffect(() => {
		setSelectedIds(sharedWithUsers.map((user: InfluencerListFolderModel) => user.id));
	}, []);

	return (
		<ShareDrawer
			title='Share folder'
			sidebarIsOpen={sidebarIsOpen}
			setSidebarIsOpen={setSidebarIsOpen}
			name={folderToShare.attributes.name}
			createdByMe={folderToShare.attributes.createdByMe}
			createdBy={undefined}
			updatedAt={folderToShare.attributes.updatedAt}
			allUsers={allUsers}
			sharedWithUsers={sharedWithUsers}
			parentIsSharedWithUsers={parentIsSharedWithUsers}
			setSelectedUsers={setSelectedIds}
			selectedIds={selectedIds}
			onSave={onSave}
			nonShared='You are not sharing this folder yet'
			user={user}
		/>
	);
};

export default ShareFolder;
