import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 24 24',

	fill: '#333',
};

export function SvgChevronRight(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g id='Group_3027' data-name='Group 3027' transform='translate(-608 468) rotate(-90)'>
				<rect id='Rectangle_1876' data-name='Rectangle 1876' className='cls-1' width='24' height='24' transform='translate(444 608)' fill='none' />
				<path
					id='Path_2254'
					data-name='Path 2254'
					className='cls-2'
					d='M12.014,17.257,4.589,9.832,5.3,9.125l6.718,6.718,6.718-6.718.707.707Z'
					transform='translate(443.986 606.875)'
				/>
			</g>
		</svg>
	);
}
