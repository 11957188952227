import { isMatch, isNil } from 'lodash';

import { ApiResponse, ResourceObject } from 'services/ApiClient/types';

import IRepository from './IRepository';

/**
 * ResourceManager is a simple in-memory store for JSON:API resources.
 */
class ResourceManager implements IRepository {
	private readonly map: Map<string, ResourceObject>;
	private resourcesByType: Map<string, ResourceObject[]>;

	constructor() {
		this.map = new Map<string, ResourceObject>();
		this.resourcesByType = new Map<string, ResourceObject[]>();
	}

	public clear(): void {
		this.map.clear();
	}

	public mergeWith(apiResponse: ApiResponse): void {
		if (Array.isArray(apiResponse.data)) {
			this.mapResources(apiResponse.data);
		} else {
			this.mapResources([apiResponse.data]);
		}

		if (apiResponse.included) {
			this.mapResources(apiResponse.included);
		}
	}

	public find<T extends ResourceObject>(type: string, id: string): T | null {
		const key = `${type}:${id}`;

		if (!this.map.has(key)) {
			return null;
		}

		return this.map.get(key) as T;
	}

	public delete(type: string, id: string): boolean {
		const key = `${type}:${id}`;

		if (this.map.has(key)) {
			this.map.delete(key);
			this.setResourceByTypeCache();

			return true;
		}

		return false;
	}

	public findAll<T extends ResourceObject>(type: string): T[] {
		return (this.resourcesByType.get(type) ?? []) as T[];
	}

	/**
	 * <code>
	 *   const filtered = repository.findBy<CampaignModel>('campaign', {status: 'draft'}) ?? [];
	 * </code>
	 */
	public findBy<T extends ResourceObject>(type: string, criteria: Partial<T['attributes']>): T[] {
		return this.findAll<T>(type).filter((resource) => isMatch(resource.attributes ?? {}, criteria));
	}

	public findOneByRelation<Related extends ResourceObject, T extends ResourceObject>(
		resource: T,
		relation: keyof Required<T>['relationships'],
	): Related | null {
		const relationData = resource.relationships![relation]?.data;
		if (isNil(relationData)) {
			return null;
		}

		if (Array.isArray(relationData)) {
			throw new Error('Expected single relation, got multiple');
		}

		return this.find<Related>(relationData.type, relationData.id);
	}

	public findByRelation<Related extends ResourceObject, R extends ResourceObject>(resource: R, relation: keyof Required<R>['relationships']): Related[] {
		if (!resource.relationships) {
			return [];
		}

		const relationData = resource.relationships[relation]?.data;
		if (isNil(relationData)) {
			return [];
		}

		if (!Array.isArray(relationData)) {
			throw new Error('Expected multiple relations, got single');
		}

		const relations: Related[] = [];

		for (const { id, type } of relationData) {
			const relationItem = this.find<Related>(type, id);

			if (null !== relationItem) {
				relations.push(relationItem);
			}
		}

		return relations;
	}

	private mapResources(resources: ResourceObject[]): void {
		for (const resource of resources) {
			const { id, type } = resource;
			const key = `${type}:${id}`;

			this.map.set(key, resource);
		}
		this.setResourceByTypeCache();
	}

	private setResourceByTypeCache(): void {
		this.resourcesByType = new Map<string, ResourceObject[]>();
		for (const resource of this.map.values()) {
			const { type } = resource;
			if (!this.resourcesByType.has(type)) {
				this.resourcesByType.set(type, []);
			}

			this.resourcesByType.get(type)!.push(resource);
		}
	}
}

export default ResourceManager;
