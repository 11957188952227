import { AxiosError, AxiosInstance } from 'axios';
import { injectable } from 'inversify';
import isRetryAllowed from 'is-retry-allowed';

import { RetryConfig } from 'shared/ApiClient/types';

@injectable()
export default class RetryRequestInterceptor {
	public onError(error: AxiosError, apiClient: AxiosInstance) {
		const config = error.config as RetryConfig;
		const isRetryable = this.isNetworkError(error) || ((error.response?.status ?? 400) >= 500 && config.method?.toLowerCase() !== 'get');

		if (!isRetryable || !config || !config.retry) {
			return Promise.reject(error);
		}

		config.retry -= 1;

		return new Promise((resolve, reject) => {
			setTimeout(async () => {
				// eslint-disable-next-line no-console
				console.info('retrying the request on "%s"', config.url);

				try {
					resolve(await apiClient(config));
				} catch (e) {
					reject(e);
				}
			}, config.retryDelay);
		});
	}

	// Stolen here: https://github.com/softonic/axios-retry/blob/master/es/index.mjs#L9-L16
	private isNetworkError(error: AxiosError) {
		const CODE_EXCLUDE_LIST = ['ERR_CANCELED', 'ECONNABORTED'];

		return (
			!error.response &&
			Boolean(error.code) && // Prevents retrying cancelled requests
			!CODE_EXCLUDE_LIST.includes(error.code!) && // Prevents retrying timed out & cancelled requests
			isRetryAllowed(error) // Prevents retrying unsafe errors
		);
	}
}
