import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgCircleArrowRight(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;
	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM11.0019 4.93801L15.6118 9.54785C15.914 9.85013 15.9141 10.35 15.6119 10.6522L10.9973 15.2668C10.759 15.5051 10.3638 15.5051 10.1254 15.2667C9.88707 15.0284 9.88703 14.6331 10.1253 14.3948L13.8042 10.716L4.30658 10.7149C4.13221 10.7148 3.98107 10.6451 3.87062 10.5346C3.76017 10.4242 3.6904 10.273 3.69038 10.0987C3.69035 9.76155 3.96931 9.48258 4.30644 9.48262L13.8041 9.4837L10.1301 5.80978C9.89181 5.57144 9.89176 5.17619 10.13 4.93791C10.3683 4.69962 10.7636 4.69967 11.0019 4.93801Z'
				fill='#333333'
			/>
		</svg>
	);
}
