import styled from 'styled-components';

import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	& > div {
		&.label {
			padding: 0 ${guttersWithRem.xs};
			font-size: 0.75rem;
			color: ${colors.integratedInbox.blastModal.color.label};
		}

		&.results {
			max-height: 192px;
			overflow-y: auto;
			${scrollbarY};
		}
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
