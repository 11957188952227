import { useEffect } from 'react';

import PersonalForm from 'components/Settings/MyAccount/PersonalForm';
import PasswordForm from 'components/Settings/PasswordForm';
import Styled from 'components/Settings/Settings.style';
import { useAppSelector } from 'hooks/useUserAppSelector';
import Grid from 'styles/grid/grid';

import UserAvatar from './UserAvatar/UserAvatar';

const MyAccountSection = () => {
	const user = useAppSelector((state) => state.user);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return user ? (
		<Styled.Content>
			<h5>Manage your account</h5>
			<Grid.Container>
				<Grid.Column lg={7} xs={12}>
					<Styled.UserAvatarWrapper>
						<UserAvatar fullName={user.name} displayBorder displayRole />
					</Styled.UserAvatarWrapper>
					<PersonalForm currentUser={user} />
					<PasswordForm />
				</Grid.Column>
			</Grid.Container>
		</Styled.Content>
	) : (
		<></>
	);
};

export default MyAccountSection;
