/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import {
	CreateAssignmentReviewMediaCommentPayload,
	UpdateAssignmentReviewPayload,
	CreateAssignmentReviewCommentPayload,
	CreateAssignmentReviewInternalCommentPayload,
	CreateAssignmentReviewPayload,
	LegacyUpdateAssignmentReviewPayload,
	UpdateAssignmentReviewMediaPayload,
	CreateAssignmentReviewMediaPresignUrlPayload,
	UpdateAssignmentReviewMediaPresignUrlPayload,
} from 'api-payloads';
import {
	ListAssignmentReviewsQuery,
	CreateAssignmentReviewMediaCommentQuery,
	ViewAssignmentReviewQuery,
	UpdateAssignmentReviewQuery,
	ApproveAssignmentReviewQuery,
	ClientApproveAssignmentReviewQuery,
	ListAssignmentReviewCommentsQuery,
	CreateAssignmentReviewCommentQuery,
	ListAssignmentReviewInternalCommentsQuery,
	CreateAssignmentReviewInternalCommentQuery,
	CreateAssignmentReviewQuery,
	LegacyViewAssignmentReviewQuery,
	LegacyUpdateAssignmentReviewQuery,
	UpdateAssignmentReviewMediaQuery,
	CampaignListAssignmentReviewsQuery,
} from 'api-queries';
import {
	AssignmentReviewCollectionResponse,
	AssignmentReviewMediaCommentResponse,
	AssignmentReviewResponse,
	AssignmentReviewCommentCollectionResponse,
	AssignmentReviewCommentResponse,
	AssignmentReviewInternalCommentCollectionResponse,
	AssignmentReviewInternalCommentResponse,
	AssignmentReviewMediaResponse,
	LinkResponse,
	AssignmentReviewMediaPresignUrlResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import AssignmentReviewApiClientInterface from './AssignmentReviewApiClientInterface';

@injectable()
export default class AssignmentReviewApiClient implements AssignmentReviewApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List assignment reviews
	 */
	async listAssignmentReviews(params: ListAssignmentReviewsQuery): Promise<AssignmentReviewCollectionResponse> {
		const response = await this.client.get<AssignmentReviewCollectionResponse>(`/assignment-reviews`, { params });
		return response.data;
	}

	/**
	 * Set assignment review comment as read
	 */
	async setCommentAsRead(assignmentReviewId: string, assignmentReviewCommentId: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/assignment-reviews/${assignmentReviewId}/comments/${assignmentReviewCommentId}/reads`, undefined, {
			params,
		});
		return response.data;
	}

	/**
	 * Create assignment review media comment
	 */
	async createMediaComment(
		assignmentReviewId: string,
		assignmentReviewMediaId: string,
		payload: CreateAssignmentReviewMediaCommentPayload,
		params: CreateAssignmentReviewMediaCommentQuery,
	): Promise<AssignmentReviewMediaCommentResponse> {
		const response = await this.client.post<AssignmentReviewMediaCommentResponse>(
			`/assignment-reviews/${assignmentReviewId}/medias/${assignmentReviewMediaId}/comments`,
			payload,
			{ params },
		);
		return response.data;
	}

	/**
	 * Set assignment review media comment as read
	 */
	async setMediaCommentAsRead(
		assignmentReviewId: string,
		assignmentReviewMediaId: string,
		assignmentReviewMediaCommentId: string,
		params: { include?: string },
	): Promise<void> {
		const response = await this.client.post<void>(
			`/assignment-reviews/${assignmentReviewId}/medias/${assignmentReviewMediaId}/comments/${assignmentReviewMediaCommentId}/reads`,
			undefined,
			{ params },
		);
		return response.data;
	}

	/**
	 * View assignment review
	 */
	async view(id: string, params: ViewAssignmentReviewQuery): Promise<AssignmentReviewResponse> {
		const response = await this.client.get<AssignmentReviewResponse>(`/assignment-reviews/${id}`, { params });
		return response.data;
	}

	/**
	 * Update assignment review
	 */
	async update(id: string, payload: UpdateAssignmentReviewPayload, params: UpdateAssignmentReviewQuery): Promise<AssignmentReviewResponse> {
		const response = await this.client.patch<AssignmentReviewResponse>(`/assignment-reviews/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * Approve assignment review
	 */
	async approve(id: string, params: ApproveAssignmentReviewQuery): Promise<AssignmentReviewResponse> {
		const response = await this.client.post<AssignmentReviewResponse>(`/assignment-reviews/${id}/approve`, undefined, { params });
		return response.data;
	}

	/**
	 * Client approve assignment review
	 */
	async clientApprove(id: string, params: ClientApproveAssignmentReviewQuery): Promise<AssignmentReviewResponse> {
		const response = await this.client.post<AssignmentReviewResponse>(`/assignment-reviews/${id}/client-approve`, undefined, { params });
		return response.data;
	}

	/**
	 * List assignment review comments
	 */
	async listComments(id: string, params: ListAssignmentReviewCommentsQuery): Promise<AssignmentReviewCommentCollectionResponse> {
		const response = await this.client.get<AssignmentReviewCommentCollectionResponse>(`/assignment-reviews/${id}/comments`, { params });
		return response.data;
	}

	/**
	 * Create assignment review comment
	 */
	async createComment(
		id: string,
		payload: CreateAssignmentReviewCommentPayload,
		params: CreateAssignmentReviewCommentQuery,
	): Promise<AssignmentReviewCommentResponse> {
		const response = await this.client.post<AssignmentReviewCommentResponse>(`/assignment-reviews/${id}/comments`, payload, { params });
		return response.data;
	}

	/**
	 * Disable assignment review for client
	 */
	async disableForClient(id: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/assignment-reviews/${id}/disable-for-client`, undefined, { params });
		return response.data;
	}

	/**
	 * Enable assignment review for client
	 */
	async enableForClient(id: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/assignment-reviews/${id}/enable-for-client`, undefined, { params });
		return response.data;
	}

	/**
	 * List assignment review internal comments
	 */
	async listInternalComments(id: string, params: ListAssignmentReviewInternalCommentsQuery): Promise<AssignmentReviewInternalCommentCollectionResponse> {
		const response = await this.client.get<AssignmentReviewInternalCommentCollectionResponse>(`/assignment-reviews/${id}/internal-comments`, { params });
		return response.data;
	}

	/**
	 * Create assignment review internal comment
	 */
	async createInternalComment(
		id: string,
		payload: CreateAssignmentReviewInternalCommentPayload,
		params: CreateAssignmentReviewInternalCommentQuery,
	): Promise<AssignmentReviewInternalCommentResponse> {
		const response = await this.client.post<AssignmentReviewInternalCommentResponse>(`/assignment-reviews/${id}/internal-comments`, payload, { params });
		return response.data;
	}

	/**
	 * Reject assignment review
	 */
	async reject(id: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/assignment-reviews/${id}/reject`, undefined, { params });
		return response.data;
	}

	/**
	 * Request change on assignment review
	 */
	async requestChangeOn(id: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/assignment-reviews/${id}/request-change`, undefined, { params });
		return response.data;
	}

	/**
	 * Request assignment review
	 */
	async request(id: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/assignment-reviews/${id}/request-review`, undefined, { params });
		return response.data;
	}

	/**
	 * Create assignment review
	 */
	async create(
		campaignId: string,
		assignmentId: string,
		influencerId: string,
		payload: CreateAssignmentReviewPayload,
		params: CreateAssignmentReviewQuery,
	): Promise<AssignmentReviewResponse> {
		const response = await this.client.post<AssignmentReviewResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/influencers/${influencerId}/reviews`,
			payload,
			{ params },
		);
		return response.data;
	}

	/**
	 * @deprecated
	 */
	async legacyView(
		campaignId: string,
		assignmentId: string,
		assignmentReviewId: string,
		params: LegacyViewAssignmentReviewQuery,
	): Promise<AssignmentReviewResponse> {
		const response = await this.client.get<AssignmentReviewResponse>(`/campaigns/${campaignId}/assignments/${assignmentId}/reviews/${assignmentReviewId}`, {
			params,
		});
		return response.data;
	}

	/**
	 * Legacy update assignment review
	 */
	async legacyUpdate(
		campaignId: string,
		assignmentId: string,
		assignmentReviewId: string,
		payload: LegacyUpdateAssignmentReviewPayload,
		params: LegacyUpdateAssignmentReviewQuery,
	): Promise<AssignmentReviewResponse> {
		const response = await this.client.patch<AssignmentReviewResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/reviews/${assignmentReviewId}`,
			payload,
			{ params },
		);
		return response.data;
	}

	/**
	 * Delete assignment review media
	 */
	async deleteMedia(campaignId: string, assignmentId: string, assignmentReviewId: string, assignmentReviewMediaId: string): Promise<void> {
		const response = await this.client.delete<void>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/reviews/${assignmentReviewId}/media/${assignmentReviewMediaId}`,
		);
		return response.data;
	}

	/**
	 * Update assignment review media
	 */
	async updateMedia(
		campaignId: string,
		assignmentId: string,
		assignmentReviewId: string,
		assignmentReviewMediaId: string,
		payload: UpdateAssignmentReviewMediaPayload,
		params: UpdateAssignmentReviewMediaQuery,
	): Promise<AssignmentReviewMediaResponse> {
		const response = await this.client.patch<AssignmentReviewMediaResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/reviews/${assignmentReviewId}/media/${assignmentReviewMediaId}`,
			payload,
			{ params },
		);
		return response.data;
	}

	/**
	 * Create assignment review media download url
	 */
	async createMediaDownloadUrl(
		campaignId: string,
		assignmentId: string,
		assignmentReviewId: string,
		assignmentReviewMediaId: string,
		params: { include?: string },
	): Promise<LinkResponse> {
		const response = await this.client.post<LinkResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/reviews/${assignmentReviewId}/media/${assignmentReviewMediaId}/downloads`,
			undefined,
			{ params },
		);
		return response.data;
	}

	/**
	 * Create assignment review media presign url
	 */
	async createMediaPresignUrl(
		campaignId: string,
		assignmentId: string,
		assignmentReviewId: string,
		payload: CreateAssignmentReviewMediaPresignUrlPayload,
		params: { include?: string },
	): Promise<AssignmentReviewMediaPresignUrlResponse> {
		const response = await this.client.post<AssignmentReviewMediaPresignUrlResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/reviews/${assignmentReviewId}/presign-urls`,
			payload,
			{ params },
		);
		return response.data;
	}

	/**
	 * Delete assignment review media presign url
	 */
	async deleteMediaPresignUrl(campaignId: string, assignmentId: string, assignmentReviewId: string, presignUrlId: string): Promise<void> {
		const response = await this.client.delete<void>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/reviews/${assignmentReviewId}/presign-urls/${presignUrlId}`,
		);
		return response.data;
	}

	/**
	 * Update assignment review media presign url
	 */
	async updateMediaPresignUrl(
		campaignId: string,
		assignmentId: string,
		assignmentReviewId: string,
		presignUrlId: string,
		payload: UpdateAssignmentReviewMediaPresignUrlPayload,
		params: { include?: string },
	): Promise<AssignmentReviewMediaPresignUrlResponse> {
		const response = await this.client.patch<AssignmentReviewMediaPresignUrlResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/reviews/${assignmentReviewId}/presign-urls/${presignUrlId}`,
			payload,
			{ params },
		);
		return response.data;
	}

	/**
	 * List assignment reviews
	 */
	async campaignListAssignmentReviews(id: string, params: CampaignListAssignmentReviewsQuery): Promise<AssignmentReviewCollectionResponse> {
		const response = await this.client.get<AssignmentReviewCollectionResponse>(`/campaigns/${id}/assignment-reviews`, { params });
		return response.data;
	}
}
