import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { fontWeights } from 'styles/variables/font-weights';

const ConfirmMessage = styled.div``;

const ConfirmMessageMainMessage = styled.p`
	&:after {
		display: block;
		content: '';
		width: 100%;
		border-bottom: 1px solid ${colors.gray9};
		margin: 10px 0;
	}
`;

const ConfirmImportantNotice = styled.p`
	font-weight: ${fontWeights.bold};
`;

const DissapproveMessage = styled.div``;

const Styled = {
	ConfirmMessage,
	ConfirmMessageMainMessage,
	ConfirmImportantNotice,
	DissapproveMessage,
};
export default Styled;
