import { inject, injectable } from 'inversify';

import { ListDashboardsQuery } from 'api-queries';
import convertDashboardModelToDashboardType from 'components/DataLibrary/ConvertDashboardModelToDashboardType';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ResourceManager from 'utils/Repository/ResourceManager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import type DashboardApiClientInterface from 'services/ApiClient/DashboardApiClientInterface';

@injectable()
class DashboardHelper {
	private readonly client: DashboardApiClientInterface;

	constructor(@inject(ApiClientService.DashboardApiClientInterface) client: DashboardApiClientInterface) {
		this.client = client;
	}

	public async getDashboards(): Promise<DashboardType[]> {
		const queryBuilder = RequestQueryBuilder.create<ListDashboardsQuery>(['createPaidMediaPresignUrl', 'createDashboardUser', 'createPaidMedia'])
			.withInclude('dashboardUsers')
			.withInclude('paidMedia')
			.withInclude('users');

		const response = await this.client.listDashboards(queryBuilder.toQuery());
		const repository = new ResourceManager();
		repository.mergeWith(response);

		return response.data.map((result) => {
			const { dashboard } = convertDashboardModelToDashboardType(repository, result);
			return dashboard;
		});
	}
}

export default DashboardHelper;
