import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { rem, px } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	padding: 0 ${rem[2]}rem;
	padding-top: ${rem[2]}rem;

	&.summary-explanation {
		padding-left: 0;
	}

	.accordion__header {
		font-family: ${typography.BaseFontFamiliy};
		column-gap: ${px[1]}px;

		& > span {
			width: unset;
		}

		& > div {
			margin: 0;
		}
	}

	.accordion__title {
		font-size: 0.75rem;
		color: ${colors.dataLibrary.chartExplanationColor};
	}

	svg {
		fill: ${colors.dataLibrary.chartExplanationColor};
	}
`;

const InnerWrapper = styled.div`
	font-size: 0.875rem;
	line-height: 21px;
	& > span {
		display: block;
		&.title {
			font-weight: 600;
		}
	}
`;

const Styled = {
	Wrapper,
	InnerWrapper,
};

export default Styled;
