import styled from 'styled-components';

const Wrapper = styled.main`
	max-width: 1920px;
	width: 100%;
	height: 100%;

	margin: 0 auto;

	&.no-padding-bottom {
		padding-bottom: 0;
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
