import { FeatureToggleContext } from 'contexts/FeatureFlagContext';

import { IFeatureFlags } from './Types';

/**
 * FeatureToggle
 *
 * Context for Feature flags
 *
 * @param param
 * @returns Context provider
 */
export const FeatureToggle = ({ children, enabledFeatures }: IFeatureFlags) => {
	return <FeatureToggleContext.Provider value={{ enabledFeatures }}>{children}</FeatureToggleContext.Provider>;
};
