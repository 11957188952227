import { GraphItems } from 'components/DataLibrary/Graphs/Categories/types';
import ByInfluencerTable from 'components/DataLibrary/Graphs/GraphComponents/ByInfluencer/GraphTypes/Table/ByInfluencerTable';
import PaidMediaTable from 'components/DataLibrary/Graphs/GraphComponents/PaidMedia/PaidMediaTable';
import { INFLUENCERS, PAID_MEDIA } from 'constants/data-library';

const SummaryGraphs = (): GraphItems => {
	const graphItems = {
		category: 'Summary',
		items: [
			{
				title: 'Influencer summary',
				icon: 'dl-influencers',
				id: 'influencer_summary',
				component: <ByInfluencerTable title={INFLUENCERS.SUMMARY_TITLE} chartType={INFLUENCERS.SUMMARY} />,
			},
			{
				title: 'Paid media summary',
				icon: 'dl-influencers',
				id: 'paid_media_summary',
				component: <PaidMediaTable title={PAID_MEDIA.SUMMARY_TITLE} chartType={PAID_MEDIA.SUMMARY} />,
			},
		],
	};

	return graphItems;
};
export default SummaryGraphs;
