import styled from 'styled-components';

import { DarkButton } from 'components/Button';
import { CTALink } from 'styles/utils/CTALink';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { mediaQueries } from 'styles/variables/media-queries';

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Heading = styled.h1`
	font-size: 1.5rem;
	margin-top: 0;
	margin-bottom: 0;
	display: flex;
	justify-content: space-between;
`;

const SubmitButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const SubmitButton = styled(DarkButton)`
	width: 100%;
	font-size: 0.9688rem;

	${mediaQueries.xxl`
		font-size: 1rem;
	`};
`;

const BottomLinks = styled.div`
	width: fit-content;

	a {
		${CTALink};
	}

	&.text-center {
		text-align: center;
	}

	&.mb-0 {
		margin-bottom: 0;
	}

	&.pt-1-25 {
		padding-top: 1.25rem;
	}
`;

const FormCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const ModalHeader = styled.div`
	display: flex;
	justify-content: space-between;

	button {
		background: none;
		border: none;
		padding: 0;
		margin-left: auto;
		cursor: pointer;
	}
`;

const ModalBody = styled.div`
	& > div {
		&.agree-checkbox {
			margin-top: ${guttersWithRem.l};
			display: flex;
			align-items: flex-start;
			column-gap: ${guttersWithRem.m};
			line-height: 1;
		}
	}
`;

const ModalFooter = styled.div`
	float: right;
`;

const ButtonLink = styled.button`
	padding: 0;
	border: 0 none;
	color: ${colors.CTALinks.color};
	background: none;
	cursor: pointer;
`;

const LinkContainer = styled.div`
	a {
		font-size: 1rem;
		font-weight: 500;
		line-height: 1.5;
		letter-spacing: 0;
		text-align: right;
		${CTALink};
	}
`;

const EmailVerifiedMessage = styled.div`
	background: ${colors.login.verifiedBackground};
	border-width: 1px 0;
	border-style: dashed;
	border-color: ${colors.login.text};
	width: 100%;
	padding: 1rem;
	display: flex;
	.icon {
		margin-top: 13px;
		margin-right: 5px;
	}
`;

const VerifiedTitle = styled.p`
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 0;
`;

const VerifiedText = styled.span`
	color: #888888;
`;

const VerifiedTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const IconWrapper = styled.div`
	cursor: pointer;
`;

const Styled = {
	IconWrapper,
	FormWrapper,
	Heading,
	SubmitButtonWrapper,
	SubmitButton,
	BottomLinks,
	FormCard,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ButtonLink,
	LinkContainer,
	EmailVerifiedMessage,
	VerifiedTitle,
	VerifiedText,
	VerifiedTitleWrapper,
};

export default Styled;
