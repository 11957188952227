import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgShare(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_1539_32493)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M3.71994 5.8875L9.32994 0.2775C9.69994 -0.0925 10.2999 -0.0925 10.6699 0.2775L16.2799 5.8775C16.5699 6.1675 16.5699 6.6475 16.2799 6.9375C15.9899 7.2275 15.5099 7.2275 15.2199 6.9375L10.7499 2.4675V14.0375C10.7499 14.2475 10.6699 14.4275 10.5299 14.5675C10.3899 14.7075 10.2099 14.7875 9.99994 14.7875C9.58994 14.7875 9.24994 14.4475 9.24994 14.0375V2.4775L4.77994 6.9475C4.48994 7.2375 4.00994 7.2375 3.71994 6.9475C3.42994 6.6575 3.42994 6.1775 3.71994 5.8875ZM2.95 19.9976H17.05C17.57 19.9976 18 19.5776 18 19.0476V9.95757C18 9.43757 17.58 9.00757 17.05 9.00757H14.5C14.09 9.00757 13.75 9.34757 13.75 9.75757C13.75 10.1676 14.09 10.5076 14.5 10.5076H16.5V18.5076H3.5V10.5076H5.63C6.04 10.5076 6.38 10.1676 6.38 9.75757C6.38 9.34757 6.04 9.00757 5.63 9.00757H2.95C2.43 9.00757 2 9.42757 2 9.95757V19.0476C2 19.5676 2.42 19.9976 2.95 19.9976Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1539_32493'>
					<rect width='20' height='19.9976' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
