import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'hooks/useUserAppSelector';
import { isConnected } from 'reducers/UserReducers/helpers';
import ReferralUrlService from 'services/ReferralUrlService';

/**
 * FallbackRoute
 * Route container to check the user is logged in or not. If not logged in, navigate to the login page and set the referral url.
 * @param {ProtectedRouteProps} props
 * @returns {JSX.Element}
 */
export const FallbackRoute = (): JSX.Element => {
	const isLoggedIn = useAppSelector(isConnected);
	const loggingOut = useAppSelector(({ user }) => user.isLoggingOut);

	if (!isLoggedIn && !loggingOut) {
		ReferralUrlService.set(window.location.href);
		return <Navigate to='/login' />;
	}

	return <></>;
};
