import { CampaignInstagramOwnerResponse } from 'api-responses';
import { createClient } from 'shared/ApiClient/ApiClient';

const client = createClient();

export const MessagesService = {
	searchInfluencers: async (campaignId: string, query: string, joined: boolean = true) => {
		const params: Record<string, string | boolean> = {
			joined,
			include:
				'influencer,latestMessage,latestMessage.influencer,latestMessage.user,unreadMessages,user,user.publisher,campaign.latestMessage,campaign.latestMessage.user,campaign.latestMessage.influencer',
			exclude: 'defaultIncludes',
		};

		if (query && query.trim() !== '') {
			params.query = query;
		}
		const response = await client.get<CampaignInstagramOwnerResponse>(`/campaigns/${campaignId}/instagram-owners`, {
			params: params,
		});
		return response;
	},
};
