import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';

const Title = styled.h4`
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 5px;
`;

const SubTitle = styled.p`
	color: ${colors.gray5};
	margin-bottom: 0.8rem;
`;

const List = styled.ul`
	list-style: none;
	& > li {
		padding: 0.3rem 0;
	}
`;

const Task = styled.div`
	display: flex;
	align-items: center;
	line-height: 1.5;
	.icon {
		margin-right: 10px;
	}
`;

const Container = styled.div`
	margin-bottom: 16px;
	${mediaQueries.large`
		margin-bottom: 24px;
	`};
`;

const Styled = {
	Title,
	SubTitle,
	List,
	Task,
	Container,
};

export default Styled;
