import DOMPurify from 'dompurify';

import Styled from 'components/NewBriefPage/Components/CampaignInfo/CampaignInfo.style';
import { Campaign } from 'components/NewBriefPage/types';

type TermsAndConditionsTextProps = {
	campaign: Campaign;
};

/**
 * @returns JSX.Element
 */
const TermsAndConditionsText = ({ campaign }: TermsAndConditionsTextProps): JSX.Element => {
	return (
		<Styled.Wrapper id='termsAndConditions'>
			<Styled.DetailItem>
				<Styled.DetailTitle>Terms & Conditions</Styled.DetailTitle>
				{campaign.termsAndConditions && <Styled.DetailText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(campaign.termsAndConditions) }} />}
				<hr />
			</Styled.DetailItem>
		</Styled.Wrapper>
	);
};

export default TermsAndConditionsText;
