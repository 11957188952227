import Styled from './InvitationStatusTag.style';

export type Props = {
	invitedAt: string;
	isFull?: boolean;
};
const InvitationStatusTag = (props: Props) => {
	const getTagText = (invitedAt: string, isFull?: boolean) => {
		if (isFull) {
			return 'Full';
		} else if (checkIsNewInvitation(invitedAt)) {
			return 'New';
		} else {
			return;
		}
	};

	const text = getTagText(props.invitedAt, props.isFull);
	return (
		<Styled.Wrapper className={text}>
			<div data-testid='invitation-status'>{text}</div>
		</Styled.Wrapper>
	);
};

export const checkIsNewInvitation = (invitedAt: string) => {
	const now = new Date();
	const invitedDate = new Date(invitedAt);

	return now.getTime() - invitedDate.getTime() < 1000 * 60 * 60 * 24 * 2;
};

export default InvitationStatusTag;
