import styled from 'styled-components';

import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	overflow: hidden;
	padding: 0;
`;

const ListWrapper = styled.div`
	margin: 0 8px;
`;

const SpinnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const NoResults = styled.div`
	padding: ${spacing[2].rem};
	text-align: center;
	.value {
		font-weight: 500;
	}
`;

const NoResultsText = styled.div`
	max-width: 100%;
	overflow-wrap: break-word;
`;

const Styled = {
	Wrapper,
	ListWrapper,
	SpinnerWrapper,
	NoResults,
	NoResultsText,
};

export default Styled;
