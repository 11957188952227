import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgRotateRight(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M13.5549 5.79417C13.4349 6.19417 13.655 6.61417 14.0451 6.73417L17.1264 7.69417C17.5166 7.81417 17.9268 7.59417 18.0368 7.19417L18.9672 4.04417C19.0873 3.64417 18.8672 3.22417 18.477 3.10417C18.0868 2.98417 17.6767 3.20417 17.5666 3.60417L17.1665 4.97417C16.5162 4.00417 15.7158 3.17417 14.7354 2.51417C10.6637 -0.245835 5.17139 0.874166 2.47025 5.00417C1.1697 6.99417 0.709502 9.39417 1.1797 11.7442C1.6499 14.1142 3.00047 16.1542 4.98131 17.4942C6.01174 18.1842 7.12221 18.6342 8.2627 18.8442C11.6441 19.4842 15.2356 18.0742 17.2465 14.9842C17.5966 14.4442 17.8968 13.8642 18.1269 13.2542C18.2669 12.8942 18.0868 12.4942 17.7467 12.3542C17.3866 12.2042 17.0064 12.3942 16.8663 12.7442C16.6662 13.2542 16.4161 13.7542 16.126 14.2042C13.8451 17.7042 9.17308 18.6542 5.73163 16.3242C4.05091 15.1942 2.91043 13.4642 2.52027 11.4542C2.1301 9.46417 2.52027 7.44417 3.62073 5.76417C5.88169 2.27417 10.5437 1.32417 13.9951 3.65417C14.8355 4.22417 15.5358 4.94417 16.086 5.79417L14.4753 5.29417C14.0852 5.17417 13.675 5.39417 13.5649 5.79417H13.5549Z'
				fill='#333333'
			/>
		</svg>
	);
}
