import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgCircleArrowUp(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;
	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM6.99975 5.6001H13.519C13.9465 5.6001 14.3 5.95352 14.3 6.38092V12.9069C14.3 13.2439 14.0205 13.5234 13.6835 13.5234C13.3464 13.5234 13.0669 13.2439 13.0669 12.9069V7.70421L6.35029 14.4193C6.22697 14.5426 6.07077 14.6001 5.91457 14.6001C5.75837 14.6001 5.60217 14.5426 5.47886 14.4193C5.24045 14.1809 5.24045 13.7864 5.47886 13.548L12.1955 6.83297H6.99975C6.66269 6.83297 6.38317 6.55352 6.38317 6.21654C6.38317 5.87955 6.66269 5.6001 6.99975 5.6001Z'
				fill='#333333'
			/>
		</svg>
	);
}
