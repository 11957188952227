import { InfluencerListItemType } from 'contexts/Discovery/types';

export const extractGenderData = (influencer: InfluencerListItemType) => {
	const removedUnknownData: { [key: string]: { count: number | null; ratio: number | null } } = {};
	Object.entries(influencer.audienceDetail?.genders ?? {})
		.filter(([genderKey]) => genderKey !== 'unknown')
		.forEach(([genderKey, value]) => {
			removedUnknownData[genderKey] = value;
		});
	const data = Object.values(removedUnknownData).map((genderValue: { count: number | null; ratio: number | null }) => genderValue.ratio ?? 0);
	const labels = Object.keys(removedUnknownData).map((el) => {
		switch (el) {
			case 'male':
			case 'man':
			case 'men':
				return 'Men';
			case 'female':
			case 'woman':
			case 'women':
				return 'Women';
			default:
				return '';
		}
	});
	return { data, labels };
};

export const extractCountryData = (influencer: InfluencerListItemType) => {
	return Object.keys(influencer.audienceDetail?.countries ?? {}).map((country) => ({
		name: country.toUpperCase(),
		followers: (influencer.audienceDetail?.countries && influencer.audienceDetail?.countries[country]?.count) || 0,
	}));
};
