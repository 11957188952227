import Skeleton from 'react-loading-skeleton';

import useWindowDimensions from 'hooks/useWindowDimensions';
import { breakpoints } from 'styles/variables/media-queries';

const SkeletonCard = () => {
	const { width } = useWindowDimensions();
	const isMobileView = width <= +breakpoints.lg.split('px')[0];

	return <Skeleton width={isMobileView ? '100%' : '220px'} height='300px' borderRadius={10} />;
};

export default SkeletonCard;
