export enum SOCIAL_MEDIA_TYPES {
	INSTAGRAM,
	TIKTOK,
}

export const socialMediaPatforms = [
	{
		type: SOCIAL_MEDIA_TYPES.INSTAGRAM,
		label: 'Instagram',
		iconName: 'instagram',
		disabled: false,
	},
	{
		type: SOCIAL_MEDIA_TYPES.TIKTOK,
		label: 'TikTok',
		iconName: 'tiktok',
		disabled: false,
	},
];
