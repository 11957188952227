import Styled from './InfluencerListItem.style';

export const defaultData = {
	id: '',
	age: '',
	country: '',
	followersCount: 0,
	gender: '',
	network: '',
	networkId: '',
	networkLink: '',
	networkLinks: {
		instagram: '',
		tiktok: '',
	},
	profileImageUrl: '',
	username: '',
	audienceBrief: {
		age: { name: null, count: null, ratio: null },
		country: { name: null, count: null, ratio: null },
		gender: { name: null, count: null, ratio: null },
	},
	audienceDetail: {
		ages: {
			'13-17': { count: 0, ratio: 0 },
			'18-24': { count: 0, ratio: 0 },
			'25-34': { count: 0, ratio: 0 },
			'35-44': { count: 0, ratio: 0 },
			'45-54': { count: 0, ratio: 0 },
			'55-64': { count: 0, ratio: 0 },
			'65+': { count: 0, ratio: 0 },
		},
		countries: {},
		genders: {
			female: { count: 0, ratio: 0 },
			male: { count: 0, ratio: 0 },
		},
	},
	brandAffiliations: [],
	categories: [],
	averageViews: 0,
	relevancy: 0,
	engagement: 0,
	engagementOrganic: 0,
	engagementPaid: 0,
	paidPostsRatio: 0,
	estimatedStoryViews: 0,
	related: [],
	canBeContacted: true,
};

export const getNameAndRatio = (param: { name?: string | null; count: number | null; ratio: number | null }) => {
	if (param.name !== null && param.ratio !== null) {
		return (
			<>
				{param.name}
				<Styled.GrayText>{`(${Math.floor(param.ratio * 100)}%)`}</Styled.GrayText>
			</>
		);
	}
};
