import { ApiResponse, ResourceObject } from 'services/ApiClient/types';

import IRepository from './IRepository';
import ResourceManager from './ResourceManager';

/**
 * A repository for a specific model type
 */
class ModelRepository<T extends ResourceObject> implements IRepository {
	private readonly resourceName: string;
	private readonly resourceManager: ResourceManager;

	constructor(resourceName: string, resourceManager = new ResourceManager()) {
		this.resourceName = resourceName;
		this.resourceManager = resourceManager;
	}

	public clear(): void {
		this.resourceManager.clear();
	}

	/**
	 * Get a resource by its id
	 */
	public get(id: string): T | null {
		return this.resourceManager.find(this.resourceName, id);
	}

	/**
	 * Get all resources of this model type
	 */
	public getAll(): T[] {
		return this.resourceManager.findAll<T>(this.resourceName);
	}

	/**
	 * <code>
	 *   const filtered = repository.getBy({status: 'draft'}) ?? [];
	 * </code>
	 */
	public getBy(criteria: Partial<T['attributes']>): T[] {
		return this.resourceManager.findBy<T>(this.resourceName, criteria);
	}

	public delete(type: string, id: string): boolean {
		return this.resourceManager.delete(type, id);
	}

	public find<T extends ResourceObject>(type: string, id: string): T | null {
		return this.resourceManager.find(type, id);
	}

	public findAll<T extends ResourceObject>(type: string): T[] {
		return this.resourceManager.findAll(type);
	}

	/**
	 * <code>
	 *   const filtered = repository.findBy<CampaignModel>('campaign', {status: 'draft'}) ?? [];
	 * </code>
	 */
	public findBy<T extends ResourceObject>(type: string, criteria: Partial<T['attributes']>): T[] {
		return this.resourceManager.findBy(type, criteria);
	}

	public findOneByRelation<Related extends ResourceObject, T extends ResourceObject>(
		resource: T,
		relation: keyof Required<T>['relationships'],
	): Related | null {
		return this.resourceManager.findOneByRelation(resource, relation);
	}

	public findByRelation<Related extends ResourceObject, T extends ResourceObject>(resource: T, relation: keyof Required<T>['relationships']): Related[] {
		return this.resourceManager.findByRelation(resource, relation);
	}

	public mergeWith(apiResponse: ApiResponse): void {
		this.resourceManager.mergeWith(apiResponse);
	}
}

export default ModelRepository;
