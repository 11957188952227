import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { gtagEvent } from 'utils/ga';

/**
 * This is used to manually track down page view and send it to GA.
 */
const NavigationTracker = () => {
	const location = useLocation();

	// https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag#manual_pageviews
	useEffect(() => {
		gtagEvent('pageview', {
			page_title: document.title,
			page_location: location.pathname,
		});
	}, [location]);

	return <Outlet />;
};

export default NavigationTracker;
