import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
`;

const ProgressText = styled.span`
	display: block;
	text-align: center;
	color: #4d4b4c;
	font-size: 0.75rem;
`;

const Progress = styled.div<{ borderColor?: string; backgroundColor?: string; height?: number; radius?: number }>`
	height: ${(props) => props.height ?? 8}px;
	border: 1px solid ${(props) => props.borderColor ?? '#4D4B4C'};
	background-color: ${(props) => props.backgroundColor ?? '#F2F2F2'};
	overflow: hidden;
	border-radius: ${(props) => props.radius ?? 10}px;
	width: 100%;

	& + ${ProgressText} {
		margin-top: 8px;
	}
`;

const Bar = styled.div<{ percentage: number; progressColor?: string; radius?: number }>`
	background: ${(props) => props.progressColor ?? '#C4A8F0'};
	width: ${(props) => props.percentage}%;
	height: 100%;
	transition: width 100ms ease-in-out;
	border-radius: ${(props) => props.radius ?? 4}px;
	margin-left: 0 !important;
`;

const PercentageLabel = styled.span`
	display: block;
	color: #4d4b4c;
	text-align: center;
	font-weight: 600;
	margin-bottom: 8px;
	&.hide {
		display: none;
	}
`;

const Styled = {
	Wrapper,
	PercentageLabel,
	ProgressText,
	Progress,
	Bar,
};

export default Styled;
