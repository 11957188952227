import { JsonApiDocument, Model, Store } from 'json-api-models';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PageNotFound from 'components/ErrorPages/PageNotFound';
import LoadingSpinner from 'components/LoadingSpinner';
import { Campaign } from 'components/NewBriefPage/types';
import CampaignsService from 'services/Campaign/Campaigns.service';
import NewBriefPage from 'views/BriefPage/NewBriefPage';

import Styled from './CampaignPreview.style';

const CampaignPreview = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [campaign, setCampaign] = useState<Campaign>();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [campaignExtra, setCampaignExtra] = useState<any>(null);

	const params = useParams();

	const getBriefData = () => {
		setLoading(true);
		const getCampaign = CampaignsService.getCampaign(
			params.campaignId || '',
			'products,termsAndConditionsFile,images,brand.client.publisher,assignments,assignments.groups,assignments.groups.deadlines',
		);

		Promise.all([getCampaign])
			.then(([campaignRes]) => {
				const campaignModels = new Store();
				campaignModels.sync(campaignRes as JsonApiDocument);

				const links = campaignRes?.data?.links;
				const coverImage = links?.horizontalCoverPhoto;

				const newCampaignExtra = {
					campaign: {
						campaignImages: {
							coverImage: coverImage,
							croppedImages: {
								original: links?.originalCoverPhoto,
								large: links?.largeCoverPhoto,
								medium: links?.mediumCoverPhoto,
								small: links?.smallCoverPhoto,
							},
							moodBoardImages: campaignModels.findAll('campaignImage'),
						},
						products:
							campaignModels.findAll('campaignProduct').length > 0
								? campaignModels.findAll('campaignProduct').map((product: Model) => {
										return {
											id: product.id,
											quantity: 1,
											product: {
												productImage: product.links.image,
												name: product.attributes.name,
												link: product.attributes.link ? product.attributes.link : null,
												description: product.attributes.description,
												value: product.attributes.value,
											},
										};
									})
								: [],
						invoices:
							campaignModels.findAll('commission').length > 0
								? campaignModels.findAll('commission').map((commission) => {
										return {
											id: commission.id,
											invoice: {
												value: commission.fixedAmount,
												name: commission.name,
												currency: commission.fixedAmountCurrency,
											},
										};
									})
								: [],
						compensationsTotalValue: [
							...(campaignModels.findAll('campaignProduct').length > 0
								? campaignModels.findAll('campaignProduct').map((product: Model) => +product.attributes.value.amount)
								: []),
							...(campaignModels.findAll('commission').length > 0 ? campaignModels.findAll('commission').map((commission) => commission.fixedAmount) : []),
						].reduce((prev, current) => prev + current, 0),
						currency: 'SEK',
						assignmentsCount: campaignModels.findAll('assignment').length,
						spotsLeft: campaignRes.data.attributes.full ? 0 : 1,
						invitesCloseAt: campaignRes.data.attributes.inviteClosedAt,
						assignments:
							campaignModels.findAll('assignment').length > 0
								? campaignModels.findAll('assignment').map((assignment) => {
										return {
											id: assignment.id,
											type: assignment.kind,
											name: assignment.name,
											dos: assignment.dos,
											donts: assignment.donts,
											cta: assignment.cta,
											groups: assignment.groups,
											description: assignment.description,
											details: assignment.details,
											// eslint-disable-next-line prettier/prettier
											startTime: assignment.groups[0]?.start,
											// eslint-disable-next-line prettier/prettier
											endTime: assignment.groups[0]?.end,
											// eslint-disable-next-line prettier/prettier
											deadlines: assignment.groups[0]?.deadlines.map((deadline: Model) => {
												return {
													id: deadline.id,
													name: deadline.name,
													date: deadline.date,
												};
											}),
											details: assignment.details,
											hashtags: assignment.hashtags,
											mentions: assignment.mentions,
										};
									})
								: [],
					},
					publisher: campaignModels.findAll('publisher')[0],
					brand: campaignModels.findAll('brand')[0],
				};

				setCampaign({
					id: campaignRes.data.id,
					images: campaignRes.data.links,
					moodBoardImages: campaignModels.findAll('campaignImage'),
					pdfFiles: campaignModels.findAll('userUpload'),
					...campaignRes.data.attributes,
				});
				setCampaignExtra(newCampaignExtra);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setLoading(false);
			});
	};

	useEffect(() => {
		getBriefData();
	}, []);

	return (
		<Styled.Wrapper>
			{loading && !campaign ? (
				<Styled.CenteredInnerWrapper>
					<LoadingSpinner position='center' />
				</Styled.CenteredInnerWrapper>
			) : campaign ? (
				<NewBriefPage getBriefData={getBriefData} isLoading={loading} campaignInstagramOwner={campaignExtra} campaign={campaign} />
			) : (
				<PageNotFound />
			)}
		</Styled.Wrapper>
	);
};

export default CampaignPreview;
