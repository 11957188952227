import { css } from 'styled-components';

export const scrollbarY = css`
	& {
		overflow-y: auto !important;

		&::-webkit-scrollbar {
			background-color: transparent;
			width: 8px;
			height: 8px;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #babac0;
			border-radius: 16px;
			border: 4px solid transparent;
			transition: background-color ease-in-out 0.2s;
		}

		&::-webkit-scrollbar-button {
			display: none;
		}
	}
`;

export const scrollbarX = css`
	& {
		overflow-x: auto !important;

		&::-webkit-scrollbar {
			background-color: transparent;
			width: 8px;
			height: 8px;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #babac0;
			border-radius: 16px;
			border: 4px solid transparent;
		}

		&::-webkit-scrollbar-button {
			display: none;
		}
	}
`;
