import { IdVerificationStatus } from 'components/Payment/types';

export type InfluencerType = {
	id: string;
	collabsId: string | null;
	followersCount: number;
	username: string;
	links?: LinksType;
	apiStatus: string;
	network: string;
	isInstagramConnected: boolean;
	country: string | null;
	affiliatePreferenceRequired: boolean;
};

export type PermissionsType = {
	entities: { [key: string]: { kind: string; role: string; name: string } } | null;
	isAgent: boolean;
	isImpersonating: boolean;
	isInfluencer: boolean;
	isSuperAdmin: boolean;
};

export type InstagramBusinessAccountType = {
	id: string;
	inactivatedAt: Date | null;
	name: string;
	profilePictureUrl: string;
	username: string;
};

export type LinksType = Record<string, string>;

export enum PinebucketStatus {
	NOT_CONNECTED = 'not_connected',
	ACCOUNT_CREATED = 'account_created',
	FULLY_REGISTERED = 'fully_registered',
}

export enum UserPaymentType {
	INDIVIDUAL = 'individual',
	COMPANY = 'company',
	AGENT = 'agent',
	LEGACY_AGENT = 'legacy_agent',
	NULL = 'null',
}

export type UserType = {
	id: string;
	uuid: string;
	initials: string | null;
	name: string | null;
	firstName?: string;
	lastName?: string;
	title: string | null;
	profilePictureUrl: string | null;
	email: string;
	mobilePhoneNumber: string | null;
	address: string | null;
	address2: string | null;
	city: string | null;
	countryCode: string | null;
	postalCode: string | null;
	influencerTermsOfConditionsAcceptedAt: Date | null;
	publisherTermsOfConditionsAcceptedAt: Date | null;
	permissions: PermissionsType;
	links: LinksType | null;
	influencer: InfluencerType;
	influencers: Array<InfluencerType> | [];
	hasCompletedProfile?: boolean;
	hasBeenLinkedToInfluencers: boolean;
	connectedToFacebook: boolean; // deprate this
	instagramBusinessAccounts: Array<InstagramBusinessAccountType>;
	hasActiveInstagramBusinessAccounts: boolean;
	needsEmailVerification?: boolean;
	addressVerifiedAt: string | null;
	isLoggingOut: boolean;
	payment: {
		idVerificationStatus: IdVerificationStatus;
		pinebucketStatus?: PinebucketStatus;
		vatNumber?: string | null;
		vatNumberValid?: boolean;
		vatNumberValidatedAt?: string | null;
		type?: UserPaymentType | null;
	};
};
