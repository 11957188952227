import { JsonApiDocument, Model, Store } from 'json-api-models';
import { useEffect, useState } from 'react';

import fallbackLogotype from 'assets/img/logo/collabs-logo-dark.svg';
import { useAppSelector } from 'hooks/useUserAppSelector';
import Toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';
import { updateUser } from 'shared/User/User.helpers';
import LoginStyle from 'views/login/Login.style';

import Styled from './InfluencerSignUpContainer.style';
import BillingInformation from './Steps/BillingInformation';
import PersonalInformation from './Steps/PersonalInformation';

const PublisherIncomplete = () => {
	const [step, setStep] = useState(0);
	const [publisherEdit, setPublisherEdit] = useState<string | undefined>('');
	const incrementStep = () => setStep(step + 1);
	const [isTeamMember, setIsTeamMember] = useState(false);

	const user = useAppSelector((state) => state.user);

	const steps = [
		<PersonalInformation
			publisherEdit=''
			key={1}
			userId={user.id}
			isNewOrganization={false}
			onSubmit={
				isTeamMember
					? () => {
							updateUser()
								.then(() => (window.location.href = '/dashboard'))
								.catch(() => Toast.error('Cannot get user details, please try again'));
						}
					: () => incrementStep()
			}
			incomplete
		/>,
		<BillingInformation
			key={2}
			publisherEdit={publisherEdit}
			onSubmit={() => {
				updateUser()
					.then(() => (window.location.href = '/dashboard'))
					.catch(() => Toast.error('Cannot get user details, please try again'));
			}}
			incomplete
		/>,
	];

	const getPublisherLink = async () => {
		const { data } = await createClient().get<JsonApiDocument>('/me', { params: { includes: 'publishers' } });
		const user = new Store().sync(data) as Model;
		// Change this
		setPublisherEdit(user.publishers[0].links.edit);
		if (user.publishers[0].links.edit === undefined) {
			setIsTeamMember(true);
		}
	};

	useEffect(() => {
		getPublisherLink();
	}, []);

	return (
		<Styled.Wrapper>
			<Styled.Card>
				<Styled.CardHeader>
					<span className='card-title'>Complete your registration</span>
				</Styled.CardHeader>
				<Styled.CardBody>{steps[step]}</Styled.CardBody>
			</Styled.Card>
			<LoginStyle.LogotypeContainer>
				<img src={fallbackLogotype} alt='Collabs' />
			</LoginStyle.LogotypeContainer>
		</Styled.Wrapper>
	);
};

export default PublisherIncomplete;
