import InfluencerItem from './InfluencerItem';
import Styled from './InfluencerList.style';
import { IInfluencerList, InfluencerWithDataForGraphs } from './types';

const InfluencerList = (props: IInfluencerList) => {
	let filteredInfluencers: InfluencerWithDataForGraphs[] = [];
	if (props.influencers?.length > 0) {
		filteredInfluencers = props.influencers.sort((a: InfluencerWithDataForGraphs, b: InfluencerWithDataForGraphs) => b.value - a.value);
	}

	if (props.isTopThree) {
		filteredInfluencers = filteredInfluencers.filter((item, index) => {
			if (index < 3) {
				return item;
			}
		});
	}

	return (
		<Styled.Wrapper>
			{filteredInfluencers?.length > 0 ? (
				filteredInfluencers.map((influencer: InfluencerWithDataForGraphs) => (
					<InfluencerItem
						key={influencer.id}
						profileImage={influencer.imageUrl}
						follower={influencer.follower}
						instagramUsername={influencer.instagramUsername}
						value={influencer.value}
						sign={influencer.sign}
						isAmount={props.isAmount}
						currency={influencer.currency}
					/>
				))
			) : (
				<Styled.CenteredWrapper>
					<span>N/A</span>
				</Styled.CenteredWrapper>
			)}
		</Styled.Wrapper>
	);
};

export default InfluencerList;
