import styled from 'styled-components';

import { rem } from 'styles/variables/spacings';

const AdminInvoices = styled.div`
	padding-bottom: ${rem[4]}rem;
`;

const MainContainerInner = styled.div`
	padding-top: 2rem;
`;

const Styled = {
	AdminInvoices,
	MainContainerInner,
};

export default Styled;
