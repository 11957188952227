import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import { AssignmentType } from 'shared/helpers/Assigment/types';

type ScreenshotsProps = {
	onFrameSelector?: boolean;
	children: JSX.Element;
	isInfluencer: boolean;
	contentType: AssignmentType;
};

/**
 * Screenshots
 * Component to list single and multiple screenshots for the stats.
 *
 * @param {ScreenshotsProps} props
 * @returns {JSX.Element}
 */
const Screenshots = ({ onFrameSelector, children, isInfluencer, contentType }: ScreenshotsProps): JSX.Element => {
	const getInstagramType = (contentType: AssignmentType) => {
		switch (contentType) {
			case AssignmentType.INSTAGRAM_REEL:
				return 'reel';
			case AssignmentType.INSTAGRAM_STORY:
				return 'story';
			default:
				return 'post';
		}
	};

	const getDescription = (contentType: AssignmentType, isInfluencer: boolean) => {
		const influencerText = isInfluencer ? 'your' : 'the';
		const platformType = contentType === AssignmentType.TIKTOK_VIDEO ? 'TikTok' : 'Instagram';
		const instagramType = contentType !== AssignmentType.TIKTOK_VIDEO ? ` ${getInstagramType(contentType)}` : '';

		return `Add screenshots of ${influencerText} ${platformType} ${instagramType} statistics by scrolling and taking screenshots of all of ${influencerText} numbers.`;
	};
	return (
		<Styled.ScreenshotWrapper>
			{onFrameSelector ? (
				<h5>Screenshots</h5>
			) : (
				<>
					<div className='title'>
						<h5>Add screenshots</h5> <span>{!isInfluencer && '(optional)'}</span>
					</div>
					<p>{getDescription(contentType, isInfluencer)}</p>
				</>
			)}
			<Styled.ScreenShotSection>{children}</Styled.ScreenShotSection>
			<hr />
		</Styled.ScreenshotWrapper>
	);
};

export default Screenshots;
