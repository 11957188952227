import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgRotateLeft(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M6.44507 5.79417C6.56512 6.19417 6.34503 6.61417 5.95486 6.73417L2.87356 7.69417C2.48339 7.81417 2.07322 7.59417 1.96317 7.19417L1.03278 4.04417C0.912729 3.64417 1.13282 3.22417 1.52299 3.10417C1.91315 2.98417 2.32333 3.20417 2.43337 3.60417L2.83354 4.97417C3.48382 4.00417 4.28415 3.17417 5.26457 2.51417C9.33629 -0.245835 14.8286 0.874166 17.5298 5.00417C18.8303 6.99417 19.2905 9.39417 18.8203 11.7442C18.3501 14.1142 16.9995 16.1542 15.0187 17.4942C13.9883 18.1842 12.8778 18.6342 11.7373 18.8442C8.35588 19.4842 4.76436 18.0742 2.75351 14.9842C2.40336 14.4442 2.10323 13.8642 1.87313 13.2542C1.73308 12.8942 1.91315 12.4942 2.2533 12.3542C2.61345 12.2042 2.99361 12.3942 3.13367 12.7442C3.33375 13.2542 3.58386 13.7542 3.87398 14.2042C6.15494 17.7042 10.8269 18.6542 14.2684 16.3242C15.9491 15.1942 17.0896 13.4642 17.4797 11.4542C17.8699 9.46417 17.4797 7.44417 16.3793 5.76417C14.1183 2.27417 9.45634 1.32417 6.00488 3.65417C5.16453 4.22417 4.46423 4.94417 3.914 5.79417L5.52468 5.29417C5.91484 5.17417 6.32502 5.39417 6.43506 5.79417H6.44507Z'
				fill='#333333'
			/>
		</svg>
	);
}
