import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgNote(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.21999 3.98896H4.62999V3.97896C4.24999 3.97896 3.92999 3.66904 3.92999 3.27915V0.699818C3.92999 0.309919 4.23999 0 4.62999 0H6.21999C6.59999 0 6.91999 0.309919 6.91999 0.699818V3.28914C6.91999 3.67904 6.60999 3.98896 6.21999 3.98896ZM5.92999 0.999739H4.92999V2.98922H5.92999V0.999739ZM13.75 4.05896H15.34C15.73 4.05896 16.04 3.74904 16.04 3.35914V0.769818C16.04 0.379919 15.72 0.07 15.34 0.07H13.75C13.36 0.07 13.05 0.379919 13.05 0.769818V3.34915C13.05 3.73904 13.37 4.04896 13.75 4.04896V4.05896ZM14.05 1.06974H15.05V3.05922H14.05V1.06974ZM11.78 2.80966H8.10999C7.69999 2.80966 7.35999 2.47966 7.35999 2.05966C7.35999 1.63966 7.69999 1.30966 8.10999 1.30966H11.78C12.19 1.30966 12.53 1.63966 12.53 2.05966C12.53 2.47966 12.19 2.80966 11.78 2.80966ZM19.48 1.61958C19.77 1.85952 19.96 2.22942 19.99 2.62931H20V18.6351C19.97 19.095 19.78 19.4649 19.49 19.7049C19.22 19.9248 18.89 20.0248 18.56 19.9948H1.36999H1.27999C1.02999 19.9948 0.74999 19.8948 0.50999 19.6949C0.21999 19.4549 0.0399902 19.085 -9.76585e-06 18.6851V2.6893C0.0299902 2.21942 0.21999 1.84952 0.50999 1.60958C0.77999 1.38964 1.10999 1.28966 1.43999 1.31966H2.76999C3.17999 1.31966 3.51999 1.64957 3.51999 2.06946C3.51999 2.48935 3.17999 2.81927 2.76999 2.81927H1.48999V5.15866H18.5V2.81927H17.14C16.73 2.81927 16.39 2.48935 16.39 2.06946C16.39 1.64957 16.73 1.31966 17.14 1.31966H18.63C18.89 1.29966 19.22 1.39964 19.48 1.61958ZM1.47999 6.64827V18.4952H18.49V6.64827H1.47999ZM12.98 13.9264H4.31999C4.06999 13.9264 3.86999 14.1464 3.86999 14.4264C3.86999 14.7064 4.06999 14.9264 4.31999 14.9264H12.98C13.23 14.9264 13.43 14.7064 13.43 14.4264C13.43 14.1464 13.23 13.9264 12.98 13.9264ZM4.31999 10.1773H15.58C15.83 10.1773 16.03 10.3973 16.03 10.6773C16.03 10.9573 15.83 11.1773 15.58 11.1773H4.31999C4.06999 11.1773 3.86999 10.9573 3.86999 10.6773C3.86999 10.3973 4.06999 10.1773 4.31999 10.1773Z'
				fill='#333333'
			/>
		</svg>
	);
}
