import { inject, injectable } from 'inversify';

import { DashboardModel, DashboardUserModel } from 'api-models';
import { CreateDashboardItemPayload, CreateDashboardPayload, CreateDashboardUserPayload, UpdateDashboardPayload } from 'api-payloads';
import { CreateDashboardItemQuery, CreateDashboardQuery, ListDashboardsQuery, UpdateDashboardQuery, ViewDashboardQuery } from 'api-queries';
import { DashboardCollectionResponse, DashboardResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, DeleteConfig, UpdateConfig } from './types';

import type DashboardApiClientInterface from 'services/ApiClient/DashboardApiClientInterface';

type Repository = ModelRepository<DashboardModel>;

@injectable()
class DataLibraryManager extends ApiManager<Repository> {
	private readonly client: DashboardApiClientInterface;

	constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.DashboardApiClientInterface) client: DashboardApiClientInterface,
	) {
		super(cacheManager, new ModelRepository<DashboardModel>('dashboard'));
		this.client = client;
	}

	public listDashboards(queryBuilder = RequestQueryBuilder.create<ListDashboardsQuery>()) {
		const key = `listDashboards::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listDashboards(queryBuilder.toQuery());

		return this.swr<DashboardCollectionResponse>(key, fetcher);
	}

	public get(id: string, queryBuilder = RequestQueryBuilder.create<ViewDashboardQuery>()) {
		const key = `get::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.view(id, queryBuilder.toQuery());

		return this.swr<DashboardResponse>(key, fetcher);
	}

	public create(
		payload: CreateDashboardPayload,
		queryBuilder = RequestQueryBuilder.create<CreateDashboardQuery>(),
		config: CreateConfig<DashboardModel> = {},
	): Promise<DashboardModel> {
		return this.createModel<DashboardModel>(this.client.create(payload, queryBuilder.toQuery()), config);
	}

	public update(
		id: string,
		payload: UpdateDashboardPayload,
		queryBuilder = RequestQueryBuilder.create<UpdateDashboardQuery>(),
		config: UpdateConfig<DashboardModel> = {},
	): Promise<DashboardModel> {
		return this.updateModel<DashboardModel>(this.client.update(id, payload, queryBuilder.toQuery()), config);
	}

	public addDashboardItem(
		id: string,
		payload: CreateDashboardItemPayload,
		queryBuilder = RequestQueryBuilder.create<CreateDashboardItemQuery>(),
		config: UpdateConfig<DashboardModel> = {},
	): Promise<DashboardModel> {
		return this.updateModel<DashboardModel>(this.client.createItem(id, payload, queryBuilder.toQuery()), config);
	}

	public delete(id: string, config: DeleteConfig = {}): Promise<Repository> {
		return this.deleteModel(this.client.delete(id), 'dashboard', id, config);
	}

	/**
	 * Share this dashboard publicly
	 */
	public createUser(id: string, payload: CreateDashboardUserPayload, config: CreateConfig<DashboardModel> = {}): Promise<DashboardUserModel> {
		return this.createModel<DashboardUserModel>(this.client.createUser(id, payload, { include: ':hateoas(false)' }), config);
	}
}

export default DataLibraryManager;
