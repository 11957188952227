/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { CreateSegmentPayload, UpdateSegmentPayload, AddInfluencersToSegmentPayload } from 'api-payloads';
import { ListSegmentsQuery, CreateSegmentQuery, ViewSegmentQuery, UpdateSegmentQuery } from 'api-queries';
import { SegmentCollectionResponse, SegmentResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import SegmentApiClientInterface from './SegmentApiClientInterface';

@injectable()
export default class SegmentApiClient implements SegmentApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List segments
	 */
	async listSegments(id: string, params: ListSegmentsQuery): Promise<SegmentCollectionResponse> {
		const response = await this.client.get<SegmentCollectionResponse>(`/campaigns/${id}/segments`, { params });
		return response.data;
	}

	/**
	 * Create segment
	 */
	async create(id: string, payload: CreateSegmentPayload, params: CreateSegmentQuery): Promise<SegmentResponse> {
		const response = await this.client.post<SegmentResponse>(`/campaigns/${id}/segments`, payload, { params });
		return response.data;
	}

	/**
	 * View segment
	 */
	async view(id: string, params: ViewSegmentQuery): Promise<SegmentResponse> {
		const response = await this.client.get<SegmentResponse>(`/segments/${id}`, { params });
		return response.data;
	}

	/**
	 * Delete segment
	 */
	async delete(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/segments/${id}`);
		return response.data;
	}

	/**
	 * Update segment
	 */
	async update(id: string, payload: UpdateSegmentPayload, params: UpdateSegmentQuery): Promise<SegmentResponse> {
		const response = await this.client.patch<SegmentResponse>(`/segments/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * Add influencers to segment
	 */
	async addInfluencersTo(id: string, payload: AddInfluencersToSegmentPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/segments/${id}/influencers`, payload, { params });
		return response.data;
	}
}
