import Styled from './ConfirmMessage.style';

interface ConfirmMessageProps {
	approveState?: 'approve' | 'disapprove' | undefined;
}

/**
 * Approve
 * renders approve message along with the important notice
 * @returns {JSX.Element}
 */
const Approve = (): JSX.Element => {
	return (
		<>
			<Styled.ConfirmMessageMainMessage>
				Approving the assignment marks it as complete and notifies the influencer that this assignment is done.
			</Styled.ConfirmMessageMainMessage>
			<Styled.ConfirmImportantNotice>Important: You can&apos;t edit the commission for this assignment once it&apos;s approved.</Styled.ConfirmImportantNotice>
		</>
	);
};

/**
 * Disapprove
 * Renders the disapprove message
 * @returns {JSX.element}
 */
const Disapprove = () => {
	return (
		<Styled.DissapproveMessage>
			By declining this assignment you terminate the collaboration with the influencer. Are you sure you want to do this?
		</Styled.DissapproveMessage>
	);
};

/**
 * ConfirmMessage
 * Component to show the approve or dissaprove message in the confirm modal
 * @param {ConfirmMessageProps} props
 * @returns  {JSX.Element}
 */
const ConfirmMessage = (props: ConfirmMessageProps): JSX.Element => {
	const { approveState } = props;

	return (
		<Styled.ConfirmMessage>
			{approveState === 'approve' && <Approve />}
			{approveState === 'disapprove' && <Disapprove />}
		</Styled.ConfirmMessage>
	);
};

export default ConfirmMessage;
