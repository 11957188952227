import classNames from 'classnames';
import { useState, useEffect } from 'react';

import { GhostButton } from 'components/Button';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import { useAppDispatch } from 'views/DataLibrary/hooks';
import { setModalIsOpen } from 'views/DataLibrary/reducers/ModalSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import Styled from './SearchDashboard.style';

/**
 * @returns {JSX.Element}
 */
const SearchDashboard = (props: { arrayToFilter: Array<DashboardType>; onSelect: (item: DashboardType) => void }): JSX.Element => {
	const { arrayToFilter, onSelect } = props;
	const [searchValue, setSearchValue] = useState<string>('');
	const [filteredSuggestions, setFilteredSuggestions] = useState<Array<DashboardType>>([]);
	const [cursor, setCursor] = useState(-1);
	const dispatch = useAppDispatch();

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchValue(value);
		if (value?.length < 1) {
			setFilteredSuggestions(arrayToFilter);
		} else if (arrayToFilter?.length > 0) {
			const newFilteredSuggestions = arrayToFilter.filter((item: DashboardType) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
			setFilteredSuggestions(newFilteredSuggestions);
		}
	};

	const reset = () => {
		setFilteredSuggestions(arrayToFilter);
		setSearchValue('');
	};

	const select = (item: DashboardType) => {
		setFilteredSuggestions([]);
		setSearchValue('');
		onSelect(item);
	};

	const keyboardNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (filteredSuggestions?.length > 0) {
				setCursor((prev) => {
					return prev < filteredSuggestions.length - 1 ? prev + 1 : prev;
				});
			}
		}
		if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (filteredSuggestions?.length > 0) {
				setCursor((prev) => {
					return prev > 0 ? prev - 1 : 0;
				});
			}
		}
		if (e.key === 'Escape') {
			setCursor(-1);
			setFilteredSuggestions([]);
		}
		if (e.key === 'Enter') {
			onSelect(filteredSuggestions[cursor]);
			setFilteredSuggestions([]);
		}
	};

	useEffect(() => {
		setFilteredSuggestions(arrayToFilter);
	}, [arrayToFilter]);

	return (
		<Styled.Dropdown data-testid='select-dashboards-dropdown'>
			<label>Search dashboards</label>
			<Styled.DropdownInnerContainer>
				<Input
					value={searchValue}
					onChange={onChange}
					onKeyDown={(e) => {
						keyboardNavigation(e);
					}}
					placeholder={'Find my dashboard'}
				/>
				{searchValue?.length > 0 && (
					<Styled.CancelContainer onClick={reset}>
						<Icon name='cancel-circle' size='16' />
					</Styled.CancelContainer>
				)}
				<Styled.ListContainer>
					<label>My dashboards</label>
					<Styled.DashboardList>
						{filteredSuggestions.map((item: DashboardType, i: number) => {
							return (
								<Styled.DashboardListItem
									data-testid='dl-search-dashboard-filtered-item'
									key={i}
									onClick={() => {
										select(item);
									}}
									className={classNames({ keyDownSelect: i === cursor })}
								>
									<span>{item.name}</span>
									<Styled.SelectDiv className={classNames({ keyDownSelect: i === cursor })}>select</Styled.SelectDiv>
								</Styled.DashboardListItem>
							);
						})}
					</Styled.DashboardList>
				</Styled.ListContainer>
				<Styled.DropdownFooter>
					<GhostButton size='sm' onClick={() => dispatch(setModalIsOpen(true))}>
						Create new
					</GhostButton>
				</Styled.DropdownFooter>
			</Styled.DropdownInnerContainer>
		</Styled.Dropdown>
	);
};

export default SearchDashboard;
