import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgAssignmentStory(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_2695_62737)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9.30225 0.697674C9.30225 0.312359 9.61461 0 9.99992 0C15.5201 0 19.9999 4.4798 19.9999 10C19.9999 13.5513 18.1482 16.6608 15.3715 18.4387C15.047 18.6465 14.6155 18.5519 14.4077 18.2274C14.1999 17.9029 14.2946 17.4714 14.6191 17.2636C17.0144 15.7299 18.6046 13.0533 18.6046 10C18.6046 5.25043 14.7495 1.39535 9.99992 1.39535C9.61461 1.39535 9.30225 1.08299 9.30225 0.697674Z'
					fill='#333333'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.73092 2.29898C4.97162 2.59986 4.92284 3.0389 4.62196 3.2796C4.36249 3.48718 4.12127 3.70261 3.88876 3.93512C3.6163 4.20758 3.17456 4.20758 2.9021 3.93512C2.62964 3.66266 2.62964 3.22091 2.9021 2.94846C3.17191 2.67864 3.45163 2.42895 3.75029 2.19002C4.05117 1.94931 4.49021 1.9981 4.73092 2.29898Z'
					fill='#333333'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M1.96583 5.25397C2.31101 5.42518 2.45205 5.8438 2.28083 6.18899C1.74725 7.26476 1.42947 8.46808 1.3951 9.73969C1.38469 10.1249 1.064 10.4287 0.67883 10.4183C0.293656 10.4079 -0.0101504 10.0872 0.000259737 9.70199C0.0403106 8.22011 0.410899 6.81878 1.0308 5.56897C1.20202 5.22379 1.62064 5.08275 1.96583 5.25397Z'
					fill='#333333'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0.79816 11.5742C1.17197 11.4808 1.55076 11.708 1.64421 12.0819C2.01381 13.5602 2.76185 14.8772 3.77975 15.9469C4.04538 16.226 4.03443 16.6676 3.7553 16.9332C3.47617 17.1988 3.03456 17.1879 2.76894 16.9088C1.59149 15.6714 0.72093 14.1419 0.290527 12.4203C0.197075 12.0465 0.424349 11.6677 0.79816 11.5742Z'
					fill='#333333'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.65516 17.6545C4.85111 17.3227 5.27891 17.2126 5.61068 17.4086C6.8931 18.166 8.38973 18.6047 9.99077 18.6047C10.5844 18.6047 11.1525 18.5445 11.7144 18.4322C12.0922 18.3566 12.4598 18.6016 12.5354 18.9795C12.6109 19.3573 12.3659 19.7248 11.9881 19.8004C11.3406 19.9299 10.6808 20 9.99077 20C8.13135 20 6.39077 19.4898 4.9011 18.61C4.56933 18.4141 4.45922 17.9863 4.65516 17.6545Z'
					fill='#333333'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M10.4 6.4C10.4 6.17909 10.2209 6 10 6C9.77909 6 9.6 6.17909 9.6 6.4V9.6H6.4C6.17909 9.6 6 9.77909 6 10C6 10.2209 6.17909 10.4 6.4 10.4H9.6V13.6C9.6 13.8209 9.77909 14 10 14C10.2209 14 10.4 13.8209 10.4 13.6V10.4H13.6C13.8209 10.4 14 10.2209 14 10C14 9.77909 13.8209 9.6 13.6 9.6H10.4V6.4Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2695_62737'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
