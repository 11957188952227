export const EU_COUNTRIES = [
	'AUT', // Austria
	'BEL', // Belgium
	'BGR', // Bulgaria
	'HRV', // Croatia
	'CYP', // Cyprus
	'CZE', // Czechia
	'DNK', // Denmark
	'EST', // Estonia
	'FIN', // Finland
	'FRA', // France
	'DEU', // Germany
	'GRC', // Greece
	'HUN', // Hungary
	'IRL', // Ireland
	'ITA', // Italy
	'LVA', // Latvia
	'LTU', // Lithuania
	'LUX', // Luxembourg
	'MLT', // Malta
	'NLD', // Netherlands
	'POL', // Poland
	'PRT', // Portugal
	'ROU', // Romania
	'SVK', // Slovakia
	'SVN', // Slovenia
	'ESP', // Spain
	'SWE', // Sweden
];

export function isVatRequired(countryCode: string): boolean {
	return EU_COUNTRIES.includes(countryCode.toUpperCase());
}
