import { isEmpty } from 'lodash';
import { Navigate } from 'react-router-dom';

import InfluencerDashboardContainer from 'components/InfluencerDashboard';
import InfluencerDashboardHeader from 'components/InfluencerDashboard/Components/InfluencerDashboardHeader';
import { FEATURE_FLAG_ONBOARDING_VIEW } from 'constants/feature-flag-keys';
import { HAS_BEEN_ONBOARDING_VIEW } from 'constants/localStorage-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import { useAppSelector } from 'hooks/useUserAppSelector';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

import Styled from './Dashboard.style';

const InfluencerDashboard = () => {
	const user = useAppSelector((state) => state.user);
	const storage = new BrowserStorage(StorageType.LOCAL);
	const hasBeenOnboardingView = Boolean(storage.getItem(HAS_BEEN_ONBOARDING_VIEW));
	const [isEnabled] = useFeatureToggle();

	if (!hasBeenOnboardingView && !isEmpty(user.influencers) && isEnabled(FEATURE_FLAG_ONBOARDING_VIEW)) {
		const isOnboardingDataRequired = user.influencer.affiliatePreferenceRequired;
		if (isOnboardingDataRequired) {
			storage.setItem(HAS_BEEN_ONBOARDING_VIEW, `true`);
			return <Navigate to='/influencer/onboarding' />;
		}
	}

	return (
		<Styled.Wrapper>
			<InfluencerDashboardHeader />
			<InfluencerDashboardContainer />
		</Styled.Wrapper>
	);
};
export default InfluencerDashboard;
