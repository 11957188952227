import moment from 'moment';

// TODO: Maybe use ISO8601 to support timezones.
export const API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const apiToMoment = (dateString) => {
	return moment(dateString, API_DATE_FORMAT);
};

export const uuid = () => {
	var temp_url = URL.createObjectURL(new Blob());
	var uuid = temp_url.toString();
	URL.revokeObjectURL(temp_url);
	return uuid.substr(uuid.lastIndexOf('/') + 1); // remove prefix (e.g. blob:null/, blob:www.test.com/, ...)
};
