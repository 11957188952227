import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	background-color: ${colors.white};
	z-index: 3000;

	width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	padding-left: ${spacing[3].px};
	padding-right: ${spacing[3].px};
`;

const InnerWrapper = styled.div`
	max-width: 100%;
	margin-top: ${spacing[2].px};

	@media screen and (${devices.md}) {
		max-width: 423px;
		margin-top: ${spacing[4].px};
	}
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

const LogoWrapper = styled.div`
	width: 72px;
	margin-bottom: ${spacing[4].px};

	@media screen and (${devices.md}) {
		width: 110px;
	}
`;

const CloseIconButton = styled.button`
	border: none;
	background-color: transparent;

	i {
		display: flex;
	}
	path {
		fill: ${colors.gray7};
	}
`;

const Styled = {
	Wrapper,
	Header,
	InnerWrapper,
	LogoWrapper,
	CloseIconButton,
};

export default Styled;
