import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DashboardType } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initialState: Array<DashboardType | any> = [];

const dashboardsSlice = createSlice({
	name: 'dashboards',
	initialState,
	reducers: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setDashboards(state, action: PayloadAction<Array<DashboardType>>) {
			state = action.payload;
			return state;
		},
		clearDashboards(state) {
			state = initialState;
			return state;
		},
	},
});

export const { setDashboards, clearDashboards } = dashboardsSlice.actions;
export default dashboardsSlice.reducer;
