import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';

const TooltipContainer = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
`;

const TooltipText = styled.span<{ width: number; align: 'left' | 'right' | 'middle'; position: 'top' | 'bottom' }>`
	visibility: hidden;
	opacity: 0;
	position: absolute;
	${(props) => (props.align === 'left' ? 'left: -50%' : props.align === 'right' ? 'right: -50%' : 'left: 0; right: 0; text-align: center')};
	${(props) => (props.position === 'top' ? 'bottom: calc(100% + 5px)' : 'bottom: -32px')};
	margin-left: ${({ align, width }) => (align === 'right' ? width / 2 : '0')}px;
	margin-right: ${({ align, width }) => (align === 'left' ? width / 2 : '0')}px;
	padding: 0.5rem;
	background-color: ${colors.black1};
	color: ${colors.mainWhite};
	font-size: 14px;
	border-radius: 5px;
	width: auto;
	max-width: 300px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: opacity 0.2s;

	${TooltipContainer} &.show {
		${mediaQueries.large`
		  visibility: visible;
    	opacity: 1;
	`};
	}

	&.transparent {
		background-color: transparent;
		color: ${colors.black1};
	}
`;

const Styled = {
	TooltipContainer,
	TooltipText,
};

export default Styled;
