/**
 * Connected states
 *
 * CONNECTED = Connected
 * DISCONNECTED = Has been connected but has disconnect
 * NOT_CONNECTED = Never been connected
 * PARTIALLY_CONNECTED = Connected but we're missing some parts, business accounts for example
 */
export enum ApiStatus {
	CONNECTED = 'connected',
	DISCONNECTED = 'disconnected',
	NOT_CONNECTED = 'not_connected',
	PARTIALLY_CONNECTED = 'partially_connected',
}

/**
 * Networks
 */
export enum Network {
	INSTAGRAM = 'instagram',
	TIKTOK = 'tiktok',
	YOUTUBE = 'youtube',
	WEBPAGE = 'webpage',
	PODCAST = 'podcast',
	OTHER = 'other',
}
