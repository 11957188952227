import styled from 'styled-components';

const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Styled = {
	Content,
};

export default Styled;
