import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { Icon } from 'components';
import { ButtonSecondary } from 'components/Button';
import {
	TrafficGraphs,
	AudienceGraphs,
	ReachGraphs,
	SalesAndBudgetGraphs,
	EngagementGraphs,
	ImpressionGraphs,
	MediaObjectGraphs,
	SummaryGraphs,
} from 'components/DataLibrary/Graphs/Categories';
import Dropdown from 'components/Dropdown';
import { SideDrawer } from 'components/SideDrawer';
import { UserType } from 'reducers/UserReducers/types';
import { useAppSelector } from 'views/DataLibrary/hooks';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import MainDropDown from './Components/MainDropDown';
import SearchContainer from './Components/Search/SearchContainer';
import ShareContainer from './Components/Share/ShareContainer';
import Styled from './ContentHeader.style';

const reach = ReachGraphs();
const sales = SalesAndBudgetGraphs();
const engagement = EngagementGraphs();
const impression = ImpressionGraphs();
const traffic = TrafficGraphs();
const audience = AudienceGraphs();
const summary = SummaryGraphs();
const mediaObjects = MediaObjectGraphs();

/**
 * @returns {JSX.Element}
 */
const ContentHeader = (props: { user: UserType }): JSX.Element => {
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [graphIndex, setGraphIndex] = useState<number>(0);
	const [elementId, setElementId] = useState<string>('');

	const AllGraphs = [sales, reach, impression, traffic, audience, engagement, summary, mediaObjects];

	const scrollSmoothTo = (elementId: string) => {
		setElementId(elementId);
		const element = document.getElementById(elementId);
		if (element) {
			element.scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	};

	useEffect(() => {
		scrollSmoothTo(AllGraphs[graphIndex]?.items[0].id);
	}, [graphIndex]);

	useEffect(() => {
		return () => {
			setGraphIndex(0);
			setElementId('');
		};
	}, []);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	return (
		<Styled.ContentContainerHeader>
			<Styled.ButtonContainer>
				<SearchContainer />
				{dashboard.id && (
					<ButtonSecondary size='sm' onClick={() => setIsModalOpen(true)}>
						Add graph <Icon size='16' name='add-circle' />
					</ButtonSecondary>
				)}
			</Styled.ButtonContainer>
			<Styled.IconsContainer>
				{dashboard.id && <ShareContainer user={props.user} />}
				<Dropdown icon='options' size='20' id='dashboard-actions' data-testid='main-dropdown' keepInDOM>
					<MainDropDown />
				</Dropdown>
			</Styled.IconsContainer>
			<SideDrawer
				sidebarIsOpen={isModalOpen}
				onClose={() => toggleModal()}
				dataTestId={''}
				title={`Add graphs to dashboard: ${dashboard.name}`}
				isExpandable
				isExpanded
				canOnlyCloseModal
				expandedContent={
					<Styled.GraphList>
						{AllGraphs[graphIndex]?.items?.map((item: { id: string; component: JSX.Element }, i: number) => {
							return (
								<li id={`${item.id}`} key={i}>
									{item.component}
								</li>
							);
						})}
					</Styled.GraphList>
				}
			>
				<Styled.GraphListWrapper>
					{AllGraphs?.map((graph, i: number) => {
						return (
							<Styled.CustomAccordion key={i} title={graph?.category} open={graphIndex === i} onClick={() => setGraphIndex(i)}>
								<Styled.AccordionList>
									{graph?.items?.map((item: { id: string; title: string }, i: number) => {
										return (
											<Styled.AccordionListItem
												className={classNames({ selected: elementId === item.id })}
												key={i}
												onClick={() => scrollSmoothTo(`${item.id}`)}
											>
												{item.title}
											</Styled.AccordionListItem>
										);
									})}
								</Styled.AccordionList>
							</Styled.CustomAccordion>
						);
					})}
				</Styled.GraphListWrapper>
			</SideDrawer>
		</Styled.ContentContainerHeader>
	);
};
export default ContentHeader;
