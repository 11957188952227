import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { devices, devicesMax } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';

const ContentWrapper = styled.div`
	height: 100%;
	overflow: hidden;
	display: flex;
	width: 0;
	transition: width 0.2s ease-in;
	// Commented out this animation unti we have fixed the issue with translateX together with the TabEdit component.
	//animation: slideIn 0.2s forwards;

	&.show {
		width: 100vw;
		z-index: 12;
		position: absolute;
		background-color: ${colors.snow};
		@keyframes slideIn {
			from {
				transform: translateX(-100%);
				display: flex;
			}
			to {
				transform: translateX(0);
				display: flex;
			}
		}
		@media screen and (${devices.md}) {
			width: calc(1256px - 423px);
			position: relative;
			z-index: 2;
			@keyframes slideIn {
				from {
					transform: translateX(100%);
					display: flex;
				}
				to {
					transform: translateX(0);
					display: flex;
				}
			}
		}
	}
`;

const ButtonsWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	gap: ${spacing[1].px};
	margin-top: 8px;
	button {
		@media screen and (${devicesMax.md}) {
			font-size: 1rem;
			flex: 1;
		}
		@media screen and (${devices.md}) {
			min-width: 171px;
			font-size: 1rem;
		}
	}
`;

const Content = styled.div`
	height: 100%;
	overflow-x: hidden;
	margin-right: -16px;
	padding-right: 16px;
	margin-top: 16px;
`;

const Section = styled.div`
	width: 100%;
	height: calc(100% - 0.5rem);
	padding: ${spacing[4].rem};
	position: relative;
`;

const Divider = styled.div`
	@media screen and (${devices.md}) {
		width: 1px;
		background-color: ${colors.ash};
		height: (100% - 80px);
		margin: ${spacing[4].px} 0;
	}
`;

const CloseButton = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	color: ${colors.oceanBlue};
	font-size: 1rem;
	.icon path {
		fill: ${colors.oceanBlue};
		&.inner-white {
			fill: ${({ theme }) => theme.colors.background};
		}
	}
	.icon {
		margin-left: 16px;
		margin-bottom: 6px;
	}
	.icon {
		transform: rotateZ(180deg);
	}
`;
const Styled = {
	ContentWrapper,
	Content,
	ButtonsWrapper,
	Section,
	Divider,
	CloseButton,
};

export default Styled;
