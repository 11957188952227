import classNames from 'classnames';
import { useContext, useState } from 'react';

import Accordion from 'components/Accordion';
import Styled from 'components/Discovery/Components/FilterDrawer/FilterDrawer.style';
import Input from 'components/Discovery/Components/Input';
import DiscoveryContext from 'contexts/Discovery';
import toast from 'services/Toast';

const EngagementFilter = () => {
	const setFilter = useContext(DiscoveryContext).setFilter;
	const filter = useContext(DiscoveryContext).filter;

	// Initialize the state with values from the filter
	const [minEngagement, setMinEngagement] = useState<number | null>(filter?.minEI ?? null);
	const [maxEngagement, setMaxEngagement] = useState<number | null>(filter?.maxEI ?? null);

	const updateFilter = () => {
		setFilter({
			...filter,
			minEI: minEngagement,
			maxEI: maxEngagement,
		});
	};

	return (
		<Accordion title='Engagement' className={classNames({ active: filter?.minEI || filter?.maxEI })}>
			<Styled.FollowersInputsWrapper>
				<Input
					label='min'
					type='text'
					pattern='[0-9]*'
					value={isNaN(Number(minEngagement)) ? '' : Number(minEngagement)}
					onChange={(e) => {
						setMinEngagement(+e.target.value);
					}}
					onBlur={(e) => {
						const value = +e.target.value;
						if (maxEngagement && value > maxEngagement) {
							toast.error(`Min engagement can't be higher than max engagement`);
						} else {
							updateFilter();
						}
					}}
				/>
				<Styled.BetweenMinMaxDiv>-</Styled.BetweenMinMaxDiv>
				<Input
					label='max'
					type='text'
					pattern='[0-9]*'
					value={isNaN(Number(maxEngagement)) ? '' : Number(maxEngagement)}
					onChange={(e) => setMaxEngagement(+e.target.value)}
					onBlur={(e) => {
						if (minEngagement && +e.target.value < minEngagement) {
							toast.error(`Max engagement can't be lower than min engagement`);
						} else {
							updateFilter();
						}
					}}
				/>
			</Styled.FollowersInputsWrapper>
		</Accordion>
	);
};

export default EngagementFilter;
