import styled from 'styled-components';

const Wrapper = styled.div`
	padding: 0;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	height: 100%;
	width: 100%;
`;

const Styled = {
	Wrapper,
};

export default Styled;
