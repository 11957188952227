import Styled from './Footer.style';

const Footer = () => {
	return (
		<Styled.Wrapper>
			<p>© 2024 Collabs AB</p>
			<a href='https://www.collabs.se/terms-of-service' target='_blank' rel='noreferrer'>
				Terms of Service
			</a>
			<a href='https://www.collabs.se/privacy-policy' target='_blank' rel='noreferrer'>
				Privacy Policy
			</a>
		</Styled.Wrapper>
	);
};

export default Footer;
