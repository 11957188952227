import { Container, interfaces } from 'inversify';

import CampaignBudgetApiClient from 'services/ApiClient/CampaignBudgetApiClient';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import InfluencerManager from 'services/ApiManager/Influencer.manager';
import PaymentManager from 'services/ApiManager/Payment.manager';
import SegmentManager from 'services/ApiManager/Segment.manager';
import ToolsManager from 'services/ApiManager/Tools.manager';
import DashboardHelper from 'services/DataLibrary/DashboardHelper';
import { MessageHandler } from 'services/Mercure/MessageHandler';
import HttpClientFactory from 'shared/ApiClient/HttpClientFactory';

import AdminApiClient from './services/ApiClient/AdminApiClient';
import AffiliateApiClient from './services/ApiClient/AffiliateApiClient';
import AgencyApiClient from './services/ApiClient/AgencyApiClient';
import ApiApiClient from './services/ApiClient/ApiApiClient';
import AssignmentApiClient from './services/ApiClient/AssignmentApiClient';
import AssignmentReviewApiClient from './services/ApiClient/AssignmentReviewApiClient';
import BrandApiClient from './services/ApiClient/BrandApiClient';
import CampaignApiClient from './services/ApiClient/CampaignApiClient';
import CampaignInstagramOwnerApiClient from './services/ApiClient/CampaignInstagramOwnerApiClient';
import ClientApiClient from './services/ApiClient/ClientApiClient';
import ConversationApiClient from './services/ApiClient/ConversationApiClient';
import CountryApiClient from './services/ApiClient/CountryApiClient';
import DashboardApiClient from './services/ApiClient/DashboardApiClient';
import FacebookApiClient from './services/ApiClient/FacebookApiClient';
import GenericApiClient from './services/ApiClient/GenericApiClient';
import InfluencerApiClient from './services/ApiClient/InfluencerApiClient';
import InfluencerListApiClient from './services/ApiClient/InfluencerListApiClient';
import InfluencerListItemCommentApiClient from './services/ApiClient/InfluencerListItemCommentApiClient';
import InfluencerListItemReactionApiClient from './services/ApiClient/InfluencerListItemReactionApiClient';
import InstagramBusinessAccountApiClient from './services/ApiClient/InstagramBusinessAccountApiClient';
import InstagramPostApiClient from './services/ApiClient/InstagramPostApiClient';
import InstagramStoryApiClient from './services/ApiClient/InstagramStoryApiClient';
import InvoiceApiClient from './services/ApiClient/InvoiceApiClient';
import MediaObjectApiClient from './services/ApiClient/MediaObjectApiClient';
import NotificationApiClient from './services/ApiClient/NotificationApiClient';
import PaymentApiClient from './services/ApiClient/PaymentApiClient';
import PinebucketApiClient from './services/ApiClient/PinebucketApiClient';
import PublicApiClient from './services/ApiClient/PublicApiClient';
import PublisherApiClient from './services/ApiClient/PublisherApiClient';
import SecurityApiClient from './services/ApiClient/SecurityApiClient';
import SegmentApiClient from './services/ApiClient/SegmentApiClient';
import ApiClientService from './services/ApiClient/ServiceIdentifier';
import StatisticsApiClient from './services/ApiClient/StatisticsApiClient';
import TiktokApiClient from './services/ApiClient/TiktokApiClient';
import ToolsApiClient from './services/ApiClient/ToolsApiClient';
import UserApiClient from './services/ApiClient/UserApiClient';
import UserUploadApiClient from './services/ApiClient/UserUploadApiClient';
import ApiCacheManager from './services/ApiManager/ApiCacheManager';
import AssignmentManager from './services/ApiManager/Assignment.manager';
import AssignmentReviewManager from './services/ApiManager/AssignmentReview.manager';
import AuthenticationManager from './services/ApiManager/Authentication.manager';
import BrandManager from './services/ApiManager/Brand.manager';
import CampaignManager from './services/ApiManager/Campaign.manager';
import CampaignCommissionManager from './services/ApiManager/CampaignCommission.manager';
import CampaignProductManager from './services/ApiManager/CampaignProduct.manager';
import ClientManager from './services/ApiManager/Client.manager';
import GenericManager from './services/ApiManager/Generic.manager';
import InfluencerListManager from './services/ApiManager/InfluencerList.manager';
import OrganizationManager from './services/ApiManager/Organization.manager';
import PublisherManager from './services/ApiManager/Publisher.manager';
import StatisticsManager from './services/ApiManager/Statistics.manager';
import CollabsAuthService from './services/Authentication/Collabs-api/Collabs-auth.service';
import LogoutService from './services/Authentication/Collabs-api/LogoutService';
import TokenStorageRegistry from './services/Authentication/TokenStorageRegistry';
import MercureService from './services/Mercure/mercure.service';
import { ServiceIdentifier as Mercure } from './services/Mercure/types';
import ToolsService from './services/Tools/Tools.service';
import LogErrorsInterceptor from './shared/ApiClient/Interceptor/LogErrors';
import RequestDecoratorInterceptor from './shared/ApiClient/Interceptor/RequestDecorator';
import RetryRequestInterceptor from './shared/ApiClient/Interceptor/RetryRequest';
import ToastOnBadRequestInterceptor from './shared/ApiClient/Interceptor/ToastOnBadRequest';
import UnauthorizedErrorInterceptor from './shared/ApiClient/Interceptor/UnauthorizedError';
import InternalHttpClientFactory from './shared/ApiClient/InternalHttpClientFactory';

/**
 * This is our dependency injection container.
 *
 * We use it to easily construct and inject dependencies.
 */
const DIContainer: Container = new Container();
// Http Client
DIContainer.bind(InternalHttpClientFactory).toSelf();
DIContainer.bind(HttpClientFactory).toSelf().inSingletonScope();
DIContainer.bind(ApiClientService.CollabsApiHttpClient).toFactory((context: interfaces.Context) => {
	return () => {
		return context.container.get(HttpClientFactory).create();
	};
});
DIContainer.bind(ApiClientService.CollabsApiHttpClientLegacy).toFactory((context: interfaces.Context) => {
	return () => {
		return context.container.get(HttpClientFactory).createLegacyClient();
	};
});

// Services
DIContainer.bind(TokenStorageRegistry).toSelf();
DIContainer.bind(CollabsAuthService).toSelf();
DIContainer.bind(LogoutService).toSelf();
DIContainer.bind(ToolsService).toSelf();
DIContainer.bind(MercureService).toSelf();
DIContainer.bind(Mercure.MercureMessageHandler).to(MessageHandler);

DIContainer.bind(LogErrorsInterceptor).toSelf();
DIContainer.bind(RequestDecoratorInterceptor).toSelf();
DIContainer.bind(RetryRequestInterceptor).toSelf();
DIContainer.bind(ToastOnBadRequestInterceptor).toSelf();
DIContainer.bind(UnauthorizedErrorInterceptor).toSelf();
DIContainer.bind(DashboardHelper).toSelf();

// API managers
DIContainer.bind(ApiCacheManager).toSelf().inSingletonScope();
DIContainer.bind(AssignmentManager).toSelf();
DIContainer.bind(AssignmentReviewManager).toSelf();
DIContainer.bind(AuthenticationManager).toSelf();
DIContainer.bind(BrandManager).toSelf();
DIContainer.bind(CampaignManager).toSelf();
DIContainer.bind(CampaignCommissionManager).toSelf();
DIContainer.bind(CampaignProductManager).toSelf();
DIContainer.bind(ClientManager).toSelf();
DIContainer.bind(DataLibraryManager).toSelf();
DIContainer.bind(GenericManager).toSelf();
DIContainer.bind(InfluencerListManager).toSelf();
DIContainer.bind(InfluencerManager).toSelf();
DIContainer.bind(OrganizationManager).toSelf();
DIContainer.bind(PublisherManager).toSelf();
DIContainer.bind(StatisticsManager).toSelf();
DIContainer.bind(PaymentManager).toSelf();
DIContainer.bind(ToolsManager).toSelf();
DIContainer.bind(SegmentManager).toSelf();

// API clients
DIContainer.bind(ApiClientService.AdminApiClientInterface).to(AdminApiClient);
DIContainer.bind(ApiClientService.AffiliateApiClientInterface).to(AffiliateApiClient);
DIContainer.bind(ApiClientService.AgencyApiClientInterface).to(AgencyApiClient);
DIContainer.bind(ApiClientService.ApiApiClientInterface).to(ApiApiClient);
DIContainer.bind(ApiClientService.AssignmentApiClientInterface).to(AssignmentApiClient);
DIContainer.bind(ApiClientService.AssignmentReviewApiClientInterface).to(AssignmentReviewApiClient);
DIContainer.bind(ApiClientService.BrandApiClientInterface).to(BrandApiClient);
DIContainer.bind(ApiClientService.CampaignApiClientInterface).to(CampaignApiClient);
DIContainer.bind(ApiClientService.CampaignInstagramOwnerApiClientInterface).to(CampaignInstagramOwnerApiClient);
DIContainer.bind(ApiClientService.CampaignBudgetApiClientInterface).to(CampaignBudgetApiClient);
DIContainer.bind(ApiClientService.ClientApiClientInterface).to(ClientApiClient);
DIContainer.bind(ApiClientService.ConversationApiClientInterface).to(ConversationApiClient);
DIContainer.bind(ApiClientService.CountryApiClientInterface).to(CountryApiClient);
DIContainer.bind(ApiClientService.DashboardApiClientInterface).to(DashboardApiClient);
DIContainer.bind(ApiClientService.FacebookApiClientInterface).to(FacebookApiClient);
DIContainer.bind(ApiClientService.GenericApiClientInterface).to(GenericApiClient);
DIContainer.bind(ApiClientService.InfluencerApiClientInterface).to(InfluencerApiClient);
DIContainer.bind(ApiClientService.InfluencerListApiClientInterface).to(InfluencerListApiClient);
DIContainer.bind(ApiClientService.InfluencerListItemCommentApiClientInterface).to(InfluencerListItemCommentApiClient);
DIContainer.bind(ApiClientService.InfluencerListItemReactionApiClientInterface).to(InfluencerListItemReactionApiClient);
DIContainer.bind(ApiClientService.InstagramBusinessAccountApiClientInterface).to(InstagramBusinessAccountApiClient);
DIContainer.bind(ApiClientService.InstagramPostApiClientInterface).to(InstagramPostApiClient);
DIContainer.bind(ApiClientService.InstagramStoryApiClientInterface).to(InstagramStoryApiClient);
DIContainer.bind(ApiClientService.InvoiceApiClientInterface).to(InvoiceApiClient);
DIContainer.bind(ApiClientService.MediaObjectApiClientInterface).to(MediaObjectApiClient);
DIContainer.bind(ApiClientService.NotificationApiClientInterface).to(NotificationApiClient);
DIContainer.bind(ApiClientService.PaymentApiClientInterface).to(PaymentApiClient);
DIContainer.bind(ApiClientService.PinebucketApiClientInterface).to(PinebucketApiClient);
DIContainer.bind(ApiClientService.PublicApiClientInterface).to(PublicApiClient);
DIContainer.bind(ApiClientService.PublisherApiClientInterface).to(PublisherApiClient);
DIContainer.bind(ApiClientService.SecurityApiClientInterface).to(SecurityApiClient);
DIContainer.bind(ApiClientService.SegmentApiClientInterface).to(SegmentApiClient);
DIContainer.bind(ApiClientService.StatisticsApiClientInterface).to(StatisticsApiClient);
DIContainer.bind(ApiClientService.TiktokApiClientInterface).to(TiktokApiClient);
DIContainer.bind(ApiClientService.ToolsApiClientInterface).to(ToolsApiClient);
DIContainer.bind(ApiClientService.UserApiClientInterface).to(UserApiClient);
DIContainer.bind(ApiClientService.UserUploadApiClientInterface).to(UserUploadApiClient);

export default DIContainer;
