import styled from 'styled-components';

import { Flex, Spacing } from 'styles/utils/UtilityClasses';
import { scrollbarY } from 'styles/utils/scrollbar';
import { breakpoints, mediaQueries } from 'styles/variables/media-queries';

interface IModalDialog {
	size: string;
}

const GetModalDialogWidth = (size: string) => {
	switch (size) {
		case 'xs':
			return '36rem';
		case 'sm':
			return '48rem';
		case 'md':
			return '59.0625rem';
		case 'lg':
			return '59.0625rem';
		case 'xl':
			return '59.0625rem';
		default:
			return '59.0625rem';
	}
};

const ModalDialog = styled.div<IModalDialog>`
	position: relative;
	width: auto;
	max-width: 100%;
	margin: 85px 16px;
	z-index: 4099;
	transition: transform 0.3s ease-out;
	transform: translate(0, -30%);
	outline: none;

	@media (min-width: ${breakpoints.md}) {
		margin: 4.5rem auto;

		max-width: ${({ size }) => GetModalDialogWidth(size)};
	}
`;

const Modal = styled.div<IModalDialog>`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: ${({ theme }) => theme.zIndexes.overlay};
	display: block;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: opacity 0.15s linear;
	background: #0000009f;
	backdrop-filter: blur(2px);

	&.modal-enter-active {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
		overflow: hidden;

		${ModalDialog} {
			transform: translate(0, 0);
		}
	}

	&.modal-enter-done {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
		overflow: hidden;

		${ModalDialog} {
			transform: translate(0, 0);
		}
	}

	&.comments-on-influencer-list {
		top: 150px;
	}
`;

export const ModalHeader = styled.div`
	padding: 2rem 1rem 0;
	margin-bottom: 1.25rem;

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: 0;
	}

	${mediaQueries.large`
			padding: 1rem 2.5rem 0;
	`};
`;

export const ModalBody = styled.div`
	padding: 0 1rem;
	margin-bottom: 1.25rem;
	transform: translateZ(-0.125rem);
	overflow-y: auto;

	${scrollbarY};

	${mediaQueries.large`
			padding: 0 2.5rem;
			margin-bottom: 2rem;

	`};
`;

export const ModalFooter = styled.div`
	${Flex};
	${Spacing};
	margin-bottom: 0.625rem;
	padding: 0 2.5rem 1rem;
`;

export const ModalContent = styled.div`
	max-height: 95vh;
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: ${({ theme }) => theme.modal.content.background};
	background-clip: padding-box;
	border-radius: ${({ theme }) => theme.modal.borderRadius};
	border: ${({ theme }) => theme.modal.border};
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
	outline: 0;
	z-index: 15;
	border-radius: 10px;
`;

export const Styled = {
	Modal,
	ModalDialog,
	ModalContent,
};
