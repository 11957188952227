import classNames from 'classnames';
import { Model } from 'json-api-models';
import { JsonApiResource } from 'json-api-models/src/types';
import { Link } from 'react-router-dom';

import Assignment from 'components/ContentManagement/Components/Views/Assignment';
import EmptyState from 'components/ContentManagement/Components/Views/EmptyState';
import { CampaignItemType } from 'components/ContentManagement/types';
import usePermissions from 'hooks/usePermissions';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { getLatestReview } from 'shared/utils/getLatestReview';
import { isMobile } from 'shared/utils/navigator';

import Styled from './ContentSection.style';

type ContentSectionProps = {
	selectedCampaign: CampaignItemType | undefined;
	selectedCIO: Model | undefined;
	CIOAssignment: Model | undefined; // CampaignInstagramOwner assignment
	CIOAS: Model[];
	CIOArefresh: () => Promise<void>;
	refresh: () => void;
	add: (resource: JsonApiResource) => Promise<void>;
};

export const BreadCrumbItems = (props: { homeUrl: string; homeText: string; currentText: string }): JSX.Element => {
	return (
		<Styled.BreadCrumbs>
			<Link to={props.homeUrl} data-testid='cm-go-back-btn'>
				<span>{props.homeText}</span>
			</Link>
			<Styled.Divider> / </Styled.Divider>
			<div>{props.currentText}</div>
		</Styled.BreadCrumbs>
	);
};

/**
 * ContentSection
 * @param {ContentReviewProps} props
 * @returns {JSX.Element}
 */
const ContentSection = (props: ContentSectionProps): JSX.Element => {
	const contentManagement = useAppSelector((state) => state.contentManagement);
	const reviews = props.CIOAssignment && props.CIOAssignment.reviews;
	const latestReview = getLatestReview(reviews);
	const { isInfluencer } = usePermissions();

	const SideNavContainerHeading = () => {
		if (isInfluencer()) {
			if (props.selectedCampaign) {
				return props.selectedCampaign.name;
			} else {
				return '';
			}
		} else {
			if (props.selectedCampaign && !props.selectedCIO) {
				// if the user has clicked on one campaign and not an influencer.
				return props.selectedCampaign.name;
			} else if (props.selectedCIO) {
				// If user has selected an influencer
				return props.selectedCIO.influencer.username;
			} else {
				// Start
				return null;
			}
		}
	};

	const goBackText = () => {
		if (isInfluencer() && props.selectedCIO) {
			return props.selectedCampaign?.name ?? 'Campaigns';
		}
		if (props.selectedCampaign && props.selectedCIO) {
			return props.selectedCampaign.name ?? 'Start';
		}
		return 'Start';
	};

	const getUrl = () => {
		if (props.selectedCampaign && props.selectedCIO) {
			return isInfluencer()
				? `/influencer/content-management/${props.selectedCampaign.id}/${props.selectedCIO.id}`
				: `/content-management/${props.selectedCampaign.id}`;
		} else {
			return isInfluencer() ? `/influencer/content-management/` : `/content-management/`;
		}
	};

	return (
		<Styled.Wrapper className={classNames({ 'is-uploading': contentManagement.isProgressVisible })} data-testid='content-section'>
			{!isMobile() && props.selectedCampaign && isInfluencer() ? (
				props.selectedCIO && props.CIOAssignment ? (
					<BreadCrumbItems
						homeUrl={`/influencer/content-management/${props.selectedCampaign.id}`}
						homeText={props.selectedCampaign.name}
						currentText={props.CIOAssignment.assignment.name}
					/>
				) : (
					<BreadCrumbItems homeUrl={`/influencer/content-management/`} homeText={'Start'} currentText={SideNavContainerHeading()} />
				)
			) : (
				SideNavContainerHeading()?.length > 0 &&
				!isMobile() && <BreadCrumbItems homeUrl={getUrl()} homeText={goBackText()} currentText={SideNavContainerHeading()} />
			)}
			{props.selectedCampaign && props.CIOAssignment && props.selectedCIO ? (
				<Assignment
					id={props.CIOAssignment.id}
					type={props.CIOAssignment.assignment.kind}
					heading={props.CIOAssignment.assignment.name}
					selectedCampaign={props.selectedCampaign}
					selectedCIO={props.selectedCIO}
					CIOAssignment={props.CIOAssignment}
					CIOAS={props.CIOAS}
					review={latestReview! as Model}
					CIOArefresh={props.CIOArefresh}
					refresh={props.refresh}
				/>
			) : (
				<EmptyState campaignSelected={props.selectedCampaign} />
			)}
		</Styled.Wrapper>
	);
};
export default ContentSection;
