import { interfaces } from 'inversify';
import { useRef } from 'react';

import DIContainer from 'DependencyInjection';

/**
 * internal utility hook
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
 *
 * Q: why not `useMemo`?
 * A: it does not guarantee same instance
 * @see https://reactjs.org/docs/hooks-reference.html#usememo
 *
 * Q: why not `useState`?
 * A: it's possible to use state factory `useState(() => container.get(...))`,
 * but ref is probably slightly more optimal because it's not related to re-rendering
 * (which we don't need anyway)
 */
function useLazyRef<T>(resolveValue: () => T): T {
	const ref = useRef<{ v: T }>();
	if (!ref.current) {
		ref.current = { v: resolveValue() };
	}
	return ref.current.v;
}

/**
 * Resolves injection by id.
 *
 * Generally, we want to useRef = true. When dealing with stateful services (ie, ApiManagers)
 * we want to useRef = false, because we want to re-create the instance on each render.
 *
 * @param serviceId The service ID
 * @param useRef if true, we will only create the instance once per component lifecycle
 */
export default function useInjection<T>(serviceId: interfaces.ServiceIdentifier<T>, useRef: boolean = false): T {
	const container = DIContainer;
	const resolver = () => container.get<T>(serviceId);

	if (useRef) {
		return useLazyRef(resolver);
	}

	return resolver();
}
