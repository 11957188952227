import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgDLSummaryContent(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.51196 3.5V3.4118L1.66183 3.5H1.51196ZM2.45578 3.5L1.51196 2.94457V2.38618L3.40462 3.5H2.45578ZM3.52791 3.10532L1.51196 1.91895V1.5H1.59444L3.52791 2.63784V3.10532ZM3.52791 2.1706V1.70277L3.18335 1.5H2.38838L3.52791 2.1706ZM0 1C0 0.447715 0.451285 0 1.00797 0H4.03189C4.58858 0 5.03987 0.447715 5.03987 1V4C5.03987 4.55228 4.58858 5 4.03189 5H1.00797C0.451285 5 0 4.55228 0 4V1ZM8.15631 1.74023C7.7388 1.74023 7.40033 2.07602 7.40033 2.49023C7.40033 2.90445 7.7388 3.24023 8.15631 3.24023L19.244 3.24023C19.6615 3.24023 20 2.90445 20 2.49023C20 2.07602 19.6615 1.74023 19.244 1.74023L8.15631 1.74023ZM8.15631 9.24023C7.7388 9.24023 7.40033 9.57602 7.40033 9.99023C7.40033 10.4044 7.7388 10.7402 8.15631 10.7402H19.244C19.6615 10.7402 20 10.4044 20 9.99023C20 9.57602 19.6615 9.24023 19.244 9.24023H8.15631ZM7.40033 17.4902C7.40033 17.076 7.7388 16.7402 8.15631 16.7402H19.244C19.6615 16.7402 20 17.076 20 17.4902C20 17.9044 19.6615 18.2402 19.244 18.2402H8.15631C7.7388 18.2402 7.40033 17.9044 7.40033 17.4902ZM1.51196 9.45146V9.91895L3.34894 11H3.52791V10.6378L1.51196 9.45146ZM1.53875 9L3.52791 10.1706V9.70277L2.33373 9H1.53875ZM3.12767 9L3.52791 9.23554V9H3.12767ZM4.03189 12.5H3.39231L3.38451 12.5138L3.36108 12.5H1.00797C0.451285 12.5 0 12.0523 0 11.5V8.5C0 7.94772 0.451285 7.5 1.00797 7.5H4.03189C4.58858 7.5 5.03987 7.94772 5.03987 8.5V11.5C5.03987 12.0523 4.58858 12.5 4.03189 12.5ZM1.51196 10.9446L1.60615 11H2.555L1.51196 10.3862V10.9446ZM1.51196 18.5V18.4118L1.66183 18.5H1.51196ZM2.45578 18.5L1.51196 17.9446V17.3862L3.40462 18.5H2.45578ZM3.52791 18.1053L1.51196 16.9189V16.5H1.59443L3.52791 17.6378V18.1053ZM3.52791 17.1706V16.7028L3.18335 16.5H2.38838L3.52791 17.1706ZM0 16C0 15.4477 0.451285 15 1.00797 15H4.03189C4.58858 15 5.03987 15.4477 5.03987 16V19C5.03987 19.5523 4.58858 20 4.03189 20H1.00797C0.451285 20 0 19.5523 0 19V16Z'
				fill='#333333'
			/>
		</svg>
	);
}
