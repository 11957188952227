import { Link } from 'react-router-dom';
import styled, { DefaultThemeV2 } from 'styled-components';

import HeaderDropdown from 'components/Header/Components/HeaderDropdown';
import themeColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import legacyColors from 'styles/variables/colors';

type P = { theme: DefaultThemeV2 };

const NotificationList = styled.ul`
	overflow: hidden;
	height: auto;
	border-radius: ${({ theme }) => theme.radius.default};
	background-color: ${({ theme }: P) => theme.colors.secondary};
	${scrollbarY};
	max-height: 319px;
`;

const NotificationListItem = styled.li`
	position: relative;
	display: flex;
	align-items: center;
	a {
		color: ${legacyColors.campaignsModal.linkColor};
		font-weight: 900;
	}
	&.alignEnd {
		justify-content: flex-end;
		a {
			font-weight: 400 !important;
		}
	}
`;

const NotificationContent = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const NotificationIconWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background-color: ${legacyColors.gray13};
	> span {
		font-weight: 900;
	}
`;

const NotificationCircle = styled.div`
	position: absolute;
	top: 0;
	right: -4px;
	width: 12px;
	height: 12px;
	background-color: ${legacyColors.dropdown.button.notificationCircle};
	border: 1px solid ${legacyColors.dropdown.button.notificationCircleBorder};
	border-radius: 20px;
	transition: opacity 0.2s ease-in-out;
	opacity: 0;

	&.visible {
		opacity: 1;
	}
`;

const NewNotificationCircle = styled.div`
	width: 6px;
	height: 6px;
	margin-left: 5px;
	background-color: ${themeColors.oceanBlue};
	border-radius: 20px;
	opacity: 0;
	&.visible {
		opacity: 1;
	}
`;

const NoResultText = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 10px;
`;

const NotificationRow = styled(Link)`
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-rows: auto auto;
	column-gap: 0.75rem;
	row-gap: 0.25rem;
	transition: background-color 0.2s ease-in-out;
	&.unread {
		background-color: ${themeColors.iceBlue};
	}
	&:hover {
		background-color: transparent;
	}
`;

const NotificationIconContainer = styled.div`
	grid-row: span 2;
	display: flex;
	align-items: center;
	padding: 0.75rem 0;
	gap: 4px;
	svg {
		display: block;
	}
`;

const NotificationTitleContainer = styled.div`
	padding-top: 1rem;
	color: ${({ theme }) => theme.colors.primary};
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const NoNotificationRow = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	gap: 1rem;
	align-items: center;
	background-color: ${themeColors.snow};
	padding: 1.5rem 0;

	${NotificationTitleContainer} {
		padding-top: 0;

		span {
			display: block;
			color: ${themeColors.slate};
			padding-top: 0.5rem;
		}
	}
`;

const NotificationDateContainer = styled.div`
	padding: 0.5rem 1rem 0 0;
	color: ${themeColors.slate};
	font-size: ${({ theme }) => theme.fontSizes.small};
`;

const NotificationDescriptionContainer = styled.div`
	grid-column: span 2;
	padding-bottom: 0.5rem;
	padding-right: 2rem;
	color: ${themeColors.slate};
	font-size: 0.9375rem;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const OptionsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: ${themeColors.mist};
	padding: 1rem;
	margin-top: 8px;
`;

const NotificationOption = styled.div`
	cursor: pointer;
	margin: 0;
	border: none;
	width: fit-content;
	font-size: 0.875rem;
	font-weight: 500;
	text-align: left;
	margin: 2px 0;
	transition: background-color 0.2s ease-in-out;
	line-height: 1.2;
	height: 25px;
	color: ${themeColors.oceanBlue};
	&:hover {
		border-bottom: 2px solid ${themeColors.oceanBlue};
	}
`;

const SpinnerWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 24px 0;
`;

const customHeaderDropdown = styled(HeaderDropdown)`
	max-height: 400px;
`;

const Styled = {
	NotificationContent,
	NotificationIconWrapper,
	NotificationList,
	NotificationListItem,
	NotificationCircle,
	NewNotificationCircle,
	NoResultText,
	NotificationRow,
	NotificationIconContainer,
	NotificationTitleContainer,
	NotificationDateContainer,
	NotificationDescriptionContainer,
	NoNotificationRow,
	OptionsWrapper,
	NotificationOption,
	SpinnerWrapper,
	customHeaderDropdown,
};

export default Styled;
