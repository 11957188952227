import styled from 'styled-components';

import Grid from 'styles/grid/grid';
import { MidColContainer, SidebarNavContainer, SidebarNavHeading, SidebarNav, SidebarNavItem } from 'styles/layout/three-col';
import colors from 'styles/theme/colors';
import boxShadow from 'styles/variables/box-shadows';

const Wrapper = styled.div`
	position: relative;
	height: calc(100vh - 72px);
	overflow: hidden;
`;

const Content = styled.div`
	position: relative;
	height: calc(100% - 24px);
`;

const SideNavContainer = styled(SidebarNavContainer)`
	position: relative;
	height: auto;
`;

const BackButton = styled.button`
	position: absolute;
	top: 8px;
	left: 22px;
	border-radius: 4px;
	border: 0;
	padding: 0;
	height: 32px;
	width: 32px;
	opacity: 0;
	visibility: hidden;
	background-color: transparent;
	transition:
		opacity 200ms ease-in-out,
		visibility 200ms ease-in-out;
	cursor: pointer;

	.icon {
		path {
			transition: fill 200ms ease-in-out;
		}
	}

	&.visible {
		opacity: 1;
		visibility: visible;
	}
`;
const SideNavHeading = styled(SidebarNavHeading)`
	background-color: ${colors.snow};
	margin-bottom: 0;
	padding: 1.28rem 2rem;

	h1 {
		transition: color 200ms ease-in-out;
		max-width: 345px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 0.875rem;
		margin-bottom: -3px;
	}

	&.back {
		padding-left: 3.875rem;
		cursor: pointer;
		transition: background-color 200ms ease-in-out;

		&:hover {
			.icon path {
				fill: ${colors.snow};
			}
			${BackButton} {
				background-color: ${colors.smoke};
			}
		}
	}

	.icon {
		margin-right: 10px;
	}
`;

const SideNav = styled(SidebarNav)`
	padding: 0 1rem;
`;

const SideNavItem = styled(SidebarNavItem)``;

const ContentContainer = styled(MidColContainer)`
	background-color: ${colors.snow};
	height: auto;
`;

const ContentContainerHeader = styled.div`
	position: fixed;
	height: 56px;
	width: 100%;
	box-shadow: ${boxShadow.small};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0rem 1rem;
	div {
		display: flex;
		align-items: center;
		div {
			margin-left: 16px;
		}
	}
`;

const Nav = styled.div`
	position: relative;
	height: 100%;
	padding-left: 2rem;
	padding-right: 0.5rem;
`;

const RightInner = styled.div`
	height: 100%;
	padding-bottom: 2.5rem;

	.toggler .icon {
		position: absolute;
		left: 0px;
		top: -3px;
		line-height: 1;
	}
`;

const SpinnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding-bottom: 3rem;
`;

const NoResults = styled.div`
	padding: 1rem;
	text-align: center;
	.value {
		font-weight: 500;
	}
`;

const NoResultsText = styled.div`
	padding-top: 1rem;
	max-width: 100%;
	overflow-wrap: break-word;
	.link {
		color: ${colors.oceanBlue};
		cursor: pointer;
	}
`;

const FiltersTitle = styled.div`
	font-size: 1rem;
	font-weight: 700;
	position: sticky;
	top: 0;
	padding-top: 0.063rem;
	z-index: 1;
	height: 56px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1rem;
`;

const CustomColumn = styled(Grid.Column)`
	height: 100vh;
	position: relative;
`;

const Styled = {
	Wrapper,
	Content,
	SideNavContainer,
	SideNav,
	SideNavItem,
	SideNavHeading,
	ContentContainer,
	Nav,
	BackButton,
	RightInner,
	ContentContainerHeader,
	SpinnerWrapper,
	NoResults,
	NoResultsText,
	FiltersTitle,
	CustomColumn,
};

export default Styled;
