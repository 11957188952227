import moment from 'moment';

export const getDateSixMonthsAgo = () => {
	return moment().subtract(6, 'months').format('YYYY-MM-DD');
};

export const getDateOneMonthAgo = () => {
	return moment().subtract(1, 'months').format('YYYY-MM-DD');
};

export const getDateOneWeekAgo = () => {
	return moment().subtract(1, 'week').format('YYYY-MM-DD');
};

export const getToday = () => {
	return moment().format('YYYY-MM-DD');
};
