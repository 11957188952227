import styled from 'styled-components';

import colors from 'styles/variables/colors';

const Wrapper = styled.div`
	max-width: 611px;
	margin: 0 auto;
	padding-top: 5.625rem;
`;

const HelpTextContainer = styled.div`
	margin-bottom: 53px;
	p {
		color: ${colors.gray7};
	}
`;

const Styled = {
	Wrapper,
	HelpTextContainer,
};

export default Styled;
