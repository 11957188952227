import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';

import { CropDimensionsType, KIND } from 'components/EditableImage/types/types';

import Styled from './EditableImageToolbar.style';

type BriefImageToolbarProps = {
	disabled: boolean;
	authed: boolean;
	shouldCrop: boolean;
	resetCroppedImage: () => void;
	setShouldCrop: Dispatch<SetStateAction<boolean>>;
	setIsEditMode: Dispatch<SetStateAction<boolean>>;
	isEditMode: boolean;
	postCropDimensionsAndImage: () => Promise<string | undefined | void>;
	deleteImage?: () => void;
	deleteImageWithToast?: () => void;
	image: string | undefined;
	addImage: () => void;
	kind: KIND;
	isLoading: boolean;
	cropDimensions: CropDimensionsType | null;
	resetCrop: (resetImage?: boolean) => void;
	setIsReplacedImage: (isReplaced: boolean) => void;
	canSave: boolean;
};

const EditableImageToolbar = ({
	disabled,
	authed,
	shouldCrop,
	resetCroppedImage,
	setShouldCrop,
	setIsEditMode,
	isEditMode,
	postCropDimensionsAndImage,
	image,
	addImage,
	deleteImage,
	deleteImageWithToast,
	kind,
	isLoading,
	cropDimensions,
	resetCrop,
	setIsReplacedImage,
	canSave,
}: BriefImageToolbarProps): JSX.Element | null => {
	if (!authed) {
		return null;
	}

	const deleteOldImageAfterNewUpload = async () => {
		const res = await postCropDimensionsAndImage();
		if (res) {
			deleteImage ? deleteImage() : null;
		}
	};

	const save = () => {
		if (cropDimensions) {
			if ((cropDimensions?.x === 0, cropDimensions?.y === 0, cropDimensions?.cropWidth === 0, cropDimensions?.cropHeight === 0)) {
				return resetCroppedImage(), setIsEditMode(!isEditMode);
			} else deleteOldImageAfterNewUpload();
		} else return deleteOldImageAfterNewUpload();
	};

	return (
		<Styled.EditableImageToolBarWrapper>
			{authed && (
				<Styled.EditableImageToolBar inEditMode={isEditMode} canCrop={true} kind={kind}>
					<div>
						<Styled.EditableImageToolBarCTA
							kind={kind}
							data-testid='edit'
							className={classNames({ cancel: isEditMode }, 'edit')}
							onClick={() => {
								setIsReplacedImage(false), resetCrop(true), setIsEditMode(!isEditMode);
							}}
							disabled={disabled}
							inEditMode={isEditMode}
						>
							{isEditMode ? 'Cancel' : 'Edit image'}
						</Styled.EditableImageToolBarCTA>

						{deleteImageWithToast && (
							<Styled.EditableImageToolBarCTA
								kind={kind}
								className='delete'
								data-testid='delete'
								onClick={() => deleteImageWithToast()}
								disabled={shouldCrop || disabled}
								inEditMode={isEditMode}
							>
								Delete
							</Styled.EditableImageToolBarCTA>
						)}
						{image && (
							<Styled.EditableImageToolBarCTA
								kind={kind}
								className='crop'
								data-testid='crop'
								onClick={() => {
									resetCrop(), setShouldCrop(!shouldCrop);
								}}
								disabled={shouldCrop || disabled}
								inEditMode={isEditMode}
							>
								Crop
							</Styled.EditableImageToolBarCTA>
						)}

						<Styled.EditableImageToolBarCTA
							kind={kind}
							className='replace'
							data-testid='replace'
							onClick={addImage}
							disabled={shouldCrop || disabled}
							inEditMode={isEditMode}
						>
							Replace
						</Styled.EditableImageToolBarCTA>
						<Styled.EditableImageToolBarCTA
							kind={kind}
							className='save'
							data-testid='save'
							onClick={save}
							disabled={!canSave || (image && shouldCrop) || disabled || isLoading}
							inEditMode={isEditMode}
						>
							Save
						</Styled.EditableImageToolBarCTA>
					</div>
				</Styled.EditableImageToolBar>
			)}
		</Styled.EditableImageToolBarWrapper>
	);
};

export default EditableImageToolbar;
