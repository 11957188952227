import { Store } from 'json-api-models';

/**
 * Get a campaign with id, shortId or UUID
 *
 * @param models or legacy json-api-models
 * @param id could be id, shortId or UUID
 */
export const LegacyModelCampaignFinder = (models: Store, id: string) => {
	let campaign = models.find('campaign', id as string);

	if (!campaign) {
		for (const candidate of models.findAll('campaign')) {
			if (candidate.shortId === id) {
				campaign = candidate;
				break;
				//} else if (candidate.uuid === id) {
				//	campaign = candidate;
				//	break;
			}
		}
	}

	return campaign;
};
