const ApiClientService = {
	CollabsApiHttpClient: Symbol('CollabsApiHttpClient'),
	CollabsApiHttpClientLegacy: Symbol('CollabsApiHttpClientLegacy'),
	AdminApiClientInterface: Symbol('AdminApiClientInterface'),
	AffiliateApiClientInterface: Symbol('AffiliateApiClientInterface'),
	AgencyApiClientInterface: Symbol('AgencyApiClientInterface'),
	ApiApiClientInterface: Symbol('ApiApiClientInterface'),
	ConversationApiClientInterface: Symbol('ConversationApiClientInterface'),
	CampaignInstagramOwnerApiClientInterface: Symbol('CampaignInstagramOwnerApiClientInterface'),
	BrandApiClientInterface: Symbol('BrandApiClientInterface'),
	AssignmentReviewApiClientInterface: Symbol('AssignmentReviewApiClientInterface'),
	AssignmentApiClientInterface: Symbol('AssignmentApiClientInterface'),
	CampaignApiClientInterface: Symbol('CampaignApiClientInterface'),
	ClientApiClientInterface: Symbol('ClientApiClientInterface'),
	InstagramBusinessAccountApiClientInterface: Symbol('InstagramBusinessAccountApiClientInterface'),
	InfluencerListItemReactionApiClientInterface: Symbol('InfluencerListItemReactionApiClientInterface'),
	InfluencerListItemCommentApiClientInterface: Symbol('InfluencerListItemCommentApiClientInterface'),
	InfluencerListApiClientInterface: Symbol('InfluencerListApiClientInterface'),
	InfluencerApiClientInterface: Symbol('InfluencerApiClientInterface'),
	FacebookApiClientInterface: Symbol('FacebookApiClientInterface'),
	DashboardApiClientInterface: Symbol('DashboardApiClientInterface'),
	CountryApiClientInterface: Symbol('CountryApiClientInterface'),
	UserUploadApiClientInterface: Symbol('UserUploadApiClientInterface'),
	UserApiClientInterface: Symbol('UserApiClientInterface'),
	ToolsApiClientInterface: Symbol('ToolsApiClientInterface'),
	TiktokApiClientInterface: Symbol('TiktokApiClientInterface'),
	StatisticsApiClientInterface: Symbol('StatisticsApiClientInterface'),
	SegmentApiClientInterface: Symbol('SegmentApiClientInterface'),
	SecurityApiClientInterface: Symbol('SecurityApiClientInterface'),
	PublisherApiClientInterface: Symbol('PublisherApiClientInterface'),
	PublicApiClientInterface: Symbol('PublicApiClientInterface'),
	PinebucketApiClientInterface: Symbol('PinebucketApiClientInterface'),
	PaymentApiClientInterface: Symbol('PaymentApiClientInterface'),
	NotificationApiClientInterface: Symbol('NotificationApiClientInterface'),
	MediaObjectApiClientInterface: Symbol('MediaObjectApiClientInterface'),
	InstagramStoryApiClientInterface: Symbol('InstagramStoryApiClientInterface'),
	InvoiceApiClientInterface: Symbol('InvoiceApiClientInterface'),
	InstagramPostApiClientInterface: Symbol('InstagramPostApiClientInterface'),
	GenericApiClientInterface: Symbol('GenericApiClientInterface'),
	CampaignBudgetApiClientInterface: Symbol('CampaignBudgetApiClientInterface'),
};

export default ApiClientService;
