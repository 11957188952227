import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import { DashboardFilters } from './types';

const today = moment().format('YYYY-MM-DD');
const sixMonthsAgo = moment().subtract(6, 'months').format('YYYY-MM-DD');

export const initialState: DashboardFilters = {
	assignments: [],
	assignmentTypes: [],
	brands: [],
	campaigns: [],
	clients: [],
	influencers: [],
	countries: [],
	from: sixMonthsAgo,
	to: today,
	uploads: [],
};

const dashboardFilterSlice = createSlice({
	name: 'dashboardFilter',
	initialState,
	reducers: {
		setDashboardFilter(state, action: PayloadAction<DashboardFilters>) {
			const payload = action.payload;

			state.assignments = payload.assignments;
			state.assignmentTypes = payload.assignmentTypes;
			state.brands = payload.brands;
			state.clients = payload.clients;
			state.campaigns = payload.campaigns;
			state.countries = payload.countries;
			state.influencers = payload.influencers;
			state.from = payload.from;
			state.to = payload.to;
			state.uploads = payload.uploads;

			return state;
		},
		setDashboardFilterAssignmentTypes(state, action: PayloadAction<Array<string>>) {
			state.assignmentTypes = action.payload;
			return state;
		},
		setDashboardFilterClients(state, action: PayloadAction<Array<string>>) {
			state.clients = action.payload;
			return state;
		},
		setDashboardFilterBrands(state, action: PayloadAction<Array<string>>) {
			state.brands = action.payload;
			return state;
		},
		setDashboardFilterCampaigns(state, action: PayloadAction<Array<string>>) {
			state.campaigns = action.payload;
			return state;
		},
		setDashboardFilterAssignments(state, action: PayloadAction<Array<string>>) {
			state.assignments = action.payload;
			return state;
		},
		setDashboardFilterInfluencers(state, action: PayloadAction<Array<string>>) {
			state.influencers = action.payload;
			return state;
		},
		setDashboardFilterCountries(state, action: PayloadAction<Array<string>>) {
			state.countries = action.payload;
			return state;
		},
		setDashboardFilterDateFrom(state, action: PayloadAction<string | undefined>) {
			state.from = moment(action.payload).format('YYYY-MM-DD');
			return state;
		},
		setDashboardFilterDateTo(state, action: PayloadAction<string | undefined>) {
			state.to = moment(action.payload).format('YYYY-MM-DD');
			return state;
		},
		setDashboardFilterUploads(state, action: PayloadAction<Array<string>>) {
			state.uploads = action.payload;
			return state;
		},
		clearDashboardFilter(state) {
			state = initialState;
			return state;
		},
	},
});

export const {
	setDashboardFilter,
	clearDashboardFilter,
	setDashboardFilterClients,
	setDashboardFilterAssignmentTypes,
	setDashboardFilterBrands,
	setDashboardFilterCampaigns,
	setDashboardFilterAssignments,
	setDashboardFilterInfluencers,
	setDashboardFilterCountries,
	setDashboardFilterDateFrom,
	setDashboardFilterDateTo,
	setDashboardFilterUploads,
} = dashboardFilterSlice.actions;
export default dashboardFilterSlice.reducer;
