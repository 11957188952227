import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgSidebar(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g id='svg-sidebar'>
				<g id='Vector'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M1.5 1.5V18.5H18.5V1.5H1.5ZM1 0C0.447715 0 0 0.447716 0 1V19C0 19.5523 0.447716 20 1 20H19C19.5523 20 20 19.5523 20 19V1C20 0.447715 19.5523 0 19 0H1Z'
						fill='#333333'
					/>
					<path fillRule='evenodd' clipRule='evenodd' d='M8.5 0.5V19.5H7.5V0.5H8.5Z' fill='#333333' />
				</g>
			</g>
		</svg>
	);
}
