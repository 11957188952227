import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	position: absolute;
	right: -450px;
	top: 0;
	width: 100%;
	transition:
		right 200ms ease-in-out,
		visibility 400ms ease-in-out,
		opacity 300ms ease-in-out;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	padding: 0.5rem;
	background-color: ${({ theme }) => theme.threeCol.sidebarBackground};

	&.visible {
		opacity: 1;
		visibility: visible;
		right: 0;
	}

	.text-center {
		margin-top: ${spacing[1].rem};
		text-align: center;
	}

	.bold {
		font-weight: bold;
	}
`;

const InnerWrapper = styled.div`
	overflow-y: scroll;
	height: 100%;
	padding-bottom: 1rem;
`;

const ListWrapper = styled.ul`
	padding: 0;
	margin-top: 0;
	list-style-type: none;
`;

const SearchWrapper = styled.div`
	padding: 0.5rem;
`;

const JoinedFilterWrapper = styled.div`
	display: flex;
	gap: 8px;
	margin: 8px;
	& > button {
		flex: 1;
	}
`;

const FilterButton = styled.div`
	height: 44px;
	width: 100%;
	border-radius: ${borderRadius.m};
	border: 1px solid ${colors.gray11};
	cursor: pointer;
	font-size: 0.875rem;
	font-weight: 500;

	padding: 0.9rem 1rem 1rem;
	color: #4d4b4c;
	border-radius: 10px;

	&.selected {
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
		border: 1px solid transparent;
	}
`;

const InputWrapper = styled.div``;

const TabsWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 0 0.5rem;
	margin-bottom: 16px;
	gap: 16px;
	height: 50px;
	div {
		padding: 1rem 0;
		font-size: 1rem;
		color: ${newColors.oceanBlue};
		width: 100%;
		text-align: center;
		cursor: pointer;
		border-bottom: 1.5px solid transparent;
		transition: border-color 0.2s ease-in-out;

		&.selected,
		&:hover {
			border-color: ${newColors.oceanBlue};
		}
		span {
			cursor: pointer;
			width: fit-content;
		}
	}
`;

const Styled = {
	TabsWrapper,
	Wrapper,
	InnerWrapper,
	ListWrapper,
	SearchWrapper,
	JoinedFilterWrapper,
	FilterButton,
	InputWrapper,
};

export default Styled;
