import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgSaved(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M2.89 20C2.72 20 2.55 19.95 2.4 19.84C2.15 19.66 2 19.35 2 19.02V0.98C2 0.44 2.4 0 2.89 0H17.11C17.6 0 18 0.44 18 0.98V19.02C18 19.35 17.85 19.66 17.59 19.84C17.33 20.02 17.01 20.04 16.74 19.9L10.23 16.57L3.25 19.92C3.14 19.98 3.01 20 2.89 20Z'
				fill='#333333'
			/>
		</svg>
	);
}
