import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgHome(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_9016_357995)'>
				<path
					d='M17.4 20H13.75C13.23 20 12.81 19.58 12.81 19.06V11.8C12.81 11.7 12.73 11.62 12.63 11.62H7.37C7.27 11.62 7.19 11.7 7.19 11.8V19.07C7.19 19.58 6.77 20 6.26 20H2.6C1.17 20 0 18.83 0 17.38V6.33C0 5.24 0.69 4.25 1.71 3.88L9.1 0.160002C9.68 -0.0499976 10.31 -0.0499976 10.89 0.160002L18.29 3.91C19.31 4.29 19.99 5.27 19.99 6.36V17.38C19.99 18.82 18.82 20 17.39 20H17.4ZM14.31 18.5H17.4C18.01 18.5 18.5 18 18.5 17.38V6.36C18.5 5.9 18.21 5.48 17.78 5.32L10.38 1.57C10.14 1.48 9.87 1.48 9.63 1.57L2.23 5.28C1.8 5.44 1.5 5.86 1.5 6.33V17.39C1.5 18 1.99 18.51 2.6 18.51H5.69V11.81C5.69 10.88 6.44 10.13 7.37 10.13H12.63C13.55 10.13 14.31 10.88 14.31 11.81V18.51V18.5Z'
					fill='#4D4B4C'
				/>
			</g>
			<defs>
				<clipPath id='clip0_9016_357995'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
