import styled from 'styled-components';

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const Styled = {
	ButtonContainer,
};

export default Styled;
