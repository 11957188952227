import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgSort(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_1962_37531)'>
				<path
					d='M8.03994 3.55L4.74994 0.25C4.66994 0.17 4.55994 0.11 4.43994 0.06C4.34994 0.02 4.25994 0 4.15994 0H4.13994C4.02994 0 3.92994 0.03 3.82994 0.07C3.70994 0.12 3.59994 0.18 3.52994 0.25L0.219941 3.55C-0.0700586 3.84 -0.0700586 4.32 0.219941 4.61C0.509941 4.9 0.989941 4.9 1.27994 4.61L3.37994 2.51V14.53C3.37994 14.94 3.71994 15.28 4.12994 15.28C4.53994 15.28 4.87994 14.94 4.87994 14.53V2.51L6.97994 4.61C7.12994 4.76 7.31994 4.83 7.50994 4.83C7.69994 4.83 7.88994 4.76 8.03994 4.61C8.32994 4.32 8.32994 3.84 8.03994 3.55Z'
					fill='#333333'
				/>
				<path
					d='M4.12988 17.6399C4.5441 17.6399 4.87988 17.3041 4.87988 16.8899C4.87988 16.4757 4.5441 16.1399 4.12988 16.1399C3.71567 16.1399 3.37988 16.4757 3.37988 16.8899C3.37988 17.3041 3.71567 17.6399 4.12988 17.6399Z'
					fill='#333333'
				/>
				<path
					d='M4.12988 20C4.5441 20 4.87988 19.6642 4.87988 19.25C4.87988 18.8358 4.5441 18.5 4.12988 18.5C3.71567 18.5 3.37988 18.8358 3.37988 19.25C3.37988 19.6642 3.71567 20 4.12988 20Z'
					fill='#333333'
				/>
				<path
					d='M11.9599 16.45L15.2599 19.75C15.3399 19.83 15.4499 19.89 15.5699 19.94C15.6599 19.98 15.7499 20 15.8499 20H15.8699C15.9799 20 16.0799 19.97 16.1799 19.93C16.2999 19.88 16.4099 19.82 16.4799 19.75L19.7799 16.45C20.0699 16.16 20.0699 15.68 19.7799 15.39C19.4899 15.1 19.0099 15.1 18.7199 15.39L16.6199 17.49V5.46997C16.6199 5.05997 16.2799 4.71997 15.8699 4.71997C15.4599 4.71997 15.1199 5.05997 15.1199 5.46997V17.49L13.0199 15.39C12.8699 15.24 12.6799 15.17 12.4899 15.17C12.2999 15.17 12.1099 15.24 11.9599 15.39C11.6699 15.68 11.6699 16.16 11.9599 16.45Z'
					fill='#333333'
				/>
				<path
					d='M15.8701 3.86011C16.2843 3.86011 16.6201 3.52432 16.6201 3.11011C16.6201 2.69589 16.2843 2.36011 15.8701 2.36011C15.4559 2.36011 15.1201 2.69589 15.1201 3.11011C15.1201 3.52432 15.4559 3.86011 15.8701 3.86011Z'
					fill='#333333'
				/>
				<path
					d='M15.8701 1.5C16.2843 1.5 16.6201 1.16421 16.6201 0.75C16.6201 0.335786 16.2843 0 15.8701 0C15.4559 0 15.1201 0.335786 15.1201 0.75C15.1201 1.16421 15.4559 1.5 15.8701 1.5Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1962_37531'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
