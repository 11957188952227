import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgCheckmark(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.6714 5.42696C20.1494 5.95135 20.1001 6.75354 19.5612 7.21872L9.75889 15.6802C9.28536 16.089 8.57859 16.1079 8.08269 15.7251L0.493779 9.86715C-0.070583 9.43151 -0.165164 8.63317 0.282527 8.084C0.730218 7.53483 1.55065 7.44279 2.11501 7.87843L8.84647 13.0745L17.8301 5.31977C18.369 4.85459 19.1933 4.90258 19.6714 5.42696Z'
				fill='#333'
			/>
		</svg>
	);
}
