/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { TokenPayload, LegacyTokenPayload, ValidateCredentialsPayload } from 'api-payloads';
import { SecurityTokenResponse, TokenResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import SecurityApiClientInterface from './SecurityApiClientInterface';

@injectable()
export default class SecurityApiClient implements SecurityApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Get discovery token
	 */
	async getDiscoveryToken(params: { include?: string }): Promise<SecurityTokenResponse> {
		const response = await this.client.post<SecurityTokenResponse>(`/discoverytokens`, undefined, { params });
		return response.data;
	}

	/**
	 * Token
	 */
	async token(payload: TokenPayload, params: { include?: string }): Promise<TokenResponse> {
		const response = await this.client.post<TokenResponse>(`/new-tokens`, payload, { params });
		return response.data;
	}

	/**
	 * @deprecated
	 */
	async legacyToken(payload: LegacyTokenPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/tokens`, payload, { params });
		return response.data;
	}

	/**
	 * Validate credentials
	 */
	async validateCredentials(payload: ValidateCredentialsPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/validate-credentials`, payload, { params });
		return response.data;
	}
}
