import classNames from 'classnames';
import { Model } from 'json-api-models';
import { debounce } from 'lodash';
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';

import { IntegratedInboxCampaign, TabKey } from 'components/IntegratedInbox/types';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchInput from 'components/SearchInput';

import InfluencerItem from './InfluencerItem';
import Styled from './InfluencerList.style';

type Props = {
	campaignInstagramOwners: Model[];
	conversations: Model[];
	isVisible: boolean;
	selectedCampaign: IntegratedInboxCampaign | null;
	isLoading: boolean;
	onSelect: (influencer: InfluencerConversation) => void;
	selectedInfluencer?: Model | null;
	onClickTab: (selected: TabKey, campaignId: string) => void;
	next: (selected: TabKey, query: string, campaignId: string) => void;
	isLoadingNext: boolean;
	onSearch: (campaignId: string, query: string, joined: boolean) => void;
};

const InfluencerList = ({
	campaignInstagramOwners,
	conversations,
	isVisible,
	selectedCampaign,
	isLoading,
	onSelect,
	selectedInfluencer,
	onClickTab,
	next,
	isLoadingNext,
	onSearch,
}: Props) => {
	const [search, setSearch] = useState('');
	const [selectedTab, setSelectedTab] = useState<TabKey>(TabKey.JOINED);
	const selectedTabRef = useRef<TabKey>(TabKey.JOINED);

	const innerWrapperRef = useRef<HTMLDivElement>(null);

	const sortedInfluencerItems = useMemo(() => {
		if (selectedCampaign?.campaign.legacy) {
			return [];
		}

		return campaignInstagramOwners.map((influencer) => new InfluencerConversation(influencer, conversations));
	}, [campaignInstagramOwners, conversations, selectedCampaign]);

	useEffect(() => {
		selectedTabRef.current = selectedTab;
	}, [selectedTab]);

	const handleScroll = useCallback(() => {
		if (innerWrapperRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = innerWrapperRef.current;
			const tolerance = 1;

			if (scrollTop + clientHeight >= scrollHeight - tolerance && !isLoadingNext && !isLoading && sortedInfluencerItems.length >= 20) {
				next(selectedTabRef.current, search, selectedCampaign!.id);
			}
		}
	}, [isLoadingNext, next, search, isLoading, sortedInfluencerItems, selectedCampaign]);

	const debouncedSearch = useCallback(
		debounce((searchValue: string) => {
			if (selectedCampaign && searchValue.length >= 3) {
				onSearch(selectedCampaign.shortId, searchValue, selectedTab === TabKey.JOINED);
			}
		}, 500), // 500 ms delay for debouncing
		[onSearch, selectedCampaign, selectedTab],
	);

	const handleSearchChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const newSearchValue = e.target.value;
			setSearch(newSearchValue);

			if (newSearchValue.length === 0) {
				if (selectedCampaign) {
					onSearch(selectedCampaign.shortId, '', selectedTab === TabKey.JOINED);
				}
			} else {
				debouncedSearch(newSearchValue);
			}
		},
		[debouncedSearch, onSearch, selectedCampaign, selectedTab],
	);

	const onClickTabItem = useCallback(
		(key: TabKey) => {
			onClickTab(key, selectedCampaign!.shortId);
			setSelectedTab(key);
		},
		[onClickTab, selectedCampaign],
	);

	const onReset = useCallback(() => {
		setSearch('');
		if (selectedCampaign) {
			onSearch(selectedCampaign.shortId, '', selectedTab === TabKey.JOINED);
		}
	}, [onSearch, selectedCampaign, selectedTab]);

	useEffect(() => {
		const innerWrapper = innerWrapperRef.current;
		if (innerWrapper) {
			innerWrapper.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (innerWrapper) {
				innerWrapper.removeEventListener('scroll', handleScroll);
			}
		};
	}, [handleScroll]);

	useEffect(() => {
		setSelectedTab(TabKey.JOINED);
	}, [selectedCampaign]);

	return (
		<Styled.Wrapper className={classNames({ visible: isVisible })}>
			<Styled.InnerWrapper ref={innerWrapperRef}>
				<Styled.ListWrapper>
					<Styled.SearchWrapper>
						<Styled.TabsWrapper>
							<div className={classNames({ selected: selectedTab === TabKey.JOINED })} onClick={() => onClickTabItem(TabKey.JOINED)}>
								<span>Joined</span>
							</div>
							<div
								className={classNames({ selected: selectedTab === TabKey.NOT_JOINED })}
								onClick={() => onClickTabItem(TabKey.NOT_JOINED)}
								data-testid='direct-message'
							>
								<span>Not joined</span>
							</div>
						</Styled.TabsWrapper>
						<Styled.InputWrapper>
							<SearchInput value={search} onKeyUp={() => {}} onChange={handleSearchChange} placeholder='Search influencers' onReset={onReset} />
						</Styled.InputWrapper>
					</Styled.SearchWrapper>
					{isLoading ? (
						<LoadingSpinner position='center' />
					) : (
						<>
							{sortedInfluencerItems.map((influencerConversation) => {
								const isSelected = selectedInfluencer ? selectedInfluencer.influencer.id === influencerConversation.id : false;
								return (
									<InfluencerItem
										data-testid={`conv-${influencerConversation.id}`}
										key={influencerConversation.id}
										name={influencerConversation.name}
										profileImage={influencerConversation.profileImage}
										onClick={() => {
											onSelect(influencerConversation);
										}}
										isSelected={isSelected}
										conversation={influencerConversation.conversation}
										hasUnreadMessages={influencerConversation.hasUnreadMessages}
									/>
								);
							})}
							{!sortedInfluencerItems.length && (
								<li className='text-center'>
									{search.length > 0 ? (
										<>
											Sorry, no match for <strong>&quot;{search}&quot;</strong>
										</>
									) : (
										'No influencers available'
									)}
								</li>
							)}
						</>
					)}
				</Styled.ListWrapper>
				{isLoadingNext && <LoadingSpinner position='center' size='sm' />}
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export class InfluencerConversation {
	id: string;
	name: string;
	profileImage: string;
	conversation: Model | undefined;
	hasUnreadMessages: boolean;
	latestMessageCreatedAt: string | undefined;
	influencer: Model;
	isInvited: boolean;
	joined: boolean;

	constructor(campaignInstagramOwner: Model, conversations: Model[]) {
		this.id = campaignInstagramOwner.influencer.id;
		this.name = campaignInstagramOwner.influencer.username;
		this.profileImage = campaignInstagramOwner.influencer.links.profilePictureUrl;
		this.conversation = conversations.find((conversation) => conversation.influencer.collabsId === campaignInstagramOwner.influencer.collabsId);
		this.hasUnreadMessages = this.conversation ? this.conversation.unreadMessages.length > 0 : false;
		this.latestMessageCreatedAt = this.conversation ? this.conversation.latestMessage.createdAt : undefined;
		this.influencer = campaignInstagramOwner;
		this.isInvited =
			campaignInstagramOwner.campaign && campaignInstagramOwner.campaign.invites ? this.checkInvite(campaignInstagramOwner.campaign.invites) : false;
		this.joined = campaignInstagramOwner.attributes.joined || false;
	}

	checkInvite(invites: Model[]) {
		if (invites.length > 0) {
			return invites.some((invite) => invite.influencer?.id === this.id);
		} else {
			return false;
		}
	}
}

export default InfluencerList;
