/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { HandleTiktokCodePayload } from 'api-payloads';
import { HandleTiktokCodeQuery } from 'api-queries';
import { CurrentUserResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import TiktokApiClientInterface from './TiktokApiClientInterface';

@injectable()
export default class TiktokApiClient implements TiktokApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Handle tiktok code
	 */
	async handleCode(payload: HandleTiktokCodePayload, params: HandleTiktokCodeQuery): Promise<CurrentUserResponse> {
		const response = await this.client.post<CurrentUserResponse>(`/tiktok/codes`, payload, { params });
		return response.data;
	}
}
