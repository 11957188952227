import styled from 'styled-components';

const Wrapper = styled.div`
	padding: 3rem;
	& > div {
		border: none;
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
