import { FormikValues } from 'formik';

import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import { Scenario } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import { InputText } from 'components/Form/FormikElements';
import { AssignmentType } from 'shared/helpers/Assigment/types';
import Grid from 'styles/grid/grid';

type StatisticFieldProps = {
	contentType: AssignmentType;
	values: FormikValues;
	profileConnected: boolean;
	scenario: Scenario | undefined;
};
/**
 * @returns {JSX.Element}
 */
const StatisticFields = ({ contentType, values, profileConnected, scenario }: StatisticFieldProps): JSX.Element => {
	const IS_STORY = contentType === AssignmentType.INSTAGRAM_STORY;
	const IS_TIKTOK = contentType === AssignmentType.TIKTOK_VIDEO;
	const IS_REEL = contentType === AssignmentType.INSTAGRAM_REEL;

	const WITH_STORY_SELECTOR = scenario === Scenario.STORY_SELECTOR;

	const onWheel = (e: React.WheelEvent) => {
		const target = e.target as HTMLElement | null;
		target?.blur();
	};

	const preventMinus = (e: React.KeyboardEvent) => {
		if (e.code === 'Minus') {
			e.preventDefault();
		}
	};

	const renderManualInputsForStory = () => {
		switch (scenario) {
			case Scenario.MANUAL_UPLOAD_SAVE:
			case Scenario.MANUAL_UPLOAD_UPDATE: {
				if (IS_STORY) {
					return true;
				}
				break;
			}
			default:
				return false;
		}
	};

	const renderManualInputsNotStory = () => {
		switch (scenario) {
			case Scenario.MANUAL_UPLOAD_SAVE:
			case Scenario.MANUAL_UPLOAD_UPDATE: {
				if (!IS_STORY) {
					return true;
				}
				break;
			}
			default:
				return false;
		}
	};

	return (
		<Styled.FieldsWrapper>
			{!IS_STORY && scenario === Scenario.READ_ONLY && (
				<>
					<h5 className='mb-24' data-testid='auto-not-story'>
						Statistics
					</h5>
					<Styled.AutomaticStats>
						<Styled.AutomaticStatsValue>
							<p>Reach</p>
							<span>{values.reach}</span>
						</Styled.AutomaticStatsValue>
						<Styled.AutomaticStatsValue>
							<p>{contentType === AssignmentType.INSTAGRAM_REEL || IS_TIKTOK ? 'Plays' : 'Impressions'}</p>
							<span>{values.impressions}</span>
						</Styled.AutomaticStatsValue>
						<Styled.AutomaticStatsValue>
							<p>Comments</p>
							<span>{values.comments}</span>
						</Styled.AutomaticStatsValue>
						<Styled.AutomaticStatsValue>
							<p>Likes</p>
							<span>{values.likes}</span>
						</Styled.AutomaticStatsValue>
						<Styled.AutomaticStatsValue>
							<p>Saves</p>
							<span>{values.saves}</span>
						</Styled.AutomaticStatsValue>
						{IS_TIKTOK && (
							<Styled.AutomaticStatsValue>
								<p>Shares</p>
								<span>{values.shares}</span>
							</Styled.AutomaticStatsValue>
						)}
					</Styled.AutomaticStats>
				</>
			)}
			{WITH_STORY_SELECTOR && (
				<>
					<h5 className='mb-24' data-testid='auto-story-selector'>
						Frame statistics
					</h5>
					<Styled.AutomaticStats>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Reach (first frame)</p>
								<span>{values.reach}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Reach (last frame)</p>
								<span>{values.reachLastFrame}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Impressions (first frame)</p>
								<span>{values.impressions}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
					</Styled.AutomaticStats>
				</>
			)}
			{renderManualInputsForStory() && (
				<>
					<h5 className='mb-24' data-testid='manual-for-story'>
						Frame statistics
					</h5>
					<Styled.InputGroupRow>
						<InputText
							min={0}
							label={`Reach ${IS_STORY ? '(first frame)' : ''}`}
							name='reach'
							type='number'
							placeholder='Leave empty if value is 0'
							onWheel={onWheel}
							onKeyPress={preventMinus}
						/>
						<InputText min={0} label='Reach (last frame)' type='number' name='reachLastFrame' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						<InputText
							min={0}
							label={`${IS_REEL || IS_TIKTOK ? 'Plays' : 'Impressions'} ${IS_STORY ? ' of the first frame' : ''}`}
							name='impressions'
							type='number'
							placeholder='Leave empty if value is 0'
							onWheel={onWheel}
						/>
					</Styled.InputGroupRow>
				</>
			)}
			{IS_STORY && (
				<>
					<h5 className='mb-8'>Interaction statistics</h5>
					{profileConnected && WITH_STORY_SELECTOR && <p className='help'>You need to add these manually.</p>}
					<Styled.InputGroupRow>
						<InputText
							min={0}
							label='Total sticker link clicks'
							type='number'
							name='stickerLinkClicks'
							placeholder='Leave empty if value is 0'
							onWheel={onWheel}
						/>
						<InputText min={0} label='Total sticker taps' type='number' name='stickerTaps' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						<InputText min={0} label='Other interactions' type='number' name='otherInteractions' placeholder='Leave empty if value is 0' onWheel={onWheel} />
					</Styled.InputGroupRow>
				</>
			)}

			{renderManualInputsNotStory() && (
				<>
					<h5 className='mb-24' data-testid='manual-for-not-story'>
						Add statistics
					</h5>
					<Styled.InputGroupRow>
						<InputText min={0} label='Reach' name='reach' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						<InputText
							min={0}
							label={`${IS_REEL || IS_TIKTOK ? 'Plays' : 'Impressions'}`}
							name='impressions'
							type='number'
							placeholder='Leave empty if value is 0'
							onWheel={onWheel}
						/>
					</Styled.InputGroupRow>
					<Styled.InputGroupRow>
						<InputText min={0} label='Comments' name='comments' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						<InputText min={0} label='Likes' name='likes' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />
					</Styled.InputGroupRow>
					<Styled.InputGroupRow>
						<div className={!IS_TIKTOK ? 'half' : ''}>
							<InputText min={0} label='Saves' name='saves' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						</div>
						{IS_TIKTOK && <InputText min={0} label='Shares' name='shares' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />}
					</Styled.InputGroupRow>
				</>
			)}
		</Styled.FieldsWrapper>
	);
};

export default StatisticFields;
