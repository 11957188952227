import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgArrowLeft(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.5207 0.462463C16.1598 1.07908 16.1598 2.07881 15.5207 2.69543L7.95053 10L15.5207 17.3046C16.1598 17.9212 16.1598 18.9209 15.5207 19.5375C14.8817 20.1542 13.8456 20.1542 13.2066 19.5375L4.47928 11.1165C3.84024 10.4999 3.84024 9.50013 4.47928 8.88352L13.2066 0.462463C13.8456 -0.154154 14.8817 -0.154154 15.5207 0.462463Z'
				fill='#333'
			/>
		</svg>
	);
}
