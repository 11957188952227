import SPCStyled from 'components/SocialProfileConnector/SocialProfileConnector.style';
import { DISCONNECT } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { InfluencerType } from 'reducers/UserReducers/types';

import Styled from './InteractiveProfileBadge.style';

export type Props = {
	influencer: InfluencerType;
	onDisconnect: (data: InfluencerType) => void;
	hideToggle?: boolean;
};

/**
 * InteractiveProfileBadge
 * @param {Props}
 * @returns {JSX.Element}
 */
const InteractiveProfileBadge = ({ influencer, onDisconnect, hideToggle }: Props): JSX.Element => {
	const { userCan } = useFeaturePermissions(influencer.links || {});

	return (
		<Styled.Li>
			<Styled.Row>
				<Styled.ProfilePicture>
					<img src={influencer.links?.profilePictureUrl ?? ''} alt={influencer.username} />
				</Styled.ProfilePicture>
				<Styled.Username>
					<span>@{influencer.username}</span>
				</Styled.Username>
				{!hideToggle && userCan(DISCONNECT) && (
					<SPCStyled.DisconnectButton data-testid='disconnect-button' onClick={() => onDisconnect(influencer)}>
						Disconnect
					</SPCStyled.DisconnectButton>
				)}
			</Styled.Row>
		</Styled.Li>
	);
};
export default InteractiveProfileBadge;
