import Icon from 'components/Icon';
import { AssignmentStatus, AssignmentReviewStatus } from 'shared/Types/Assignment';
import { ASSIGNMENT_TYPE } from 'views/Campaign/Dashboard/types';

/**
 * CampaignInstagramOwnerStatusText
 * @param {string} status
 * @returns {string}
 */
export const CampaignInstagramOwnerStatusText = (status: string): string => {
	switch (status) {
		case AssignmentStatus.WAITING_FOR_UPLOAD:
			return 'Awaiting content upload';
		case AssignmentStatus.IN_REVIEW:
		case AssignmentReviewStatus.IN_REVIEW:
			return 'Content to review';
		case AssignmentStatus.REVIEW_APPROVED:
			return 'Approved for posting';
		case AssignmentStatus.STATISTICS_UPLOADED:
			return 'Stats uploaded';
		case AssignmentStatus.APPROVED:
		case AssignmentReviewStatus.APPROVED:
			return 'Approved for payment';
		case AssignmentStatus.DECLINED:
			return 'Declined';
		case AssignmentStatus.DISPUTED:
			return 'Disputed';
		case AssignmentStatus.COMPENSATED:
			return 'Compensated';
		case AssignmentReviewStatus.REJECTED:
			return 'Rejected';
		case AssignmentReviewStatus.CHANGE_REQUESTED:
			return 'Changes requested';
		default:
			return '';
	}
};

export const CampaignInstagramOwnerStatusPriority = (status: string): number => {
	switch (status) {
		case AssignmentStatus.APPROVED:
		case AssignmentStatus.REVIEW_APPROVED:
			return 4;
		case AssignmentStatus.DECLINED:
		case AssignmentStatus.DISPUTED:
		case AssignmentReviewStatus.REJECTED:
			return 20;
		case AssignmentReviewStatus.CHANGE_REQUESTED:
			return 3;
		case AssignmentStatus.IN_REVIEW:
			return 2;
		case AssignmentStatus.STATISTICS_UPLOADED:
			return 15;
		default:
			return 1;
	}
};

export const ManagerCampaignInstagramOwnerStatusPriority = (status: string): number => {
	switch (status) {
		case AssignmentStatus.APPROVED:
			return 5;
		case AssignmentStatus.REVIEW_APPROVED:
			return 4;
		case AssignmentStatus.DECLINED:
		case AssignmentStatus.DISPUTED:
		case AssignmentReviewStatus.REJECTED:
			return 20;
		case AssignmentReviewStatus.CHANGE_REQUESTED:
			return 2;
		case AssignmentStatus.IN_REVIEW:
			return 1;
		case AssignmentStatus.STATISTICS_UPLOADED:
			return 3;
		default:
			return 14;
	}
};

export const customValidator = (file: File) => {
	const { type, size } = file;
	if (!type.includes('image') && !type.includes('video')) {
		return {
			code: 'file-format',
			message: `The Supported formats include JPEG, PNG, HEIC, MP4, MOV, AVI`,
		};
	}
	if (type.includes('image') && size > 10485760) {
		return { code: 'to-large-image', message: `The image file is too big. File size should be up to 10MB` };
	}
	if (type.includes('video') && size > 524288000) {
		return { code: 'to-large-video', message: `The video file is too big. File size should be up to 500MB` };
	}

	return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reorderArray = (event: { oldIndex: number; newIndex: number }, originalArray: any[]): Array<any> => {
	const movedItem = originalArray.find((_item, index) => index === event.oldIndex);
	const remainingItems = originalArray.filter((_item, index) => index !== event.oldIndex);

	if (event.newIndex == -1) {
		return originalArray;
	}

	const reorderedItems = [...remainingItems.slice(0, event.newIndex), movedItem, ...remainingItems.slice(event.newIndex)];

	return reorderedItems;
};

export const getUserFriendlyMediaType = (type: string) => {
	return type.includes('image') ? 'image' : 'video';
};

export const getAssignmentIcon = (assignmentType: string): JSX.Element => {
	switch (assignmentType) {
		case ASSIGNMENT_TYPE.INSTAGRAM_REEL:
			return <Icon name='instagram' size='24' className='org' />;
		case ASSIGNMENT_TYPE.INSTAGRAM_STORY:
			return <Icon name='instagram' size='24' />;
		case ASSIGNMENT_TYPE.TIKTOK_VIDEO:
			return <Icon name='tiktok' size='24' />;
		case ASSIGNMENT_TYPE.AFFILIATE_TASK:
			return <Icon name='affiliate-campaign' size='24' />;
		default:
			return <Icon name='instagram' size='24' />;
	}
};
