import { Model, Store } from 'json-api-models';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GHOST_TOKEN, GHOST_USER, GHOST_USER_UUID, GHOST_USER_START_URL } from 'constants/localStorage-keys';
import useInjection from 'hooks/useInjection';
import { useAppDispatch } from 'hooks/useUserAppSelector';
import { setUser } from 'reducers/UserReducers/UserSlice';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import BrowserStorage from 'shared/helpers/BrowserStorage/BrowserStorage';

/**
 * ImpersonateBar
 */
const ImpersonateBar = () => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);
	const storage = new BrowserStorage();

	return (
		<div className='header-ghosting'>
			<span>You&apos;re impersonating a user...</span>
			<button
				disabled={loading}
				className='no-style-btn text-strong cursor-pointer ml-16 text-small'
				onClick={async () => {
					const ghostStartUrl = storage.getItem(GHOST_USER_START_URL);
					storage.removeItem(GHOST_TOKEN);
					storage.removeItem(GHOST_USER_START_URL);
					storage.removeItem(GHOST_USER);
					storage.removeItem(GHOST_USER_UUID);
					storage.removeItem('user');

					setLoading(true);
					const data = await authService.me(authService.getGlobalUserIncludes());
					const user = new Store().sync(data) as Model;
					dispatch(setUser(user));
					setLoading(false);
					const destination = ghostStartUrl ?? '/admin/dashboard';
					if ('test' === process.env.NODE_ENV) {
						navigate(destination);
					} else {
						location.replace(destination);
					}
				}}
			>
				{loading ? '...' : 'Logout'}
			</button>
		</div>
	);
};

export default ImpersonateBar;
