import styled from 'styled-components';

import colors from 'styles/theme/colors';

const AdminPrePayments = styled.div`
	width: 100%;
`;

const PagNav = styled.div`
	cursor: pointer;
	font-weight: ${({ theme }) => theme.fontWeights.semi};
	font-size: 1rem;
	&.disabled {
		cursor: auto;
		color: ${colors.silver};
	}
`;

const Styled = {
	AdminPrePayments,
	PagNav,
};

export default Styled;
