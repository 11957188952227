// Authentication
export const KEY_TOKENS = 'tokens';

export const KEY_COLLABS_API_TOKEN = 'c_token';
export const KEY_COLLABS_API_USER_OBJECT = 'c_user';
export const KEY_MERCURE_TOKEN = 'm_token';

// Login state
export const KEY_REAUTHENTICATED = 'reauthenticated';

// Discovery Auth
export const KEY_DISCOVERY_API_TOKEN = 'd_token';

// Organization
export const ORGANIZATION_SLUG = 'organizationSlug'; // Current organization
export const GHOST_ORGANIZATION_SLUG = 'ghostOrganizationSlug'; // For ghost users to keep the real one

// Menu
export const KEY_HIDDEN_MENU_ITEMS = 'hiddenMenuItems'; // To store hidden menu items (Temporary)

// Facebook Auth
export const KEY_FACEBOOK_ACCESS_TOKEN = 'fb_token';

// Facebook Auth
export const KEY_REFERRAL_URL = 'referralUrl';

// Ghost user email
export const GHOST_USER_UUID = 'ghostUserUuid';
export const GHOST_USER_START_URL = 'ghostStartUrl';
export const GHOST_USER = 'ghostUser';
export const GHOST_TOKEN = 'ghostToken';

// Integrated Inbox
export const HAS_CAMPAIGN_INSTAGRAM_OWNER = 'hasCampaignInstagramOwner';

export const COLLECTED_PAYMENT = 'collectedPayment';

// Data library
export const LINK_TO_SHARE = 'linkToShare';

// Selected profile from the influencer selector
export const SELECTED_PROFILE = 'selectedProfile';

// Onboarding view
export const HAS_BEEN_ONBOARDING_VIEW = 'hasBeenOnboardingView';

export const COMPLETED_PROFILE_ON_LOGIN = 'hadCompletedProfileOnLogin';
