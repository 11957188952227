import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgPdfFile(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M18 5.36268L12.66 0.04002H12.35L2.95 0C2.43 0 2 0.42021 2 0.930465V19.0395C2 19.5498 2.43 19.97 2.95 19.97L8.08 20H17C17.55 20 18 19.5698 18 19.0495V5.77289C18 5.77289 18 5.74287 17.99 5.72286C17.99 5.70285 18 5.69285 18 5.67284V5.36268ZM13 2.49125L15.54 5.02251H13V2.49125ZM16.5 10.4852V18.5193H8.36L3.5 18.4892V1.49075L11.5 1.53077V5.66283C11.5 6.13307 11.88 6.51326 12.35 6.51326H16.5V10.4752V10.4852Z'
				fill='#333333'
			/>
			<path
				d='M8.12083 9.13953C7.95339 9.04651 7.74874 9 7.51618 9H6.3999V12.0698H7.14409V11.1209H7.51618C7.74874 11.1209 7.95339 11.0744 8.12083 10.9721C8.28827 10.8791 8.41851 10.7488 8.51153 10.5814C8.60455 10.4233 8.65107 10.2372 8.65107 10.0419C8.65107 9.84651 8.60455 9.66977 8.51153 9.51163C8.41851 9.35349 8.28827 9.23256 8.12083 9.13953ZM7.84176 10.3209C7.80455 10.3953 7.74874 10.4512 7.67432 10.4977C7.5999 10.5349 7.52548 10.5535 7.43246 10.5535H7.14409V9.55814H7.43246C7.52548 9.55814 7.5999 9.57674 7.67432 9.60465C7.74874 9.63256 7.79525 9.68837 7.84176 9.76279C7.87897 9.83721 7.90688 9.92093 7.90688 10.0419C7.90688 10.1628 7.88827 10.2465 7.84176 10.3209Z'
				fill='#333333'
			/>
			<path
				d='M11.1069 9.43718C10.9859 9.29765 10.8371 9.19532 10.6697 9.12091C10.5022 9.04649 10.3162 9.00928 10.1115 9.00928H9.04175V12.079H10.1115C10.3162 12.079 10.5022 12.0418 10.6697 11.9674C10.8371 11.893 10.9859 11.7814 11.1069 11.6418C11.2278 11.5023 11.3208 11.3442 11.3952 11.1488C11.4604 10.9628 11.4976 10.7581 11.4976 10.5349C11.4976 10.3116 11.4604 10.107 11.3952 9.9209C11.3301 9.73486 11.2371 9.57672 11.1069 9.43718ZM10.6417 11.0558C10.5766 11.2046 10.4836 11.3163 10.372 11.3907C10.2604 11.4651 10.1394 11.5116 9.99989 11.5116H9.77663V9.56742H9.99989C10.1394 9.56742 10.2604 9.60463 10.372 9.67905C10.4836 9.75346 10.5673 9.86509 10.6417 10.0139C10.7069 10.1535 10.7441 10.3302 10.7441 10.5349C10.7441 10.7395 10.7069 10.907 10.6417 11.0558Z'
				fill='#333333'
			/>
			<path d='M11.8882 12.0698H12.6324V10.8605H13.6556V10.293H12.6324V9.56744H13.8417V9H11.8882V12.0698Z' fill='#333333' />
		</svg>
	);
}
