import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgPen(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_1626_38766)'>
				<path
					d='M3.53004 18.0101L1.90004 16.3901L14.68 3.67007L13.83 2.82007L0.140035 16.4401C0.0400352 16.5401 -0.00996485 16.6701 3.5145e-05 16.8101L0.220035 19.6301C0.220035 19.7301 0.300035 19.8001 0.400035 19.8101L3.00004 20.0101C3.14004 20.0201 3.27003 19.9701 3.37003 19.8701L17.13 6.18007L16.28 5.33007L3.53004 18.0101Z'
					fill='#333333'
				/>
				<path
					d='M19.88 2.94006L17.05 0.12006C16.91 -0.0199402 16.71 -0.0299402 16.59 0.0800598L14.55 2.11006L17.89 5.43006L19.93 3.40006C20.05 3.28006 20.03 3.08006 19.89 2.94006H19.88Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1626_38766'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
