import styled, { DefaultThemeV2 } from 'styled-components';

import { Button, GhostButton } from 'components/Button';
import { DropdownMenu } from 'components/Dropdown/Dropdown';
import { InputText } from 'components/Form/FormikElements';
import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

type P = { theme: DefaultThemeV2 };

const cm = colors.contentManagement;

const Wrapper = styled.div``;

const MediaListItems = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 16px;
	margin-bottom: 32px;

	${mediaQueries.small`
		grid-template-columns: repeat(2, 1fr);
	`};

	${mediaQueries.large`
		grid-template-columns: repeat(3, 1fr);
	`};

	${mediaQueries.xl`
		grid-template-columns: repeat(3, 1fr);
`};

	${mediaQueries.xxl`
		grid-template-columns: repeat(3, 1fr);
	`};
`;

const Form = styled.form``;

const Label = styled.label`
	display: block;
	font-weight: 700;
	margin-bottom: 16px;
`;

const InputGroup = styled.div`
	margin-bottom: 36px;
	textarea {
		line-height: 1.5;
	}
`;

const TextArea = styled(InputText)`
	${scrollbarY};

	min-height: 150px;
	&:disabled {
		border: ${({ theme }) => theme.InputField.border};
		color: inherit;
	}
`;

const InputField = styled(InputText)`
	&:disabled {
		border: ${({ theme }) => theme.InputField.border};
		color: inherit;
	}
`;

const AddMediaItemText = styled.span`
	display: flex;
	align-items: center;
	text-align: center;
	font-size: 0.938rem;
	color: ${cm.post.addMediaItemTextColor};
	transition: color 200ms ease-in-out;

	.icon {
		display: block;
		margin-right: 8px;

		path {
			transition: fill 200ms ease-in-out;
			fill: ${cm.post.addMediaItemTextColor};
		}

		.cross {
			transition: stroke 200ms ease-in-out;
			stroke: ${cm.post.addMediaItemTextColor};
		}
	}
`;

const AddMediaItemWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;

const AddMediaItem = styled(Button)`
	width: 129px;
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${cm.post.addMediaItemBackground};
	border-radius: 4px;
	border: transparent;
	width: fit-content;
	cursor: pointer;
	transition: background-color 200ms ease-in-out;

	&:hover {
		background-color: ${colors.black1};
		border-color: ${colors.white};

		span {
			color: ${colors.white};
		}

		.icon path {
			fill: ${colors.white};

			&.cross {
				stroke: ${colors.white};
			}
		}
	}
`;

const InvisibleInput = styled.input`
	display: none;
`;

const ProductLinkInput = styled(InputText)``;

const ActionWrapper = styled.div`
	cursor: pointer;
	&.url-link-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 10px;
		background-color: ${colors.contentManagement.story.originalLink.editModeIconBackground};

		& > a {
			border: none;

			&:hover {
				border: none;
			}
		}
	}
`;

const ProductLinkBlockWrapper = styled.div`
	& > div {
		&.title {
			font-weight: 600;
			margin-bottom: 1rem;
		}

		&.help {
			font-size: 0.875rem;
			color: ${colors.form.input.disabledColor};
			margin-bottom: 1.5rem;
		}

		&.input-wrapper {
			display: flex;
			align-items: center;
			column-gap: 8px;
			& > div {
				margin-bottom: 0;
			}
		}
	}
`;

const OriginalLinkWrapper = styled.div`
	margin-top: 1rem;
	height: 64px;
	padding: 0.75rem 1rem;
	border-radius: ${borderRadius.s};
	border: 1px solid ${colors.contentManagement.story.originalLink.border};

	display: flex;
	align-items: center;
	column-gap: 16px;

	& > div {
		&.icon-wrapper {
			width: 40px;
			height: 40px;
			border-radius: 10px;
			&:hover {
				background-color: ${colors.contentManagement.story.originalLink.iconBackground};
			}

			display: flex;
			justify-content: center;
			align-items: center;
		}

		&.original-link {
			flex: 1;
			overflow-x: hidden;

			& > span {
				display: block;
				margin: 0;
				width: 100%;
				padding-top: 3px;
				color: ${colors.contentManagement.story.originalLink.color};
			}
		}

		&.link-button {
			& > a {
				border: none;
			}
		}
	}
`;

const TitleWrapper = styled.div`
	margin-top: 40px;
	margin-bottom: 8px;
	div {
		font-weight: 600;
	}
	p {
		margin: 0;
		color: ${({ theme }: P) => theme.colors.placeholder};
		font-size: 0.875rem;
	}
`;

const Title = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1.063rem;
	font-weight: 600;
	margin-bottom: 24px;
`;

const HelpText = styled.p`
	color: ${colors.contentManagement.helpText};
	font-size: 0.938rem;
`;

const SubmitSection = styled.div`
	position: absolute;
	background-color: ${colors.mainBlack};
	z-index: 10;
	bottom: 0;
	width: 100%;
	padding: 1rem;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
`;

const SubmitButton = styled(Button)`
	background: ${colors.contentManagement.infoBlue};
	border: 1px solid ${colors.contentManagement.infoBlue};
	color: ${colors.white};
	font-size: 0.938rem;
`;

const CustomGhostButton = styled(GhostButton)`
	font-size: 0.938rem;
`;
const Caption = styled.div`
	width: 100%;
	position: relative;
	margin-bottom: 8px;
	min-height: 221px;
	height: auto;
`;

const CaptionTopBar = styled.div`
	width: auto;
	display: flex;
	flex-direction: row;
	justify-content: end;
	align-items: center;
	gap: 0.5rem;
	position: absolute;
	top: 1px;
	left: 1px;
	right: 1px;
	color: ${({ theme }: P) => theme.colors.placeholder};
	padding: 0.5rem 1rem;
	background-color: ${newColors.mist};
	border-radius: ${borderRadius.m};
	z-index: 1;
`;

const TagMenu = styled(DropdownMenu)`
	border: none;
	padding: 0;
	border-radius: ${({ theme }: P) => theme.radius.default};
`;

const CommentsContainer = styled.div`
	padding-bottom: 5rem;
	scroll-padding-top: 200px;
`;

const TagWrapper = styled.div`
	display: flex;
	gap: 8px;
	margin-top: 8px;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 40px;
`;

const Tag = styled.div`
	margin-bottom: 5px;
	display: inline-block;
	font-size: ${typography.label.small.fontSize};
	background-color: ${colors.buttonGray};
	border: 1px solid ${colors.gray10};
	border-radius: ${borderRadius.m};
	padding: 0.5rem;
	padding-top: 0.6rem;
	&.error {
		border: 1px solid ${colors.errorRed};
	}
	cursor: pointer;
	&:hover {
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
	}
`;

const Styled = {
	TagWrapper,
	Tag,
	CommentsContainer,
	CustomGhostButton,
	SubmitSection,
	SubmitButton,
	TitleWrapper,
	Title,
	Wrapper,
	MediaListItems,
	Form,
	Label,
	TextArea,
	InputField,
	InputGroup,
	AddMediaItemWrapper,
	AddMediaItem,
	AddMediaItemText,
	InvisibleInput,
	ProductLinkInput,
	ActionWrapper,
	ProductLinkBlockWrapper,
	OriginalLinkWrapper,
	HelpText,
	Caption,
	CaptionTopBar,
	TagMenu,
};

export default Styled;
