import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	width: 100%;
	text-align: center;
	margin-top: ${spacing[3].px};
	margin-bottom: ${spacing[4].px};
`;

const Image = styled.img`
	margin-bottom: 16px;
`;

const Headline = styled.div`
	h2 {
		font-size: 1rem;
		line-height: 1.2;
		margin-bottom: 8px;
	}
`;

const Text = styled.div`
	p {
		margin-top: 0;
	}
`;

const ManualStatsBox = styled.div`
	margin: auto;
	width: 100%;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	gap: 16px;
	padding: ${spacing[3].px} ${spacing[4].px};
	border-radius: 10px;
	background-color: ${colors.buttonGray};
	h6 {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 1rem;
	}
	.link {
		border-bottom: 1px solid ${colors.contentManagement.infoBlue};
		padding-bottom: 2px;
		width: fit-content;
		margin: auto;
		cursor: pointer;
		color: ${colors.contentManagement.infoBlue};
		&:hover {
			color: ${colors.contentManagement.infoBlue};
		}
	}
`;

const Styled = {
	ManualStatsBox,
	Wrapper,
	Headline,
	Image,
	Text,
};

export default Styled;
