import styled from 'styled-components';

export interface ContainerBar {
	containerBg?: string;
}

export interface FillerBar {
	percent?: string;
	fillerBg?: string;
}

const ContainerBar = styled.div<ContainerBar>`
	width: 100%;
	min-height: 12px;
	background-color: ${(props) => props.containerBg};
	display: flex;
	border-radius: 20px;
`;

const FillerBar = styled.div<FillerBar>`
	width: ${(props) => props.percent};
	background-color: ${(props) => props.fillerBg};
	border-radius: 20px;
`;

const Styled = {
	ContainerBar,
	FillerBar,
};

export default Styled;
