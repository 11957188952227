import styled from 'styled-components';

import { Button } from 'components/Button';
import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';

const jc = colors.briefPage.joinCampaign;

const JoinTitle = styled.div`
	display: flex;
	align-items: center;
`;

const IconContainer = styled.div`
	margin-right: 10px;
	height: 48px;
	width: 48px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${jc.ligthBlue};
	.icon {
		margin-top: 7px;
	}
	.icon path {
		fill: ${jc.infoBlue};
	}
`;

const Text = styled.div``;

const ListWithLinks = styled.ul`
	list-style: none;
	margin-bottom: 0;
	li {
		padding: 0.5rem;
		cursor: pointer;
		&.selected {
			font-weight: 500;
			color: ${jc.infoBlue};
		}
	}
`;

const CampaignInfo = styled.ul`
	list-style: none;
	margin-bottom: 0;
	height: auto;
	${scrollbarY};
	& > li {
		padding: 0.5rem 0;
	}
`;

const ButtonSection = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	padding-bottom: 1rem;
	gap: 16px;
	margin-top: 16px;
	${mediaQueries.large`
		padding-bottom: 0;
	`};
`;

const AgreeSection = styled.div`
	display: flex;
	align-items: flex-start;
	width: calc(100% - 8px);
	padding: 0.5rem;
	margin: auto;
	cursor: pointer;
	border-radius: 4px;
	${mediaQueries.large`
		padding: 0.5rem 1rem;
		align-items: center;
		margin-top: 10px;
	`};
	&.isActive {
		border-color: ${colors.affiliateLinkGenerator.inputWrapper.hover.borderColor};
		box-shadow: 0 0 4px 0 ${colors.affiliateLinkGenerator.inputWrapper.hover.inputFocus};
	}
`;

const AgreeSectionText = styled.div`
	margin-left: 4px;

	a {
		&:hover {
			color: ${newColors.oceanBlue};
			border-bottom: 1px solid ${newColors.oceanBlue};
		}
	}
`;

const JoinButton = styled(Button)`
	background-color: ${jc.infoBlue};
	color: ${colors.white};
	border: none;
	width: 100%;
	&:disabled {
		background-color: ${colors.button.cancelBackground};
	}
	${mediaQueries.large`
		width: fit-content;
	`}
`;

const CancelButton = styled(Button)`
	background-color: ${colors.button.cancelBackground};
	border: none;
	width: 100%;
	${mediaQueries.large`
		width: fit-content;
	`}
`;

const CheckBoxContainer = styled.div`
	${mediaQueries.large`
		margin-left: 0;
	`};
`;

const BottomPartContainer = styled.div`
	margin-top: 10px;
	gap: 8px;
	display: flex;
	flex-direction: column;
	${mediaQueries.large`
		gap: 0;
		height: auto;
		margin-top: 0;
    justify-content: flex-end;
	`};
`;

const CheckMarkContainer = styled.div`
	display: flex;
	align-items: center;
	span {
		margin-left: 10px;
	}
`;

const EmptyDiv = styled.div`
	width: 13px;
`;

const Styled = {
	EmptyDiv,
	CheckMarkContainer,
	AgreeSectionText,
	Text,
	JoinTitle,
	IconContainer,
	ListWithLinks,
	CampaignInfo,
	ButtonSection,
	AgreeSection,
	JoinButton,
	CancelButton,
	CheckBoxContainer,
	BottomPartContainer,
};

export default Styled;
