import {
	ClickTotalQuery,
	ConversationDailyQuery,
	ConversationTotalQuery,
	CountryQuery,
	CpmTotalQuery,
	InfluencerTotalQuery,
	InvoiceDailyQuery,
	InvoiceTotalQuery,
} from 'api-queries';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import { ByCountryOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByCountry/types';
import { ByInfluencerOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByInfluencer/types';
import { OverTimeOutput } from 'components/DataLibrary/Graphs/GraphComponents/OverTime/types';
import { TotalOutput } from 'components/DataLibrary/Graphs/GraphComponents/Total/types';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

export const getSalesOverTime = (manager: StatisticsManager, filter: DashboardFilters): { result: OverTimeOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<ConversationDailyQuery>(filter);
	const { result, error } = manager.listConversationDaily(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = result.map((node) => {
		return {
			date: node.attributes.date,
			count: node.attributes.count,
		};
	});

	return { result: output };
};

export const getSalesTotal = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<ConversationTotalQuery>(filter);
	const { result, error } = manager.showConversationTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		value: result.attributes.saleAmount || 0,
		currency: result.attributes.currency || '',
	};

	return { result: output };
};

export const getConversionTotal = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<ConversationTotalQuery>(filter);
	const { result, error } = manager.showConversationTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		value: result.attributes.withSaleAmountCount || 0,
		currency: result.attributes.currency || '',
	};

	return { result: output };
};

export const getInfluencerSalesTotal = (manager: StatisticsManager, filter: DashboardFilters): { result: ByInfluencerOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<InfluencerTotalQuery>(filter);
	const { result, error } = manager.listInfluencerTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		influencers: result,
	};

	// TODO is this correct?
	return { result: output };
};

export const getBudgetTotal = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<InvoiceTotalQuery>(filter);
	const { result, error } = manager.showInvoiceTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		value: result.attributes.amount || 0,
		currency: result.attributes.currency || '',
	};

	return { result: output };
};

export const getBudgetOverTime = (manager: StatisticsManager, filter: DashboardFilters): { result: OverTimeOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<InvoiceDailyQuery>(filter);
	const { result, error } = manager.listInvoiceDaily(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = result.map((node) => {
		return {
			date: node.attributes.date,
			count: node.attributes.amount,
			// TODO make sure this is part of the type
			currency: node.attributes.currency,
		};
	});

	return { result: output };
};

export const getBudgetsByCountry = (manager: StatisticsManager, filter: DashboardFilters): { result: ByCountryOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<CountryQuery>(filter);
	const { result, error } = manager.listInvoiceCountry(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	let currency = undefined;
	const countries = [];
	for (const country of result) {
		if (country.attributes.amount > 0) {
			currency = country.attributes.currency;
			countries.push({
				label: country.attributes.country ?? '[no name]',
				value: country.attributes.amount,
			});
		}
	}

	const output = {
		total: result.reduce((prev: number, current) => prev + current.attributes.amount, 0),
		countries: countries,
		currency: currency,
	};

	// TODO verify format
	return { result: output };
};

export const getCPM = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<CpmTotalQuery>(filter);
	const { result, error } = manager.showCpmTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		value: result.attributes.amount || 0,
		currency: result.attributes.currency || '',
	};

	return { result: output };
};

export const getCPC = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qbInvoice = convertFilterToQueryBuilder<InvoiceTotalQuery>(filter);
	const { result: resultInvoice, error: errorInvoice } = manager.showInvoiceTotal(qbInvoice);

	const qbClick = convertFilterToQueryBuilder<ClickTotalQuery>(filter);
	const { result: resultClick, error: errorClick } = manager.showClickTotal(qbClick);

	if (!resultClick || !resultInvoice || errorInvoice || errorClick) {
		return { result: undefined, error: errorInvoice ?? errorClick };
	}

	let output;

	if (resultClick.attributes.count > 0 && resultInvoice.attributes.amount > 0) {
		output = { value: resultInvoice.attributes.amount / resultClick.attributes.count, currency: resultInvoice.attributes.currency };
	} else {
		output = { value: 0, currency: resultInvoice.attributes.currency };
	}

	return { result: output, error: undefined };
};
