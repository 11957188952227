import { JsonApiDocument } from 'json-api-models';

import { createClient } from 'shared/ApiClient/ApiClient';

export default function useSummaryData() {
	const client = createClient();

	const approve = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response.data;
	};

	const disapprove = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response.data;
	};

	return {
		approve,
		disapprove,
	};
}
