import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 34 28',
};

export function SvgBankId(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_5348_45540)'>
				<path
					d='M13.7986 14.3766L14.5234 9.83545C14.2379 9.83545 13.7492 9.83545 13.7492 9.83545C13.3868 9.83545 12.9201 9.6335 12.7828 9.26235C12.7389 9.13682 12.6345 8.70563 13.233 8.28536C13.4472 8.13799 13.5845 7.97425 13.6119 7.84871C13.6394 7.71772 13.6064 7.6031 13.5131 7.51577C13.3813 7.39023 13.1232 7.31927 12.7938 7.31927C12.2392 7.31927 11.8493 7.63584 11.8109 7.86508C11.7834 8.03428 11.9152 8.17074 12.0305 8.25807C12.3765 8.5146 12.4588 8.88575 12.2447 9.23506C12.025 9.5953 11.5473 9.83 11.0367 9.83545C11.0367 9.83545 10.5315 9.83545 10.246 9.83545C10.1801 10.2776 9.10385 17.0565 9.02148 17.5914H13.2934C13.3319 17.3567 13.5296 16.0741 13.7986 14.3766Z'
					fill='black'
				/>
				<path
					d='M8.79095 19.1633H10.5426C11.2893 19.1633 11.4705 19.5399 11.4156 19.8838C11.3717 20.1622 11.1795 20.3696 10.8501 20.506C11.2674 20.6643 11.4321 20.9099 11.3717 21.2974C11.2948 21.7832 10.872 22.1434 10.3174 22.1434H8.32422L8.79095 19.1633ZM9.94954 20.3969C10.29 20.3969 10.4492 20.2167 10.4822 20.0039C10.5151 19.7746 10.4108 19.6164 10.0703 19.6164H9.76834L9.64754 20.3969H9.94954ZM9.76285 21.6795C10.1143 21.6795 10.3174 21.5376 10.3669 21.2483C10.4053 20.9972 10.2625 20.8499 9.92209 20.8499H9.58165L9.44986 21.685H9.76285V21.6795Z'
					fill='black'
				/>
				<path
					d='M13.827 22.1653C13.3712 22.198 13.1516 22.1489 13.0418 21.9524C12.8002 22.0998 12.5311 22.1762 12.2456 22.1762C11.7294 22.1762 11.5482 21.9088 11.5977 21.614C11.6196 21.4721 11.702 21.3357 11.8338 21.221C12.1193 20.9754 12.8221 20.9427 13.0967 20.7571C13.1187 20.5497 13.0363 20.4733 12.7782 20.4733C12.4762 20.4733 12.2236 20.5715 11.7898 20.8663L11.8942 20.1895C12.2676 19.922 12.63 19.7965 13.0473 19.7965C13.5799 19.7965 14.0521 20.0148 13.9643 20.5934L13.8599 21.2483C13.8215 21.4776 13.8325 21.5485 14.0906 21.554L13.827 22.1653ZM13.0363 21.1337C12.7947 21.2865 12.3444 21.2592 12.295 21.5758C12.273 21.7232 12.3664 21.8323 12.5147 21.8323C12.6574 21.8323 12.8331 21.7723 12.9759 21.6741C12.9649 21.6195 12.9704 21.5649 12.9869 21.4612L13.0363 21.1337Z'
					fill='black'
				/>
				<path
					d='M14.6772 19.8347H15.5887L15.5393 20.1349C15.8303 19.8893 16.05 19.7965 16.3355 19.7965C16.8462 19.7965 17.0823 20.1076 16.9999 20.6152L16.7638 22.138H15.8523L16.05 20.8772C16.0884 20.6479 16.017 20.5388 15.8413 20.5388C15.6985 20.5388 15.5668 20.6152 15.4405 20.7844L15.2318 22.1325H14.3203L14.6772 19.8347Z'
					fill='black'
				/>
				<path
					d='M17.7128 19.1633H18.6243L18.3937 20.6261L19.2668 19.8347H20.3924L19.2723 20.8171L20.1728 22.138H19.0252L18.3333 21.0682H18.3223L18.1576 22.1325H17.2461L17.7128 19.1633Z'
					fill='black'
				/>
				<path d='M20.9378 19.1633H21.9866L21.5253 22.138H20.4766L20.9378 19.1633Z' fill='black' />
				<path
					d='M22.496 19.1633H23.9951C25.1537 19.1633 25.4886 19.9984 25.3788 20.6916C25.2745 21.3684 24.7363 22.138 23.7205 22.138H22.0293L22.496 19.1633ZM23.4679 21.4284C23.9786 21.4284 24.2586 21.1774 24.341 20.6479C24.4014 20.2549 24.2806 19.8674 23.715 19.8674H23.435L23.1934 21.4284H23.4679Z'
					fill='black'
				/>
				<path
					d='M19.5431 5.86743H15.1777L14.5957 9.54073H15.337C15.7433 9.54073 16.1277 9.35515 16.2924 9.0877C16.3473 9.00038 16.3693 8.92396 16.3693 8.85301C16.3693 8.70018 16.265 8.58556 16.1606 8.50915C15.8751 8.29628 15.8147 8.0725 15.8147 7.91422C15.8147 7.88147 15.8147 7.85418 15.8202 7.82689C15.8806 7.43936 16.4077 7.01909 17.1051 7.01909C17.5224 7.01909 17.8409 7.11733 18.033 7.29745C18.2033 7.45574 18.2692 7.67952 18.2197 7.91422C18.1593 8.19258 17.8793 8.42182 17.7201 8.53644C17.2973 8.83117 17.3522 9.0877 17.3796 9.16412C17.4675 9.39336 17.8024 9.54073 18.0605 9.54073H19.1916C19.1916 9.54073 19.1916 9.54073 19.1916 9.54618C20.7291 9.5571 21.5527 10.2612 21.2947 11.8986C21.0531 13.4214 19.878 14.0764 18.4778 14.0873L17.9232 17.6023H18.7414C22.1952 17.6023 25.0175 15.3973 25.5721 11.915C26.2585 7.59219 23.4965 5.86743 19.5431 5.86743Z'
					fill='black'
				/>
			</g>
			<defs>
				<clipPath id='clip0_5348_45540'>
					<rect width='34' height='28' fill='black' />
				</clipPath>
			</defs>
		</svg>
	);
}
