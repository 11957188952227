import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import colors from 'styles/variables/colors';

import type { ChartData, ChartOptions } from 'chart.js';

// eslint-disable-next-line prettier/prettier

export type GraphDataType = {
	name: string;
	value: number;
	secondValue: number;
	firstLineValue: number;
	valueLabel: string;
	secondValueLabel: string;
	firstLineValueLabel: string;
};

type DataSetType = {
	label: string;
	data: number[];
	type: string;
	backgroundColor: string;
	order: number;
	yAxisID: string;
};

const CombinedBarWithLineChart = (props: { graphData: GraphDataType[] }) => {
	const [data, setData] = useState<{ datasets: DataSetType[]; labels: string[] }>({
		datasets: [
			{
				label: 'Unknown',
				data: [0],
				type: 'bar',
				backgroundColor: '',
				order: 1,
				yAxisID: 'Y1',
			},
			{
				label: 'Unknown',
				data: [0],
				type: 'bar',
				backgroundColor: '',
				order: 1,
				yAxisID: 'Y2',
			},
		],
		labels: ['Unknown'],
	});

	const options = {
		plugins: {
			datalabels: {
				display: false,
			},
		},

		responsive: true,
		maintainAspectRatio: false,
		scales: {
			Y1: {
				type: 'linear',
				position: 'left',
				label: 'Reach',
				ticks: { beginAtZero: true },
				grid: { display: false },
			},
			Y2: {
				type: 'linear',
				position: 'right',
				label: 'Engagement',
				ticks: { beginAtZero: true },
				grid: { display: false },
			},
		},
	};

	useEffect(() => {
		if (props.graphData?.length > 0) {
			const labels: Array<string> = [];
			const valueData: Array<number> = [];
			const valueDataLabel = props.graphData[0].valueLabel;
			const secondValueData: Array<number> = [];
			const secondValueDataLabel = props.graphData[0].secondValueLabel;
			props.graphData.forEach((campaign: { name: string; value: number; secondValue: number; firstLineValue: number }) => {
				labels.push(campaign.name?.length > 12 ? campaign.name.slice(0, 12 - 1) + '…' : campaign.name);
				valueData.push(campaign.value);
				secondValueData.push(campaign.secondValue);
			});

			setData({
				datasets: [
					{
						label: valueDataLabel,
						data: valueData,
						type: 'bar',
						backgroundColor: colors.chartPrimary,
						order: 1,
						yAxisID: 'Y1',
					},
					{
						label: secondValueDataLabel,
						data: secondValueData,
						type: 'bar',
						backgroundColor: colors.chartSecondary,
						order: 2,
						yAxisID: 'Y2',
					},
				],
				labels: labels,
			});
		}
	}, [props.graphData]);

	return <Bar data={data as ChartData<'bar'>} width={400} height={300} options={options as ChartOptions<'bar'>} />;
};

export default CombinedBarWithLineChart;
