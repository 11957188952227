import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgUpload(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M9.32974 0.2775L3.71758 5.8875C3.42747 6.1775 3.42747 6.6575 3.71758 6.9475C4.0077 7.2375 4.48788 7.2375 4.77799 6.9475L9.24971 2.4775V14.0375C9.24971 14.4475 9.58984 14.7875 10 14.7875C10.2101 14.7875 10.3901 14.7075 10.5302 14.5675C10.6703 14.4275 10.7503 14.2475 10.7503 14.0375V2.4675L15.222 6.9375C15.5121 7.2275 15.9923 7.2275 16.2824 6.9375C16.5725 6.6475 16.5725 6.1675 16.2824 5.8775L10.6703 0.2775C10.3001 -0.0925 9.69988 -0.0925 9.32974 0.2775Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2 19.2476C2 18.8333 2.307 18.4976 2.68571 18.4976H17.3143C17.693 18.4976 18 18.8333 18 19.2476C18 19.6618 17.693 19.9976 17.3143 19.9976H2.68571C2.307 19.9976 2 19.6618 2 19.2476Z'
				fill='#333333'
			/>
		</svg>
	);
}
