import { PREFIX_CONST_REQUEST, PREFIX_NAME } from './base';

export const RESET = [PREFIX_NAME, 'RESET', 'REDUCER'].join('_');

export const AUTH = {
	...PREFIX_CONST_REQUEST('AUTH_LOGIN'),
	...PREFIX_CONST_REQUEST('AUTH_LOGOUT'),
	...PREFIX_CONST_REQUEST('AUTH_REFETCH'),
};

export const CURRENTUSER = {
	...PREFIX_CONST_REQUEST('CURRENTUSER_SEARCH_FEATURE'),
	...PREFIX_CONST_REQUEST('CURRENTUSER_SWITCH_FEATURE'),
};
