import { InputHTMLAttributes } from 'react';

import InputComponent from 'components/Forms/Input';

import Styled from './Input.style';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
}

const Input = (props: IInput) => {
	return (
		<Styled.Wrapper>
			{props.label && <Styled.Label>{props.label}</Styled.Label>}
			<InputComponent {...props} type='text' />
		</Styled.Wrapper>
	);
};

export default Input;
