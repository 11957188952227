import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgCoupon(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_2038_27802)'>
				<path
					d='M18.966 10.4408L19.9898 1.02367C20.0202 0.749977 19.929 0.476282 19.7364 0.283682C19.5337 0.0505347 19.2701 -0.0102864 18.9964 0.00998731L9.74147 0.881756C9.52859 0.90203 9.33599 0.993261 9.18394 1.14531L0.334472 9.98465C-0.14196 10.4611 -0.111549 11.2518 0.395293 11.7586L8.23108 19.5944C8.73792 20.1012 9.53873 20.1316 10.005 19.6552L18.6923 10.9679C18.8342 10.826 18.9255 10.6334 18.9559 10.4307L18.966 10.4408ZM9.16367 18.378L1.63199 10.8463L10.1064 2.37187L18.3983 1.59134L17.4759 10.0556L9.15353 18.378H9.16367Z'
					fill='#333333'
				/>
				<path
					d='M13.2589 4.2577C12.5696 4.94701 12.5696 6.05192 13.2589 6.74123C13.9482 7.43054 15.0531 7.43054 15.7425 6.74123C16.4318 6.05192 16.4318 4.94701 15.7425 4.2577C15.0531 3.5684 13.9482 3.5684 13.2589 4.2577ZM15.0227 4.97742C15.3167 5.27139 15.3167 5.73768 15.0227 6.02151C14.7288 6.30535 14.2625 6.31548 13.9786 6.02151C13.6948 5.72755 13.6847 5.26125 13.9786 4.97742C14.2726 4.69359 14.7389 4.68345 15.0227 4.97742Z'
					fill='#333333'
				/>
				<path d='M5.83887 11.1405L5.88955 11.7487L11.7892 11.2723L11.7385 10.6641L5.83887 11.1405Z' fill='#333333' />
				<path
					d='M8.22097 9.44736C8.28179 9.60955 8.37302 9.75147 8.49467 9.87311C8.61631 9.99475 8.76836 10.0961 8.93055 10.1569C9.09274 10.2178 9.25493 10.2279 9.41712 10.1975C9.57931 10.1671 9.73136 10.086 9.853 9.95421C9.97465 9.82243 10.0659 9.67037 10.0963 9.50818C10.1267 9.34599 10.1166 9.1838 10.0557 9.02162C9.99492 8.85943 9.90369 8.71751 9.77191 8.58573C9.64013 8.45395 9.50835 8.37286 9.34616 8.31204C9.18397 8.25121 9.02178 8.24108 8.85959 8.27149C8.6974 8.3019 8.54535 8.38299 8.42371 8.51477C8.30207 8.64655 8.21083 8.78847 8.18042 8.95066C8.15001 9.11285 8.16015 9.27504 8.22097 9.43723V9.44736ZM9.05219 8.84929C9.11301 8.83915 9.17384 8.84929 9.23466 8.86956C9.29548 8.89997 9.34616 8.93038 9.38671 8.97093C9.43739 9.02162 9.4678 9.0723 9.49821 9.13312C9.52863 9.19394 9.53876 9.25476 9.52862 9.31558C9.52862 9.3764 9.48808 9.44736 9.41712 9.50818C9.34616 9.56901 9.29548 9.60955 9.22452 9.60955C9.1637 9.60955 9.09274 9.60955 9.04206 9.57914C8.98124 9.54873 8.93055 9.51832 8.87987 9.46764C8.83932 9.42709 8.79877 9.3764 8.7785 9.31558C8.74809 9.25476 8.74809 9.19394 8.75823 9.13312C8.75823 9.0723 8.80891 9.01148 8.86973 8.95066C8.93055 8.88984 8.99137 8.84929 9.06233 8.83915L9.05219 8.84929Z'
					fill='#333333'
				/>
				<path
					d='M9.33601 12.9244C9.27519 12.7623 9.18396 12.6203 9.05218 12.4886C8.9204 12.3568 8.78862 12.2757 8.62644 12.2149C8.46425 12.154 8.30206 12.1439 8.13987 12.1743C7.97768 12.2047 7.82562 12.2858 7.70398 12.4176C7.58234 12.5494 7.49111 12.6913 7.4607 12.8535C7.43029 13.0157 7.44042 13.1779 7.50124 13.3401C7.56207 13.5022 7.6533 13.6442 7.77494 13.7658C7.89658 13.8874 8.04864 13.9888 8.21082 14.0496C8.37301 14.1105 8.5352 14.1206 8.69739 14.0902C8.85958 14.0598 9.01164 13.9787 9.13328 13.8469C9.25492 13.7151 9.34615 13.5631 9.37656 13.4009C9.40697 13.2387 9.3867 13.0765 9.33601 12.9143V12.9244ZM8.51493 13.5225C8.45411 13.5225 8.38315 13.5225 8.33247 13.4921C8.27165 13.4617 8.22096 13.4313 8.17028 13.3806C8.12973 13.3401 8.08918 13.2894 8.06891 13.2286C8.0385 13.1677 8.0385 13.1069 8.04863 13.0461C8.05877 12.9853 8.09932 12.9244 8.16014 12.8636C8.22096 12.8028 8.28178 12.7623 8.35274 12.7521C8.41356 12.742 8.47438 12.7521 8.5352 12.7724C8.59602 12.8028 8.64671 12.8332 8.68726 12.8738C8.73794 12.9244 8.76835 12.9751 8.79876 13.036C8.82917 13.0968 8.83931 13.1576 8.82917 13.2184C8.82917 13.2792 8.78862 13.3502 8.71767 13.411C8.64671 13.4718 8.59602 13.5124 8.52507 13.5124L8.51493 13.5225Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2038_27802'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
