import classNames from 'classnames';
import { Model } from 'json-api-models';
import { useEffect, useRef, useState } from 'react';

import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Styled from 'components/InfluencerManagement/InfluencerManagement.style';

export const getCompanyName = (user: Model) => {
	const brandNames = user.brandPrivileges.map((privilege: Model) => privilege.brand.attributes.name);
	const brands = brandNames.filter((brand: string, index: number) => {
		return brandNames.indexOf(brand) === index;
	});
	const clientNames = user.clientPrivileges.map((privilege: Model) => privilege.client.attributes.name);
	const clients = clientNames.filter((client: string, index: number) => {
		return clientNames.indexOf(client) === index;
	});
	const publisherNames = user.publisherPrivileges.map((privilege: Model) => privilege.publisher.attributes.name);
	const publishers = clientNames.filter((client: string, index: number) => {
		return publisherNames.indexOf(client) === index;
	});
	return brands.length > 0 ? brands[0] : clients.length > 0 ? clients[0] : publishers.length > 0 ? publishers[0] : '-';
};

type SearchProps = {
	onSelect: (value: Model) => void;
	allUsers: Array<Model>;
	selectedIds: Array<string>;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	sharedWithUsers: Array<Model>;
};

/**
 * Search input
 * @param SearchProps
 * @returns {JSX.Element}
 */
const Search = ({ onSelect, allUsers, selectedIds, isOpen, setIsOpen, sharedWithUsers }: SearchProps): JSX.Element => {
	const [searchValue, setSearchValue] = useState<string>('');
	const [cursor, setCursor] = useState(-1);
	const [filteredSuggestions, setFilteredSuggestions] = useState<Array<Model>>(allUsers);
	const menuRef = useRef<HTMLDivElement>(null);

	const keyboardNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'ArrowDown' && cursor < filteredSuggestions.length - 1) {
			e.preventDefault();
			setCursor((prev) => prev + 1);
		} else if (e.key === 'ArrowUp' && cursor > 0) {
			e.preventDefault();
			setCursor((prev) => prev - 1);
		} else if (e.key === 'Escape') {
			setCursor(-1);
			setFilteredSuggestions([]);
		} else if (e.key === 'Enter' && filteredSuggestions.length > 0) {
			setSearchValue('');
			onSelect(filteredSuggestions[cursor]);
		}
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		!isOpen && setIsOpen(true);
		setSearchValue(value);
		if (value === '') {
			setFilteredSuggestions(allUsers);
		} else {
			const newFilteredSuggestions = allUsers.filter((user) => user.attributes.name.toLowerCase().includes(value.toLowerCase()));
			setFilteredSuggestions(newFilteredSuggestions);
		}
	};

	useEffect(() => {
		!isOpen && setSearchValue('');
	}, [isOpen]);

	useEffect(() => {
		if (searchValue === '') setFilteredSuggestions(allUsers);
	}, [searchValue]);

	const handleEscapeKey = (e: KeyboardEvent): void => {
		if (menuRef && menuRef.current && e.key === 'Escape') {
			setIsOpen(false);
		}
	};

	const handleClickOutside = (e: MouseEvent): void => {
		if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', handleClickOutside, true);
		document.addEventListener('keyup', handleEscapeKey, true);
		return () => {
			window.removeEventListener('click', handleClickOutside, true);
			document.addEventListener('keyup', handleEscapeKey, true);
		};
	}, []);

	return (
		<Styled.DropdownInnerContainer>
			<Field label='Share with'>
				<Input
					placeholder='Search for team members'
					value={searchValue}
					onChange={onChange}
					onClick={() => !isOpen && setIsOpen(true)}
					onKeyDown={(e) => {
						keyboardNavigation(e);
					}}
				/>
			</Field>

			<Styled.ListContainer className={classNames({ isOpen: isOpen })} ref={menuRef}>
				<Styled.List>
					{filteredSuggestions
						.filter((user: Model) => !sharedWithUsers.map((u: Model) => u.id).includes(user.id))
						.map((item: Model, i: number) => {
							const isSelected = selectedIds.includes(item.id);
							return (
								<Styled.ListItem
									data-testid='dl-search-dashboard-filtered-item'
									key={i}
									onClick={() => {
										setSearchValue('');
										onSelect(item);
									}}
									className={classNames({ keyDownSelect: i === cursor, selected: isSelected })}
								>
									<Styled.NameAndCompany>
										<span>{item.attributes.name}</span>
										<p>{getCompanyName(item)}</p>
									</Styled.NameAndCompany>
									<Styled.SelectDiv className={classNames({ keyDownSelect: i === cursor })}>{isSelected ? 'remove' : 'select'}</Styled.SelectDiv>
								</Styled.ListItem>
							);
						})}
				</Styled.List>
			</Styled.ListContainer>
		</Styled.DropdownInnerContainer>
	);
};

export default Search;
