import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';

import { InvoicesTableProps } from 'components/AdminPrePayments/components/types';
import useAdminPrePaymentsData from 'components/AdminPrePayments/hooks/useAdminPrePaymentsData';
import { PrePaymentItemType } from 'components/AdminPrePayments/types';
import Copy from 'components/Copy/Copy';
import DownloadableUrl from 'components/DownloadableUrl';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import ConfirmModal from 'components/Modals/ConfirmModal';
import Pill from 'components/Pill';
import TableComponent, { Column } from 'components/Table/V2/Table';
import Tooltip from 'components/Tooltip/V2';
import { formatAmount } from 'utils/formatters';

import ClientInvoices from './ClientInvoices';
import Styled from './InvoicesTable.style';

/**
 * InvoicesTable
 * @param {InvoicesTableProps} props
 * @returns {JSX.Element}
 */
const InvoicesTable = (props: InvoicesTableProps): JSX.Element => {
	const [editingFortnoxId, setEditingFortnoxId] = useState<string | null>(null);
	const [fortnoxIdValue, setFortnoxIdValue] = useState<string>('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { updateFortnoxId } = useAdminPrePaymentsData();

	const handleFortnoxIdUpdate = (id: string, fortnoxId: string) => {
		setIsLoading(true);
		updateFortnoxId(id, fortnoxId)
			.then((res) => {
				if (res.status === 200) {
					toast.success('Fortnox id updated');
					setEditingFortnoxId(null);
					setFortnoxIdValue('');
					setIsModalOpen(false);
					props.refresh();
				}
			})
			.catch(() => {
				toast.error('Failed to update fortnox id');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	// sort after paidAt
	const items =
		props.items &&
		props.items.sort((a, b) => {
			if (a.paidAt === null && b.paidAt !== null) {
				return -1; // a comes before b
			}
			if (a.paidAt !== null && b.paidAt === null) {
				return 1; // b comes before a
			}
			if (a.scheduledAt === null && b.scheduledAt !== null) {
				return -1; // a comes before b
			}
			if (a.scheduledAt !== null && b.scheduledAt === null) {
				return 1; // b comes before a
			}
			// Both a and b have null paidAt or both have actual dates, sort by scheduledAt
			return new Date(b.scheduledAt || '').getTime() - new Date(a.scheduledAt || '').getTime();
		});

	const columns: Column<PrePaymentItemType>[] = useMemo(
		() => [
			{
				Header: 'Influencer',
				accessor: 'influencer',
				minWidth: 350,
				Cell: ({ row }) => (
					<Styled.InfluencerData>
						<p className='text-strong'>{row.original.influencer?.username ? `@${row.original.influencer.username}` : row.original.companyName} </p>
						{row.original.influencer?.user?.kind && <Pill title={row.original.influencer?.user?.kind} className={row.original.influencer?.user?.kind} />}
					</Styled.InfluencerData>
				),
			},
			{
				Header: 'Campaign',
				accessor: 'name',
				minWidth: 200,
				Cell: ({ row }) => (
					<Tooltip content={row.original.name}>
						<Styled.CampaignName>{row.original.name}</Styled.CampaignName>
					</Tooltip>
				),
			},
			{
				Header: 'Amount',
				accessor: 'amount',
				Cell: ({ row }) => formatAmount(row.original.amount, row.original.currency),
			},
			{
				Header: 'Scheduled',
				accessor: 'scheduledAt',
				Cell: ({ row }) => moment(row.original.scheduledAt).format('YYYY-MM-DD'),
			},
			{
				Header: 'Status',
				accessor: 'paidAt',
				minWidth: 170,
				Cell: ({ row }) => (
					<>
						{row.original.paidAt ? <> Paid {moment(row.original.paidAt).format('YYYY-MM-DD')}</> : row.original.status.replace(/_/g, ' ')}
						{!isEmpty(row.original.failureReasons) && (
							<Tooltip content={JSON.stringify(row.original.failureReasons)}>
								<Styled.FailureWrapper>
									<div>Error</div> <Icon name='info-circle' size='16' />
								</Styled.FailureWrapper>
							</Tooltip>
						)}
					</>
				),
			},
			{
				Header: 'Invoice(s)',
				accessor: 'id',
				Cell: ({ row }) => <ClientInvoices items={row.original.invoices} />,
			},
			{
				Header: 'Fortnox Id',
				accessor: 'fortnoxId',
				Cell: ({ row }) =>
					row.original.fortnoxId ? (
						<span
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setEditingFortnoxId(row.original.id);
								setFortnoxIdValue(row.original.fortnoxId?.toString() ?? '');
								setIsModalOpen(true);
							}}
						>
							{row.original.fortnoxId}
						</span>
					) : (
						<span>-</span>
					),
			},
			{
				Header: 'Info',
				accessor: 'agreement',
				Cell: ({ row }) =>
					row.original.agreement?.links?.createDownload || row.original.file?.links?.createDownload ? (
						<Dropdown icon='options' size='16' className='display-circle'>
							<DropdownMenu>
								<DropdownItem className='noClick'>
									<strong>
										{row.original.influencer?.user?.firstName} {row.original.influencer?.user?.lastName}
									</strong>
								</DropdownItem>
								<DropdownItem>
									{row.original.influencer?.user?.email}
									<Copy iconSize='12' description='email' value={row.original.influencer?.user?.email ?? ''} />
								</DropdownItem>
								{row.original.agreement?.links?.createDownload && (
									<DropdownItem>
										<DownloadableUrl
											url={row.original.agreement.links.createDownload}
											originalFilename={row.original.agreement.originalName}
											attributeName='url'
										>
											Download Agreement
										</DownloadableUrl>
									</DropdownItem>
								)}
								{row.original.file?.links?.createDownload && (
									<DropdownItem>
										<DownloadableUrl url={row.original.file.links.createDownload} originalFilename={row.original.file.originalName} attributeName='url'>
											Download Invoice
										</DownloadableUrl>
									</DropdownItem>
								)}
							</DropdownMenu>
						</Dropdown>
					) : (
						'-'
					),
			},
		],
		[props.items],
	);

	return (
		<Styled.Wrapper data-testid='invoices-table'>
			<TableComponent className='payments-table' columns={columns} data={items} enableSorting={true} />
			<ConfirmModal
				isFetching={isLoading}
				isModalOpen={isModalOpen}
				toggleModal={() => {
					setIsModalOpen(!isModalOpen), setEditingFortnoxId(null), setFortnoxIdValue('');
				}}
				title='Edit fortnox ID'
				buttonText='Yes'
				action={() => handleFortnoxIdUpdate(editingFortnoxId!, fortnoxIdValue.trim())}
			>
				<Formik initialValues={{ fortnoxIdValue: fortnoxIdValue }} onSubmit={() => {}} enableReinitialize>
					{() => {
						return (
							<Form>
								<Field label='Edit fortnoxId'>
									<Input
										name='fortnoxIdValue'
										data-testid='fortnoxIdValue'
										value={fortnoxIdValue}
										autoFocus
										onChange={(e) => setFortnoxIdValue(e.target.value)}
									/>
								</Field>
							</Form>
						);
					}}
				</Formik>
			</ConfirmModal>
		</Styled.Wrapper>
	);
};

export default InvoicesTable;
