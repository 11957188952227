import { useEffect, useState } from 'react';

import { InfluencerListListModel } from 'api-models';
import { ShareDrawerProps } from 'components/InfluencerManagement/Lists/types';
import ShareDrawer from 'components/ShareDrawer';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import InfluencerListManager from 'services/ApiManager/InfluencerList.manager';
import toast from 'services/Toast';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

/**
 * Share list component
 * @param ShareDrawerProps
 * @returns {JSX.Element}
 */
const ShareList = ({ sidebarIsOpen, setSidebarIsOpen, listToShare, allUsers, updateListView }: ShareDrawerProps): JSX.Element => {
	const manager = useInjection<InfluencerListManager>(InfluencerListManager);

	const user = useAppSelector((state) => state.user);

	const queryBuilder = RequestQueryBuilder.create().withInclude('users').withInclude('parentUsers');
	const { repository, mutate } = manager.getList(listToShare.id, queryBuilder);

	const sharedWithUsers = repository.findAll<InfluencerListListModel>('user') ?? [];
	const [selectedIds, setSelectedIds] = useState<string[]>([]);

	const onSave = () => {
		return manager
			.updateList(listToShare.id, { users: selectedIds }, undefined, {})
			.then(() => {
				toast.success(
					selectedIds.length > 0 ? `${listToShare.attributes.name} is now shared with ${selectedIds.length - 1} users` : 'List not shared with anyone',
				);
				setSidebarIsOpen(false);
				mutate();
				updateListView(); // Remove this when list view is refactored
			})
			.catch((error) => {
				console.error('Error sharing the list', error);
			});
	};

	useEffect(() => {
		setSelectedIds(sharedWithUsers.map((user: InfluencerListListModel) => user.id));
	}, [sharedWithUsers.length, sidebarIsOpen]);

	useEffect(() => {
		setSelectedIds(sharedWithUsers.map((user: InfluencerListListModel) => user.id));
	}, []);

	return (
		<ShareDrawer
			title='Share list'
			sidebarIsOpen={sidebarIsOpen}
			setSidebarIsOpen={setSidebarIsOpen}
			name={listToShare.attributes.name}
			nonShared='You are not sharing this list yet'
			createdByMe={listToShare.attributes.createdByMe}
			createdBy={undefined}
			updatedAt={listToShare.attributes.updatedAt}
			allUsers={allUsers}
			sharedWithUsers={sharedWithUsers}
			setSelectedUsers={setSelectedIds}
			selectedIds={selectedIds}
			onSave={onSave}
			user={user}
		/>
	);
};

export default ShareList;
