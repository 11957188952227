import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';

/**
 * @returns {JSX.Element}
 */
const InfluencerNotConnected = (): JSX.Element => {
	return (
		<Styled.ConnectInfoAlert>
			<div className='d-flex'>
				<span className='text'>Add a social account to upload your statistics automatically.</span>
				<span className='divider'></span> <Styled.FacebookConnectLink to='/influencer/settings/profiles'>Add account in settings</Styled.FacebookConnectLink>
			</div>
		</Styled.ConnectInfoAlert>
	);
};

export default InfluencerNotConnected;
