import Navigation from 'components/Navigation/Navigation';

import Styled from './BasicLayout.style';

type BasicLayoutProps = {
	pageHeaderComponent: JSX.Element;
	navigationMenu: Array<{ icon: string; title: string | JSX.Element; url: string; disabled: boolean; testId: string }>;
	sidebarComponent: JSX.Element;
	mainContent: JSX.Element;
};
/**
 * @returns {JSX.Element}
 */
const BasicLayout = ({ pageHeaderComponent, navigationMenu, sidebarComponent, mainContent }: BasicLayoutProps): JSX.Element => {
	return (
		<Styled.Wrapper>
			{pageHeaderComponent}
			<Styled.WrapperInner>
				<Styled.LeftContainer>
					<Navigation Menu={navigationMenu} />
				</Styled.LeftContainer>
				<Styled.MainContainer>
					<Styled.MainContainerInner>{mainContent}</Styled.MainContainerInner>
				</Styled.MainContainer>
			</Styled.WrapperInner>
			{sidebarComponent}
		</Styled.Wrapper>
	);
};

export default BasicLayout;
