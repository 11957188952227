// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getKeyByValue = (object: any, value: any) => {
	return Object.keys(object).find((key) => object[key] === value);
};

export const getTotalCount = (data: Array<number>) => {
	let totalCount = 0;
	if (data.length > 0) {
		totalCount = data.reduce((prev, current) => prev + current, 0);
	}
	return totalCount;
};

export const formatNumberWithDecimals = (value: number, suffix?: string) => {
	let result;
	const targetValue = value * 100;
	if (targetValue >= 100) {
		result = 100;
	} else if (targetValue >= 10) {
		result = targetValue.toFixed(1);
	} else if (targetValue === 0) {
		result = 0;
	} else {
		result = targetValue.toFixed(2);
	}
	return suffix ? `${result}${suffix}` : result;
};

export const formatDate = (date: Date) => {
	let month = '' + (date.getMonth() + 1);
	let day = '' + date.getDate();
	const year = date.getFullYear();

	if (month.length < 2) {
		month = '0' + month;
	}
	if (day.length < 2) {
		day = '0' + day;
	}

	return [year, month, day].join('-');
};

export const formatDateAndMonth = (date: string) => {
	const newDate = new Date(date);
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const month = months[newDate.getMonth()];
	const day = '' + newDate.getDate();

	return [day, month].join(' ');
};

export const getMonthBeforeDate = (date: Date) => {
	const monthBefore = date;
	monthBefore.setMonth(monthBefore.getMonth() - 1);
	monthBefore.setDate(monthBefore.getDate() - 1);
	return monthBefore;
};

type TransformToLineChartDataInput = {
	year?: number;
	month?: number;
	date?: string;
	count: number;
};

export const transformToLineChartData = (input: TransformToLineChartDataInput[], color: string) => {
	let labels: string[] = [];
	let value: number[] = [];
	if (input.length) {
		labels = input.map((node) => {
			if (!node.year && node.date) {
				return formatDateAndMonth(node.date);
			}
			return formatDate(new Date(node.year!, node.month!, 1));
		});

		value = input.map((node) => {
			return node.count ? node.count : 0;
		});
	}

	return { labels: labels, datasets: [{ data: value, borderColor: color }] };
};

export const formatNumber = (value: number | string, integer = false, additionalOptions: Intl.NumberFormatOptions = {}): string => {
	let result = '0';
	if (typeof value === 'string') {
		result = value;
	} else if (typeof value === 'number') {
		const fractionDigits = 2;
		const options: Intl.NumberFormatOptions = {
			minimumFractionDigits: 0,
			maximumFractionDigits: integer ? 0 : fractionDigits,
		};

		if (value >= 10_000) {
			options.notation = 'compact';
			options.compactDisplay = 'short';
			options.maximumFractionDigits = fractionDigits;
			options.minimumFractionDigits = fractionDigits;
		}

		const formatter = new Intl.NumberFormat('en', { ...options, ...additionalOptions });

		return formatter.format(value);
	} else {
		result = 'N/A';
	}
	return result;
};

export const formatPercent = (value: number | string) => {
	let valueInPercent;
	if (typeof value === 'number') {
		valueInPercent = value * 100;
	} else {
		valueInPercent = parseInt(value) * 100;
	}

	if (valueInPercent < 0.005) {
		return `< ${valueInPercent.toFixed(2)} %`;
	}
	if (valueInPercent % 1 !== 0) {
		return valueInPercent.toFixed(2) + ' %';
	} else {
		return valueInPercent + ' %';
	}
};
