import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgRefresh(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg fill='none' {...svgProps}>
			<path
				d='M17.38 10.55C17.04 10.55 16.76 10.82 16.76 11.16C16.76 15.36 13.28 18.77 9.01 18.77C4.74 18.77 1.25 15.36 1.25 11.16C1.25 6.96 4.63 3.65 8.82 3.55L7.36 4.99C7.12 5.23 7.12 5.62 7.36 5.85C7.48 5.97 7.64 6.03 7.8 6.03C7.96 6.03 8.12 5.97 8.24 5.85L10.73 3.4C10.97 3.16 10.97 2.77 10.73 2.54L8.32 0.18C8.08 -0.06 7.68 -0.06 7.44 0.18C7.2 0.42 7.2 0.81 7.44 1.04L8.76 2.33C3.91 2.47 0 6.37 0 11.16C0 15.95 4.04 20 9 20C13.96 20 18 16.04 18 11.16C18 10.82 17.72 10.55 17.38 10.55Z'
				fill='#F2F2F2'
			/>
		</svg>
	);
}
