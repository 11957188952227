import classNames from 'classnames';
import { isNil } from 'lodash';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import oneLike from 'assets/img/app/one-like.svg';
import fallbackLogotype from 'assets/img/logo/collabs-logo-dark.svg';
import Icon from 'components/Icon';
import Grid from 'styles/grid/grid';
import Styled from 'views/influencer/NewSignUp/InfluencerSignUpContainer.style';

import AccountCreation from './AccountCreation';

type SignUpProps = {
	email?: string;
	inviteToken?: string;
};

/**
 */
const SignUp = ({ inviteToken, email }: SignUpProps) => {
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [steps, setSteps] = useState<number>(1);

	return (
		<Styled.CustomGridContainer id='Signup'>
			<Grid.Column lg={6}>
				<Styled.Card>
					<Grid.Container className='h-full'>
						<Grid.Column lg={2}></Grid.Column>
						<Grid.Column lg={8} className='h-full'>
							<Styled.LeftCardContent>
								<div>
									<p>Why sign up?</p>
									<ul>
										<li>
											<Icon name='checkmark' size='16' />
											<h5>Seamless campaign management</h5>
										</li>
										<li>
											<Icon name='checkmark' size='16' />
											<h5>Easy & secure payments</h5>
										</li>
										<li>
											<Icon name='checkmark' size='16' />
											<h5>Direct feedback & communication</h5>
										</li>
									</ul>
								</div>
								<img src={oneLike} alt='Collabs' className={classNames({ display: termsAccepted })} />
							</Styled.LeftCardContent>
						</Grid.Column>
						<Grid.Column lg={2}></Grid.Column>
					</Grid.Container>
				</Styled.Card>
			</Grid.Column>
			<Grid.Column lg={6}>
				<Grid.Container>
					<Grid.Column lg={2}></Grid.Column>
					<Grid.Column lg={8}>
						<Styled.Wrapper>
							<img src={fallbackLogotype} alt='Collabs' width='110px' height='auto' />
							<Styled.Title>
								<div>{!isNil(inviteToken) && steps === 2 ? 'Connect account' : 'Create account'}</div>
								{steps === 1 && (
									<div>
										<Link to='/login'>or log in</Link>
									</div>
								)}
							</Styled.Title>
							<AccountCreation
								steps={steps}
								setSteps={setSteps}
								inviteToken={inviteToken}
								email={email}
								getAcceptedTerms={(termsAccepted: boolean) => setTermsAccepted(termsAccepted)}
							/>
						</Styled.Wrapper>
					</Grid.Column>
					<Grid.Column lg={2}></Grid.Column>
				</Grid.Container>
			</Grid.Column>
		</Styled.CustomGridContainer>
	);
};

export default SignUp;
