import { Model } from 'json-api-models';
import { useState } from 'react';

import { DarkButton, GhostButton } from 'components/Button';
import ApproveModal from 'components/ContentManagement/Components/ContentSection/Components/ApproveModal';
import { CampaignInstagramOwnerStatusText, getAssignmentIcon } from 'components/ContentManagement/Utils';
import { CampaignItemType } from 'components/ContentManagement/types';
import { APPROVE, DISAPPROVE } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { isAssignmentApproved, isAssignmentDisapproved, AssignmentReviewStatus, AssignmentStatus } from 'shared/Types/Assignment';
import { getLatestReview } from 'shared/utils/getLatestReview';
import { AssignmentReview } from 'views/Campaign/Dashboard/types';

import Styled from './AssingmentList.style';

type AssignmentListProps = {
	activeCampaign: CampaignItemType;
	assignments: Model[];
	onClickApprove?: (url: string) => void;
	onClickDisapprove?: (url: string) => void;
};

interface CIOAssignment {
	reviews: Array<AssignmentReview>;
	newFancyStatus: AssignmentStatus;
	/**
	 * @deprecated
	 */
	status: string;
	links: {
		approve: string;
		disapprove: string;
	};
}

/**
 * AssignmentList
 * @param {AssignmentListProps} props
 * @returns {JSX.Element}
 */
const AssignmentList = ({ assignments, onClickApprove, onClickDisapprove }: AssignmentListProps): JSX.Element => {
	const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);

	const actions = (CIOAssignment: CIOAssignment) => {
		const CIOALinks = CIOAssignment.links;
		const CIOAPermissions = useFeaturePermissions(CIOALinks);
		const isApproved = isAssignmentApproved(CIOAssignment.newFancyStatus);
		const isDisapproved = isAssignmentDisapproved(CIOAssignment.newFancyStatus);

		return (
			<Styled.ActionButtons>
				{CIOAPermissions.userCan(DISAPPROVE) && !(isApproved || isDisapproved) && (
					<a
						onClick={(e) => {
							e.preventDefault(), onClickDisapprove && onClickDisapprove(CIOALinks.disapprove);
						}}
					>
						Decline
					</a>
				)}
				{CIOAPermissions.userCan(APPROVE) && !isApproved && (
					<GhostButton
						size='sm'
						onClick={() => {
							onClickApprove && onClickApprove(CIOALinks.approve);
						}}
						data-testid='cm-approve-btn'
					>
						Approve for payment
					</GhostButton>
				)}

				{isDisapproved && (
					<DarkButton size='sm' disabled>
						Declined
					</DarkButton>
				)}
			</Styled.ActionButtons>
		);
	};

	return (
		<>
			<Styled.Wrapper>
				{assignments &&
					assignments.length > 0 &&
					assignments.map((item) => {
						const latestReview = getLatestReview(item.reviews);

						return (
							<Styled.Item key={item.id}>
								<Styled.Icon>{getAssignmentIcon(item.assignment.kind)}</Styled.Icon>
								<Styled.Content>
									<Styled.AssignmentName data-testid='cm-summary-assignment-name'>
										<Styled.Name>{item.assignment.name}</Styled.Name>
										<Styled.Status>
											<span>
												{CampaignInstagramOwnerStatusText(
													latestReview &&
														(latestReview.newStatus === AssignmentReviewStatus.CHANGE_REQUESTED || latestReview.newStatus === AssignmentReviewStatus.REJECTED)
														? latestReview.newStatus
														: item.newFancyStatus,
												)}
											</span>
										</Styled.Status>
									</Styled.AssignmentName>
									{actions(item as unknown as CIOAssignment)}
								</Styled.Content>
							</Styled.Item>
						);
					})}
			</Styled.Wrapper>
			<ApproveModal isOpen={isApproveModalOpen} onClickApprove={() => {}} handleClose={() => setIsApproveModalOpen(false)} isLoading={false} />
		</>
	);
};
export default AssignmentList;
