import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	width: 100%;
	height: 48px;
	padding: ${guttersWithRem.xs};
	display: flex;
	column-gap: ${guttersWithRem.xxs};

	align-items: center;
	cursor: pointer;

	& > figure {
		margin-left: 0;
	}

	& > div {
		&.name {
			font-size: 0.875rem;
			font-weight: 500;
		}
	}

	&:hover {
		background-color: ${colors.integratedInbox.blastModal.search.item.hover.background};
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
