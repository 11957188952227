import styled from 'styled-components';

import Accordion from 'components/Accordion';
import Card from 'components/Card';
import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	margin-bottom: 50px;
	width: 100%;
	${mediaQueries.large`
		width: 50%;
		margin: auto;
		margin-bottom: 50px;
	`};
`;

const CustomCard = styled(Card)`
	background-color: ${colors.mainWhite};
	border: 1px solid ${colors.borderGray};
	h6 {
		margin: 16px 0 0;
		font-size: 1rem;
		font-weight: 600;
		font-family: ${typography.BaseFontFamiliy};
	}
	p {
		font-family: ${typography.BaseFontFamiliy};
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.7;
		letter-spacing: 0em;
		color: ${colors.influencerView.grayText};
	}
`;

const CustomAccordion = styled(Accordion)`
	.accordion__header {
		margin-left: 0%;
		padding: 1rem;

		${mediaQueries.large`
			padding: 1rem 1rem 2rem 1.5rem;
	`};
	}
	.accordion__title {
		margin-left: 0;
		span {
			padding-right: 0 !important;
		}
		h6 {
			margin: 25px 0 0;
			font-size: 1rem;
			font-weight: 600;
			font-family: ${typography.BaseFontFamiliy};
		}
		p {
			font-family: ${typography.BaseFontFamiliy};
			font-size: 0.875rem;
			font-weight: 400;
			line-height: 1.7;
			letter-spacing: 0em;
			color: ${colors.influencerView.grayText};
		}
	}
	.accordion__toggleIcon {
		margin-left: -18px;
	}
`;

const Step = styled.div`
	display: flex;
	align-items: flex-start;
	width: calc(100% - 22px);
`;

const StepContent = styled.div`
	display: flex;
	justify-content: space-between;
	border-top: 1px solid ${colors.borderAlmostLightGray};
	padding: 1.1rem 0 1rem;
	margin-left: 8px;
	width: 100%;
	&.clickable {
		cursor: pointer;
	}
	div {
		&.align-top {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			padding-right: 1rem;
			h5 {
				font-size: 0.875rem;
				font-weight: 500;
				color: ${colors.influencerView.darkGray};
				margin-bottom: 0;
			}
			p {
				font-size: 0.875rem;
				font-weight: 400;
				color: ${colors.influencerView.grayText};
			}
		}
		&.align-center {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
`;

const IconWrapper = styled.div`
	margin-top: 18px;
`;

const AwesomeCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	img {
		height: 114px;
		width: auto;
	}
	${mediaQueries.medium`
			flex-direction: row;
			padding: 1rem 1rem 1rem 0;
	`};
`;

const AwesomeCardContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	${mediaQueries.medium`
			margin-left: -55px;
			margin-top: 15px;
			justify-content: space-between;
			flex-direction: row;
	`};
`;

const Styled = {
	Wrapper,
	CustomCard,
	CustomAccordion,
	Step,
	StepContent,
	IconWrapper,
	AwesomeCard,
	AwesomeCardContent,
};

export default Styled;
