import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgPlay(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M10.0015 0C4.48519 0 0.00146484 4.48372 0.00146484 10C0.00146484 15.5163 4.48519 20 10.0015 20C15.5177 20 20.0015 15.5163 20.0015 10C20.0015 4.48372 15.5177 0 10.0015 0ZM10.0015 18.6047C5.25728 18.6047 1.39681 14.7442 1.39681 10C1.39681 5.25581 5.25728 1.39535 10.0015 1.39535C14.7457 1.39535 18.6061 5.25581 18.6061 10C18.6061 14.7442 14.7457 18.6047 10.0015 18.6047Z'
				fill='#333333'
			/>
			<path
				d='M8.53151 4.17511C8.31151 3.98511 7.99151 3.94511 7.73151 4.06511C7.47151 4.18511 7.30151 4.45511 7.30151 4.74511V14.7451C7.30151 15.0351 7.47151 15.2951 7.73151 15.4251C7.83151 15.4751 7.94151 15.4951 8.05151 15.4951C8.22151 15.4951 8.39151 15.4351 8.53151 15.3251L14.5315 10.4051C14.7015 10.2651 14.8015 10.0551 14.8015 9.83511C14.8015 9.61511 14.7015 9.40511 14.5315 9.25511L8.53151 4.17511ZM8.80151 13.1651V6.36511L12.8815 9.81511L8.80151 13.1651Z'
				fill='#333333'
			/>
		</svg>
	);
}
