import Styled from './InlineEditButton.style';

export type InlineEditButtonProps = {
	label: string;
	editMode: boolean;
	setEditMode: () => void;
	saveChanges: () => void;
};
/**
 * Actions
 * @return {JSX.Element}
 */
const InlineEditButton = ({ label, editMode, saveChanges, setEditMode }: InlineEditButtonProps): JSX.Element => {
	return (
		<Styled.EditSlider>
			<Styled.ConfirmCancel editMode={editMode} className='edit' onClick={setEditMode} data-testid='edit'>
				<div>{label}</div>
			</Styled.ConfirmCancel>
			<Styled.ConfirmCancel editMode={editMode} className='done' data-testid='confirm' onClick={saveChanges}>
				<div>Done</div>
			</Styled.ConfirmCancel>
		</Styled.EditSlider>
	);
};

export default InlineEditButton;
