import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { IN_DEVELOPMENT } from 'constants/enviroments';
import { FEATURE_FLAG_FORCE_CONNECT } from 'constants/feature-flag-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import usePermissions from 'hooks/usePermissions';
import { useAppSelector } from 'hooks/useUserAppSelector';

type ProtectedRouteProps = {
	children: JSX.Element;
};

/**
 * ProtectedRoute
 * Route container to check the user is logged in or not. If not logged in, navigate to the login page and set the referral url.
 * @param {ProtectedRouteProps} props
 * @returns {JSX.Element}
 */
export const ConnectGuard = ({ children }: ProtectedRouteProps): JSX.Element => {
	const user = useAppSelector((state) => state.user);
	const navigate = useNavigate();
	const [isEnabled] = useFeatureToggle();
	const { isInfluencer } = usePermissions();

	useEffect(() => {
		if (!IN_DEVELOPMENT && isEnabled(FEATURE_FLAG_FORCE_CONNECT) && isInfluencer() && !user.hasBeenLinkedToInfluencers) {
			navigate('/influencer/connect');
		}
	}, []);

	return children;
};
