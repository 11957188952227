import { useField } from 'formik';
import { forwardRef } from 'react';

import Styled from 'components/Form/FormikElements/FormikElements.style';
import FieldComponent from 'components/Forms/Field';

import { capitalizeFirstLetter, parseMaxLength, resolveFieldType } from './Utils';
import { FormInputTextProps } from './types';

/**
 * Field for formik. Supports input, select and texteras
 * @param {FormInputTextProps} props
 * @returns {JSX.Element}
 */
const InputText = forwardRef<HTMLElement, FormInputTextProps>((props, ref) => {
	const {
		min,
		label,
		name,
		id,
		action,
		helpText,
		maxLength,
		disabled,
		autoFocus,
		hideValidationMessage,
		scale = 'medium',
		error,
		className,
		displayInfoTooltip,
		...inputProps
	} = props;

	const [field, meta] = useField({ name, id });
	const numericMaxLength = parseMaxLength(maxLength);

	const getErrorMessage = (): string | undefined => {
		if (error) return error;
		if (meta.error?.includes('field must be later than')) {
			return 'End date must be later than start date';
		}
		if (meta.error?.includes('end is a required field')) {
			return 'This field is required';
		}
		return meta.error;
	};

	const FieldType = resolveFieldType(props.as);

	return (
		<Styled.FieldContainer className='form-field-container'>
			<FieldComponent
				className={className}
				id={id}
				displayInfoTooltip={displayInfoTooltip}
				label={capitalizeFirstLetter(label ?? '')}
				hint={helpText}
				validationState={!hideValidationMessage && (meta.error || error) ? 'error' : undefined}
				validationMessage={getErrorMessage()}
				disabled={disabled}
				readOnly={props.readOnly}
				orientation={props.orientation}
				scale={scale}
			>
				<FieldType
					id={id}
					autoFocus={autoFocus}
					min={min}
					maxLength={numericMaxLength}
					{...field}
					{...inputProps}
					value={field.value || ''}
					ref={ref}
					name={name}
					isError={meta.error}
					disabled={disabled}
					data-testid={`fc-${name}`}
					contentBefore={props.contentBefore}
					contentAfter={action || props.contentAfter}
				/>
			</FieldComponent>
		</Styled.FieldContainer>
	);
});

export default InputText;
