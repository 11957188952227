import Icon from 'components/Icon';

import Styled from './SuccessMessage.style';

type SuccessMessageProps = {
	text: string;
};

const SuccessMessage = ({ text }: SuccessMessageProps) => {
	return (
		<Styled.Wrapper>
			<Styled.Message>
				<Icon name='check-circle' /> <span className=' text-strong'>{text}</span>
			</Styled.Message>
		</Styled.Wrapper>
	);
};

export default SuccessMessage;
