import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgDirectMessage(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_812_477887)'>
				<path
					d='M19.07 6.79004H7.76997C7.25997 6.79004 6.83997 7.21004 6.83997 7.71004V15.72C6.83997 16.23 7.24997 16.64 7.76997 16.64H13.38C13.9 17.52 15.01 19 16.48 19C16.71 19 16.92 18.89 17.06 18.71C17.2 18.53 17.25 18.29 17.19 18.07C17.06 17.6 17.04 16.92 17.2 16.7C17.22 16.68 17.25 16.63 17.46 16.63H19.08C19.59 16.63 20.01 16.22 20.01 15.71V7.71004C20.01 7.20004 19.6 6.79004 19.08 6.79004H19.07ZM18.54 15.18H17.4C17.4 15.18 17.37 15.18 17.36 15.18C16.66 15.2 16.26 15.54 16.04 15.82C15.75 16.2 15.65 16.67 15.63 17.11C15.21 16.74 14.81 16.19 14.52 15.66C14.36 15.36 14.05 15.18 13.71 15.18H8.32997V8.25004H18.55V15.18H18.54Z'
					fill='#4D4B4C'
				/>
				<path
					d='M5.66994 7.84H4.82994C4.54994 7.84 4.29994 8 4.17994 8.24C4.01994 8.56 3.75994 8.95 3.46994 9.26C3.45994 9.02 3.42994 8.78 3.36994 8.57C3.18994 7.89 2.64994 7.84 2.49994 7.84H1.47994V2.46H9.53994V5.3C9.53994 5.7 9.86994 6.03 10.2699 6.03C10.6699 6.03 10.9999 5.7 10.9999 5.3V1.9C10.9999 1.4 10.5999 1 10.0999 1H0.899939C0.399939 1 -6.10352e-05 1.4 -6.10352e-05 1.9V8.4C-6.10352e-05 8.9 0.399939 9.3 0.899939 9.3H1.98994C2.00994 9.66 1.96994 10.11 1.92994 10.37C1.89994 10.58 1.95994 10.8 2.09994 10.96C2.23994 11.12 2.43994 11.22 2.65994 11.22C3.90994 11.22 4.83994 9.98 5.25994 9.3H5.66994C6.06994 9.3 6.39994 8.97 6.39994 8.57C6.39994 8.17 6.06994 7.84 5.66994 7.84Z'
					fill='#4D4B4C'
				/>
			</g>
			<defs>
				<clipPath id='clip0_812_477887'>
					<rect width='20' height='18' fill='white' transform='translate(-6.10352e-05 1)' />
				</clipPath>
			</defs>
		</svg>
	);
}
