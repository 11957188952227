/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { CreatePinebucketJWTPayload } from 'api-payloads';
import { PinebucketExternalAccountCollectionResponse, PinebucketTokenResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import PinebucketApiClientInterface from './PinebucketApiClientInterface';

@injectable()
export default class PinebucketApiClient implements PinebucketApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List external accounts
	 */
	async listExternalAccounts(params: { include?: string }): Promise<PinebucketExternalAccountCollectionResponse> {
		const response = await this.client.get<PinebucketExternalAccountCollectionResponse>(`/pinebucket/external-accounts`, { params });
		return response.data;
	}

	/**
	 * Create pinebucket j w t
	 */
	async createJWT(payload: CreatePinebucketJWTPayload, params: { include?: string }): Promise<PinebucketTokenResponse> {
		const response = await this.client.post<PinebucketTokenResponse>(`/pinebucket/jwts`, payload, { params });
		return response.data;
	}

	/**
	 * Create user
	 */
	async createUser(params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/pinebucket/users`, undefined, { params });
		return response.data;
	}
}
