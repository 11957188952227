import { injectable } from 'inversify';

import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

/**
 * A class to register our storages where we store private things. This that we should remove on logout.
 */
@injectable()
export default class TokenStorageRegistry {
	private storages: BrowserStorage[] = [];

	public create(type: StorageType): BrowserStorage {
		const storage = new BrowserStorage(type);
		this.storages.push(storage);
		return storage;
	}

	/**
	 * Clear all the storages
	 */
	public clearAll(): void {
		this.storages.forEach((storage) => storage.clear());
	}
}
