import Styled from 'components/Header/Components/UserMenu/UserMenu.style';
import Icon from 'components/Icon';
import LinkAsGhostButton from 'components/LinkAsGhostButton/LinkAsGhostButton';

/**
 * Blank state
 * @returns {JSX.Element}
 */
const BlankState = ({ to, onClick }: { to: string; onClick: () => void }): JSX.Element => {
	return (
		<Styled.BlankStateWrapper data-testid='blank-state'>
			<h3>Connect a social account</h3>
			<p>Connect a social account and boost your presence and workflow.</p>
			<LinkAsGhostButton to={to} onClick={onClick}>
				<Styled.ButtonContent>
					<span>Connect social account </span>
					<Icon name='arrow-right' size='12' />
				</Styled.ButtonContent>
			</LinkAsGhostButton>
		</Styled.BlankStateWrapper>
	);
};

export default BlankState;
