import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { InfluencerListFolderModel } from 'api-models';
import { UpdateInfluencerListFolderPayload } from 'api-payloads';
import { InputText } from 'components/Form/FormikElements';
import { UpdateFolderProps } from 'components/InfluencerManagement/Folders/types';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import InfluencerListManager from 'services/ApiManager/InfluencerList.manager';
import toast from 'services/Toast';

const validationSchema = yup.object({
	name: yup.string().defined('Name your folder').min(1),
});
type FolderDraft = yup.InferType<typeof validationSchema>;

/**
 * Update folder component
 * @param UpdateFolderProps
 * @returns {JSX.Element}
 */
const UpdateFolder = ({ onSaved, item, forwardRef }: UpdateFolderProps): JSX.Element => {
	const manager = useInjection<InfluencerListManager>(InfluencerListManager);

	const initialValues: FolderDraft = {
		name: item?.attributes.name ?? '',
	};

	async function handleSubmit(item: InfluencerListFolderModel, payload: UpdateInfluencerListFolderPayload) {
		const placeholder = structuredClone(item);
		placeholder.attributes.name = payload.name ?? '';

		try {
			await manager.updateFolder(item.id, payload, undefined, {
				optimisticModel: placeholder,
			});
			toast.success(`Folder ${payload.name} saved`);
			onSaved();
		} catch (error) {
			toast.error(getSomethingWentWrongMessage());
			console.error('Error updating folder', error);
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values) => {
				return handleSubmit(item!, values);
			}}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{() => (
				<Form>
					<InputText autoFocus onClick={(e: React.MouseEvent) => e.stopPropagation()} forwardRef={forwardRef} name='name' id='form-folder-input-name' />
				</Form>
			)}
		</Formik>
	);
};

export default UpdateFolder;
