export type CurrentUser = {
	id: string;
	name: string;
	email: string;
	connectedWithFacebook: boolean;
};

export interface ISettingsContainer {
	currentUser: CurrentUser;
}

export enum OrganizationHierarchyRole {
	ADMINISTRATOR = 'administrator',
	BRAND_MANAGER = 'brand_manager',
	CAMPAIGN_MANAGER = 'project_manager',
	DATA_ANALYST = 'data_analyst',
}

export enum RoleTranslation {
	ADMINISTRATOR = 'Manager',
	BRAND_MANAGER = 'Viewer',
	CAMPAIGN_MANAGER = 'Editor',
}
