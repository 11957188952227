/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { UpdateInstagramStoryPayload, UploadFilePayload, UploadStoryStatisticsScreenshotPayload } from 'api-payloads';
import { ViewInstagramStoryQuery, UpdateInstagramStoryQuery, UploadStoryStatisticsScreenshotQuery } from 'api-queries';
import { InstagramStoryResponse, InstagramStoryFileResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import InstagramStoryApiClientInterface from './InstagramStoryApiClientInterface';

@injectable()
export default class InstagramStoryApiClient implements InstagramStoryApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * View instagram story
	 */
	async view(id: string, params: ViewInstagramStoryQuery): Promise<InstagramStoryResponse> {
		const response = await this.client.get<InstagramStoryResponse>(`/instagram-stories/${id}`, { params });
		return response.data;
	}

	/**
	 * Update instagram story
	 */
	async update(id: string, payload: UpdateInstagramStoryPayload, params: UpdateInstagramStoryQuery): Promise<InstagramStoryResponse> {
		const response = await this.client.patch<InstagramStoryResponse>(`/instagram-stories/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * Upload file
	 */
	async uploadFile(id: string, payload: UploadFilePayload, params: { include?: string }): Promise<InstagramStoryFileResponse> {
		const payloadForm = new FormData();
		payloadForm.set('file', payload);

		const response = await this.client.post<InstagramStoryFileResponse>(`/instagram-stories/${id}/files`, payloadForm, {
			...{ params },
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return response.data;
	}

	/**
	 * Upload statistics screenshot
	 */
	async uploadStoryStatisticsScreenshot(
		id: string,
		payload: UploadStoryStatisticsScreenshotPayload,
		params: UploadStoryStatisticsScreenshotQuery,
	): Promise<InstagramStoryResponse> {
		const payloadForm = new FormData();
		payloadForm.set('file', payload);

		const response = await this.client.post<InstagramStoryResponse>(`/instagram-stories/${id}/statistics-screenshots`, payloadForm, {
			...{ params },
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return response.data;
	}

	/**
	 * Delete file
	 */
	async deleteFile(instagramStoryId: string, fileId: string): Promise<void> {
		const response = await this.client.delete<void>(`/instagram-stories/${instagramStoryId}/files/${fileId}`);
		return response.data;
	}

	/**
	 * Delete screenshot
	 */
	async deleteScreenshot(instagramStoryId: string, screenshotId: string): Promise<void> {
		const response = await this.client.delete<void>(`/instagram-stories/${instagramStoryId}/screenshots/${screenshotId}`);
		return response.data;
	}
}
