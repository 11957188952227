import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { AssignmentModel } from 'api-models';
import { getAssignmentOptions } from 'components/DataLibrary/AllRequests/DataFilterOptions';
import FilterSectionAccordionItem from 'components/DataLibrary/FilterSection/FilterSectionAccordionItem';
import useInjection from 'hooks/useInjection';
import AssignmentManager from 'services/ApiManager/Assignment.manager';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboardFilterAssignments } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const AssignmentOptions = (props: { clearFilter: boolean; getClearFilter: (clearFilter: boolean) => void }): JSX.Element => {
	const [selectedAssignments, setSelectedAssignments] = useState<Array<string>>([]);
	const [clearThisFilter, setClearThisFilter] = useState<boolean>(false);
	const [thisDashboardId, setThisDashboardId] = useState<string | undefined>(undefined);

	const manager = useInjection<AssignmentManager>(AssignmentManager);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dispatch = useAppDispatch();

	let assignments: AssignmentModel[] = [];
	let isSearching = true;

	if (dashboard.id) {
		const { result, error } = getAssignmentOptions(manager, dashboardFilter);
		if (error) {
			console.error(error.message);
		}
		isSearching = !result;
		if (result) {
			assignments = result;
		}
	}

	const onSelect = (id: string) => {
		props.getClearFilter(false);
		setClearThisFilter(false);
		if (selectedAssignments.includes(id)) {
			const update = selectedAssignments.filter((i) => i !== id);
			setSelectedAssignments(update);
		} else {
			setSelectedAssignments([...selectedAssignments, id]);
		}
	};

	const checkDashboardFilter = () => {
		if (dashboard.metaData.dashboardFilter) {
			const savedAssignments = dashboard.metaData.dashboardFilter.assignments ?? [];
			if (!isEqual(savedAssignments, selectedAssignments)) {
				setSelectedAssignments(savedAssignments);
			}
		} else {
			setSelectedAssignments([]);
		}
	};

	useEffect(() => {
		checkDashboardFilter();
	}, [thisDashboardId, dashboard]);

	useEffect(() => {
		dispatch(setDashboardFilterAssignments(selectedAssignments));
	}, [selectedAssignments]);

	useEffect(() => {
		setThisDashboardId(dashboard.id);
	}, [dashboard.id]);

	useEffect(() => {
		if (clearThisFilter || props.clearFilter) {
			setSelectedAssignments([]);
		}
	}, [props.clearFilter, clearThisFilter]);

	useEffect(() => {
		checkDashboardFilter();
		return () => {
			setSelectedAssignments([]);
			setClearThisFilter(false);
		};
	}, []);

	return (
		<FilterSectionAccordionItem
			setClearThisFilter={setClearThisFilter}
			title={<>Assignment {selectedAssignments?.length > 0 && <>({selectedAssignments?.length})</>} </>}
			label={'Search for assignments'}
			placeholder={'Ex Assignment 1'}
			data={assignments.map((item) => ({
				id: item.id,
				name: item.attributes.name,
			}))}
			selectedData={selectedAssignments}
			onSelect={onSelect}
			isSearching={isSearching}
		/>
	);
};
export default AssignmentOptions;
