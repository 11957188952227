import { configureStore } from '@reduxjs/toolkit';

import dashboardFilter from './reducers/DashboardFilterSlice';
import dashboard from './reducers/DashboardSlice';
import dashboards from './reducers/DashboardsSlice';
import fetchingDashboards from './reducers/FetchingDashboardsSlice';
import filter from './reducers/FilterSlice';
import modal from './reducers/ModalSlice';

export const store = configureStore({
	reducer: {
		dashboard,
		dashboards,
		dashboardFilter,
		fetchingDashboards,
		modal,
		filter,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
