export const FakeData = {
	data: [
		{
			date: '2022-11-12',
			count: 1404130,
		},
		{
			date: '2022-11-13',
			count: 3379281,
		},
		{
			date: '2022-11-14',
			count: 4144444,
		},
		{
			date: '2022-11-15',
			count: 2665178,
		},
		{
			date: '2022-11-16',
			count: 2742118,
		},
		{
			date: '2022-11-17',
			count: 1407142,
		},
		{
			date: '2022-11-18',
			count: 2090022,
		},
		{
			date: '2022-11-19',
			count: 1514488,
		},
		{
			date: '2022-11-20',
			count: 3051678,
		},
		{
			date: '2022-11-21',
			count: 2456152,
		},
		{
			date: '2022-11-22',
			count: 1753437,
		},
		{
			date: '2022-11-24',
			count: 2761040,
		},
		{
			date: '2022-11-25',
			count: 1839759,
		},
		{
			date: '2022-11-27',
			count: 2489501,
		},
		{
			date: '2022-11-28',
			count: 3763062,
		},
		{
			date: '2022-11-29',
			count: 3470467,
		},
		{
			date: '2022-11-30',
			count: 1473704,
		},
		{
			date: '2022-12-01',
			count: 2367304,
		},
		{
			date: '2022-12-02',
			count: 1258448,
		},
		{
			date: '2022-12-03',
			count: 2328256,
		},
		{
			date: '2022-12-04',
			count: 266705,
		},
		{
			date: '2022-12-05',
			count: 2399509,
		},
		{
			date: '2022-12-06',
			count: 1745973,
		},
		{
			date: '2022-12-07',
			count: 2301786,
		},
		{
			date: '2022-12-08',
			count: 2145021,
		},
		{
			date: '2022-12-09',
			count: 1466376,
		},
		{
			date: '2022-12-10',
			count: 3280257,
		},
	],
};
