import EmptyChatImage from 'assets/img/empty-state-message.svg';

import Styled from './EmptyChat.style';

/**
 */
const EmptyChatV2 = () => {
	return (
		<Styled.Wrapper data-testid='inbox-empty-chat-section'>
			<div>
				<div className='image-wrapper'>
					<img src={EmptyChatImage} alt='empty-integrated-inbox' />
				</div>
				<div className='strong'>Select a conversation to get started</div>
			</div>
		</Styled.Wrapper>
	);
};

export default EmptyChatV2;
