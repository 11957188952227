import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';

import { GhostButton } from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';

import Styled from './DownloadCsv.style';

type DownloadCsvProps = {
	endpoint: string;
	fileName?: string;
};

/**
 * Download CSV
 * Component with download button, accepts api endpoint
 * @returns {JSX.Element}
 */
const DownloadCsv = ({ endpoint, fileName }: DownloadCsvProps): JSX.Element => {
	const [isDownloading, setIsDownloading] = useState(false);

	const currentDate = moment(new Date()).format('YYYY-MM-DD');
	const dowloadCsv = async () => {
		const Client = createClient();
		try {
			setIsDownloading(true);
			const res = await Client.get(endpoint, { headers: { Accept: 'text/csv' } });
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement('a');
			link.href = url;
			const autoFileName = `downloaded Report ${currentDate}.csv`;
			link.setAttribute('download', fileName ?? autoFileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setIsDownloading(false);

			return res;
		} catch (err) {
			setIsDownloading(false);
			if (!axios.isAxiosError(err)) {
				return;
			}
			console.error(err);
			if (err.response && err.response.data.errors) {
				toast.error(`Error: ${err.response.data.errors[0].source.message}`);
			}
			toast.error(getSomethingWentWrongMessage());
		}
		setIsDownloading(false);
	};

	return (
		<Styled.ButtonContainer>
			<GhostButton size='sm' onClick={() => dowloadCsv()}>
				{isDownloading ? <LoadingSpinner size='sm' /> : 'Download CSV'}
			</GhostButton>
		</Styled.ButtonContainer>
	);
};

export default DownloadCsv;
