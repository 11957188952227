import { Model } from 'json-api-models';
import { isNil } from 'lodash';

import { getGenderLabel } from 'components/Lists/helpers';
import { formatNumberWithDecimals } from 'shared/helpers/Chart/chart-util';

export const MetricBarData = (list: Model) => {
	const attributes = list.attributes;
	return [
		{
			label: 'Engagement Rate',
			value: !isNil(attributes.engagementRate) ? formatNumberWithDecimals(attributes.engagementRate, '%') : '-',
			tooltipText: 'Average Engagement Rate indicates the typical level of audience interaction with content across profiles.',
		},
		{
			label: 'Avg. Age span',
			value: !isNil(attributes.topAgeRatio) ? (
				<strong>
					{attributes.topAge.replace('To', '-')} <span className='small-val'>({Math.floor(attributes.topAgeRatio * 100)}%)</span>
				</strong>
			) : (
				'-'
			),
			tooltipText: 'Indicates the primary age range of the audience following the listed profiles.',
		},
		{
			label: 'Avg. Location',
			value: !isNil(attributes.country1Ratio) ? (
				<strong>
					{attributes.country1Code} <span className='small-val'>({Math.floor(attributes.country1Ratio * 100)}%)</span>
				</strong>
			) : (
				'-'
			),
			tooltipText: 'Indicates the primary geographical location of the audience following the profiles.',
		},
		{
			label: 'Avg. Gender',
			value: !isNil(attributes.topGenderRatio) ? (
				<strong>
					{getGenderLabel(attributes.topGender)} <span className='small-val'>({Math.floor(attributes.topGenderRatio * 100)} %)</span>
				</strong>
			) : (
				'-'
			),
			tooltipText: 'Indicates the primary gender distribution within the audience following the listed profiles.',
		},
	];
};
