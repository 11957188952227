import classNames from 'classnames';
import { HTMLAttributes, InputHTMLAttributes, Ref } from 'react'; // Use Ref instead of RefObject

import Styled from './Checkbox.style';

// Change forwardRef type to Ref<HTMLInputElement> instead of RefObject
const Checkbox = (
	props: InputHTMLAttributes<HTMLInputElement> &
		HTMLAttributes<HTMLDivElement> & {
			label?: string | JSX.Element;
			isFirstUppercase?: boolean;
			isIndeterminate?: boolean;
			forwardRef?: Ref<HTMLInputElement>;
		},
) => {
	return (
		<Styled.CheckboxContainer data-testid='checkbox' className={props.className} onClick={props.disabled ? () => {} : props.onChange}>
			<Styled.HiddenCheckbox type='checkbox' name={props.name} id={props.id} checked={props.checked} {...props} ref={props.forwardRef} />
			<Styled.Checkbox checked={props.checked} disabled={props.disabled} className={classNames('checkbox', { checked: props.checked })}>
				<Styled.Icon viewBox='0 0 24 24'>{props.isIndeterminate ? <polyline points='4 12 20 12' /> : <polyline points='20 6 9 17 4 12' />}</Styled.Icon>
			</Styled.Checkbox>
			{props.label ? (
				<Styled.Label id={props.id} className={classNames({ isFirstUppercase: props.isFirstUppercase })}>
					{props.label}
				</Styled.Label>
			) : null}
		</Styled.CheckboxContainer>
	);
};

export default Checkbox;
