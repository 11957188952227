/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { CreatePrePaymentForAffiliateTransactionsPayload, UpdatePrePaymentPayload } from 'api-payloads';
import {
	ListPaymentSummariesQuery,
	ListPrePaymentsQuery,
	CreatePrePaymentForAffiliateTransactionsQuery,
	UpdatePrePaymentQuery,
	ListPrePaymentInvoicesQuery,
} from 'api-queries';
import {
	PaymentSummaryCollectionResponse,
	PaymentPrePaymentCollectionResponse,
	PaymentPrePaymentResponse,
	MoneyCollectionResponse,
	PaymentInvoiceCollectionResponse,
	AdminPaymentExtraCollectionResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import PaymentApiClientInterface from './PaymentApiClientInterface';

@injectable()
export default class PaymentApiClient implements PaymentApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List payment summaries
	 */
	async listSummaries(params: ListPaymentSummariesQuery): Promise<PaymentSummaryCollectionResponse> {
		const response = await this.client.get<PaymentSummaryCollectionResponse>(`/payment-summaries`, { params });
		return response.data;
	}

	/**
	 * List pre payments
	 */
	async listPrePayments(params: ListPrePaymentsQuery): Promise<PaymentPrePaymentCollectionResponse> {
		const response = await this.client.get<PaymentPrePaymentCollectionResponse>(`/pre-payments`, { params });
		return response.data;
	}

	/**
	 * Create pre payment for affiliate transactions
	 */
	async createPreForAffiliateTransactions(
		payload: CreatePrePaymentForAffiliateTransactionsPayload,
		params: CreatePrePaymentForAffiliateTransactionsQuery,
	): Promise<PaymentPrePaymentResponse> {
		const response = await this.client.post<PaymentPrePaymentResponse>(`/pre-payments`, payload, { params });
		return response.data;
	}

	/**
	 * Unpaid
	 */
	async unpaid(params: { include?: string }): Promise<MoneyCollectionResponse> {
		const response = await this.client.get<MoneyCollectionResponse>(`/pre-payments/unpaid`, { params });
		return response.data;
	}

	/**
	 * Update pre payment
	 */
	async updatePre(id: string, payload: UpdatePrePaymentPayload, params: UpdatePrePaymentQuery): Promise<PaymentPrePaymentResponse> {
		const response = await this.client.patch<PaymentPrePaymentResponse>(`/pre-payments/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * List pre payment invoices
	 */
	async listPreInvoices(id: string, params: ListPrePaymentInvoicesQuery): Promise<PaymentInvoiceCollectionResponse> {
		const response = await this.client.get<PaymentInvoiceCollectionResponse>(`/pre-payments/${id}/invoices`, { params });
		return response.data;
	}

	/**
	 * List payment extra
	 */
	async listExtra(params: { include?: string }): Promise<AdminPaymentExtraCollectionResponse> {
		const response = await this.client.get<AdminPaymentExtraCollectionResponse>(`/statistics/admin/payments`, { params });
		return response.data;
	}
}
