import React, { RefObject } from 'react';
import { ReactCropperElement } from 'react-cropper';

import Styled from './CropperToolbar.style';

type CropperToolbarProps = React.AllHTMLAttributes<HTMLDivElement> & {
	cropperRef: RefObject<ReactCropperElement>;
	children?: React.ReactNode;
};

const CropperToolbar = (props: CropperToolbarProps) => {
	const { children } = props;

	// Save for later when backend is implemented
	// const flipHorizontal = () => {
	// 	if (cropperRef.current) {
	// 		const currentScaleX = cropperRef.current.cropper.getData().scaleX;
	// 		cropperRef.current.cropper.scaleX(-currentScaleX!);
	// 	}
	// };

	// const flipVertical = () => {
	// 	if (cropperRef.current) {
	// 		const currentScaleY = cropperRef.current.cropper.getData().scaleY;
	// 		cropperRef.current.cropper.scaleY(-currentScaleY!);
	// 	}
	// };

	// const rotateCounterClock = () => {
	// 	if (cropperRef.current) {
	// 		cropperRef.current.cropper.rotate(-90);
	// 	}
	// };

	// const rotateClock = () => {
	// 	if (cropperRef.current) {
	// 		cropperRef.current.cropper.rotate(90);
	// 	}
	// };

	// const zoomOut = () => {
	// 	if (cropperRef.current) {
	// 		cropperRef.current.cropper.zoom(-0.1);
	// 	}
	// };

	// const zoomIn = () => {
	// 	if (cropperRef.current) {
	// 		cropperRef.current.cropper.zoom(0.1);
	// 	}
	// };

	return (
		<Styled.CropperToolbarWrapper>
			<div className='cropper-blurred-background'></div>
			<div className='cropper-toolbar'>
				{/* Need backend support */}
				{/* <GhostButton size='sm' onClick={flipHorizontal}>
					<Icon name='flip-horizontal' size='20' />
				</GhostButton>
				<GhostButton size='sm' onClick={flipVertical}>
					<Icon name='flip-vertical' size='20' />
				</GhostButton>
				<GhostButton size='sm' onClick={rotateCounterClock}>
					<Icon name='rotate-left' size='20' />
				</GhostButton>
				<GhostButton size='sm' onClick={rotateClock}>
					<Icon name='rotate-right' size='20' />
				</GhostButton>
				<GhostButton size='sm' onClick={zoomOut}>
					<Icon name='zoom-out' size='20' />
				</GhostButton>
				<GhostButton size='sm' onClick={zoomIn}>
					<Icon name='zoom-in' size='20' />
				</GhostButton> */}
				{/* <div className='divider'></div> */}
				{children}
			</div>
		</Styled.CropperToolbarWrapper>
	);
};

export default CropperToolbar;
