import { GHOST_USER_UUID } from 'constants/localStorage-keys';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

import CollabsAuthService from './Authentication/Collabs-api';

export function getTokenPayload() {
	return null;
}

/**
 * This check if ghost user is admin
 */
export function checkGhostUser() {
	const storage = new BrowserStorage(StorageType.LOCAL);
	const ghostUserUuid = storage.getItem(GHOST_USER_UUID) || '';

	if (ghostUserUuid) return true;

	return false;
}

export function isLoggedIn() {
	return false;
}

export function isInfluencer() {
	const tokenPayload = getTokenPayload();

	if (!tokenPayload) {
		return false;
	}

	const userObject = CollabsAuthService.getCollabsUserObject();

	return !!(userObject.permissions.isInfluencer || isAdminOrGhostAdmin());
}

export function isPublisher() {
	const tokenPayload = getTokenPayload();
	if (!tokenPayload) {
		return false;
	}
	return tokenPayload.user.publishers && tokenPayload.user.publishers.length > 0;
}

export function isAdminOrGhostAdmin() {
	const tokenPayload = getTokenPayload();
	if (!tokenPayload) {
		return false;
	}
	return isAdmin() || checkGhostUser();
}

/**
 * @deprecated use isSuperAdmin
 */
export function isAdmin() {
	if (!isLoggedIn()) return false;

	const tokenPayload = getTokenPayload();
	if (!tokenPayload) {
		return false;
	}

	return tokenPayload.user.isAdmin === true;
}

export function isImpersonating() {
	return checkGhostUser();

	// TODO let the loggedInUser help with the logic
	// const loggedInUser = CollabsAuthService.getCollabsUserObject();
	// return true === loggedInUser.permissions.isImpersonating;
}

export function isSaler() {
	if (!isLoggedIn()) return false;

	const tokenPayload = getTokenPayload();
	if (!tokenPayload) {
		return false;
	}

	return tokenPayload.user.isSaleUser === true;
}

export function isTester() {
	if (!isLoggedIn()) return false;

	const tokenPayload = getTokenPayload();
	if (!tokenPayload) {
		return false;
	}

	return tokenPayload.user.isTestUser === true;
}

export function ghostUserIsSaler() {
	if (!isLoggedIn()) return false;

	if (!hasGhostUser()) return false;

	const tokenPayload = getTokenPayload();
	if (!tokenPayload) {
		return false;
	}

	return tokenPayload.user.isSaleUser === true;
}

export function hasGhostUser() {
	if (!isLoggedIn()) return false;

	const tokenPayload = getTokenPayload();
	if (!tokenPayload) {
		return false;
	}

	if (tokenPayload.ghostUser) {
		return true;
	}

	return false;
}

export function redirectToHome({ history }) {
	history('/login');
}
