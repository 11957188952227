import { inject, injectable } from 'inversify';

import { ClientModel } from 'api-models';
import { CreateClientInvitePayload, CreateClientPayload, UpdateBrandPrivilegePayload, UpdateClientLogoPayload, UpdateClientPayload } from 'api-payloads';
import {
	CreateClientQuery,
	ListClientPrivilegesQuery,
	ListClientsQuery,
	UpdateClientLogoQuery,
	UpdateClientPrivilegeQuery,
	UpdateClientQuery,
} from 'api-queries';
import { ClientCollectionResponse, ClientPrivilegeResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, DeleteConfig, UpdateConfig } from './types';

import type ClientApiClientInterface from 'services/ApiClient/ClientApiClientInterface';

type Repository = ModelRepository<ClientModel>;

@injectable()
class ClientManager extends ApiManager<Repository> {
	private readonly client: ClientApiClientInterface;

	constructor(@inject(ApiCacheManager) cacheManager: ApiCacheManager, @inject(ApiClientService.ClientApiClientInterface) client: ClientApiClientInterface) {
		super(cacheManager, new ModelRepository<ClientModel>('client'));
		this.client = client;
	}

	public listClients(queryBuilder = RequestQueryBuilder.create<ListClientsQuery>()) {
		const key = `listClients::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listClients(queryBuilder.toQuery());

		return this.swr<ClientCollectionResponse>(key, fetcher);
	}

	public create(
		publisherId: string,
		payload: CreateClientPayload,
		queryBuilder = RequestQueryBuilder.create<CreateClientQuery>(),
		config: CreateConfig<ClientModel> = {},
	): Promise<ClientModel> {
		return this.createModel<ClientModel>(this.client.create(publisherId, payload, queryBuilder.toQuery()), config);
	}

	public update(
		id: string,
		payload: UpdateClientPayload,
		queryBuilder = RequestQueryBuilder.create<UpdateClientQuery>(),
		config: UpdateConfig<ClientModel> = {},
	): Promise<ClientModel> {
		return this.updateModel<ClientModel>(this.client.update(id, payload, queryBuilder.toQuery()), config);
	}

	public delete(id: string, config: DeleteConfig = {}): Promise<Repository> {
		return this.deleteModel(this.client.delete(id), 'client', id, config);
	}

	public deleteInvite(clientId: string, inviteId: string): Promise<void> {
		return this.client.deleteInvite(inviteId).then(() => {
			this.refetch('client', clientId);
		});
	}

	public listUsersAndInvitations(id: string) {
		const key = `listUsersAndInvitations::${id}`;
		const fetcher = () => {
			return Promise.all([
				this.client.listInvites(id, { include: ':hateoas(delete)' }),
				this.client.listPrivileges(id, RequestQueryBuilder.create<ListClientPrivilegesQuery>().withInclude('user').toQuery()),
			]);
		};
		return this.swr(key, fetcher, { multipleApiResponses: true });
	}

	public inviteUsers(clientId: string, payload: CreateClientInvitePayload): Promise<void> {
		return this.client.createInvite(clientId, payload, { include: ':hateoas(false)' }).then(() => {
			this.refetch('client', clientId);
		});
	}

	public updateRole(
		clientId: string,
		userId: string,
		payload: UpdateBrandPrivilegePayload,
		queryBuilder = RequestQueryBuilder.create<UpdateClientPrivilegeQuery>(),
	): Promise<ClientPrivilegeResponse> {
		return this.client.updatePrivilege(clientId, userId, payload, queryBuilder.toQuery());
	}

	public deleteUser(clientId: string, userId: string): Promise<void> {
		return this.client.deletePrivilege(clientId, userId);
	}

	public uploadLogo(clientId: string, payload: UpdateClientLogoPayload): Promise<string | undefined> {
		const qb = RequestQueryBuilder.create<UpdateClientLogoQuery>(['logo']);
		return this.client.updateLogo(clientId, payload, qb.toQuery()).then((response) => {
			this.refetch('client', clientId);

			return response.data.links?.logo;
		});
	}

	public deleteLogo(clientId: string): Promise<void> {
		return this.client.deleteLogo(clientId);
	}
}

export default ClientManager;
