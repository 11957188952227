import classNames from 'classnames';
import { uniqueId } from 'lodash';
import React from 'react';
import { PositionStrategy, Tooltip as TooltipComponent } from 'react-tooltip';

import Styled from './Tooltip.style';
import { TooltipPosition } from './types';

export type TooltipProps = {
	id?: string;
	position?: TooltipPosition;
	content: React.ReactNode;
	children: React.ReactNode;
	clickToToggle?: boolean;
	delayShow?: number;
	positionStrategy?: PositionStrategy;
	isOpen?: boolean;
	className?: string;
};

/**
 * Tooltip component to display additional information on hover or click.
 * @param {TooltipProps} props - Props for the Tooltip component.
 * @returns {JSX.Element} - Rendered JSX element for the Tooltip.
 */
const Tooltip = ({ id, isOpen, position = 'bottom', content, children, clickToToggle, delayShow, positionStrategy, className }: TooltipProps): JSX.Element => {
	const formattedId = id ? id.toLowerCase().replace(/ /g, '_').replace(/#/g, '') : uniqueId('cb-tt_');
	return (
		<Styled.TooltipContainer
			id={formattedId}
			data-testid='tooltip'
			className={classNames(className, {
				'cursor-pointer': clickToToggle,
			})}
		>
			{children}
			<TooltipComponent
				delayShow={delayShow ?? 0}
				anchorSelect={`#${formattedId}`}
				place={position}
				className='cb-tooltip'
				openOnClick={clickToToggle}
				positionStrategy={positionStrategy ?? 'absolute'}
				isOpen={isOpen}
			>
				{content}
			</TooltipComponent>
		</Styled.TooltipContainer>
	);
};

export default Tooltip;
