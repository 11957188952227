import { useState, useEffect } from 'react';

const useNumberGrowEffect = (value: number) => {
	const [tempValue, setTempValue] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTempValue((prev) => Math.min(1, prev + 0.1));
		}, 50);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 1000);
	}, []);

	return Math.round(tempValue * value);
};

export default useNumberGrowEffect;
