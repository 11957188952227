import styled from 'styled-components';

import colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

const MenuItemLink = styled.a`
	display: flex;
	align-items: center;
	flex-grow: 1;
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1rem;
	font-weight: 500;
	color: ${colors.MainNavigation.color};
	width: 100%;
	line-height: 1;

	&.external {
		border-bottom: none;
	}

	&:hover {
		color: ${colors.MainNavigation.colorHover};
	}

	.icon {
		margin-right: 14px;
		line-height: 0;
	}

	&.active-page {
		font-weight: 900;
	}
`;

const Styled = {
	MenuItemLink,
};

export default Styled;
