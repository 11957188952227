import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip/V2';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import { formatNumber, formatNumberWithDecimals } from 'shared/helpers/Chart/chart-util';

import Styled from './ProfileCard.style';

const ProfileCard = (props: { data: InfluencerListItemType }) => {
	// Define the data items and their labels
	const cardItems = [
		// {
		// Hide this until we figure out how this is caluclated
		// 	label: 'Engagement',
		// 	value: props.data.engagement ? formatNumberWithDecimals(props.data.engagement, '%') : '-',
		// 	icon: 'cpe',
		// },
		{
			label: 'Average view',
			value: props.data.averageViews ? formatNumber(props.data.averageViews) : '-',
			icon: 'content',
			tooltip: 'The average number of views the posts receive based on recent performance.',
		},
		{
			label: 'Paid post ratio',
			value: props.data.paidPostsRatio ? formatNumberWithDecimals(props.data.paidPostsRatio, '%') : '-',
			icon: 'sales-budget',
			tooltip: 'The percentage of the posts that are sponsored or paid collaborations.',
		},
		{
			label: 'Organic Engagement',
			value: props.data.engagementOrganic ? formatNumberWithDecimals(props.data.engagementOrganic, '%') : '-',
			icon: 'activations',
			tooltip: 'The percentage of likes, comments, and interactions the posts receive from non-sponsored content.',
		},
		{
			label: 'Paid Engagement',
			value: props.data.engagementPaid ? formatNumberWithDecimals(props.data.engagementPaid, '%') : '-',
			icon: 'money',
			tooltip: 'The percentage of likes, comments, and interactions on sponsored or paid content.',
		},
		{
			label: 'Estimated story views',
			value: props.data.estimatedStoryViews ? formatNumber(props.data.estimatedStoryViews) : '-',
			icon: 'impressions',
			tooltip: 'The projected number of views for Instagram stories based on past performance',
		},
	];

	return (
		<Styled.Wrapper>
			<Styled.InnerWrapper>
				{cardItems.map((item, index) => (
					<Styled.Item key={index}>
						<Styled.IconContainer>
							<Icon name={item.icon} size='18' />
						</Styled.IconContainer>
						<Tooltip content={item.tooltip} delayShow={300}>
							<div>
								<Styled.LabelText>{item.label}</Styled.LabelText>
								<Styled.DataText>{item.value}</Styled.DataText>
							</div>
						</Tooltip>
					</Styled.Item>
				))}
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default ProfileCard;
