import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgCheckCircle(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.4803 6.5C15.7732 6.79289 15.7732 7.26777 15.4803 7.56066L9.48028 13.5607C9.18739 13.8536 8.71251 13.8536 8.41962 13.5607L4.91962 10.0607C4.62673 9.76777 4.62673 9.29289 4.91962 9C5.21251 8.70711 5.68739 8.70711 5.98028 9L8.94995 11.9697L14.4196 6.5C14.7125 6.20711 15.1874 6.20711 15.4803 6.5Z'
				fill='#333333'
			/>
		</svg>
	);
}
