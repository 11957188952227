import { JsonApiDocument } from 'json-api-models';

import { createClient } from 'shared/ApiClient/ApiClient';

export const useTikTokConnector = () => {
	const client = createClient();

	const disconnect = async (url: string) => {
		const response = await client.delete<JsonApiDocument>(url);
		return response.data;
	};

	const updateApiToken = async (url: string, code: string) => {
		const response = await client.post<JsonApiDocument>(url, {
			code: code,
		});
		return response.data;
	};

	return {
		disconnect,
		updateApiToken,
	};
};
