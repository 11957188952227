/**
 * Default global box shadows
 * This file keeps the global box shadows.
 *
 */
const boxShadow = {
	small: '0 2px 4px rgba(51, 51, 51, 0.1)',
	medium: '0 10px 20px rgba(92, 104, 128, 0.16)',
	dnd: '0 3px 4px rgba(116, 116, 116, 0.3)', // drag and drop shadow
};

export default boxShadow;
