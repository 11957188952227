import Styled from './LoadingSpinner.style';

export interface LoadingSpinnerProps {
	size?: 'sm' | 'md' | 'lg';
	position?: 'default' | 'center';
	inverted?: boolean;
}

const LoadingSpinner = ({ size, position, inverted = false }: LoadingSpinnerProps) => {
	return <Styled.Spinner className='cb-spinner' size={size || 'md'} position={position || 'default'} data-testid='loading-spinner' inverted={inverted} />;
};

export default LoadingSpinner;
