Object.defineProperty(Number.prototype, 'between', {
	value: function (a, b) {
		const min = Math.min.apply(Math, [a, b]);
		const max = Math.max.apply(Math, [a, b]);
		return this >= min && this < max;
	},
});

Object.defineProperty(Number.prototype, 'toShort', {
	value: function () {
		if (this >= 1000000) {
			return `${parseInt(this / 1000000, 10)}m`;
		} else if (this >= 1000) {
			return `${parseInt(this / 1000, 10)}k`;
		} else {
			return `${parseInt(this, 10)}`;
		}
	},
});

Object.defineProperty(Array.prototype, 'toSort', {
	value: function (key, mode = 'asc') {
		return this.sort((a, b) => {
			if (a[key] > b[key]) {
				return mode === 'asc' ? 1 : -1;
			}

			if (a[key] < b[key]) {
				return mode === 'asc' ? -1 : 1;
			}

			return 0;
		});
	},
});
