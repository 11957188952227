import DiscoveryDropdown from 'components/Discovery/Components/DiscoveryDropdown';
import Styled from 'components/Discovery/DiscoveryContainer.style';
import Icon from 'components/Icon';

const SelectedInfluencerOptions = (props: {
	selectedInfluencers: string[];
	setSelectedInfluencers: (selectedInfluencers: string[]) => void;
	showSelectedInfluencer: boolean;
	setShowSelectedInfluencers: (show: boolean) => void;
}) => {
	return (
		<Styled.ButtonsWrapper>
			{props.selectedInfluencers.length > 0 && (
				<Styled.FlexDiv>
					<DiscoveryDropdown selectedItems={props.selectedInfluencers} onResetSelection={() => props.setSelectedInfluencers([])} size='16' />
				</Styled.FlexDiv>
			)}
			{props.selectedInfluencers.length > 0 && (
				<>
					<Styled.Divider />
					<Styled.SelectedInfluencerContainer onClick={() => props.setShowSelectedInfluencers(!props.showSelectedInfluencer)}>
						<Styled.SelectedInfluencer>
							{props.showSelectedInfluencer ? `Continue search` : `Show ${props.selectedInfluencers.length} selected`}
						</Styled.SelectedInfluencer>
						<Icon name='chevron-down' size='16' />
					</Styled.SelectedInfluencerContainer>
				</>
			)}
		</Styled.ButtonsWrapper>
	);
};

export default SelectedInfluencerOptions;
