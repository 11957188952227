import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgChevronRightDouble(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.37673 3.12308C3.75714 2.89126 4.26321 2.9965 4.50707 3.35813L8.87063 9.82916C9.04904 10.0937 9.04242 10.4344 8.85385 10.6925L4.49029 16.6658C4.23255 17.0186 3.72273 17.106 3.35159 16.861C2.98044 16.616 2.88851 16.1313 3.14625 15.7785L7.19835 10.2316L3.12947 4.1976C2.88562 3.83597 2.99632 3.35489 3.37673 3.12308Z'
				fill='#333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.3767 3.12308C11.7571 2.89126 12.2632 2.9965 12.5071 3.35813L16.8706 9.82916C17.049 10.0937 17.0424 10.4344 16.8538 10.6925L12.4903 16.6658C12.2325 17.0186 11.7227 17.106 11.3516 16.861C10.9804 16.616 10.8885 16.1313 11.1463 15.7785L15.1983 10.2316L11.1295 4.1976C10.8856 3.83597 10.9963 3.35489 11.3767 3.12308Z'
				fill='#333'
			/>
		</svg>
	);
}
