import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

type BaseQuery = {
	/**
	 * @example [
	 *   "b86d301f-0fbf-41d9-9c29-923293281f71",
	 *   "e0c7d418-d1e9-4b4c-80e2-2718c4db56ca"
	 * ]
	 */
	clients?: string[];
	/**
	 * @example [
	 *   "6ed0eecc-58af-4467-b227-967fda576ad0",
	 *   "19c1b717-6909-49bd-a194-53a1d96de786"
	 * ]
	 */
	brands?: string[];
	/**
	 * @example [
	 *   "183",
	 *   "256",
	 *   "610"
	 * ]
	 */
	campaigns?: number[];
	/**
	 * @example [
	 *   "ab148503-cedb-4b72-988b-10f7e61127d6",
	 *   "97efcd61-8671-4f87-9577-9f3c2c8a2723"
	 * ]
	 */
	assignments?: string[];
	/**
	 * @example [
	 *   "e5d83f0c-52b5-4fab-b425-a137b9e0a19b",
	 *   "a4362e03-e6e3-44ef-ab79-349a0fcf1471"
	 * ]
	 */
	influencers?: string[];
	/** @deprecated */
	platforms?: ('instagram-post' | 'instagram-story')[];
	/**
	 * @example [
	 *   "instagram_post",
	 *   "instagram_reel"
	 * ]
	 */
	assignmentTypes?: ('instagram_post' | 'instagram_story' | 'instagram_reel' | 'tiktok_video' | 'affiliate' | 'youtube' | 'blog' | 'podcast' | 'other')[];
	/**
	 * @example [
	 *   "SWE",
	 *   "FIN"
	 * ]
	 */
	countries?: string[];
	/**
	 * @example [
	 *   "2023-04-01"
	 * ]
	 */
	from: string;
	/**
	 * @example [
	 *   "2023-04-30"
	 * ]
	 */
	to: string;

	paidMediaDashboardId?: string;
};

/**
 * We use slice() here to avoid modifying the original array.
 */
export const convertFilterToQueryBuilder = <D extends BaseQuery>(
	filter: DashboardFilters & { paidMediaDashboardId?: string },
): RequestQueryBuilder<D, never> => {
	let qb = RequestQueryBuilder.create<D>();

	if (filter.clients && filter.clients.length > 0) {
		qb = qb.withFilter('clients', filter.clients.slice().sort().join(','));
	}
	if (filter.brands && filter.brands.length > 0) {
		qb = qb.withFilter('brands', filter.brands.slice().sort().join(','));
	}
	if (filter.campaigns && filter.campaigns.length > 0) {
		qb = qb.withFilter('campaigns', filter.campaigns.slice().sort().join(','));
	}
	if (filter.influencers && filter.influencers.length > 0) {
		qb = qb.withFilter('influencers', filter.influencers.slice().sort().join(','));
	}
	if (filter.countries && filter.countries.length > 0) {
		qb = qb.withFilter('countries', filter.countries.slice().sort().join(','));
	}
	if (filter.assignments && filter.assignments.length > 0) {
		qb = qb.withFilter('assignments', filter.assignments.slice().sort().join(','));
	}
	if (filter.assignmentTypes && filter.assignmentTypes.length > 0) {
		qb = qb.withFilter('assignmentTypes', filter.assignmentTypes.slice().sort().join(','));
	}
	if (filter.from) {
		qb = qb.withFilter('from', filter.from);
	}
	if (filter.to) {
		qb = qb.withFilter('to', filter.to);
	}
	if (filter.paidMediaDashboardId) {
		qb = qb.withFilter('paidMediaDashboardId', filter.paidMediaDashboardId);
	}

	return qb;
};
