import { useNavigate } from 'react-router';

import Icon from 'components/Icon';
import { pathAssignmentAdd } from 'routing/PathLookup';

import Styled from './EmptyStateAssignments.style';

/**
 * Left side page navigation.
 * @returns {JSX.Element}
 */
const EmptyStateAssignments = (props: { campaignId: string }): JSX.Element => {
	const navigate = useNavigate();
	return (
		<Styled.Wrapper>
			<Styled.Title>Assignments</Styled.Title>
			<Styled.Button onClick={() => navigate(pathAssignmentAdd(props.campaignId))}>
				Add assignment <Icon name='arrow-right' size='16' />{' '}
			</Styled.Button>
		</Styled.Wrapper>
	);
};

export default EmptyStateAssignments;
