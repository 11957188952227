import { css } from 'styled-components';

import colors from 'styles/theme/colors';

export const CheckPicker = css`
	.rs-picker-check-menu {
		background-color: ${({ theme }) => theme.colors.background};
		border-radius: ${({ theme }) => theme.radius.small};

		.rs-check-item.rs-check-item-focus,
		.rs-check-item:focus,
		.rs-check-item:not(.rs-checkbox-disabled):hover {
			background-color: ${({ theme }) => theme.colors.inputs};
		}

		.rs-check-item.rs-check-item-focus,
		.rs-check-item:focus,
		.rs-check-item:not(.rs-checkbox-disabled):hover {
			color: ${({ theme }) => theme.colors.text};
		}

		.rs-checkbox-checked .rs-checkbox-control .rs-checkbox-inner:before,
		.rs-checkbox-indeterminate .rs-checkbox-control .rs-checkbox-inner:before {
			background-color: ${colors.smoke};
			border-color: ${colors.slate};
		}

		label:hover .rs-checkbox-control .rs-checkbox-inner:before {
			border-color: ${colors.slate};
		}

		.rs-input-group.rs-input-group-inside .rs-input-group-addon {
			padding-right: 0;

			svg {
				width: 16px;
				height: 16px;
			}
		}
	}
`;
