import { Model } from 'json-api-models';
import moment from 'moment';

import ContentCard from 'components/ContentCard';
import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import useContentManagementData from 'components/ContentManagement/hooks';
import toast from 'services/Toast';
import { InstagramStory } from 'shared/Types/InstagramStories';

type ScreenshotsProps = {
	files?: File[];
	refresh?: () => Promise<void>;
	setFiles?: (files: File[]) => void;
	fetchedScreenShots: InstagramStory[] | Model[];
	assignmentApproved?: boolean;
};

/**
 * List screenshots
 * Component to list single and multiple screenshots for the stats.
 *
 * @param {ScreenshotsProps} props
 * @returns {JSX.Element}
 */
const ListScreenshots = ({ files, refresh, setFiles, fetchedScreenShots, assignmentApproved }: ScreenshotsProps): JSX.Element => {
	const { deleteMedia } = useContentManagementData();

	const updateSelectedScreenshots = (file: File) => {
		const deselectFile = files?.filter((f) => f.name === file.name);
		deselectFile && deselectFile?.length > 0
			? setFiles && setFiles(files?.filter((f) => f.name !== file.name) ?? [])
			: setFiles && files && setFiles([...files, file]);
	};

	const deleteFile = async (selectedFile: Model) => {
		await deleteMedia(selectedFile.links.delete)
			.then(() => {
				refresh && refresh();
				toast.success('File deleted');
			})
			.catch((e) => {
				console.error(e);
			});
	};

	return (
		<Styled.ListScreenShots>
			{// eslint-disable-next-line @typescript-eslint/no-explicit-any
			fetchedScreenShots?.map((screenshot: any, i) => {
				return (
					<Styled.StoryWrapper key={i}>
						<ContentCard imageUrl={screenshot.links.file} altText='Screenshot' active={assignmentApproved ? false : true} type='tall' />
						<Styled.RemoveFile>
							<p className='name'>Uploaded: {moment(screenshot.attributes.createdAt).format('YYYY-MM-DD')}</p>
							{screenshot.links.delete && (
								<p
									className='remove'
									onClick={() => {
										deleteFile(screenshot);
									}}
								>
									Delete
								</p>
							)}
						</Styled.RemoveFile>
					</Styled.StoryWrapper>
				);
			})}

			{files?.map((file, i) => {
				return (
					<Styled.StoryWrapper key={i} data-id={i}>
						<ContentCard imageUrl={typeof file === 'string' ? file : URL.createObjectURL(file)} altText='Screenshot' active={true} type='tall' />
						<Styled.RemoveFile>
							<p className='name'>{file.name}</p>
							<p
								className='remove'
								onClick={() => {
									updateSelectedScreenshots(file);
								}}
							>
								Delete
							</p>
						</Styled.RemoveFile>
					</Styled.StoryWrapper>
				);
			})}
		</Styled.ListScreenShots>
	);
};

export default ListScreenshots;
