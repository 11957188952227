import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgCampaign(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M19.6851 4.13955C19.4951 4.00955 19.2451 3.95955 19.0051 4.00955L1.48512 7.88955C1.45512 7.49955 1.14512 7.19955 0.745117 7.19955C0.335117 7.19955 -0.00488281 7.53955 -0.00488281 7.94955V11.5496C-0.00488281 11.9596 0.335117 12.2996 0.745117 12.2996C1.15512 12.2996 1.49512 11.9596 1.49512 11.5496V11.5196L5.28512 12.4796L4.89512 13.9296C4.84512 14.1096 4.86512 14.2996 4.96512 14.4696C5.05512 14.6296 5.21512 14.7496 5.39512 14.7996L9.68512 15.9696C9.76512 15.9896 9.84512 15.9996 9.92512 15.9896C10.2151 15.9696 10.4651 15.7696 10.5451 15.4796L10.9651 13.9296L18.9851 15.9696C19.0551 15.9896 19.1351 15.9996 19.2051 15.9996C19.3751 15.9996 19.5451 15.9496 19.6851 15.8596C19.8851 15.7296 20.0051 15.5196 20.0051 15.2996V4.68955C20.0051 4.46955 19.8951 4.26955 19.6951 4.13955H19.6851ZM9.64512 14.9196L5.93512 13.9096L6.25512 12.7296L9.98512 13.6796L9.64512 14.9196ZM18.4051 14.3596L1.49512 10.0596V9.33955L18.4051 5.58955V14.3596Z'
				fill='#333333'
			/>
		</svg>
	);
}
