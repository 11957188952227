import { JsonApiDocument, Store } from 'json-api-models';
import { isNil, isEmpty } from 'lodash';
import useSWR from 'swr';

import { createClient } from 'shared/ApiClient/ApiClient';

export type AMCamapignParam = {
	campaignIds?: string[];
	influencerIds?: string[];
	from?: string;
	to?: string;
};

export default function useAdminInvoicesData() {
	const client = createClient();

	const fetcher = async (url: string) => {
		const { data } = await client.get<JsonApiDocument>(url, {});
		return data;
	};

	const getInvoices = ({ campaignIds, influencerIds, from, to }: AMCamapignParam) => {
		const models = new Store();

		const params = new URLSearchParams();
		if (!isNil(campaignIds) && !isEmpty(campaignIds)) {
			params.append('campaigns', campaignIds.join(','));
		}
		if (!isNil(influencerIds) && !isEmpty(influencerIds)) {
			params.append('influencers', influencerIds.join(','));
		}
		if (!isNil(from)) {
			params.append('from', encodeURIComponent(from));
		}
		if (!isNil(to)) {
			params.append('to', encodeURIComponent(to));
		}

		const url = `/invoices?includes=campaign,file`;

		const { data, error, mutate, isValidating } = useSWR(url, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			isLoading: !data && !error,
			error: error,
			refresh: () => mutate(),
			isValidating,
		};
	};

	return {
		getInvoices,
	};
}
