import { css } from 'styled-components';

import colors from 'styles/variables/colors';

export const RegularLink = css`
	position: relative;
	margin-left: auto;
	padding: 0;
	color: ${colors.link.color};

	&:after {
		position: absolute;
		left: 0;
		bottom: -3px;
		width: 100%;
		height: 2px;
		content: '';
		background-color: ${colors.link.borderColor};
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}

	&:hover:not(:disabled) {
		border-color: transparent;

		&:after {
			opacity: 1;
		}
	}
`;
