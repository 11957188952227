import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgFile(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.6599 0.0400391L18 5.36279V5.67285C18 5.68311 17.9973 5.69092 17.9949 5.69873C17.9924 5.70581 17.99 5.71313 17.99 5.7229C17.9937 5.73047 17.9961 5.73926 17.9976 5.74756C18 5.76123 18 5.77295 18 5.77295V19.0496C18 19.5698 17.55 20 17 20H8.08008L2.94995 19.97C2.42993 19.97 2 19.5498 2 19.0396V0.93042C2 0.420166 2.42993 0 2.94995 0L12.3501 0.0400391H12.6599ZM15.54 5.02246L13 2.49121V5.02246H15.54ZM16.5 18.5193V10.4854V10.4753V6.51318H12.3501C11.8799 6.51318 11.5 6.13306 11.5 5.66284V1.53076L3.5 1.49072V18.4893L8.36011 18.5193H16.5ZM14.6035 10.1111H5.49976C5.21997 10.1111 5 9.8667 5 9.55566C5 9.35693 5.0896 9.18555 5.22729 9.0874C5.30542 9.03198 5.39868 9 5.49976 9H14.6035C14.8835 9 15.1033 9.24438 15.1033 9.55566C15.1033 9.8667 14.8835 10.1111 14.6035 10.1111ZM9.49023 12.6445H5.49976C5.21997 12.6445 5 12.8889 5 13.2002C5 13.5112 5.21997 13.7556 5.49976 13.7556H9.50024C9.78003 13.7556 10 13.5112 10 13.2002C10 12.8889 9.78003 12.6445 9.50024 12.6445H9.49023ZM11.4998 12.6445H13.5488C13.8286 12.6445 14.0486 12.8889 14.0486 13.2002C14.0486 13.5112 13.8286 13.7556 13.5488 13.7556H11.4998C11.22 13.7556 11 13.5112 11 13.2002C11 12.998 11.0928 12.8242 11.2344 12.7271C11.3108 12.6746 11.4016 12.6445 11.4998 12.6445Z'
				fill='#333333'
			/>
		</svg>
	);
}
