import React from 'react';

import Icon from 'components/Icon';

import Styled from './MenuLink.style';
import { IMenuLinkProps } from './types';

const MenuLink = (props: IMenuLinkProps) => {
	const { title, url, iconName, tabIndex, onClick, isTopParent } = props;

	const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		if (isTopParent) e.preventDefault();

		onClick && onClick(e);
	};

	// If external URL
	if (url.substring(0, 8) === 'https://') {
		return (
			<Styled.MenuItemLink href={url} target='_blank' tabIndex={tabIndex} className={'external'}>
				{iconName && <Icon name={iconName} />}
				<span>{title}</span>
			</Styled.MenuItemLink>
		);
	}

	return (
		<Styled.MenuItemLink href={url} tabIndex={tabIndex} onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleClick(e)}>
			{iconName && <Icon name={iconName} />}
			<span>{title}</span>
		</Styled.MenuItemLink>
	);
};

export default MenuLink;
