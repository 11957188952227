import { NavigateFunction } from 'react-router-dom';

import { DarkButton } from 'components/Button';
import Icon from 'components/Icon';
import { CREATE_CAMPAIGN } from 'constants/hateoas-keys';
import usePermissions from 'hooks/usePermissions';

import Styled from './ActionBar.style';

type Props = {
	navigate: NavigateFunction;
};

/**
 * ActionBar
 * @param props
 * @returns {JSX.Element}
 */
const ActionBar = (props: Props): JSX.Element => {
	const { navigate } = props;

	const { userCan } = usePermissions();

	return (
		<Styled.Wrapper>
			{userCan(CREATE_CAMPAIGN) && (
				<>
					<DarkButton
						data-testid='ab-new-campaign'
						onClick={() => {
							navigate('/campaigns/create');
						}}
					>
						New campaign <Icon name='expand' />
					</DarkButton>
				</>
			)}
		</Styled.Wrapper>
	);
};

export default ActionBar;
