import styled from 'styled-components';

import Modal from 'components/Modals/Modal';
import { ModalHeader, ModalContent } from 'components/Modals/Modal/Modal.style';
import { InputField } from 'styles/formElements/input';
import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import _colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

const dl = _colors.dataLibrary;
const sm = _colors.shareModal;

const CustomModal = styled(Modal)`
	${ModalContent} {
		box-shadow: 0px 10px 20px 0px rgba(92, 104, 128, 0.16);
		border: 1px solid ${dl.modal.grayBorder};
		border-radius: 10px;
		overflow: hidden;
		min-height: 380px;
	}
`;

const CloseContainer = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border-radius: ${borderRadius.s};

	.icon {
		line-height: 0;
	}
	&:hover {
		background: ${dl.button.cancelBackground};
	}
`;

const CustomModalHeader = styled(ModalHeader)`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 2rem 2rem 1rem 2rem;
	margin-bottom: 0;
`;

const CustomInput = styled.div`
	position: relative;

	div {
		position: absolute;
		top: 60px;
		left: 0;
	}
`;

const CustomModalBody = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 312px;
	padding: 0 2rem 2rem 2rem;
`;

const CustomModalFooter = styled.div`
	display: flex;
	width: calc(100% - 4rem);
	justify-content: space-between;
	height: 64px;
	padding: 1rem 0;
	border-top: 1px solid ${dl.modal.grayBorder};
	margin: 0 2rem 1rem 2rem;

	button {
		font-size: 1rem;
		line-height: 1;
		width: 100px;
		height: 46px;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		box-sizing: border-box;
		background-color: ${colors.smoke};
		color: ${colors.snow};
		transition:
			background-color 0.2s ease-in-out,
			color 0.2s ease-in-out;

		&.save {
			background-color: ${colors.smoke};
			color: ${colors.black};

			&.saveable {
				background-color: ${dl.blackHover};
				color: ${dl.white};
			}
			&:hover {
				background-color: ${dl.blackHover};
				color: ${dl.white};
			}
			&:disabled {
				background-color: ${sm.button.disabledBackground};
				color: ${sm.button.disabledText};
			}
		}
		&.cancel {
			background-color: transparent;
			color: ${colors.black};
		}
		&:hover {
			background-color: ${dl.blackHover};
			color: ${dl.white};
		}
	}
`;

const ModalHeaderTitle = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0em;
	text-align: left;
`;

const SpinnerWrapper = styled.div`
	justify-content: center;
	width: 100%;
	display: flex;
	padding-top: 2rem;
`;

const ModalButtonsContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-top: 30px;
`;

const ModalButton = styled.button`
	font-size: 1rem;
	line-height: 1;
	width: 185px;
	height: 36px;
	border: none;
	cursor: pointer;
	&.cancel {
		background: none;
		border: 1px solid ${dl.black};
	}
	&.delete {
		background: ${dl.modal.dangerRed};
	}
	&:focus {
		box-shadow: 0 0 4px 0 ${_colors.discovery.inputFocus};
	}
`;

const ModalDeleteContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
`;

const ModalDeleteIconContainer = styled.div`
	height: 40px;
	width: 40px;
	background: ${dl.modal.dangerRed};
	border-radius: 4px;
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Wrapper = styled.div`
	padding-top: 2rem;
	display: flex;
	flex-direction: column;
`;

const Input = styled.input`
	${InputField};
`;

const Label = styled.label`
	font-family: ${typography.BaseFontFamiliy} !important;
`;

const Styled = {
	Input,
	Label,
	CustomModalHeader,
	ModalHeaderTitle,
	CustomModal,
	CustomModalFooter,
	CustomModalBody,
	CustomInput,
	CloseContainer,
	SpinnerWrapper,
	ModalButton,
	ModalDeleteContainer,
	ModalDeleteIconContainer,
	ModalButtonsContainer,
	Wrapper,
};

export default Styled;
