import Styled from 'components/EditableImage/EditableImage.style';
import LoadingSpinner from 'components/LoadingSpinner';

type LoadingProps = {
	title: string;
};
/**
 * Loading component
 * @returns {JSX.Element}
 *
 */
const Loading = ({ title }: LoadingProps): JSX.Element => {
	return (
		<Styled.EditableImageSpinner>
			<LoadingSpinner />
			<div>{title}</div>
		</Styled.EditableImageSpinner>
	);
};

export default Loading;
