import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgFilters(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_1539_32422)'>
				<path
					d='M19.3 16.75H7.87C7.55 15.74 6.61 15 5.5 15C4.39 15 3.45 15.74 3.13 16.75H0.7C0.31 16.75 0 17.09 0 17.5C0 17.91 0.31 18.25 0.7 18.25H3.13C3.45 19.26 4.39 20 5.5 20C6.61 20 7.55 19.26 7.87 18.25H19.3C19.69 18.25 20 17.91 20 17.5C20 17.09 19.69 16.75 19.3 16.75ZM3 17.5C3 17.36 3.02 17.23 3.04 17.09C3.02 17.22 3 17.36 3 17.5C3 17.64 3.02 17.77 3.04 17.91C3.01 17.78 3 17.64 3 17.5ZM5.5 18.85C4.76 18.85 4.15 18.25 4.15 17.5C4.15 16.75 4.75 16.15 5.5 16.15C6.25 16.15 6.85 16.75 6.85 17.5C6.85 18.25 6.25 18.85 5.5 18.85ZM7.96 17.91C7.98 17.78 8 17.64 8 17.5C8 17.36 7.98 17.23 7.96 17.09C7.99 17.22 8 17.36 8 17.5C8 17.64 7.98 17.77 7.96 17.91Z'
					fill='#333333'
				/>
				<path
					d='M19.3 1.75H7.87C7.55 0.74 6.61 0 5.5 0C4.39 0 3.45 0.74 3.13 1.75H0.7C0.31 1.75 0 2.09 0 2.5C0 2.91 0.31 3.25 0.7 3.25H3.13C3.45 4.26 4.39 5 5.5 5C6.61 5 7.55 4.26 7.87 3.25H19.3C19.69 3.25 20 2.91 20 2.5C20 2.09 19.69 1.75 19.3 1.75ZM3 2.5C3 2.36 3.02 2.23 3.04 2.09C3.02 2.22 3 2.36 3 2.5C3 2.64 3.02 2.77 3.04 2.91C3.01 2.78 3 2.64 3 2.5ZM5.5 3.85C4.76 3.85 4.15 3.25 4.15 2.5C4.15 1.75 4.75 1.15 5.5 1.15C6.25 1.15 6.85 1.75 6.85 2.5C6.85 3.25 6.25 3.85 5.5 3.85ZM7.96 2.91C7.98 2.78 8 2.64 8 2.5C8 2.36 7.98 2.23 7.96 2.09C7.99 2.22 8 2.36 8 2.5C8 2.64 7.98 2.77 7.96 2.91Z'
					fill='#333333'
				/>
				<path
					d='M0.7 10.75H12.13C12.45 11.76 13.39 12.5 14.5 12.5C15.61 12.5 16.55 11.76 16.87 10.75H19.3C19.69 10.75 20 10.41 20 10C20 9.59 19.69 9.25 19.3 9.25H16.87C16.55 8.24 15.61 7.5 14.5 7.5C13.39 7.5 12.45 8.24 12.13 9.25H0.7C0.31 9.25 0 9.59 0 10C0 10.41 0.31 10.75 0.7 10.75ZM17 10C17 10.14 16.98 10.27 16.96 10.41C16.98 10.28 17 10.14 17 10C17 9.86 16.98 9.73 16.96 9.59C16.99 9.72 17 9.86 17 10ZM14.5 8.65C15.24 8.65 15.85 9.25 15.85 10C15.85 10.75 15.25 11.35 14.5 11.35C13.75 11.35 13.15 10.75 13.15 10C13.15 9.25 13.75 8.65 14.5 8.65ZM12.04 9.59C12.02 9.72 12 9.86 12 10C12 10.14 12.02 10.27 12.04 10.41C12.01 10.28 12 10.14 12 10C12 9.86 12.02 9.73 12.04 9.59Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1539_32422'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
