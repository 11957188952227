import { Model } from 'json-api-models';

export const getLatestReview = (reviews: Model[]) => {
	const sortedReviews = Array.isArray(reviews) && reviews.length ? reviews.sort((a, b) => +a.id - +b.id) : null;
	if (sortedReviews !== null) {
		return sortedReviews[sortedReviews.length - 1];
	} else {
		return null;
	}
};
