import { useEffect } from 'react';

import IntegratedInboxContainer from 'components/IntegratedInbox';
import PageHeader from 'components/PageHeader';

import Styled from './IntegratedInbox.style';

const IntegratedInbox = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Styled.Wrapper>
			<PageHeader headline='Messages' />
			<IntegratedInboxContainer />
		</Styled.Wrapper>
	);
};

export default IntegratedInbox;
