import { useNavigate } from 'react-router-dom';

import { DarkButton } from 'components/Button';
import useInjection from 'hooks/useInjection';
import { useAppDispatch } from 'hooks/useUserAppSelector';
import LogoutService from 'services/Authentication/Collabs-api/LogoutService';

import NotFoundImg from './404.svg';
import { Styled } from './NoPermissionsUser.style';

const NoPermissionsUserView = () => {
	const navigate = useNavigate();
	const userDispatch = useAppDispatch();
	const logoutService = useInjection<LogoutService>(LogoutService);

	const logOut = async () => {
		await logoutService.logout(userDispatch);
		navigate('/login');
	};

	return (
		<Styled.Wrapper>
			<Styled.Content>
				<Styled.InnerContent>
					<Styled.Image src={NotFoundImg} height='300px' />
					<Styled.Heading>Oh no! Your account is not connected yet.</Styled.Heading>
					<p>Please try again later or contact the support.</p>
				</Styled.InnerContent>
				<Styled.ButtonGroup>
					<DarkButton onClick={() => (window.location.href = 'mailto:info@collabs.se')}>Contact support</DarkButton>
				</Styled.ButtonGroup>
				<a onClick={() => logOut()}>Sign out</a>
			</Styled.Content>
		</Styled.Wrapper>
	);
};

export default NoPermissionsUserView;
