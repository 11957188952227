import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { fontWeights } from 'styles/variables/font-weights';
import { mediaQueries } from 'styles/variables/media-queries';

import { StyledMenuItem } from './types';

const Wrapper = styled.div`
	position: absolute;
	top: 100%;
	left: -999px;
	opacity: 0;
	border: 1px solid #c1c1c1;
	border-radius: 8px;
	background-color: ${colors.MainNavigation.backgroundOpen};
	box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.16);
	width: calc(100vw - 32px);
	z-index: 2000;
	overflow-x: hidden;
	transition:
		left 0.5s ease-in-out,
		background-color 0.4s ease-in-out,
		opacity 0.3s linear;
	transition-delay: 0s, 0.35s, 0s;
	padding: 1.75rem 0;

	${mediaQueries.medium`
		width: 340px
	`};

	&.open {
		left: 16px;
		opacity: 1;
	}
`;

const MenuList = styled.ul`
	padding: 0 8px;
	margin: 0;
	list-style: none;

	ul {
		max-height: 0;
		height: 100%;
		transition:
			max-height 0.2s cubic-bezier(0, 1, 0, 1),
			opacity 0.2s ease-in-out;
		overflow: hidden;
		opacity: 0;
		pointer-events: none;
		user-select: none;

		li:first-child {
			margin-top: 8px;
		}

		&.open {
			max-height: 1000px;
			opacity: 1;
			pointer-events: auto;
			user-select: auto;
		}
	}
`;

const ToggleIcon = styled.button`
	background-color: transparent;
	border: 0;
	padding: 0;
	transition: transform 0.2s ease;
	cursor: pointer;
	line-height: 1;
`;

const MenuItem = styled.li<StyledMenuItem>`
	display: ${(props) => (props.hasChildren ? 'list-item' : 'inline-flex')};
	padding: ${(props) => (props.level ? '0.25rem 2.06rem' : '0.5rem 2.06rem')};
	transition:
		background-color 0.2s ease-in-out,
		background-color 0.2s ease-in-out;
	align-items: center;
	flex-direction: row;
	height: 44px;
	width: 100%;
	border-radius: ${borderRadius.s};

	&.expanded {
		height: auto;
	}

	&.expanded,
	&:hover,
	&:focus-within {
		background-color: ${newColors.dust};
	}

	&.expanded {
		${ToggleIcon} {
			transform: rotateZ(90deg);
		}
	}

	&.active-parent {
		> div a {
			font-weight: 900;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const MenuItemRow = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const hamburgerMenu = styled.button`
	display: block;
	height: 31px;
	width: 16px;
	cursor: pointer;
	padding: 0.5rem 0 0.5rem 0;
	background-color: transparent;
	border: none;
	margin-right: 1rem;

	> span {
		position: relative;
		display: block;
		height: 2px;
		width: 100%;
		margin-top: 4px;
		background-color: ${colors.MainNavigation.hamburgerMenuColor};
		transition: all 0.25s;
		margin-top: 4px;
	}

	span:nth-child(1) {
		margin-top: 0;
	}

	span:nth-child(2) {
		width: 50%;
	}

	&.open span:first-child {
		transform: rotate(45deg) translate(4.4px, 4.4px);
	}

	&.open span:nth-child(2) {
		width: 0%;
		opacity: 0;
	}

	&.open span:last-child {
		transform: rotate(-45deg) translate(4.4px, -4.4px);
	}
`;

const RowMenu = styled.nav`
	display: flex;
	gap: 24px;
	align-items: center;

	a {
		font-size: 0.875rem;
		color: ${newColors.smoke};
		white-space: nowrap;
		line-height: 3;
		height: 40px;
		font-weight: ${fontWeights.bold};
		text-transform: capitalize;
		border-bottom: 2px solid transparent;
		transition: border 0.2s ease-in-out;

		&.active {
			border-bottom: 2px solid ${newColors.smoke};
		}

		&:hover {
			border-bottom: 2px solid ${newColors.smoke};
		}
	}
`;

const Styled = {
	Wrapper,
	MenuList,
	MenuItem,
	MenuItemRow,
	ToggleIcon,
	hamburgerMenu,
	RowMenu,
};

export default Styled;
