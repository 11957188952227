import styled from 'styled-components';

import Dropdown from 'components/Dropdown';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const TextAreaWrapper = styled.div`
	padding: 1rem 0 0 1rem;
	display: flex;
	flex: 1;
	max-height: 200px;
	${scrollbarY};
`;

const TextArea = styled.textarea`
	border: none;
	color: ${colors.integratedInbox.chatSection.chatInput.color};
	outline: none;
	resize: none;
	width: 100%;
	line-height: 1.5;
	background-color: ${colors.integratedInbox.chatSection.chatInput.background};
`;

const MessageWrapper = styled.div`
	display: flex;
	padding: 0.5rem 1rem;
`;

const MessageBar = styled.div`
	display: flex;
	flex-grow: 1;
	position: relative;
	background-color: ${colors.integratedInbox.chatSection.chatInput.background};
	flex-direction: row;
	border: 1px solid ${colors.lightGray};
	border-radius: ${borderRadius.m};
	flex-direction: column;
`;

const MessageBarControlsWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
`;

const Send = styled.button`
	background-color: transparent;
	border: none;
	padding: ${guttersWithRem.xxs};
	margin-left: auto;

	border-radius: 2px;

	&:not(:disabled) {
		background-color: ${colors.mainBlack};
		path {
			fill: ${colors.mainWhite};
		}
		cursor: pointer;
	}

	& > span > i {
		display: flex;
	}
`;

const MessageBarControls = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const CustomDropdown = styled(Dropdown)`
	margin-left: -8px;
	button .icon {
		display: inline-block;
		width: 24px;
		height: 24px;
		background-color: #f2f2f2;
		border-radius: 50%;
		cursor: pointer;
		svg {
			margin-top: 5px;
		}
	}

	.show {
		button {
			background-color: transparent !important;
		}
	}

	button {
		&:hover {
			background-color: transparent !important;
		}

		&:disabled {
			opacity: 0.4;
		}
	}

	span {
		display: flex;
		align-items: center;
	}
`;

const InvisibleInput = styled.input`
	display: none;
`;

const AttachedFilesWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
`;

const Attachment = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const AttachmentList = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 16px;

	.attachment__name {
		display: inline-block;
		max-width: 150px;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}

	.attachment__delete {
		cursor: pointer;
	}
`;

const Styled = {
	MessageWrapper,
	MessageBar,
	TextAreaWrapper,
	TextArea,
	MessageBarControlsWrapper,
	MessageBarControls,
	Send,
	CustomDropdown,
	InvisibleInput,
	AttachedFilesWrapper,
	Attachment,
	AttachmentList,
};

export default Styled;
