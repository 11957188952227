import { Network } from 'constants/socialMedia';

import { AutoCompleteOption, AutoCompleteValues } from './Components/AutoCompleteList/AutoCompleteList';

export const mapAutoCompleteOptions = (values: AutoCompleteValues): AutoCompleteOption[] =>
	values.map((value) => ({
		id: value.id,
		label: value.attributes.username,
		influencer: {
			username: value.attributes.username,
			links: {
				profilePictureUrl: value.attributes.profileImageUrl,
			},
			network: value.attributes.network,
		},
	}));

export const extractUsernameAndNetwork = (url: string) => {
	let network: Network | null = null;
	let username: string | null = null;

	// Check for Instagram URL
	const instagramRegex = /https?:\/\/(www\.)?instagram\.com\/([a-zA-Z0-9._]+)/;
	const tiktokRegex = /https?:\/\/(www\.)?tiktok\.com\/@([a-zA-Z0-9._]+)/;

	if (instagramRegex.test(url)) {
		const match = url.match(instagramRegex);
		network = Network.INSTAGRAM;
		username = match ? match[2] : null;
	} else if (tiktokRegex.test(url)) {
		const match = url.match(tiktokRegex);
		network = Network.TIKTOK;
		username = match ? match[2] : null;
	}

	return { network, username };
};
