import Utils from 'hooks/usePermissions/Utils';
import { LinksType } from 'reducers/UserReducers/types';

/**
 * useFeaturePermissions
 *
 * Custom hook to check if the user has permission to do things based on the hateoas links
 * from the response.
 *
 * @param {LinksType} links
 */
export default function useFeaturePermissions(links?: LinksType) {
	const userCan = Utils.can(links);
	const userCanAll = Utils.canAll(links);

	return {
		userCan,
		userCanAll,
	};
}
