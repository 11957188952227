import moment from 'moment';

import Table, { Column } from 'components/Table/V2/Table';
import { formatAmount } from 'utils/formatters';
import { PaymentInfo } from 'views/admin/Components/Users/types';

/**
 * @returns {JSX.Element}
 */
const PaymentsTable = (props: { paymentInfo: Array<PaymentInfo> }): JSX.Element => {
	const columns: Column[] = [
		{
			Header: 'Created',
			accessor: 'createdAt',
			Cell: ({ row }) => (row.original.createdAt ? moment(row.original.createdAt).format('YYYY-MM-DD HH:mm') : '-'),
		},
		{
			Header: 'Amount',
			accessor: 'amount',
			Cell: ({ row }) => (row.original.amount ? formatAmount(row.original.amount, row.original.currency) : '-'),
		},
		{
			Header: 'Reference',
			accessor: 'reference',
		},
		{
			Header: 'Payout',
			accessor: 'payoutAt',
			Cell: ({ row }) => (row.original.payoutAt ? moment(row.original.payoutAt).format('YYYY-MM-DD') : '-'),
		},
		{
			Header: 'Status',
			accessor: 'status',
		},
	];

	return (
		<div>
			<h5>Payments</h5>
			<Table columns={columns} data={props.paymentInfo} />
		</div>
	);
};

export default PaymentsTable;
