import { useNavigate } from 'react-router-dom';

import Fallback from 'assets/icons/influencer-no-image-fallback.svg';
import { GhostButton } from 'components/Button';
import Icon from 'components/Icon';
import { ActiveCampaign } from 'components/InfluencerDashboard/utils';
import { pathInfluencerCampaignView } from 'routing/PathLookup';
import { formatAmount } from 'utils/formatters';

import Styled from './CampaignItem.style';

type CampaignItemProps = {
	campaign: ActiveCampaign;
};
/**
 * @returns {JSX.Element}
 */
const CampaignItem = ({ campaign }: CampaignItemProps): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Styled.Wrapper lg={6} md={12} data-testid='campaign-item'>
			<Styled.InnerWrapper>
				<Styled.CampaignInfoWrapper>
					{campaign.image && (
						<Styled.CampaignImage>
							<img src={campaign.image ?? Fallback} alt='campaign-cover-image' />
						</Styled.CampaignImage>
					)}
					<Styled.FlexDiv>
						<GhostButton
							data-testid='go-to-brief'
							onClick={() => {
								navigate(pathInfluencerCampaignView(campaign.shortId), { state: { previewUrl: campaign.campaignInstagramOwner.links?.invitePreview } });
							}}
						>
							View brief
						</GhostButton>
						<Styled.CampaignInfo>
							<div className='title'>{campaign.name}</div>
							<div className='brand'>{campaign.brand}</div>
							{campaign.compensation.totalCommission > 0 || campaign.affiliate ? (
								<>
									<Styled.Text className='commission-value'>Commission</Styled.Text>
									<Styled.Text className='commission-label'>
										{campaign.affiliate ? 'Performance based' : formatAmount(campaign.compensation.totalCommission, campaign.compensation.currency)}
									</Styled.Text>
								</>
							) : (
								<></>
							)}
						</Styled.CampaignInfo>
					</Styled.FlexDiv>
				</Styled.CampaignInfoWrapper>
				<Styled.Text className='commission-value'>Assignments</Styled.Text>
				<Styled.CampaignInfo>
					{campaign.assignments.map((assignment, index) => {
						return (
							<Styled.AssignmentLink
								key={index}
								onClick={() => navigate(`/influencer/content-management/${campaign.id}/${campaign.campaignInstagramOwner.id}/${assignment.id}`)}
							>
								{assignment.name} <Icon name='chevron-right' />
							</Styled.AssignmentLink>
						);
					})}
				</Styled.CampaignInfo>
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default CampaignItem;
