import { Button } from 'components/Button';
import Icon from 'components/Icon';

import Styled from './ActionModal.style';

export type ActionModalProps = {
	isModalOpen: boolean;
	toggleModal: () => void;
	children: JSX.Element;
	footer?: JSX.Element | boolean;
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
	title?: string;
	actionHandler: () => void;
	buttonText?: string;
	saveIsActive?: boolean;
	onShown?: () => void;
	className?: string;
	replacementCancelButtonText?: string;
};
/**
 * @returns {JSX.Element}
 */
const ActionModal = ({
	saveIsActive,
	isModalOpen,
	toggleModal,
	actionHandler,
	children,
	buttonText,
	size,
	title,
	className,
	replacementCancelButtonText,
}: ActionModalProps): JSX.Element => {
	return (
		<Styled.CustomModal size={size} open={isModalOpen} handleClose={toggleModal} className={className}>
			<Styled.CustomModalHeader>
				<Styled.ModalHeaderTitle>{title}</Styled.ModalHeaderTitle>
				<Styled.CloseContainer onClick={toggleModal}>
					<Icon name='cross' size='12' />
				</Styled.CloseContainer>
			</Styled.CustomModalHeader>
			<Styled.CustomModalBody>{children}</Styled.CustomModalBody>
			<Styled.CustomModalFooter>
				<Button className='cancel' onClick={toggleModal}>
					{replacementCancelButtonText ? replacementCancelButtonText : 'Cancel'}
				</Button>
				<Button data-testid='modal-action-button' disabled={!saveIsActive} className={`${saveIsActive && 'saveable'} save`} onClick={actionHandler}>
					{buttonText}
				</Button>
			</Styled.CustomModalFooter>
		</Styled.CustomModal>
	);
};

export default ActionModal;
