import axios, { AxiosError } from 'axios';
import { JsonApiDocument, Model, Store } from 'json-api-models';
import { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import LoadingSpinner from 'components/LoadingSpinner';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { useAppDispatch } from 'hooks/useUserAppSelector';
import { setUser } from 'reducers/UserReducers/UserSlice';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import toast from 'services/Toast';
import Styled from 'views/influencer/NewSignUp/InfluencerSignUpContainer.style';

const VerifyEmailRedirect = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [redirectThisUser, setRedirectThisUser] = useState(false);
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const url = params.get('url');
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);

	const dispatch = useAppDispatch();

	const token = authService.getCollabsToken();

	const fetchUpdatedUser = () => {
		authService
			.me(authService.getGlobalUserIncludes())
			.then((res) => {
				const models = new Store();
				const user = models.sync(res) as Model;
				dispatch(setUser(user));
			})
			.then(() => {
				setIsLoading(false);
			});
	};

	const redirectUser = (displayToast?: boolean) => {
		if (token) {
			fetchUpdatedUser();
			displayToast && toast.success('Email has been verified');
		}
	};

	useEffect(() => {
		redirectThisUser && redirectUser();
	}, [redirectThisUser]);

	useEffect(() => {
		setIsLoading(true);
		if (url) {
			axios
				.post<JsonApiDocument>(url)
				.then(() => {
					setRedirectThisUser(true);
				})
				.catch((error: AxiosError) => {
					if (error?.response?.status === 410) {
						setRedirectThisUser(true);
						setIsLoading(false);
					} else {
						toast.error(getSomethingWentWrongMessage());
						setIsLoading(false);
					}
				});
		} else {
			setIsLoading(false);
		}
	}, []);

	return (
		<Styled.VerifyEmailContainer>
			{isLoading ? <LoadingSpinner position='center' /> : <Navigate to='/login' state={redirectThisUser ? 'verified' : undefined} />}
		</Styled.VerifyEmailContainer>
	);
};

export default VerifyEmailRedirect;
