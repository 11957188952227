import Styled from './Body.style';
import { IBody } from './types';

const Body = (props: IBody) => {
	return (
		<Styled.Wrapper display={props.display} justifyContent={props.justifyContent} alignItems={props.alignItems} className='card__body'>
			{props.children}
		</Styled.Wrapper>
	);
};

export default Body;
