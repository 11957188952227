import axios, { AxiosResponse } from 'axios';
import { injectable } from 'inversify';

import { StatusCode } from 'services/Response.types';
import toast from 'services/Toast';
import { ErrorResponse } from 'types/http';

@injectable()
export default class ToastOnBadRequestInterceptor {
	public onError(error: Error) {
		if (axios.isAxiosError(error) && error.response?.status === StatusCode.BAD_REQUEST) {
			const errors = (error.response as AxiosResponse<ErrorResponse>).data.errors ?? [];

			for (const { title, detail } of errors.slice(0, 3)) {
				const message = detail ?? title;

				if (message) {
					toast.error(message);
				}
			}
		}
		return Promise.reject(error);
	}
}
