import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { InfluencerListListItemModel, InfluencerListListModel } from 'api-models';
import Avatar from 'components/Avatar';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon';
import DeleteList from 'components/InfluencerManagement/Lists/Delete/DeleteList';
import Styled from 'components/InfluencerManagement/Lists/Lists.style';
import UpdateList from 'components/InfluencerManagement/Lists/Update/UpdateList';
import { InfluencerPreviewItem, ListsProps } from 'components/InfluencerManagement/Lists/types';
import { FileType, Filter } from 'components/InfluencerManagement/types';
import LoadingSpinner from 'components/LoadingSpinner';
import TableComponent, { Column, GenericData } from 'components/Table/V2/Table';
import Tooltip from 'components/Tooltip/V2';
import { DELETE_LIST_FOLDER, SHARE_LIST_FOLDER } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { pathInfluencerManagementList } from 'routing/PathLookup';

/**
 * List lists component
 * @param ListsProps
 * @returns {JSX.Element}
 */
const ListLists = ({
	lists,
	isLoading,
	setSelectedTab,
	setSelectedFilter,
	itemsFromListFn,
	setSidebarIsOpen,
	setListToEdit,
	listToEdit,
	setListToShare,
	setFolderToShare,
}: ListsProps): JSX.Element => {
	const [itemToDelete, setItemToDelete] = useState<InfluencerListListModel | null>(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const InputRef = useRef<HTMLInputElement | null>(null);

	const navigate = useNavigate();

	const handleSelectedRow = (values: GenericData) => {
		setSelectedTab(FileType.ALL);
		setSelectedFilter(Filter.ALL);
		navigate(pathInfluencerManagementList(values.id as string), { state: { previousUrl: location.pathname } });
	};

	const handleClickOutside = (e: MouseEvent): void => {
		if (InputRef.current && !InputRef.current.contains(e.target as Node)) {
			setListToEdit(null);
		}
	};

	const handleEscapeKey = (e: KeyboardEvent): void => {
		if (InputRef && InputRef.current && e.key === 'Escape') {
			setListToEdit(null);
		}
	};

	useEffect(() => {
		window.addEventListener('click', handleClickOutside, true);
		document.addEventListener('keyup', handleEscapeKey, true);
		return () => {
			window.removeEventListener('click', handleClickOutside, true);
			document.addEventListener('keyup', handleEscapeKey, true);
		};
	}, []);

	const columns: Column<InfluencerListListModel>[] = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'type',
				Cell: ({ row }) => {
					const getIcon = () => {
						if (row.original.attributes.shared && !row.original.attributes.createdByMe) {
							return 'list-shared-with-me';
						} else if (row.original.attributes.shared && row.original.attributes.createdByMe) {
							return 'list-shared';
						} else {
							return 'list';
						}
					};
					const getTooltipText = () => {
						if (row.original.attributes.shared && !row.original.attributes.createdByMe) {
							return 'List is shared with me';
						} else if (row.original.attributes.shared && row.original.attributes.createdByMe) {
							return 'List shared with others';
						} else {
							return 'Private list';
						}
					};
					return (
						<Styled.Item className={classNames({ canEdit: row.original.links?.delete })}>
							<Tooltip content={getTooltipText()}>
								<Styled.IconContainer>
									<Icon name={getIcon()} />
								</Styled.IconContainer>
							</Tooltip>
							{row.original.id === listToEdit?.id ? (
								<Styled.InputWrapper>
									<UpdateList
										forwardRef={InputRef}
										onSaved={() => {
											setListToEdit(null);
										}}
										item={listToEdit}
									/>
								</Styled.InputWrapper>
							) : (
								<Styled.Name>{row.original.attributes.name}</Styled.Name>
							)}
						</Styled.Item>
					);
				},
			},
			{
				Header: '',
				accessor: 'attributes',
				disableSortBy: true,
				Cell: ({ row }) => {
					const influencerPreviewItems = itemsFromListFn(row.original)?.map((influencer: InfluencerListListItemModel) => {
						return {
							id: influencer.id,
							profilePictureUrl: influencer.links?.profilePictureUrl ?? '',
						};
					});
					return (
						row.original.attributes.itemCount > 0 && (
							<Styled.Item className='justify-end'>
								<Styled.AvatarsContainer>
									{influencerPreviewItems.length > 0 ? (
										influencerPreviewItems?.map((influencer: InfluencerPreviewItem) => {
											return (
												<Styled.AvatarsStyleWrapper key={influencer.id}>
													<Avatar name={''} imageUrl={influencer.profilePictureUrl} size='md' />
												</Styled.AvatarsStyleWrapper>
											);
										})
									) : (
										<LoadingSpinner size='sm' />
									)}
									<Styled.InfluencerCount>{`(${row.original.attributes.itemCount})`}</Styled.InfluencerCount>
								</Styled.AvatarsContainer>
							</Styled.Item>
						)
					);
				},
				width: 80,
			},
			{
				Header: '',
				accessor: 'id',
				disableSortBy: true,
				Cell: ({ row }) => {
					const { userCan } = useFeaturePermissions(row.original.links);
					return (
						<Styled.Icons>
							<Styled.Options>
								{row.original.attributes.createdByMe ? (
									<Dropdown icon='options' size='16'>
										<DropdownMenu>
											{row.original.attributes.createdByMe && (
												<DropdownItem
													onClick={(e) => {
														e.stopPropagation();
														e.stopPropagation(), setListToEdit(row.original);
													}}
												>
													Edit
												</DropdownItem>
											)}
											{userCan(SHARE_LIST_FOLDER) && (
												<DropdownItem
													onClick={(e) => {
														e.stopPropagation(), setSidebarIsOpen(true), setListToShare(row.original), setFolderToShare(null);
													}}
												>
													Share
												</DropdownItem>
											)}
											{userCan(DELETE_LIST_FOLDER) && row.original.links?.delete && (
												<DropdownItem
													data-testid='action-delete-item'
													onClick={(e) => {
														e.stopPropagation(), setItemToDelete(row.original), setIsDeleteModalOpen(true);
													}}
												>
													Delete
												</DropdownItem>
											)}
										</DropdownMenu>
									</Dropdown>
								) : (
									<Styled.EmptyDiv />
								)}
							</Styled.Options>
							<Styled.ArrowContainer>
								<Icon name='arrow-right' size='12' />
							</Styled.ArrowContainer>
						</Styled.Icons>
					);
				},
				width: 30,
				maxWidth: 30,
			},
		],
		[listToEdit, itemsFromListFn],
	);

	return isLoading ? (
		<Styled.SpinnerContainer>
			<LoadingSpinner />
		</Styled.SpinnerContainer>
	) : lists.length > 0 ? (
		<div>
			<h3>Lists</h3>
			<TableComponent
				dataTestId='list-table'
				className='influencer-management-table'
				columns={columns}
				data={lists}
				enableSorting={true}
				getSelectedRow={(selectedRow) => handleSelectedRow(selectedRow)}
			/>
			<DeleteList itemToDelete={itemToDelete} setIsDeleteModalOpen={setIsDeleteModalOpen} isDeleteModalOpen={isDeleteModalOpen} />
		</div>
	) : (
		<></>
	);
};
export default ListLists;
