import Styled from './Bar.style';

const Bar = (props: { containerBg: string; percent: string; fillerBg: string }) => {
	return (
		<Styled.ContainerBar containerBg={props.containerBg}>
			<Styled.FillerBar percent={props.percent} fillerBg={props.fillerBg} />
		</Styled.ContainerBar>
	);
};

export default Bar;
