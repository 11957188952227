import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { GhostButton } from 'components/Button';
import { GHOST_USER_UUID, GHOST_USER_START_URL } from 'constants/localStorage-keys';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

type Props = {
	uuid: string;
};
const LoginAsButton = ({ uuid }: Props): JSX.Element => {
	const navigate = useNavigate();
	const path = useLocation();
	const storage = new BrowserStorage(StorageType.LOCAL);
	const [loading, setLoading] = useState(false);

	return (
		<GhostButton
			size='sm'
			onClick={(e) => {
				e.stopPropagation();
				storage.setItem(GHOST_USER_UUID, uuid);
				storage.setItem(GHOST_USER_START_URL, path.pathname);
				setLoading(true);

				navigate(`/dashboard`);
			}}
		>
			{loading ? '...' : 'Login as'}
		</GhostButton>
	);
};

export default LoginAsButton;
