import { inject, injectable } from 'inversify';

import { AssignmentReviewCommentModel, AssignmentReviewInternalCommentModel, AssignmentReviewMediaCommentModel, AssignmentReviewModel } from 'api-models';
import {
	CreateAssignmentReviewCommentPayload,
	CreateAssignmentReviewInternalCommentPayload,
	CreateAssignmentReviewMediaCommentPayload,
	CreateAssignmentReviewPayload,
	UpdateAssignmentReviewPayload,
} from 'api-payloads';
import {
	CampaignListAssignmentReviewsQuery,
	CreateAssignmentReviewCommentQuery,
	CreateAssignmentReviewInternalCommentQuery,
	CreateAssignmentReviewMediaCommentQuery,
	CreateAssignmentReviewQuery,
	ListAssignmentReviewCommentsQuery,
	ListAssignmentReviewInternalCommentsQuery,
	UpdateAssignmentReviewQuery,
} from 'api-queries';
import {
	AssignmentReviewCollectionResponse,
	AssignmentReviewCommentCollectionResponse,
	AssignmentReviewInternalCommentCollectionResponse,
} from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, UpdateConfig, SWRConfiguration } from './types';

import type AssignmentReviewApiClientInterface from 'services/ApiClient/AssignmentReviewApiClientInterface';

type Repository = ModelRepository<AssignmentReviewModel>;

@injectable()
class AssignmentReviewManager extends ApiManager<Repository> {
	private readonly client: AssignmentReviewApiClientInterface;

	public constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.AssignmentReviewApiClientInterface) client: AssignmentReviewApiClientInterface,
	) {
		super(cacheManager, new ModelRepository<AssignmentReviewModel>('assignmentReview'));
		this.client = client;
	}

	public listAssignmentReviewsByCampaign(
		campaignId: string,
		queryBuilder = RequestQueryBuilder.create<CampaignListAssignmentReviewsQuery>(),
		config: SWRConfiguration = {},
	) {
		const key = `listAssignmentReviewsByCampaign::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.campaignListAssignmentReviews(campaignId, queryBuilder.toQuery());

		return this.swr<AssignmentReviewCollectionResponse>(key, fetcher, config);
	}

	public listComments(reviewId: string, queryBuilder = RequestQueryBuilder.create<ListAssignmentReviewCommentsQuery>()) {
		const key = `listComments::${reviewId}_${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listComments(reviewId, queryBuilder.toQuery());

		return this.swr<AssignmentReviewCommentCollectionResponse>(key, fetcher);
	}

	public listInternalComments(reviewId: string, queryBuilder = RequestQueryBuilder.create<ListAssignmentReviewInternalCommentsQuery>()) {
		const key = `listInternalComments::${reviewId}_${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listInternalComments(reviewId, queryBuilder.toQuery());

		return this.swr<AssignmentReviewInternalCommentCollectionResponse>(key, fetcher);
	}

	public createComment(
		reviewId: string,
		payload: CreateAssignmentReviewCommentPayload,
		queryBuilder = RequestQueryBuilder.create<CreateAssignmentReviewCommentQuery>(),
		config: CreateConfig<AssignmentReviewModel> = {},
	): Promise<AssignmentReviewCommentModel> {
		return this.createModel<AssignmentReviewCommentModel>(this.client.createComment(reviewId, payload, queryBuilder.toQuery()), config);
	}

	public createInternalComment(
		reviewId: string,
		payload: CreateAssignmentReviewInternalCommentPayload,
		queryBuilder = RequestQueryBuilder.create<CreateAssignmentReviewInternalCommentQuery>(),
		config: CreateConfig<AssignmentReviewModel> = {},
	): Promise<AssignmentReviewInternalCommentModel> {
		return this.createModel<AssignmentReviewInternalCommentModel>(this.client.createInternalComment(reviewId, payload, queryBuilder.toQuery()), config);
	}

	public createMediaComment(
		reviewId: string,
		mediaId: string,
		payload: CreateAssignmentReviewMediaCommentPayload,
		queryBuilder = RequestQueryBuilder.create<CreateAssignmentReviewMediaCommentQuery>(),
		config: CreateConfig<AssignmentReviewModel> = {},
	): Promise<AssignmentReviewMediaCommentModel> {
		return this.createModel<AssignmentReviewMediaCommentModel>(this.client.createMediaComment(reviewId, mediaId, payload, queryBuilder.toQuery()), config);
	}

	public create(
		campaignId: string,
		assignmentId: string,
		influencerId: string,
		payload: CreateAssignmentReviewPayload,
		queryBuilder = RequestQueryBuilder.create<CreateAssignmentReviewQuery>(),
		config: CreateConfig<AssignmentReviewModel> = {},
	): Promise<AssignmentReviewModel> {
		return this.createModel<AssignmentReviewModel>(this.client.create(campaignId, assignmentId, influencerId, payload, queryBuilder.toQuery()), config);
	}

	public update(
		assignmentReviewId: string,
		payload: UpdateAssignmentReviewPayload,
		queryBuilder = RequestQueryBuilder.create<UpdateAssignmentReviewQuery>(),
		config: UpdateConfig<AssignmentReviewModel> = {},
	): Promise<AssignmentReviewModel> {
		return this.updateModel<AssignmentReviewModel>(this.client.update(assignmentReviewId, payload, queryBuilder.toQuery()), config);
	}
}

export default AssignmentReviewManager;
