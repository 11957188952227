import { ReactNode } from 'react';

import Styled from './TwoColumnModal.style';

const RightColumn = (props: { title: JSX.Element | string; children: ReactNode; overFlowAuto?: boolean }) => {
	return (
		<Styled.RightColumnContainerWrapper>
			<Styled.Title>{props.title}</Styled.Title>
			<Styled.RightColumnContainer>{props.children}</Styled.RightColumnContainer>
		</Styled.RightColumnContainerWrapper>
	);
};

export default RightColumn;
