/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { LogPayload } from 'api-payloads';
import { ScraperInputQuery, OgMetaQuery } from 'api-queries';
import { ToolUrlOgMetaResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import ToolsApiClientInterface from './ToolsApiClientInterface';

@injectable()
export default class ToolsApiClient implements ToolsApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Scraper input
	 */
	async scraperInput(params: ScraperInputQuery): Promise<void> {
		const response = await this.client.post<void>(`/tools/collect-data`, undefined, { params });
		return response.data;
	}

	/**
	 * Log
	 */
	async log(payload: LogPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/tools/log`, payload, { params });
		return response.data;
	}

	/**
	 * Og meta
	 */
	async ogMeta(params: OgMetaQuery): Promise<ToolUrlOgMetaResponse> {
		const response = await this.client.get<ToolUrlOgMetaResponse>(`/tools/og-metadata`, { params });
		return response.data;
	}
}
