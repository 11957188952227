import { GraphItems } from 'components/DataLibrary/Graphs/Categories/types';
import { Total, ByInfluencerContainer, ByAssignmentType } from 'components/DataLibrary/Graphs/GraphComponents';
import { ENGAGEMENT } from 'constants/data-library';

const EngagementGraphs = (): GraphItems => {
	const graphItems = {
		category: 'Engagement',
		items: [
			{
				title: ENGAGEMENT.TOTAL_TITLE,
				icon: ENGAGEMENT.TOTAL_ICON,
				id: ENGAGEMENT.TOTAL,
				component: <Total title={ENGAGEMENT.TOTAL_TITLE} chartType={ENGAGEMENT.TOTAL} />,
			},
			{
				title: ENGAGEMENT.TOTAL_LIKES_TITLE,
				icon: ENGAGEMENT.TOTAL_LIKES_ICON,
				id: ENGAGEMENT.TOTAL_LIKES,
				component: <Total title={ENGAGEMENT.TOTAL_LIKES_TITLE} chartType={ENGAGEMENT.TOTAL_LIKES} />,
			},
			{
				title: ENGAGEMENT.TOTAL_COMMENTS_TITLE,
				icon: ENGAGEMENT.TOTAL_COMMENTS_ICON,
				id: ENGAGEMENT.TOTAL_COMMENTS,
				component: <Total title={ENGAGEMENT.TOTAL_COMMENTS_TITLE} chartType={ENGAGEMENT.TOTAL_COMMENTS} />,
			},
			{
				title: ENGAGEMENT.TOTAL_SHARES_TITLE,
				icon: ENGAGEMENT.TOTAL_SHARES_ICON,
				id: ENGAGEMENT.TOTAL_LIKES,
				component: <Total title={ENGAGEMENT.TOTAL_SHARES_TITLE} chartType={ENGAGEMENT.TOTAL_SHARES} />,
			},
			{
				title: ENGAGEMENT.TOTAL_SAVES_TITLE,
				icon: ENGAGEMENT.TOTAL_SAVES_ICON,
				id: ENGAGEMENT.TOTAL_SAVES,
				component: <Total title={ENGAGEMENT.TOTAL_SAVES_TITLE} chartType={ENGAGEMENT.TOTAL_SAVES} />,
			},
			{
				title: ENGAGEMENT.BY_ASSIGNMENT_TYPE_TITLE,
				icon: ENGAGEMENT.BY_ASSIGNMENT_TYPE_ICON,
				id: ENGAGEMENT.BY_ASSIGNMENT_TYPE,
				component: <ByAssignmentType displayBar title={ENGAGEMENT.BY_ASSIGNMENT_TYPE_TITLE} chartType={ENGAGEMENT.BY_ASSIGNMENT_TYPE} />,
			},
			{
				title: ENGAGEMENT.BY_INFLUENCER_TITLE,
				icon: ENGAGEMENT.BY_INFLUENCER_ICON,
				id: ENGAGEMENT.BY_INFLUENCER,
				component: (
					<ByInfluencerContainer title={ENGAGEMENT.BY_INFLUENCER_TITLE} isTopThree={true} chartType={ENGAGEMENT.BY_INFLUENCER} valueType='engagement' />
				),
			},
			{
				title: ENGAGEMENT.ENGAGEMENT_CPE_TITLE,
				icon: ENGAGEMENT.BY_INFLUENCER_ICON,
				id: ENGAGEMENT.ENGAGEMENT_AND_CPE_BY_INFLUENCER,
				component: (
					<ByInfluencerContainer
						title={ENGAGEMENT.ENGAGEMENT_CPE_TITLE}
						chartType={ENGAGEMENT.ENGAGEMENT_AND_CPE_BY_INFLUENCER}
						valueType='engagement-cpe'
						displayCombinedBar
					/>
				),
			},
			{
				title: ENGAGEMENT.ENGAGEMENT_AND_REACH_BY_INFLUENCER_TITLE,
				icon: ENGAGEMENT.BY_INFLUENCER_ICON,
				id: ENGAGEMENT.ENGAGEMENT_AND_REACH_BY_INFLUENCER,
				component: (
					<ByInfluencerContainer
						title={ENGAGEMENT.ENGAGEMENT_AND_REACH_BY_INFLUENCER_TITLE}
						chartType={ENGAGEMENT.ENGAGEMENT_AND_REACH_BY_INFLUENCER}
						valueType='engagement-reach-by-influencer'
						displayCombinedBar
					/>
				),
			},
		],
	};

	return graphItems;
};
export default EngagementGraphs;
