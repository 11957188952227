import styled, { css, DefaultThemeV2 } from 'styled-components';

import colors from 'styles/variables/colors';

import { ICardStyle } from './types';

type T = { theme: DefaultThemeV2 };

const Wrapper = styled.div<ICardStyle>`
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || 'auto'};
	padding: 10px 15px;
	border-radius: 10px;
	border: 1px solid ${(props) => (props.shadow ? 'transparent' : colors.borderGray)};
	background-color: ${colors.card.background};

	${(props) =>
		props.shadow
			? css`
					box-shadow: ${({ theme }: T) => theme.shadows.default};
				`
			: ''}
`;

const InnerWrapper = styled.div<ICardStyle>`
	width: 100%;
	height: 100%;

	display: ${(props) => props.display};
	align-content: ${(props) => props.alignContent};
`;

const Styled = {
	Wrapper,
	InnerWrapper,
};

export default Styled;
