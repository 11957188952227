import styled from 'styled-components';

const CustomInput = styled.div`
	.react-datepicker__input-container {
		background-color: red;
	}
`;

const Styled = {
	CustomInput,
};

export default Styled;
