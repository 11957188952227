import { Model } from 'json-api-models';
import { useEffect, useState } from 'react';

import Icon from 'components/Icon';
import EmptyStateAssignments from 'components/NewBriefPage/Components/EmptyStateAssignments/EmptyStateAssignments';
import { ASSIGNMENT_TYPE } from 'views/Campaign/Dashboard/types';

import Styled from './Assignment.style';

const getIcon = (kind: string) => {
	switch (kind) {
		case ASSIGNMENT_TYPE.INSTAGRAM_POST:
			return 'instagram';
		case ASSIGNMENT_TYPE.INSTAGRAM_STORY:
			return 'instagram';
		case ASSIGNMENT_TYPE.INSTAGRAM_REEL:
			return 'instagram';
		case ASSIGNMENT_TYPE.TIKTOK_VIDEO:
			return 'tiktok';
		case ASSIGNMENT_TYPE.AFFILIATE_TASK:
			return 'affiliate-campaign';
	}
};
const getAssignmentType = (kind: string, number: number) => {
	switch (kind) {
		case ASSIGNMENT_TYPE.INSTAGRAM_POST:
			return number > 1 ? 'Posts' : 'Post';
		case ASSIGNMENT_TYPE.INSTAGRAM_STORY:
			return number > 1 ? 'Stories' : 'Story';
		case ASSIGNMENT_TYPE.INSTAGRAM_REEL:
			return number > 1 ? 'Reels' : 'Reel';
		case ASSIGNMENT_TYPE.TIKTOK_VIDEO:
			return number > 1 ? 'TikToks' : 'TikTok';
		case ASSIGNMENT_TYPE.AFFILIATE_TASK:
			return number > 1 ? 'Affiliates' : 'Affiliate';
	}
};

const Assignment = (props: { assignments: Model; onPreview?: boolean; campaignId: string }) => {
	const [groupedAssignments, setGroupedAssignments] = useState<{ type: string; items: Model }[]>();

	useEffect(() => {
		const modifiedList = props.assignments?.reduce((array: Model, item: Model) => {
			if (!array[item.type]) {
				array[item.type] = [item];
			} else {
				array[item.type].push(item);
			}
			return array;
		}, {});

		const newList = [];
		for (const item in modifiedList) {
			newList.push({
				type: item,
				items: modifiedList[item],
			});
		}
		setGroupedAssignments(newList);
	}, [props.assignments]);

	return (
		<Styled.Container>
			<Styled.Title>Assignment</Styled.Title>
			{props.assignments.length > 0 ? (
				<>
					<Styled.List>
						{groupedAssignments?.map((assignment, i) => {
							return (
								<li key={i}>
									<Styled.Task>
										<Icon name={getIcon(assignment.type)} />
										<span>
											{assignment.items.length} {getAssignmentType(assignment.type, assignment.items.length)}
										</span>
									</Styled.Task>
								</li>
							);
						})}
					</Styled.List>
				</>
			) : (
				props.onPreview && <EmptyStateAssignments campaignId={props.campaignId} />
			)}
		</Styled.Container>
	);
};

export default Assignment;
