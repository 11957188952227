import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgUnHideEye(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.935 4C15.155 4 19.095 8.58618 19.835 9.50541L19.825 9.5154C20.055 9.80516 20.055 10.1948 19.825 10.4846C19.085 11.4138 15.145 16 9.925 16C4.705 16 0.875 11.3938 0.165 10.4746C-0.055 10.1848 -0.055 9.80516 0.165 9.5254C0.885 8.59617 4.715 4 9.935 4ZM5.515 6.58784C3.535 7.73689 2.085 9.32556 1.525 9.995C2.085 10.6644 3.535 12.2531 5.515 13.4022C4.675 12.4829 4.175 11.2839 4.175 9.995C4.175 8.70608 4.675 7.51707 5.515 6.58784ZM5.105 9.995C5.105 12.4829 7.305 14.5012 10.005 14.5012C12.705 14.5012 14.905 12.4829 14.905 9.995C14.905 7.50708 12.705 5.48876 10.005 5.48876C7.305 5.48876 5.105 7.50708 5.105 9.995ZM14.6053 13.2802C16.515 12.1425 17.917 10.6422 18.475 9.995C17.925 9.34555 16.505 7.8368 14.595 6.69775C15.365 7.60699 15.835 8.74604 15.835 9.995C15.835 11.2384 15.3791 12.3729 14.6053 13.2802ZM8.22485 9.99497C8.22485 9.00579 9.02485 8.20646 10.0149 8.20646C11.0049 8.20646 11.8049 9.00579 11.8049 9.99497C11.8049 10.9841 11.0049 11.7835 10.0149 11.7835C9.02485 11.7835 8.22485 10.9841 8.22485 9.99497Z'
				fill='#333333'
			/>
		</svg>
	);
}
