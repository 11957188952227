import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgListShared(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 2.48999C7.3418 2.07578 7.67758 1.73999 8.0918 1.73999H19.0918C19.506 1.73999 19.8418 2.07578 19.8418 2.48999C19.8418 2.9042 19.506 3.23999 19.0918 3.23999H8.0918C7.67758 3.23999 7.3418 2.9042 7.3418 2.48999Z'
				fill='#4D4B4C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 9.98999C7.3418 9.57578 7.67758 9.23999 8.0918 9.23999H12.0918C12.506 9.23999 12.8418 9.57578 12.8418 9.98999C12.8418 10.4042 12.506 10.74 12.0918 10.74H8.0918C7.67758 10.74 7.3418 10.4042 7.3418 9.98999Z'
				fill='#4D4B4C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 17.49C7.3418 17.0758 7.67758 16.74 8.0918 16.74L11.75 16.74C12.1642 16.74 12.5 17.0758 12.5 17.49C12.5 17.9042 12.1642 18.24 11.75 18.24H8.0918C7.67758 18.24 7.3418 17.9042 7.3418 17.49Z'
				fill='#4D4B4C'
			/>
			<path
				d='M18.1414 15.8946L19.8691 14.3004C19.911 14.2617 19.9424 14.2084 19.9686 14.1502C19.9895 14.1066 20 14.063 20 14.0145L20 14.0048C20 13.9515 19.9843 13.9031 19.9634 13.8546C19.9372 13.7965 19.9058 13.7432 19.8691 13.7093L18.1414 12.1054C17.9895 11.9649 17.7382 11.9649 17.5864 12.1054C17.4346 12.2459 17.4346 12.4785 17.5864 12.619L18.6859 13.6366L13.3927 13.6366C13.178 13.6366 13 13.8013 13 14C13 14.1987 13.178 14.3634 13.3927 14.3634L18.6859 14.3634L17.5864 15.381C17.5079 15.4537 17.4712 15.5457 17.4712 15.6378C17.4712 15.7299 17.5079 15.8219 17.5864 15.8946C17.7382 16.0351 17.9895 16.0351 18.1414 15.8946Z'
				fill='#4D4B4C'
			/>
			<rect x='0.75' y='0.75' width='3.5' height='3.5' rx='1.75' stroke='#4D4B4C' strokeWidth='1.5' />
			<rect x='0.75' y='8.25' width='3.5' height='3.5' rx='1.75' stroke='#4D4B4C' strokeWidth='1.5' />
			<rect x='0.75' y='15.75' width='3.5' height='3.5' rx='1.75' stroke='#4D4B4C' strokeWidth='1.5' />
		</svg>
	);
}
