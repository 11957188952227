import styled from 'styled-components';

const AdminPayout = styled.div``;

const MainContainerInner = styled.div`
	padding-top: 2rem;
`;

const Styled = {
	AdminPayout,
	MainContainerInner,
};

export default Styled;
