import SocialProfileConnector from 'components/SocialProfileConnector';

import Styled from './SocialConnect.style';

const SocialConnect = () => {
	return (
		<Styled.Wrapper>
			<h1>Social account missing</h1>
			<p>You need to add a social media account before you can proceed.</p>
			<SocialProfileConnector isForceConnect={true} />
		</Styled.Wrapper>
	);
};

export default SocialConnect;
