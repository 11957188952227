import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgThumbsUp(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M9.26768 1C8.23785 1 7.39039 1.81584 7.01493 2.37349L3.04579 8.39414C2.28415 9.55076 2.11251 9.85025 2.09105 9.90188C1.88723 10.284 2.03742 10.7487 2.43433 10.9449C2.83124 11.1411 3.31398 10.9965 3.5178 10.6144C3.53925 10.5731 3.70016 10.284 4.40817 9.23063L8.37731 3.20998C8.37731 3.20998 8.97804 2.33219 9.50369 2.63167C9.88987 2.84854 9.70751 3.36489 9.6646 3.45783L7.99112 7.29948C7.86239 7.59896 7.89458 7.95008 8.07694 8.21858C8.27004 8.48708 8.58113 8.65231 8.92441 8.65231H14.7923C15.039 8.65231 15.4681 8.68329 15.6183 8.88983C15.7899 9.12736 15.6934 9.67469 15.5861 9.97417C15.4574 10.284 15.5539 10.6454 15.8221 10.852C16.1654 11.1205 16.337 11.389 16.3478 11.6471C16.3478 12.0086 16.0367 12.37 15.9187 12.4836C15.747 12.6385 15.6398 12.8657 15.6505 13.0929C15.6612 13.3201 15.7792 13.537 15.9723 13.6816C16.4014 13.9914 16.4121 14.2289 16.4121 14.3012C16.4121 14.7143 15.9294 15.21 15.8114 15.3029C15.511 15.5508 15.4359 15.9638 15.629 16.2943C15.9401 16.79 15.9401 17.0585 15.8972 17.1308C15.7792 17.327 15.1463 17.4716 14.7172 17.4509H6.04946C4.49399 17.4509 3.52852 15.7676 3.5178 15.747C3.30325 15.3752 2.82052 15.2306 2.4236 15.4372C2.03742 15.6437 1.88723 16.1084 2.09105 16.4802C2.14469 16.5835 3.5178 19 6.03874 19H14.6636C14.8352 19 16.5838 19.0206 17.2489 17.9156C17.5707 17.389 17.5814 16.759 17.2703 16.0464C17.6136 15.6437 18.0105 15.0137 17.9998 14.2702C17.9998 13.9294 17.8925 13.475 17.5171 13.0103C17.7423 12.6592 17.9462 12.1945 17.9354 11.6368C17.9354 11.2134 17.7852 10.6248 17.2167 10.0258C17.3347 9.50945 17.3991 8.69362 16.927 8.03269C16.6267 7.60929 16.0152 7.11359 14.7708 7.11359H9.80405L11.1343 4.05679C11.4132 3.42685 11.6063 2.0327 10.2975 1.29948C9.93278 1.09294 9.57878 1.01033 9.25696 1.01033L9.26768 1Z'
				fill='#333333'
			/>
		</svg>
	);
}
