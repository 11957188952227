import axios, { AxiosResponse } from 'axios';
import { FormikErrors } from 'formik/dist/types';
import { isNil, set } from 'lodash';

import { ErrorResponse } from 'types/http';

export default function (e: unknown, setErrors: (errors: FormikErrors<object>) => void) {
	const errors: Record<string, string> = {};
	if (!axios.isAxiosError(e) || !e.response) {
		console.error('Unexpected error %O', e);

		return errors;
	}

	const { data, status } = e.response as AxiosResponse<ErrorResponse>;

	if (400 !== status) {
		console.error('Unexpected HTTP %d response: %O', status, data);

		return errors;
	}

	for (const { source, title, detail } of data.errors) {
		let errorMessage = detail ?? title ?? source.message;

		if (!isNil(detail) && !isNil(title)) {
			errorMessage = `${title}: ${detail}`;
		}

		set(errors, source.parameter, errorMessage);
	}

	setErrors(errors);

	return errors;
}
