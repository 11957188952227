import Icon from 'components/Icon';

import Styled from './SeeAllToggle.style';

type SeeAllToggleProps = {
	setIsShowAll: (isShowAll: boolean) => void;
	isShowAll: boolean;
};

/**
 * @param {SeeAllToggleProps} props
 * @returns {JSX.Element}
 */
const SeeAllToggle = ({ setIsShowAll, isShowAll }: SeeAllToggleProps): JSX.Element => {
	return (
		<Styled.BottomWrapper>
			<Styled.SeeAll
				onClick={() => {
					setIsShowAll(!isShowAll);
				}}
				data-testid='see-all'
			>
				<Icon name={isShowAll ? 'chevron-up' : 'chevron-down'} size='16' />
				<div>{isShowAll ? 'See less' : 'See all'}</div>
			</Styled.SeeAll>
		</Styled.BottomWrapper>
	);
};

export default SeeAllToggle;
