import { ByCountryOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByCountry/types';
import ChartCard from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard';
import Styled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { ChartCardProps } from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/types';
import BarChart from 'components/DataLibrary/Graphs/GraphComponents/CustomBarChart/BarChart';
import ExplanationAccordition from 'components/DataLibrary/Graphs/GraphComponents/ExplanationAccordion';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { formatNumber } from 'shared/helpers/Chart/chart-util';
import { formatAmount } from 'utils/formatters';
import { useAppSelector } from 'views//DataLibrary/hooks';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

import CircleChart from './GraphTypes/CircleChart';

/**
 * @returns {JSX.Element}
 */
const ByCountryContainer = ({
	chartId,
	fetchData,
	title,
	subTitle,
	chartType,
	chartOnDashboard,
	displayBar,
	displayBarTotal,
	displayCircle,
	isAmount,
	chartExplanation,
}: ChartCardProps<ByCountryOutput>): JSX.Element => {
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const manager = useInjection<StatisticsManager>(StatisticsManager);

	let somethingWentWrong = null;
	let total = 0;
	let countryData: { label: string; value: number }[] = [];
	let currency;
	let isLoading = true;

	if (!fetchData) {
		isLoading = false;
		total = 2700 + 2000 + 1400;
		countryData = [
			{ label: 'Sweden', value: 2700 },
			{ label: 'Norway', value: 2000 },
			{ label: 'Finland', value: 1400 },
		];
	} else {
		const { result, error } = fetchData(manager, dashboardFilter);
		if (error) {
			somethingWentWrong = error.message;
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			somethingWentWrong = null;
			total = result.total;
			countryData = result.countries;
			currency = result.currency;
		}
	}

	return (
		<ChartCard
			somethingWentWrong={somethingWentWrong}
			chartId={chartId}
			title={title}
			chartType={chartType}
			chartOnDashboard={chartOnDashboard}
			isLoading={isLoading}
		>
			<Styled.InnerWrapper>
				<Styled.TextContainer>
					<Styled.SubTitle>{subTitle}</Styled.SubTitle>
					<Styled.Total>Total {isAmount ? formatAmount(total, currency) : formatNumber(total, true)}</Styled.Total>
				</Styled.TextContainer>
				<div>
					{displayBarTotal && !isLoading && <BarChart total={isAmount ? total / 100 : total} items={countryData} />}
					{displayBar && !isLoading && (
						<BarChart gridTemplateColumns={isAmount ? '1fr 6fr 1fr' : '4fr 4fr 1fr'} total={isAmount ? total / 100 : total} items={countryData} sign={'%'} />
					)}
					{displayCircle && <CircleChart total={isAmount ? total / 100 : total} items={countryData} />}
				</div>
			</Styled.InnerWrapper>
			{chartExplanation && <ExplanationAccordition chartTitle={title} chartExplanation={chartExplanation} />}
		</ChartCard>
	);
};

export default ByCountryContainer;
