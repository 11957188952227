export const CROPPER_OPTIONS: Cropper.Options = {
	viewMode: 1,
	guides: true,
	autoCrop: false,
	preview: '.img-preview',
};

export const ALLOWED_MEDIA: Record<string, string[]> = {
	'image/jpeg': ['.jpeg', '.jpg'],
	'image/png': ['.png'],
	'image/heic': ['.heic'],
	'image/webp': ['.webp'],
};
