import classNames from 'classnames';
import { isNil } from 'lodash';

import Icon from 'components/Icon';
import InfluencerAvatar from 'components/InfluencerAvatar';
import { Network } from 'constants/socialMedia';
import { InfluencerType } from 'reducers/UserReducers/types';

import Styled from './ProfileSelector.style';

type Props = {
	profiles: InfluencerType[];
	selectedProfile?: InfluencerType;
	onSelect: (profile: InfluencerType) => void;
	missingPreferenceProfileIds?: string[];
};

const ProfileSelector = ({ profiles, selectedProfile, onSelect, missingPreferenceProfileIds }: Props) => {
	return (
		<Styled.Wrapper>
			{profiles.map((influencer) => {
				return (
					<Styled.InfleuncerItem
						data-testid={`onboarding-profile-${influencer.id}`}
						key={influencer.id}
						className={classNames({
							selected: !isNil(selectedProfile) && selectedProfile.id === influencer.id,
							error: !isNil(missingPreferenceProfileIds) && missingPreferenceProfileIds.includes(influencer.id),
						})}
						onClick={() => {
							onSelect(influencer);
						}}
					>
						{!isNil(missingPreferenceProfileIds) && missingPreferenceProfileIds.includes(influencer.id) && <Icon name='alert' size='16' />}
						<Styled.InfluencerAvatarWrapper>
							<InfluencerAvatar
								userName={influencer.username}
								network={influencer.network as Network}
								profileImageUrl={influencer.links?.profilePictureUrl ?? ''}
								displayNetwork={true}
							/>
						</Styled.InfluencerAvatarWrapper>
						{influencer.username}
					</Styled.InfleuncerItem>
				);
			})}
		</Styled.Wrapper>
	);
};

export default ProfileSelector;
