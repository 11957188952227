import { Model } from 'json-api-models';

import { InfluencerListFolderModel, InfluencerListListItemModel, InfluencerListListModel } from 'api-models';
import { MutateFn } from 'services/ApiManager/types';

export enum FileType {
	ALL = 'All',
	FOLDERS = 'Folders',
	LISTS = 'Lists',
}

export enum Filter {
	ALL = 'All',
	SHARED = 'Shared',
	SHARED_WITH_ME = 'Shared-with-me',
	PRIVATE = 'private',
}

export type InfluencerManagementProps = {
	currentFolder: InfluencerListFolderModel | null;
	parentFolder: InfluencerListFolderModel | undefined;
	previousUrl: string;
	isLoading: boolean;
	mutate: MutateFn;
	folders: Array<InfluencerListFolderModel>;
	lists: Array<InfluencerListListModel>;
	itemsFromListFn: (list: InfluencerListListModel) => Array<InfluencerListListItemModel>;
	allUsers: Array<Model>;
};
