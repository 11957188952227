import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgCopy(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg fill='none' {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.56649 0H17.1205H17.1305C17.6102 0 18 0.39 18 0.88V14.47C18 14.95 17.6102 15.35 17.1305 15.35H15.3716C14.9919 15.35 14.6821 15.04 14.6821 14.65C14.6821 14.26 14.9919 13.95 15.3716 13.95H16.6209V1.39H8.07617V2.55C8.07617 2.94 7.76636 3.25 7.3866 3.25C7.00684 3.25 6.69703 2.94 6.69703 2.55V0.88C6.69703 0.4 7.08679 0 7.56649 0ZM2.86946 4.6499H12.4135C12.8932 4.6499 13.283 5.0399 13.283 5.5299V19.1199C13.283 19.5999 12.8932 19.9999 12.4135 19.9999H2.86946C2.38976 19.9999 2 19.6099 2 19.1199V5.5299C2 5.0499 2.38976 4.6499 2.86946 4.6499ZM3.37914 18.6099H11.9238V18.5999V6.0399H3.37914V18.6099Z'
				fill='#333333'
			/>
		</svg>
	);
}
