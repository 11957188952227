import Styled from './Card.style';
import { ICard } from './types';

const Card = (props: ICard) => {
	return (
		<Styled.Wrapper width={props.width} height={props.height} className={props.className} shadow={props.shadow}>
			<Styled.InnerWrapper display={props.display} alignContent={props.alignContent} className='card__inner'>
				{props.children}
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default Card;
