import Icon from 'components/Icon';
import { translateRole } from 'components/Settings/Components/InviteTeamMembers/InviteTeamMembers';
import UserAvatar from 'components/Settings/MyAccount/UserAvatar';
import { OrganizationHierarchyRole, RoleTranslation } from 'components/Settings/types';
import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './UsersWithRolesList.style';
import { UserListWithRolesProps } from './type';

const UsersWithRolesList = ({ roles, getUser, changeUserRole, canEdit, availableRoles }: UserListWithRolesProps) => {
	const me = useAppSelector((state) => state.user);

	const displayDescriptionOfRole = (role: string) => {
		switch (role) {
			case OrganizationHierarchyRole.ADMINISTRATOR:
				return RoleTranslation.ADMINISTRATOR;
			case OrganizationHierarchyRole.CAMPAIGN_MANAGER:
				return RoleTranslation.CAMPAIGN_MANAGER;
			case OrganizationHierarchyRole.BRAND_MANAGER:
				return RoleTranslation.BRAND_MANAGER;
		}
	};

	const sortedRoles = [...roles].sort((a, b) => {
		const userA = getUser(a);
		const userB = getUser(b);
		if (userA.id === me.id) return -1;
		if (userB.id === me.id) return 1;
		return 0;
	});

	return (
		<Styled.UserList>
			{sortedRoles?.map((role, key) => {
				const user = getUser(role);
				return (
					<li key={key}>
						<Styled.ItemAvatar title={user?.attributes?.name || ''}>
							<UserAvatar
								fullName={user?.attributes?.name}
								displayRole
								displayThisRole={displayDescriptionOfRole(role.attributes?.role)}
								className='dark'
								medium
							/>
						</Styled.ItemAvatar>
						{me?.id !== user?.id
							? canEdit && (
									<div className='select'>
										<Styled.Select value={role.attributes?.role} onChange={(event) => changeUserRole(event.target, user)}>
											{availableRoles?.map((role, i) => (
												<option key={i} value={role}>
													{translateRole(role)}
												</option>
											))}
											<option value='delete'>Remove</option>
										</Styled.Select>
										<Icon name='arrow-down' size='14' />
									</div>
								)
							: ' (You)'}
					</li>
				);
			})}
		</Styled.UserList>
	);
};

export default UsersWithRolesList;
