import { useState } from 'react';

import { ByAssignmentTypeOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByAssignmentType/types';
import ChartCard from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard';
import Styled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { ChartCardProps } from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/types';
import BarItemContainer from 'components/DataLibrary/Graphs/GraphComponents/CustomBarChart/BarItem/BarItemContainer';
import ExplanationAccordition from 'components/DataLibrary/Graphs/GraphComponents/ExplanationAccordion';
import LoadingSpinner from 'components/LoadingSpinner';
import { INSTAGRAM_POST, INSTAGRAM_STORY, INSTAGRAM_REEL, TIKTOK_VIDEO } from 'constants/data-library';
import { getErrorMessageOnFetch } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import toast from 'services/Toast';
import { formatNumber } from 'shared/helpers/Chart/chart-util';
import colors from 'styles/variables/colors';
import { useAppSelector } from 'views//DataLibrary/hooks';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

import CircleChart from './GraphTypes/CircleChart';

/**
 * @returns {JSX.Element}
 */
const ByAssignmentType = ({
	chartId,
	fetchData,
	title,
	chartType,
	chartOnDashboard,
	displayBar,
	displayCircle,
	chartExplanation,
}: ChartCardProps<ByAssignmentTypeOutput>): JSX.Element => {
	const [displayRealValue, setDisplayRealValue] = useState<boolean>(false);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const manager = useInjection<StatisticsManager>(StatisticsManager);

	let total = 0;
	let isLoading = true;

	const instagramPost = {
		label: 'Post',
		value: 0,
		fillerColor: colors.dataLibrary.charts.color1,
		containerColor: colors.dataLibrary.charts.color1Light,
	};

	const instagramReel = {
		label: 'Reel',
		value: 0,
		fillerColor: colors.dataLibrary.charts.color2,
		containerColor: colors.dataLibrary.charts.color2Light,
	};

	const instagramStory = {
		label: 'Story',
		value: 0,
		fillerColor: colors.dataLibrary.charts.color3,
		containerColor: colors.dataLibrary.charts.color3Light,
	};

	const tiktokVideo = {
		label: 'Tiktok',
		value: 0,
		fillerColor: colors.dataLibrary.charts.color4,
		containerColor: colors.dataLibrary.charts.color4Light,
	};

	if (!fetchData) {
		isLoading = false;
		total = 12300 + 25450 + 23440 + 19000;
		instagramPost.value = 12300;
		instagramStory.value = 25450;
		tiktokVideo.value = 23440;
		instagramReel.value = 19000;
	} else {
		const { result, error } = fetchData(manager, dashboardFilter);
		if (error) {
			toast.error(getErrorMessageOnFetch('Error fetching data'));
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			total = result.total;
			instagramPost.value = result.types.get(INSTAGRAM_POST)?.value || 0;
			instagramStory.value = result.types.get(INSTAGRAM_STORY)?.value || 0;
			tiktokVideo.value = result.types.get(TIKTOK_VIDEO)?.value || 0;
			instagramReel.value = result.types.get(INSTAGRAM_REEL)?.value || 0;
		}
	}

	return (
		<ChartCard chartId={chartId} title={title} chartType={chartType} chartOnDashboard={chartOnDashboard}>
			<Styled.InnerWrapper>
				{isLoading ? (
					<LoadingSpinner size='sm' />
				) : (
					<Styled.Total
						onMouseEnter={() => {
							setDisplayRealValue(true);
						}}
						onMouseLeave={() => {
							setDisplayRealValue(false);
						}}
					>
						Total {displayRealValue ? total : formatNumber(total, true)}
					</Styled.Total>
				)}
				{displayBar && (
					<>
						{instagramPost.value > 0 && <BarItemContainer item={instagramPost} total={total} isFetching={isLoading} />}
						{instagramStory.value > 0 && <BarItemContainer item={instagramStory} total={total} isFetching={isLoading} />}
						{instagramReel.value > 0 && <BarItemContainer item={instagramReel} total={total} isFetching={isLoading} />}
						{tiktokVideo.value > 0 && <BarItemContainer item={tiktokVideo} total={total} isFetching={isLoading} />}
					</>
				)}
				{displayCircle && <CircleChart total={total} items={[instagramPost, instagramStory, instagramReel, tiktokVideo]} />}
			</Styled.InnerWrapper>
			{chartExplanation && <ExplanationAccordition chartTitle={title} chartExplanation={chartExplanation} />}
		</ChartCard>
	);
};

export default ByAssignmentType;
