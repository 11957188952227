import AudienceAgeFilter from './Components/AgeFilter/AudienceAgeFilter';
import InfluencerAgeFilter from './Components/AgeFilter/InfluencerAgeFilter';
import BrandAffiliationsFilter from './Components/BrandAffiliationsFilter';
import AudienceCountryFilter from './Components/CountryFilter/AudienceCountryFilter';
import InfluencerCountryFilter from './Components/CountryFilter/InfluencerCountryFilter';
import EngagementFilter from './Components/EngagementFilter/EngagementFilter';
import FollowersFilter from './Components/FollowersFilter/FollowersFilter';
import AudienceGenderFilter from './Components/GenderFilter/AudienceGenderFilter';
import InfluencerGenderFilter from './Components/GenderFilter/InfluencerGenderFilter';
import HashtagsFilter from './Components/HashtagsFilter';
import Styled from './FilterDrawer.style';

const FilterDrawer = () => {
	return (
		<Styled.Wrapper>
			<Styled.InnerWrapper>
				{/* Influencer */}
				<Styled.FilterItemWapper>
					<InfluencerAgeFilter />
				</Styled.FilterItemWapper>
				<Styled.FilterItemWapper>
					<InfluencerGenderFilter />
				</Styled.FilterItemWapper>
				<Styled.FilterItemWapper>
					<InfluencerCountryFilter />
				</Styled.FilterItemWapper>
				<Styled.FilterItemWapper>
					<FollowersFilter />
				</Styled.FilterItemWapper>
				<Styled.FilterItemWapper>
					<EngagementFilter />

					{/* Content */}
				</Styled.FilterItemWapper>
				<Styled.FilterItemWapper>
					<BrandAffiliationsFilter />
				</Styled.FilterItemWapper>
				<Styled.FilterItemWapper>
					<HashtagsFilter />
				</Styled.FilterItemWapper>

				{/* Audience */}
				<Styled.FilterItemWapper>
					<AudienceAgeFilter />
				</Styled.FilterItemWapper>
				<Styled.FilterItemWapper>
					<AudienceGenderFilter />
				</Styled.FilterItemWapper>
				<Styled.FilterItemWapper>
					<AudienceCountryFilter />
				</Styled.FilterItemWapper>
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default FilterDrawer;
