import Icon from 'components/Icon';
import LinkAsGhostButton from 'components/LinkAsGhostButton/LinkAsGhostButton';

import Styled from './JoinedCampaign.style';

const JoinedCampaign = () => {
	return (
		<Styled.Container>
			<div>
				<Styled.IconContainer>
					<Icon name='celebrate' size='28' />
				</Styled.IconContainer>
				<h2>Thanks for joining!</h2>
				<p>We’re really excited to have you as part of this campaign. You’ll find your TO DO:s on your dashboard. </p>
			</div>
			<LinkAsGhostButton to='/influencer/dashboard'>Go to your dashboard</LinkAsGhostButton>
		</Styled.Container>
	);
};

export default JoinedCampaign;
