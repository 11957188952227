import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgDLVtr(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps} fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.5 9C10.9853 9 13 6.98528 13 4.5C13 2.01472 10.9853 0 8.5 0C6.01472 0 4 2.01472 4 4.5C4 6.98528 6.01472 9 8.5 9ZM8.5 7.5C10.1569 7.5 11.5 6.15685 11.5 4.5C11.5 2.84315 10.1569 1.5 8.5 1.5C6.84315 1.5 5.5 2.84315 5.5 4.5C5.5 6.15685 6.84315 7.5 8.5 7.5Z'
				fill='#333333'
			/>
			<path
				d='M2.7305 14.2781C1.70826 15.5322 1.4397 17.0778 1.5138 18.1853L1.51546 18.2102V19.2332C1.51546 19.6466 1.17914 19.9817 0.764267 19.9817C0.349394 19.9817 0.0130735 19.6466 0.0130735 19.2332V18.2592C-0.0740588 16.8733 0.255816 14.9396 1.56431 13.3342C2.90356 11.6911 5.17869 10.5006 8.76131 10.5H8.76374C11.3896 10.5 12.8699 10.9937 14.2493 11.9971C14.5843 12.2409 14.6577 12.7092 14.4131 13.0431C14.1685 13.377 13.6985 13.4501 13.3634 13.2063C12.2935 12.4281 11.1426 11.9971 8.7638 11.9971C5.53711 11.9971 3.72976 13.0521 2.7305 14.2781Z'
				fill='#333333'
			/>
			<path
				d='M16.5 13C14.5693 13 13 14.5693 13 16.5C13 18.4307 14.5693 20 16.5 20C18.4307 20 20 18.4307 20 16.5C20 14.5693 18.4307 13 16.5 13ZM16.5 19.5116C14.8395 19.5116 13.4884 18.1605 13.4884 16.5C13.4884 14.8395 14.8395 13.5 16.5 13.5C18.1605 13.5 19.5116 14.8395 19.5116 16.5C19.5116 18.1605 18.1605 19.5116 16.5 19.5116Z'
				fill='#333333'
			/>
			<path
				d='M15.9855 14.4613C15.9085 14.3948 15.7965 14.3808 15.7055 14.4228C15.6145 14.4648 15.555 14.5593 15.555 14.6608V18.1608C15.555 18.2623 15.6145 18.3533 15.7055 18.3988C15.7405 18.4163 15.779 18.4233 15.8175 18.4233C15.877 18.4233 15.9365 18.4023 15.9855 18.3638L18.0855 16.6418C18.145 16.5928 18.18 16.5193 18.18 16.4423C18.18 16.3653 18.145 16.2918 18.0855 16.2393L15.9855 14.4613ZM16.08 17.6078V15.2278L17.508 16.4353L16.08 17.6078Z'
				fill='#333333'
			/>
			<path d='M15.6392 17.1671L15.8083 16.8743L16.7157 17.3981L16.5466 17.6909L15.6392 17.1671Z' fill='#333333' />
			<path d='M15.623 16.3764L15.7921 16.0836L17.313 16.9617L17.1439 17.2545L15.623 16.3764Z' fill='#333333' />
			<path d='M15.6985 15.6392L15.8675 15.3464L17.7715 16.4457L17.6024 16.7385L15.6985 15.6392Z' fill='#333333' />
			<path
				d='M15.9856 14.4612C15.9086 14.3947 15.7966 14.3807 15.7056 14.4227C15.6146 14.4647 15.5551 14.5592 15.5551 14.6607V18.1607C15.5551 18.2622 15.6146 18.3532 15.7056 18.3987C15.7406 18.4162 15.7791 18.4232 15.8176 18.4232C15.8771 18.4232 15.9366 18.4022 15.9856 18.3637L18.0856 16.6417C18.1451 16.5927 18.1801 16.5192 18.1801 16.4422C18.1801 16.3652 18.1451 16.2917 18.0856 16.2392L15.9856 14.4612ZM16.0801 17.6077V15.2277L17.5081 16.4352L16.0801 17.6077Z'
				fill='#333333'
			/>
			<path d='M15.6394 17.167L15.8084 16.8742L16.7158 17.3981L16.5467 17.6909L15.6394 17.167Z' fill='#333333' />
			<path d='M15.6231 16.3763L15.7922 16.0835L17.3131 16.9616L17.144 17.2544L15.6231 16.3763Z' fill='#333333' />
			<path d='M15.6986 15.6392L15.8677 15.3464L17.7716 16.4456L17.6025 16.7384L15.6986 15.6392Z' fill='#333333' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.5 19.3C18.0464 19.3 19.3 18.0464 19.3 16.5C19.3 14.9536 18.0464 13.7 16.5 13.7C14.9536 13.7 13.7 14.9536 13.7 16.5C13.7 18.0464 14.9536 19.3 16.5 19.3ZM16.5 20C18.433 20 20 18.433 20 16.5C20 14.567 18.433 13 16.5 13C14.567 13 13 14.567 13 16.5C13 18.433 14.567 20 16.5 20Z'
				fill='#333333'
			/>
		</svg>
	);
}
