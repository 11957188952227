import styled, { DefaultThemeV2 } from 'styled-components';

type T = { theme: DefaultThemeV2 };

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	width: 100%;
	font-size: ${(props: T) => props.theme.fontSizes.small};
	border-radius: ${(props) => props.theme.radius.small};
	color: ${(props: T) => props.theme.colors.error};
	background-color: ${(props: T) => props.theme.colors.errorBackground};
	padding: 0.5rem;

	&.is-warning {
		background-color: ${(props: T) => props.theme.colors.warningBackground};
		color: ${(props: T) => props.theme.colors.warning};
	}

	span {
		margin-top: 2px;
	}
	.icon path {
		fill: ${(props: T) => props.theme.colors.toasts.error.color};
	}
`;

export default { Wrapper };
