import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { breakpoints } from 'styles/variables/media-queries';

const cm = colors.contentManagement;

const Wrapper = styled.div`
	background-color: ${({ theme }) => theme.threeCol.sidebarBackground};
	transition:
		transform 0.2s ease-in-out,
		opacity 0.4s ease-in-out;
	transform: translateX(100%);
	height: 0;
	opacity: 0;
	&.visible {
		padding: 1rem;
		transform: translateX(0);
		opacity: 1;
		height: auto;
		width: 100%;
	}
`;

const InnerWrapper = styled.div`
	width: 100%;
	overflow-y: auto;
	height: 100%;
	overflow-y: auto;
	margin-bottom: 17px;
`;

const ListWrapper = styled.ul`
	padding: 0;
	list-style-type: none;
	height: 50vh;
	overflow-y: auto;
`;

const NoInfluencersText = styled.li`
	text-align: center;
	font-weight: 700;
	font-size: 0.875rem;
`;

const FilterTitle = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	span {
		margin-right: 8px;
	}
`;

const FilterItem = styled.li`
	cursor: pointer;
	padding: 0.2rem;
	border-radius: ${borderRadius.s};
	&:hover {
		background: ${cm.campaignList.listItem.hover.backgroundColor};
		color: ${cm.campaignList.listItem.hover.color};
		.icon path {
			fill: ${cm.campaignList.listItem.hover.color};
		}
	}
	&.iconUpdate {
		&:hover {
			background: ${cm.campaignList.listItem.hover.backgroundColor};
			color: ${cm.campaignList.listItem.hover.color};
			.icon path {
				stroke: ${cm.campaignList.listItem.hover.color};
				fill: none;
			}
		}
	}
	&.selected {
		font-weight: 700;
	}
`;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
`;

const InfluencersTitle = styled.div`
	margin-top: 40px;
	margin-bottom: 16px;
	font-weight: 600;
	display: flex;
	gap: 8px;
	align-items: center;
`;

const AssignmentsTitle = styled.div`
	margin-left: 16px;
	margin-bottom: 16px;
	font-weight: 600;
	display: flex;
	gap: 8px;
	align-items: center;
`;

const Divider = styled.div`
	@media (max-width: ${breakpoints.sm}) {
		margin: 4px 0 4px 60px;
		height: 1px;
		border-bottom: 1px solid ${colors.contentManagement.campaignList.listItem.borderColor};
	}
`;

const SearchInputWrapper = styled.div`
	padding-bottom: 1rem;
`;

const Styled = {
	Wrapper,
	InnerWrapper,
	ListWrapper,
	NoInfluencersText,
	FilterTitle,
	FilterItem,
	IconContainer,
	InfluencersTitle,
	Divider,
	SearchInputWrapper,
	AssignmentsTitle,
};

export default Styled;
