import { useEffect } from 'react';

export default function useUnsavedChangesWarning(condition: boolean, text?: string, cb?: () => void) {
	useEffect(() => {
		const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
			if (condition) {
				e.preventDefault();
				e.returnValue = text || true;
				cb && cb();
			}
		};

		window.addEventListener('beforeunload', beforeUnloadHandler);
		return () => {
			window.removeEventListener('beforeunload', beforeUnloadHandler);
		};
	}, [condition]);
}
