import styled from 'styled-components';

import newColors from 'styles/theme/colors';

const ModalDeleteIconContainer = styled.div`
	height: 32px;
	width: 32px;
	min-width: 32px;
	min-height: 32px;
	background: ${newColors.rose};
	border-radius: ${({ theme }) => theme.radius.small};
	display: flex;
	justify-content: center;
	align-items: center;
	.icon {
		margin-top: 2px;
	}
`;

const Content = styled.div`
	padding: 2rem 0 0;
`;

const Styled = {
	ModalDeleteIconContainer,
	Content,
};

export default Styled;
