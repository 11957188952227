// src/services/Mercure/mercure.service.ts

import { inject, injectable } from 'inversify';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Dispatch } from 'redux';

import AuthenticationManager from 'services/ApiManager/Authentication.manager';
import BrowserStorage from 'shared/helpers/BrowserStorage/BrowserStorage';

import { TokenStorage } from './TokenStorage';
import { MercureMessage, ServiceIdentifier } from './types';

import type MessageHandlerInterface from './MessageHandlerInterface';

/**
 * This service sets up a connection to the Mercure hub and listens for new messages.
 * It also handles errors, re-connects etc.
 *
 * This service is stateful.
 */
@injectable()
class MercureService {
	private eventSource: EventSource | null = null;
	private tokenExpireTimeout: number | null = null;
	private reconnectTimeout: number | null = null;
	private manager: AuthenticationManager;
	private tokenStorage: TokenStorage;
	private dispatchFn: Dispatch | null = null;
	private handler: MessageHandlerInterface;

	constructor(
		@inject(AuthenticationManager) authenticationManager: AuthenticationManager,
		@inject(ServiceIdentifier.MercureMessageHandler) handler: MessageHandlerInterface,
		private hubUrl: string = 'https://mercure.collabs.app/.well-known/mercure',
	) {
		this.manager = authenticationManager;
		const storage = new BrowserStorage();
		this.tokenStorage = new TokenStorage(storage);
		this.handler = handler;
	}

	/**
	 * Start the subscription to the Mercure hub.
	 */
	public async startSubscription(dispatch: Dispatch): Promise<void> {
		this.dispatchFn = dispatch;

		const token = await this.verifyToken();
		this.subscribe(token);
	}

	/**
	 * Stop the subscription to the Mercure hub.
	 */
	public stopSubscription = () => {
		this.dispatchFn = null;
		this.clearToken();
		this.cancelTimeouts();

		if (this.eventSource !== null) {
			this.eventSource.close();
			this.eventSource = null;
		}
	};

	private subscribe = (token?: string | null | undefined) => {
		const storedToken = token ?? this.tokenStorage.getMercureToken();
		if (!storedToken) {
			console.error('No Mercure token available. Cannot subscribe.');
			return;
		}

		const url = new URL(this.hubUrl);
		url.searchParams.append('authorization', storedToken);
		const decodedToken: JwtPayload = jwtDecode(storedToken);

		// @ts-ignore
		for (const topic of decodedToken.mercure?.subscribe ?? []) {
			url.searchParams.append('topic', topic);
		}

		this.eventSource = new EventSource(url.toString());

		this.eventSource.onmessage = (event) => {
			try {
				const eventData = JSON.parse(event.data);
				if (eventData.type !== undefined && typeof eventData.attributes === 'object') {
					this.handleNewMercureMessage(eventData);
				}
			} catch (parseError) {
				console.error('Failed to parse Mercure message:', parseError);
			}
		};

		this.eventSource.onerror = (error) => {
			if (this.eventSource?.readyState === EventSource.CLOSED) {
				// Connection was closed, try reconnect
				this.tryReconnect();
			} else {
				// Handle other errors, e.g., network issues
				console.error('Other error:', error);
				this.tryReconnect();
			}
		};
	};

	private handleNewMercureMessage = (event: MercureMessage) => {
		if (this.dispatchFn === null) {
			return;
		}
		this.handler.handle(event, this.dispatchFn);
	};

	/**
	 * Get token from Collabs API
	 */
	public requestNewMercureToken = async (): Promise<string | null> => {
		try {
			const response = await this.manager.createMercureToken();
			const token = response.attributes.token;
			this.tokenStorage.setMercureToken(token);
			this.expirationTimer(token);

			return token;
		} catch (error) {
			console.error('Error getting Mercure token: ', error);
			return null;
		}
	};

	private tryReconnect = () => {
		if (this.reconnectTimeout !== null) {
			clearTimeout(this.reconnectTimeout);
		}

		this.reconnectTimeout = window.setTimeout(() => {
			this.subscribe();
		}, 15_000);
	};

	private cancelTimeouts = () => {
		if (this.tokenExpireTimeout !== null) {
			clearTimeout(this.tokenExpireTimeout);
			this.tokenExpireTimeout = null;
		}

		if (this.reconnectTimeout !== null) {
			clearTimeout(this.reconnectTimeout);
			this.reconnectTimeout = null;
		}
	};

	private expirationTimer = (token: string | null) => {
		if (!token) {
			return;
		}

		const decodedToken: JwtPayload = jwtDecode(token);

		if (decodedToken.exp === undefined) {
			console.error('No expiration date found in token');
			return;
		}

		const timeoutAfterMs = decodedToken.exp * 1000 - Date.now() - 60_000; // 60 seconds before expiration

		this.tokenExpireTimeout = window.setTimeout(async () => {
			const newToken = await this.requestNewMercureToken();
			this.subscribe(newToken);
		}, timeoutAfterMs);
	};

	/**
	 * Make sure token is valid. If not, try to get a new one.
	 */
	private verifyToken = async (): Promise<string | null> => {
		const storedToken = this.tokenStorage.getMercureToken();
		if (!storedToken) {
			return await this.requestNewMercureToken();
		} else {
			try {
				const decodedToken = jwtDecode<JwtPayload>(storedToken);

				// If the token is expired, request a new one
				if (this.isTokenExpired(decodedToken)) {
					return await this.requestNewMercureToken();
				}
			} catch (error) {
				console.error('Error decoding token:', error);
				return await this.requestNewMercureToken();
			}
		}

		return storedToken;
	};

	private clearToken = (): void => {
		this.tokenStorage.clearMercureToken();
	};

	private isTokenExpired = (token: JwtPayload): boolean => {
		const currentTimeStamp = Math.floor(Date.now() / 1000);

		if (token.exp === undefined) {
			return true;
		}

		// If it is soon to expire (within 200 seconds)
		return token.exp < currentTimeStamp + 200;
	};
}

export default MercureService;
