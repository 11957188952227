import classNames from 'classnames';
import { useContext } from 'react';

import Accordion from 'components/Accordion';
import Checkbox from 'components/Checkbox';
import Styled from 'components/Discovery/Components/FilterDrawer/FilterDrawer.style';
import { getTheCountryData } from 'components/Discovery/Components/FilterDrawer/helpers';
import DiscoveryContext from 'contexts/Discovery';

const AudienceCountryFilter = () => {
	const updateCountryHandler = useContext(DiscoveryContext).updateCountryHandler;
	const filter = useContext(DiscoveryContext).filter;

	const audienceCountryIsSelected = (country: string) => {
		if (!filter?.audienceCountries) {
			return false;
		}
		return filter?.audienceCountries.includes(country);
	};

	return (
		<Accordion title='Audience countries' className={classNames({ active: filter?.audienceCountries })}>
			<Styled.AccordionInnerWrapper className='countries-list'>
				{Object.keys(getTheCountryData()).map((item) => {
					return (
						<div className='checkbox-container' key={item}>
							<Checkbox
								className='textFirst'
								key={item}
								label={item}
								checked={audienceCountryIsSelected(item)}
								onChange={() => {
									updateCountryHandler(item, 'audienceCountries');
								}}
							/>
						</div>
					);
				})}
			</Styled.AccordionInnerWrapper>
		</Accordion>
	);
};

export default AudienceCountryFilter;
