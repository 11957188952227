import { inject, injectable } from 'inversify';

import { PaymentPrePaymentModel } from 'api-models';
import { ListPrePaymentInvoicesQuery } from 'api-queries';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';

import type PaymentApiClientInterface from 'services/ApiClient/PaymentApiClientInterface';

@injectable()
class PaymentManager extends ApiManager<ModelRepository<PaymentPrePaymentModel>> {
	private readonly client: PaymentApiClientInterface;

	public constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.PaymentApiClientInterface) client: PaymentApiClientInterface,
	) {
		super(cacheManager, new ModelRepository<PaymentPrePaymentModel>('payment'));
		this.client = client;
	}

	public listPrepaymentInvoices(id: string) {
		const key = `listPrepaymentInvoices::${id}`;
		const fetcher = () => {
			return Promise.all([this.client.listPreInvoices(id, RequestQueryBuilder.create<ListPrePaymentInvoicesQuery>().toQuery())]);
		};

		return this.swr(key, fetcher, { multipleApiResponses: true });
	}
}

export default PaymentManager;
