import { InfluencerListFolderModel, InfluencerListListItemModel, InfluencerListListModel } from 'api-models';
import EmptyImg from 'assets/img/empty-state-folder.svg';
import { EmptyState } from 'components';
import Styled from 'components/ErrorPages/PageNotFound.style';
import FolderList from 'components/InfluencerManagement/Folders/List/ListFolders';
import Lists from 'components/InfluencerManagement/Lists/List/ListLists';
import { FileType, Filter } from 'components/InfluencerManagement/types';
import Tabs from 'components/Tabs/Tabs';
import { MutateFn } from 'services/ApiManager/types';

type TablesProps = {
	mutate: MutateFn;
	isLoading: boolean;

	selectedTab: FileType;
	setSelectedTab: (selectedTab: FileType) => void;
	selectedFilter: Filter;
	setSelectedFilter: (selectedFilter: Filter) => void;
	setSidebarIsOpen: (setSidebarIsOpen: boolean) => void;

	folders: Array<InfluencerListFolderModel>;
	folderToEdit: InfluencerListFolderModel | null;
	listToEdit: InfluencerListListModel | null;
	setFolderToShare: (InfluencerFolderItem: InfluencerListFolderModel | null) => void;

	lists: Array<InfluencerListListModel>;
	setListToEdit: (list: InfluencerListListModel | null) => void;
	setFolderToEdit: (folder: InfluencerListFolderModel | null) => void;
	setListToShare: (InfluencerListItem: InfluencerListListModel | null) => void;
	itemsFromListFn: (list: InfluencerListListModel) => Array<InfluencerListListItemModel>;
};

/**
 * Tables with lists and folders.
 * @returns {JSX.Element}
 */
const Tables = ({
	mutate,
	isLoading,
	folders,
	folderToEdit,
	setListToEdit,
	listToEdit,
	lists,
	selectedFilter,
	setSelectedFilter,
	itemsFromListFn,
	setSidebarIsOpen,
	setFolderToShare,
	setListToShare,
	setFolderToEdit,
	setSelectedTab,
	selectedTab,
}: TablesProps): JSX.Element => {
	const getLists = () => {
		switch (selectedFilter) {
			case Filter.ALL:
				return lists;
			case Filter.PRIVATE:
				return lists.filter((list: InfluencerListListModel) => !list.attributes.shared && list.attributes.createdByMe);
			case Filter.SHARED:
				return lists.filter((list: InfluencerListListModel) => list.attributes.shared && list.attributes.createdByMe);
			case Filter.SHARED_WITH_ME:
				return lists.filter((list: InfluencerListListModel) => list.attributes.shared && !list.attributes.createdByMe);
		}
	};

	const getFolders = () => {
		switch (selectedFilter) {
			case Filter.ALL:
				return folders;
			case Filter.PRIVATE:
				return folders.filter((folder: InfluencerListFolderModel) => !folder.attributes.shared && folder.attributes.createdByMe);
			case Filter.SHARED:
				return folders.filter((folder: InfluencerListFolderModel) => folder.attributes.shared && folder.attributes.createdByMe);
			case Filter.SHARED_WITH_ME:
				return folders.filter((folder: InfluencerListFolderModel) => folder.attributes.shared && !folder.attributes.createdByMe);
		}
	};

	return (
		<Styled.TablesWrapper>
			<Tabs
				tabs={[
					{ title: FileType.ALL, amount: `${getLists().length + getFolders().length}`, disabled: false },
					{ title: FileType.FOLDERS, testId: 'tab-Lists', amount: `${getFolders().length}`, disabled: getFolders().length === 0 },
					{ title: FileType.LISTS, testId: 'tab-Folders', amount: `${getLists().length}`, disabled: getLists().length === 0 },
				]}
				selectedTab={selectedTab}
				setSelectedTab={(tab: string) => setSelectedTab(tab as FileType)}
			/>
			{!isLoading && lists.length + folders.length === 0 ? (
				<EmptyState title='Create a new folder or list' text='' img={EmptyImg} />
			) : (
				<>
					{(selectedTab === FileType.ALL || selectedTab === FileType.FOLDERS) && (
						<FolderList
							setSelectedTab={setSelectedTab}
							setSelectedFilter={setSelectedFilter}
							mutateFn={mutate}
							isLoading={isLoading}
							folders={getFolders()}
							setListToShare={setListToShare}
							setSidebarIsOpen={setSidebarIsOpen}
							setFolderToShare={setFolderToShare}
							folderToEdit={folderToEdit}
							setFolderToEdit={setFolderToEdit}
						/>
					)}
					{(selectedTab === FileType.ALL || selectedTab === FileType.LISTS) && (
						<Lists
							setSelectedFilter={setSelectedFilter}
							setSelectedTab={setSelectedTab}
							isLoading={isLoading}
							lists={getLists()}
							setFolderToShare={setFolderToShare}
							setListToEdit={setListToEdit}
							itemsFromListFn={itemsFromListFn}
							setSidebarIsOpen={setSidebarIsOpen}
							setListToShare={setListToShare}
							listToEdit={listToEdit}
						/>
					)}
				</>
			)}
		</Styled.TablesWrapper>
	);
};

export default Tables;
