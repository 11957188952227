import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { InfluencerModel } from 'api-models';
import { getInfluencerOptions } from 'components/DataLibrary/AllRequests/DataFilterOptions';
import FilterSectionAccordionItem from 'components/DataLibrary/FilterSection/FilterSectionAccordionItem';
import { FilterOptionProps } from 'components/DataLibrary/FilterSection/types';
import useInjection from 'hooks/useInjection';
import InfluencerManager from 'services/ApiManager/Influencer.manager';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboardFilterInfluencers } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const InfluencerOptions = (props: FilterOptionProps): JSX.Element => {
	const [selectedInfluencers, setSelectedInfluencers] = useState<Array<string>>([]);
	const [clearThisFilter, setClearThisFilter] = useState<boolean>(false);

	const manager = useInjection<InfluencerManager>(InfluencerManager);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dispatch = useAppDispatch();

	let influencers: InfluencerModel[] = [];
	let isSearching = true;

	if (dashboard.id) {
		const { result, error } = getInfluencerOptions(manager, dashboardFilter);
		if (error) {
			console.error(error.message);
		}
		isSearching = !result;
		if (result) {
			influencers = result;
		}
	}

	const onSelect = (id: string) => {
		props.getClearFilter(false);
		setClearThisFilter(false);
		if (selectedInfluencers.includes(id)) {
			const update = selectedInfluencers.filter((i) => i !== id);
			setSelectedInfluencers(update);
		} else {
			setSelectedInfluencers([...selectedInfluencers, id]);
		}
	};

	useEffect(() => {
		dispatch(setDashboardFilterInfluencers(selectedInfluencers));
	}, [selectedInfluencers]);

	const checkDashboardFilter = () => {
		if (dashboard.metaData.dashboardFilter) {
			const savedInfluencers = dashboard.metaData.dashboardFilter.influencers ?? [];
			if (!isEqual(savedInfluencers, selectedInfluencers)) {
				setSelectedInfluencers(savedInfluencers);
			}
		} else {
			setSelectedInfluencers([]);
		}
	};
	useEffect(() => {
		checkDashboardFilter();
	}, [props.dashboardId, dashboard]);

	useEffect(() => {
		checkDashboardFilter();

		return () => {
			setSelectedInfluencers([]);
			setClearThisFilter(false);
		};
	}, []);

	useEffect(() => {
		if (clearThisFilter || props.clearFilter) {
			setSelectedInfluencers([]);
		}
	}, [props.clearFilter, clearThisFilter]);

	return (
		<FilterSectionAccordionItem
			setClearThisFilter={setClearThisFilter}
			title={<>Influencer {selectedInfluencers?.length > 0 && <>({selectedInfluencers?.length})</>} </>}
			label={'Search for influencers'}
			placeholder={'Ex influencer name'}
			data={influencers.map((influencer: InfluencerModel) => ({
				id: influencer.id,
				name: influencer.attributes.username ?? '',
				imageUrl: influencer.attributes?.profilePictureUrl,
			}))}
			selectedData={selectedInfluencers}
			onSelect={onSelect}
			isSearching={isSearching}
		/>
	);
};
export default InfluencerOptions;
