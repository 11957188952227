import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { onSearchFeatureRequest } from 'actions/current-user';
import Header from 'components/Header';
import ImpersonateBar from 'components/ImpersonateBar';
import LoadingSpinner from 'components/LoadingSpinner';
import { isImpersonating } from 'services/auth-service';
import { updateUser } from 'shared/User/User.helpers';

export const mapDispatchToProps = (dispatch) => ({
	onRefetch: () => dispatch(onSearchFeatureRequest()),
});

const LoggedInLayout = (props) => {
	useEffect(() => {
		// Poll /me to ensure that the user always has the latest user object
		updateUser().then(() => {});
		const meInterval = setInterval(async () => {
			await updateUser();
		}, 30 * 60000);

		props.onRefetch();

		return () => clearInterval(meInterval);
	}, []);

	return (
		<div className='layout main-content'>
			<Header menuItems={props.sidebarMenuItems} location={props.location} fixed={true} />
			{isImpersonating() && <ImpersonateBar />}
			<div className='organization-layout'>{props.children}</div>
		</div>
	);
};

export default connect(null, mapDispatchToProps)(LoggedInLayout);
