import { Model } from 'json-api-models';
import { isEmpty } from 'lodash';
import 'react-loading-skeleton/dist/skeleton.css'; // eslint-disable-line import/no-unassigned-import

import LoadingSpinner from 'components/LoadingSpinner';

import { DirectConversationData } from './DirectConversationData';
import DirectConversationItem from './DirectConversationItem';
import Styled from './DirectConversationList.style';

type Props = {
	directConversations: Model[];
	selectedDirectConversation: DirectConversationData | null;
	onClick: () => void;
	isOpen: boolean;
	onSelect: (directConversation: DirectConversationData) => void;
	isCampaignListOpen: boolean;
	isInfluencer?: boolean;
	campaignItemsLength: number;
	isFetching: boolean;
};

const DirectConversationList = ({ directConversations, selectedDirectConversation, onSelect, isInfluencer, isFetching }: Props) => {
	return (
		<Styled.Wrapper data-testid='inbox-direct-message-list-wrapper'>
			{isFetching ? (
				<Styled.SpinnerWrapper>
					<LoadingSpinner />
				</Styled.SpinnerWrapper>
			) : (
				<Styled.ListWrapper>
					{isEmpty(directConversations) && (
						<Styled.NoResults>
							<Styled.NoResultsText>You have no message yet.</Styled.NoResultsText>
						</Styled.NoResults>
					)}
					{directConversations
						?.map((conversation) => {
							return new DirectConversationData(conversation);
						})
						.map((directConversation) => {
							const isSelected = selectedDirectConversation ? selectedDirectConversation.id === directConversation.id : false;
							return (
								<DirectConversationItem
									key={directConversation.id}
									title={isInfluencer ? directConversation.creatorInitials : directConversation.name}
									coverImage={isInfluencer ? directConversation.creatorProfilePictureUrl : directConversation.profilePictureUrl}
									onClick={(e: React.MouseEvent) => {
										e.stopPropagation();
										onSelect(directConversation);
									}}
									isSelected={isSelected}
									conversation={directConversation}
									hasUnreadMessages={directConversation.hasUnreadMessages}
								/>
							);
						})}
				</Styled.ListWrapper>
			)}
		</Styled.Wrapper>
	);
};

export default DirectConversationList;
