import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { InfluencerListListModel } from 'api-models';
import { UpdateInfluencerListPayload } from 'api-payloads';
import { InputText } from 'components/Form/FormikElements';
import { UpdateListProps } from 'components/InfluencerManagement/Lists/types';
import useInjection from 'hooks/useInjection';
import InfluencerListManager from 'services/ApiManager/InfluencerList.manager';
import toast from 'services/Toast';

const validationSchema = yup.object({
	name: yup.string().defined('Name your list').min(1),
});

type FolderDraft = yup.InferType<typeof validationSchema>;

/**
 * Update lists component
 * @param ListsProps
 * @returns {JSX.Element}
 */
const UpdateList = ({ onSaved, item, forwardRef }: UpdateListProps): JSX.Element => {
	const manager = useInjection<InfluencerListManager>(InfluencerListManager);

	const initialValues: FolderDraft = {
		name: item?.attributes.name ?? '',
	};

	function handleSubmit(item: InfluencerListListModel, payload: UpdateInfluencerListPayload) {
		const placeholder = structuredClone(item);
		placeholder.attributes.name = payload.name ?? '';

		return manager
			.updateList(item.id, payload, undefined, {
				optimisticModel: placeholder,
			})
			.then(() => {
				toast.success(`List ${payload.name} saved`);
				onSaved();
			})
			.catch((error) => {
				console.error('Error saving the list', error);
			});
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values) => {
				return handleSubmit(item!, values);
			}}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{() => (
				<Form>
					<InputText
						autoFocus
						className='display-error-message-after'
						onClick={(e: React.MouseEvent) => e.stopPropagation()}
						forwardRef={forwardRef}
						name='name'
						id='form-folder-input-name'
					/>
				</Form>
			)}
		</Formik>
	);
};

export default UpdateList;
