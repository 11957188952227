import { RouteObject } from 'react-router-dom';

import PageNotFound from 'components/ErrorPages/PageNotFound';
import LoginView from 'views/login/';
import LoginForgotView from 'views/login/ForgotPasswordView';
import LoginResetView from 'views/login/ResetPasswordView';

/**
 * Login Routes
 * Routes for the login/recover
 * @returns {Array<RouteObject>}
 */
const routes: Array<RouteObject> = [
	{
		path: '',
		children: [
			{ index: true, element: <LoginView /> },
			{ path: 'forgot', element: <LoginForgotView /> },
			{ path: 'reset/:token', element: <LoginResetView /> },
		],
	},
	{ path: '*', element: <PageNotFound /> },
];

export default routes;
