/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { UpdateInstagramPostPayload, UploadPostStatisticsScreenshotPayload } from 'api-payloads';
import { ViewInstagramPostQuery, UpdateInstagramPostQuery, UploadPostStatisticsScreenshotQuery } from 'api-queries';
import { InstagramPostResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import InstagramPostApiClientInterface from './InstagramPostApiClientInterface';

@injectable()
export default class InstagramPostApiClient implements InstagramPostApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * View instagram post
	 */
	async view(id: string, params: ViewInstagramPostQuery): Promise<InstagramPostResponse> {
		const response = await this.client.get<InstagramPostResponse>(`/instagram-posts/${id}`, { params });
		return response.data;
	}

	/**
	 * Update instagram post
	 */
	async update(id: string, payload: UpdateInstagramPostPayload, params: UpdateInstagramPostQuery): Promise<InstagramPostResponse> {
		const response = await this.client.patch<InstagramPostResponse>(`/instagram-posts/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * Upload statistics screenshot
	 */
	async uploadPostStatisticsScreenshot(
		id: string,
		payload: UploadPostStatisticsScreenshotPayload,
		params: UploadPostStatisticsScreenshotQuery,
	): Promise<InstagramPostResponse> {
		const payloadForm = new FormData();
		payloadForm.set('file', payload);

		const response = await this.client.post<InstagramPostResponse>(`/instagram-posts/${id}/statistics-screenshots`, payloadForm, {
			...{ params },
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return response.data;
	}

	/**
	 * Delete statistics screenshot
	 */
	async deleteStatisticsScreenshot(instagramPostId: string, instagramPostScreenshotId: string): Promise<void> {
		const response = await this.client.delete<void>(`/instagram-posts/${instagramPostId}/statistics-screenshots/${instagramPostScreenshotId}`);
		return response.data;
	}
}
