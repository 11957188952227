import { Model } from 'json-api-models';

import { conversationMetaData } from 'shared/Types/Campaign/ConversationMetaData';

export type FileItem = {
	id: string;
	uuid?: string;
	originalName: string;
	extension: string;
	isLoading: boolean;
	createdAt: string;
	links: {
		createDownload: string;
	};
};

export type Attachment = {
	id: string;
	files: Array<File>;
};

export type PresignObject = {
	key: string;
	url: string;
	userUpload: string;
	file: File;
};

export class IntegratedInboxCampaign {
	id: string;
	shortId: string;
	name: string;
	coverImage: string;
	brand: Model;
	conversationMetaData: conversationMetaData;
	latestMessage: Model;
	campaign: Model;
	status: string;

	constructor(campaign: Model) {
		this.id = campaign.id;
		this.shortId = campaign.shortId;
		this.name = campaign.name;
		this.coverImage = campaign.links.smallCoverPhoto;
		this.brand = campaign.brand;
		this.conversationMetaData = campaign.conversationMetaData;
		this.latestMessage = campaign.latestMessage;
		this.campaign = campaign;
		this.status = campaign.status;
	}
}

export enum TabKey {
	JOINED = 'Joined',
	NOT_JOINED = 'Not joined',
}
