import Styled from './Header.style';
import { IHeader } from './types';

const Header = (props: IHeader) => {
	return (
		<Styled.Wrapper
			fontSize={props.fontSize}
			display={props.display}
			justifyContent={props.justifyContent}
			alignItems={props.alignItems}
			margin={props.margin}
			className='card__header'
		>
			{props.children}
		</Styled.Wrapper>
	);
};

export default Header;
