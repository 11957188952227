import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgArrowUp(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.5375 15.5207C18.9209 16.1598 17.9212 16.1598 17.3046 15.5207L10 7.95053L2.69543 15.5207C2.07881 16.1598 1.07908 16.1598 0.462462 15.5207C-0.154154 14.8817 -0.154154 13.8456 0.462462 13.2066L8.88352 4.47928C9.50013 3.84024 10.4999 3.84024 11.1165 4.47928L19.5375 13.2066C20.1542 13.8456 20.1542 14.8817 19.5375 15.5207Z'
				fill='#333'
			/>
		</svg>
	);
}
