import { useContext, useEffect, useState } from 'react';

import { InfluencerModel, PublisherModel } from 'api-models';
import { ListPublishersQuery } from 'api-queries';
import Avatar from 'components/Avatar';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input/Input';
import Textarea from 'components/Forms/Textarea/Textarea';
import SelectMenu from 'components/SelectMenu';
import DiscoveryContext from 'contexts/Discovery';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import useInjection from 'hooks/useInjection';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import Styled from './MessageContent.style';

/**
 * @todo test
 */
const MessageContent = (props: {
	onSend: (subject: string, message: string) => Promise<boolean>;
	onCloseModal: () => void;
	messageTargetInfluencer?: InfluencerListItemType | undefined;
	influencer?: InfluencerModel;
	isList?: boolean;
	getMessage: (subject: string | null, message: string, publisherId: string | null) => void;
}) => {
	const { messageTargetInfluencer: contextMessageTargetInfluencer } = useContext(DiscoveryContext);
	const [publisher, setPublisher] = useState<PublisherModel | null>(null);

	const [message, setMessage] = useState<string>('');
	const [subject, setSubject] = useState<string | null>(null);

	useEffect(() => {
		props.getMessage(subject, message, publisher?.id ?? null);
	}, [message, subject, publisher]);

	const manager = useInjection<PublisherManager>(PublisherManager);
	const { repository } = manager.listPublishers(RequestQueryBuilder.create<ListPublishersQuery>());

	const publishers = repository.findAll('publisher');

	const activePublishers = publishers.filter((publisher) => !publisher.attributes?.disabledAt);

	const renderInfluencerTag = (influencer: InfluencerListItemType | InfluencerModel, isModel: boolean = false) => {
		const imageUrl = isModel
			? (influencer as InfluencerModel).attributes.profilePictureUrl ?? ''
			: (influencer as InfluencerListItemType).profileImageUrl || (influencer as InfluencerListItemType).links?.profilePictureUrl || '';
		const username = isModel ? (influencer as InfluencerModel).attributes.username ?? '' : (influencer as InfluencerListItemType).username;

		return (
			<Styled.SelectedInfluencerTag key={influencer.id}>
				<Avatar imageUrl={imageUrl} name={username} size='md' />
				<div className='name'>{username}</div>
			</Styled.SelectedInfluencerTag>
		);
	};

	const selectedInfluencer = props.messageTargetInfluencer || props.influencer || contextMessageTargetInfluencer;

	return (
		<>
			{selectedInfluencer && (
				<Styled.SelectedInfluencers>
					<label>To</label>
					{props.messageTargetInfluencer && renderInfluencerTag(props.messageTargetInfluencer)}
					{props.influencer && renderInfluencerTag(props.influencer, true)}
					{!props.messageTargetInfluencer && !props.influencer && contextMessageTargetInfluencer && renderInfluencerTag(contextMessageTargetInfluencer)}
				</Styled.SelectedInfluencers>
			)}
			{activePublishers.length > 1 ? (
				<Styled.SelectWrapper>
					<Field label='Select organization'>
						<SelectMenu
							placeholder='Select organization'
							onChange={(option) => {
								setPublisher(option?.value);
							}}
							options={publishers.map((publisher: PublisherModel) => {
								return { value: publisher, label: publisher.attributes.name };
							})}
							value={{ value: publisher, label: publisher?.attributes.name }}
						/>
					</Field>
				</Styled.SelectWrapper>
			) : null}

			{activePublishers.length > 1 && !publisher ? null : (
				<Styled.MessageWrapper>
					<Field label='Subject' disabled={activePublishers.length > 1 && !publisher}>
						<Input
							placeholder='Optional'
							onChange={(e) => setSubject(e.target.value)}
							value={subject ?? undefined}
							disabled={activePublishers.length > 1 && !publisher}
						/>
					</Field>
					<Field label='Message' disabled={activePublishers.length > 1 && !publisher}>
						<Textarea
							disabled={activePublishers.length > 1 && !publisher}
							placeholder='Write your message here...'
							rows={10}
							onChange={(e) => setMessage(e.target.value)}
							value={message}
							data-testid='blast-message-textarea'
						/>
					</Field>
					<Styled.HelpText>Message will be sent as an email and show up in their messages if they already have an account.</Styled.HelpText>
				</Styled.MessageWrapper>
			)}
		</>
	);
};

export default MessageContent;
