import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'hooks/useUserAppSelector';
import { getHomePath, isConnected } from 'reducers/UserReducers/helpers';
import ReferralUrlService from 'services/ReferralUrlService';

import LoginView from './LoginView';

const Login = (): JSX.Element => {
	const navigate = useNavigate();

	const isLoggedIn = useAppSelector(isConnected);
	const homePath = useAppSelector(getHomePath);

	useEffect(() => {
		// Redirect to home if already logged in
		if (isLoggedIn) {
			ReferralUrlService.redirect(navigate, homePath);
		}
	}, [isLoggedIn]);

	return <LoginView />;
};

export default Login;
