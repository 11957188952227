import { DeleteFolderProps } from 'components/InfluencerManagement/Folders/types';
import DeleteModal from 'components/Modals/DeleteModal';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import InfluencerListManager from 'services/ApiManager/InfluencerList.manager';
import toast from 'services/Toast';

/**
 * Delete folder component
 * @param DeleteFolderProps
 * @returns {JSX.Element}
 */
const DeleteFolder = ({ itemToDelete, isDeleteModalOpen, setIsDeleteModalOpen }: DeleteFolderProps): JSX.Element => {
	const manager = useInjection<InfluencerListManager>(InfluencerListManager);

	const deleteItem = () => {
		if (itemToDelete) {
			manager
				.deleteFolder(itemToDelete.id)
				.then(() => {
					setIsDeleteModalOpen(false);
					toast.success('Folder deleted');
				})
				.catch((error) => {
					setIsDeleteModalOpen(true);
					toast.error(getSomethingWentWrongMessage());
					console.error('Error updating folder', error);
				});
		}
	};

	return (
		<DeleteModal
			title={`Delete folder: ${itemToDelete?.attributes.name}`}
			isModalOpen={isDeleteModalOpen}
			toggleModal={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
			action={deleteItem}
		>
			<p>Make sure this folder doesn’t contain any folders or lists you wish to keep.</p>
		</DeleteModal>
	);
};

export default DeleteFolder;
