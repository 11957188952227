import styled from 'styled-components';

import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';

const cm = colors.contentManagement;

const Wrapper = styled.section`
	padding: 1rem;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	min-height: 46px;

	div:last-child {
		margin-left: auto;
	}
`;

const Media = styled.div`
	position: relative;
	border: 1px solid ${newColors.skyBlue};
	filter: drop-shadow(0px 2px 4px rgba(51, 51, 51, 0.1));
	border-radius: 10px;
	height: 240px;
	overflow: hidden;
`;

const Image = styled.div<{ imageUrl: string }>`
	position: absolute;
	top: 0;
	left: 0;
	background: url(${(p) => p.imageUrl});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
`;

const ItemIndex = styled.span`
	font-size: 1.2rem;
	font-weight: 700;
	color: ${cm.card.indexColor};
`;

const PendingMedia = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	min-height: 144px;
	text-align: center;
	background-color: ${cm.pendingMedia.backgroundColor};
	padding: 1rem;

	&.error {
		align-items: center;
		flex-direction: column;

		p {
			margin: 0;
		}

		.icon {
			margin-bottom: 24px;
			svg path {
				fill: #ff474e;
			}
		}
	}
`;

const CustomDropdown = styled(Dropdown)`
	.icon {
		path {
			fill: ${colors.gray7};
		}
	}
`;

const DropdownItemIcon = styled(Icon)`
	margin-right: 8px;
`;

const ShowFullSizeButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	position: absolute;
	bottom: 10px;
	right: 10px;
	background-color: ${colors.contentReview.fullscreenButtonBackground};
	cursor: pointer;
	border-radius: 2px;
	padding: 0.5rem;

	.icon {
		line-height: 0;

		svg path {
			fill: ${colors.white};
		}
	}
`;

const ArrowButtons = styled.div`
	margin-left: 8px;
`;

const ArrowButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding: 0.25rem;

	.icon {
		display: inline-block;
		line-height: 0;
		rotate: -90deg;
	}
`;

const Iframe = styled.iframe`
	width: 100%;
	height: 100%;
`;

const Video = styled.video`
	height: auto;
	width: 100%;
	max-height: 213px;
`;

const Styled = {
	Wrapper,
	Header,
	Media,
	Image,
	ItemIndex,
	PendingMedia,
	CustomDropdown,
	DropdownItemIcon,
	ShowFullSizeButton,
	ArrowButtons,
	ArrowButton,
	Iframe,
	Video,
};

export default Styled;
