import styled from 'styled-components';

import { scrollbarY } from 'styles/utils/scrollbar';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const Container = styled.div`
	padding: 2rem;
	padding-bottom: 5rem;

	&.inDataLibrary {
		height: calc(100vh - 200px);
		${scrollbarY};
	}
`;

const SpinnerWrapper = styled.div`
	justify-content: center;
	width: 100%;
	display: flex;
	padding-top: 2rem;
`;

const Title = styled.div`
	font-family: ${typography};
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 1.4;
	letter-spacing: 0;
	text-align: left;
`;

const EmptyState = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	button {
		width: 40%;
	}
	.empty-state {
		text-align: center;
		width: 50%;
		.image-wrapper img {
			margin-top: 48px;
			margin-bottom: ${guttersWithRem.xl};
			max-width: 480px;
		}
		.strong {
			font-weight: 700;
			margin-top: ${guttersWithRem.m};
			margin-bottom: ${guttersWithRem.m};
		}
		.other-text {
			font-size: 0.875rem;
			margin-bottom: ${guttersWithRem.l};
		}
	}
`;

const Styled = {
	SpinnerWrapper,
	Container,
	Title,
	EmptyState,
};

export default Styled;
