type ReferralUrlMapping = {
	name: string;
	url: string;
};

const referralUrlMapping: Array<ReferralUrlMapping> = [{ name: 'marketplace', url: '/affiliate/marketplace' }];

export const getReferralUrlByName = (name: string) => {
	return referralUrlMapping.find((x) => x.name === name)?.url;
};
