import styled from 'styled-components';

import colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

import { IButtonProps } from './types';

const theme = colors.button;
const typograph = typography.button;

const Button = styled.button<IButtonProps>`
	font-family: ${typograph.fontFamiliy};
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 8px;
	height: 44px;
	padding: ${(props) => (props.size === 'sm' ? '0.25rem 0.5rem' : props.size === 'lg' ? '0.625rem 1.5rem' : '0.438rem 1rem')};
	border: solid 2px ${theme.primary.borderColor};
	background-color: ${theme.primary.background};
	cursor: pointer;
	font-size: ${(props) => (props.size === 'sm' ? typograph.small.fontSize : props.size === 'lg' ? typograph.large.fontSize : typograph.medium.fontSize)};
	color: ${theme.primary.color};
	transition:
		background-color 150ms ease-in-out,
		color 150ms ease-in-out,
		border-color 150ms ease-in-out;
	border-radius: 4px;
	&:hover:not(:disabled, .disabled) {
		background-color: ${theme.primary.backgroundHover};
		color: ${theme.primary.colorHover};
	}

	&.disabled,
	&:disabled {
		border-color: ${theme.primary.disabled.borderColor};
		color: ${theme.primary.disabled.color};
		cursor: default;
		path {
			fill: ${theme.primary.disabled.color};
		}
	}

	.icon {
		display: inherit;
		width: auto;
		height: auto;
		path {
			transition: fill 150ms ease-in-out;
		}
	}
`;

const SecondaryButton = styled(Button)`
	border-color: ${theme.sencondary.borderColor};
	color: ${theme.sencondary.color};

	&:hover:not(:disabled) {
		background-color: ${theme.sencondary.backgroundHover};
		color: ${theme.sencondary.colorHover};
	}
	.disabled,
	&:disabled {
		border-color: ${theme.sencondary.borderColor};
		color: ${theme.sencondary.disabled.color};
	}
`;

const ButtonSecondary = styled(Button)`
	background-color: ${theme.secondary.borderColor};
	border-color: ${theme.secondary.borderColor};
	color: ${theme.secondary.color};

	&:hover:not(:disabled) {
		background-color: ${theme.secondary.backgroundHover};
		border-color: ${theme.secondary.borderColorHover};
		color: ${theme.secondary.colorHover};
		.icon path {
			fill: ${theme.secondary.colorHover};
		}
	}
	.disabled,
	&:disabled {
		border-color: ${theme.secondary.borderColor};
		color: ${theme.secondary.disabled.color};
	}
`;

const DarkButton = styled(Button)`
	background-color: ${theme.dark.background};
	color: ${theme.dark.color};
	.icon {
		path {
			fill: ${theme.dark.color};
		}
	}
	&.disabled,
	&:disabled {
		background-color: ${theme.dark.disabled.background};
		color: ${theme.dark.disabled.color};
		border-color: ${theme.dark.disabled.borderColor};

		svg > * {
			fill: ${theme.primary.disabled.color};
		}
	}
`;

const GhostButton = styled(Button)`
	min-height: 44px;
	background-color: ${theme.ghost.background};
	font-family: ${typography.BaseFontFamiliy};
	&:hover:not(:disabled, .disabled) {
		color: ${theme.primary.colorHover} !important;
		.icon {
			path {
				fill: ${theme.primary.colorHover};
			}
			rect {
				stroke: ${theme.primary.colorHover};
			}
		}
	}
`;

const ButtonContent = styled.span`
	display: flex;
	align-items: center;
`;

const LinkButton = styled(Button)`
	background-color: transparent;
	border-color: transparent;

	&:hover:not(:disabled, .disabled) {
		border-color: #333;
		background-color: transparent;
		color: ${theme.primary.color};
	}

	&.disabled,
	&:disabled {
		border-color: transparent;
	}
`;

const BlueButton = styled(Button)`
	background-color: #104cb6;
	border-color: transparent;
	color: #fff;

	&:hover:not(:disabled, .disabled) {
		border-color: #333;
		background-color: transparent;
		color: ${theme.primary.color};
	}

	&.disabled,
	&:disabled {
		background-color: #f5f5f5;
		border-color: transparent;
		color: #888888;
	}
`;

const Styled = {
	Button,
	SecondaryButton,
	ButtonSecondary,
	DarkButton,
	GhostButton,
	ButtonContent,
	LinkButton,
	BlueButton,
};

export default Styled;
