import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { CampaignModel } from 'api-models';
import { getCampaignOptions } from 'components/DataLibrary/AllRequests/DataFilterOptions';
import FilterSectionAccordionItem from 'components/DataLibrary/FilterSection/FilterSectionAccordionItem';
import { FilterOptionProps } from 'components/DataLibrary/FilterSection/types';
import useInjection from 'hooks/useInjection';
import CampaignManager from 'services/ApiManager/Campaign.manager';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboardFilterCampaigns } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const CampaignOptions = (props: FilterOptionProps): JSX.Element => {
	const [selectedCampaigns, setSelectedCampaigns] = useState<Array<string>>([]);
	const [clearThisFilter, setClearThisFilter] = useState<boolean>(false);

	const manager = useInjection<CampaignManager>(CampaignManager);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dispatch = useAppDispatch();

	let campaigns: CampaignModel[] = [];
	let isSearching = true;

	if (dashboard.id) {
		const { result, error } = getCampaignOptions(manager, dashboardFilter);
		if (error) {
			console.error(error.message);
		}
		isSearching = !result;
		if (result) {
			campaigns = result;
		}
	}

	const onSelect = (id: string) => {
		props.getClearFilter(false);
		setClearThisFilter(false);
		if (selectedCampaigns.includes(id)) {
			const update = selectedCampaigns.filter((i) => i !== id);
			setSelectedCampaigns(update);
		} else {
			setSelectedCampaigns([...selectedCampaigns, id]);
		}
	};

	useEffect(() => {
		dispatch(setDashboardFilterCampaigns(selectedCampaigns));
	}, [selectedCampaigns]);

	const checkDashboardFilter = () => {
		if (dashboard.metaData.dashboardFilter) {
			const savedCampaigns = dashboard.metaData.dashboardFilter.campaigns ?? [];
			if (!isEqual(savedCampaigns, selectedCampaigns)) {
				setSelectedCampaigns(savedCampaigns);
			}
		} else {
			setSelectedCampaigns([]);
		}
	};

	useEffect(() => {
		checkDashboardFilter();
	}, [props.dashboardId, dashboard]);

	useEffect(() => {
		checkDashboardFilter();
		return () => {
			setSelectedCampaigns([]);
			setClearThisFilter(false);
		};
	}, []);

	useEffect(() => {
		if (clearThisFilter || props.clearFilter) {
			setSelectedCampaigns([]);
		}
	}, [props.clearFilter, clearThisFilter]);

	return (
		<FilterSectionAccordionItem
			setClearThisFilter={setClearThisFilter}
			title={<>Campaign {selectedCampaigns?.length > 0 && <>({selectedCampaigns?.length})</>} </>}
			label={'Search for campaigns'}
			placeholder={'Ex Campaign 1'}
			data={campaigns.map((item) => ({
				id: item.id,
				name: item.attributes.name,
			}))}
			selectedData={selectedCampaigns}
			onSelect={onSelect}
			isSearching={isSearching}
		/>
	);
};
export default CampaignOptions;
