import { isNil } from 'lodash';
import { InputHTMLAttributes, WheelEvent } from 'react';

import Icon from 'components/Icon';

import Styled from './NumberInput.style';

type Props = {
	onChangeWithButton: (name: string, value: number) => void;
	unit: string;
	name: string;
};

const NumberInput = ({ id, name, value, onChange, onChangeWithButton, min, max, unit, onBlur, step }: InputHTMLAttributes<HTMLInputElement> & Props) => {
	return (
		<Styled.Wrapper>
			<Styled.InputFieldContainer>
				<Styled.IconWrapper
					type='button'
					data-testid='minus-button'
					onClick={() => {
						if (!isNil(value) && !isNil(min) && value > min) {
							onChangeWithButton(name!, +(+value - 0.01).toFixed(2));
						}
					}}
				>
					<Icon name='minus' size='10' />
				</Styled.IconWrapper>

				<Styled.InputWrapper>
					<Styled.InputField
						role='textbox'
						type='number'
						data-testid={id}
						name={name}
						value={value}
						onChange={onChange}
						onWheel={(e: WheelEvent<HTMLInputElement>) => {
							(e.target as HTMLInputElement).blur();
						}}
						min={Number(min).toFixed(2)}
						max={Number(max)}
						onBlur={onBlur}
						step={step}
					/>
					<span>{unit}</span>
				</Styled.InputWrapper>

				<Styled.IconWrapper
					type='button'
					data-testid='plus-button'
					onClick={() => {
						if (!isNil(value) && !isNil(max) && value < max) {
							onChangeWithButton(name, +(+value + 0.01).toFixed(2));
						}
					}}
				>
					<Icon name='plus' size='10' />
				</Styled.IconWrapper>
			</Styled.InputFieldContainer>
		</Styled.Wrapper>
	);
};

export default NumberInput;
