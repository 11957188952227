/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import {
	UploadCampaignCoverPhotoPayload,
	CreateCampaignPayload,
	AddInfluencersToAssignmentGroupPayload,
	UpdateCampaignInstagramOwnerAssignmentPayload,
	CreateInstagramPostPayload,
	CreateInstagramReelPayload,
	CreateInstagramStoryPayload,
	CreateTiktokPayload,
	UpdateCommissionPayload,
	AddInfluencersToCommissionPayload,
	UpdateCampaignGroupPayload,
	AddInfluencersToCampaignGroupPayload,
	UpdateCampaignImagePayload,
	CreateCampaignInvitePayload,
	ViewAdtractionAffiliateLinkPostPayload,
	UpdateProductPayload,
	UploadProductImagePayload,
	UpdateCampaignPayload,
	CreateCommissionPayload,
	CreateCampaignGroupPayload,
	UploadCampaignImagePayload,
	AddInfluencersToCampaignPayload,
	CreateCampaignInvitesPayload,
	CreateProductPayload,
} from 'api-payloads';
import {
	ListCampaignInvitesQuery,
	ListCampaignInvitesLegacyQuery,
	ListCampaignsQuery,
	CreateCampaignQuery,
	ListFeaturedCampaignsQuery,
	UpdateCampaignInstagramOwnerAssignmentQuery,
	CreateInstagramPostQuery,
	CreateInstagramReelQuery,
	CreateInstagramStoryQuery,
	CreateTiktokQuery,
	UpdateCommissionQuery,
	CreateCampaignInviteQuery,
	ViewCampaignInviteQuery,
	UpdateProductQuery,
	ViewCampaignQuery,
	UpdateCampaignQuery,
	CampaignListAssignmentReviewsQuery,
	ListCommissionsQuery,
	CreateCommissionQuery,
	ListContentsQuery,
	ListInstagramOwnersQuery,
	ListProductsQuery,
	CreateProductQuery,
} from 'api-queries';
import {
	CampaignCoverPhotoCollectionResponse,
	CampaignCoverPhotoResponse,
	CampaignInviteListItemCollectionResponse,
	CampaignCollectionResponse,
	CampaignResponse,
	CampaignsSummaryResponse,
	AssignmentCampaignInstagramOwnerAssignmentResponse,
	InstagramPostResponse,
	InstagramStoryResponse,
	CampaignCommissionResponse,
	CampaignGroupResponse,
	CampaignInviteResponse,
	LinkResponse,
	CampaignProductResponse,
	AssignmentReviewCollectionResponse,
	CampaignCommissionSummaryResponse,
	CampaignCommissionCollectionResponse,
	CampaignContentCollectionResponse,
	CampaignGroupCollectionResponse,
	CampaignImageCollectionResponse,
	CampaignInstagramOwnerCollectionResponse,
	CampaignProductCollectionResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import CampaignApiClientInterface from './CampaignApiClientInterface';

@injectable()
export default class CampaignApiClient implements CampaignApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List cover photos
	 */
	async listCoverPhotos(params: { include?: string }): Promise<CampaignCoverPhotoCollectionResponse> {
		const response = await this.client.get<CampaignCoverPhotoCollectionResponse>(`/campaign-cover-photos`, { params });
		return response.data;
	}

	/**
	 * @deprecated
	 */
	async uploadCoverPhoto(payload: UploadCampaignCoverPhotoPayload, params: { include?: string }): Promise<CampaignCoverPhotoResponse> {
		const payloadForm = new FormData();
		payloadForm.set('file', payload);

		const response = await this.client.post<CampaignCoverPhotoResponse>(`/campaign-cover-photos`, payloadForm, {
			...{ params },
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return response.data;
	}

	/**
	 * List campaign invites
	 */
	async listInvites(params: ListCampaignInvitesQuery): Promise<CampaignInviteListItemCollectionResponse> {
		const response = await this.client.get<CampaignInviteListItemCollectionResponse>(`/campaign-invites`, { params });
		return response.data;
	}

	/**
	 * List campaign invites
	 */
	async listInvitesLegacy(params: ListCampaignInvitesLegacyQuery): Promise<CampaignInviteListItemCollectionResponse> {
		const response = await this.client.get<CampaignInviteListItemCollectionResponse>(`/campaign-invites-20230721`, { params });
		return response.data;
	}

	/**
	 * List campaigns
	 */
	async listCampaigns(params: ListCampaignsQuery): Promise<CampaignCollectionResponse> {
		const response = await this.client.get<CampaignCollectionResponse>(`/campaigns`, { params });
		return response.data;
	}

	/**
	 * Create campaign
	 */
	async create(payload: CreateCampaignPayload, params: CreateCampaignQuery): Promise<CampaignResponse> {
		const response = await this.client.post<CampaignResponse>(`/campaigns`, payload, { params });
		return response.data;
	}

	/**
	 * Delete campaign invite
	 */
	async deleteInvite(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns-invites/${id}`);
		return response.data;
	}

	/**
	 * Campaigns summary
	 */
	async campaignsSummary(params: { include?: string }): Promise<CampaignsSummaryResponse> {
		const response = await this.client.get<CampaignsSummaryResponse>(`/campaigns-summary`, { params });
		return response.data;
	}

	/**
	 * List featured campaigns
	 */
	async listFeaturedCampaigns(params: ListFeaturedCampaignsQuery): Promise<CampaignCollectionResponse> {
		const response = await this.client.get<CampaignCollectionResponse>(`/campaigns/featured`, { params });
		return response.data;
	}

	/**
	 * Add influencers to assignment group
	 */
	async addInfluencersToAssignmentGroup(
		campaignId: string,
		assignmentId: string,
		groupId: string,
		payload: AddInfluencersToAssignmentGroupPayload,
		params: { include?: string },
	): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${campaignId}/assignments/${assignmentId}/groups/${groupId}/influencers`, payload, { params });
		return response.data;
	}

	/**
	 * Remove influencer from assignment
	 */
	async removeInfluencerFromAssignment(campaignId: string, assignmentId: string, influencerId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/assignments/${assignmentId}/influencers/${influencerId}`);
		return response.data;
	}

	/**
	 * Update campaign instagram owner assignment
	 */
	async updateInstagramOwnerAssignment(
		campaignId: string,
		assignmentId: string,
		influencerId: string,
		payload: UpdateCampaignInstagramOwnerAssignmentPayload,
		params: UpdateCampaignInstagramOwnerAssignmentQuery,
	): Promise<AssignmentCampaignInstagramOwnerAssignmentResponse> {
		const response = await this.client.patch<AssignmentCampaignInstagramOwnerAssignmentResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/influencers/${influencerId}`,
			payload,
			{ params },
		);
		return response.data;
	}

	/**
	 * Create instagram post
	 */
	async createInstagramPost(
		campaignId: string,
		assignmentId: string,
		influencerId: string,
		payload: CreateInstagramPostPayload,
		params: CreateInstagramPostQuery,
	): Promise<InstagramPostResponse> {
		const response = await this.client.post<InstagramPostResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/influencers/${influencerId}/instagram-posts`,
			payload,
			{ params },
		);
		return response.data;
	}

	/**
	 * Create instagram reel
	 */
	async createInstagramReel(
		campaignId: string,
		assignmentId: string,
		influencerId: string,
		payload: CreateInstagramReelPayload,
		params: CreateInstagramReelQuery,
	): Promise<InstagramPostResponse> {
		const response = await this.client.post<InstagramPostResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/influencers/${influencerId}/instagram-reels`,
			payload,
			{ params },
		);
		return response.data;
	}

	/**
	 * Create instagram story
	 */
	async createInstagramStory(
		campaignId: string,
		assignmentId: string,
		influencerId: string,
		payload: CreateInstagramStoryPayload,
		params: CreateInstagramStoryQuery,
	): Promise<InstagramStoryResponse> {
		const response = await this.client.post<InstagramStoryResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/influencers/${influencerId}/instagram-stories`,
			payload,
			{ params },
		);
		return response.data;
	}

	/**
	 * Create tiktok
	 */
	async createTiktok(
		campaignId: string,
		assignmentId: string,
		influencerId: string,
		payload: CreateTiktokPayload,
		params: CreateTiktokQuery,
	): Promise<InstagramPostResponse> {
		const response = await this.client.post<InstagramPostResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/influencers/${influencerId}/tiktoks`,
			payload,
			{ params },
		);
		return response.data;
	}

	/**
	 * Set assignment review media presign url as uploaded
	 */
	async setAssignmentReviewMediaPresignUrlAsUploaded(
		campaignId: string,
		assignmentId: string,
		assignmentReviewId: string,
		presignUrlId: string,
		params: { include?: string },
	): Promise<void> {
		const response = await this.client.post<void>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/reviews/${assignmentReviewId}/presign-urls/${presignUrlId}`,
			undefined,
			{ params },
		);
		return response.data;
	}

	/**
	 * Delete commission
	 */
	async deleteCommission(campaignId: string, commissionId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/commissions/${commissionId}`);
		return response.data;
	}

	/**
	 * Update commission
	 */
	async updateCommission(
		campaignId: string,
		commissionId: string,
		payload: UpdateCommissionPayload,
		params: UpdateCommissionQuery,
	): Promise<CampaignCommissionResponse> {
		const response = await this.client.patch<CampaignCommissionResponse>(`/campaigns/${campaignId}/commissions/${commissionId}`, payload, { params });
		return response.data;
	}

	/**
	 * Add influencers to commission
	 */
	async addInfluencersToCommission(
		campaignId: string,
		commissionId: string,
		payload: AddInfluencersToCommissionPayload,
		params: { include?: string },
	): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${campaignId}/commissions/${commissionId}/influencers`, payload, { params });
		return response.data;
	}

	/**
	 * Remove influencer from commission
	 */
	async removeInfluencerFromCommission(campaignId: string, commissionId: string, influencerId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/commissions/${commissionId}/influencers/${influencerId}`);
		return response.data;
	}

	/**
	 * Delete campaign group
	 */
	async deleteGroup(campaignId: string, groupId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/groups/${groupId}`);
		return response.data;
	}

	/**
	 * Update campaign group
	 */
	async updateGroup(campaignId: string, groupId: string, payload: UpdateCampaignGroupPayload, params: { include?: string }): Promise<CampaignGroupResponse> {
		const response = await this.client.patch<CampaignGroupResponse>(`/campaigns/${campaignId}/groups/${groupId}`, payload, { params });
		return response.data;
	}

	/**
	 * Add influencers to campaign group
	 */
	async addInfluencersToGroup(campaignId: string, groupId: string, payload: AddInfluencersToCampaignGroupPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${campaignId}/groups/${groupId}/influencers`, payload, { params });
		return response.data;
	}

	/**
	 * Remove influencer from campaign group
	 */
	async removeInfluencerFromGroup(campaignId: string, groupId: string, influencerId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/groups/${groupId}/influencers/${influencerId}`);
		return response.data;
	}

	/**
	 * Delete campaign image
	 */
	async deleteImage(campaignId: string, imageId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/images/${imageId}`);
		return response.data;
	}

	/**
	 * Update campaign image
	 */
	async updateImage(campaignId: string, imageId: string, payload: UpdateCampaignImagePayload, params: { include?: string }): Promise<void> {
		const response = await this.client.patch<void>(`/campaigns/${campaignId}/images/${imageId}`, payload, { params });
		return response.data;
	}

	/**
	 * Cancel
	 */
	async cancel(campaignId: string, influencerId: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${campaignId}/influencers/${influencerId}/cancel`, undefined, { params });
		return response.data;
	}

	/**
	 * Decline
	 */
	async decline(campaignId: string, influencerId: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${campaignId}/influencers/${influencerId}/decline`, undefined, { params });
		return response.data;
	}

	/**
	 * Dispute
	 */
	async dispute(campaignId: string, influencerId: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${campaignId}/influencers/${influencerId}/dispute`, undefined, { params });
		return response.data;
	}

	/**
	 * Undispute
	 */
	async undispute(campaignId: string, influencerId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/influencers/${influencerId}/dispute`);
		return response.data;
	}

	/**
	 * Create campaign invite
	 */
	async createInvite(
		campaignId: string,
		influencerId: string,
		payload: CreateCampaignInvitePayload,
		params: CreateCampaignInviteQuery,
	): Promise<CampaignInviteResponse> {
		const response = await this.client.post<CampaignInviteResponse>(`/campaigns/${campaignId}/influencers/${influencerId}/invites`, payload, { params });
		return response.data;
	}

	/**
	 * Set as invoiced
	 */
	async setAsInvoiced(campaignId: string, influencerId: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${campaignId}/influencers/${influencerId}/invoiced`, undefined, { params });
		return response.data;
	}

	/**
	 * Join
	 */
	async join(campaignId: string, influencerId: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${campaignId}/influencers/${influencerId}/join`, undefined, { params });
		return response.data;
	}

	/**
	 * View adtraction affiliate link
	 */
	async viewAdtractionAffiliateLinkGet(campaignId: string, influencerId: string, params: { include?: string }): Promise<LinkResponse> {
		const response = await this.client.get<LinkResponse>(`/campaigns/${campaignId}/influencers/${influencerId}/link`, { params });
		return response.data;
	}

	/**
	 * View adtraction affiliate link
	 */
	async viewAdtractionAffiliateLinkPost(
		campaignId: string,
		influencerId: string,
		payload: ViewAdtractionAffiliateLinkPostPayload,
		params: { include?: string },
	): Promise<LinkResponse> {
		const response = await this.client.post<LinkResponse>(`/campaigns/${campaignId}/influencers/${influencerId}/link`, payload, { params });
		return response.data;
	}

	/**
	 * Uncancel
	 */
	async uncancel(campaignId: string, influencerId: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${campaignId}/influencers/${influencerId}/uncancel`, undefined, { params });
		return response.data;
	}

	/**
	 * View campaign invite
	 */
	async viewInvite(campaignId: string, influencerId: string, params: ViewCampaignInviteQuery): Promise<CampaignInviteResponse> {
		const response = await this.client.get<CampaignInviteResponse>(`/campaigns/${campaignId}/invite/${influencerId}/preview`, { params });
		return response.data;
	}

	/**
	 * Delete product
	 */
	async deleteProduct(campaignId: string, productId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/products/${productId}`);
		return response.data;
	}

	/**
	 * Update product
	 */
	async updateProduct(campaignId: string, productId: string, payload: UpdateProductPayload, params: UpdateProductQuery): Promise<CampaignProductResponse> {
		const response = await this.client.patch<CampaignProductResponse>(`/campaigns/${campaignId}/products/${productId}`, payload, { params });
		return response.data;
	}

	/**
	 * Upload product image
	 */
	async uploadProductImage(campaignId: string, productId: string, payload: UploadProductImagePayload, params: { include?: string }): Promise<void> {
		const payloadForm = new FormData();
		payloadForm.set('file', payload);

		const response = await this.client.post<void>(`/campaigns/${campaignId}/products/${productId}/image`, payloadForm, {
			...{ params },
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return response.data;
	}

	/**
	 * Delete product image
	 */
	async deleteProductImage(campaignId: string, productId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/products/${productId}/image`);
		return response.data;
	}

	/**
	 * View campaign
	 */
	async view(id: string, params: ViewCampaignQuery): Promise<CampaignResponse> {
		const response = await this.client.get<CampaignResponse>(`/campaigns/${id}`, { params });
		return response.data;
	}

	/**
	 * Delete campaign
	 */
	async delete(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${id}`);
		return response.data;
	}

	/**
	 * Update campaign
	 */
	async update(id: string, payload: UpdateCampaignPayload, params: UpdateCampaignQuery): Promise<CampaignResponse> {
		const response = await this.client.patch<CampaignResponse>(`/campaigns/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * Set as archived
	 */
	async setAsArchived(id: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${id}/archive`, undefined, { params });
		return response.data;
	}

	/**
	 * List assignment reviews
	 */
	async listAssignmentReviews(id: string, params: CampaignListAssignmentReviewsQuery): Promise<AssignmentReviewCollectionResponse> {
		const response = await this.client.get<AssignmentReviewCollectionResponse>(`/campaigns/${id}/assignment-reviews`, { params });
		return response.data;
	}

	/**
	 * View commission summary
	 */
	async viewCommissionSummary(id: string, params: { include?: string }): Promise<CampaignCommissionSummaryResponse> {
		const response = await this.client.get<CampaignCommissionSummaryResponse>(`/campaigns/${id}/commission-summary`, { params });
		return response.data;
	}

	/**
	 * List commissions
	 */
	async listCommissions(id: string, params: ListCommissionsQuery): Promise<CampaignCommissionCollectionResponse> {
		const response = await this.client.get<CampaignCommissionCollectionResponse>(`/campaigns/${id}/commissions`, { params });
		return response.data;
	}

	/**
	 * Create commission
	 */
	async createCommission(id: string, payload: CreateCommissionPayload, params: CreateCommissionQuery): Promise<CampaignCommissionResponse> {
		const response = await this.client.post<CampaignCommissionResponse>(`/campaigns/${id}/commissions`, payload, { params });
		return response.data;
	}

	/**
	 * List contents
	 */
	async listContents(id: string, params: ListContentsQuery): Promise<CampaignContentCollectionResponse> {
		const response = await this.client.get<CampaignContentCollectionResponse>(`/campaigns/${id}/contents`, { params });
		return response.data;
	}

	/**
	 * Favourite campaign
	 */
	async favourite(id: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${id}/favourite`, undefined, { params });
		return response.data;
	}

	/**
	 * Un favourite campaign
	 */
	async unFavourite(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${id}/favourite`);
		return response.data;
	}

	/**
	 * List campaign groups
	 */
	async listGroups(id: string, params: { include?: string }): Promise<CampaignGroupCollectionResponse> {
		const response = await this.client.get<CampaignGroupCollectionResponse>(`/campaigns/${id}/groups`, { params });
		return response.data;
	}

	/**
	 * Create campaign group
	 */
	async createGroup(id: string, payload: CreateCampaignGroupPayload, params: { include?: string }): Promise<CampaignGroupResponse> {
		const response = await this.client.post<CampaignGroupResponse>(`/campaigns/${id}/groups`, payload, { params });
		return response.data;
	}

	/**
	 * List campaign images
	 */
	async listImages(id: string, params: { include?: string }): Promise<CampaignImageCollectionResponse> {
		const response = await this.client.get<CampaignImageCollectionResponse>(`/campaigns/${id}/images`, { params });
		return response.data;
	}

	/**
	 * @deprecated
	 */
	async uploadImage(id: string, payload: UploadCampaignImagePayload, params: { include?: string }): Promise<void> {
		const payloadForm = new FormData();
		payloadForm.set('file', payload);

		const response = await this.client.post<void>(`/campaigns/${id}/images`, payloadForm, {
			...{ params },
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return response.data;
	}

	/**
	 * Add influencers to campaign
	 */
	async addInfluencersTo(id: string, payload: AddInfluencersToCampaignPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${id}/influencers`, payload, { params });
		return response.data;
	}

	/**
	 * List instagram owners
	 */
	async listInstagramOwners(id: string, params: ListInstagramOwnersQuery): Promise<CampaignInstagramOwnerCollectionResponse> {
		const response = await this.client.get<CampaignInstagramOwnerCollectionResponse>(`/campaigns/${id}/instagram-owners`, { params });
		return response.data;
	}

	/**
	 * Create campaign invites
	 */
	async createInvites(id: string, payload: CreateCampaignInvitesPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/campaigns/${id}/invites`, payload, { params });
		return response.data;
	}

	/**
	 * List products
	 */
	async listProducts(id: string, params: ListProductsQuery): Promise<CampaignProductCollectionResponse> {
		const response = await this.client.get<CampaignProductCollectionResponse>(`/campaigns/${id}/products`, { params });
		return response.data;
	}

	/**
	 * Create product
	 */
	async createProduct(id: string, payload: CreateProductPayload, params: CreateProductQuery): Promise<CampaignProductResponse> {
		const response = await this.client.post<CampaignProductResponse>(`/campaigns/${id}/products`, payload, { params });
		return response.data;
	}
}
