import { Store } from 'json-api-models';
import { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import Campaigns from 'components/InfluencerDashboard/Components/Campaigns/Campaigns';
import NoValueState from 'components/InfluencerDashboard/Components/NoValueState/NoValueState';
import { ActiveCampaign, getActiveCampaign } from 'components/InfluencerDashboard/utils';
import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './CampaignSection.style';

/**
 * @returns {JSX.Element}
 */
const CampaignSection = (props: { campaignStore: Store }): JSX.Element => {
	const [campaigns, setCampaigns] = useState<ActiveCampaign[]>([]);
	const [displaySkeletonLoader, setDisplaySkeletonLoader] = useState<boolean>(false);
	const user = useAppSelector((state) => state.user);

	useEffect(() => {
		if (props.campaignStore) {
			setCampaigns(getActiveCampaign(props.campaignStore, user));
		}
	}, [props.campaignStore]);

	const [selectedInfluencerId, setSelectedInfluencerId] = useState<string>(user.influencer.id);

	useEffect(() => {
		if (user.influencer.id !== selectedInfluencerId) {
			setDisplaySkeletonLoader(true);
			setTimeout(() => {
				setDisplaySkeletonLoader(false);
			}, 2000);
			setSelectedInfluencerId(user.influencer.id);
		}
	}, [user.influencer.id]);

	return (
		<Styled.Wrapper>
			<Styled.HeadingWrapper>
				<div>{displaySkeletonLoader ? <Skeleton width='180px' height='30px' borderRadius='4px' /> : 'Active campaigns'}</div>
			</Styled.HeadingWrapper>
			<Styled.CampaignsWrapper>
				{campaigns.length > 0 ? <Campaigns displaySkeletonLoader={displaySkeletonLoader} campaigns={campaigns} /> : <NoValueState type='no-campaign' />}
			</Styled.CampaignsWrapper>
		</Styled.Wrapper>
	);
};

export default CampaignSection;
