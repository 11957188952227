import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgManualPayments(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M10.3441 9.55337C9.4086 9.2 9.02151 8.94479 9.02151 8.44417C9.02151 8.05153 9.33333 7.6 10.1505 7.6C10.8387 7.6 11.2688 7.81595 11.4946 7.93374L11.7634 7.28589C11.4516 7.11902 11.0108 6.96196 10.3763 6.94233V6H9.70968V6.9816C8.73118 7.12883 8.09677 7.74724 8.09677 8.55215C8.09677 9.35706 8.77419 9.84785 9.86021 10.2405C10.6559 10.535 11.0645 10.8687 11.0645 11.3988C11.0645 11.9288 10.5484 12.3509 9.7957 12.3509C9.19355 12.3509 8.64516 12.1644 8.25806 11.9288L8 12.5865C8.37634 12.8319 9.01075 13.0086 9.63441 13.0184V14H10.3118V12.989C11.4086 12.8221 12 12.1055 12 11.3301C12 10.4663 11.4409 9.95583 10.3441 9.54356V9.55337Z'
				fill='#4D4B4C'
			/>
			<path
				d='M1.39535 10C1.39535 5.24778 5.24778 1.39535 10 1.39535C14.7522 1.39535 18.6047 5.24778 18.6047 10C18.6047 10.3853 18.917 10.6977 19.3023 10.6977C19.6876 10.6977 20 10.3853 20 10C20 4.47715 15.5228 0 10 0C9.30964 0 8.63563 0.0699555 7.98465 0.203164C3.42782 1.13563 0 5.16751 0 10C0 15.5228 4.47715 20 10 20C10.3853 20 10.6977 19.6876 10.6977 19.3023C10.6977 18.917 10.3853 18.6047 10 18.6047C5.24805 18.6047 1.39578 14.7519 1.39535 10C1.39535 9.99974 1.39535 10.0003 1.39535 10Z'
				fill='#4D4B4C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11 15.5001C11 13.02 13.02 11 15.5 11C17.98 11 20 13.02 20 15.5001C20 17.9801 17.98 20.0001 15.5 20.0001C13.02 20.0001 11 17.9801 11 15.5001ZM12.05 15.5001C12.05 17.4001 13.6 18.9501 15.5 18.9501C17.4 18.9501 18.95 17.4001 18.95 15.5001C18.95 13.6 17.4 12.05 15.5 12.05C13.6 12.05 12.05 13.6 12.05 15.5001Z'
				fill='#4D4B4C'
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M12.75 14.75H18.25V16.25H12.75V14.75Z' fill='#4D4B4C' />
		</svg>
	);
}
