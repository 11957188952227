import styled from 'styled-components';

import colors from 'styles/theme/colors';

const Hr = styled.hr`
	border-top: 1px solid ${colors.ash};
	margin: 24px 0;
`;

export default {
	Hr,
};
