import { inject, injectable } from 'inversify';

import GenericApiClient from 'services/ApiClient/GenericApiClient';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import { ApiResponse, ApiSingleResponse, ResourceObject } from 'services/ApiClient/types';
import ResourceManager from 'utils/Repository/ResourceManager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, UpdateConfig } from './types';

@injectable()
class GenericManager extends ApiManager {
	private readonly client: GenericApiClient<ApiResponse>;

	constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.GenericApiClientInterface) client: GenericApiClient<ApiResponse>,
	) {
		super(cacheManager, new ResourceManager());
		this.client = client;
	}

	public get(url: string, queryBuilder = RequestQueryBuilder.create()) {
		const key = `${url}::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.get(url, queryBuilder.toQuery()).then((response) => response.data);

		return this.swr(key, fetcher);
	}

	public create(
		url: string,
		payload: any, // eslint-disable-line @typescript-eslint/no-explicit-any
		queryBuilder = RequestQueryBuilder.create(),
		config: CreateConfig<ResourceObject> = {},
	): Promise<ResourceObject> {
		const promise = this.client.post(url, payload, queryBuilder.toQuery()).then((response) => {
			if (response.data.data === undefined || Array.isArray(response.data.data)) {
				throw new Error('Invalid response. You must not use the Generic Manager for this request.');
			}

			return response.data as ApiSingleResponse;
		});

		return this.createModel<ResourceObject>(promise, config);
	}

	public update(
		url: string,
		payload: any, // eslint-disable-line @typescript-eslint/no-explicit-any
		queryBuilder = RequestQueryBuilder.create(),
		config: UpdateConfig<ResourceObject> = {},
	): Promise<ResourceObject> {
		const promise = this.client.patch(url, payload, queryBuilder.toQuery()).then((response) => {
			if (response.data.data === undefined || Array.isArray(response.data.data)) {
				throw new Error('Invalid response. You must not use the Generic Manager for this request.');
			}

			return response.data as ApiSingleResponse;
		});

		return this.updateModel<ResourceObject>(promise, config);
	}

	public async delete(url: string): Promise<void> {
		// There is nothing cool with SWR here since we don't have resource name or id
		await this.client.delete(url);
	}
}

export default GenericManager;
