import { Store } from 'json-api-models';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';

import type { RootState, AppDispatch } from 'components/CreateCampaign/store/store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// See: https://redux-toolkit.js.org/tutorials/typescript#define-typed-hooks
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function usePresignUrl() {
	const client = createClient();

	const getPresignUrls = async (file: File) => {
		try {
			const presignUrlResponse = await client.post('/user-uploads/presign-urls', { filename: file.name, private: false });
			const model = new Store();
			model.sync(presignUrlResponse.data);
			const presignUrlData = model.findAll('presignUrl')[0];
			return presignUrlData;
		} catch (error) {
			toast.error(`Failed to upload ${file.name}`);
		}
	};

	return {
		getPresignUrls,
	};
}
