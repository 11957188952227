import useModels from 'hooks/useModels';

import { CampaignSection, InvitationSection } from './Components';
import Styled from './InfluencerDashboardContainer.style';
import { getCampaignsUri } from './utils';

/**
 * Influencer dashboard
 * @returns {JSX.Element}
 */
const InfluencerDashboardContainer = (): JSX.Element => {
	const campaignStore = useModels(getCampaignsUri());

	return (
		<Styled.Wrapper>
			<InvitationSection />
			<CampaignSection campaignStore={campaignStore.models} />
		</Styled.Wrapper>
	);
};

export default InfluencerDashboardContainer;
