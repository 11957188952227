import { Bar } from 'react-chartjs-2';

import type { ChartData } from 'chart.js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InfluencerAudienceGraph = (props: { audienceAge: any }) => {
	// Extracting labels and data from props.audienceAge
	const labels = Object.keys(props.audienceAge);
	const dataValues = labels.map((ageGroup) => (props.audienceAge[ageGroup].ratio * 100).toFixed(2)); // Convert ratio to percentage

	// Creating chart data with extracted labels and percentage values
	const data = {
		labels: labels, // Age groups: '13-17', '18-24', etc.
		datasets: [
			{
				label: '', // Dataset label
				data: dataValues, // Corresponding percentages for each age group
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(255, 159, 64, 0.2)',
					'rgba(255, 205, 86, 0.2)',
					'rgba(75, 192, 192, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(153, 102, 255, 0.2)',
					'rgba(201, 203, 207, 0.2)',
				],
				borderColor: [
					'rgb(255, 99, 132)',
					'rgb(255, 159, 64)',
					'rgb(255, 205, 86)',
					'rgb(75, 192, 192)',
					'rgb(54, 162, 235)',
					'rgb(153, 102, 255)',
					'rgb(201, 203, 207)',
				],
				borderWidth: 1,
			},
		],
	};

	return (
		<Bar
			data={data as ChartData<'bar'>}
			width={400}
			height={300}
			options={{
				plugins: {
					legend: {
						display: false,
					},
					datalabels: {
						display: false,
					},
				},
				scales: {
					y: {
						beginAtZero: true,
						ticks: {
							// display: false
							callback: (value) => `${value}%`, // Format y-axis labels to show percentage
							stepSize: 20,
						},
						grid: {
							display: false,
						},
					},
					x: {
						grid: {
							display: false,
						},
					},
				},
			}}
		/>
	);
};

export default InfluencerAudienceGraph;
