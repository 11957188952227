import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const TextAreaWrapper = styled.div`
	padding: 1rem 0 1rem 1rem;
	display: flex;
	flex: 1;
	overflow-y: auto;
`;

const TextArea = styled.textarea`
	border: none;
	color: ${colors.integratedInbox.chatSection.chatInput.color};
	outline: none;
	resize: none;
	width: 100%;
	line-height: 1.5;
	background-color: ${colors.integratedInbox.chatSection.chatInput.background};
`;

const MessageWrapper = styled.div`
	width: 100%;
	min-height: 170px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const MessageBar = styled.div`
	display: flex;
	flex-grow: 1;
	position: relative;
	background-color: ${colors.integratedInbox.chatSection.chatInput.background};
	flex-direction: row;
	border: 1px solid ${newColors.ash};
	border-radius: 10px;

	flex-direction: column;
`;

const MessageBarControlsWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
`;

const Send = styled.button`
	background-color: transparent;
	border: none;
	padding: ${guttersWithRem.xxs};
	border-radius: 4px;
	&:not(:disabled) {
		background-color: ${newColors.paleGreenTint};

		& > span > i > svg > path {
			fill: ${newColors.black};
		}
		cursor: pointer;
	}

	& > span > i {
		display: flex;
	}
`;

const MessageBarControls = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;

	& > div {
		&.notify {
			padding-bottom: 8px;
			align-self: flex-end;
			font-size: 0.875rem;
			color: ${colors.integratedInbox.blastModal.color.label};
			margin-right: ${guttersWithRem.l};
		}
	}
`;

const BottomText = styled.div`
	text-align: end;
	font-size: 0.875rem;
	color: ${colors.integratedInbox.blastModal.color.label};
	margin-top: ${guttersWithRem.xxs};
`;

const HelpText = styled.div`
	text-align: start;
	padding: 1rem 0;
	font-size: 0.875rem;
	line-height: 1.5;
`;

const Styled = {
	HelpText,
	MessageWrapper,
	MessageBar,
	TextAreaWrapper,
	TextArea,
	MessageBarControlsWrapper,
	MessageBarControls,
	Send,
	BottomText,
};

export default Styled;
