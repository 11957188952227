import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgVerifiedUser(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11 15.5001C11 13.02 13.02 11 15.5 11C17.98 11 20 13.02 20 15.5001C20 17.9801 17.98 20.0001 15.5 20.0001C13.02 20.0001 11 17.9801 11 15.5001ZM12.05 15.5001C12.05 17.4001 13.6 18.9501 15.5 18.9501C17.4 18.9501 18.95 17.4001 18.95 15.5001C18.95 13.6 17.4 12.05 15.5 12.05C13.6 12.05 12.05 13.6 12.05 15.5001Z'
				fill='#4CA867'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18 14.4296L15.0962 17.5L13 15.5052L13.8318 14.528L15.0512 15.6884L17.1208 13.5L18 14.4296Z'
				fill='#4CA867'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15 4.5C15 6.98528 12.9853 9 10.5 9C8.01472 9 6 6.98528 6 4.5C6 2.01472 8.01472 0 10.5 0C12.9853 0 15 2.01472 15 4.5ZM13.5 4.5C13.5 6.15685 12.1569 7.5 10.5 7.5C8.84315 7.5 7.5 6.15685 7.5 4.5C7.5 2.84315 8.84315 1.5 10.5 1.5C12.1569 1.5 13.5 2.84315 13.5 4.5Z'
				fill='#4D4B4C'
			/>
			<path
				d='M2.84384 14.0349C3.82134 12.7011 5.47335 11.579 8.25 11.579L10.1603 11.579L10.1658 11.579L10.1677 11.579L10.1698 11.579L10.1716 11.5789L10.25 11.5789C10.6642 11.5789 11 11.2255 11 10.7895C11 10.3535 10.6642 10 10.25 10L10.1087 10L10.1021 10L10.0985 10.0001L10.0975 10.0001L8.25 10.0001C5.02665 10.0001 2.92866 11.3342 1.65616 13.0705C0.410546 14.7701 0 16.792 0 18.1579V19.2105C0 19.6465 0.335786 20 0.75 20C1.16421 20 1.5 19.6465 1.5 19.2105V18.1579C1.5 17.0677 1.83945 15.4054 2.84384 14.0349Z'
				fill='#4D4B4C'
			/>
		</svg>
	);
}
