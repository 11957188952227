import styled from 'styled-components';

import { MidColContainer } from 'styles/layout/three-col';
import colors from 'styles/variables/colors';

const Wrapper = styled(MidColContainer)`
	background-color: ${colors.mainWhite};
	flex: 1;
	position: relative;
	height: 100%;
`;

const Content = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	& > div {
		&.input-wrapper {
			align-self: flex-end;
		}
	}
`;

const ChatInputWrapper = styled.div`
	/* position: absolute;
	bottom: 60px;
	right: 0;
	width: 100%; */
`;

const Styled = {
	Wrapper,
	Content,
	ChatInputWrapper,
};

export default Styled;
