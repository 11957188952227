import NoCampaignSvg from 'assets/icons/influencer-no-campaign.svg';
import Fallback from 'assets/icons/influencer-no-image-fallback.svg';
import NoTodoSvg from 'assets/icons/influencer-to-do.svg';

import Styled from './NoValueState.style';

const NoValueState = (props: { type: 'no-campaign' | 'no-todo' }) => {
	const getAsset = (type: string) => {
		switch (type) {
			case 'no-campaign':
				return NoCampaignSvg;
			case 'no-todo':
				return NoTodoSvg;
			default:
				return Fallback;
		}
	};

	const renderMessage = (type: string) => {
		switch (type) {
			case 'no-campaign':
				return (
					<Styled.TextWrapper>
						<div className='strong'>You have no active campaigns</div>
						<div>All active campaigns will be showed here.</div>
					</Styled.TextWrapper>
				);
			case 'no-todo':
				return (
					<Styled.TextWrapper>
						<div className='strong'>You have no To-do&#39;s</div>
						<div>All incoming To-do&#39;s will be listed here.</div>
					</Styled.TextWrapper>
				);
			default:
				return null;
		}
	};

	return (
		<Styled.Wrapper data-testid='no-value-state'>
			<img src={getAsset(props.type)} alt={props.type} />
			{renderMessage(props.type)}
		</Styled.Wrapper>
	);
};

export default NoValueState;
