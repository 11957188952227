import { StatisticsCountrySummaryModel } from 'api-models';
import { StatisticsAudienceAgeQuery, StatisticsAudienceCountryQuery, StatisticsAudienceGenderQuery } from 'api-queries';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import { ByAgeOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByAge/types';
import { ByCountryOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByCountry/types';
import { ByGenderOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByGender/types';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

export const getAudienceAge = (manager: StatisticsManager, filter: DashboardFilters): { result: ByAgeOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<StatisticsAudienceAgeQuery>(filter);
	const { result, error } = manager.showAudienceAge(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		total: Object.values(result.attributes).reduce((prev: any, current: any) => prev + current, 0),
		ages: Object.keys(result.attributes).map((item: string) => {
			// @ts-ignore
			return { label: item, value: result.attributes[item] };
		}),
	};

	return { result: output };
};

export const getAudienceCountry = (manager: StatisticsManager, filter: DashboardFilters): { result: ByCountryOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<StatisticsAudienceCountryQuery>(filter);
	const { result, error } = manager.listAudienceCountry(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const countries = [];
	for (const country of result) {
		if (country.attributes.followers > 0) {
			countries.push({
				label: country.attributes.name ?? '[no name]',
				value: country.attributes.followers,
			});
		}
	}

	const output = {
		total: result.reduce((prev: number, current: StatisticsCountrySummaryModel) => prev + current.attributes.followers, 0),
		countries: countries,
		currency: undefined,
	};

	return { result: output };
};

export const getAudienceGender = (manager: StatisticsManager, filter: DashboardFilters): { result: ByGenderOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<StatisticsAudienceGenderQuery>(filter);
	const { result, error } = manager.showAudienceGender(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const data = Object.values(result.attributes);
	const labels = Object.keys(result.attributes).map((el) => {
		switch (el.toLocaleLowerCase()) {
			case 'male':
				return 'Men';
			case 'female':
				return 'Women';
			default:
				return 'Unknown';
		}
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const total = data.reduce((prev: number, current: any) => prev + current, 0);
	const output = {
		total: total,
		labels: labels,
		data: data,
	};

	return { result: output };
};
