/**
 * Default global spacing
 * This file keeps the global spacing.
 *
 * USAGE : rem[0-X], px[0-X]
 *
 * rem - for paddings
 * px - for margins etc.
 */
export const rem = [0.25, 0.5, 1, 1.5, 2.5, 5];
export const px = [4, 8, 16, 24, 40, 80];

type Spacing = {
	[key: number]: { rem: string; px: string };
};

/**
 * 	USAGE : spacing[0-X].rem, spacing[0-X].px
 *
 * 	0: { rem: '0.25rem', px: '4px' }
 *
 * 	1: { rem: '0.5rem', px: '8px' },
 *
 * 	2: { rem: '1rem', px: '16px' },
 *
 * 	3: { rem: '1.5rem', px: '24px' },
 *
 * 	4: { rem: '2.5rem', px: '40px' },
 *
 * 	5: { rem: '5rem', px: '80px' },
 */
export const spacing: Spacing = rem.reduce((acc: { [key: number]: { rem: string; px: string } }, value, index) => {
	acc[index] = {
		rem: `${value}rem`,
		px: `${px[index]}px`,
	};
	return acc;
}, {});
