import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import InvitationStatusTag from 'components/InfluencerDashboard/Components/InvitationStatusTag/InvitationStatusTag';
import { InvitationItemProps } from 'components/InfluencerDashboard/types';
import { pathInfluencerCampaignView } from 'routing/PathLookup';
import { formatAmount } from 'utils/formatters';

import Styled from './InvitationItem.style';

/**
 * @param {InvitationItemProps} props
 * @returns {JSX.Element}
 */
const InvitationItem = ({ invitation, displaySkeletonLoader }: InvitationItemProps): JSX.Element => {
	const navigate = useNavigate();

	return displaySkeletonLoader ? (
		<Skeleton width='350px' height='150px' />
	) : (
		<Styled.Wrapper lg={4} md={6} data-testid='invitation'>
			<Styled.InnerWrapper>
				<InvitationStatusTag invitedAt={invitation.createdAt} />
				<Styled.InvitationContentWrapper>
					{invitation.links.smallCoverPhoto && (
						<Styled.CoverImageWrapper>
							<img src={invitation.links.smallCoverPhoto} alt='campaign-cover' />
						</Styled.CoverImageWrapper>
					)}
					<Styled.InvitationContent>
						<div>
							<Styled.Text className='title'>{invitation.campaignName}</Styled.Text>
							<Styled.Text className='brand'>{invitation.brandName}</Styled.Text>
						</div>
						<div>
							{(invitation.isAffiliateCampaign || invitation.commission?.id) && (
								<>
									<Styled.Text className='commission-value'>Commission:</Styled.Text>
									<Styled.Text className='commission-label'>
										{invitation.isAffiliateCampaign
											? 'Performance based'
											: invitation.commission?.id
												? formatAmount(invitation.commission?.fixedAmount, invitation.commission?.fixedAmountCurrency)
												: null}
									</Styled.Text>
								</>
							)}
						</div>
					</Styled.InvitationContent>
				</Styled.InvitationContentWrapper>
				<Styled.GoToBriefButton
					data-testid='view-brief'
					size='sm'
					onClick={() => {
						navigate(pathInfluencerCampaignView(invitation.campaignShortId));
					}}
				>
					Respond to invite
				</Styled.GoToBriefButton>
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default InvitationItem;
