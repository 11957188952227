import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { mediaMaxQueries, mediaQueries } from 'styles/variables/media-queries';

const ThreeColContainer = styled.div`
	.three-col-layout {
		${mediaMaxQueries.xl`
				display: block;
		`};

		position: relative;
		height: 100%;
		min-height: 700px;
		.three-col {
			position: relative;
			display: block;
			overflow: hidden;

			&.overflow-visible {
				overflow: visible !important;
			}

			/* First column */
			&:nth-child(1) {
				position: absolute;
				transition:
					left 200ms ease-in-out,
					visibility 250ms ease-in-out,
					opacity 400ms ease-in-out;
				background-color: ${({ theme }) => theme.threeCol.sidebarBackground};
				left: -450px;
				width: 100%;
				visibility: hidden;
				opacity: 0;
				height: 100vh;
				z-index: 20;
				border-radius: ${borderRadius.m};

				&.active-col {
					left: 0;
				}

				${mediaQueries.xl`
					position: relative;
					left: auto;
					visibility: visible;
					opacity: 1;
					width: auto;
					width: 100%;
					height: 100%;
				`};
			}

			&:nth-child(2) {
				background-color: ${({ theme }) => theme.threeCol.mainBackground};
			}

			/* Last column */
			&:nth-child(3) {
				transition:
					width 200ms ease-in-out,
					visibility 300ms ease-in-out,
					opacity 400ms ease-in-out;
				background-color: ${({ theme }) => theme.threeCol.sidebarBackground};
				box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.1);
				position: absolute;
				top: 0;
				right: -0;
				height: 100vh;
				max-width: 300px;
				width: 0;
				z-index: 20;
				border-radius: ${borderRadius.m};

				.toggler {
					position: absolute;
					background-color: #e3edef;
					left: -22px;
					top: 13px;
					border-top-left-radius: 2px;
					border-bottom-left-radius: 2px;
					cursor: pointer;

					button {
						position: relative;
						background: transparent;
						border: none;
						padding: 4px;
						cursor: pointer;
						width: 24px;
						height: 24px;
					}

					svg {
						pointer-events: none;
					}
				}

				&.active-col {
					right: 0;
					width: 100%;
				}

				${mediaQueries.xl`
					position: static;
					right: auto;
					visibility: visible;
					opacity: 1;
					width: auto;
					max-width: 100%;
					height: 100%;
					box-shadow: none;

					.toggler {
						display: none;
				}
			`};
			}

			&.active-col {
				visibility: visible;
				opacity: 1;
			}
		}
	}
`;

const SidebarNavContainer = styled.div``;

const SidebarNavHeading = styled.div`
	background-color: ${({ theme }) => theme.threeCol.sidebarBackground};
	padding: 1rem;

	h1 {
		font-size: 1rem;
		font-weight: 700;
		line-height: 1;
		margin: 0;
	}
`;

const SidebarNav = styled.ul`
	list-style: none;
	padding-left: 2rem;
`;

const SidebarNavItem = styled.li``;

const MidColContainer = styled.div`
	background-color: ${colors.threeCol.middleColumnBackground};
`;

export { MidColContainer, ThreeColContainer, SidebarNavContainer, SidebarNavHeading, SidebarNav, SidebarNavItem };
