import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgSidebarRightArrow(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps} className='icon-sidebar-right-arrow'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.2 5.2V18.8H18.8V5.2H5.2ZM4.8 4C4.35817 4 4 4.35817 4 4.8V19.2C4 19.6418 4.35817 20 4.8 20H19.2C19.6418 20 20 19.6418 20 19.2V4.8C20 4.35817 19.6418 4 19.2 4H4.8Z'
				fill='#333'
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M10.8 4.4V19.6H10V4.4H10.8Z' fill='#333' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.2512 8.07033C13.5048 7.93786 13.8421 7.998 14.0047 8.20465L16.9138 11.9024C17.0327 12.0536 17.0283 12.2482 16.9026 12.3957L13.9935 15.809C13.8217 16.0106 13.4818 16.0606 13.2344 15.9206C12.987 15.7806 12.9257 15.5036 13.0975 15.302L15.7989 12.1324L13.0863 8.68434C12.9237 8.4777 12.9975 8.20279 13.2512 8.07033Z'
				fill='#333'
			/>
		</svg>
	);
}
