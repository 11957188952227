import EmptyCloud from 'assets/img/empty-cloud.svg';

import Styled from './EmptyState.style';

export type EmptyStateProps = {
	title: string;
	text?: string;
	component?: JSX.Element;
	img?: string;
};
/**
 * Empty state component
 * @returns {JSX.Element}
 */
const EmptyState = ({ title, text, component, img }: EmptyStateProps): JSX.Element => {
	return (
		<Styled.Wrapper>
			<img src={img ?? EmptyCloud} alt='Empty cloud' />
			<h5>{title}</h5>
			{component ?? <p>{text}</p>}
		</Styled.Wrapper>
	);
};

export default EmptyState;
