import classNames from 'classnames';
import { useContext } from 'react';

import Accordion from 'components/Accordion';
import Checkbox from 'components/Checkbox';
import { getGenderLabel } from 'components/Discovery/Components/FilterDrawer/helpers';
import DiscoveryContext from 'contexts/Discovery';

const AudienceGenderFilter = () => {
	const filter = useContext(DiscoveryContext).filter;
	const updateGenderHandler = useContext(DiscoveryContext).updateGenderHandler;

	const audienceGenderSelected = (gender: string) => {
		if (filter?.audienceGenders?.includes('f') && gender === 'female') {
			return true;
		}
		if (filter?.audienceGenders?.includes('m') && gender === 'male') {
			return true;
		}
		return false;
	};

	return (
		<Accordion title='Audience genders' className={classNames({ active: filter?.audienceGenders })}>
			{Object.keys({
				female: false,
				male: false,
			}).map((gender) => {
				return (
					<div className='checkbox-container' key={gender}>
						<Checkbox
							className='textFirst'
							label={getGenderLabel(gender)}
							onChange={() => {
								updateGenderHandler(gender, 'audienceGenders');
							}}
							checked={audienceGenderSelected(gender)}
						/>
					</div>
				);
			})}
		</Accordion>
	);
};

export default AudienceGenderFilter;
