import { StatisticsEngagementModel } from 'api-models';
import { EngagementTotalQuery } from 'api-queries';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import { ByAssignmentTypeOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByAssignmentType/types';
import { TotalOutput } from 'components/DataLibrary/Graphs/GraphComponents/Total/types';
import { INSTAGRAM_POST, INSTAGRAM_REEL, INSTAGRAM_STORY, TIKTOK_VIDEO } from 'constants/data-library.ts';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

export const getEngagementTotal = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<EngagementTotalQuery>(filter);
	const { result, error } = manager.showEngagementTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		value: result.attributes.engagement,
		currency: undefined,
	};

	return { result: output };
};

export const getEngagementByPlatforms = (
	manager: StatisticsManager,
	filter: DashboardFilters,
): { result: ByAssignmentTypeOutput | undefined; error?: Error } => {
	const results = new Map<string, StatisticsEngagementModel | undefined>();
	const errors = new Map<string, Error | undefined>();

	for (const type of [INSTAGRAM_POST, INSTAGRAM_STORY, INSTAGRAM_REEL, TIKTOK_VIDEO]) {
		const qb = convertFilterToQueryBuilder<EngagementTotalQuery>(filter).withFilter('assignmentTypes', type);
		const { result, error } = manager.showEngagementTotal(qb);

		results.set(type, result);
		errors.set(type, error);
	}

	for (const [type, error] of errors) {
		if (results.get(type) === undefined || error) {
			return { result: undefined, error };
		}
	}

	const output: ByAssignmentTypeOutput = {
		total: 0,
		types: new Map<string, { label: string; value: number }>(),
	};

	for (const [type, result] of results) {
		output.total += result?.attributes.engagement || 0;
		output.types.set(type, {
			label: type,
			value: result?.attributes.engagement || 0,
		});
	}

	return { result: output };
};

export const getEngagementShares = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<EngagementTotalQuery>(filter);
	const { result, error } = manager.showEngagementTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		value: result.attributes.shares,
		currency: undefined,
	};

	return { result: output };
};

export const getEngagementSaves = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<EngagementTotalQuery>(filter);
	const { result, error } = manager.showEngagementTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}
	const output = {
		value: result.attributes.saves,
		currency: undefined,
	};

	return { result: output };
};

export const getEngagementLikes = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<EngagementTotalQuery>(filter);
	const { result, error } = manager.showEngagementTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		value: result.attributes.likes,
		currency: undefined,
	};

	return { result: output };
};

export const getEngagementComments = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<EngagementTotalQuery>(filter);
	const { result, error } = manager.showEngagementTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		value: result.attributes.comments,
		currency: undefined,
	};

	return { result: output };
};
