export const FakeData: { name: string; value: number; valueLabel: string; secondValue: number; secondValueLabel: string }[] = [
	{
		name: 'Agricultural Sales Representative',
		valueLabel: 'Reach',
		value: 9401512,
		secondValueLabel: 'Engagement',
		secondValue: 500,
	},
	{
		name: 'Electrical Parts Reconditioner',
		valueLabel: 'Reach',
		value: 4780569,
		secondValueLabel: 'Engagement',
		secondValue: 250,
	},
	{
		name: 'Obstetrician',
		valueLabel: 'Reach',
		value: 11187125,
		secondValueLabel: 'Engagement',
		secondValue: 450,
	},
];
