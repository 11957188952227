import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';

import Styled from 'components/DataLibrary/ContentHeader/ContentHeader.style';
import convertDashboardModelToDashboardType from 'components/DataLibrary/ConvertDashboardModelToDashboardType';
import { CreateDashboardBody, DataLibraryModal } from 'components/DataLibrary/DataLibraryModal';
import Icon from 'components/Icon';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import DashboardHelper from 'services/DataLibrary/DashboardHelper';
import toast from 'services/Toast';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { setDashboards } from 'views/DataLibrary/reducers/DashboardsSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const EditDashboard = (): JSX.Element => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [newDashboardName, setNewDashboardName] = useState<string>('');
	const [isFetching, setIsFetching] = useState<boolean>(false);

	const dispatch = useAppDispatch();
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const manager = useInjection<DataLibraryManager>(DataLibraryManager);
	const dashboardHelper = useInjection<DashboardHelper>(DashboardHelper);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
		setNewDashboardName(dashboard.name);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewDashboardName(e.target.value);
	};

	const cancel = () => {
		setIsModalOpen(!isModalOpen);
		setNewDashboardName('');
	};

	const editDashboardHandler = () => {
		setIsFetching(true);
		manager
			.update(dashboard.id, { name: newDashboardName })
			.then((result) => {
				const { dashboard: updatedDashboard } = convertDashboardModelToDashboardType(manager.getRepository(), result);
				dispatch(setDashboard(updatedDashboard));
				dashboardHelper.getDashboards().then((res) => {
					// To update searchable options
					dispatch(setDashboards(res));
				});
			})
			.catch((err: AxiosError) => {
				toast.error(getErrorMessageOnPost('updating the dashboard'));
				console.error(err.message);
			})
			.finally(() => {
				setIsFetching(false);
				setIsModalOpen(false);
				toast.success(`Name on dashboard changed to: ${newDashboardName}`);
				setNewDashboardName('');
			});
	};

	useEffect(() => {
		return () => {
			setIsModalOpen(false);
			setNewDashboardName('');
		};
	}, []);

	return (
		<>
			<Styled.CustomDropdownItem onClick={() => toggleModal()}>
				<Icon name='pen' size='20' />
				Rename dashboard
			</Styled.CustomDropdownItem>
			<DataLibraryModal
				size='xs'
				title='Edit dashboard'
				isModalOpen={isModalOpen}
				toggleModal={toggleModal}
				footer={
					<>
						<button className='cancel' onClick={cancel}>
							Cancel
						</button>
						<button className='create' onClick={editDashboardHandler}>
							Save
						</button>
					</>
				}
			>
				<CreateDashboardBody title={'Rename your dashboard'} newDashboardName={newDashboardName} handleChange={handleChange} isFetching={isFetching} />
			</DataLibraryModal>
		</>
	);
};
export default EditDashboard;
