import { DarkButton, GhostButton } from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';

import Styled from './ExpandableContentLeft.style';

export type ExpandableContentProps = {
	children: JSX.Element;
	onCancel: () => void;
	drawerIsExpanded: boolean;
	expandedTitle?: string | JSX.Element;
	saveButtonText?: string;
	onSave?: () => void;
	isDisabled?: boolean;
	actionComponent?: JSX.Element;
	isSubmitting?: boolean;
	canOnlyCloseModal?: boolean;
	customButtons?: JSX.Element;
	cancelButtonText?: string | JSX.Element;
};

/**
 * Expandable content component
 * @returns {JSX.Element}
 */
const ExpandableContentLeft = ({
	children,
	onCancel,
	onSave,
	expandedTitle,
	saveButtonText,
	isDisabled,
	actionComponent,
	isSubmitting,
	canOnlyCloseModal,
	customButtons,
	cancelButtonText,
}: ExpandableContentProps): JSX.Element => {
	return (
		<Styled.ContentWrapper data-testid='left-content-wrapper'>
			{expandedTitle && <Styled.Title>{expandedTitle}</Styled.Title>}
			{actionComponent}
			<Styled.Content>{children}</Styled.Content>
			<Styled.BottomSection>
				<Styled.ButtonsWrapper>
					{customButtons ? (
						customButtons
					) : canOnlyCloseModal ? (
						<DarkButton onClick={onCancel}>Close</DarkButton>
					) : (
						<>
							<GhostButton size='sm' onClick={onCancel} data-testid='close-side-drawer'>
								{cancelButtonText || 'Cancel'}
							</GhostButton>
							<DarkButton size='sm' disabled={isDisabled || isSubmitting} onClick={onSave && onSave} data-testid='expand-drawer' type='submit'>
								{isSubmitting ? <LoadingSpinner size='sm' /> : saveButtonText || 'Confirm'}
							</DarkButton>
						</>
					)}
				</Styled.ButtonsWrapper>
			</Styled.BottomSection>
		</Styled.ContentWrapper>
	);
};

export default ExpandableContentLeft;
