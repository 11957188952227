import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgTemplate(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.949525 0H19.0405H19.0505C19.5702 0 20 0.42979 20 0.949536V5.50731C20 6.02706 19.5702 6.45685 19.0505 6.45685H0.949525C0.429785 6.45685 0 6.02706 0 5.50731V0.949536C0 0.419795 0.429785 0 0.949525 0ZM1.49925 4.94758H18.4907V1.49927H1.49925V4.94758ZM0.949525 8.88566H7.54622C8.06596 8.88566 8.49575 9.31545 8.49575 9.8352V19.0307C8.49575 19.5505 8.06596 19.9802 7.54622 19.9802H0.949525C0.429785 19.9802 0 19.5505 0 19.0307V9.8352C0 9.31545 0.429785 8.88566 0.949525 8.88566ZM1.49925 18.481H6.9965V10.3849H1.49925V18.481ZM19.0405 8.84568H12.4438C11.924 8.84568 11.4942 9.27547 11.4942 9.79522V12.7538C11.4942 13.2735 11.914 13.7033 12.4438 13.7033H19.0405C19.5602 13.7033 19.99 13.2735 19.99 12.7538V9.79522C19.99 9.27547 19.5602 8.84568 19.0405 8.84568ZM18.4907 12.194H12.9935V10.335H18.4907V12.194ZM12.4438 15.1426H19.0405C19.5602 15.1426 19.99 15.5724 19.99 16.0921V19.0507C19.99 19.5704 19.5602 20.0002 19.0405 20.0002H12.4438C11.914 20.0002 11.4942 19.5704 11.4942 19.0507V16.0921C11.4942 15.5624 11.924 15.1426 12.4438 15.1426ZM12.9935 18.491H18.4907V16.6419H12.9935V18.491Z'
				fill='#333333'
			/>
		</svg>
	);
}
