import Icon from 'components/Icon';

import { AssignmentType } from './types';

export function assignmentTypeToIcon(type: string): JSX.Element {
	switch (type) {
		case AssignmentType.INSTAGRAM_POST:
			return <Icon name='instagram' />;
		case AssignmentType.INSTAGRAM_STORY:
			return <Icon name='instagram' />;
		case AssignmentType.INSTAGRAM_REEL:
			return <Icon name='instagram' />;
		case AssignmentType.TIKTOK_VIDEO:
			return <Icon name='tiktok' />;
		case AssignmentType.AFFILIATE_TASK:
			return <Icon name='affiliate-campaign' />;
		default:
			return <Icon name='assignment-post' />;
	}
}

export function assignmentTypeToText(type: string): string {
	switch (type) {
		case AssignmentType.INSTAGRAM_POST:
			return 'Instagram post';
		case AssignmentType.INSTAGRAM_STORY:
			return 'Instagram Story';
		case AssignmentType.INSTAGRAM_REEL:
			return 'Instagram Reel';
		case AssignmentType.TIKTOK_VIDEO:
			return 'Tiktok video';
		case AssignmentType.AFFILIATE_TASK:
			return 'Affiliate task';
		default:
			return '';
	}
}

export function assignmentTypeToShortText(type: AssignmentType): string {
	switch (type) {
		case AssignmentType.INSTAGRAM_POST:
			return 'Post';
		case AssignmentType.INSTAGRAM_STORY:
			return 'Story';
		case AssignmentType.INSTAGRAM_REEL:
			return 'Reel';
		case AssignmentType.TIKTOK_VIDEO:
			return 'Tiktok';
		case AssignmentType.AFFILIATE_TASK:
			return 'Affiliate';
		default:
			return '';
	}
}
