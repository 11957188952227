import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';

const IconContainer = styled.div`
	margin: auto;
	margin-bottom: 30px;
	height: 48px;
	width: 48px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${colors.briefPage.joinCampaign.lightGreen};
	.icon {
		margin-top: 7px;
	}
	${mediaQueries.large`
		margin-top: 0;
	`};
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	button {
		margin-top: 30px;
	}
	${mediaQueries.large`
		width: 60%;
	`};
`;

const Styled = {
	IconContainer,
	Container,
};

export default Styled;
