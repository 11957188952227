import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgThumbsUpFilled(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M2.35379 16.3076C2.42239 16.6003 2.55959 16.8817 2.73109 17.1181C3.2113 17.7822 4.33178 19.0092 5.95534 19.0092H15.2736C15.2736 19.0092 18.6351 19.0992 17.0344 16.4652C17.0344 16.4652 19.1153 14.7767 17.183 13.3584C17.183 13.3584 18.9666 11.8275 17.0572 10.2854C17.0572 10.2854 18.1091 7.6851 15.3994 7.6851H9.06524C8.90517 7.6851 8.79084 7.5275 8.85944 7.38117L10.6774 3.17123C10.6774 3.17123 11.2605 1.84296 10.14 1.20134C8.83657 0.458412 7.75039 2.11312 7.75039 2.11312L3.4514 8.69818C3.08553 9.24975 2.85686 9.62122 2.70823 9.86886C2.51386 10.184 2.37666 10.5105 2.29662 10.8707C2.06795 11.8275 1.74781 13.8537 2.35379 16.3076Z'
				fill='#333333'
			/>
		</svg>
	);
}
