import classNames from 'classnames';
import { isEmpty, isNil, isArray } from 'lodash';
import { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import ActionModal, { ActionModalProps } from 'components/Modals/ActionModal/ActionModal';
import { StatusCode } from 'services/Response.types';

import Styled from './UploadCSVFileModal.style';

type Props = Pick<ActionModalProps, 'isModalOpen' | 'toggleModal'> & {
	actionHandler: (files: File[]) => Promise<{ file: File; uploadStatus: StatusCode }[] | undefined>;
	isUploading: boolean;
	refetchPaidMedia: () => void;
};
/**
 * @param {Props}
 * @returns {JSX.Element}
 */
const UploadCSVFileModal = ({ isModalOpen, toggleModal, actionHandler, isUploading, refetchPaidMedia }: Props) => {
	const [addedFiles, setAddedFiles] = useState<File[]>([]);
	const [uploadedFiles, setUploadedFiles] = useState<{ file: File; uploadStatus: StatusCode }[] | undefined>();
	const inputRef = useRef<HTMLInputElement>(null);
	const { getRootProps, getInputProps, open, acceptedFiles, fileRejections, isDragAccept, isDragReject } = useDropzone({
		noClick: true,
		maxFiles: 2,
		noKeyboard: true,
		accept: { 'text/csv': [] },
	});

	let tooManyFiles = fileRejections.some(({ errors }) => errors.some((e) => e.code === 'too-many-files'));

	const addFiles = (files: Array<File>) => {
		if (addedFiles.length + files.length > 2) {
			tooManyFiles = true;
			return;
		}

		if (files.length > 0 && addedFiles.length < 2) {
			setAddedFiles((props) => props.concat(...files));
		}
	};

	useEffect(() => {
		if (Array.isArray(fileRejections) && !fileRejections.length) {
			addFiles(acceptedFiles);
		}
	}, [acceptedFiles, fileRejections]);

	return (
		<ActionModal
			isModalOpen={isModalOpen}
			toggleModal={() => {
				if (!isNil(uploadedFiles) && !isEmpty(uploadedFiles)) {
					refetchPaidMedia();
				}
				toggleModal();
				setAddedFiles([]);
				setUploadedFiles(undefined);
			}}
			actionHandler={() => {
				actionHandler(addedFiles).then((res) => {
					if (res) {
						setUploadedFiles(res);
					} else {
						setUploadedFiles(undefined);
					}
				});
			}}
			size='xs'
			buttonText='Import'
			saveIsActive={!isEmpty(addedFiles) && !isUploading && isNil(uploadedFiles)}
			className={classNames('upload-csv-file-modal', { 'close-button-only': !isNil(uploadedFiles) })}
			replacementCancelButtonText={!isNil(uploadedFiles) ? 'Close' : undefined}
		>
			<Styled.Wrapper data-testid='import-csv-file-modal-inner'>
				<h5>Import CSV file</h5>
				{isUploading && isNil(uploadedFiles) && (
					<Styled.UploadStatusWrapper data-testid='importing-loading-indicator'>
						<Styled.UploadingMessage>
							<span>Importing CSV file...</span>
							<LoadingSpinner size='sm' />
						</Styled.UploadingMessage>
					</Styled.UploadStatusWrapper>
				)}
				{!isUploading && !isNil(uploadedFiles) && (
					<Styled.UploadStatusWrapper>
						<div className='file-upload-statuses'>
							{isArray(uploadedFiles) && !isEmpty(uploadedFiles) ? (
								uploadedFiles.map((uploadedFile, index) => {
									if (uploadedFile.uploadStatus === StatusCode.OK) {
										return (
											<Styled.UploadingMessage className='completed' key={index}>
												<Icon name='check-circle' size='16' />
												{uploadedFile.file.name} file uploaded.
											</Styled.UploadingMessage>
										);
									} else {
										return (
											<Styled.UploadingMessage className='error' key={index}>
												<Icon name='alert' size='16' />
												Something went wrong, please upload {uploadedFile.file.name} again.
											</Styled.UploadingMessage>
										);
									}
								})
							) : (
								<Styled.UploadingMessage className='error'>
									<Icon name='alert' size='16' />
									Something went wrong, please try again.
								</Styled.UploadingMessage>
							)}
						</div>

						<div>
							{uploadedFiles.some((uploadedFile) => uploadedFile.uploadStatus !== StatusCode.OK) || isEmpty(uploadedFiles)
								? 'Something went wrong when importing your file. Please check that it is a correct file and try again.'
								: 'Your file was successfully imported, and the statistics for the campaign added to your dashboard.'}
						</div>
					</Styled.UploadStatusWrapper>
				)}
				{!isUploading && isNil(uploadedFiles) && (
					<>
						<Styled.HelpText className='mb-3'>Select an Instagram (Meta) or TikTok CSV File.</Styled.HelpText>
						{!isEmpty(addedFiles) && (
							<Styled.FileItems>
								{addedFiles.map((addedFile, index) => {
									return (
										<Styled.FileItem key={index} data-testid='added-file-item'>
											<span>{addedFile.name} added</span>
											<Styled.IconButton
												className='remove-file'
												onClick={() => {
													setAddedFiles((prev) => prev.filter((file) => file !== addedFile));
												}}
											>
												<Icon name='cancel-circle' size='20' />
											</Styled.IconButton>
										</Styled.FileItem>
									);
								})}
							</Styled.FileItems>
						)}
						{(isEmpty(addedFiles) || addedFiles.length < 2) && (
							<Styled.DndArea
								{...getRootProps({ className: 'dropzone', isDragAccept, isDragReject })}
								className={classNames({ shorter: !isEmpty(addedFiles) })}
								data-testid='import-csv-file-dnd-zone'
							>
								<div className='icon-wrapper'>
									<Icon name='upload' size='20' />
								</div>
								<Styled.HelpText className={classNames('dnd__help-text', { 'mb-3': addedFiles.length === 0 })}>
									{isDragAccept ? 'Drop to add the file.' : !isEmpty(addedFiles) ? 'Add another file?' : 'Drop or browse to add a file.'}
								</Styled.HelpText>
								<Styled.Button size='sm' onClick={open}>
									Browse
								</Styled.Button>
								<Styled.UploadInput accept='text/csv' type='file' ref={inputRef} {...getInputProps()} data-testid='csv-file-input' />
							</Styled.DndArea>
						)}

						{tooManyFiles ? (
							<Styled.ErrorMessage data-testid='too-many-file-error'>
								<Icon name='alert' size='16' />
								<span>You can add max 2 files.</span>
							</Styled.ErrorMessage>
						) : (
							<Styled.HelpText className='modal__help-text mb-3'>Please make sure you don&apos;t edit the CSV file before importing.</Styled.HelpText>
						)}
					</>
				)}
			</Styled.Wrapper>
		</ActionModal>
	);
};

export default UploadCSVFileModal;
