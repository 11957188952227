import Checkbox from 'components/Checkbox';

import Styled from './FilterSection.style';

const FilterSectionOptionListItem = (props: { onChange: () => void; label: string | JSX.Element; checked: boolean }) => {
	return (
		<Styled.FilterOptionListItem>
			<Checkbox checked={props.checked} label={props.label} onChange={props.onChange} className='textFirst' />
		</Styled.FilterOptionListItem>
	);
};
export default FilterSectionOptionListItem;
