import { Model } from 'json-api-models';

export interface CategoryType extends Model {
	createdAt: string;
	name: string;
}

export interface BrandType extends Model {
	name: string;
	category: CategoryType;
}

export const ONBOARDING_HEADER_CONTENTS = [
	{ title: 'Your type of collaboration?', description: 'Which type of promotion do you prefer to do?' },
	{ title: "What's your thing?", description: 'Select all that applies. This will help us find more relevant collaborations for you.' },
	{ title: 'Any preferred partners?', description: "Are there any brands you're particularly excited to work with? Select as many as applies." },
];

export const ONBOARDING_ERROR_TYPES = {
	noCollaborationTypeSelected: 'Please select at least one collraboration type.',
	noCategorySelected: 'Please select at least one category to continue.',
	noBrandSelected: 'Please select at least one brand to continue.',
	missingPreferenceProfiles: 'Assign a category to each of your accounts.',
};
