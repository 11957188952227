import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgCommentFilled(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.75127 20C3.40411 20 3.08589 19.8089 2.92195 19.484C2.73873 19.1209 2.82552 18.6909 3.1341 18.4233C3.64519 17.9742 3.78984 16.8753 3.81877 16.216C1.41761 14.4959 6.10352e-05 11.8586 6.10352e-05 9.07788C6.10352e-05 4.07071 4.48415 0 10.0001 0C15.7956 0 20.0001 3.82226 20.0001 9.08743C20.0001 12.0497 18.6597 14.7062 16.3356 16.3784C13.9538 18.0889 10.9065 18.5858 7.86891 17.7735C6.86602 19.6178 5.34239 19.8089 4.39736 19.9236C4.23343 19.9427 4.07914 19.9618 3.93449 19.9904C3.87663 20 3.81877 20.0096 3.76091 20.0096L3.75127 20Z'
				fill='#4D4B4C'
			/>
		</svg>
	);
}
