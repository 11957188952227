import classNames from 'classnames';
import { Model } from 'json-api-models';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Icon from 'components/Icon';
import { formatDateToDDMMM } from 'components/InfluencerDashboard/utils';
import { assignmentTypeToIcon } from 'shared/helpers/Assigment/Assignment';
import { formatAmount } from 'utils/formatters';

import Styled from './CampaignInfoBlock.style';

/**
 * @todo test
 */
const CampaignInfoBlock = (props: {
	label: 'Manage content' | 'products' | 'Campaign codes' | 'commission';
	commission?: string;
	assignments?: Model[];
	products?: Model[];
	promoCodes?: string[];
	isAffiliate?: boolean;
	CIO?: Model;
}) => {
	const [detailOpens, setDetailOpens] = useState<{ assignments: boolean; products: boolean; promoCodes: boolean }>({
		assignments: false,
		products: false,
		promoCodes: false,
	});
	const [copied, setCopied] = useState<number | null>(null);
	const navigate = useNavigate();
	const { campaignId } = useParams();

	const changeDetailOpenHandler = (label: 'commission' | 'Manage content' | 'products' | 'promoCodes') => {
		setDetailOpens((prev) => {
			// @ts-ignore
			return { ...prev, [label]: !prev[label] };
		});
	};

	const renderDetail = () => {
		switch (props.label) {
			case 'commission':
				return props.isAffiliate ? 'Performance based' : props.commission;
			case 'Manage content':
				return (
					<>
						{props.assignments!.map((assignment: Model) => {
							return (
								<Styled.DetailItemWrapper
									key={assignment.id}
									onClick={() =>
										props.CIO
											? navigate(`/content-management/${campaignId}/${props.CIO.id}/${assignment.assignment.id}`)
											: navigate(`/influencer/content-management/${campaignId}`)
									}
								>
									<div className='title'>
										{assignmentTypeToIcon(assignment.assignment.kind)}
										<span>{assignment.assignment.name}</span>
									</div>
									<div className='date'>
										{`${formatDateToDDMMM(assignment.assignment.groups[0]?.start)} - ${formatDateToDDMMM(assignment.assignment.groups[0]?.end)}`}
									</div>
								</Styled.DetailItemWrapper>
							);
						})}
					</>
				);
			case 'products':
				return props.products?.map((campaignInstagramIwnerProduct) => {
					const productHasLink = campaignInstagramIwnerProduct.product.link;
					return (
						<Styled.DetailItemWrapper
							key={campaignInstagramIwnerProduct.id}
							className={classNames({ hideCursor: productHasLink?.length === 0 })}
							onClick={
								productHasLink
									? () => {
											window.open(productHasLink, '_blank');
										}
									: () => {}
							}
						>
							<Styled.ProductDetailInnerWrapper>
								<div className='title'>{campaignInstagramIwnerProduct.product?.name}</div>
								{productHasLink && (
									<Styled.IconWrapper>
										<Icon name='external-link' size='16' />
									</Styled.IconWrapper>
								)}
							</Styled.ProductDetailInnerWrapper>
							<div className='value'>
								{campaignInstagramIwnerProduct.product.value?.amount &&
									formatAmount(campaignInstagramIwnerProduct.product.value?.amount, campaignInstagramIwnerProduct.product.value?.currency)}
							</div>
						</Styled.DetailItemWrapper>
					);
				});
			case 'Campaign codes':
				return (
					<>
						<Styled.Details className={classNames({ visible: detailOpens.promoCodes })}>
							<Styled.PromoCodeList>
								{props.promoCodes?.map((code, i) => {
									return (
										<li
											key={i}
											onClick={async (e) => {
												e.preventDefault();
												try {
													await navigator.clipboard.writeText(code);
													setCopied(i);
													changeDetailOpenHandler('promoCodes');
													setTimeout(() => setCopied(null), 3000);
												} catch (e) {
													console.error('Unable to access clipboard: %O', e);
												}
											}}
										>
											<div>
												{code} {copied === i ? <span>Copied!</span> : <Icon name='copy' />}
											</div>
										</li>
									);
								})}
							</Styled.PromoCodeList>
						</Styled.Details>
					</>
				);
			default:
				return null;
		}
	};

	return (
		<Styled.Wrapper
			className={classNames({ showCursor: props.label !== 'commission' })}
			onClick={() =>
				props.label !== 'commission' && props.label === 'Campaign codes' ? changeDetailOpenHandler('promoCodes') : changeDetailOpenHandler(props.label)
			}
			data-testid={`inbox-campaign-detail-${props.label}`}
		>
			<Styled.Label>
				<div>{props.label}</div>
			</Styled.Label>
			{renderDetail()}
		</Styled.Wrapper>
	);
};

export default CampaignInfoBlock;
