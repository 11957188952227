import styled from 'styled-components';

import { scrollbarY } from 'styles/utils/scrollbar';
import boxShadow from 'styles/variables/box-shadows';
import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';

const ActionDropdownMenu = styled.div`
	position: absolute;
	top: 64px;
	will-change: transform;
	overflow: hidden;
	text-align: left;
	border: 1px solid ${colors.userDropdown.border};
	background-color: ${colors.userDropdown.background};
	left: 0;
	right: 0;
	margin: auto;
	max-width: 92vw;
	min-width: 92vw;
	height: auto;
	opacity: 0;
	z-index: -1;
	visibility: hidden;
	border-radius: ${({ theme }) => theme.radius.default};
	box-shadow: ${boxShadow.medium};
	&.isNotificationMenu {
		max-height: 440px;
	}
	${scrollbarY};
	${mediaQueries.medium`
		min-width: 327px;
		max-width: auto;
		right: 25px;
		left: unset;

	`};
`;

const ActionDropDownArrow = styled.div`
	cursor: pointer;
	transform: translate3d(48px, -30px, 10px);
	position: absolute;
	z-index: 10;
`;

const Overlay = styled.div`
	width: 100vw;
	height: 100vh;
	position: absolute;
	left: 0;
	top: 0;
	display: none;
`;
const OverlayColor = styled.div`
	width: 100vw;
	height: 100vh;
	position: absolute;
	left: 0;
	top: 0;
	background-color: ${colors.black};
	opacity: 0.6499999761581421;
	display: none;
`;

const ActionDropdownWrapper = styled.div`
	&.show {
		${ActionDropdownMenu} {
			opacity: 1;
			z-index: 1;
			visibility: visible;
			ul {
				li {
					opacity: 1;
				}
			}
		}
		${Overlay} {
			display: unset;
			backdrop-filter: blur(2px);
			${mediaQueries.medium`
				display: none;
			`};
		}
		${OverlayColor} {
			display: unset;
			${mediaQueries.medium`
				display: none;
			`};
		}
	}
`;

const Styled = {
	Overlay,
	OverlayColor,
	ActionDropdownWrapper,
	ActionDropDownArrow,
	ActionDropdownMenu,
};

export default Styled;
