import classNames from 'classnames';
import { uniq } from 'lodash';
import React, { useContext, useState } from 'react';

import Accordion from 'components/Accordion';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import DiscoveryContext from 'contexts/Discovery';

import Styled from './BrandAffiliationsFilter.style';

const BrandAffiliationsFilter = () => {
	const { filter, updateBrandAffiliationsHandler } = useContext(DiscoveryContext);
	const [enteredText, setEnteredText] = useState('');
	const [option, setOption] = useState<string>('include');

	const setBrandName = (e: React.KeyboardEvent) => {
		if (e.code === 'Enter' && enteredText.length > 0) {
			// Check for multiple mentions or words
			const hasMultipleMentions = enteredText.split(/@/).length > 2;
			const hasMultipleWords = enteredText.indexOf(' ') >= 0;

			// Get existing brand affiliations
			const includedBrands = filter?.brandAffiliations?.split(',') || [];
			const excludedBrands = filter?.excludedBrandAffiliations?.split(',') || [];

			// Helper function to add brand if not already included/excluded
			const addBrandIfNotExists = (brand: string) => {
				const cleanedBrand = brand.replace(/[@#]/g, '').trim();
				if (cleanedBrand.length > 0 && !includedBrands.includes(cleanedBrand) && !excludedBrands.includes(cleanedBrand)) {
					updateBrandAffiliationsHandler(cleanedBrand, option as 'include' | 'exclude', 'add');
				}
			};

			if (hasMultipleMentions) {
				// Split by "@" and filter out empty values
				const arrayOfMentions = enteredText
					.split(/@/)
					.filter((mention) => mention.length)
					.map((m) => m.trim());

				// Add each unique mention if it’s not already in the filter
				uniq(arrayOfMentions).forEach(addBrandIfNotExists);
			} else if (hasMultipleWords) {
				// Split by space and filter out duplicates
				uniq(enteredText.split(' ')).forEach(addBrandIfNotExists);
			} else {
				// Add single brand if not already included/excluded
				addBrandIfNotExists(enteredText);
			}
			setEnteredText('');
		}
	};

	const changeOptionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setOption(e.target.value);
	};

	return (
		<Accordion title='Brand collaboration' className={classNames({ active: filter?.brandAffiliations })}>
			<Styled.Wrapper>
				<Input
					onReset={() => {
						setEnteredText('');
					}}
					value={enteredText}
					onChange={(e) => {
						setEnteredText(e.target.value);
					}}
					onKeyDown={setBrandName}
					contentAfter={
						enteredText ? (
							<Styled.ContentAfterContainter>
								<Styled.EnterLabel title='Press "enter" to submit'>[enter]</Styled.EnterLabel>
								<Styled.IconContainer onClick={() => setEnteredText('')}>
									<Icon name='cancel-circle' size='16' />
								</Styled.IconContainer>
							</Styled.ContentAfterContainter>
						) : undefined
					}
				/>
				<Styled.OptionWrapper>
					<Styled.RadioButtonWrapper className={classNames({ selected: option === 'include' })}>
						<input type='radio' id='include' name='barnd-mentions' value='include' checked={option === 'include'} onChange={changeOptionHandler} />
						<label htmlFor='include'>Include</label>
					</Styled.RadioButtonWrapper>
					<Styled.RadioButtonWrapper className={classNames({ selected: option === 'exclude' })}>
						<input type='radio' id='exclude' name='barnd-mentions' value='exclude' checked={option === 'exclude'} onChange={changeOptionHandler} />
						<label htmlFor='exclude'>Exclude</label>
					</Styled.RadioButtonWrapper>
				</Styled.OptionWrapper>
				<Styled.BrandsWrapper>
					{filter?.brandAffiliations?.split(',')?.map((brand, index) => {
						return (
							<Styled.BrandItem
								key={index}
								onClick={() => {
									updateBrandAffiliationsHandler(brand, 'include', 'remove');
								}}
							>
								{brand}
								<Icon name='cross' size='8' />
							</Styled.BrandItem>
						);
					})}
					{filter?.excludedBrandAffiliations?.split(',')?.map((brand, index) => {
						return (
							<Styled.BrandItem
								className='exclude'
								key={index}
								onClick={() => {
									updateBrandAffiliationsHandler(brand, 'exclude', 'remove');
								}}
							>
								{brand}
								<Icon name='cross' size='8' />
							</Styled.BrandItem>
						);
					})}
				</Styled.BrandsWrapper>
			</Styled.Wrapper>
		</Accordion>
	);
};

export default BrandAffiliationsFilter;
