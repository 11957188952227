import Styled from 'components/Discovery/Components/InfluencerDetailCardDiscovery/InfluencerDetailCard.style';
import { convertDateFormat } from 'components/Discovery/Components/InfluencerDetailCardDiscovery/types';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import colors from 'styles/theme/colors';

type BrandAffiliationsProps = {
	influencer: InfluencerListItemType;
};

const BrandAffiliations = ({ influencer }: BrandAffiliationsProps) => {
	return influencer.brandAffiliations && influencer.brandAffiliations.length > 0 ? (
		<Styled.Section className='brand-affiliations'>
			<Styled.CustomAccordion
				hoverBackgroundColor={colors.smoke}
				className='brand-affiliations'
				icon='chevron-down'
				title={<Styled.DataBlockTitle className='brand-affiliations'>Brand affiliations</Styled.DataBlockTitle>}
				open
			>
				<Styled.Content>
					{influencer.brandAffiliations
						.sort((a, b) => new Date(b.postedAt) - new Date(a.postedAt))
						.slice(0, 5)
						.map(({ username, url, postedAt }, index) => {
							return (
								<Styled.FlexDiv className='brand-affiliation-item' align-items='center' key={`${username}-${index}`}>
									<div className='brand-affiliation-item-username'>{username}</div>
									{url !== null ? (
										<div>
											<Styled.DateInfo>{convertDateFormat(postedAt)}</Styled.DateInfo>
											<Styled.LinkToPost href={url} target='_blank' className='post-link'>
												View post
											</Styled.LinkToPost>
										</div>
									) : (
										<div>
											<Styled.DateInfo>{convertDateFormat(postedAt)}</Styled.DateInfo>
											<span className='missing-link'>Link missing</span>
										</div>
									)}
								</Styled.FlexDiv>
							);
						})}
				</Styled.Content>
			</Styled.CustomAccordion>
		</Styled.Section>
	) : null;
};

export default BrandAffiliations;
