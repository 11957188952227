import DOMPurify from 'dompurify';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/Icon';
import { Product } from 'components/NewBriefPage/types';
import { pathProductShow } from 'routing/PathLookup';
import { formatAmount } from 'utils/formatters';

import Styled from './CampaignProducts.style';

type CampaignProductProps = {
	id: string;
	products: Product[];
	campaignId: string;
	canEdit: boolean;
};
/**
 * @returns JSX.Element
 */
const CampaignProducts = ({ id, products, campaignId, canEdit }: CampaignProductProps): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Styled.Wrapper id={id}>
			<Styled.HeaderText>Products</Styled.HeaderText>
			<Styled.ContentWrapper>
				<Styled.AssignmentCards>
					{products?.map((product: Product) => {
						return (
							<Styled.AssignmentCard key={product.id} id={`product-${product.id}`} data-testid={`product-${product.id}`}>
								<Styled.Title>
									<div className='name'>{product.product.name}</div>
									{canEdit && (
										<Styled.Icons>
											<Styled.IconContainer role='button' onClick={() => navigate(pathProductShow(campaignId, product.id))}>
												<Icon name='pen' size='16' /> <div>Edit</div>
											</Styled.IconContainer>
										</Styled.Icons>
									)}
								</Styled.Title>
								{product.product.value.amount > 0 && (
									<Styled.Value>
										Product value: <strong>{formatAmount(product.product.value.amount, product.product.value.currency)}</strong>
									</Styled.Value>
								)}

								<Styled.ProductInfo>
									{product.product.productImage && (
										<Styled.ProductLink placedOnImage={true}>
											<Styled.Image src={product.product.productImage} alt='product image' />
										</Styled.ProductLink>
									)}
									<div>
										<Styled.Description dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.product.description) }} />
										<Styled.StyledLink to={product.product.link} target='_blank' rel='noopener noreferrer' data-testid={`product-link-${product.id}`}>
											View product on homepage
										</Styled.StyledLink>
									</div>
								</Styled.ProductInfo>
							</Styled.AssignmentCard>
						);
					})}
				</Styled.AssignmentCards>
			</Styled.ContentWrapper>
		</Styled.Wrapper>
	);
};

export default CampaignProducts;
