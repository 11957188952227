import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgMobileCheck(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps} fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.0691 19.99H3.92007C3.41132 19.99 3 19.5902 3 19.1005V0.889555C3 0.3998 3.41132 0 3.92007 0H15.0799C15.5887 0 16 0.3998 16 0.889555V10.1349C16 10.5247 15.6753 10.8346 15.2748 10.8346C14.8743 10.8346 14.5495 10.5247 14.5495 10.1349V1.3993H4.43963V18.6007H14.5387V17.3413C14.5387 16.9515 14.8634 16.6417 15.2639 16.6417C15.6644 16.6417 15.9892 16.9515 15.9892 17.3413V19.1104C15.9892 19.6002 15.5779 20 15.0691 20V19.99ZM16.7803 12.2135C17.0732 12.5062 17.0732 12.9808 16.7803 13.2736L14.2803 15.7723C13.9874 16.0651 13.5126 16.0651 13.2197 15.7723L12.2197 14.7728C11.9268 14.4801 11.9268 14.0055 12.2197 13.7127C12.5126 13.42 12.9874 13.42 13.2803 13.7127L13.75 14.1821L15.7197 12.2135C16.0126 11.9207 16.4874 11.9207 16.7803 12.2135ZM8.39894 2.99902H10.6011C10.8245 2.99902 11 2.77902 11 2.49902C11 2.21902 10.8245 1.99902 10.6011 1.99902H8.39894C8.17553 1.99902 8 2.21902 8 2.49902C8 2.77902 8.17553 2.99902 8.39894 2.99902Z'
				fill='#4D4B4C'
			/>
		</svg>
	);
}
