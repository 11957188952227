import LoadingSpinner from 'components/LoadingSpinner';

import Styled from './CenteredSpinner.style';

const CenteredSpinner = () => {
	return (
		<Styled.SpinnerWrapper>
			<LoadingSpinner />
		</Styled.SpinnerWrapper>
	);
};

export default CenteredSpinner;
