import { AxiosResponse } from 'axios';
import { JsonApiDocument, Store } from 'json-api-models';

const getModel = (httpResponse: AxiosResponse<JsonApiDocument>, targetType: string, targetId?: string) => {
	const models = new Store();
	models.sync(httpResponse.data);

	if (targetId) {
		return models.find({ type: targetType, id: targetId });
	}

	return models.findAll(targetType);
};

export default getModel;
