import { AxiosError } from 'axios';
import { inject, injectable } from 'inversify';

import TokenStorageRegistry from 'services/Authentication/TokenStorageRegistry';
import { StatusCode } from 'services/Response.types';

@injectable()
export default class UnauthorizedErrorInterceptor {
	public constructor(@inject(TokenStorageRegistry) private storageRegistry: TokenStorageRegistry) {}

	public onError(error: AxiosError) {
		if (error.response?.status === StatusCode.UNAUTHORIZED) {
			// Panic logout
			this.storageRegistry.clearAll();
		}

		return Promise.reject(error);
	}
}
