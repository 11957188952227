import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgUserInfluencerRemove(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.5 9C10.9853 9 13 6.98528 13 4.5C13 2.01472 10.9853 0 8.5 0C6.01472 0 4 2.01472 4 4.5C4 6.98528 6.01472 9 8.5 9ZM8.5 7.5C10.1569 7.5 11.5 6.15685 11.5 4.5C11.5 2.84315 10.1569 1.5 8.5 1.5C6.84315 1.5 5.5 2.84315 5.5 4.5C5.5 6.15685 6.84315 7.5 8.5 7.5Z'
				fill='#333333'
			/>
			<path
				d='M2.7305 14.2781C1.70826 15.5322 1.4397 17.0778 1.5138 18.1853L1.51546 18.2102V19.2332C1.51546 19.6466 1.17914 19.9817 0.764267 19.9817C0.349394 19.9817 0.0130735 19.6466 0.0130735 19.2332V18.2592C-0.0740588 16.8733 0.255816 14.9396 1.56431 13.3342C2.90356 11.6911 5.17869 10.5006 8.76131 10.5H8.76374C11.3896 10.5 12.8699 10.9937 14.2493 11.9971C14.5843 12.2409 14.6577 12.7092 14.4131 13.0431C14.1685 13.377 13.6985 13.4501 13.3634 13.2063C12.2935 12.4281 11.1426 11.9971 8.7638 11.9971C5.53711 11.9971 3.72976 13.0521 2.7305 14.2781Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.8613 14.8087C20.0463 14.6237 20.0463 14.3237 19.8613 14.1387C19.6763 13.9538 19.3764 13.9538 19.1914 14.1387L16.9995 16.3306L14.8087 14.1398C14.6237 13.9548 14.3237 13.9548 14.1387 14.1398C13.9538 14.3248 13.9538 14.6247 14.1387 14.8097L16.3296 17.0005L14.139 19.1912C13.954 19.3762 13.954 19.6761 14.139 19.8611C14.3239 20.0461 14.6239 20.0461 14.8089 19.8611L16.9995 17.6705L19.1912 19.8621C19.3762 20.0471 19.6761 20.0471 19.8611 19.8621C20.0461 19.6772 20.0461 19.3772 19.8611 19.1922L17.6694 17.0005L19.8613 14.8087Z'
				fill='#333333'
			/>
		</svg>
	);
}
