import classNames from 'classnames';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip/V2';

import Styled from './MetricBar.style';
import { MetricBarType } from './types';

/**
 * MetricBar
 * @param {MetricBarType}
 * @returns {JSX.Element}
 */
const MetricBar = ({ items, mode = 'default' }: MetricBarType): JSX.Element => {
	const renderValue = (value: string | React.ReactNode) => {
		if (typeof value === 'string') return <strong>{value}</strong>;
		return value;
	};

	return (
		<Styled.MetricBar mode={mode} className={classNames({ 'is-empty': !items.length })}>
			{items.length > 0 &&
				items.map((item, index) => (
					<Styled.MetricItem key={index}>
						<Styled.Label>
							<span>{item.label}</span>
							{item.tooltipText && (
								<Tooltip content={<span>{item.tooltipText}</span>} clickToToggle>
									<Styled.TooltipButton aria-label='Tooltip'>
										<Icon name='info-circle' size='12' />
									</Styled.TooltipButton>
								</Tooltip>
							)}
						</Styled.Label>
						<Styled.Value>{renderValue(item.value)}</Styled.Value>
					</Styled.MetricItem>
				))}
			{!items.length && (
				<Styled.EmptyState>
					<p>No items</p>
				</Styled.EmptyState>
			)}
		</Styled.MetricBar>
	);
};

export default MetricBar;
