import { inject, injectable } from 'inversify';

import { SegmentModel } from 'api-models';
import { AddInfluencersToSegmentPayload, CreateSegmentPayload, UpdateSegmentPayload } from 'api-payloads';
import { CreateSegmentQuery, ListSegmentsQuery, UpdateSegmentQuery, ViewSegmentQuery } from 'api-queries';
import { SegmentCollectionResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ResourceManager from 'utils/Repository/ResourceManager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, DeleteConfig, UpdateConfig } from './types';

import type SegmentApiClientInterface from 'services/ApiClient/SegmentApiClientInterface';

@injectable()
class SegmentManager extends ApiManager {
	private readonly client: SegmentApiClientInterface;

	constructor(@inject(ApiCacheManager) cacheManager: ApiCacheManager, @inject(ApiClientService.SegmentApiClientInterface) client: SegmentApiClientInterface) {
		const resourceManager = new ResourceManager();
		super(cacheManager, resourceManager);

		this.client = client;
	}

	public get(id: string, queryBuilder = RequestQueryBuilder.create<ViewSegmentQuery>()) {
		const key = `listSegments::${id}_${queryBuilder.toHash()}`;
		const fetcher = () => this.client.view(id, queryBuilder.toQuery());
		return this.swr<SegmentCollectionResponse>(key, fetcher);
	}

	public list(campaignId: string, queryBuilder = RequestQueryBuilder.create<ListSegmentsQuery>()) {
		const key = `listSegments::${campaignId}_${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listSegments(campaignId, queryBuilder.toQuery());
		return this.swr<SegmentCollectionResponse>(key, fetcher, { revalidateOnFocus: false, revalidateIfStale: false });
	}

	public async addInfluencersTo(
		id: string,
		payload: AddInfluencersToSegmentPayload,
		queryBuilder = RequestQueryBuilder.create<CreateSegmentQuery>(),
	): Promise<void> {
		await this.client.addInfluencersTo(id, payload, queryBuilder.toQuery());
	}

	public create(
		id: string,
		payload: CreateSegmentPayload,
		queryBuilder = RequestQueryBuilder.create<CreateSegmentQuery>(),
		config: CreateConfig<SegmentModel> = {},
	): Promise<SegmentModel> {
		return this.createModel<SegmentModel>(this.client.create(id, payload, queryBuilder.toQuery()), config);
	}

	public update(
		id: string,
		payload: UpdateSegmentPayload,
		queryBuilder = RequestQueryBuilder.create<UpdateSegmentQuery>(),
		config: UpdateConfig<SegmentModel> = {},
	): Promise<SegmentModel> {
		return this.updateModel<SegmentModel>(this.client.update(id, payload, queryBuilder.toQuery()), config);
	}

	public delete(id: string, config: DeleteConfig = {}) {
		return this.deleteModel(this.client.delete(id), 'segment', id, config);
	}
}

export default SegmentManager;
