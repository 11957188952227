import styled from 'styled-components';

import { DarkButton } from 'components/Button';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	padding-top: ${spacing[2].rem};
	padding-bottom: ${spacing[2].rem};
	width: 100%;
`;

const InnerWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Button = styled(DarkButton)`
	margin-bottom: ${spacing[2].px};
	font-size: 1rem;
`;

const HelpText = styled.span`
	font-size: 0.75rem;
	color: ${colors.dataLibrary.csvFileUpload.helpText};
`;

const Styled = {
	Wrapper,
	InnerWrapper,
	Button,
	HelpText,
};

export default Styled;
