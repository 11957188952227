import classNames from 'classnames';
import { useState } from 'react';

import FullscreenModal from 'components/ContentManagement/Components/FullscreenModal/FullscreenModal';
import Icon from 'components/Icon';

import Styled from './ContentCard.style';

type ContentCardProps = {
	active: boolean;
	disabled?: boolean;
	type?: 'default' | 'tall';
	imageUrl: string;
	videoUrl?: string;
	altText?: string;
	children?: React.ReactNode;
};

/**
 * ContentCard
 * @param {ContentCardProps}
 * @returns {JSX.Element}
 */
const ContentCard = ({ active, disabled, imageUrl, altText, videoUrl, children, type = 'default' }: ContentCardProps): JSX.Element => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const onClickPlay = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsModalOpen(!isModalOpen);
	};

	const onFullSizePreview = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsModalOpen(!isModalOpen);
	};

	return (
		<Styled.Card className={classNames({ selected: active, tall: type === 'tall', disabled: disabled })}>
			<Styled.CardImageWrapper>
				<Styled.CardImageInner>
					<Styled.CardVideo>
						<div className='layer'>
							<div>
								<Icon name='check-circle' />
								<span>Selected</span>
							</div>
						</div>
						<Styled.CardImage src={imageUrl} alt={altText} />
					</Styled.CardVideo>
				</Styled.CardImageInner>
				<Styled.VideoOverlay>
					<Styled.ShowFullSizeButton data-testid='play-button' onClick={videoUrl ? onClickPlay : onFullSizePreview}>
						<Icon name='zoom' size='18' />
					</Styled.ShowFullSizeButton>
				</Styled.VideoOverlay>
			</Styled.CardImageWrapper>
			{children && <Styled.CardContent>{children}</Styled.CardContent>}

			<FullscreenModal
				isOpen={isModalOpen}
				handleClose={() => {
					setIsModalOpen(false);
				}}
				videoUrl={videoUrl}
				file={!videoUrl ? imageUrl : undefined}
			/>
		</Styled.Card>
	);
};

export default ContentCard;
