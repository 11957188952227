import styled from 'styled-components';

import { IBodyStyle } from './types';

const Wrapper = styled.div<IBodyStyle>`
	width: 100%;
	height: auto;

	display: ${(props) => props.display};
	align-items: ${(props) => props.alignItems};
	justify-content: ${(props) => props.justifyContent};
`;

const Styled = {
	Wrapper,
};

export default Styled;
