import styled from 'styled-components';

import Avatar from 'components/Avatar';
import colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

import { IInfluencerStyle } from './types';

const textTypograph = typography.headings;

const Wrapper = styled.div<IInfluencerStyle>`
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || 'auto'};
	min-width: 345px;

	display: flex;
	column-gap: 5px;
`;

const Section = styled.div<IInfluencerStyle>`
	flex: 1;
	display: ${(props) => props.display};
	font-size: ${(props) => props.fontSize};
	font-weight: ${(props) => props.fontWeight};
	justify-content: ${(props) => props.justifyContent};
	align-items: ${(props) => props.alignItems};
	flex-direction: ${(props) => props.flexDirection};
	max-width: ${(props) => props.maxWidth};
	overflow-x: auto;
	cursor: default;
`;

const FollowerValue = styled.span`
	color: ${colors.dataLibrary.darkGray};
	font-size: 0.75rem;
	font-weight: 400;
`;

const InfluenceName = styled.span`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
	font-weight: 600;
`;

const InfluencerValue = styled.span`
	font-family: ${textTypograph.fontFamily};
	font-size: ${textTypograph.h5.fontSize};
	font-weight: ${textTypograph.h5.fontWeight};
`;

const Image = styled(Avatar)``;

const Styled = {
	Wrapper,
	Section,
	FollowerValue,
	InfluenceName,
	InfluencerValue,
	Image,
};

export default Styled;
