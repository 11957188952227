import { useState } from 'react';

import { StatisticsInfluencerWithStatisticsModel } from 'api-models';
import ChartCard from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard';
import Styled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { ChartCardProps } from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/types';
import ExplanationAccordition from 'components/DataLibrary/Graphs/GraphComponents/ExplanationAccordion';
import { getErrorMessageOnFetch } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import toast from 'services/Toast';
import { useAppSelector } from 'views//DataLibrary/hooks';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

import { FakeData } from './FakeData';
import CombinedBarChart from './GraphTypes/CombinedBarChart';
import CombinedBarWithLineChart from './GraphTypes/CombinedBarWithLineChart';
import InfluencerList from './InfluencerList/InfluencerList';
import { InfluencerWithDataForGraphs } from './InfluencerList/types';
import { ByInfluencerOutput } from './types';

const mapInfluencers = (array: StatisticsInfluencerWithStatisticsModel[], valueType: string | undefined): InfluencerWithDataForGraphs[] => {
	return array.map((influencer: StatisticsInfluencerWithStatisticsModel) => {
		let value = 0;
		let sign: string | undefined;
		let valueLabel = '';
		let secondValue = 0;
		let secondValueLabel = '';
		switch (valueType) {
			case 'ctr':
				sign = ' %';
				if (influencer.attributes.reach === 0) {
					value = 0;
				} else {
					// Same as getTrafficCTRTotal()
					value = (100 * influencer.attributes.clicks) / influencer.attributes.reach;
				}

				break;
			case 'reach':
				// Let's use grossReach instead of reach
				value = influencer.attributes.reach;
				break;
			case 'grossReach':
				value = influencer.attributes.grossReach;
				break;
			case 'impressions':
				value = influencer.attributes.impressions;
				break;
			case 'assignments':
				value = influencer.attributes.assignments;
				break;
			case 'clicks':
				value = influencer.attributes.clicks;
				break;
			case 'engagement':
				value = influencer.attributes.engagement;
				break;
			case 'likes':
				value = influencer.attributes.likes;
				break;
			case 'comments':
				value = influencer.attributes.comments;
				break;
			case 'followers':
				value = influencer.attributes.followers;
				break;
			case 'conversions':
				value = influencer.attributes.conversions;
				break;
			case 'orderValue':
				value = influencer.attributes.conversionSaleAmount ?? 0;
				break;
			case 'reach-cpr':
				value = influencer.attributes.grossReach;
				valueLabel = 'Reach';
				secondValue = influencer.attributes.cpr ?? 0;
				secondValueLabel = 'CPR';
				break;
			case 'engagement-cpe':
				value = influencer.attributes.engagement;
				valueLabel = 'Engagement';
				secondValue = influencer.attributes.cpe ?? 0;
				secondValueLabel = 'CPE';
				break;
			case 'engagement-reach-by-influencer':
				value = influencer.attributes.grossReach;
				valueLabel = 'Reach';
				secondValue = influencer.attributes.engagement;
				secondValueLabel = 'Engagement';
				break;
			default:
				value = 0;
				break;
		}

		return {
			id: influencer.id,
			imageUrl: influencer.links?.profileImage,
			follower: influencer.attributes.followers,
			instagramUsername: influencer.attributes.username ?? '',
			value: value,
			valueLabel: valueLabel,
			secondValue: secondValue,
			secondValueLabel: secondValueLabel,
			sign: sign,
			currency: influencer.attributes.currency ?? undefined,
			firstLineValue: 0,
			firstLineValueLabel: '',
			secondLineValue: 0,
			secondLineValueLabel: '',
		};
	});
};

/**
 * @returns {JSX.Element}
 */
const ByInfluencerContainer = ({
	isTopThree,
	fetchData,
	title,
	chartId,
	chartType,
	chartOnDashboard,
	valueType,
	displayCombinedBar,
	displayCombinedBarWithLine,
	chartExplanation,
	isAmount,
}: ChartCardProps<ByInfluencerOutput>): JSX.Element => {
	const [displayTopThree, setDisplayTopThree] = useState<boolean | undefined>(isTopThree);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const manager = useInjection<StatisticsManager>(StatisticsManager);

	let influencerList: InfluencerWithDataForGraphs[] = [];
	let isLoading = true;

	if (!fetchData) {
		isLoading = false;
		influencerList = mapInfluencers(FakeData, valueType);
	} else {
		const { result, error } = fetchData(manager, dashboardFilter);
		if (error) {
			toast.error(getErrorMessageOnFetch('influencer data'));
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			influencerList = mapInfluencers(result.influencers, valueType);
		}
	}

	return (
		<ChartCard chartId={chartId} title={title} chartType={chartType} chartOnDashboard={chartOnDashboard} isLoading={isLoading}>
			{displayCombinedBar ? (
				<Styled.InnerWrapper>
					<CombinedBarChart graphData={influencerList} />
				</Styled.InnerWrapper>
			) : displayCombinedBarWithLine ? (
				<Styled.InnerWrapper>
					<CombinedBarWithLineChart graphData={influencerList} />
				</Styled.InnerWrapper>
			) : (
				<>
					<Styled.InnerWrapper>
						<InfluencerList isAmount={isAmount} influencers={influencerList} isTopThree={displayTopThree} />
					</Styled.InnerWrapper>

					{influencerList?.length > 3 && (
						<Styled.SeeAll
							onClick={() => {
								setDisplayTopThree(!displayTopThree);
							}}
						>
							{displayTopThree ? 'see all' : 'see less'}
						</Styled.SeeAll>
					)}
				</>
			)}
			{chartExplanation && <ExplanationAccordition chartTitle={title} chartExplanation={chartExplanation} />}
		</ChartCard>
	);
};

export default ByInfluencerContainer;
