import classNames from 'classnames';
import { Model } from 'json-api-models';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import PageHeader from 'components/PageHeader';
import TableComponent, { Column, GenericData } from 'components/Table/V2/Table';
import Tabs from 'components/Tabs/Tabs';
import useModels from 'hooks/useModels';
import { pathCampaignDashboard } from 'routing/PathLookup';

import ActionBar from './Components/ActionBar';
import Campaigns from './Components/Campaigns';
import { CampaignCardType } from './Components/Campaigns/types';
import Styled from './DashboardContainer.style';

function mapCampaignItem(campaignItem: Model): CampaignCardType {
	return {
		id: Number(campaignItem.id), //  @todo change to string
		shortId: campaignItem.attributes.shortId,
		name: campaignItem.attributes.name,
		coverImage: {
			sm: campaignItem.links.smallCoverPhoto,
			md: campaignItem.links.mediumCoverPhoto,
			lg: campaignItem.links.largeCoverPhoto,
		},
		/** @deprecated Build the URL yourself */
		campaignUrl: `/campaigns/${campaignItem.shortId}`,
		deleteUrl: campaignItem.links.delete,
		status: campaignItem.attributes.status,
		archivedAt: campaignItem.attributes.archivedAt,
		createdAt: campaignItem.attributes.createdAt,
		brand: campaignItem.brand.name,
	};
}

enum TabType {
	ALL = 'All',
	ACTIVE = 'Collaborations',
	DRAFTS = ' Drafts',
	ARCHIVED = 'Archived',
}

enum Status {
	ACTIVE = 'active',
	DRAFT = 'draft',
	ARCHIVED = 'archived',
}

/**
 * DashboardContainer
 * @returns {JSX.Element}
 */
const DashboardContainer = (): JSX.Element => {
	const { models: featuredCampaigns, mutation } = useModels('/campaigns/featured?includes=brand');
	const {
		models: campaigns,
		isFetching: isFetchingAll,
		mutation: allCampaignsMutation,
	} = useModels('/campaigns?includes=brand&statuses=draft,active&order=createdAt&sort=DESC');

	const { models: archivedCampaigns, mutation: archivedMutation } = useModels('/campaigns?includes=brand&statuses=archived&order=archivedAt&sort=DESC');

	const featuredCampaignItems = featuredCampaigns.findAll('campaign').map(mapCampaignItem);
	const archived = archivedCampaigns.findAll('campaign').map(mapCampaignItem);

	const all = campaigns.findAll('campaign').map(mapCampaignItem);
	const drafts = all.filter((campaign) => campaign.status === Status.DRAFT);
	const active = all.filter((campaign) => campaign.status === Status.ACTIVE);

	const [selectedTab, setSelectedTab] = useState<TabType>(TabType.ALL);
	const [dataToDisplay, setDataToDisplay] = useState<CampaignCardType[]>(all);

	const columns: Column<CampaignCardType>[] = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ row }) => <Styled.Status className={classNames({ active: row.original.status === Status.ACTIVE })}>{row.original.status}</Styled.Status>,
			},
			{
				Header: 'Brand',
				accessor: 'brand',
			},
			{
				Header: 'Created',
				accessor: 'createdAt',
				Cell: ({ row }) => moment(row.original.createdAt).format('YYYY-MM-DD'),
			},
			{
				Header: '',
				accessor: 'id',
				disableSortBy: true,
				Cell: () => (
					<div className='align-right'>
						<Icon name='arrow-right' size='12' />
					</div>
				),
			},
		],
		[],
	);

	const navigate = useNavigate();

	useEffect(() => {
		switch (selectedTab) {
			case TabType.ALL:
				return setDataToDisplay(all);
			case TabType.ACTIVE:
				return setDataToDisplay(active);
			case TabType.DRAFTS:
				return setDataToDisplay(drafts);
			case TabType.ARCHIVED:
				return setDataToDisplay(archived);
			default:
				setDataToDisplay(all);
		}
	}, [selectedTab]);

	useEffect(() => {
		setDataToDisplay(all);
	}, [isFetchingAll]);

	const handleSelectedRow = (values: GenericData) => {
		return navigate(pathCampaignDashboard(`${values.shortId}`));
	};

	return (
		<Styled.Wrapper>
			<PageHeader showBreadcrumb={false} showCurrentDate={false} customHeadline={<>My Campaigns</>}>
				<ActionBar navigate={navigate} />
			</PageHeader>
			<Styled.Content>
				<Campaigns
					dataTestId='featured-campaigns'
					campaigns={featuredCampaignItems}
					refresh={mutation.refresh}
					refreshAll={allCampaignsMutation.refresh}
					refreshArchived={archivedMutation.refresh}
				/>
				<Styled.SearchSection>
					<Tabs
						id='tab-section'
						tabs={[
							{
								title: TabType.ALL,
								amount: all.length,
							},
							{
								title: TabType.ACTIVE,
								amount: active.length,
							},
							{
								title: TabType.DRAFTS,
								amount: drafts.length,
							},
							{
								title: TabType.ARCHIVED,
								amount: archived.length,
							},
						]}
						selectedTab={selectedTab}
						setSelectedTab={(tab: string) => setSelectedTab(tab as TabType)}
					/>
					{isFetchingAll && dataToDisplay.length === 0 ? (
						<Styled.SpinnerContainer>
							<LoadingSpinner />
						</Styled.SpinnerContainer>
					) : (
						<TableComponent
							dataTestId='campaign-table'
							className='on-start-page'
							showSearch
							columns={columns}
							data={dataToDisplay}
							enableSorting={true}
							getSelectedRow={(selectedRow) => handleSelectedRow(selectedRow)}
						/>
					)}
				</Styled.SearchSection>
			</Styled.Content>
		</Styled.Wrapper>
	);
};

export default DashboardContainer;
