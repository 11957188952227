import classNames from 'classnames';
import { Model } from 'json-api-models';
import { useState, useEffect } from 'react';

import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import BlastChatInput from 'components/IntegratedInbox/Components/BlastChatInput';
import SearchResult from 'components/IntegratedInbox/Components/SearchResult';
import { IntegratedInboxCampaign } from 'components/IntegratedInbox/types';

import Styled from './BlastMessageContent.style';

/**
 * @todo test
 */
const BlastMessageContent = (props: {
	campaign: IntegratedInboxCampaign | null;
	campaignInstagramOwners: Model[];
	selectedInfluencers: Model[];
	onSelectInfluencer: (type: string, targetInfluencer?: Model) => void;
	getMessage: (subject: string | null, message: string) => void;
}) => {
	const [searchValue, setSearchValue] = useState<string>('');
	const [searchResult, setSearchResult] = useState<Model[]>([]);
	const [message, setMessage] = useState('');
	const [subject, setSubject] = useState<string | null>(null);

	const searchInfluencerhandleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			const targetInfluencer = props.campaignInstagramOwners
				.filter((campaignInstagramOwner) => {
					return !props.selectedInfluencers.some((selectedInfluencer) => selectedInfluencer.id === campaignInstagramOwner.id);
				})
				.find((campaignInstagramOwner) => campaignInstagramOwner.influencer.username === searchValue);
			if (targetInfluencer) {
				selectHandler('single', targetInfluencer);
				setSearchValue('');
			}
		}
	};

	const searchInfluencerChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const selectHandler = (type: 'single' | 'joined' | 'not-joined' | 'all' | 'remove', targetInfluencer?: Model) => {
		if ((type === 'single' && targetInfluencer) || (type === 'remove' && targetInfluencer)) {
			props.onSelectInfluencer(type, targetInfluencer);
			setSearchValue('');
		} else {
			props.onSelectInfluencer(type);
		}
	};

	useEffect(() => {
		if (searchValue.length > 0) {
			setSearchResult(props.campaignInstagramOwners.filter((campaignInstagramOwner) => campaignInstagramOwner.influencer.username.includes(searchValue)));
		} else {
			setSearchResult([]);
		}
	}, [searchValue]);

	useEffect(() => {
		props.getMessage(subject, message);
	}, [subject, message]);

	return (
		<Styled.Wrapper>
			<Styled.ToWrapper>
				<Styled.RecipientSearchBox className={classNames({ 'has-result': searchResult.length > 0 })}>
					<Styled.RecipientSearchInput>
						<span>To</span>
						<input placeholder='@profile' value={searchValue} onChange={searchInfluencerChangeHandler} onKeyDown={searchInfluencerhandleOnKeyPress} />
					</Styled.RecipientSearchInput>
					{searchResult.length > 0 ? (
						<SearchResult
							campaignInfluencerOwners={searchResult}
							onSelect={(targetInfluencer: Model) => {
								selectHandler('single', targetInfluencer);
							}}
							selectedInfluencers={props.selectedInfluencers}
						/>
					) : null}
				</Styled.RecipientSearchBox>
				{props.selectedInfluencers.length > 0 && (
					<Styled.SelectedInfluencers>
						<div className='influencers'>
							{props.selectedInfluencers.map((campaignInstagramOwner) => {
								return (
									<Styled.SelectedInfluencerTag key={campaignInstagramOwner.id}>
										<Avatar imageUrl={campaignInstagramOwner.influencer.links.profilePictureUrl} name={campaignInstagramOwner.influencer.username} size='sm' />
										<div className='name'>{campaignInstagramOwner.influencer.username}</div>
										<div
											onClick={() => {
												selectHandler('remove', campaignInstagramOwner);
											}}
										>
											<Icon name='cross' size='8' />
										</div>
									</Styled.SelectedInfluencerTag>
								);
							})}
						</div>
					</Styled.SelectedInfluencers>
				)}
				<Styled.AddShortCut>
					<span>Select</span>
					<div
						className='shortcut'
						onClick={() => {
							selectHandler('joined');
						}}
					>
						Joined
					</div>
					<div
						className='shortcut'
						onClick={() => {
							selectHandler('not-joined');
						}}
					>
						Not joined
					</div>
					<div
						data-testid='blast-message-select-all'
						className='shortcut'
						onClick={() => {
							selectHandler('all');
						}}
					>
						All
					</div>
				</Styled.AddShortCut>
			</Styled.ToWrapper>
			<BlastChatInput
				getMessage={(subject, message) => {
					setMessage(message), setSubject(subject);
				}}
			/>
			<Styled.HelpText>Message will be sent as an email and show up in their messages if they already have an account.</Styled.HelpText>
		</Styled.Wrapper>
	);
};

export default BlastMessageContent;
