import classNames from 'classnames';

import Icon from 'components/Icon';

import Styled from './ErrorMessage.style';

type ErrorMessageProps = {
	message: string;
	isError?: boolean;
};
/**
 * AddContentUrl
 * @returns {JSX.Element}
 */
const Message = ({ message, isError }: ErrorMessageProps): JSX.Element => {
	return (
		<Styled.Div className={classNames({ error: isError })}>
			<Icon name={!isError ? 'check-circle' : 'alert'} size='16' />
			<p>{message}</p>
		</Styled.Div>
	);
};

export default Message;
