import Icon from 'components/Icon';

import Styled from './UploadCSVFileBlock.style';

type Props = {
	onClick: () => void;
	disabled?: boolean;
};

/**
 * @param {Props} props
 * @returns {JSX.Element}
 */
const UploadCSVFileBlock = ({ onClick, disabled }: Props) => {
	return (
		<Styled.Wrapper data-testid='upload-csv-file-block'>
			<Styled.InnerWrapper>
				<Styled.Button onClick={onClick} disabled={disabled}>
					<Icon name='upload' size='20' />
					<span>Import CSV file</span>
				</Styled.Button>
				<Styled.HelpText>Import CSV files from Instagram or TikTok.</Styled.HelpText>
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default UploadCSVFileBlock;
