/* eslint-disable import/no-unassigned-import */
// eslint-disable-next-line import/order
import 'reflect-metadata'; // This should be the first import. It is for our dependency injection to work.
import bugsnag from 'bugsnag-js';
// @ts-ignore
import createPlugin from 'bugsnag-react';
import 'core-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';

import { BugsnagContext, ThemeContextProvider } from 'contexts';
import 'services/prototype';
import { store } from 'store/User';

import App from './App';
import './services/moment';

const bugsnagClient = bugsnag({
	apiKey: 'c2e978af0b1dc628376202ef81d40e47',
	appVersion: process.env.VITE_APP_VERSION || 'no-version',
	releaseStage: process.env.VITE_APP_RELEASE_STAGE || 'development',
	notifyReleaseStages: ['production', 'staging'],
});

const ErrorBoundary = bugsnagClient.use(createPlugin(React));

const content = (
	<BugsnagContext.Provider value={{ client: bugsnagClient }}>
		<ErrorBoundary>
			<Provider store={store}>
				<IntlProvider locale={'en'}>
					<ThemeContextProvider>
						<App />
					</ThemeContextProvider>
				</IntlProvider>
			</Provider>
		</ErrorBoundary>
	</BugsnagContext.Provider>
);

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(content);
