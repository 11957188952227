import { Model } from 'json-api-models';
import { generatePath } from 'react-router';
import { Link, useParams } from 'react-router-dom';

import Avatar from 'components/Avatar';

import Styled from './InfluencerItem.style';

type InfluencerItemProps = {
	profileImage: string;
	username: string;
	cioId: string;
	assignment?: Model;
};

/**
 * InfluencerItem
 * @param {InfluencerItemProps} props
 * @returns {JSX.Element}
 */
const InfluencerItem = ({ cioId, profileImage, username, assignment }: InfluencerItemProps): JSX.Element => {
	const { campaignId } = useParams();
	const url = assignment
		? generatePath('/content-management/:campaignId/:ownerId/:assignmentId', {
				campaignId: campaignId!,
				ownerId: cioId,
				assignmentId: assignment.assignment.id,
			})
		: generatePath('/content-management/:campaignId/:ownerId', {
				campaignId: campaignId!,
				ownerId: cioId,
			});
	return (
		<Link to={url}>
			<Styled.ListItemContainer data-testid='cm-influencer-list-item'>
				<Styled.Wrapper>
					<Avatar imageUrl={profileImage} name={username} />
					<Styled.TextContainer>
						<Styled.TruncatedText>{username}</Styled.TruncatedText>
						{assignment && <>{assignment.assignment.attributes.name}</>}
					</Styled.TextContainer>
				</Styled.Wrapper>
			</Styled.ListItemContainer>
			<Styled.Divider />
		</Link>
	);
};

export default InfluencerItem;
