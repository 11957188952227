import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import HeaderStyles from 'components/Header/Header.style';
import Icon from 'components/Icon';

import Styled from './HeaderDropdown.style';
import { IDropdownProps } from './types';

/**
 * Header dropdown
 * @param {IDropdownProps} props
 * @returns {JSX.Element}
 */
const HeaderDropdown = ({
	isUserMenu,
	unsetShowAllNotifications,
	actionButtonIcon,
	userDropdownOpen,
	setUserDropdownOpen,
	children,
	className,
}: IDropdownProps): JSX.Element => {
	const ActionDropdownRef = useRef<HTMLDivElement>(null);
	const ActionButtonRef = useRef<HTMLButtonElement>(null);

	const toggleDropdown = () => {
		setUserDropdownOpen((prevOpen: boolean) => !prevOpen);
	};

	const closeDropdown = () => {
		setUserDropdownOpen(false);
		unsetShowAllNotifications && unsetShowAllNotifications();
	};

	const handleActionButtonClick = () => {
		if (userDropdownOpen) {
			closeDropdown();
		} else {
			toggleDropdown();
		}
	};

	const handleClickOutside = (e: MouseEvent): void => {
		if (ActionDropdownRef.current && !ActionDropdownRef.current.contains(e.target as Node)) {
			closeDropdown();
		}
	};

	const handleEscapeKey = (e: KeyboardEvent): void => {
		if (e.key === 'Escape') {
			closeDropdown();
		}
	};

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (ActionButtonRef.current && ActionButtonRef.current.contains(e.target as Node)) {
				return;
			}
			handleClickOutside(e);
		};

		document.addEventListener('mousedown', handleClick);
		document.addEventListener('keydown', handleEscapeKey);

		return () => {
			document.removeEventListener('mousedown', handleClick);
			document.removeEventListener('keydown', handleEscapeKey);
		};
	}, []);

	return (
		<Styled.ActionDropdownWrapper className={classNames({ show: userDropdownOpen }, className)}>
			<HeaderStyles.ActionButton data-testid='dropdownMenuButton' onClick={handleActionButtonClick} aria-haspopup={true} ref={ActionButtonRef}>
				{actionButtonIcon}
			</HeaderStyles.ActionButton>
			{isUserMenu && (
				<>
					<Styled.ActionDropDownArrow onClick={handleActionButtonClick} role='button' data-testid='dropdownMenuButtonArrow'>
						<Icon name={userDropdownOpen ? 'arrow-up' : 'arrow-down'} size='10' />
					</Styled.ActionDropDownArrow>
					<Styled.OverlayColor />
					<Styled.Overlay />
				</>
			)}
			<Styled.ActionDropdownMenu ref={ActionDropdownRef} className={classNames({ isNotificationMenu: !isUserMenu }, 'dropdown-item')}>
				{children}
			</Styled.ActionDropdownMenu>
		</Styled.ActionDropdownWrapper>
	);
};

export default HeaderDropdown;
