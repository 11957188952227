import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';

const cm = colors.contentManagement;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	cursor: pointer;
	transition: all 200ms ease-in-out;
	color: ${colors.black1};
	&.selected,
	&:hover {
		background-color: ${cm.campaignList.listItem.hover.backgroundColor};
		color: ${cm.campaignList.listItem.hover.color};
		border-radius: ${borderRadius.s};
	}
`;

const ListItemContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	margin: 4px 0;
`;

const TextContainer = styled.div`
	flex: 1;
	margin-left: 10px;
`;

const TruncatedText = styled.span`
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	font-size: 0.875rem;
	font-weight: 600;
	max-width: 288px;

	${mediaQueries.small`
		max-width: 100%;
	`};

	${mediaQueries.xl`
		max-width: 205px;
	`};
`;

const Divider = styled.div`
	margin-left: 80px;
	height: 1px;
`;

const Styled = {
	Wrapper,
	ListItemContainer,
	TextContainer,
	TruncatedText,
	Divider,
};

export default Styled;
