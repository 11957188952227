import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const IconWrpper = styled.button<{ helpText?: string }>`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: none;

	background-color: transparent;

	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	&:hover {
		background-color: ${newColors.ash};
	}

	.icon {
		line-height: 0;
	}
`;

const HelpText = styled.span`
	display: none;
	justify-content: center;
	cursor: default;
	position: absolute;

	left: 0;
	right: 0;
	z-index: 10;

	& > span {
		border-radius: 4px;
		white-space: nowrap;
		font-size: 0.75rem;
		padding: ${spacing[1].rem};
		background-color: ${colors.discovery.helpText.background};
		color: ${colors.discovery.helpText.color};
		transition: background-color 0.1s ease-in-out;
	}
`;

const Wrapper = styled.div`
	position: relative;
	cursor: pointer;

	${IconWrpper} {
		transition: background-color 0.2s ease-in-out;
	}

	&:hover {
		${HelpText} {
			display: flex;
		}
	}
`;

const Styled = {
	Wrapper,
	IconWrpper,
	HelpText,
};

export default Styled;
