import { useEffect, useState } from 'react';

import Icon from 'components/Icon';
import { AssignmentStatus } from 'shared/Types/Assignment';

import Styled from './Help.style';

type HelpProps = {
	onContent?: boolean;
	onStats?: boolean;
	newFancyStatus?: AssignmentStatus;
};

/**
 * Help section
 * @returns {JSX.Element}
 */
const Help = ({ onContent, onStats, newFancyStatus }: HelpProps): JSX.Element => {
	const [isDisplayHelp, setIsDisplayHelp] = useState(false);

	useEffect(() => {
		if (onStats) {
			setIsDisplayHelp(true);
		}
		return () => {
			setIsDisplayHelp(false);
		};
	}, []);

	useEffect(() => {
		if (newFancyStatus === AssignmentStatus.WAITING_FOR_UPLOAD) {
			setIsDisplayHelp(true);
		}

		if (newFancyStatus === AssignmentStatus.IN_REVIEW) {
			setIsDisplayHelp(false);
		}
	}, [newFancyStatus]);

	return (
		<Styled.CustomAccordion
			hideChevron
			open={isDisplayHelp}
			title={
				isDisplayHelp ? (
					<Styled.HelpTitle>
						<Icon name='info' /> <h4>{onContent ? 'How it works' : onStats ? 'How to add statistics' : ''}</h4>
						<Styled.HelpLink data-testid='help-close' onClick={() => setIsDisplayHelp(false)}>
							{isDisplayHelp ? (
								<>
									Close help <Icon name='cross' size='10' />{' '}
								</>
							) : (
								'Help'
							)}
						</Styled.HelpLink>
					</Styled.HelpTitle>
				) : (
					<Styled.HelpButton data-testid='help-show' onClick={() => setIsDisplayHelp(true)}>
						Help <Icon name='help' size='18' />
					</Styled.HelpButton>
				)
			}
		>
			<Styled.HelpText>
				<ol>
					{onContent ? (
						<>
							<li>
								Select your images or videos by clicking <strong>Browse files.</strong>
							</li>
							<li>Write a compelling caption that aligns with the campaign description found in the brief.</li>
							<li>Submit your draft. We’ll review as soon as possible and get back to you with our feedback.</li>
						</>
					) : onStats ? (
						<>
							<li>Add the link to the post and fill in the numbers.</li>
							<li>Upload screenshots of your post statistics.</li>
						</>
					) : null}
				</ol>
				{onContent && <p>It&apos;s that easy! Thank you for your participation!</p>}
			</Styled.HelpText>
		</Styled.CustomAccordion>
	);
};

export default Help;
