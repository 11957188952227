import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Influencer } from 'views/Campaign/Dashboard/Segments/Components/SidebarSegmentContainer/Components/SegmentLists/types';

type SelectedItem = {
	listId: string;
	influencers: Influencer[];
	isChecked: boolean;
	isIndeterminate: boolean;
};

type SliceState = {
	selectedItems: SelectedItem[];
};

export const initialState: SliceState = {
	selectedItems: [],
};

const findList = (state: SliceState, listId: string): SelectedItem | undefined => state.selectedItems.find((item) => item.listId === listId);

const removeEmptyLists = (state: SliceState): void => {
	state.selectedItems = state.selectedItems.filter((item) => item.influencers.length > 0);
};

const SegmentSlice = createSlice({
	name: 'SegmentSlice',
	initialState,
	reducers: {
		addLists(state, action: PayloadAction<Array<{ listId: string; influencers: Influencer[] }>>) {
			const incomingIds = new Set(action.payload.map((list) => list.listId));
			state.selectedItems = state.selectedItems.filter((item) => incomingIds.has(item.listId));
			action.payload.forEach((list) => {
				const existingIndex = state.selectedItems.findIndex((item) => item.listId === list.listId);
				if (existingIndex === -1) {
					state.selectedItems.push({
						...list,
						isChecked: false,
						isIndeterminate: false,
					});
				}
			});
		},
		addInfluencers(state, action: PayloadAction<{ listId: string; influencers: Influencer[] }>) {
			const selectedItem = findList(state, action.payload.listId);

			if (selectedItem) {
				const newInfluencers = action.payload.influencers.filter((influencer) => !selectedItem.influencers.includes(influencer));
				selectedItem.influencers.push(...newInfluencers);
			} else {
				state.selectedItems.push({
					listId: action.payload.listId,
					influencers: action.payload.influencers,
					isChecked: false,
					isIndeterminate: false,
				});
			}

			removeEmptyLists(state);
		},
		removeInfluencers(state, action: PayloadAction<{ listId: string; influencers: Influencer[] }>) {
			const selectedItem = findList(state, action.payload.listId);
			if (selectedItem) {
				const influencersToRemove = new Set(action.payload.influencers.map((inf) => inf.id));
				selectedItem.influencers = selectedItem.influencers.filter((influencer) => !influencersToRemove.has(influencer.id));
			}

			removeEmptyLists(state);
		},
		clearItems(state) {
			state.selectedItems = [];
		},
	},
});

export const { addLists, addInfluencers, removeInfluencers, clearItems } = SegmentSlice.actions;

export default SegmentSlice.reducer;
