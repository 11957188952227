import { DashboardModel, DashboardPaidMediaModel, DashboardUserModel } from 'api-models';
import IRepository from 'utils/Repository/IRepository';
import { DashboardType } from 'views/DataLibrary/reducers/types';

export default function convertDashboardModelToDashboardType(
	repository: IRepository,
	result: DashboardModel,
): {
	paidMedia: DashboardPaidMediaModel[];
	dashboardUsers: DashboardUserModel[];
	dashboard: DashboardType;
} {
	const dashboardUsers = repository.findByRelation<DashboardUserModel, DashboardModel>(result, 'dashboardUsers');
	const paidMedia = repository.findByRelation<DashboardPaidMediaModel, DashboardModel>(result, 'paidMedia');

	const dashboard: DashboardType = {
		id: result.id,
		name: result.attributes.name,
		active: result.attributes.active,
		createdAt: result.attributes.createdAt,
		items: result.attributes.items,
		dashboardUsers: dashboardUsers ?? [],
		metaData: result.attributes.metaData,
		links: result.links ?? {},
		paidMedia: paidMedia ?? [],
		hasPaidMedia: result.attributes.hasPaidMedia,
		uniqueInfluencers: result.attributes.uniqueInfluencers,
	};

	return { paidMedia, dashboardUsers, dashboard };
}
