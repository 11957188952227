import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgWarning(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path d='M11.5264 11.7306V6.88184H10.3835V11.7306H11.5264Z' fill='#A35018' />
			<path
				d='M11.9548 13.8818C11.9548 14.4341 11.5071 14.8818 10.9548 14.8818C10.4025 14.8818 9.95483 14.4341 9.95483 13.8818C9.95483 13.3296 10.4025 12.8818 10.9548 12.8818C11.5071 12.8818 11.9548 13.3296 11.9548 13.8818Z'
				fill='#A35018'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.4468 2.99155L2.77472 16.458C2.55952 16.8358 2.83961 17.2999 3.28272 17.2999H18.6269C19.0701 17.2999 19.3501 16.8358 19.135 16.458L11.4628 2.99154L12.9868 2.16592L20.659 15.6324C21.5197 17.1433 20.3994 19 18.6269 19H3.28272C1.51029 19 0.389938 17.1433 1.2507 15.6324L8.92282 2.16592C9.80848 0.611361 12.1012 0.611359 12.9868 2.16592L11.4628 2.99154C11.2414 2.60291 10.6682 2.6029 10.4468 2.99155Z'
				fill='#A35018'
			/>
		</svg>
	);
}
