import { injectable } from 'inversify';
import { mutate } from 'swr';

import { ApiSingleResponse, ResourceObject } from 'services/ApiClient/types';

import { SWRMutateConfig } from './types';

/**
 * This class keeps track of all Models and SWR cache keys.
 * It will revalidate/refresh the SWR cache when a Model is updated/Removed.
 *
 * There is only one instance of this class in the application.
 */
@injectable()
class ApiCacheManager {
	/**
	 * SWR cache keys indexed by the model type and id.
	 */
	private swrKeys: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();

	public clear(): void {
		this.swrKeys.clear();
	}

	/**
	 * Add an SWR cache key to the manager
	 */
	public addSWRKey(modelType: string, modelId: string, key: string): void {
		const model = this.modelKey(modelType, modelId);
		if (!this.swrKeys.has(model)) {
			this.swrKeys.set(model, new Map<string, boolean>());
		}
		// This will avoid duplicates
		this.swrKeys.get(model)?.set(key, true);
	}

	private modelKey(modelType: string, modelId: string): string {
		return `${modelType}::${modelId}`;
	}

	public deleteModel(type: string, id: string): void {
		this.swrKeys.get(this.modelKey(type, id))?.forEach((_bool, swrKey) => {
			this.revalidateSWR(swrKey);
		});
	}

	/**
	 * Before we delete a model, tell SWR to revalidate the relevant keys (with placeholder)
	 */
	public optimisticDeleteModel(type: string, id: string, apiDelete: Promise<void>, config: SWRMutateConfig): Promise<void> {
		const promises: Promise<void>[] = [];

		// Tell all keys to revalidate.
		this.swrKeys.get(this.modelKey(type, id))?.forEach((_bool, swrKey) => {
			promises.push(mutate(swrKey, apiDelete, config));
		});

		if (promises.length === 0) {
			return apiDelete;
		}

		// Since there is only one HTTP call (promise), we just need to wait for the first one.
		return Promise.race(promises);
	}

	/**
	 * After you updated a model, make sure to revalidate the cache
	 * @param model Updated model
	 */
	public updateModel(model: ResourceObject): void {
		this.updateModelById(model.type, model.id);
	}

	/**
	 * Update a model by type and ID.
	 */
	public updateModelById(type: string, id: string): void {
		this.swrKeys.get(this.modelKey(type, id))?.forEach((_bool, swrKey) => {
			this.revalidateSWR(swrKey);
		});
	}

	/**
	 * Before we update a model, Find all relevant SWR keys and tell them to revalidate (with placeholder)
	 */
	public optimisticUpdateModel(
		type: string,
		id: string,
		apiUpdate: Promise<ApiSingleResponse>,
		config: SWRMutateConfig,
	): Promise<ApiSingleResponse | undefined> {
		const promises: Promise<ApiSingleResponse | undefined>[] = [];

		// Tell all keys to revalidate.
		this.swrKeys.get(this.modelKey(type, id))?.forEach((_bool, swrKey) => {
			promises.push(mutate(swrKey, apiUpdate, config));
		});

		if (promises.length === 0) {
			return apiUpdate;
		}

		// Since there is only one HTTP call (promise), we just need to wait for the first one.
		return Promise.race(promises);
	}

	/**
	 * Revalidate the SWR cache for a given key
	 */
	private revalidateSWR(swrKey: string): void {
		// tell all SWRs with this key to revalidate
		mutate(swrKey);
	}
}

export default ApiCacheManager;
