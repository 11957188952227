import moment from 'moment';

import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
// import { CREATE_COMMENT } from 'constants/hateoas-keys';
// import useFeaturePermissions from 'hooks/FeaturePermissions';

/**
 * @returns {JSX.Element}
 */
const Heading = (props: { onClickComments?: () => void; links: { [key: string]: string }; postedAt?: string }): JSX.Element => {
	// const { userCan } = useFeaturePermissions(props.links);
	// const CAN_COMMENT = userCan(CREATE_COMMENT);

	return (
		<Styled.HeadingWrapper>
			<Styled.Heading>
				<div>
					<h5>Upload statistics</h5>
					<p>{props.postedAt && `Post date ${moment(props.postedAt).format('YYYY-MM-DD')}`}</p>
				</div>
				{/* Add comments later */}
				{/* {CAN_COMMENT && (
					<Styled.CommentsButton onClick={() => props.onClickComments()} data-testid='cm-comments' type='button'>
						<Icon name='message-empty' size='20' />
					</Styled.CommentsButton>
				)} */}
			</Styled.Heading>
		</Styled.HeadingWrapper>
	);
};

export default Heading;
