import InMemoryStorage, { instance } from './InMemoryStorage';

export enum StorageType {
	SESSION = 'session',
	LOCAL = 'local',
	IN_MEMORY = 'in_memory',
}

/**
 * BrowserStorage class
 * Description: Enables CRUD against session or local storage
 */
class BrowserStorage {
	storage: Storage;

	constructor(storageType = StorageType.LOCAL) {
		if ('test' === process.env.NODE_ENV) {
			this.storage = instance;
		} else {
			switch (storageType) {
				case StorageType.SESSION:
					this.storage = window.sessionStorage;
					break;
				case StorageType.LOCAL:
					this.storage = window.localStorage;
					break;
				case StorageType.IN_MEMORY:
					this.storage = new InMemoryStorage();
					break;
			}
		}
	}

	getItem(key: string): string | null {
		const value = this.storage.getItem(key);
		const expirationKey = `${key}_expiresAt`;
		const expirationDate = this.storage.getItem(expirationKey);

		if (expirationDate && new Date(expirationDate) < new Date()) {
			this.removeItem(key);
			this.removeItem(expirationKey);

			return null;
		}

		return value;
	}

	setItem(key: string, value: string, expiresIn?: number) {
		this.storage.setItem(key, value);

		if (expiresIn) {
			const now = new Date();
			this.storage.setItem(`${key}_expiresAt`, new Date(now.getTime() + expiresIn).toISOString());
		}
	}

	removeItem(key: string) {
		return this.storage.removeItem(key);
	}

	clear() {
		this.storage.clear();
	}

	getAllKeys() {
		return Object.keys(this.storage);
	}

	multiGet(keys: string[]) {
		return keys.map((key) => [key, this.storage.getItem(key)]);
	}

	multiSet(keyValuePairs: string[][]) {
		keyValuePairs.forEach((keyValuePair) => this.storage.setItem(keyValuePair[0], keyValuePair[1]));
		return keyValuePairs.map((keyValuePair) => [keyValuePair[0], this.storage.getItem(keyValuePair[0])]);
	}
}

export default BrowserStorage;
