import classNames from 'classnames';
import { Model } from 'json-api-models';
import moment from 'moment';
import { useRef, useState, useEffect, KeyboardEvent } from 'react';

import Icon from 'components/Icon';
import Tabs from 'components/Tabs/Tabs';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useAutosizeTextArea from 'hooks/useAutosizeTextArea';
import usePermissions from 'hooks/usePermissions';
import toast from 'services/Toast';
import { isMobile } from 'shared/utils/navigator';
import colors from 'styles/theme/colors';

import Styled from './Comments.style';
import { CommentsProps, CommentType, selectedTabType } from './types';

const Comments = ({
	user,
	canUseTabs,
	comments = [],
	disabled = false,
	activeTab = selectedTabType.Influencer,
	onClickTab,
	onSend,
}: CommentsProps): JSX.Element => {
	const [value, setValue] = useState<string>('');
	const [selectedTab, setSelectedTab] = useState<selectedTabType>(activeTab);
	const [hidePlaceholder, setHidePlaceholder] = useState(false);
	const [displaySendButton, setDisplaySendButton] = useState<boolean>(false);
	const [localComments, setLocalComments] = useState<(Model | CommentType)[]>(comments);

	const { isInfluencer } = usePermissions();

	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const commentsRef = useRef<HTMLDivElement>(null);
	const messageFieldRef = useRef<HTMLDivElement>(null);
	const lastMessageRef = useRef<HTMLDivElement>(null);

	useAutosizeTextArea(textAreaRef.current, value);

	useEffect(() => {
		setDisplaySendButton(/\S/.test(value));
	}, [value]);

	useEffect(() => {
		setLocalComments(comments);
	}, [comments]);

	useEffect(() => {
		setSelectedTab(activeTab);
	}, [activeTab]);

	const clearMessageField = () => {
		if (messageFieldRef.current) {
			messageFieldRef.current.textContent = '';
			setHidePlaceholder(false);
		}
	};

	const scrollToLastMessage = () => {
		if (lastMessageRef.current) {
			lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const onClickSend = async () => {
		if (!value) return;

		const newComment = {
			id: `temp-${Date.now()}`, // temporary ID
			attributes: {
				text: value,
				createdAt: new Date().toISOString(),
			},
			user: {
				attributes: {
					initials: user.initials,
					firstName: user.name,
					uuid: user.uuid,
				},
			},
		};

		setLocalComments((prevComments) => [...prevComments, newComment]);
		clearMessageField();
		setValue('');

		try {
			await onSend(value);
		} catch (e) {
			console.error(e);
			toast.error(getErrorMessageOnPost('sending your comment'));
			// Optionally remove the temp comment on failure
			setLocalComments((prevComments) => prevComments.filter((comment) => comment.id !== newComment.id));
		}
	};

	const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (!/\S/.test(value) && e.key === 'Enter') {
			e.preventDefault();
			scrollToLastMessage();
			return false;
		}

		if (!isMobile() && e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			onClickSend();
			return false;
		}

		if (e.key.length === 1) {
			setHidePlaceholder(true);
		}

		return true;
	};

	const onKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
		if (!/\S/.test(e.currentTarget.textContent || '')) {
			setHidePlaceholder(false);
		}
	};

	const formatDate = (dateString: string) => {
		const date = moment(dateString);
		if (date.isSame(moment(), 'day')) {
			return `Today, ${date.format('HH:mm')}`;
		}
		return date.format('DD MMM, HH:mm');
	};

	return (
		<>
			{canUseTabs && (
				<Tabs
					tabs={[
						{
							title: selectedTabType.Influencer,
						},
						{
							title: selectedTabType.BrandManager,
							testId: 'cm-comment-bm-tab',
						},
					]}
					selectedTab={selectedTab}
					setSelectedTab={(tab: string) => {
						setSelectedTab(tab as selectedTabType);
						onClickTab && onClickTab(tab as selectedTabType);
					}}
				/>
			)}
			<Styled.CommentsContainer data-testid='cm-comment-box'>
				<Styled.CommentsList ref={commentsRef} className='cm-comments'>
					{localComments.length === 0 && (
						<Styled.DefaultText>
							<span>
								{isInfluencer()
									? 'This is the start of your conversation.'
									: 'Add your first comment to request changes or provide updates about this assignment.'}
							</span>
						</Styled.DefaultText>
					)}
					{localComments.map((comment: Model | CommentType, index: number) => (
						<Styled.MessageRow
							role='row'
							key={comment.id}
							data-testid={!comment.user.attributes?.uuid ? 'temp' : `fc-comment-${index}`}
							className={classNames({ latest: index === localComments.length - 1 })}
							ref={index === localComments.length - 1 ? lastMessageRef : null} // Set ref to last message
						>
							<Styled.UserAvatar role='presentation'>
								<Styled.CommentAvatar
									name={!comment.user.attributes?.uuid ? user.initials : comment.user.attributes?.initials}
									backgroundColor={comment.user.attributes?.uuid === user.uuid || !comment.user.attributes?.uuid ? colors.paleGreenTint : colors.ash}
								/>
							</Styled.UserAvatar>
							<Styled.UserMessage role='presentation'>
								<div data-testid='cm-comment-text'>{comment.attributes.text}</div>
								<Styled.MessageStatus>
									{!comment.user.attributes?.uuid ? user.firstName : comment.user.attributes?.firstName} - {formatDate(comment.attributes?.createdAt)}
								</Styled.MessageStatus>
							</Styled.UserMessage>
						</Styled.MessageRow>
					))}
				</Styled.CommentsList>
				<div>
					<Styled.ThreadTool>
						<Styled.AvatarContainer>
							<Styled.CommentAvatar name={user.name!} backgroundColor={colors.paleGreenTint} />
						</Styled.AvatarContainer>
						<Styled.MessageFieldContainer>
							<Styled.MessageField
								aria-label='message'
								contentEditable='true'
								role='textbox'
								data-testid='input-comment'
								spellCheck='true'
								onInput={(e) => setValue(e.currentTarget.textContent ?? '')}
								onKeyDown={onKeyDown}
								onKeyUp={onKeyUp}
								ref={messageFieldRef}
								onBlur={(e) => !e.currentTarget.textContent && setHidePlaceholder(false)}
							/>
							{!hidePlaceholder && (
								<Styled.MessageFieldPlaceholder onClick={() => messageFieldRef.current?.focus()}>Leave a comment</Styled.MessageFieldPlaceholder>
							)}
							<Styled.SendMessage>
								<Styled.SendButton onClick={onClickSend} disabled={disabled || !displaySendButton} data-testid='submit-comment'>
									<Icon name='send' size='20' />
								</Styled.SendButton>
							</Styled.SendMessage>
						</Styled.MessageFieldContainer>
					</Styled.ThreadTool>
				</div>
			</Styled.CommentsContainer>
		</>
	);
};

export default Comments;
