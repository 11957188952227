export function getQueryVariable(location: Location, variable: string): string | null {
	const query = location.search.substring(1);
	const queryParams = query.split('&');
	const [code] = queryParams
		.map((queryParam) => {
			const params = queryParam.split('=');
			return params[0] === variable ? params[1] : null;
		})
		.filter((isCodeAvailable) => isCodeAvailable);

	return code;
}

export const isValidUrl = (_string: string) => {
	const matchpattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/gm;
	return matchpattern.test(_string);
};
