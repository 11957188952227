import styled from 'styled-components';

interface ContainerBar {
	containerBg: string;
}

interface FillerBar {
	percent: string;
	fillerBg: string;
}

const ContainerBar = styled.div<ContainerBar>`
	width: 100%;
	min-height: 8px;
	background-color: ${(props) => props.containerBg};
	display: flex;
	border-radius: 16px;
`;

const FillerBar = styled.div<FillerBar>`
	width: ${(props) => props.percent};
	background-color: ${(props) => props.fillerBg};
	border-radius: 16px;
`;

const Styled = {
	ContainerBar,
	FillerBar,
};

export default Styled;
