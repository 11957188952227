/**
 * This file contains a list of paths that we are using.
 *
 * Using this file will make it easier to find and update paths. We will
 * also know what path we are using.
 */

export function pathInfluencerManagement(): string {
	return pathInfluencerManagementFolder(null);
}

export function pathInfluencerManagementFolder(folderId: string | null): string {
	if (!folderId) {
		return `/influencer-management/folders`;
	}

	return `/influencer-management/folders/${folderId}`;
}

export function pathInfluencerManagementList(listId: string): string {
	return `/influencer-management/lists/${listId ?? ''}`;
}

export function pathDashboard(): string {
	return `/dashboard`;
}

export function pathCampaignDashboard(campaignId: string): string {
	return `/campaigns/${campaignId}`;
}

export function pathCampaignEdit(campaignId: string): string {
	return `/campaigns/${campaignId}/edit`;
}

/**
 * This page has a "thank you" page for the influencer
 */
export function pathInfluencerCampaignJoined(campaignId: string): string {
	return `/influencer/campaigns/${campaignId}/joined`;
}

export function pathInfluencerDashboard(): string {
	return `/influencer/dashboard`;
}
export function pathInfluencerCampaignView(campaignId: string): string {
	return `/influencer/campaigns/${campaignId}`;
}

export function pathCampaignLaunch(campaignId: string): string {
	return `/campaigns/${campaignId}/launch`;
}

export function pathCommissionShow(campaignId: string, commissionId: string): string {
	return `/campaigns/${campaignId}/commissions/${commissionId}`;
}

export function pathCommissionCopy(campaignId: string, commissionId: string): string {
	return `/campaigns/${campaignId}/commissions/${commissionId}/copy`;
}

export function pathCommissionAdd(campaignId: string): string {
	return `/campaigns/${campaignId}/commissions/add`;
}

export function pathAssignmentShow(campaignId: string, assignmentId: string): string {
	return `/campaigns/${campaignId}/assignments/${assignmentId}`;
}

export function pathAssignmentCopy(campaignId: string, assignmentId: string): string {
	return `/campaigns/${campaignId}/assignments/${assignmentId}/copy`;
}

export function pathAssignmentAdd(campaignId: string): string {
	return `/campaigns/${campaignId}/assignments/add`;
}

export function pathProductShow(campaignId: string, productId: string): string {
	return `/campaigns/${campaignId}/products/${productId}`;
}

export function pathProductCopy(campaignId: string, productId: string): string {
	return `/campaigns/${campaignId}/products/${productId}/copy`;
}

export function pathProductAdd(campaignId: string): string {
	return `/campaigns/${campaignId}/products/add`;
}

export function pathSegmentAdd(campaignId: string): string {
	return `/campaigns/${campaignId}/segments/add`;
}

export function pathSegmentShow(campaignId: string, segmentId: string): string {
	return `/campaigns/${campaignId}/segments/${segmentId}`;
}

/**
 * This is a publisher previewing an brew as an influencer
 */
export function pathCampaignPreviewBrief(campaignId: string, influencerId: string | undefined = undefined): string {
	if (influencerId) {
		return `/campaigns/${campaignId}/preview/${influencerId}`;
	}
	return `/campaigns/${campaignId}/preview`;
}

export function pathConversationsCampaign(campaignId: string, conversationId: string): string {
	return `/inbox/${campaignId}/${conversationId}`;
}
export function pathInfluencerConversationsCampaign(campaignId: string, conversationId: string): string {
	return `/influencer/inbox/${campaignId}/${conversationId}`;
}

export function pathContentAssignment(campaignId: string, ownerId: string, assignmentId: string): string {
	return `/content-management/${campaignId}/${ownerId}/${assignmentId}`;
}

export function pathInfluencerContentAssignment(campaignId: string, ownerId: string, assignmentId: string): string {
	return `/influencer/content-management/${campaignId}/${ownerId}/${assignmentId}`;
}

export function pathSettingsClients(): string {
	return `/settings/clients`;
}

export function pathSettingsClientShow(clientId: string): string {
	return `/settings/clients/${clientId}`;
}

export function pathSettingsBrandShow(clientId: string, brandId: string): string {
	return `/settings/clients/${clientId}/brands/${brandId}`;
}

export function pathInfluencerSettingsInstagram(): string {
	return '/influencer/settings/instagram';
}

export function pathDiscovery(): string {
	return `/discovery`;
}
