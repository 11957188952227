import { css } from 'styled-components';

import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const wrapperPadding = `${spacing[1].rem} ${spacing[1].rem}`;

export const Table = css`
	width: 100% !important;
`;

export const TableHeader = css``;

export const TableBody = css``;

export const Th = css`
	font-family: ${typography.list.accessory.fontFamilies};
	font-size: ${typography.list.medium.fontSize};
	font-weight: ${typography.list.accessory.fontWeight};
	text-align: left;
	padding: ${wrapperPadding};
	padding-top: 0.8rem;
	white-space: nowrap;
`;

export const Tr = css`
	width: 100%;
	overflow-x: auto;
	background-color: ${colors.white};
	border: 2px solid #888;
	border-left: none;
	border-right: none;
	border-bottom: none;
`;

export const Td = css`
	padding: ${wrapperPadding};
	text-align: left;
`;
