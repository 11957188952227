import { Player } from '@lottiefiles/react-lottie-player';
import { AxiosError } from 'axios';
import { JsonApiDocument } from 'json-api-models';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// eslint-disable-next-line import/extensions
import Done from 'assets/animations/signup-flow-complete.json';
import fallbackLogotype from 'assets/img/logo/collabs-logo-dark.svg';
import ThemeContext from 'contexts/ThemeProvider';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';
import Grid from 'styles/grid/grid';
import Styled from 'views/influencer/NewSignUp/InfluencerSignUpContainer.style';

/**
 */
const VerifyEmailInfo = () => {
	const user = useAppSelector((state) => state.user);
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);

	const navigate = useNavigate();
	const themeContext = useContext(ThemeContext);
	const location = useLocation();
	const inSignupFlow = location.pathname === '/verify-email/welcome';

	const resendEmail = () => {
		user?.links?.sendEmailVerificationEmail &&
			createClient()
				.post<JsonApiDocument>(user.links.sendEmailVerificationEmail)
				.then(() => {
					toast.success('Email sent');
				})
				.catch((error: AxiosError) => {
					console.error(error);
					toast.error(getErrorMessageOnPost('sending the email'));
				});
	};

	const backToLogin = () => {
		authService.panicLogout(false);
	};

	const registerNewEmail = () => {
		localStorage.removeItem('tokens');
		localStorage.removeItem('c_user');
		navigate('/signup');
	};

	useEffect(() => {
		!user.id && navigate('/login');
	}, [user]);

	useEffect(() => {
		themeContext.setTheme('signup');
	}, []);

	return (
		<Grid.Container>
			<Grid.Column lg={4}></Grid.Column>
			<Grid.Column lg={4}>
				<Styled.Wrapper>
					<img src={fallbackLogotype} alt='Collabs' width='110px' height='auto' />
					<Styled.WrapperContent>
						<Player src={Done} autoplay={true} loop={true} style={{ height: '56px', width: '56px', marginLeft: '0' }} />
						<Styled.CompletedTextDiv>
							<h5>{inSignupFlow ? 'Welcome to Collabs' : 'You need to verify your email'}</h5>
							<p>
								We’ve sent you a message with a verification link to: <Styled.Email data-testid='verify-email'>{user.email}</Styled.Email>
							</p>
							<p>Please check your email and click the confirmation link to continue.</p>
							<hr />
							<Styled.SomethingWentWrong>Something went wrong? </Styled.SomethingWentWrong>
							<Styled.ActionContainer>
								<Styled.Action onClick={() => resendEmail()}>Resend mail</Styled.Action> <span>or</span>
								<Styled.Action onClick={() => registerNewEmail()}>Register new email.</Styled.Action>
							</Styled.ActionContainer>
						</Styled.CompletedTextDiv>
						<Styled.GrayButton onClick={() => backToLogin()}>Back to login</Styled.GrayButton>
					</Styled.WrapperContent>
				</Styled.Wrapper>
			</Grid.Column>
			<Grid.Column lg={4}></Grid.Column>
		</Grid.Container>
	);
};

export default VerifyEmailInfo;
