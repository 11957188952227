import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { JsonApiDocument } from 'json-api-models';

import { ICollabsError, ICollabsResponse } from 'services/Response.types';
import Client from 'shared/ApiClient';
import { createClient } from 'shared/ApiClient/ApiClient';

/**
 * @deprecated use InfluencerListManager instead. The InfluencerListManager is part of our new ApiManager pattern.
 */
class ListsService {
	LISTS_ENDPOINT: string;
	FOLDERS_ENDPOINT: string;
	USER_LISTS: string;
	private client: AxiosInstance;

	constructor() {
		this.LISTS_ENDPOINT = '/lists';
		this.FOLDERS_ENDPOINT = '/folders';
		this.USER_LISTS = '/users';
		this.client = createClient();
	}

	/*
FOLDERS
	*/
	getFolders = async (includes?: string) => {
		let folders: ICollabsResponse | null = null;
		let lists: ICollabsResponse | null = null;

		await Client('get', `${this.FOLDERS_ENDPOINT}?includes=parent,children,${includes}`)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				folders = response.data;
			})
			.catch((err: AxiosError) => {
				if (axios.isAxiosError(err)) {
					const errorData = err.response?.data as { errors: Array<ICollabsError> };
					folders = {
						errors: errorData.errors,
						status: err.response?.status ?? 400,
					};
				} else {
					console.error(err);
				}
			});

		await Client('get', `${this.LISTS_ENDPOINT}?includes=folder,items:maxItems(5),${includes}`)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				lists = response.data;
			})
			.catch((err: AxiosError) => {
				if (axios.isAxiosError(err)) {
					const errorData = err.response?.data as { errors: Array<ICollabsError> };
					lists = {
						errors: errorData.errors,
						status: err.response?.status ?? 400,
					};
				} else {
					console.error(err);
				}
			});

		return { folders, lists };
	};

	createFolder = async (name: string, parentId?: string) => {
		let result: ICollabsResponse | null = null;

		await Client('post', `${this.FOLDERS_ENDPOINT}`, { name: name, parent: parentId })
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = { data: response.data, included: response.data.included, status: response.status };
			})
			.catch((err: AxiosError) => {
				if (axios.isAxiosError(err)) {
					const errorData = err.response?.data as { errors: Array<ICollabsError> };
					result = {
						errors: errorData.errors,
						status: err.response?.status ?? 400,
					};
				} else {
					console.error(err);
				}
			});

		return result;
	};

	updateFolder = async (folderId: string, changes: { name?: string; parentId?: string; users?: string[] }) => {
		const response = await this.client.patch<JsonApiDocument>(`${this.FOLDERS_ENDPOINT}/${folderId}`, {
			name: changes.name,
			parent: changes.parentId,
			users: changes.users,
		});
		return { data: response.data, included: response.data.included, status: response.status };
	};

	/*
	Remove this later new request getTheFolder
	*/
	getFolder = async (id: string, includes?: string) => {
		let result: ICollabsResponse | null = null;

		await Client('get', `${this.FOLDERS_ENDPOINT}/${id}?includes=parent,children,${includes}`)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = { data: response.data.data, included: response.data.included, status: response.status }; // todo: add errors
			})
			.catch((err: AxiosError) => {
				if (axios.isAxiosError(err)) {
					const errorData = err.response?.data as { errors: Array<ICollabsError> };
					result = {
						errors: errorData.errors,
						status: err.response?.status ?? 400,
					};
				} else {
					console.error(err);
				}
			});
		return result;
	};

	async getTheFolder(id: string, includes?: string) {
		return await this.client.get(`${this.FOLDERS_ENDPOINT}/${id}?includes=parent,children,${includes}`);
	}

	/*
	LIST - Remove this later new request getInfluencerLists
	*/
	getLists = async (includes?: string) => {
		let result: ICollabsResponse | null = null;

		await Client('get', `${this.LISTS_ENDPOINT}?includes=folder,items:maxItems(5),${includes}`)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = { data: response.data.data, included: response.data.included, status: response.status };
			})
			.catch((err: AxiosError) => {
				if (axios.isAxiosError(err)) {
					const errorData = err.response?.data as { errors: Array<ICollabsError> };
					result = {
						errors: errorData.errors,
						status: err.response?.status ?? 400,
					};
				} else {
					console.error(err);
				}
			});

		return result;
	};

	async getInfluencerLists(includes?: string) {
		const response = await this.client.get(`${this.LISTS_ENDPOINT}?includes=folder,items:maxItems(5),${includes}`);
		return response;
	}

	async getListsNew(includes?: string) {
		const response = await this.client.get<JsonApiDocument>(`${this.LISTS_ENDPOINT}?includes=folder,items:maxItems(5),${includes}`);
		return response;
	}

	getList = async (folderId: string, includes?: string) => {
		let result: ICollabsResponse | null = null;

		await Client('get', `${this.LISTS_ENDPOINT}/${folderId}?includes=folder,items,comments,reactions,${includes}`)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = { data: response.data.data, included: response.data.included, status: response.status };
			})
			.catch((err: AxiosError) => {
				if (axios.isAxiosError(err)) {
					const errorData = err.response?.data as { errors: Array<ICollabsError> };
					result = {
						errors: errorData.errors,
						status: err.response?.status ?? 400,
					};
				} else {
					console.error(err);
				}
			});
		return result;
	};

	createList = async (name: string, parentFolderId: string) => {
		let result: ICollabsResponse | null = null;
		await Client('post', `${this.LISTS_ENDPOINT}`, { name: name, folder: parentFolderId })
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = { data: response.data.data, included: response.data.included, status: response.status };
			})
			.catch((err: AxiosError) => {
				if (axios.isAxiosError(err)) {
					const errorData = err.response?.data as { errors: Array<ICollabsError> };
					result = {
						errors: errorData.errors,
						status: err.response?.status ?? 400,
					};
				} else {
					console.error(err);
				}
			});
		return result;
	};

	editList = async (listId: string, changes: { name?: string; folderId?: string; users?: string[] }) => {
		let result: ICollabsResponse | null = null;
		await Client('patch', `${this.LISTS_ENDPOINT}/${listId}`, { name: changes.name, folder: changes.folderId, users: changes.users })
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = { data: response.data.data, included: response.data.included, status: response.status };
			})
			.catch((err: AxiosError) => {
				if (axios.isAxiosError(err)) {
					const errorData = err.response?.data as { errors: Array<ICollabsError> };
					result = {
						errors: errorData.errors,
						status: err.response?.status ?? 400,
					};
				} else {
					console.error(err);
				}
			});
		return result;
	};

	addInfluencerToList = async (influencers: string[], parentFolderId: string) => {
		let result;
		await Client('post', `${this.LISTS_ENDPOINT}/${parentFolderId}/influencers`, { collabsIds: influencers })
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = response.status;
			})
			.catch((err: AxiosError) => {
				if (axios.isAxiosError(err)) {
					const errorData = err.response?.data as { errors: Array<{ source: { message: string } }> };
					result = {
						errors: errorData.errors,
					};
				} else {
					console.error(err);
				}
			});
		return result;
	};

	fetchUserLists = (includes?: string) => {
		let url = this.USER_LISTS;
		if (includes) {
			url = `${url}?includes=${includes}`;
		}

		return Client('GET', url).then((res) => {
			return res.data;
		});
	};

	shareWithPeople = (hateoasURL: string, users: string[]) => {
		return Client('POST', hateoasURL, { users: users }).then((res) => {
			return res;
		});
	};

	shareList = (listId: string, users: string[]) => {
		const url = `${this.LISTS_ENDPOINT}/${listId}/users`;

		return Client('POST', url, { users: users }).then((res) => {
			return res;
		});
	};

	shareFolder = (folderId: string, users: string[]) => {
		const url = `${this.FOLDERS_ENDPOINT}/${folderId}/users`;

		return Client('POST', url, { users: users }).then((res) => {
			return res;
		});
	};
}

export default new ListsService();
