import { useState, useEffect } from 'react';

import { Doughnut } from 'components/Chart';
import colors from 'styles/variables/colors';

/**
 * @returns {JSX.Element}
 */
const CircleChart = (props: { total: number; items: Array<{ name?: string; count?: number; label: string; value: number }> }): JSX.Element => {
	const [innerText, setInnerText] = useState<number | string>(0);
	const [datasets, setDatasets] = useState<{ total: number; data: number[]; labels: string[] }>({
		total: 0,
		labels: ['Unknown'],
		data: [0],
	});

	useEffect(() => {
		if (props.items) {
			const labels: Array<string> = [];
			const data: Array<number> = [];

			let sum = 0;
			props.items
				.filter((item: { value: number }) => item.value / props.total > 0.01)
				.filter((item: { label: string }) => item.label)
				.sort((a: { value: number }, b: { value: number }) => b.value - a.value)
				.slice(0, 4)
				.forEach((country: { label: string; value: number }) => {
					labels.push(country.label);
					data.push(country.value);
					sum += country.value;
				});

			// Add a 5 the element
			if (sum / props.total < 0.99) {
				labels.push('Other');
				data.push(props.total - sum);
			}

			setDatasets({
				total: props.total,
				labels: labels,
				data: data,
			});
			setInnerText(props.total);
		}
	}, [props.items]);

	useEffect(() => {
		return () => {
			setDatasets({
				total: 0,
				labels: ['Unknown'],
				data: [0],
			});
			setInnerText(0);
		};
	}, [props.items]);

	return (
		<Doughnut
			labels={datasets.labels}
			outsideLabel={true}
			legendDisplay={false}
			backgroundColors={[colors.dataLibrary.charts.color1, colors.dataLibrary.charts.color2, colors.dataLibrary.charts.color3]}
			data={datasets.data}
			innerText={innerText}
			cutout='75%'
			spacing={0}
			borderWidth={0}
			innerFontSize='1em'
		/>
	);
};

export default CircleChart;
