import styled from 'styled-components';

import base from 'styles/theme/base';

import { tags, HeadingProps } from './types';

const { fontWeights } = base;

const style = {
	[tags.H1]: {
		fontSize: '1.375rem',
		fontSizeMd: '2.4875rem',
		fontWeight: fontWeights.bold,
		fontWeightMd: fontWeights.medium,
	},
	[tags.H2]: {
		fontSize: '1.1875rem',
		fontSizeMd: '2.075rem',
		fontWeight: fontWeights.bold,
		fontWeightMd: fontWeights.semi,
	},
	[tags.H3]: {
		fontSize: '1.0625rem',
		fontSizeMd: '1.5rem',
		fontWeight: fontWeights.semi,
		fontWeightMd: fontWeights.bold,
	},
	[tags.H4]: {
		fontSize: '0.9375rem',
		fontSizeMd: '1.1875rem',
		fontWeight: fontWeights.semi,
		fontWeightMd: fontWeights.bold,
	},
	[tags.H5]: {
		fontSize: '0.9375rem',
		fontSizeMd: '1rem',
		fontWeight: fontWeights.semi,
		fontWeightMd: fontWeights.bold,
	},
	[tags.H6]: {
		fontSize: '0.9375rem',
		fontSizeMd: '0.875rem',
		fontWeight: fontWeights.semi,
		fontWeightMd: fontWeights.bold,
	},
};

/**
 * Heading component
 * Component to display H1-H6 with correct styles. H2 is the default tag
 * @returns {JSX.Element}
 */
const Heading = styled.span<HeadingProps>`
	font-size: ${({ as }) => style[as || tags.H2].fontSize};
	font-weight: ${({ as }) => style[as || tags.H2].fontWeight};
	line-height: 1.5;

	${({ theme }) => theme.mediaQueries.md} {
		font-size: ${({ as }) => style[as || tags.H2].fontSizeMd};
		font-weight: ${({ as }) => style[as || tags.H2].fontWeightMd};
	}
`;

Heading.defaultProps = {
	as: tags.H2 as any, // eslint-disable-line @typescript-eslint/no-explicit-any
};

export default Heading;
