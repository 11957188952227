import classNames from 'classnames';
import { sortBy, reverse, isNil, isEmpty } from 'lodash';
import React, { useState } from 'react';

import { DashboardModel, DashboardPaidMediaModel } from 'api-models';
import { ViewDashboardQuery } from 'api-queries';
import { PaidMediaFakeData } from 'components/DataLibrary/Graphs/GraphComponents/ByInfluencer/FakeData';
import ChartCard from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard';
import CardStyled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { ChartCardProps } from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/types';
import UploadCSVFileBlock from 'components/DataLibrary/UploadCSVFileBlock';
import UploadCSVFileModal from 'components/DataLibrary/UploadCSVFileModal';
import Icon from 'components/Icon';
import { UPLOAD_PAID_MEDIA_CSV_FILE } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import useInjection from 'hooks/useInjection';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import { ICollabsResponse, StatusCode } from 'services/Response.types';
import toast from 'services/Toast';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import { useAppSelector } from 'views/DataLibrary/hooks';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import PaidMediaItem from './PaidMediaItem';
import Styled from './PaidMediaTable.style';
import { PaidMediaStatistic, PaidMediaTableOutput } from './types';
import { removePaidMediaData } from './utils';

import type GenericApiClientInterface from 'services/ApiClient/GenericApiClientInterface';

const SORT_TABLE_FIELDS = [
	{ property: 'total', label: 'Total' },
	{ property: 'name', label: 'Name' },
	{ property: 'platform', label: 'Platform' },
	{ property: 'reach', label: 'Reach' },
	{ property: 'impressions', label: 'Impressions' },
	{ property: 'frequency', label: 'Frequency' },
	{ property: 'cpm', label: 'CPM' },
	{ property: 'clicks', label: 'Clicks' },
	{ property: 'ctr', label: 'CTR' },
	{ property: 'cpc', label: 'CPC' },
	{ property: 'registrationsCompleted', label: 'Registrations' },
	{ property: 'conversions', label: 'Conversions' },
];

enum SORT_ORDER {
	ASC,
	DESC,
}

class PaidMediaData {
	name: string;
	reach: number;
	impressions: number;
	frequency: number;
	cpm: number;
	clicks: number;
	ctr: number;
	cpc: number;
	checkoutsInitiated: number;
	registrationsCompleted: number;
	conversions: number;
	costPerPurchase: number;
	amountSpent: number;
	reportingStarts: string;
	reportingEnds: string;
	createdAt: string;
	currency: string;
	total: boolean;
	platform: string;
	links: Record<string, string>;

	constructor(paidMediaModel: DashboardPaidMediaModel) {
		this.name = paidMediaModel.attributes.name;
		this.reach = paidMediaModel.attributes.reach;
		this.impressions = paidMediaModel.attributes.impressions;
		this.frequency = paidMediaModel.attributes.frequency;
		this.cpm = paidMediaModel.attributes.cpm;
		this.clicks = paidMediaModel.attributes.clicks;
		this.ctr = paidMediaModel.attributes.ctr;
		this.cpc = paidMediaModel.attributes.cpc;
		this.checkoutsInitiated = paidMediaModel.attributes.checkoutsInitiated;
		this.registrationsCompleted = paidMediaModel.attributes.registrationsCompleted;
		this.conversions = paidMediaModel.attributes.conversions;
		this.costPerPurchase = paidMediaModel.attributes.costPerPurchase;
		this.amountSpent = paidMediaModel.attributes.amountSpent;
		this.reportingStarts = paidMediaModel.attributes.reportingStarts;
		this.reportingEnds = paidMediaModel.attributes.reportingEnds;
		this.createdAt = paidMediaModel.attributes.createdAt;
		this.currency = paidMediaModel.attributes.currency;
		this.total = paidMediaModel.attributes.total;
		this.platform = paidMediaModel.attributes.platform;
		this.links = paidMediaModel.links ?? {};
	}
}

const getDashboardWithPaidData = (manager: DataLibraryManager, id: string): { result: PaidMediaTableOutput | undefined; error?: Error; refresh: () => {} } => {
	const qb = RequestQueryBuilder.create<ViewDashboardQuery>().withInclude('paidMedia');
	const { result, error, repository, mutate } = manager.get(id, qb);

	if (!result || error) {
		return { result: undefined, error, refresh: mutate };
	}

	const paidMedia = repository.findByRelation<DashboardPaidMediaModel, DashboardModel>(result, 'paidMedia');
	if (!paidMedia) {
		return { result: undefined, error, refresh: mutate };
	}

	const output = {
		paidMedia: paidMedia,
	};

	return { result: output, refresh: mutate };
};

/**
 * @returns {JSX.Element}
 */
const PaidMediaTable = ({ title, chartId, chartType, chartOnDashboard, fetchData }: ChartCardProps<PaidMediaTableOutput>): JSX.Element => {
	const manager = useInjection<DataLibraryManager>(DataLibraryManager);
	const [sortProperty, setSortProperty] = useState('total');
	const [sortOrder, setSortOrder] = useState(SORT_ORDER.DESC);
	const [showAll, setShowAll] = useState<boolean>(false);
	const [isUploadCSVFileModalOpen, setIsUploadCSVFileModalOpen] = useState<boolean>(false);
	const [isUploadingCSVFile, setIsUploadingCSVFile] = useState<boolean>(false);
	const client = useInjection<GenericApiClientInterface>(ApiClientService.GenericApiClientInterface);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const { userCan } = useFeaturePermissions(dashboard.links);

	let paidMediaList: PaidMediaStatistic[] = [];
	let isLoading = true;
	let fetchPaidMediaData = () => {};

	if (!fetchData) {
		isLoading = false;
		paidMediaList = PaidMediaFakeData;
	} else {
		const { result, error, refresh } = getDashboardWithPaidData(manager, dashboard.id);
		if (error) {
			toast.error(`${error.message}`);
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			fetchPaidMediaData = () => {
				refresh();
			};
			paidMediaList = result.paidMedia.map((item) => new PaidMediaData(item));
		}
	}

	const handleSortChange = (e: React.MouseEvent) => {
		const { dataset } = e.target as HTMLTableCellElement;
		const property = dataset.sortProperty;

		if (isNil(property)) {
			toast.error(`We can't sort on that data`);
			return;
		}

		if (property === sortProperty) {
			setSortOrder(sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC);
		} else {
			setSortProperty(property);
		}
	};

	const sortListHandler = (list: PaidMediaStatistic[]) => {
		let sortedList = sortBy(list, sortProperty);

		if (SORT_ORDER.DESC === sortOrder) {
			sortedList = reverse(sortedList);
		}

		return sortedList;
	};

	const uploadCSVFileHandler = async (addedFiles: File[]) => {
		if (!userCan(UPLOAD_PAID_MEDIA_CSV_FILE)) {
			console.error('You are not allowed to upload files to this dashboard');
			return;
		}

		setIsUploadingCSVFile(true);
		let results: { file: File; uploadStatus: StatusCode }[] = [];
		try {
			const promises = addedFiles.map((file) => {
				return client.post(dashboard.links['createPaidMediaPresignUrl']).then(async (res: ICollabsResponse) => {
					const { url } = res.data.data.attributes;
					const uploadToS3Response = await client.external().put(url, file);
					return { file: file, uploadStatus: uploadToS3Response.status };
				});
			});

			results = await Promise.all(promises);
		} catch (err) {
			console.error(err);
		} finally {
			setIsUploadingCSVFile(false);
		}
		return results;
	};

	return (
		<ChartCard
			chartId={chartId}
			title={title}
			chartType={chartType}
			chartOnDashboard={chartOnDashboard}
			isLoading={isLoading}
			dropdownMenuItems={
				<>
					<Styled.CustomDropDownMenuItem
						onClick={() => {
							setIsUploadCSVFileModalOpen(true);
						}}
						data-testid='import-instagram-csv-file-menu-item'
					>
						<Icon name={paidMediaList.some((paidMedia) => paidMedia.platform === 'instagram') ? 'changes' : 'add-document'} size='20' />
						<Styled.IconMargin>{`${
							paidMediaList.some((paidMedia) => paidMedia.platform === 'instagram') ? 'Replace' : 'Import'
						} Instagram CSV file`}</Styled.IconMargin>
					</Styled.CustomDropDownMenuItem>
					<Styled.CustomDropDownMenuItem
						onClick={() => {
							setIsUploadCSVFileModalOpen(true);
						}}
						data-testid='import-tiktok-csv-file-menu-item'
					>
						<Icon name={paidMediaList.some((paidMedia) => paidMedia.platform === 'tiktok') ? 'changes' : 'add-document'} size='20' />
						<Styled.IconMargin>{`${
							paidMediaList.some((paidMedia) => paidMedia.platform === 'tiktok') ? 'Replace' : 'Import'
						} TikTok CSV file`}</Styled.IconMargin>
					</Styled.CustomDropDownMenuItem>
				</>
			}
			onRemove={() => removePaidMediaData(paidMediaList)}
		>
			<Styled.InnerCenteredWrapper data-testid='paid-media-table-item'>
				<Styled.ListTable className={classNames({ showAll: showAll })}>
					<Styled.ListTableHead>
						<tr>
							{SORT_TABLE_FIELDS.map(({ property, label }) => {
								if (label === 'Total') {
									return;
								}
								return (
									<Styled.ListTableTh title={`Sort by ${label.toLowerCase()}`} key={property}>
										<Styled.TableHeaderInnerWrapper onClick={handleSortChange} data-sort-property={property}>
											<Styled.Label>
												<Styled.LabelWrapper>
													{label.split('\n').map((label, index) => {
														return (
															<Styled.LabelTextWrapper data-sort-property={property} key={index} className={classNames({ 'text-sm': index !== 0 })}>
																{label}
															</Styled.LabelTextWrapper>
														);
													})}
												</Styled.LabelWrapper>
											</Styled.Label>
											<Styled.SortIconWrapper>
												<div
													data-sort-property={property}
													className={classNames('arrow-up', { isActive: property === sortProperty && sortOrder === SORT_ORDER.ASC })}
												/>
												<div
													data-sort-property={property}
													className={classNames('arrow-down', { isActive: property === sortProperty && sortOrder === SORT_ORDER.DESC })}
												/>
											</Styled.SortIconWrapper>
										</Styled.TableHeaderInnerWrapper>
									</Styled.ListTableTh>
								);
							})}
						</tr>
					</Styled.ListTableHead>
					<Styled.ListTableBody data-testid='list-table-body'>
						{sortListHandler(showAll ? paidMediaList : paidMediaList.slice(0, 3)).map((paidMedia, i) => {
							return <PaidMediaItem key={i} i={i} paidMediaItem={paidMedia} />;
						})}
					</Styled.ListTableBody>
				</Styled.ListTable>
				{isEmpty(paidMediaList) && (
					<UploadCSVFileBlock
						disabled={typeof fetchData === 'undefined'}
						onClick={() => {
							setIsUploadCSVFileModalOpen(true);
						}}
					/>
				)}
			</Styled.InnerCenteredWrapper>
			{paidMediaList?.length > 3 && (
				<CardStyled.SeeAll
					onClick={() => {
						setShowAll(!showAll);
					}}
				>
					{showAll ? 'see less' : 'see all'}
				</CardStyled.SeeAll>
			)}
			<UploadCSVFileModal
				isModalOpen={isUploadCSVFileModalOpen}
				toggleModal={() => {
					setIsUploadCSVFileModalOpen(false);
				}}
				actionHandler={uploadCSVFileHandler}
				isUploading={isUploadingCSVFile}
				refetchPaidMedia={fetchPaidMediaData}
			/>
		</ChartCard>
	);
};

export default PaidMediaTable;
