import colors from 'styles/variables/colors';

export const getGraphColors = (index: number) => {
	const colorSet = { containerColor: colors.dataLibrary.graph.bar.defaultContainer, fillerColor: colors.dataLibrary.graph.bar.defaultFiller };
	switch (index) {
		case 0:
			colorSet.containerColor = colors.dataLibrary.charts.color1Light;
			colorSet.fillerColor = colors.dataLibrary.charts.color1;
			break;
		case 1:
			colorSet.containerColor = colors.dataLibrary.charts.color2Light;
			colorSet.fillerColor = colors.dataLibrary.charts.color2;
			break;
		case 2:
			colorSet.containerColor = colors.dataLibrary.charts.color3Light;
			colorSet.fillerColor = colors.dataLibrary.charts.color3;
			break;
		case 3:
			colorSet.containerColor = colors.dataLibrary.charts.color4Light;
			colorSet.fillerColor = colors.dataLibrary.charts.color4;
			break;
		case 4:
			colorSet.containerColor = colors.dataLibrary.charts.color5Light;
			colorSet.fillerColor = colors.dataLibrary.charts.color5;
			break;
		case 5:
			colorSet.containerColor = colors.dataLibrary.charts.color6Light;
			colorSet.fillerColor = colors.dataLibrary.charts.color6;
			break;
		case 6:
			colorSet.containerColor = colors.dataLibrary.charts.color7Light;
			colorSet.fillerColor = colors.dataLibrary.charts.color7;
			break;
		case 7:
			colorSet.containerColor = colors.dataLibrary.charts.color8Light;
			colorSet.fillerColor = colors.dataLibrary.charts.color8;
			break;
		case 8:
			colorSet.containerColor = colors.dataLibrary.charts.color9Light;
			colorSet.fillerColor = colors.dataLibrary.charts.color9;
			break;
		default:
			colorSet.containerColor = colors.buttonGray;
			colorSet.fillerColor = colors.gray5;
	}
	return colorSet;
};
