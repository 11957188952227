import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	padding: 0 2rem;

	& > div > ul {
		display: flex;
		list-style-type: none;
		margin-bottom: ${guttersWithRem.xxs};
		column-gap: ${guttersWithRem.xxs};

		& > li {
			cursor: pointer;
			font-size: 0.75rem;
		}

		& > li > a {
			color: ${colors.black};
			margin: ${guttersWithRem.xxs};
			&:hover {
				text-decoration: underline;
			}
		}
	}

	@media (max-width: ${breakpoints.sm}) {
		padding-right: 1rem;
		padding-left: 1rem;
	}
`;

const InnerWrapper = styled.div``;

const Styled = {
	Wrapper,
	InnerWrapper,
};

export default Styled;
