import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-table';

import DataToCSV from 'components/DataToCSV';
import LoadingSpinner from 'components/LoadingSpinner';
import TableComponent, { Column, GenericData } from 'components/Table/V2/Table';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { formatAmount } from 'utils/formatters';

import Styled from './AdminPayout.style';
import { TableItem } from './Types';
import { sortItems } from './Utils';
import useAdminPayoutToCSV from './hooks/useAdminPayoutoCSV';

/**
 * Admin Payout
 * View for showing payouts with export functionality
 * @returns {JSX.Element}
 */
const AdminPayout = (): JSX.Element => {
	const manager = useInjection<StatisticsManager>(StatisticsManager);
	const { result, isLoading, error } = manager.listAdminMonthlyStatistics();
	const adminStatisticsPerMonth = result ?? [];
	const [tableData, setTableData] = useState<Array<TableItem>>([]);
	const { csvData, getCsvData, hasPayoutData } = useAdminPayoutToCSV(isLoading, tableData);
	const { formattedData, csvHeaders } = csvData;

	const fileNameDateAndType = `${moment(new Date()).format('YYYY-MM-DD')}.csv`;

	const columns: Column[] = useMemo(
		() => [
			{
				Header: 'Id',
				accessor: 'id',
			},
			{
				Header: 'Campaigns',
				accessor: 'campaigns',
				disableSortBy: true,
			},
			{
				Header: 'Total commission',
				accessor: 'totalCommission',
				Cell: ({ row }: { row: Row<GenericData> }) => formatAmount(Number(row.original.totalCommission), undefined, 'sv'),
			},
			{
				Header: 'Currency',
				accessor: 'currency',
			},
			{
				Header: 'Month',
				accessor: 'month',
			},
			{
				Header: 'Year',
				accessor: 'year',
			},
		],
		[],
	);

	const mapData = () => {
		const mappedItems = adminStatisticsPerMonth.map(({ id, attributes }) => {
			const { campaigns, currency, month, totalCommission, year } = attributes;
			return {
				id: id,
				campaigns: campaigns,
				currency: currency ?? '-',
				month: month,
				totalCommission: totalCommission?.toString() ?? '0',
				year: year,
			};
		});

		return mappedItems.sort(sortItems);
	};

	useEffect(() => {
		if (!isLoading) {
			const mappedItems = mapData();
			setTableData(mappedItems);
		}
	}, [isLoading]);

	return (
		<Styled.AdminPayout data-testid='admin-payout'>
			<h4>Financial Summary (for Liquidity Analysis)</h4>
			{!isLoading && (
				<DataToCSV shouldButtonBeEnabled={hasPayoutData} data={formattedData} headers={csvHeaders} filename={fileNameDateAndType} getCsvData={getCsvData} />
			)}
			<Styled.MainContainerInner>
				{isLoading && <LoadingSpinner position='center' />}
				{!isLoading && error && <p>There was an error. Please try again later.</p>}
				{!isLoading && (
					<>
						<TableComponent showSearch columns={columns} data={tableData} enableSorting={true} />
					</>
				)}
			</Styled.MainContainerInner>
		</Styled.AdminPayout>
	);
};
export default AdminPayout;
