import styled from 'styled-components';

const SubTitle = styled.h5`
	margin-bottom: 8px;
	font-size: 1rem;
	font-weight: 600;
`;

export default {
	SubTitle,
};
