/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { CreateAgencyPayload } from 'api-payloads';
import { ListAgencyQuery, CreateAgencyQuery } from 'api-queries';
import { AgencyCollectionResponse, AgencyResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import AgencyApiClientInterface from './AgencyApiClientInterface';

@injectable()
export default class AgencyApiClient implements AgencyApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List Agencies.
	 *
	 * See all our agencies
	 */
	async list(params: ListAgencyQuery): Promise<AgencyCollectionResponse> {
		const response = await this.client.get<AgencyCollectionResponse>(`/agencies`, { params });
		return response.data;
	}

	/**
	 * Create Agency.
	 *
	 * Create a brand-new agency for all your special needs.
	 */
	async create(payload: CreateAgencyPayload, params: CreateAgencyQuery): Promise<AgencyResponse> {
		const response = await this.client.post<AgencyResponse>(`/agencies`, payload, { params });
		return response.data;
	}
}
