import { inject, injectable } from 'inversify';

import { CampaignProductModel } from 'api-models';
import { CreateProductPayload, UpdateProductPayload, UploadProductImagePayload } from 'api-payloads';
import { CreateProductQuery, ListProductsQuery, UpdateProductQuery } from 'api-queries';
import { CampaignProductCollectionResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, DeleteConfig, UpdateConfig, SWRConfiguration } from './types';

import type CampaignApiClientInterface from 'services/ApiClient/CampaignApiClientInterface';

type Repository = ModelRepository<CampaignProductModel>;

@injectable()
class CampaignProductManager extends ApiManager<Repository> {
	private readonly client: CampaignApiClientInterface;

	public constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.CampaignApiClientInterface) client: CampaignApiClientInterface,
	) {
		super(cacheManager, new ModelRepository<CampaignProductModel>('campaignProduct'));
		this.client = client;
	}

	public listProducts(campaignId: string, queryBuilder = RequestQueryBuilder.create<ListProductsQuery>(), config: SWRConfiguration = {}) {
		const key = `listProducts::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listProducts(campaignId, queryBuilder.toQuery());

		return this.swr<CampaignProductCollectionResponse>(key, fetcher, config);
	}

	public create(
		campaignId: string,
		payload: CreateProductPayload,
		queryBuilder = RequestQueryBuilder.create<CreateProductQuery>(),
		config: CreateConfig<CampaignProductModel> = {},
	): Promise<CampaignProductModel> {
		return this.createModel<CampaignProductModel>(this.client.createProduct(campaignId, payload, queryBuilder.toQuery()), config);
	}

	public update(
		campaignId: string,
		productId: string,
		payload: UpdateProductPayload,
		queryBuilder = RequestQueryBuilder.create<UpdateProductQuery>(),
		config: UpdateConfig<CampaignProductModel> = {},
	): Promise<CampaignProductModel> {
		return this.updateModel<CampaignProductModel>(this.client.updateProduct(campaignId, productId, payload, queryBuilder.toQuery()), config);
	}

	public delete(campaignId: string, productId: string, config: DeleteConfig = {}): Promise<Repository> {
		return this.deleteModel(this.client.deleteProduct(campaignId, productId), 'campaignProduct', productId, config);
	}

	public async uploadImage(
		campaignId: string,
		productId: string,
		payload: UploadProductImagePayload,
		queryBuilder = RequestQueryBuilder.create<UpdateProductQuery>(),
	): Promise<void> {
		await this.client.uploadProductImage(campaignId, productId, payload, queryBuilder.toQuery());
		this.refetch('campaignProduct', productId);
	}

	public deleteImage(campaignId: string, productId: string, config: DeleteConfig = {}): Promise<Repository> {
		return this.deleteModel(this.client.deleteProductImage(campaignId, productId), '', productId, config);
	}
}

export default CampaignProductManager;
