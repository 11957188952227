import { useNavigate } from 'react-router-dom';

import Icon from 'components/Icon';
import { pathProductAdd } from 'routing/PathLookup';

import Styled from './CampaignProducts.style';

type CampaignProductProps = {
	campaignId: string;
};
/**
 * @returns JSX.Element
 */
const CampaignProductsEmptyState = ({ campaignId }: CampaignProductProps): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Styled.Wrapper>
			<Styled.HeaderText>Products</Styled.HeaderText>
			<p>Section will not be visible to the influencer if left empty.</p>
			<Styled.ContentWrapper>
				<Styled.Wrapper>
					<Styled.Button onClick={() => navigate(pathProductAdd(campaignId))}>
						Add product <Icon name='arrow-right' size='16' />{' '}
					</Styled.Button>
				</Styled.Wrapper>
			</Styled.ContentWrapper>
		</Styled.Wrapper>
	);
};

export default CampaignProductsEmptyState;
