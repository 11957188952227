import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	width: 100%;
	height: 384px;

	background-color: ${colors.gray13};
	border-radius: ${borderRadius.m};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: ${guttersWithRem.m};
`;

const TextWrapper = styled.div`
	text-align: center;

	& > div {
		&.strong {
			font-weight: 600;
			margin-bottom: ${guttersWithRem.m};
		}
	}
`;

const Styled = {
	Wrapper,
	TextWrapper,
};

export default Styled;
