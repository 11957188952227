import Icon from 'components/Icon';

import Styled from './NoItems.style';

const NotItems = (props: { title: string; icon: string; text: string }) => {
	return (
		<Styled.NoClients>
			<Styled.IconContainer>
				<Icon name={props.icon} />
			</Styled.IconContainer>
			<strong>{props.title}</strong>
			<p>{props.text}</p>
		</Styled.NoClients>
	);
};

export default NotItems;
