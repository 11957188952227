import styled, { DefaultThemeV2 } from 'styled-components';

import Accordion from 'components/Accordion';
import colors from 'styles/theme/colors';

type T = { theme: DefaultThemeV2 };

const StyledAccordion = styled(Accordion)`
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 1.5rem;
	overflow-y: auto;
	.accordion__title {
		font-weight: 600;
		padding-left: 0;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	.accordion__header {
		margin-bottom: 0;
	}
	p {
		margin-bottom: 0;
	}
`;

const InviteWrapper = styled.div`
	margin-top: 40px;
`;

export default {
	StyledAccordion,
	InviteWrapper,
};
