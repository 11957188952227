import styled from 'styled-components';

import typography from 'styles/variables/typography';

interface Wrapper {
	width?: string;
	height?: string;
}

interface Section {
	display?: 'flex' | 'grid' | 'inline' | 'inline-block' | 'inline-flex' | 'inline-grid' | 'block' | 'flow-root';
	fontSize?: string;
	textAlign?: string;
	fontWeight?: 'bold' | 'normal' | 'lighter' | 'bolder' | number;
	justifyContent?: string;
	alignItems?: string;
	flexDirection?: string;
}

interface Span {
	fontSize?: string;
	fontWeight?: 'bold' | 'normal' | 'lighter' | 'bolder' | number;
}

const Wrapper = styled.div<Wrapper>`
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || 'auto'};

	display: grid;
	grid-template-columns: 1fr 4fr 1fr;
	column-gap: 8px;
	align-items: center;
`;

const Section = styled.div<Section>`
	flex: 1;
	display: ${(props) => props.display};
	font-size: ${(props) => props.fontSize};
	font-weight: ${(props) => props.fontWeight};
	justify-content: ${(props) => props.justifyContent};
	align-items: ${(props) => props.alignItems};
	flex-direction: ${(props) => props.flexDirection};
	text-align: ${(props) => props.textAlign};
	font-family: ${typography.BaseFontFamiliy};
`;

const Span = styled.span<Span>`
	font-size: ${(props) => props.fontSize};
	font-weight: ${(props) => props.fontWeight};
`;

const Styled = {
	Wrapper,
	Section,
	Span,
};

export default Styled;
