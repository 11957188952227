import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgAddDocument(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.78424 18.503L8.34884 18.4997C8.32528 18.4965 8.3014 18.4944 8.27725 18.4935L8.78424 18.503ZM8.24953 18.493L8.27725 18.4935C8.26805 18.4932 8.25881 18.493 8.24953 18.493ZM3.50094 18.4631L8.34884 18.4997C8.71327 18.5491 9 18.8659 9 19.2415C9 19.6507 8.65979 19.99 8.24953 19.99L2.95059 19.9501C2.43027 19.9501 2 19.5409 2 19.022V0.928144C2 0.419162 2.43027 0 2.95059 0L12.3465 0.0399202H12.6567L18 5.35928V5.66866C18 5.67864 17.9975 5.68613 17.995 5.69361C17.9925 5.7011 17.99 5.70858 17.99 5.71856C18 5.73852 18 5.76846 18 5.76846V10.2515C18 10.6607 17.6598 11 17.2495 11C16.8393 11 16.4991 10.6607 16.4991 10.2515L16.4991 6.51697H12.3465C11.8762 6.51697 11.4959 6.13772 11.4959 5.66866V1.53693L3.50094 1.49701V18.4631ZM12.9969 2.48503L15.5385 5.00998H12.9969V2.48503ZM13.4972 11.018C11.0156 11.018 8.99437 13.0339 8.99437 15.509C8.99437 17.984 11.0156 20 13.4972 20C15.9787 20 18 17.984 18 15.509C18 13.0339 15.9787 11.018 13.4972 11.018ZM13.4972 18.9521C11.596 18.9521 10.045 17.4052 10.045 15.509C10.045 13.6128 11.596 12.0659 13.4972 12.0659C15.3984 12.0659 16.9493 13.6128 16.9493 15.509C16.9493 17.4052 15.3984 18.9521 13.4972 18.9521ZM14.0675 12.9441V15.0399H16.0788V16.0878H14.0675V18.0739H13.0169V16.0878H10.9256V15.0399H13.0169V12.9441H14.0675Z'
				fill='#333333'
			/>
		</svg>
	);
}
