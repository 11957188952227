import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgFolderSharedWithMe(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.945117 19H10.7351C11.1551 19 11.4851 18.66 11.4851 18.24C11.4851 17.82 11.1451 17.48 10.7351 17.48H1.48512V6.63H16.3332C16.6134 6.63 16.8335 6.41 16.8335 6.13C16.8335 5.85 16.6134 5.63 16.3332 5.63H1.48512V2.51H5.34512L6.73512 3.92C6.91512 4.1 7.15512 4.2 7.40512 4.2H18.5051V9.6C18.5051 10.02 18.8351 10.36 19.2551 10.36C19.6751 10.36 20.0051 10.02 20.0051 9.6V3.65C20.0051 3.12 19.5751 2.69 19.0551 2.69H7.63512L6.24512 1.28C6.06512 1.1 5.82512 1 5.57512 1H0.945117C0.415117 1 -0.00488281 1.43 -0.00488281 1.96V18.04C-0.00488281 18.57 0.425117 19 0.945117 19Z'
				fill='#4D4B4C'
			/>
			<path
				d='M14.8586 12.1054L13.1309 13.6996C13.089 13.7383 13.0576 13.7916 13.0314 13.8498C13.0105 13.8934 13 13.937 13 13.9855L13 13.9952C13 14.0485 13.0157 14.0969 13.0366 14.1454C13.0628 14.2035 13.0942 14.2568 13.1309 14.2907L14.8586 15.8946C15.0105 16.0351 15.2618 16.0351 15.4136 15.8946C15.5654 15.7541 15.5654 15.5215 15.4136 15.381L14.3141 14.3634L19.6073 14.3634C19.822 14.3634 20 14.1987 20 14C20 13.8013 19.822 13.6366 19.6073 13.6366L14.3141 13.6366L15.4136 12.619C15.4921 12.5463 15.5288 12.4543 15.5288 12.3622C15.5288 12.2701 15.4921 12.1781 15.4136 12.1054C15.2618 11.9649 15.0105 11.9649 14.8586 12.1054Z'
				fill='#4D4B4C'
			/>
		</svg>
	);
}
