import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgArrowRight(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.47928 19.5375C3.84024 18.9209 3.84024 17.9212 4.47928 17.3046L12.0495 10L4.47928 2.69543C3.84024 2.07881 3.84024 1.07908 4.47928 0.462461C5.11832 -0.154154 6.15441 -0.154154 6.79345 0.462461L15.5207 8.88351C16.1598 9.50013 16.1598 10.4999 15.5207 11.1165L6.79345 19.5375C6.15441 20.1542 5.11832 20.1542 4.47928 19.5375Z'
				fill='#333'
			/>
		</svg>
	);
}
