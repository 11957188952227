import classNames from 'classnames';
import { isNil } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';

import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './PageHeader.style';
import { IPageHeaderProps } from './types';

export const greetingPhrase = (curHr: number): string => {
	if (curHr < 12) {
		return 'Good morning';
	} else if (curHr < 18) {
		return 'Good afternoon';
	} else {
		return 'Good evening';
	}
};

/**
 * Page header
 * @param {IPageHeaderProps} props
 * @returns {JSX.Element}
 */
const PageHeader = (props: IPageHeaderProps): JSX.Element => {
	const [currentDate, setCurrentDate] = useState<string>(moment().format('HH:mm dddd, DD MMMM'));
	const [displaySkeletonLoader, setDisplaySkeletonLoader] = useState<boolean>(false);

	const { headline } = props;
	const user = useAppSelector((state) => state.user);
	const curHr = props.currentHour ? props.currentHour : new Date().getHours();
	const [selectedInfluencerId, setSelectedInfluencerId] = useState<string>('');

	useEffect(() => {
		props.showCurrentDate && dateInterval();
		if (user?.permissions?.isInfluencer) {
			setSelectedInfluencerId(user.influencer.id);
		}
		return () => {
			setCurrentDate('');
		};
	}, []);

	const dateInterval = (): void => {
		setInterval(() => {
			setCurrentDate(moment().format('HH:mm dddd, DD MMMM'));
		}, 1000); // update each second
	};

	const getUserHeadlineGreeting = () => {
		if (user && user.name) {
			return `${greetingPhrase(curHr)}, ${user.name}`;
		}
		return greetingPhrase(curHr);
	};

	const Heading = () => {
		if (props.userGreeting) {
			return getUserHeadlineGreeting();
		}

		if (props.customHeadline) {
			return props.customHeadline; // JSX Element
		}

		return headline || '';
	};

	if (user?.permissions?.isInfluencer) {
		useEffect(() => {
			if (user?.influencer?.id !== selectedInfluencerId) {
				setDisplaySkeletonLoader(true);
				setTimeout(() => {
					setDisplaySkeletonLoader(false);
				}, 1000);
				setSelectedInfluencerId(user.influencer?.id);
			}
		}, [user?.influencer.id]);
	}

	return (
		<Styled.Wrapper className={classNames(props.className)}>
			<Helmet>
				<title>{isNil(props.headline) ? 'Collabs' : `${props.headline} | Collabs.se`}</title>
			</Helmet>
			<Styled.HeaderBottom>
				<Styled.PageHeadingWrapper>
					<Styled.PageHeading data-testid='page-title'>
						{displaySkeletonLoader && Heading() ? <Skeleton width='150px' height='15px' borderRadius='4px' /> : Heading()}
					</Styled.PageHeading>
				</Styled.PageHeadingWrapper>
				<Styled.ComponentContainer>{props.children}</Styled.ComponentContainer>
			</Styled.HeaderBottom>
			{props.showCurrentDate && (
				<Styled.DateWrapper>
					<Styled.DateText>{currentDate}</Styled.DateText>
				</Styled.DateWrapper>
			)}
		</Styled.Wrapper>
	);
};

PageHeader.defaultProps = {
	showBreadcrumb: true,
	showCurrentDate: false,
};

export default PageHeader;
