import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgMoveCheck(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps} fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.95483 0.769233C5.95483 0.344399 6.29923 2.63724e-06 6.72406 2.63724e-06H8.90355C10.4613 2.63724e-06 11.7241 1.26279 11.7241 2.82052V17.1795C11.7241 18.7372 10.4613 20 8.90355 20H2.72406C2.29923 20 1.95483 19.6556 1.95483 19.2308C1.95483 18.8059 2.29923 18.4615 2.72406 18.4615H8.90355C9.61161 18.4615 10.1856 17.8875 10.1856 17.1795V2.82052C10.1856 2.11246 9.61161 1.53846 8.90355 1.53846H6.72406C6.29923 1.53846 5.95483 1.19407 5.95483 0.769233Z'
				fill='#4D4B4C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.9548 19.2308C19.9548 19.6556 19.6104 20 19.1856 20H16.083C14.5253 20 13.2625 18.7372 13.2625 17.1795L13.2625 2.82051C13.2625 1.26279 14.5253 2.07516e-06 16.083 2.21134e-06L19.1856 2.56999e-06C19.6104 2.60713e-06 19.9548 0.344399 19.9548 0.769233C19.9548 1.19407 19.6104 1.53846 19.1856 1.53846L16.083 1.53846C15.375 1.53846 14.801 2.11246 14.801 2.82051L14.801 17.1795C14.801 17.8875 15.375 18.4615 16.083 18.4615H19.1856C19.6104 18.4615 19.9548 18.8059 19.9548 19.2308Z'
				fill='#4D4B4C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.70483 2.05835e-06L3.70483 0C4.11905 -8.57856e-07 4.45483 0.335785 4.45483 0.749999C4.45483 1.16421 4.11905 1.5 3.70483 1.5L2.70484 1.5C2.29062 1.5 1.95483 1.16422 1.95483 0.750002C1.95483 0.33579 2.29062 2.9162e-06 2.70483 2.05835e-06Z'
				fill='#4D4B4C'
			/>
		</svg>
	);
}
