import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	height: auto;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const RecipientSearchBox = styled.div`
	&.has-result {
		left: ${guttersWithRem.xs};
		width: 100%;
		max-height: 278px;
		overflow-y: hidden;
		border-radius: 4px;
		border: 1px solid ${newColors.ash};
		padding-top: ${guttersWithRem.xs};
		padding-bottom: ${guttersWithRem.xs};

		box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.1);

		background-color: ${colors.integratedInbox.blastModal.search.background};
	}
`;

const RecipientSearchInput = styled.div`
	min-height: 40px;
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xxs};
	font-size: 0.875rem;
	padding-top: 0.625rem;
	& > input {
		width: 100%;
		border: none;
		outline: none;
		flex: 1;
		background-color: ${colors.transparent};
	}

	& > span {
		color: ${colors.integratedInbox.blastModal.color.label};
	}
`;

const SelectedInfluencers = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	max-height: 200px;
	& > div {
		&.influencers {
			max-height: 200px;
			overflow-y: auto;
			display: flex;
			flex-wrap: wrap;
			column-gap: ${guttersWithRem.xxs};
		}
	}
`;

const SelectedInfluencerTag = styled.div`
	padding: 0.5rem;
	background-color: ${colors.integratedInbox.blastModal.search.influencerTag.background};
	border-radius: ${borderRadius.m};
	margin: 2px 0;
	display: flex;
	column-gap: ${guttersWithRem.xxs};
	align-items: center;

	font-weight: 500;
	font-size: 0.875rem;

	& > figure {
		margin-left: 0;
		margin-right: 4px;
	}

	& > div > i {
		display: flex;
		margin-left: ${guttersWithRem.xs};
		cursor: pointer;
		margin-top: 2px;
	}
`;

const AddShortCut = styled.div`
	width: 100%;
	padding: 1rem 0;
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xxs};
	font-size: 0.875rem;

	border-top: 1px solid ${colors.integratedInbox.blastModal.shortcut.border};

	& > span {
		color: ${newColors.slate};
	}

	& > div {
		&.shortcut {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.5rem 1rem;
			border-radius: ${borderRadius.m};
			background-color: ${colors.integratedInbox.blastModal.shortcut.background};
			color: ${colors.integratedInbox.blastModal.shortcut.color};
			font-weight: 500;
			cursor: pointer;
		}
	}
`;
const MessageWindow = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-height: 460px;
	overflow: hidden;

	& > div {
		&.window {
			flex: 1;
			${scrollbarY};

			& > div {
				padding: 0;
			}
		}
	}
`;

const ChatInputWrapper = styled.div`
	margin-top: 24px;
`;

const EmptyWrapper = styled.div`
	margin-top: 40px;
`;

const ToWrapper = styled.div`
	height: auto;
`;

const HelpText = styled.div`
	text-align: start;
	padding: 1rem;
	font-size: 0.875rem;
	line-height: 1.5;
	background-color: ${newColors.paleGreenTint};
	border-radius: ${borderRadius.m};
`;
const Styled = {
	ToWrapper,
	EmptyWrapper,
	Wrapper,
	RecipientSearchInput,
	SelectedInfluencers,
	SelectedInfluencerTag,
	RecipientSearchBox,
	AddShortCut,
	MessageWindow,
	ChatInputWrapper,
	HelpText,
};

export default Styled;
