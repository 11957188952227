import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvghourGlass(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18 2.80005C18 3.18994 17.6599 3.5 17.25 3.5H16.2V7C16.21 7.08008 16.3301 8.45996 15.3999 9.46997C15.23 9.6499 15.05 9.80005 14.8501 9.92993C15.9099 10.6201 16.1899 11.8301 16.1899 12.3999V16.5H17.26C17.6699 16.5 18 16.8201 18 17.2V20H2V17.2C2 16.8101 2.34009 16.5 2.75 16.5H3.81006V12.3999C3.81006 11.8201 4.09009 10.6201 5.1499 9.92993C4.93994 9.80005 4.76001 9.6499 4.6001 9.46997C3.69092 8.48291 3.78516 7.14233 3.79932 6.94214L3.80005 6.92993V3.5H2.73999C2.33008 3.5 2 3.17993 2 2.80005V0H18V2.80005ZM3.07007 1V2.5H16.9399V1H3.07007ZM16.9399 19V17.5H3.07007V19H16.9399ZM14.27 8.48999C14.76 7.94995 14.6899 7.08008 14.6899 7.08008V7.07007V3.48999H5.32007V6.98999L5.31885 7.021C5.31128 7.19897 5.27759 7.98047 5.73999 8.47998C6.03003 8.80005 6.5 8.95996 7.13989 8.95996C7.63989 8.95996 8.01001 9.36011 8.01001 9.87988C8.01001 10.4399 7.61011 10.8799 7.1001 10.8799C5.47998 10.8799 5.32007 12.04 5.32007 12.3999V16.5H6.17993V14.8899C6.17993 14.8899 5.93994 13.72 8.30005 13.72H11.72C14.0801 13.72 13.8401 14.8899 13.8401 14.8899V16.5H14.6899V12.3999C14.6799 12.05 14.53 10.8899 12.9099 10.8899C12.3999 10.8899 12 10.45 12 9.88989C12 9.70996 12.04 9.54004 12.1201 9.3999C12.27 9.12988 12.55 8.96997 12.8601 8.96997C13.5 8.96997 13.98 8.81006 14.27 8.48999ZM11.71 8.12012H8.29004C5.92993 8.12012 6.16992 6.94995 6.16992 6.94995V6H13.8301V6.94995C13.8301 6.94995 14.0701 8.12012 11.71 8.12012Z'
				fill='#333333'
			/>
		</svg>
	);
}
