import { forwardRef, cloneElement, isValidElement } from 'react';

import Label from 'components/Label';

import Styled from './Field.style';
import { FieldProps, FieldControlProps } from './types';

const Field = forwardRef<HTMLDivElement, FieldProps>(
	(
		{ label, hint, children, scale, orientation = 'vertical', disabled, readOnly, validationMessage, validationState, id, displayInfoTooltip, className },
		ref,
	) => {
		const controlId = typeof label === 'string' ? `field-${label.replace(/\s+/g, '-').toLowerCase()}` : undefined;
		const fieldProps: FieldControlProps & Pick<FieldProps, 'scale' | 'disabled' | 'readOnly'> = {
			id: !id ? controlId : id,
			scale: scale,
			disabled: disabled,
			readOnly: readOnly,
		};

		const content = typeof children === 'function' ? children(fieldProps) : isValidElement(children) ? cloneElement(children, fieldProps) : children;

		return (
			<Styled.Field ref={ref} orientation={orientation} data-testid='field-container' data-ori={orientation} className='form-field'>
				{label && (
					<Label
						htmlFor={id ?? controlId}
						scale={scale}
						disabled={disabled}
						aria-readonly={readOnly}
						aria-disabled={disabled}
						displayInfoTooltip={displayInfoTooltip}
					>
						{label}
					</Label>
				)}
				<Styled.FieldComponent className={className}>
					{content}
					{!validationMessage && hint && <Styled.Hint>{hint}</Styled.Hint>}
					{validationMessage && validationState && <Styled.FieldErrorMessage state={validationState}>{validationMessage}</Styled.FieldErrorMessage>}
				</Styled.FieldComponent>
			</Styled.Field>
		);
	},
);

Field.displayName = 'Field';

export default Field;
