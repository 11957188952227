import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgCancelCircle(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM14.5305 5.46939C14.8234 5.76228 14.8234 6.23715 14.5305 6.53005L11.0606 9.99998L14.5305 13.4699C14.8234 13.7628 14.8234 14.2377 14.5305 14.5306C14.2376 14.8235 13.7627 14.8235 13.4698 14.5306L9.99991 11.0606L6.53101 14.5295C6.23811 14.8224 5.76324 14.8224 5.47035 14.5295C5.17745 14.2367 5.17745 13.7618 5.47035 13.4689L8.93925 9.99998L5.47035 6.53108C5.17746 6.23819 5.17746 5.76332 5.47035 5.47042C5.76325 5.17753 6.23812 5.17753 6.53101 5.47042L9.99991 8.93932L13.4698 5.46939C13.7627 5.17649 14.2376 5.17649 14.5305 5.46939Z'
				fill='#333333'
			/>
		</svg>
	);
}
