import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	padding: 1rem;
	padding-bottom: 35rem;
`;

const Styled = {
	Wrapper,
};

export default Styled;
