import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { GhostButton } from 'components/Button';
import Icon from 'components/Icon';
import { InputField } from 'styles/formElements/input';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const InputWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > input {
		${InputField};
		border: none;
		background-color: ${colors.transparent};

		&:hover:not(:disabled),
		&:focus:not(:disabled) {
			border: none;
			box-shadow: none;
		}
	}
`;

const GuidelineWrapper = styled.div`
	border-radius: ${borderRadius.s};
	background-color: ${colors.createCampaign.textareaBackground};
`;

const GuidelineInnerWrapper = styled.ul`
	padding: ${guttersWithRem.xxs};
	margin-bottom: 0;
`;

const ButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: ${guttersWithRem.m} 0;

	button {
		height: 44px;
		min-width: 100px;
		&:hover {
			.icon path {
				fill: ${colors.mainWhite};
			}
		}
	}
`;

const CustomIcon = styled(Icon)`
	line-height: 0;
	&.icon {
		margin-left: 0;
	}

	&.reflect {
		transform: rotate(180deg);
	}
`;

const ErrorMessage = styled.div`
	background-color: ${colors.errorLight};
	border-radius: ${borderRadius.s};
	padding: ${spacing[1].rem};
	color: ${colors.errorDarkRed};
	font-size: 0.75rem;
	display: flex;
	span {
		margin-top: 2px;
	}
	.icon {
		margin-right: 5px;
	}
`;

const TextareaWrapper = styled.div`
	margin-bottom: ${guttersWithRem.s};

	.campaign-form {
		background-color: transparent;
	}
`;

const SideSection = styled.div`
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 16px;
	@media screen and (${devices.lg}) {
		top: 80px;
		position: sticky;
		height: 90vh;
		width: 50%;
		flex-direction: column;
		width: 30%;
	}
`;

const CardInnerWrapper = styled.div`
	@media screen and (${devices.md}) {
		padding: ${spacing[2].rem};
	}
	hr {
		border-top: ${colors.buttonGray};
	}
`;

const FormContentWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 auto;
	gap: 32px;
	margin-top: ${spacing[2].px};
	hr {
		border-top: ${colors.buttonGray};
	}
	@media screen and (${devices.lg}) {
		width: 80%;
		margin-top: ${spacing[4].px};
		flex-direction: row;
	}
`;

const Label = styled.label`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
	line-height: 2;
`;

const SelectTeamMembers = styled.div`
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	margin: ${spacing[3].px} 0;
	button {
		width: 100% !important;
	}
`;

export const TeamMembersContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;

export const SelectedTeamMember = styled.div`
	padding: 0 ${spacing[1].rem} 0 ${spacing[1].rem};
	border-radius: ${borderRadius.m};
	border: 1px solid ${colors.gray9};
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: fit-content;
	background-color: ${colors.mainWhite};
	span {
		font-size: 0.75rem !important;
	}
	div {
		&.you {
			margin-left: 5px;
			color: ${colors.gray15};
		}
	}
`;

export const DropdownAvatar = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: ${colors.mainWhite};
	border-radius: 250px;

	width: 24px;
	height: 24px;
	> span {
		font-size: 0.75rem;
		font-weight: 700;
	}
`;

export const DropdownItem = styled.div`
	display: flex;
	align-items: center;
	h5 {
		font-size: 1rem;
		font-weight: 500;
		margin: 0;
		margin-left: ${spacing[2].px};
		span {
			margin-left: 3px;
			color: ${colors.gray7};
		}
	}
`;

export const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	justify-self: center;
	height: 25px;
	width: 25px;
	padding: 0.5rem;
	border-radius: 50%;
	margin-left: ${spacing[1].px};
	cursor: pointer;
	transition: background-color 200ms;
	&:hover {
		background: ${colors.gray10};
	}
`;

const SaveButton = styled(GhostButton)`
	width: 50%;
	&.on-edit {
		background-color: ${colors.buttonGray};
		border: 2px solid ${colors.buttonGray};
	}
	@media screen and (${devices.md}) {
		width: fit-content;
	}
`;

const SpinnerContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: ${spacing[3].px};
`;

const BackgroundSection = styled.div``;

const FormContent = styled.div`
	width: 100%;
	@media screen and (${devices.lg}) {
		width: 70%;
	}
`;

const AddTeamMembersButton = styled.button`
	all: unset;
	width: fit-content;
	cursor: pointer;
	color: ${colors.influencerBlue};
	border-bottom: 1px solid ${colors.influencerBlue};
	float: right;
	font-size: 0.875rem;
`;

const Section = styled.div`
	margin: ${spacing[4].px} 0;
	h5 {
		font-size: 1rem;
	}
	p {
		&.help-text {
			color: ${colors.gray7};
			margin-bottom: ${spacing[2].px};
		}
	}
	hr {
		margin: 32px 0 24px 0;
	}
`;

const Wrapper = styled.div`
	margin: ${spacing[4].px} 0;
	h5 {
		font-size: 1rem;
	}
	p {
		&.help-text {
			color: ${colors.gray7};
			margin-bottom: ${spacing[2].px};
		}
	}
`;

const StyledLink = styled(Link)`
	font-size: 0.875rem;
	margin-top: -16px;
	color: ${colors.influencerBlue};
	width: fit-content;
	height: 15px;
	&:hover {
		color: ${colors.influencerBlue};
		border-bottom: 1px solid ${colors.influencerBlue};
	}
`;

const SelectWrapContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: end;
`;

const CardContent = styled.div`
	padding: ${spacing[0].rem};
	p {
		color: ${colors.gray7};
		font-size: 0.875rem;
		margin-bottom: ${spacing[3].px};
	}

	button {
		width: 100%;
	}
`;

const SaveButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const MediaFeeWrapper = styled.div`
	padding: 1rem;
	gap: 24px;
	border-radius: ${borderRadius.m};
	border: 1px solid ${({ theme }) => theme.borders.secondary};
	display: flex;
	flex-direction: row;
	margin-bottom: 24px;
	align-items: top;
	justify-content: space-between;
	&.checked {
		background-color: ${newColors.paleGreenTint};
		border: 1px solid transparent;
	}
`;

const MediaFeeTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	label {
		padding-top: 4px;
		color: ${({ theme }) => theme.colors.label};
	}
	p {
		margin-bottom: 0;
	}
`;
const Styled = {
	SaveButtonsWrapper,
	CardContent,
	StyledLink,
	Wrapper,
	AddTeamMembersButton,
	FormContent,
	BackgroundSection,
	IconContainer,
	InputWrapper,
	GuidelineWrapper,
	GuidelineInnerWrapper,
	ButtonsWrapper,
	CustomIcon,
	ErrorMessage,
	TextareaWrapper,
	SideSection,
	CardInnerWrapper,
	FormContentWrapper,
	Label,
	SelectTeamMembers,
	TeamMembersContainer,
	SelectedTeamMember,
	DropdownAvatar,
	DropdownItem,
	SaveButton,
	SpinnerContainer,
	Section,
	SelectWrapContainer,
	MediaFeeWrapper,
	MediaFeeTextWrapper,
};

export default Styled;
