/* eslint-disable no-console */
import axios, { Method, AxiosPromise, InternalAxiosRequestConfig } from 'axios';

import DiscoveryAuthService from 'services/Authentication/Discovery-api/Discovery-auth.service';
import { StatusCode } from 'services/Response.types';

const discoveryApiClient = axios.create({
	baseURL: process.env.VITE_APP_DISCOVERY_API,
	withCredentials: true, // because we are using Authorization in headers
});
const DEBUG = process.env.NODE_ENV === 'development';

discoveryApiClient.interceptors.request.use(
	async (config: InternalAxiosRequestConfig) => {
		let authToken = DiscoveryAuthService.getDiscoveryToken();

		if (null === authToken || (authToken && new Date(authToken.expiresAt) <= new Date())) {
			await DiscoveryAuthService.requestDiscoveryToken();
			authToken = DiscoveryAuthService.getDiscoveryToken();
		}

		// Check if we have a token and add it to the header during the calls
		if (authToken) {
			config.headers!['Authorization'] = `Bearer ${authToken.token}`;
		}

		if (DEBUG) {
			console.info('✉️ Request config: %O', config);
		}

		return config;
	},
	(error) => {
		if (DEBUG) {
			console.error('✉️ ', error);
		}
		Promise.reject(error);
	},
);

discoveryApiClient.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response?.status === StatusCode.UNAUTHORIZED && !error.config._retry) {
			error.config._retry = true;
			await DiscoveryAuthService.requestDiscoveryToken();
			const authToken = DiscoveryAuthService.getDiscoveryToken();
			if (authToken) {
				error.config.headers!['Authorization'] = `Bearer ${authToken.token}`;
				return axios(error.config);
			}
		} else {
			return Promise.reject(error);
		}
	},
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DiscoveryApiClient<T = any>(requestMethod: Method, url: string, data: Record<string, any> = {}, signal: any = null): AxiosPromise<T> {
	return discoveryApiClient.request<T>({
		url,
		method: requestMethod,
		data: data,
		signal: signal,
	});
}
