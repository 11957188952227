import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgAddToFolder(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.74 19H0.95C0.43 19 0 18.57 0 18.04V1.96C0 1.43 0.42 1 0.95 1H5.58C5.83 1 6.07 1.1 6.25 1.28L7.64 2.69H19.06C19.58 2.69 20.01 3.12 20.01 3.65V9.6C20.01 10.02 19.68 10.36 19.26 10.36C18.84 10.36 18.51 10.02 18.51 9.6V4.2H7.41C7.16 4.2 6.92 4.1 6.74 3.92L5.35 2.51H1.49V5.63H16.3381C16.6182 5.63 16.8384 5.85 16.8384 6.13C16.8384 6.41 16.6182 6.63 16.3381 6.63H1.49V17.48H10.74C11.15 17.48 11.49 17.82 11.49 18.24C11.49 18.66 11.16 19 10.74 19ZM15.7279 19C13.3667 19 11.4457 17.08 11.4457 14.72C11.4457 12.36 13.3667 10.44 15.7279 10.44C18.089 10.44 20.01 12.36 20.01 14.72C20.01 17.08 18.089 19 15.7279 19ZM15.7279 11.44C13.917 11.44 12.4462 12.91 12.4462 14.72C12.4462 16.53 13.917 18 15.7279 18C17.5388 18 19.0095 16.53 19.0095 14.72C19.0095 12.91 17.5388 11.44 15.7279 11.44ZM15.2676 12.22H16.2676V14.22H18.1791V15.22H16.2676V17.11H15.2676V15.22H13.2766V14.22H15.2676V12.22Z'
				fill='#333333'
			/>
		</svg>
	);
}
