import { AxiosInstance } from 'axios';
import { JsonApiDocument } from 'json-api-models';

import { createClient } from 'shared/ApiClient/ApiClient';

/**
 * @deprecated use UserManager instead. The UserManager is part of our new ApiManager pattern.
 */
class UserService {
	ENDPOINT_URL: string;
	private client: AxiosInstance;

	constructor() {
		this.client = createClient();
		this.ENDPOINT_URL = '/users';
	}

	async fetchAllUsers(includes: string) {
		const response = await this.client.get<JsonApiDocument>(`${this.ENDPOINT_URL}?includes=${includes}`);
		return response;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async updateUser(id: string, values: any) {
		const response = await this.client.patch<JsonApiDocument>(`${this.ENDPOINT_URL}/${id}`, values);
		return response;
	}

	async searchForUsers(q: string, includes: string) {
		const response = await this.client.get<JsonApiDocument>(`${this.ENDPOINT_URL}/?includes=${includes}&q=${q}`);
		return response;
	}

	async disableUser(url: string) {
		const response = await this.client.post<JsonApiDocument>(url);
		return response;
	}

	async getPayments(userId: string) {
		const response = await this.client.get<JsonApiDocument>(`/admin/users/${userId}/payments`);
		return response;
	}
}

export default new UserService();
