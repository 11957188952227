import { inject, injectable } from 'inversify';

import { InfluencerModel } from 'api-models';
import { ListInfluencersQuery } from 'api-queries';
import { InfluencerCollectionResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';

import type InfluencerApiClientInterface from 'services/ApiClient/InfluencerApiClientInterface';

type Repository = ModelRepository<InfluencerModel>;

@injectable()
class InfluencerManager extends ApiManager<Repository> {
	private readonly client: InfluencerApiClientInterface;

	constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.InfluencerApiClientInterface) client: InfluencerApiClientInterface,
	) {
		super(cacheManager, new ModelRepository<InfluencerModel>('influencer'));
		this.client = client;
	}

	public listInfluencers(queryBuilder = RequestQueryBuilder.create<ListInfluencersQuery>()) {
		const key = `listInfluencers::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listInfluencers(queryBuilder.toQuery());

		return this.swr<InfluencerCollectionResponse>(key, fetcher);
	}

	async createEmailRequest(id: string): Promise<void> {
		return await this.client.createEmailRequest(id, { include: ':hateoas(false)' });
	}
}

export default InfluencerManager;
