import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Dropdown from 'components/Dropdown';
import { Table, TableHeader, TableBody, Th, Tr, Td } from 'styles/table';
import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div``;

const ListTableContainer = styled.div`
	width: 100%;
	height: 100%;
	min-height: 500px;
`;

const ListTable = styled.table`
	${Table};
`;

const ListTableHead = styled.thead`
	${TableHeader};
`;

const ListTableBody = styled.tbody`
	${TableBody};
`;

const ListTableTh = styled.th`
	${Th};
`;

const ListTableTr = styled.tr`
	${Tr};
`;

const ListTableTd = styled.td`
	${Td};
`;

const InfluencerWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;

	.avatar {
		margin-left: 0;
	}
`;

const InfluencerData = styled.div`
	padding: 1.25rem 0.75rem;
`;

const Username = styled.div`
	font-weight: 700;
	font-size: 1.2rem;
`;

const Followers = styled.div`
	font-size: 0.875rem;
	white-space: nowrap;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: ${spacing[3].px};

	@media screen and (${devices.lg}) {
		margin-bottom: ${spacing[4].px};
	}
`;

const BackLink = styled.a`
	display: flex;
	align-items: center;
	font-size: 1.25rem;
	color: ${colors.discovery.black};
	font-weight: 700;

	.icon {
		line-height: 0;
	}
`;

const IconWrapper = styled.div`
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color ease-in-out 0.2s;

	&:hover {
		background-color: ${newColors.ash};
	}
`;

const CustomDropdown = styled(Dropdown)`
	display: flex;
	align-items: center;

	& button {
		padding: 0;

		&:hover,
		&.show {
			border-radius: 2px;
			background-color: ${colors.discovery.iconHover};
		}
	}
`;

const NoResultContainer = styled.div`
	text-align: center;

	button {
		margin: 0 auto;
	}
`;

const ButtonContainer = styled.div`
	margin-top: ${spacing[4].px};
	width: 100%;
	display: flex;
	justify-content: center;
`;

const TableHeaderInnerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	&[data-sort-property] {
		cursor: pointer;
	}
`;

const SortIconWrapper = styled.div`
	display: inline-flex;
	flex-direction: column;
	row-gap: 1.6px;

	& > div {
		&.arrow-up {
			width: 0;
			height: 0;
			border-left: 5px solid ${colors.transparent};
			border-right: 5px solid ${colors.transparent};
			border-bottom: 5px solid ${colors.discovery.gray};
		}

		&.arrow-down {
			width: 0;
			height: 0;
			border-left: 5px solid ${colors.transparent};
			border-right: 5px solid ${colors.transparent};
			border-top: 5px solid ${colors.discovery.gray};
		}

		&.isActive {
			border-color: ${colors.discovery.black};
			border-left: 5px solid ${colors.transparent};
			border-right: 5px solid ${colors.transparent};
		}
	}
`;

const MetricContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: ${spacing[4].px};

	@media screen and (${devices.lg}) {
		margin-bottom: ${spacing[5].px};
	}

	.small-val {
		font-size: 0.875rem;
	}
`;

const IconContainer = styled.div`
	display: flex;
	gap: ${spacing[2].px};
`;

const SharedContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const ListName = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	font-size: 0.875rem;
	@media screen and (${devices.md}) {
		font-size: 1.2rem;
	}
	font-weight: 600;
`;

const Back = styled(Link)`
	width: fit-content;
	white-space: nowrap;
	font-size: 0.875rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	color: ${colors.mainBlack} !important;
	margin-top: 24px;
	&:active,
	&:visited {
		color: ${colors.mainBlack} !important;
	}
	.icon {
		margin-right: ${spacing[1].px};
	}
`;

const ActionIconsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const Styled = {
	Back,
	ListName,
	SharedContainer,
	Wrapper,
	ListTable,
	ListTableHead,
	ListTableBody,
	ListTableTh,
	ListTableTr,
	ListTableTd,
	InfluencerWrapper,
	InfluencerData,
	Username,
	Followers,
	BackLink,
	CustomDropdown,
	NoResultContainer,
	ButtonContainer,
	TableHeaderInnerWrapper,
	SortIconWrapper,
	ButtonWrapper,
	IconWrapper,
	MetricContainer,
	ListTableContainer,
	IconContainer,
	ActionIconsWrapper,
};

export default Styled;
