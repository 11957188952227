import styled from 'styled-components';

import colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

const cm = colors.contentManagement.fileUploadProgress;

const Wrapper = styled.div`
	background-color: ${cm.background};
	margin-inline: auto;
	position: absolute;
	z-index: 2020;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	max-width: 100%;
	right: 0;
`;

const Inner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 5rem;
	padding-bottom: 2rem;

	button {
		width: 300px;
	}
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	max-width: 656px;
	margin-inline: auto;
	gap: 4px;
	margin-bottom: 84px;

	h2 {
		font-size: 1.5rem;
		line-height: 32px;
		margin-bottom: 0;
	}

	p {
		line-height: 24px;
		margin-bottom: 0;

		&.help {
			color: ${cm.helptext.color};
			font-size: 0.875rem;
			text-align: center;
			margin-top: 0;

			& > span {
				text-decoration: underline;
				text-underline-offset: 5px;
				font-weight: 600;
				color: ${cm.helptext.blueColor};
			}
		}
	}

	span {
		display: inline-block;
		margin: 0 4px;
	}
`;

const LoadingContainer = styled.div`
	box-shadow: 0 10px 20px rgba(92, 104, 128, 0.16);
	background-color: ${cm.loadingContainer.background};
	border-radius: 10px;
	padding: 1rem 1.5rem;
	width: 100%;
	max-width: 295px;
	margin: 80px 0 9px 0;
	text-align: left;
	font-size: 0.875rem;
	font-weight: 400;

	.progress-bar {
		margin-top: 8px;
	}
`;

const UploadingText = styled.div`
	display: flex;
	align-items: center;
	text-align: left;
	font-size: 0.875rem;
	font-weight: 400;
`;

const CheckMark = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${cm.checkMarkCircle.background};
	height: 32px;
	width: 32px;
	border-radius: 38px;
	margin-bottom: 24px;

	.icon {
		line-height: 0;
	}
`;

const Done = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;

	.icon {
		line-height: 0;
	}
`;

const Title = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1.063rem;
	font-weight: 600;
	line-height: 1.6;
	letter-spacing: 0;
	margin-bottom: 16px;
`;

const CompletedWrapper = styled.div`
	text-align: center;
	margin-top: 40px;
	padding: 1rem;
	p {
		color: ${colors.contentManagement.grayText};
	}
`;

const Styled = {
	Wrapper,
	Inner,
	LoadingContainer,
	UploadingText,
	CheckMark,
	Content,
	Done,
	Title,
	CompletedWrapper,
};

export default Styled;
