import { Chart, ChartOptions, Plugin } from 'chart.js';
import 'chart.js/auto'; // eslint-disable-line import/no-unassigned-import
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';

import typography from 'styles/variables/typography';

import Styled from './Doughnut.style';
import { IDoughnut } from './types';

/**
 *
 * @param props
 * @returns JSX.Element
 */

Chart.register(ChartDataLabels);

type ChartArea = {
	top: number;
	bottom: number;
	left: number;
	right: number;
	width: number;
	height: number;
};

type LegendPosition = 'top' | 'left' | 'bottom' | 'right';

type PluginOptions = {
	// Define the properties and types you expect
	fontColor: string;
	fontSize: string;
	fontStyle: string;
	legendPosition: LegendPosition;
	innerText: string;
	chartArea: ChartArea;
};

const Doughnut = (props: IDoughnut) => {
	const counter: Plugin<'doughnut'> = {
		id: 'counter',
		beforeDraw: (chart: Chart<'doughnut', number[], unknown>, _args: { cancelable: true }, options: PluginOptions): boolean | void => {
			const { ctx, chartArea } = chart;

			ctx.save();
			ctx.fillStyle = options.fontColor;
			ctx.font = `700 ${options.fontSize} ${options.fontStyle || typography.BaseFontFamiliy}`;
			ctx.textAlign = 'center';

			const legendPosition = options.legendPosition;

			if (legendPosition) {
				ctx.fillText(
					options.innerText ? options.innerText : '',
					legendPosition === 'left' ? (options.chartArea[legendPosition] + chartArea.width + 50) / 2 : (chartArea.width + 50) / 2,
					chartArea.top + chartArea.height / 2,
				);
			} else {
				ctx.fillText(options.innerText ? options.innerText : '', (chartArea.width + 50) / 2, chartArea.top + chartArea.height / 1.8);
			}
		},
	};

	const options: ChartOptions<'doughnut'> = {
		responsive: true,
		maintainAspectRatio: false,
		layout: {
			padding: 25,
		},
		plugins: {
			datalabels: {
				display: props.outsideLabel,
				padding: 10,
				align: 'end',
				anchor: 'end',
				formatter: function (_value, context: Context) {
					return context.chart.data.labels![context.dataIndex];
				},
				font: {
					family: typography.BaseFontFamiliy,
				},
			},
			legend: {
				display: props.legendDisplay,
				position: props.legendPosition,
			},
			title: {
				display: false,
			},
		},
	};

	const doughnutData = {
		labels: props.labels,
		datasets: [
			{
				data: props.data && props.data.reduce((prev, current) => prev + current, 0) > 0 ? props.data : [0, 0, 0, 100],
				backgroundColor: props.data.reduce((prev, current) => prev + current, 0) > 0 ? props.backgroundColors : ['rgba(0,0,0,0.2)'],
				borderColor: props.borderColors,
				borderWidth: props.borderWidth,
				spacing: props.spacing,
				hoverBackgroundColor: props.hoverBackgroundColors,
				hoverBorderColor: props.hoverBorderColors,
				hoverBorderWidth: props.hoverBorderWidth,
				cutout: props.cutout,
			},
		],
	};

	return (
		<Styled.Content>
			<DoughnutChart data={doughnutData} options={options} plugins={[counter]} />
		</Styled.Content>
	);
};

export default Doughnut;
