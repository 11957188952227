import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { InfluencerListListModel } from 'api-models';
import { GhostButton } from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import { CreateListProps, RouteParams } from 'components/InfluencerManagement/Lists/types';
import { FileType, Filter } from 'components/InfluencerManagement/types';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import InfluencerListManager from 'services/ApiManager/InfluencerList.manager';
import toast from 'services/Toast';

/**
 * Create list component
 * @param CreateListProps
 * @returns {JSX.Element}
 */
const CreateList = ({ isLoading, mutate, setListToEdit, setSelectedFilter, setSelectedTab }: CreateListProps): JSX.Element => {
	const [isCreating, setIsCreating] = useState(false);

	const { folderId } = useParams<RouteParams>();
	const manager = useInjection<InfluencerListManager>(InfluencerListManager);

	const handleCreate = () => {
		setIsCreating(true);
		setSelectedFilter(Filter.ALL);
		setSelectedTab(FileType.ALL);
		return manager
			.createList({ name: 'New list', folder: folderId, collabsIds: [], users: [] }, undefined, {
				mutateFn: mutate,
			})
			.then((list: InfluencerListListModel) => {
				setIsCreating(false);
				const listWithoutName = { ...list, attributes: { ...list.attributes, name: '' } };
				setListToEdit(listWithoutName);
			})
			.catch((error) => {
				setIsCreating(false);
				toast.error(getSomethingWentWrongMessage());
				console.error('Error creating list', error);
			});
	};

	return (
		<GhostButton disabled={isLoading} onClick={() => handleCreate()} data-testid='create-list-button'>
			{isCreating ? (
				<LoadingSpinner size='sm' />
			) : (
				<span>
					<Icon name='list' />
					New list
				</span>
			)}
		</GhostButton>
	);
};

export default CreateList;
