import styled from 'styled-components';

import { Scale } from 'styles/theme/types';

const ContentBefore = styled.span`
	display: flex;
	align-items: center;
	margin-right: ${({ theme }) => theme.spacing[1].px};
`;

const ContentAfter = styled.span`
	display: flex;
	align-items: center;
	padding-top: 3px;
	margin-left: ${({ theme }) => theme.spacing[1].px};

	.icon {
		line-height: 0;
	}
`;

const InputContainer = styled.span<{ scale: Scale }>`
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.inputs};
	border-radius: ${({ theme }) => theme.radius.small};
	border: 1px solid ${({ theme }) => theme.borders.secondary};
	transition: border-color 0.2s ease-in-out;

	&.has-warning {
		margin: -1px;
		border-color: ${({ theme }) => theme.borders.warning};
		border-width: 2px;
	}

	&.has-error {
		margin: -1px;
		border-color: ${({ theme }) => theme.borders.error};
		border-width: 2px;
	}

	&:hover:not(.disabled):not(.readonly):not(.has-error):not(.has-warning) {
		border-color: ${({ theme }) => theme.borders.hover};
	}

	&.has-focus:not(.readonly) {
		border-color: ${({ theme }) => theme.borders.focus} !important;
		outline: none;
	}

	padding: ${({ scale }) => {
		switch (scale) {
			case 'small':
				return '00.5rem';
			case 'large':
				return '0 1rem';
			default:
				return '0 0.625rem';
		}
	}};
`;

const Input = styled.input<{ scale: Scale }>`
	background-color: transparent;
	border: none;
	width: 100%;
	color: ${({ theme }) => theme.colors.text};
	font-size: ${({ theme, scale }) => {
		switch (scale) {
			case 'small':
				return theme.fontSizes.smallInput;
			case 'large':
				return theme.fontSizes.largeInput;
			default:
				return theme.fontSizes.input;
		}
	}};

	padding: ${({ scale }) => {
		switch (scale) {
			case 'small':
				return '0.4375rem 0';
			case 'large':
				return '1rem 0';
			default:
				return '0.675rem 0';
		}
	}};

	height: ${({ scale }) => {
		switch (scale) {
			case 'small':
				return '38px';
			case 'large':
				return '60px';
			default:
				return '44px';
		}
	}};

	&::placeholder {
		color: ${({ theme }) => theme.colors.placeholder};
	}

	&:disabled::placeholder {
		color: ${({ theme }) => theme.colors.textDisabled};
	}

	&:disabled {
		color: ${({ theme }) => theme.colors.textDisabled};
	}

	&:read-only {
		cursor: default;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:active,
	&:-internal-autofill-selected {
		background-color: transparent !important;
		transition:
			background-color 5000s ease-in-out 0s,
			color 5000s ease-in-out 0s;
	}

	&:focus,
	&:focus-visible {
		border: 0;
		box-shadow: none;
		outline: none;
	}

	&[type='date'] {
		position: relative;
	}

	&[type='date']::-webkit-calendar-picker-indicator {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: auto;
		height: auto;
		color: transparent;
		background: transparent;
		cursor: pointer;
	}
`;

const Styled = {
	InputContainer,
	Input,
	ContentBefore,
	ContentAfter,
};

export default Styled;
