import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgAll(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_9123_372716)'>
				<path
					d='M19.046 20.009H11.9495C11.4198 20.009 11 19.5786 11 19.0581V11.951C11 11.4304 11.4298 11 11.9495 11H19.036C19.5657 11 19.9855 11.4304 19.9855 11.951V19.048C19.9855 19.5786 19.5557 19.999 19.036 19.999L19.046 20.009ZM12.4993 18.5075H18.4963V12.5015H12.4993V18.5075Z'
					fill='#4D4B4C'
				/>
				<path
					d='M8.04598 20.009H0.949525C0.41979 20.009 0 19.5786 0 19.0581V11.951C0 11.4304 0.429785 11 0.949525 11H8.03598C8.56572 11 8.98551 11.4304 8.98551 11.951V19.048C8.98551 19.5786 8.55572 19.999 8.03598 19.999L8.04598 20.009ZM1.49925 18.5075H7.49625V12.5015H1.49925V18.5075Z'
					fill='#4D4B4C'
				/>
				<path
					d='M19.0362 9.00901H11.9498C11.42 9.00901 10.9902 8.57858 10.9902 8.05806V0.950951C10.9902 0.42042 11.42 0 11.9498 0H19.0362C19.5659 0 19.9857 0.43043 19.9857 0.950951V8.04805C19.9857 8.57858 19.556 8.999 19.0362 8.999V9.00901ZM12.4995 7.50751H18.4965V1.5015H12.4995V7.50751Z'
					fill='#4D4B4C'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.5 7.5C6.15685 7.5 7.5 6.15685 7.5 4.5C7.5 2.84315 6.15685 1.5 4.5 1.5C2.84315 1.5 1.5 2.84315 1.5 4.5C1.5 6.15685 2.84315 7.5 4.5 7.5ZM4.5 9C6.98528 9 9 6.98528 9 4.5C9 2.01472 6.98528 0 4.5 0C2.01472 0 0 2.01472 0 4.5C0 6.98528 2.01472 9 4.5 9Z'
					fill='#4D4B4C'
				/>
			</g>
			<defs>
				<clipPath id='clip0_9123_372716'>
					<rect width='20' height='20' fill='white' transform='translate(-0.00390625)' />
				</clipPath>
			</defs>
		</svg>
	);
}
