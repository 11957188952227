import BeachClub from 'assets/img/beach-club.jpg';
import CrueltyFreeBeatuy from 'assets/img/cruelty-free-beauty.jpg';
import FamilyVacation from 'assets/img/family-vacation.jpg';
import MealPreparation from 'assets/img/meal-preparation.jpg';
import StreetFashionClothing from 'assets/img/street-fashion-clothing.jpg';
import YogaBeginners from 'assets/img/yoga-beginners.jpg';

export const Searches = [
	{
		inputValue: 'A day at the Beach club',
		img: BeachClub,
	},
	// {
	// 	inputValue: 'Budget back packing',
	// 	img: BudgetBackpacking,
	// },
	{
		inputValue: 'Cruelty free beauty',
		img: CrueltyFreeBeatuy,
	},
	{
		inputValue: 'Family vacation',
		img: FamilyVacation,
	},
	// {
	// 	inputValue: 'Gardening',
	// 	img: Gardening,
	// },
	// {
	// 	inputValue: 'Hiking',
	// 	img: Hiking,
	// },
	{
		inputValue: 'Meal prep recipes',
		img: MealPreparation,
	},
	// {
	// 	inputValue: 'Vintage fashion',
	// 	img: VintageFashion,
	// },
	{
		inputValue: 'Yoga for beginners',
		img: YogaBeginners,
	},
	{
		inputValue: 'Street fashion clothing',
		img: StreetFashionClothing,
	},
	// {
	// 	inputValue: 'Gym workout',
	// 	img: GymWorkout,
	// },
	// {
	// 	inputValue: 'Mountain climb',
	// 	img: MountainClimb,
	// },
];
