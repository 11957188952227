import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { BrandModel, ClientModel, PublisherModel } from 'api-models';
import { ListClientsQuery } from 'api-queries';
import noResult from 'assets/icons/empty-state-no-result.svg';
import EmptyState from 'components/EmptyState/V2';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import CenteredSpinner from 'components/Settings/Components/CenteredSpinner/CenteredSpinner';
import ClientItemCard from 'components/Settings/Components/ClientItemCard/ClientItemCard';
import CreateClient from 'components/Settings/Components/CreateClient/CreateClient';
import NotItems from 'components/Settings/Components/NoItems/NoItems';
import { CREATE_CLIENT } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import useInjection from 'hooks/useInjection';
import ClientManager from 'services/ApiManager/Client.manager';
import Grid from 'styles/grid/grid';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import Styled from './ClientManagement.style';
import { ClientsSectionProps } from './types';

const ClientManagement = ({ publisher }: ClientsSectionProps) => {
	const [searchValue, setSearchValue] = useState('');
	const { userCan } = useFeaturePermissions(publisher.links);

	const manager = useInjection<ClientManager>(ClientManager);
	const queryBuilder = RequestQueryBuilder.create<ListClientsQuery>(['logo', 'edit', 'delete', 'createInvitation', 'createBrand'])
		.withInclude('brands', ['logo', 'createInvitation', 'edit', 'delete'])
		.withInclude('publisher', ['logo', 'createInvitation', 'edit', 'delete', 'createClient']);
	const { result: clients, repository, isLoading, mutate } = manager.listClients(queryBuilder);

	const getBrandsByClient = (client: ClientModel): BrandModel[] => {
		return client && repository.findByRelation<BrandModel, ClientModel>(client, 'brands');
	};

	const getPublisherByClient = (client: ClientModel): PublisherModel | null => {
		return client && repository.findOneByRelation<PublisherModel, ClientModel>(client, 'publisher');
	};

	const matchesSearch = (search: string, client: ClientModel): boolean => {
		const toLower = search.toLowerCase().trim();
		const name = client.attributes.name.toLowerCase().trim();

		// Get brands associated with the client
		const brands = getBrandsByClient(client);

		// Check if any brand matches the search
		const brandMatches = brands.some((brand) => brand.attributes.name.toLowerCase().trim().includes(toLower));

		return brandMatches || toLower === '' || name.includes(toLower);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Grid.Container gap='24'>
			<Grid.Column xl={12}>
				<Styled.TopSection>
					<h4>Client management</h4>
					<Styled.Text>
						{userCan(CREATE_CLIENT) ? 'Add your clients below.' : null} Each client can have any number of brands associated with it. You must add a brand
						before creating a campaign. <span>Please note, no information will be shared between clients.</span>
					</Styled.Text>
				</Styled.TopSection>
			</Grid.Column>
			<Grid.Column xl={12}>
				<Styled.FlexDiv>
					{userCan(CREATE_CLIENT) ? <CreateClient publisher={publisher} mutateFn={mutate} /> : <div />}
					{clients && clients?.length > 0 && (
						<Styled.SearchInputWrapper>
							<Field>
								<Input
									placeholder='Search for clients or brands'
									value={searchValue}
									onChange={({ target }) => setSearchValue(target.value)}
									contentBefore={<Icon name='search' size='16' />}
								/>
							</Field>
						</Styled.SearchInputWrapper>
					)}
				</Styled.FlexDiv>
			</Grid.Column>
			<Grid.Column xl={12}>
				{isLoading || undefined === clients ? (
					<CenteredSpinner />
				) : (
					<>
						{clients.length > 0 ? (
							<Styled.ClientContainer
								id='clients'
								className={classNames({ searching: searchValue.length > 0 && clients.filter((client) => matchesSearch(searchValue, client)).length > 0 })}
							>
								{clients
									.sort((a: ClientModel, b: ClientModel) => a.attributes.name.toLowerCase().localeCompare(b.attributes.name.toLowerCase()))
									.filter((client) => matchesSearch(searchValue, client))
									.map((client, key) => {
										return (
											<ClientItemCard
												key={key}
												client={client}
												publisher={getPublisherByClient(client)}
												brands={repository.findByRelation<BrandModel, ClientModel>(client, 'brands')}
											/>
										);
									})}
								{clients.filter((client) => matchesSearch(searchValue, client)).length === 0 && (
									<Styled.EmptyResultImgWrapper>
										<EmptyState
											image={noResult}
											altText='No result found'
											testId='no-result-found'
											text={
												<>
													<p>
														Unfortunately, <strong>'{searchValue}'</strong> did not return any results.
													</p>
													{userCan(CREATE_CLIENT) ? <p>Check the spelling or add a new client.</p> : <p>Check the spelling.</p>}
												</>
											}
										/>
									</Styled.EmptyResultImgWrapper>
								)}
							</Styled.ClientContainer>
						) : (
							<NotItems icon='influencer' title='Add client' text='Add a client to organize and manage their brands.' />
						)}
					</>
				)}
			</Grid.Column>
		</Grid.Container>
	);
};

export default ClientManagement;
