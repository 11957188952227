import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	width: 100%;
	padding: ${guttersWithRem.m};
	height: calc(100vh + -72px);
	justify-content: center;
	display: flex;
	margin-top: 150px;
	& > div {
		justify-content: center;
		flex-direction: column;

		& > div {
			text-align: center;
			max-width: 313px;

			&.image-wrapper {
				margin-bottom: ${guttersWithRem.m};

				& > img {
					max-height: 150px;
				}
			}

			&.strong {
				font-weight: 700;
				margin-bottom: ${guttersWithRem.m};
			}

			&.other-text {
				font-size: 0.875rem;
				margin-bottom: ${guttersWithRem.l};
			}
		}

		& > button {
			background-color: ${colors.transparent};
			margin: auto;
		}
	}

	&.blast {
		height: fit-content;
		overflow-y: hidden;
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
