import { useState } from 'react';

import Styled from 'components/DataLibrary/Graphs/GraphComponents/CustomBarChart/BarList/BarList.style';
import LoadingSpinner from 'components/LoadingSpinner';
import { formatNumber } from 'shared/helpers/Chart/chart-util';

import Bar from './Bar';
import BarItem from './BarItem';

const BarItemContainer = (props: {
	isFetching: boolean;
	total: number;
	item: { label: string; value: number; fillerColor: string; containerColor: string };
}) => {
	const { item, isFetching, total } = props;
	const percent = `${Math.ceil((item.value / total) * 100)}%`;
	const [displayRealValue, setDisplayRealValue] = useState<boolean>(false);

	return (
		<Styled.BarWrapper
			key={1}
			onMouseEnter={() => {
				setDisplayRealValue(true);
			}}
			onMouseLeave={() => {
				setDisplayRealValue(false);
			}}
		>
			<BarItem
				label={item.label}
				value={
					isFetching ? (
						<Styled.SpinnerWrapper>
							<LoadingSpinner size='sm' />
						</Styled.SpinnerWrapper>
					) : displayRealValue ? (
						item.value
					) : (
						formatNumber(item.value, true)
					)
				}
				key={'Post'}
			>
				<Bar percent={percent} containerBg={item.containerColor} fillerBg={item.fillerColor} />
			</BarItem>
		</Styled.BarWrapper>
	);
};

export default BarItemContainer;
