import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'sonner';

import FullscreenModal from 'components/ContentManagement/Components/FullscreenModal';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon';

import Styled from './CustomDropZone.style';

type Props = {
	imageUrl?: string;
	title: string;
	handleOnDrop(files: File[]): void;
	remove?: () => void;
	deleteImage?: () => void;
	deleteImageUrl?: string;
	isOnSettings?: boolean;
	canEdit: boolean;
	canDelete?: boolean;
	helpText?: string;
};

/**
 * @returns {JSX.Element}
 */
const CustomDropZone = ({
	deleteImage,
	deleteImageUrl,
	remove,
	imageUrl,
	title,
	handleOnDrop,
	isOnSettings,
	canEdit,
	canDelete,
	helpText,
}: Props): JSX.Element => {
	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles[0]?.size > 4194304) {
			toast.error('Max size is 4MB');
		} else {
			handleOnDrop(acceptedFiles);
		}
	}, []);
	const [isFullscreenOpen, setIsFullscreenOpen] = useState<boolean>(false);

	const onClickFullScreen = () => {
		setIsFullscreenOpen(true);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			'image/jpeg': [],
			'image/jpg': [],
			'image/png': [],
		},
	});

	return (
		<Styled.DropZoneWrapper>
			{imageUrl ? (
				<>
					<Styled.LogoWrapper data-testid='dropzone-image'>
						{isOnSettings ? (
							<Styled.Image src={imageUrl} />
						) : (
							<Styled.Logo style={{ backgroundImage: `url(${imageUrl})` }}>
								<Styled.ShowFullSizeButton onClick={onClickFullScreen} data-testid='image-show-full-size'>
									<Icon name='zoom' size='18' />
								</Styled.ShowFullSizeButton>
							</Styled.Logo>
						)}
					</Styled.LogoWrapper>
					{canEdit && (
						<Dropdown icon='options' size='16'>
							<DropdownMenu>
								{!deleteImageUrl && remove && !isOnSettings && (
									<DropdownItem onClick={remove} data-testid='image-remove-menu-item'>
										<Styled.IconMargin>Remove</Styled.IconMargin>
									</DropdownItem>
								)}
								{deleteImage && (deleteImageUrl || canDelete) && (
									<DropdownItem onClick={deleteImage} data-testid='image-delete-menu-item'>
										<Styled.IconMargin>Delete</Styled.IconMargin>
									</DropdownItem>
								)}
							</DropdownMenu>
						</Dropdown>
					)}
				</>
			) : canEdit ? (
				<Styled.DropZone {...getRootProps({ className: 'dropzone' })}>
					<input {...getInputProps()} data-testid='drop-zone-image-input' />
					{!isDragActive && (
						<div>
							<Icon name='expand' size='28' />
							<p data-testid='custom-drop-zone-title'>{title}</p>
							<Styled.CustomGhostButton role='button'>Browse</Styled.CustomGhostButton>
						</div>
					)}
				</Styled.DropZone>
			) : (
				<Styled.Placeholder>{helpText ?? 'Ask a manager to upload logo'}</Styled.Placeholder>
			)}
			{imageUrl && <FullscreenModal file={imageUrl} isOpen={isFullscreenOpen} handleClose={() => setIsFullscreenOpen(false)} />}
		</Styled.DropZoneWrapper>
	);
};

export default CustomDropZone;
