import styled, { DefaultThemeV2 } from 'styled-components';

import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';

type P = { theme: DefaultThemeV2 };

interface Avatar {
	img?: string | undefined;
	size?: 'sm' | 'md' | 'lg';
	scale: 'sm' | 'md' | 'lg';
}

const UserAvatar = styled.div<Avatar>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background: url(${(props) => props.img ?? ''});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: ${(props: P) => props.theme.colors.avatarBackground};
	border-radius: 50%;
	width: ${(props) => (props.scale === 'sm' ? '32px' : props.scale === 'md' ? '40px' : '56px')};
	height: ${(props) => (props.scale === 'sm' ? '32px' : props.scale === 'md' ? '40px' : '56px')};
	z-index: 10;
	&.account {
		border: 3px solid ${newColors.skyBlue};
	}
`;

const LetterAvatar = styled(Avatar)<{ scale: 'sm' | 'md' | 'lg' }>`
	margin-left: 0;
	width: ${(props) => (props.scale === 'sm' ? '32px' : props.scale === 'md' ? '40px' : '56px')};
	height: ${(props) => (props.scale === 'sm' ? '32px' : props.scale === 'md' ? '40px' : '56px')};
`;

const Wrapper = styled.div`
	position: relative;
	width: max-content;
`;

const NetworkIcon = styled(Icon)`
	position: absolute;
	border: 1px solid ${colors.white};
	border-radius: 50%;
	bottom: 0px;
	right: -5px;
	width: 22px;
	height: 22px;
	z-index: 12;
	line-height: 0.5;
	&.missing-email {
		right: 10px;
	}
`;

const EmailIcon = styled(Icon)`
	position: absolute;
	border: 1px solid ${newColors.mist};
	background-color: ${newColors.rose};
	border-radius: 50%;
	bottom: 0px;
	right: -5px;
	width: 22px;
	height: 22px;
	z-index: 13;
	line-height: 0.5;
	svg {
		margin-top: 5px;
		margin-left: 4px;
		path {
			fill: ${colors.red};
		}
	}
`;

const NotificationDot = styled.div`
	position: absolute;
	top: 0;
	right: -4px;
	width: 12px;
	height: 12px;
	background-color: ${colors.dropdown.button.notificationCircle};
	border: 1px solid ${colors.white};
	border-radius: 20px;
	transition: opacity 0.2s ease-in-out;
	opacity: 0;
	z-index: 12;

	&.visible {
		opacity: 1;
	}
`;

const PulseRing = styled.div`
	@keyframes pulsate {
		0% {
			transform: scale(1, 1);
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			transform: scale(1.5, 1.5);
			opacity: 0;
		}
	}
	width: 40px;
	height: 40px;
	background: radial-gradient(#333 0%, #aeaeae87 0.01%, #cccccc5e 100%);
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	animation: 1s 2 pulsate;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const Styled = {
	Container,
	EmailIcon,
	UserAvatar,
	NetworkIcon,
	Wrapper,
	NotificationDot,
	PulseRing,
	LetterAvatar,
};

export default Styled;
