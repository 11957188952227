import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DashboardType } from './types';

export const initialState: DashboardType = {
	id: '',
	name: '',
	active: false,
	createdAt: '',
	items: [],
	metaData: {},
	users: [],
	dashboardUsers: [],
	links: {},
	paidMedia: [],
	hasPaidMedia: false,
	uniqueInfluencers: 0,
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		setDashboard(state, action: PayloadAction<DashboardType>) {
			state = action.payload;
			return state;
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setFilterOnDashboard(state, action: PayloadAction<any>) {
			const payload = action.payload;
			state = {
				...state,
				metaData: payload,
			};
			return state;
		},
		clearDashboard(state) {
			state = initialState;
			return state;
		},
	},
});

export const { setDashboard, clearDashboard, setFilterOnDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
