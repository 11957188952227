import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

export const focusInput = keyframes`
	from {
		opacity: 0;
		backdrop-filter: invert(30%);
	}
	to {
		opacity: 100%;
		backdrop-filter: unset;

	}
`;
