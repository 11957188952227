import Input from 'components/Forms/Input';
import Select from 'components/Forms/Select';
import Textarea from 'components/Forms/Textarea';

export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

export const parseMaxLength = (maxLength: number | string | undefined): number | undefined => {
	if (maxLength === undefined) return undefined;
	return typeof maxLength === 'string' ? parseInt(maxLength, 10) : maxLength;
};

export const resolveFieldType = (as?: string): React.ElementType => {
	switch (as) {
		case 'textarea':
			return Textarea;
		case 'select':
			return Select;
		default:
			return Input; // Default to 'Input' if not specified
	}
};
