import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgBrand(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 0C13.86 0 17 3.14 17 7C17 9.39 15.8 11.5 13.97 12.76V19.17C13.97 19.63 13.6 20 13.14 20H12.97L10 18.59L7.03 20H6.86C6.4 20 6.03 19.63 6.03 19.17V12.76C4.2 11.5 3 9.39 3 7C3 3.14 6.14 0 10 0ZM10 1.42C6.93 1.42 4.42 3.93 4.42 7C4.42 10.07 6.92 12.58 10 12.58C13.08 12.58 15.58 10.08 15.58 7C15.58 3.92 13.07 1.42 10 1.42ZM10.35 17.1L12.46 18.1H12.47V13.54C11.7 13.83 10.87 14 10 14C9.13 14 8.3 13.83 7.53 13.54V18.1L9.96 16.95L10.35 17.1ZM7.31564 9.31049C7.2826 9.53121 7.37071 9.74189 7.56896 9.87231L7.57997 9.89238C7.77822 10.0228 8.03154 10.0328 8.2408 9.93251L10.025 9.06971L11.8203 9.92248C12.0295 10.0228 12.2828 10.0027 12.4811 9.87231C12.6683 9.74189 12.7674 9.52117 12.7234 9.31049L12.371 7.49459L13.8138 6.20039C13.979 6.0499 14.045 5.82919 13.9679 5.6185C13.8909 5.40782 13.7036 5.25733 13.4613 5.22723L11.4568 4.96638L10.5537 3.32104C10.4546 3.12039 10.2343 3 9.99198 3C9.74968 3 9.54042 3.13042 9.43028 3.32104L8.53817 4.97642L6.53367 5.2473C6.29136 5.27739 6.10413 5.42788 6.02703 5.63857C5.96095 5.83922 6.01602 6.06997 6.19224 6.22046L7.64605 7.49459L7.31564 9.31049ZM8.2408 6.86254L7.10638 5.86932H7.08435L8.64831 5.65863C8.95669 5.6185 9.21001 5.44795 9.34217 5.19713L10.036 3.90293L10.7409 5.1871C10.8731 5.42788 11.1374 5.59844 11.4348 5.63857L12.9987 5.83922L11.8753 6.84248C11.6551 7.04313 11.5559 7.31401 11.611 7.58489L11.8863 8.99948L10.4876 8.33733C10.2233 8.20691 9.90387 8.20691 9.63954 8.33733L8.2408 9.00951L8.50513 7.59492C8.5602 7.33407 8.46107 7.05316 8.2408 6.86254Z'
				fill='#333333'
			/>
		</svg>
	);
}
