/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { UpdateCampaignInstagramOwnerPayload } from 'api-payloads';
import { ListCampaignInstagramOwnersQuery, ViewCampaignInstagramOwnersQuery, UpdateCampaignInstagramOwnerQuery } from 'api-queries';
import { CampaignInstagramOwnerCollectionResponse, CampaignInstagramOwnerResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import CampaignInstagramOwnerApiClientInterface from './CampaignInstagramOwnerApiClientInterface';

@injectable()
export default class CampaignInstagramOwnerApiClient implements CampaignInstagramOwnerApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List campaign instagram owners
	 */
	async listCampaignInstagramOwners(params: ListCampaignInstagramOwnersQuery): Promise<CampaignInstagramOwnerCollectionResponse> {
		const response = await this.client.get<CampaignInstagramOwnerCollectionResponse>(`/campaign-instagram-owners`, { params });
		return response.data;
	}

	/**
	 * View campaign instagram owner
	 */
	async viewCampaignInstagramOwners(
		campaignId: string,
		influencerId: string,
		params: ViewCampaignInstagramOwnersQuery,
	): Promise<CampaignInstagramOwnerResponse> {
		const response = await this.client.get<CampaignInstagramOwnerResponse>(`/campaigns/${campaignId}/influencers/${influencerId}`, { params });
		return response.data;
	}

	/**
	 * Update campaign instagram owner
	 */
	async update(
		campaignId: string,
		influencerId: string,
		payload: UpdateCampaignInstagramOwnerPayload,
		params: UpdateCampaignInstagramOwnerQuery,
	): Promise<CampaignInstagramOwnerResponse> {
		const response = await this.client.patch<CampaignInstagramOwnerResponse>(`/campaigns/${campaignId}/influencers/${influencerId}`, payload, { params });
		return response.data;
	}

	/**
	 * Delete campaign instagram owner
	 */
	async delete(campaignId: string, campaignInstagramOwnerId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/instagram-owners/${campaignInstagramOwnerId}`);
		return response.data;
	}
}
