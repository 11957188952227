import { Model } from 'json-api-models';
import { useMemo, useState } from 'react';

import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import { Network } from 'constants/socialMedia';
import { formatNumber } from 'shared/helpers/Chart/chart-util';

import Styled from './InfluencerInfo.style';

const InfluencerInfo = (props: { influencer: Model }) => {
	const [isHover, setIsHover] = useState<boolean>(false);

	const socialNetwork = useMemo((): Network | null => {
		if (!props.influencer.attributes) {
			return null;
		}
		const { network } = props.influencer.attributes;
		switch (network) {
			case Network.TIKTOK:
				return Network.TIKTOK;
			case Network.INSTAGRAM:
				return Network.INSTAGRAM;
			default:
				return Network.OTHER;
		}
	}, [props.influencer]);

	const socialNetworkLink = () => {
		switch (socialNetwork) {
			case Network.INSTAGRAM:
				return `https://www.instagram.com/${props.influencer.username}`;
			case Network.TIKTOK:
				return `https://www.tiktok.com/@${props.influencer.username}`;
			default:
				return '';
		}
	};

	const socialNetworkIcon = () => {
		switch (socialNetwork) {
			case Network.INSTAGRAM:
				return 'instagram';
			case Network.TIKTOK:
				return 'tiktok';
			default:
				return '';
		}
	};

	const socialNetworkName = () => {
		switch (socialNetwork) {
			case Network.INSTAGRAM:
				return 'Instagram';
			case Network.TIKTOK:
				return 'TikTok';
			default:
				return socialNetwork;
		}
	};

	return (
		<Styled.Wrapper>
			<Styled.InfluencerWrapper>
				<Avatar imageUrl={props.influencer.links.profilePictureUrl} name={props.influencer.username} size='lg' />
				<div>
					<div className='name'>{props.influencer.username}</div>
					<Styled.ProfileFollower
						onMouseEnter={() => {
							setIsHover(true);
						}}
						onMouseLeave={() => {
							setIsHover(false);
						}}
					>
						<Icon name={socialNetworkIcon()} size='16' />
						<a href={socialNetworkLink()} target='_blank' data-testid='social-link'>
							{isHover ? <>Go to {socialNetworkName()}</> : <>{formatNumber(props.influencer.followersCount)} Followers</>}
						</a>
					</Styled.ProfileFollower>
				</div>
			</Styled.InfluencerWrapper>
		</Styled.Wrapper>
	);
};

export default InfluencerInfo;
