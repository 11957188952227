export function loadState(key: string) {
	try {
		const serializedState = localStorage.getItem(key);
		if (!serializedState) return undefined;
		return JSON.parse(serializedState);
	} catch (e) {
		return undefined;
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function saveState(state: any, key: string) {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem(key, serializedState);
	} catch (e) {
		// Ignore
	}
}
