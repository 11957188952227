import OauthPopup from 'components/OAuthPopup';
import SPCStyled from 'components/SocialProfileConnector/SocialProfileConnector.style';
import { HANDLE_TIKTOK_CODE, TIKTOK_OAUTH_URL } from 'constants/hateoas-keys';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { UserType } from 'reducers/UserReducers/types';

type TikTokConnectorProps = {
	onCode: (code: string, params: URLSearchParams, url: string) => void;
	dispayAsLink?: boolean;
};

/**
 * TikTokConnector
 * @param {TikTokConnectorProps} props
 * @returns {JSX.Element}
 */
const TikTokConnector = ({ onCode, dispayAsLink }: TikTokConnectorProps): JSX.Element => {
	const user: UserType = useAppSelector((state) => state.user);
	const oauthURL = (user.links && user.links[TIKTOK_OAUTH_URL]) || '';
	const handleTiktokCode = (user.links && user.links[HANDLE_TIKTOK_CODE]) || '';

	const onClosePopup = () => {};

	const onGetCode = (code: string, params: URLSearchParams, url: string) => onCode(code, params, url);

	const defaultView = () => {
		return (
			<SPCStyled.ConnectSection>
				<p>Add a TikTok Business Account to share promotional insights.</p>
				{oauthURL !== '' && (
					<OauthPopup
						url={oauthURL}
						title='Auth'
						width={600}
						height={700}
						onClose={onClosePopup}
						onCode={(code: string, params: URLSearchParams) => {
							if (handleTiktokCode) {
								onGetCode(code, params, handleTiktokCode);
							}
						}}
					>
						<SPCStyled.ConnectButton size='lg' data-testid='connect-button'>
							<span>Continue with TikTok</span>
						</SPCStyled.ConnectButton>
					</OauthPopup>
				)}
			</SPCStyled.ConnectSection>
		);
	};

	const LinkView = () => {
		return (
			<SPCStyled.ConnectSection className='link-view'>
				{oauthURL !== '' && (
					<OauthPopup
						url={oauthURL}
						title='Auth'
						width={600}
						height={700}
						onClose={onClosePopup}
						onCode={(code: string, params: URLSearchParams) => {
							if (handleTiktokCode) {
								onGetCode(code, params, handleTiktokCode);
							}
						}}
					>
						<SPCStyled.ConnectLink>Add more TikTok accounts</SPCStyled.ConnectLink>
					</OauthPopup>
				)}
			</SPCStyled.ConnectSection>
		);
	};

	return !dispayAsLink ? defaultView() : LinkView();
};
export default TikTokConnector;
