import { inject, injectable } from 'inversify';

import { SecurityTokenModel, TokenModel, UserModel } from 'api-models';
import { TokenPayload } from 'api-payloads';
import { CurrentUserQuery } from 'api-queries';
import { CurrentUserResponse, UserResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';

import type SecurityApiClientInterface from 'services/ApiClient/SecurityApiClientInterface';
import type UserApiClientInterface from 'services/ApiClient/UserApiClientInterface';

type Repository = ModelRepository<UserModel>;

/**
 * This manager is responsible for authentication and user activation stuff. Like validate password, verification email etc.
 */
@injectable()
class AuthenticationManager extends ApiManager<Repository> {
	private readonly userClient: UserApiClientInterface;
	private readonly securityClient: SecurityApiClientInterface;

	public constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.UserApiClientInterface) userClient: UserApiClientInterface,
		@inject(ApiClientService.SecurityApiClientInterface) securityClient: SecurityApiClientInterface,
	) {
		super(cacheManager, new ModelRepository<UserModel>('user'));
		this.userClient = userClient;
		this.securityClient = securityClient;
	}

	public getCurrentUser(queryBuilder = RequestQueryBuilder.create<CurrentUserQuery>()) {
		const key = `getCurrentUser::${queryBuilder.toHash()}`;
		const fetcher = () => this.userClient.current(queryBuilder.toQuery());

		return this.swr<CurrentUserResponse>(key, fetcher);
	}

	public acceptInfluencerTermsOfConditions(): Promise<CurrentUserResponse> {
		return this.userClient.acceptInfluencerTermsOfConditions({ include: ':hateoas(false)' });
	}

	public acceptPublisherTermsOfConditions(): Promise<CurrentUserResponse> {
		return this.userClient.acceptPublisherTermsOfConditions({ include: ':hateoas(false)' });
	}

	public async createToken(credentials: TokenPayload): Promise<TokenModel> {
		const response = await this.securityClient.token(credentials, { include: ':hateoas(false)' });
		return response.data;
	}

	public async createMercureToken(): Promise<SecurityTokenModel> {
		const response = await this.userClient.createMercureToken({ include: ':hateoas(false)' });
		return response.data;
	}

	public sendEmailVerificationEmail(userId: string): Promise<UserResponse> {
		return this.userClient.sendEmailVerificationEmail(userId, { include: ':hateoas(false)' });
	}
}

export default AuthenticationManager;
