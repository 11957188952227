import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgHeart(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M9.96081 18.9978C9.81206 18.9978 9.66331 18.9517 9.53315 18.8501L9.26354 18.6378C8.86378 18.3333 8.45472 18.0287 8.04566 17.7241C5.86091 16.0905 3.60178 14.4014 1.84468 12.1494C0.385077 10.285 -0.182029 8.51289 0.0503912 6.56543C0.282812 4.69181 1.33335 3.02124 2.9417 1.98751C4.46638 1.0184 6.30715 0.750738 7.99918 1.24914C8.71503 1.46142 9.35651 1.81215 9.9794 2.33824C11.2438 1.29529 12.8614 0.824576 14.5348 1.05532C16.6731 1.34144 18.4767 2.68897 19.3692 4.64566C20.3175 6.73157 20.1966 8.81747 19.0066 10.8572C18.1513 12.3247 16.9613 13.6631 15.2693 15.0567C14.2187 15.9243 13.1217 16.755 12.0619 17.558C11.5599 17.9456 11.1043 18.2871 10.6488 18.6378L10.3792 18.8501C10.249 18.9517 10.1003 18.9978 9.95151 18.9978H9.96081ZM6.28856 2.38439C5.39606 2.38439 4.49427 2.64282 3.70404 3.15045C2.43967 3.95343 1.62155 5.26405 1.44491 6.72234C1.25898 8.28215 1.73312 9.74044 2.951 11.3003C4.59654 13.4139 6.772 15.0383 8.88237 16.6165C9.24495 16.8842 9.60753 17.1519 9.96081 17.4288C10.3699 17.1149 10.7696 16.8104 11.1787 16.5058C12.2757 15.6659 13.3634 14.8444 14.3861 14.0045C15.948 12.7124 17.0357 11.4941 17.8073 10.1742C18.7556 8.54981 18.8579 6.87924 18.1048 5.22713C17.4169 3.70423 16.013 2.66128 14.3489 2.43977C12.88 2.23672 11.5134 2.71666 10.5 3.77807C10.3699 3.91652 10.1932 3.99035 10.0073 3.99035C9.85854 4.01804 9.63542 3.91652 9.50526 3.7873C8.87308 3.15968 8.28738 2.7905 7.60871 2.58744C7.18106 2.45823 6.7441 2.39362 6.29786 2.39362L6.28856 2.38439Z'
				fill='#333333'
			/>
		</svg>
	);
}
