import styled from 'styled-components';

import { SidebarNavContainer, SidebarNavHeading } from 'styles/layout/three-col';
import colors from 'styles/variables/colors';
import { breakpoints, mediaQueries } from 'styles/variables/media-queries';
import { px, rem, spacing } from 'styles/variables/spacings';

const cm = colors.contentManagement.user;

const SideNavContainer = styled(SidebarNavContainer)`
	position: relative;
	overflow: hidden;
	height: 100%;
`;

const SideNavHeading = styled(SidebarNavHeading)`
	position: relative;
	display: flex;
	align-items: center;
	padding-right: ${rem[2]}rem;
	padding-top: 1.1875rem;
	padding-bottom: 0.8125rem;
	transition: padding-left 200ms ease-in-out;
	h1 {
		font-size: 1.2rem;
		transition: color 200ms ease-in-out;
		max-width: 345px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1.5;
	}
	&.back {
		cursor: pointer;
		padding-left: 3.875rem;
		transition: background-color 200ms ease-in-out;
	}
`;

const BackButton = styled.button`
	position: absolute;
	top: 16px;
	left: 22px;
	background-color: transparent;
	border: 0;
	padding: 0;
	width: 32px;
	height: 32px;
	border-radius: 4px;

	opacity: 0;
	visibility: hidden;
	transition:
		opacity 200ms ease-in-out,
		visibility 200ms ease-in-out;

	display: flex;
	justify-content: center;
	align-items: center;

	${mediaQueries.xl`
		top: 14px;
	`};

	.icon {
		display: flex;
		path {
			transition: fill 200ms ease-in-out;
		}
	}

	&.visible {
		opacity: 1;
		visibility: visible;
		cursor: pointer;
	}

	&:hover {
		background-color: ${cm.navigationSection.header.hover.background};

		.icon path {
			fill: ${cm.navigationSection.header.hover.color};
		}
	}
`;

const ListContainer = styled.div`
	position: relative;
	height: 100%;
`;

const InputWrapper = styled.div`
	padding: 1rem;
`;

const FilterTabs = styled.div`
	padding: ${rem[1]}rem ${rem[2]}rem;
	margin: ${px[0]}px ${px[2]}px;
	max-height: 40px;
	border-radius: 4px;
	cursor: pointer;
	font-size: 0.875rem;
	span {
		display: flex;
		align-items: center;
		font-weight: 400;
		padding-top: 2px;
		&.number {
			color: ${colors.borderGray};
			margin-left: 5px;
		}
	}
	.icon {
		margin-right: 8px;
	}
	&.selected {
		background-color: ${colors.mainBlack};
		color: ${colors.buttonGray};
		.icon path {
			fill: ${colors.buttonGray};
		}
	}
	div {
		display: flex;
		align-items: center;
	}
`;

const Divider = styled.div`
	margin-left: 60px;
	height: 4px;
	@media (max-width: ${breakpoints.sm}) {
		border-bottom: 1px solid ${colors.contentManagement.campaignList.listItem.borderColor};
	}
`;

const FilterTabsWrapper = styled.div`
	margin-bottom: ${spacing[4].px};
`;

const NoResults = styled.div`
	padding: ${spacing[2].rem};
	text-align: center;
	.value {
		font-weight: 500;
	}
`;

const NoResultsText = styled.div`
	padding-top: ${spacing[2].rem};
	max-width: 100%;
	overflow-wrap: break-word;
	.link {
		color: ${colors.CTALinks.color};
		cursor: pointer;
	}
`;

const EmptyText = styled.div`
	text-align: center;
	font-weight: 700;
	font-size: 0.875rem;
	padding: 1rem;
`;

const ListItems = styled.div`
	height: 80vh;
	padding: 1rem;
	overflow-y: scroll;
`;

const Styled = {
	ListItems,
	InputWrapper,
	SideNavContainer,
	SideNavHeading,
	ListContainer,
	BackButton,
	FilterTabs,
	FilterTabsWrapper,
	Divider,
	NoResults,
	NoResultsText,
	EmptyText,
};

export default Styled;
