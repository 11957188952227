import ToolsService from 'services/Tools';

/**
 * useLogger
 * Uses the ToolsService to log things in kibana
 */
export default function useLogger() {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const log = async (message: string, context: any) => {
		return await ToolsService.log(message, context);
	};

	return {
		log,
	};
}
