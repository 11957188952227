import classNames from 'classnames';
import { Model } from 'json-api-models';
import { useEffect, useState } from 'react';

import { DarkButton } from 'components/Button';
import { EditableImage } from 'components/EditableImage';
import { KIND } from 'components/EditableImage/types/types';
import { CROPPER_OPTIONS } from 'components/EditableImage/utils/utils';
import useImageUpload from 'hooks/useImageUpload/useImageUpload';
import usePermissions from 'hooks/usePermissions';
import { ROLE_PROJECT_MANAGER } from 'hooks/usePermissions/types';
import { isSuccessfulResponse } from 'services/Response.types';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';

import Styled from './MoodBoardImages.style';
import UploadMedia from './UploadMedia';

type MoodBoardImagesProps = {
	// We expect campaign ID not shortId
	campaignId: string;
	moodBoardImages?: Model[];
	onPreview?: boolean;
	getBriefData?: () => void;
	isLoadingBrief?: boolean;
	canEdit: boolean;
};
/**
 * UploadMedia
 * Component to let users drag and drop files into a input
 * react-dropzone are used to make this possible.
 * @todo better typing
 * @param {UploadMedia}
 * @returns {JSX.Element}
 */
const MoodBoardImages = ({ campaignId, moodBoardImages, onPreview, getBriefData, isLoadingBrief, canEdit }: MoodBoardImagesProps): JSX.Element => {
	const [moodBoardFiles, setMoodBoardFiles] = useState<File[] | null>(null);
	const [isUploadingFiles, setIsUploadingFiles] = useState(false);
	const [addMorePhotos, setAddMorePhotos] = useState(false);
	const [isDeletingImage, setIsDeletingImage] = useState<string | undefined>(undefined);

	const { uploadAndPollImage } = useImageUpload(KIND.CAMPAIGN_IMAGE, moodBoardFiles?.length);

	const { isGranted } = usePermissions();
	const canEditImage = isGranted(ROLE_PROJECT_MANAGER);
	const EmptyStateOnPreview = onPreview && moodBoardImages?.length === 0 && moodBoardFiles?.length === 0 && canEdit;

	const deleteMoodboardImage = async (url: string, withToast?: boolean) => {
		const client = createClient();

		try {
			withToast && setIsDeletingImage(url);
			const res = await client.delete(url);
			if (isSuccessfulResponse(res.status)) {
				setIsDeletingImage(undefined);
				getBriefData && getBriefData();
				withToast && toast.success('Image deleted');
			}
			return res;
		} catch (error) {
			setIsDeletingImage(undefined);
			console.error('Error deleting image, ', error);
			withToast && toast.error('Failed to delete image');
		}
	};

	const saveFile = async (file: File) => {
		const responseUrl = await uploadAndPollImage(file!, null, campaignId!);
		return responseUrl;
	};

	useEffect(() => {
		if (moodBoardFiles && moodBoardFiles?.length > 0) {
			setIsUploadingFiles(true);
			setAddMorePhotos(false);
			Promise.all(
				moodBoardFiles.map((file) => {
					return saveFile(file);
				}),
			).finally(() => {
				getBriefData && getBriefData();
				setIsUploadingFiles(false);
			});
		}
	}, [moodBoardFiles]);

	useEffect(() => {
		if (!isUploadingFiles && !isLoadingBrief) {
			setMoodBoardFiles([]);
		}
	}, [isLoadingBrief]);

	return (
		<>
			<Styled.Wrapper data-testid='mood-board'>
				<Styled.MoodBoardTitle>Mood board</Styled.MoodBoardTitle>
				<p>Below you&apos;ll find inspirational assets for the campaign. Remember to use them for inspiration only, do not imitate. </p>
				{EmptyStateOnPreview && (
					<>
						<p>Section will not be visible to the influencer if left empty.</p>
						<UploadMedia
							onChange={(files: File[]) => {
								setMoodBoardFiles(files);
							}}
						/>
					</>
				)}
			</Styled.Wrapper>
			<Styled.MoodboardImagesContainer>
				<Styled.SelectedFiles>
					{moodBoardImages &&
						moodBoardImages.length > 0 &&
						moodBoardImages
							.sort((a, b) => a.attributes.sort - b.attributes.sort)
							.map((image: Model) => {
								const isDeleting = isDeletingImage === image.links.delete && canEditImage;
								return (
									<EditableImage
										key={image.id}
										sortOrder={image.attributes.sort}
										isDeletingImage={isDeleting}
										deleteImage={() => deleteMoodboardImage(image.links.delete)}
										deleteImageWithToast={() => deleteMoodboardImage(image.links.delete, true)}
										className={classNames({ fadeOut: isDeleting }, 'moodboardImage')}
										kind={KIND.CAMPAIGN_IMAGE}
										isFeatureEnabled={true}
										moodboardImageUrl={image.links.image}
										grantedForEditing={canEditImage}
										cropperOptions={{ ...CROPPER_OPTIONS, aspectRatio: 1 / 1 }}
									/>
								);
							})}

					{moodBoardFiles?.map((file) => {
						return (
							<EditableImage
								key={file.name}
								total={moodBoardFiles.length}
								isUploading={true}
								className='moodboardImage'
								kind={KIND.CAMPAIGN_IMAGE}
								isFeatureEnabled={true}
								moodboardImageUrl={URL.createObjectURL(file)}
								grantedForEditing={canEditImage}
								cropperOptions={{ ...CROPPER_OPTIONS, aspectRatio: 1 / 1 }}
							/>
						);
					})}
					{addMorePhotos && (
						<Styled.UploadBox data-testid='upload-box'>
							<Styled.Cancel>
								<p onClick={() => setAddMorePhotos(false)}>Cancel</p>
							</Styled.Cancel>
							<UploadMedia
								onEdit
								onChange={(files: File[]) => {
									setMoodBoardFiles(files);
								}}
							/>
						</Styled.UploadBox>
					)}
				</Styled.SelectedFiles>
				{canEdit && !addMorePhotos && moodBoardImages && moodBoardImages?.length > 0 && (
					<Styled.ButtonWrapper>
						<DarkButton onClick={() => setAddMorePhotos(true)}>Add moodboard images</DarkButton>
					</Styled.ButtonWrapper>
				)}
			</Styled.MoodboardImagesContainer>
		</>
	);
};

export default MoodBoardImages;
