import { AxiosInstance, AxiosPromise, Method } from 'axios';

import DIContainer from 'DependencyInjection';

import HttpClientFactory from './HttpClientFactory';
import { RetryConfig } from './types';

/**
 * @deprecated use `HttpClientFactory` instead
 */
export function createClient(baseConfig: RetryConfig = {}): AxiosInstance {
	return DIContainer.get(HttpClientFactory).createLegacyClient(baseConfig);
}

/**
 * @deprecated use `HttpClientFactory` instead
 */
const apiClient = createClient();

/**
 * @deprecated use `HttpClientFactory` instead
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Client = (requestMethod: Method, url: string, data: Record<string, any> = {}, signal: any = null, headers: any = {}): AxiosPromise => {
	return apiClient.request({ method: requestMethod, url: url, data: data, signal: signal, headers: headers });
};
