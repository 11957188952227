import { useParams } from 'react-router-dom';

import EmptyChatImage from 'assets/icons/empty-state-data-library.svg';
import { DarkButton } from 'components/Button';
import Summary from 'components/DataLibrary/Graphs/Categories/Summary';
import LoadingSpinner from 'components/LoadingSpinner';
import { INFLUENCERS, PAID_MEDIA } from 'constants/data-library';
import Grid from 'styles/grid/grid';
import { useAppSelector, useAppDispatch } from 'views/DataLibrary/hooks';
import { setModalIsOpen } from 'views/DataLibrary/reducers/ModalSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import Styled from './ContentBody.style';
import useGetChart from './useGetChart';

/**
 * This component is displayed in DataLibrary and the public dashboard It contains the summary and all of the graphs.
 * @returns {JSX.Element}
 */
const ContentBody = (): JSX.Element => {
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dashboards: DashboardType[] = useAppSelector((state) => state.dashboards);
	const isFetching = useAppSelector((state) => state.fetchingDashboards.isFetching);

	const [getChart] = useGetChart();
	const dispatch = useAppDispatch();
	const { dashboardId } = useParams();

	const tables = dashboard.items?.filter((item) => item.kind === INFLUENCERS.SUMMARY || item.kind === PAID_MEDIA.SUMMARY);
	const graphs = dashboard.items?.filter((item) => item.kind !== INFLUENCERS.SUMMARY && item.kind !== PAID_MEDIA.SUMMARY);

	return (
		<Styled.Container className='inDataLibrary'>
			{isFetching ? (
				<Styled.SpinnerWrapper>
					<LoadingSpinner />
				</Styled.SpinnerWrapper>
			) : (!dashboard.id && dashboards.length > 0) || (!dashboardId && dashboards.length > 0) ? (
				<Styled.EmptyState>
					<div className='empty-state'>
						<div className='image-wrapper'>
							<img src={EmptyChatImage} alt='Please select dashboard' />
						</div>
						<div className='other-text'>Click on select dashboard to list available dashboards</div>
					</div>
				</Styled.EmptyState>
			) : dashboards.length === 0 ? (
				<Styled.EmptyState>
					<div className='empty-state'>
						<div className='image-wrapper'>
							<img src={EmptyChatImage} alt='Please select dashboard' />
						</div>
						<div className='strong'>No dashboard created yet</div>
						<div className='other-text'>Create your first dashboard to get started</div>
					</div>
					<DarkButton onClick={() => dispatch(setModalIsOpen(true))}>Create dashboard</DarkButton>
				</Styled.EmptyState>
			) : (
				<Grid.Container>
					<Grid.Column lg={12}>
						<Summary inDataLibrary />
					</Grid.Column>
					<Grid.Column lg={12}>{dashboard.id && <Styled.Title>Insights</Styled.Title>}</Grid.Column>
					{tables?.map((item: { kind: string; size: string; id: string }) => {
						return (
							<Grid.Column key={item.id} lg={12} data-testid='dl-table-graph-item'>
								{getChart({
									type: item.kind,
									chartId: item.id,
								})}
							</Grid.Column>
						);
					})}
					<Grid.Column xs={12} lg={6}>
						<Grid.Container>
							{graphs.map((item: { kind: string; size: string; id: string }, index: number) => {
								if (!(index % 2)) {
									return (
										<Grid.Column key={item.id} xxl={12}>
											{getChart({
												type: item.kind,
												chartId: item.id,
											})}
										</Grid.Column>
									);
								}
							})}
						</Grid.Container>
					</Grid.Column>
					<Grid.Column xs={12} lg={6}>
						<Grid.Container>
							{graphs.map((item: { kind: string; size: string; id: string }, index: number) => {
								if (index % 2) {
									return (
										<Grid.Column key={item.id} xxl={12}>
											{getChart({
												type: item.kind,
												chartId: item.id,
											})}
										</Grid.Column>
									);
								}
							})}
						</Grid.Container>
					</Grid.Column>
				</Grid.Container>
			)}
		</Styled.Container>
	);
};
export default ContentBody;
