import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const gutters = guttersWithRem;

const Wrapper = styled.header`
	background-color: ${({ theme }) => theme.background};
	transition: background-color 0.2s ease-in-out;
	padding: 1rem;
	@media (min-width: ${breakpoints.md}) {
		padding: 2rem 1rem;
	}

	&.bold-home-text {
		font-weight: 700;
	}
`;

const HeaderBottom = styled.div`
	@media (min-width: ${breakpoints.md}) {
		display: flex;
	}
`;

const PageHeadingWrapper = styled.div`
	padding-right: ${gutters.m};
`;

const PageHeading = styled.h1`
	margin-bottom: 16px;
	font-size: 1.0625rem;
	font-weight: 600;
	@media (min-width: ${breakpoints.md}) {
		font-weight: 700;
		font-size: 1.5rem;
		margin-bottom: 0;
	}
`;

const Breadcrumb = styled.ul`
	font-family: ${typography.BaseFontFamiliy};
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0;
	list-style: none;
	margin-left: -0.25rem;
	margin-right: -0.25rem;
	margin-bottom: ${gutters.xs};
`;

const BreadcrumbItem = styled.li`
	display: inline-block;
	padding: 0 0.25rem;

	&:first-child {
		padding-left: 0;
	}

	a {
		display: flex;
		align-items: center;
		font-size: 1.125rem;
		color: ${colors.header.color};
	}
`;

const ComponentContainer = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: center;
	flex-direction: column;

	@media (min-width: ${breakpoints.md}) {
		justify-content: center;
	}
`;

const DateWrapper = styled.div`
	margin-bottom: ${guttersWithRem.s};
`;

const DateText = styled.span``;

const Styled = {
	Wrapper,
	HeaderBottom,
	PageHeadingWrapper,
	PageHeading,
	ComponentContainer,
	Breadcrumb,
	BreadcrumbItem,
	DateWrapper,
	DateText,
};

export default Styled;
