import styled from 'styled-components';

import fontSize from 'styles/variables/font-size';

import { IHeaderStyle } from './types';

const Wrapper = styled.div<IHeaderStyle>`
	width: 100%;
	height: auto;
	margin: ${(props) => props.margin || 0};

	display: ${(props) => props.display};
	align-items: ${(props) => props.alignItems};
	justify-content: ${(props) => props.justifyContent};

	font-size: ${(props) => props.fontSize || fontSize.lg};
	font-weight: bold;
`;

const Styled = {
	Wrapper,
};

export default Styled;
