import styled from 'styled-components';

import { devices } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
	@media only screen and (${devices.md}) {
		justify-content: flex-end;
		gap: 16px;
		flex-direction: row;
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
