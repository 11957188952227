type Commission = {
	fixedAmount: number;
	fixedAmountCurrency: string;
	id: string;
};

export type Invite = {
	id: string;
	coverImage?: string;
	campaignName: string;
	campaignId: string;
	campaignShortId: string;
	brandName: string;
	createdAt: string;
	commission?: Commission;
	links: {
		join: string;
		smallCoverPhoto?: string;
	};
	isAffiliateCampaign: boolean;
};

export type InvitationSectionProps = {
	invites: Invite[];
};

export type InvitationItemProps = {
	invitation: Invite;
	displaySkeletonLoader: boolean;
};

export enum TODO_STATUS {
	LATE = 'Late',
	UPCOMING = 'Upcoming',
	PENDING = 'Pending',
	READY_TO_PUBLISH = 'Approved for posting',
	CHANGE_REQUESTED = 'Changes requested',
	DONE = 'done',
}

export type TodoType = 'upload_content' | 'publish_content' | 'update_statistics' | 'custom_deadline';

export enum TODO_TYPE {
	UPLOAD_CONTENT = 'upload_content',
	PUBLISH_CONTENT = 'publish_content',
	UPDATE_STATISTICS = 'update_statistics',
	CUSTOM_DEADLINE = 'custom_deadline',
}

export enum CAMPAIGN_INSTAGRAM_OWNER_ASSIGNMENT_STATUS {
	REVIEW_ACCEPTED = 'review_accepted',
	POSTED_ON_INSTAGRAM = 'posted_on_instagram',
}
