import { AxiosError } from 'axios';
import DOMPurify from 'dompurify';
import { RefObject, useState } from 'react';

import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import UploadTermsAndConditions from 'components/NewBriefPage/Components/UploadTermsAndConditions/UploadTermsAndConditions';
import { Campaign } from 'components/NewBriefPage/types';
import { CREATE_CAMPAIGN } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { LinksType } from 'reducers/UserReducers/types';
import CampaignsService from 'services/Campaign';
import toast from 'services/Toast';

import DownloadPDFLink from './DownloadPDFLink';
import Styled from './TermsAndConditions.style';

const TermsAndConditions = (props: { onPreview?: boolean; campaign: Campaign; termsRef: RefObject<HTMLDivElement>; canEdit: boolean }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [pdfDeleted, setPdfDeleted] = useState<boolean>(false);

	const user = useAppSelector((state) => state.user);
	const { userCan } = useFeaturePermissions((user.links as LinksType) || undefined);

	const removePdf = () => {
		setIsLoading(true);
		CampaignsService.addTermsAndConditionsPdf(props.campaign.id || '', '')
			.then(() => {
				setPdfDeleted(true);
				toast.success('PDF deleted');
			})
			.catch((error: AxiosError) => {
				console.error(error);
				toast.error(getErrorMessageOnPost('saving the pdf'));
			});
		setIsLoading(false);
	};

	return (
		<Styled.Container ref={props.termsRef}>
			<h5>Terms & Conditions</h5>
			{props.campaign.termsAndConditions && <Styled.DetailText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.campaign.termsAndConditions) }} />}

			{props.onPreview && props.canEdit && (
				<Styled.TacContainer>
					<h6>Upload a detailed PDF with the terms and conditions (optional)</h6>
					<UploadTermsAndConditions />
				</Styled.TacContainer>
			)}
			{!pdfDeleted &&
				props.campaign?.pdfFiles?.map((pdfFile, index: number) => {
					return (
						<Styled.FileWrapper key={index}>
							<DownloadPDFLink key={pdfFile.attributes.uuid} userUpload={pdfFile} />
							{isLoading ? (
								<LoadingSpinner size='sm' />
							) : (
								userCan(CREATE_CAMPAIGN) && (
									<Styled.Delete onClick={() => removePdf()}>
										<Icon name='cross' size='16' />
									</Styled.Delete>
								)
							)}
						</Styled.FileWrapper>
					);
				})}
		</Styled.Container>
	);
};
export default TermsAndConditions;
