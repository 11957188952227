import { Navigate } from 'react-router-dom';

import AdminInvoices from 'components/AdminInvoices';
import { VIEW_ADMIN_PRE_PAYMENTS } from 'constants/hateoas-keys';
import useHaveUserPermissions from 'hooks/usePermissions/usePermissions';

const InvoicesView = () => {
	const { userCan } = useHaveUserPermissions();

	return userCan(VIEW_ADMIN_PRE_PAYMENTS) ? <AdminInvoices /> : <Navigate to='/not-found' />;
};

export default InvoicesView;
