import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { JsonApiDocument } from 'json-api-models';

import { StatusCode } from 'services/Response.types';
import Client from 'shared/ApiClient';
import { createClient } from 'shared/ApiClient/ApiClient';
import { logout } from 'shared/facebook/facebook-sdk';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

import { IFacebookAuthService } from './types';

/**
 * Facebook Authentication Service
 * @deprecated use FacebookManager instead. The FacebookManager is part of our new ApiManager pattern.
 */
class FacebookAuthService implements IFacebookAuthService {
	client: AxiosInstance;

	storage: BrowserStorage;

	ENDPOINT_URL: string;

	constructor() {
		this.storage = new BrowserStorage(StorageType.LOCAL);
		this.ENDPOINT_URL = '/facebook';
		this.client = createClient();
	}

	async deleteFacebookToken() {
		let result = 0;

		// revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
		logout();

		await Client('delete', `${this.ENDPOINT_URL}/tokens`)
			.then(() => {
				result = StatusCode.OK;
			})
			.catch((error: AxiosResponse) => {
				if (axios.isAxiosError(error)) {
					result = StatusCode.BAD_REQUEST;
				}
			});

		return result;
	}

	async deleteFacebookData() {
		let result = 0;
		await Client('delete', `${this.ENDPOINT_URL}/data`)
			.then(() => {
				result = StatusCode.OK;
			})
			.catch((error: AxiosResponse) => {
				if (axios.isAxiosError(error)) {
					result = StatusCode.BAD_REQUEST;
				}
			});

		return result;
	}

	async getBusinessAccounts() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let result: any;
		await Client('get', `${this.ENDPOINT_URL}/accounts`)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then((response: AxiosResponse<any>) => {
				result = response.data.data;
			})
			.catch((error: AxiosResponse) => {
				if (axios.isAxiosError(error)) {
					result = StatusCode.BAD_REQUEST;
				}
			});

		return result;
	}

	async storeFacebookAccessToken(accessToken: string) {
		let result = 0;
		await Client('post', `${this.ENDPOINT_URL}/tokens`, { token: accessToken })
			.then(() => {
				result = StatusCode.OK;
			})
			.catch((error: AxiosResponse) => {
				if (axios.isAxiosError(error)) {
					result = StatusCode.BAD_REQUEST;
				}
			});

		return result;
	}

	async tokens(accessToken: string) {
		const url = `${this.ENDPOINT_URL}/tokens`;
		const response = await this.client.post<JsonApiDocument>(url, { token: accessToken });
		return response;
	}
}

export default new FacebookAuthService();
