/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { InstagramBusinessAccountCollectionResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import InstagramBusinessAccountApiClientInterface from './InstagramBusinessAccountApiClientInterface';

@injectable()
export default class InstagramBusinessAccountApiClient implements InstagramBusinessAccountApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List instagram business accounts
	 */
	async listInstagramBusinessAccounts(params: { include?: string }): Promise<InstagramBusinessAccountCollectionResponse> {
		const response = await this.client.get<InstagramBusinessAccountCollectionResponse>(`/instagram-business-accounts`, { params });
		return response.data;
	}
}
