import { useState } from 'react';

import ChartCard from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard';
import Styled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { ChartCardProps } from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/types';
import BarChart from 'components/DataLibrary/Graphs/GraphComponents/CustomBarChart/BarChart';
import ExplanationAccordition from 'components/DataLibrary/Graphs/GraphComponents/ExplanationAccordion';
import LoadingSpinner from 'components/LoadingSpinner';
import { getErrorMessageOnFetch } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import toast from 'services/Toast';
import { formatNumber } from 'shared/helpers/Chart/chart-util';
import { useAppSelector } from 'views//DataLibrary/hooks';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

import { FakeData } from './FakeData';
import { FakeData2 } from './FakeData2';
import CircleChart from './GraphTypes/CircleChart';
import CombinedBarWithLineChart from './GraphTypes/CombinedBarWithLineChart';
import { ByCampaignOutput } from './types';

/**
 * @returns {JSX.Element}
 */
const ByCampaignContainer = ({
	fetchData,
	title,
	chartId,
	chartType,
	chartOnDashboard,
	displayBar,
	displayCircle,
	chartExplanation,
}: ChartCardProps<ByCampaignOutput>): JSX.Element => {
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const manager = useInjection<StatisticsManager>(StatisticsManager);

	let total = 0;
	let campaignList: { label: string; value: number }[] | { name: string; value: number; valueLabel: string; secondValue: number; secondValueLabel: string }[] =
		[];
	let isLoading = true;

	if (!fetchData) {
		isLoading = false;
		if (displayBar || displayCircle) {
			total = 48;
			campaignList = FakeData2;
		} else {
			campaignList = FakeData;
		}
	} else {
		const { result, error } = fetchData(manager, dashboardFilter);
		if (error) {
			console.error(error.message);
			toast.error(getErrorMessageOnFetch('Campaign data'));
		}
		isLoading = !result;
		if (result) {
			campaignList = result.campaigns;
			total = result.total ?? undefined;
		}
	}

	const [displayRealValue, setDisplayRealValue] = useState<boolean>(false);

	return (
		<ChartCard chartId={chartId} title={title} chartType={chartType} chartOnDashboard={chartOnDashboard} isLoading={isLoading}>
			<Styled.InnerCenteredWrapper>
				<>
					{isLoading ? (
						<LoadingSpinner size='sm' />
					) : (
						<Styled.Total
							onMouseEnter={() => {
								setDisplayRealValue(true);
							}}
							onMouseLeave={() => {
								setDisplayRealValue(false);
							}}
						>
							Total {displayRealValue ? total : formatNumber(total, true)}
						</Styled.Total>
					)}
					{!isLoading ? (
						displayBar ? (
							<BarChart gridTemplateColumns={'4fr 4fr 1fr'} total={total} items={campaignList} />
						) : displayCircle ? (
							<CircleChart total={total} items={campaignList} />
						) : (
							<CombinedBarWithLineChart graphData={campaignList} />
						)
					) : (
						'N/A'
					)}
				</>
			</Styled.InnerCenteredWrapper>
			{chartExplanation && <ExplanationAccordition chartTitle={title} chartExplanation={chartExplanation} />}
		</ChartCard>
	);
};

export default ByCampaignContainer;
