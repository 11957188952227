import styled, { css } from 'styled-components';

import colors from 'styles/variables/colors';
import { rem } from 'styles/variables/spacings';

const borderStyle = css`
	background-image: linear-gradient(to right, ${colors.TableV2.borderDotsColor} 13%, rgba(136, 136, 136, 0) 0%);
	background-position: top;
	background-size: 8px 1px;
	background-repeat: repeat-x;
`;

const Wrapper = styled.div`
	position: relative;
	overflow-x: auto;
	width: 100%;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;

	thead {
		th {
			padding: ${rem[2]}rem ${rem[3]}rem;
			text-align: left;
			white-space: nowrap;
			color: ${colors.TableV2.thead.color};
			font-weight: 600;
			font-size: 0.875rem;
		}
	}

	tbody {
		tr {
			transition:
				background-color ease-in-out 0.2s,
				color ease-in-out 0.2s,
				background-image ease-in-out 0.1s;
			background-color: ${colors.TableV2.tbody.background};
			${borderStyle};

			&:nth-child(even) {
				background-color: ${colors.TableV2.tbody.evenBackground};
			}

			.icon path {
				transition: fill ease-in-out 0.2s;
			}

			&:hover {
				background-color: ${colors.TableV2.tbody.hoverBackground};
				background-image: linear-gradient(to right, ${colors.TableV2.tbody.hoverBackground} 13%, rgba(136, 136, 136, 0) 0%);
				color: ${colors.TableV2.tbody.hoverColor};

				.icon path {
					fill: ${colors.TableV2.tbody.hoverColor};
				}

				&:hover + tr {
					background-image: none;
				}
			}
		}

		td {
			padding: ${rem[3]}rem;
		}
	}
`;

const DownloadButton = styled.button`
	display: flex;
	align-items: center;
	cursor: pointer;
	background: transparent;
	border: 0;
`;

const Styled = {
	Wrapper,
	Table,
	DownloadButton,
};

export default Styled;
