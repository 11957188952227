import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgUser(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.2793 7.31837C12.0472 7.31837 13.3739 5.95825 13.3739 4.40918C13.3739 2.86012 12.0472 1.5 10.2793 1.5C8.51133 1.5 7.18466 2.86012 7.18466 4.40918C7.18466 5.95825 8.51133 7.31837 10.2793 7.31837ZM10.2793 8.81837C12.8168 8.81837 14.8739 6.84431 14.8739 4.40918C14.8739 1.97406 12.8168 0 10.2793 0C7.74173 0 5.68466 1.97406 5.68466 4.40918C5.68466 6.84431 7.74173 8.81837 10.2793 8.81837ZM4.5827 14.5861C3.61579 15.7766 3.36177 17.2436 3.43185 18.2948L3.43343 18.3184V19.2895C3.43343 19.6819 3.11531 20 2.7229 20C2.33048 20 2.01237 19.6819 2.01237 19.2895V18.365C1.92995 17.0495 2.24197 15.214 3.47963 13.6902C4.65168 12.2472 6.58153 11.1719 9.5476 11.0188C9.59992 11.0065 9.65447 11 9.71053 11C9.80804 11 9.90453 11.0009 10 11.0028C10.0955 11.0009 10.192 11 10.2895 11C10.3455 11 10.4001 11.0065 10.4524 11.0188C13.4185 11.1719 15.3483 12.2472 16.5204 13.6902C17.758 15.214 18.07 17.0495 17.9876 18.365V19.2895C17.9876 19.6819 17.6695 20 17.2771 20C16.8847 20 16.5666 19.6819 16.5666 19.2895V18.3184L16.5681 18.2948C16.6382 17.2436 16.3842 15.7766 15.4173 14.5861C14.5024 13.4597 12.8714 12.4854 10 12.4241C7.12856 12.4854 5.49756 13.4597 4.5827 14.5861Z'
				fill='#333333'
			/>
		</svg>
	);
}
