/**
 * Keys from Hateoas links
 */

export const EDIT_USER = 'edit';

export const EDIT = 'edit';
export const DELETE = 'delete';

export const CREATE_USER = 'createUser';

export const VIEW_PUBLISHERS = 'publishers';

export const CREATE_PUBLISHER_INVITE = 'createPublisherInvite';

export const VIEW_INBOX = 'inbox';

export const ADD_INFLUENCERS = 'addInfluencers';

export const VIEW_COMMISSIONS = 'commissions';

export const CREATE_ASSIGNMENTS = 'createAssignment';

export const CREATE_GROUP = 'createGroup';

export const VIEW_GROUPS = 'groups';

export const CREATE_CLIENT = 'createClient';
export const CREATE_BRAND = 'createBrand';
export const DELETE_LOGO = 'deleteLogo';

export const CREATE_INVITATION = 'createInvitation';
export const SET_AS_ARCHIVED = 'setAsArchived';

export const SHARE_LIST_FOLDER = 'addUsers';
export const DELETE_LIST_FOLDER = 'delete';
export const EDIT_LIST_FOLDER = 'edit';

export const DELETE_INFLUENCER_FROM_LIST = 'delete';
export const HIDE = 'hide';
export const LOVE = 'love';
export const GLOBAL_HIDE = 'global_hide';
export const GLOBAL_LOVE = 'global_love';

export const CREATE_DOWNLOAD_URL = 'createDownloadUrl';
export const DELETE_PENDING_URL = 'delete';

export const UPLOAD_MOOD_BOARD_IMAGES = 'uploadImage';
export const GET_MOOD_BOARD_IMAGES = 'uploadImage';

export const UPLOAD_PUBLISHER_LOGOTYPE = 'uploadLogo';

export const INFLUENCER_JOIN_CAMPAIGN = 'join';

export const CLAIM = 'claim';

export const CREATE_COMMENT = 'createComment';

export const CREATE_INTERNAL_COMMENT = 'createInternalComment';

export const APPROVE_REVIEW = 'approve';

export const APPROVE = 'approve';

export const REJECT_REVIEW = 'reject';

export const REQUEST_CHANGE = 'requestChange';

export const DISCOVERY_INFLUENCER_RESULT_EXPLAIN = 'explain';

export const DISAPPROVE = 'disapprove';

export const CREATE_INSTAGRAM_POST = 'createInstagramPost';

export const CREATE_INSTAGRAM_STORY = 'createInstagramStory';

export const CREATE_INSTAGRAM_REEL = 'createInstagramReel';
export const CREATE_TIKTOK = 'createTiktok';

export const INVITE_INFLUENCERS = 'inviteInfluencers';

export const ENABLE_FOR_CLIENT = 'enableForClient';

export const CLIENT_APPROVE = 'clientApprove';

export const EDIT_URL = 'editUrl';

export const CREATE_ASSIGNMENT_REVIEW = 'createAssignmentReview';
export const REQUEST_REVIEW = 'requestReview';

export const DISCONNECT = 'disconnect';

// Campaign dashboard
export const CAMPAIGN_INSTAGRAM_OWNERS = 'campaignInstagramOwners';
export const CAMPAIGN_CONTENTS = 'contents';
export const CAMPAIGN_PRODUCTS = 'products';

// AM Campaign
export const ADD_TRACKING_LINK = 'addTrackingLink';
export const LIST_TRACKING_LINK = 'listTrackingLink';
export const GET_AM_CAMPAIGN = 'getAffiliateCampaign';
export const UPLOAD_COVER_IMAGE = 'uploadCoverImage';
export const EDIT_AM_CAMPAIGN = 'edit';
export const DELETE_AM_CAMPAIGN = 'delete';
export const GET_INFLUENCER_ONBOARDING_DATA = 'affiliatePreferences';

// Admin
export const VIEW_ADMIN_PRE_PAYMENTS = 'prePayments';
export const VIEW_ADMIN_INVOICES = 'invoices';

// Data Library
export const UPLOAD_PAID_MEDIA_CSV_FILE = 'createPaidMediaPresignUrl';

// TikTok oAuth
export const TIKTOK_OAUTH_URL = 'tiktokOauthUrl';
export const HANDLE_TIKTOK_CODE = 'handleTiktokCode';

// Campaign
export const CREATE_CAMPAIGN = 'createCampaign';

// Segments
export const CREATE_SEGMENT = 'createSegment';

// Budget
export const CREATE_BUDGET = 'createBudget';

// Email request
export const CREATE_EMAIL_REQUEST = 'createEmailRequest';
