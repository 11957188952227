import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgCircleArrowLeft(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;
	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10ZM8.74227e-07 10C1.35705e-06 4.47715 4.47715 -1.35705e-06 10 -8.74228e-07C15.5228 -3.91405e-07 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 3.91405e-07 15.5228 8.74227e-07 10ZM8.99808 15.062L4.38824 10.4522C4.08596 10.1499 4.0859 9.64999 4.38812 9.34778L9.00271 4.73318C9.241 4.49489 9.63625 4.49494 9.87459 4.73328C10.1129 4.97162 10.113 5.36687 9.87469 5.60515L6.1958 9.28405L15.6934 9.28513C15.8678 9.28515 16.0189 9.35492 16.1294 9.46537C16.2398 9.57582 16.3096 9.72695 16.3096 9.90133C16.3097 10.2385 16.0307 10.5174 15.6936 10.5174L6.19594 10.5163L9.86986 14.1902C10.1082 14.4286 10.1082 14.8238 9.86995 15.0621C9.63167 15.3004 9.23642 15.3003 8.99808 15.062Z'
				fill='#333333'
			/>
		</svg>
	);
}
