import styled from 'styled-components';

import { Button } from 'components/Button';
import Grid from 'styles/grid/grid';
import { Flex } from 'styles/utils/UtilityClasses';
import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { guttersWithRem as gutters } from 'styles/variables/gutter';
import { mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const Wrapper = styled.section`
	${Flex};
	padding: 2rem;
	${mediaQueries.medium`
		padding: 5rem 2rem;
	`};

	label {
		font-family: ${typography.BaseFontFamiliy};
		font-size: ${typography.label.small.fontSize};
		line-height: ${typography.label.small.lineHeight};
	}

	button[type='submit'],
	button.d-block {
		display: block;
		font-size: 1rem;
		width: 100%;
		margin-top: 16px;
	}

	.gutter-l > div {
		margin-right: ${gutters.l};

		&:last-child {
			margin-right: 0;
		}
	}

	.my-l {
		margin-top: ${gutters.l};
		margin-bottom: ${gutters.l};
	}

	.validation-error {
		color: ${colors.error};
	}
	input[type='checkbox'] {
		display: inline;
		appearance: checkbox;
		width: auto;
	}

	.description-text {
		color: ${colors.gray7};
		margin-bottom: 40px;
	}

	.label-chk {
		font-family: ${typography.BaseFontFamiliy};
		font-weight: 500;

		input {
			height: 16px;
			width: 16px;
			margin: 8px 8px 8px 0;
			vertical-align: bottom;
			position: relative;
			top: -1px;
			overflow: hidden;
		}
	}

	.info-ul {
		margin: 1rem 0;
		padding-left: 1.5rem;
	}

	legend {
		font-size: 1rem;
		font-weight: 700;
	}

	hr {
		margin-top: 24px;
		margin-bottom: 24px;
		border-top: 1px solid ${colors.borderGray};
	}
`;

const Card = styled.div`
	@keyframes slide-in {
		100% {
			transform: translateY(0);
		}
	}

	background: ${colors.signup.card.background};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	padding: 1.5rem;
	${mediaQueries.medium`
		position: fixed;
		width: 50%;
		height: 100vh;
		padding-top: 10rem;
	`};
	img {
		width: 224px;
		height: 138px;
		opacity: 0;
		transition: opacity 400ms fade-in fade-out;
		transition: transform 200ms fade-in fade-out;
		transform: translateY(200px);
		-webkit-transform: translateY(200%);
		&.display {
			opacity: 1;
			animation: slide-in 0.5s forwards;
		}
	}
	ul {
		list-style: none;
		li {
			display: flex;
			padding: 0.5rem;
			.icon {
				margin-top: 5px;
			}
			h5 {
				font-family: ${typography.BaseFontFamiliy};
				font-size: 1.438rem;
				font-weight: 700;
				line-height: 32px;
				letter-spacing: 0em;
				margin-left: 10px;
			}
		}
	}
	a {
		font-family: ${typography.BaseFontFamiliy};
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: right;
		color: ${colors.info};
		display: flex;
		align-items: center;
		width: fit-content;
		.icon path {
			fill: ${colors.info};
		}
	}
`;

const LeftCardContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	align-items: center;
	${mediaQueries.medium`
		padding-top: 2rem;
	`};
	p {
		margin-top: 0;
	}
	img {
		height: 78px;
		${mediaQueries.medium`
			margin-top: 0;
			width: 224px;
			height: 138px;
	`};
	}
`;

const Title = styled.h5`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1.438rem;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: 0em;
	width: 100%;
	justify-content: space-between;
	display: flex;
	margin-top: 80px;
	h5 {
		margin-bottom: 40px;
	}
	a {
		font-family: ${typography.BaseFontFamiliy};
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: right;
		color: ${colors.info};
		text-decoration: underline;
	}
`;

const CompletedTextDiv = styled.div`
	margin-top: 40px;
	h5 {
		font-family: ${typography.BaseFontFamiliy};
		font-size: 1.438rem;
		font-weight: 700;
		line-height: 32px;
		letter-spacing: 0em;
	}
`;

const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 40px;
	span {
		margin-left: 10px;
		margin-top: 5px;
	}
	a {
		text-decoration: none;
		border-bottom: none !important;
		color: ${colors.info} !important;
	}
`;

const CustomGridContainer = styled(Grid.Container)`
	height: 100vh;
	position: fixed;
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	${scrollbarY};
	${mediaQueries.medium`
		flex-direction: row;
	`};
`;

const Email = styled.span`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1rem;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: ${colors.login.link};
`;

const SomethingWentWrong = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1rem;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
`;

const Action = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1rem;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: ${colors.login.link};
	cursor: pointer;
	&:hover {
		border-bottom: 1px solid ${colors.login.link};
	}
`;

const ActionContainer = styled.div`
	display: flex;
	align-items: baseline;
	span {
		margin: 0 5px;
	}
`;

const GrayButton = styled(Button)`
	width: 100%;
	padding: 1rem;
	background: ${colors.buttonGray};
	border: none;
	font-size: 1rem;
	font-family: ${typography.BaseFontFamiliy};
	margin-top: 24px;
`;

const WrapperContent = styled.div`
	margin-top: 80px;
`;

const VerifyEmailContainer = styled.div`
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const IconWrapper = styled.div`
	cursor: pointer;
`;

export default {
	Wrapper,
	Card,
	LeftCardContent,
	Title,
	CompletedTextDiv,
	CheckboxContainer,
	CustomGridContainer,
	Email,
	SomethingWentWrong,
	Action,
	ActionContainer,
	GrayButton,
	WrapperContent,
	VerifyEmailContainer,
	IconWrapper,
};
