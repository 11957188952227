import { isNil } from 'lodash';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ListCampaignsQuery } from 'api-queries';
import CampaignItem from 'components/ContentManagement/Components/CampaignList/CampaignItem';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchInput from 'components/SearchInput';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import CampaignManager from 'services/ApiManager/Campaign.manager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import Styled from './NavigationSection.style';

const CampaignList = () => {
	const user = useAppSelector((state) => state.user);
	const userIsInfluencer = user.permissions.isInfluencer;
	const selectedProfileId = user.influencer.id;

	const manager = useInjection<CampaignManager>(CampaignManager);
	const [search, setSearch] = useState('');
	const [cursor, setCursor] = useState(-1);
	let queryBuilder = RequestQueryBuilder.create<ListCampaignsQuery>(['smallCoverPhoto']);

	const navigate = useNavigate();
	const location = useLocation();

	if (search.length >= 3) {
		queryBuilder = queryBuilder.withFilter('name', search);
	}

	if (selectedProfileId) {
		queryBuilder = queryBuilder.withFilter('influencer', selectedProfileId);
	}

	if (userIsInfluencer) {
		queryBuilder = queryBuilder.withFilter('joined', 'true');
	}

	const { result, isLoading, error } = manager.listCampaigns(queryBuilder, { keepPreviousData: true });
	const campaigns = result ?? [];

	const sortedCampaigns = campaigns.sort((a, b) => {
		const dateA = a.attributes.archivedAt;
		const dateB = b.attributes.archivedAt;

		const timestampA = dateA ? new Date(dateA).getTime() : 0;
		const timestampB = dateB ? new Date(dateB).getTime() : 0;

		return timestampA - timestampB;
	});

	return (
		<>
			<Styled.InputWrapper>
				<SearchInput
					value={search}
					onChange={({ target }) => setSearch(target.value)}
					placeholder='Search for campaigns'
					onReset={() => {
						setSearch('');
						setCursor(-1);
					}}
					onKeyDown={(e) => {
						if (campaigns.length === 0) {
							return;
						}
						const selectedCampaign = campaigns[cursor];

						switch (e.key) {
							case 'ArrowDown':
								e.preventDefault();
								setCursor((prev) => {
									return prev < campaigns.length - 1 ? prev + 1 : prev;
								});
								break;
							case 'ArrowUp':
								e.preventDefault();
								setCursor((prev) => {
									return prev > 0 ? prev - 1 : 0;
								});
								break;
							case 'Escape':
								setCursor(-1);
								setSearch('');
								break;
							case 'Enter':
								if (selectedCampaign) {
									navigate(`${location.pathname}/${selectedCampaign.attributes.shortId}`);
								}
								break;
						}
					}}
				/>
			</Styled.InputWrapper>
			<Styled.ListItems>
				{isLoading && <LoadingSpinner position='center' />}
				{sortedCampaigns.map(({ id, attributes, links }, i) => (
					<Link to={attributes.shortId} key={id}>
						<CampaignItem
							i={i}
							archivedAt={attributes.archivedAt}
							id={id}
							name={attributes.name}
							coverImage={links?.smallCoverPhoto}
							onClick={() => {}}
							isSelected={false}
							cursor={cursor}
						/>
					</Link>
				))}
				{isNil(error) && !isLoading && campaigns.length === 0 && (
					<>
						{search.length > 0 ? (
							<Styled.NoResults>
								<Styled.NoResultsText>
									Sorry, no match for <span className='value'>&apos;{search}&apos;</span>
								</Styled.NoResultsText>
							</Styled.NoResults>
						) : (
							<Styled.EmptyText>You have no campaigns yet.</Styled.EmptyText>
						)}
					</>
				)}
			</Styled.ListItems>
		</>
	);
};

export default CampaignList;
