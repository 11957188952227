/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { CreateAffiliateProgramPayload, EditAffiliateProgramPayload, UploadProgramCoverImagePayload } from 'api-payloads';
import {
	ListAffiliateCategoriesQuery,
	ListAffiliateProductsQuery,
	ListAffiliateProgramsQuery,
	CreateAffiliateProgramQuery,
	ViewAffiliateProgramQuery,
	EditAffiliateProgramQuery,
	CreateTrackingLinkQuery,
	ListTrackingLinkQuery,
	ListAffiliateBrandsQuery,
	GetEarnedByCampaignQuery,
	GetTotalEarnedQuery,
} from 'api-queries';
import {
	AffiliateCategoryCollectionResponse,
	AffiliateProductCollectionResponse,
	AffiliateProgramCollectionResponse,
	AffiliateProgramResponse,
	UserUploadResponse,
	AffiliateTrackingLinkResponse,
	AffiliateTrackingLinkCollectionResponse,
	AffiliateBrandCollectionResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import AffiliateApiClientInterface from './AffiliateApiClientInterface';

@injectable()
export default class AffiliateApiClient implements AffiliateApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List affiliate categories
	 */
	async listCategories(params: ListAffiliateCategoriesQuery): Promise<AffiliateCategoryCollectionResponse> {
		const response = await this.client.get<AffiliateCategoryCollectionResponse>(`/affiliate-categories`, { params });
		return response.data;
	}

	/**
	 * List affiliate products.
	 *
	 * Get a list of all available affiliate products.
	 */
	async listProducts(params: ListAffiliateProductsQuery): Promise<AffiliateProductCollectionResponse> {
		const response = await this.client.get<AffiliateProductCollectionResponse>(`/affiliate-products`, { params });
		return response.data;
	}

	/**
	 * List Affiliate programs.
	 *
	 * Show every program we have
	 */
	async listPrograms(params: ListAffiliateProgramsQuery): Promise<AffiliateProgramCollectionResponse> {
		const response = await this.client.get<AffiliateProgramCollectionResponse>(`/affiliate-programs`, { params });
		return response.data;
	}

	/**
	 * Create Affiliate Program.
	 *
	 * Affiliate program is basically a job advert for Influencers.
	 */
	async createProgram(payload: CreateAffiliateProgramPayload, params: CreateAffiliateProgramQuery): Promise<AffiliateProgramResponse> {
		const response = await this.client.post<AffiliateProgramResponse>(`/affiliate-programs`, payload, { params });
		return response.data;
	}

	/**
	 * View affiliate program
	 */
	async viewProgram(id: string, params: ViewAffiliateProgramQuery): Promise<AffiliateProgramResponse> {
		const response = await this.client.get<AffiliateProgramResponse>(`/affiliate-programs/${id}`, { params });
		return response.data;
	}

	/**
	 * Delete affiliate program
	 */
	async deleteProgram(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/affiliate-programs/${id}`);
		return response.data;
	}

	/**
	 * Edit affiliate program.
	 *
	 * Did you change your mind?
	 */
	async editProgram(id: string, payload: EditAffiliateProgramPayload, params: EditAffiliateProgramQuery): Promise<AffiliateProgramResponse> {
		const response = await this.client.patch<AffiliateProgramResponse>(`/affiliate-programs/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * Upload program cover image
	 */
	async uploadProgramCoverImage(id: string, payload: UploadProgramCoverImagePayload, params: { include?: string }): Promise<UserUploadResponse> {
		const payloadForm = new FormData();
		payloadForm.set('file', payload);

		const response = await this.client.post<UserUploadResponse>(`/affiliate-programs/${id}/cover-image`, payloadForm, {
			...{ params },
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return response.data;
	}

	/**
	 * Create tracking link
	 */
	async createTrackingLink(id: string, params: CreateTrackingLinkQuery): Promise<AffiliateTrackingLinkResponse> {
		const response = await this.client.post<AffiliateTrackingLinkResponse>(`/affiliate-programs/${id}/tracking-link`, undefined, { params });
		return response.data;
	}

	/**
	 * List tracking link
	 */
	async listTrackingLink(id: string, influencerId: string, params: ListTrackingLinkQuery): Promise<AffiliateTrackingLinkCollectionResponse> {
		const response = await this.client.get<AffiliateTrackingLinkCollectionResponse>(`/affiliate-programs/${id}/tracking-link/${influencerId}`, { params });
		return response.data;
	}

	/**
	 * List affiliate brands.
	 *
	 * Get a list of all affiliate brands. Filter with categories if you want.
	 */
	async listBrands(params: ListAffiliateBrandsQuery): Promise<AffiliateBrandCollectionResponse> {
		const response = await this.client.get<AffiliateBrandCollectionResponse>(`/affiliate/brands`, { params });
		return response.data;
	}

	/**
	 * @deprecated
	 */
	async getEarnedByCampaign(params: GetEarnedByCampaignQuery): Promise<void> {
		const response = await this.client.get<void>(`/affiliate/per-campaign`, { params });
		return response.data;
	}

	/**
	 * @deprecated
	 */
	async getTotalEarned(params: GetTotalEarnedQuery): Promise<void> {
		const response = await this.client.get<void>(`/affiliate/total`, { params });
		return response.data;
	}
}
