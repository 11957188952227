/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { TestInstagramPostInsightAccessPayload, SaveTokenPayload } from 'api-payloads';
import { FacebookAccountCollectionResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import FacebookApiClientInterface from './FacebookApiClientInterface';

@injectable()
export default class FacebookApiClient implements FacebookApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Get accounts
	 */
	async getAccounts(params: { include?: string }): Promise<FacebookAccountCollectionResponse> {
		const response = await this.client.get<FacebookAccountCollectionResponse>(`/facebook/accounts`, { params });
		return response.data;
	}

	/**
	 * Delete data
	 */
	async deleteData(): Promise<void> {
		const response = await this.client.delete<void>(`/facebook/data`);
		return response.data;
	}

	/**
	 * Test instagram post insight access
	 */
	async testInstagramPostInsightAccess(id: string, payload: TestInstagramPostInsightAccessPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/facebook/test-instagram-post-insight-access/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * Save token
	 */
	async saveToken(payload: SaveTokenPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/facebook/tokens`, payload, { params });
		return response.data;
	}

	/**
	 * Delete token
	 */
	async deleteToken(): Promise<void> {
		const response = await this.client.delete<void>(`/facebook/tokens`);
		return response.data;
	}
}
