import styled from 'styled-components';

import { devices } from 'styles/variables/media-queries';

const FlexDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 24px;
	@media screen and (${devices.lg}) {
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
	}
`;

const SearchInputWrapper = styled.div`
	float: right;
	width: 100%;
	margin-top: 16px;
	@media screen and (${devices.lg}) {
		margin-top: 0;
		width: 350px;
	}
`;

export const EmptyResultImgWrapper = styled.div`
	max-width: 500px;
	margin: 0 auto;

	p strong {
		font-weight: ${({ theme }) => theme.fontWeights.bold};
	}
`;

const PublisherContainer = styled.div`
	width: 100%;
	gap: 8px;
	display: flex;
	flex-wrap: wrap;
	&.searching {
		min-height: 600px;
		overflow-y: auto;
	}
`;

export default {
	FlexDiv,
	SearchInputWrapper,
	EmptyResultImgWrapper,
	PublisherContainer,
};
