import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgList(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 2.48999C7.3418 2.07578 7.67758 1.73999 8.0918 1.73999H19.0918C19.506 1.73999 19.8418 2.07578 19.8418 2.48999C19.8418 2.9042 19.506 3.23999 19.0918 3.23999H8.0918C7.67758 3.23999 7.3418 2.9042 7.3418 2.48999Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 9.98999C7.3418 9.57578 7.67758 9.23999 8.0918 9.23999H19.0918C19.506 9.23999 19.8418 9.57578 19.8418 9.98999C19.8418 10.4042 19.506 10.74 19.0918 10.74H8.0918C7.67758 10.74 7.3418 10.4042 7.3418 9.98999Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 17.49C7.3418 17.0758 7.67758 16.74 8.0918 16.74H19.0918C19.506 16.74 19.8418 17.0758 19.8418 17.49C19.8418 17.9042 19.506 18.24 19.0918 18.24H8.0918C7.67758 18.24 7.3418 17.9042 7.3418 17.49Z'
				fill='#333333'
			/>
			<mask id='path-4-inside-1_1539_32550' fill='white'>
				<rect width='5' height='5' rx='1' />
			</mask>
			<rect width='5' height='5' rx='1' stroke='#333333' strokeWidth='3' mask='url(#path-4-inside-1_1539_32550)' />
			<mask id='path-5-inside-2_1539_32550' fill='white'>
				<rect y='7.5' width='5' height='5' rx='1' />
			</mask>
			<rect y='7.5' width='5' height='5' rx='1' stroke='#333333' strokeWidth='3' mask='url(#path-5-inside-2_1539_32550)' />
			<mask id='path-6-inside-3_1539_32550' fill='white'>
				<rect y='15' width='5' height='5' rx='1' />
			</mask>
			<rect y='15' width='5' height='5' rx='1' stroke='#333333' strokeWidth='3' mask='url(#path-6-inside-3_1539_32550)' />
		</svg>
	);
}
