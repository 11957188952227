import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgSearch(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_1997_27473)'>
				<path
					d='M19.78 18.72L15.01 13.95C16.25 12.47 17 10.57 17 8.5C17 3.81 13.19 0 8.5 0C3.81 0 0 3.81 0 8.5C0 13.19 3.81 17 8.5 17C10.57 17 12.47 16.25 13.95 15.01L18.72 19.78C18.87 19.93 19.06 20 19.25 20C19.44 20 19.63 19.93 19.78 19.78C20.07 19.49 20.07 19.01 19.78 18.72ZM8.5 15.27C4.77 15.27 1.73 12.23 1.73 8.5C1.73 4.77 4.77 1.73 8.5 1.73C12.23 1.73 15.27 4.77 15.27 8.5C15.27 12.23 12.23 15.27 8.5 15.27Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1997_27473'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
