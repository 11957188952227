import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';

import Checkbox from 'components/Checkbox';
import LoadingSpinner from 'components/LoadingSpinner';
import useOnboardingData from 'components/Onboarding/hooks';
import { BrandType } from 'components/Onboarding/types';

import Styled from './BrandSelect.style';

type Props = {
	selectedBrandIds: string[];
	onClickBrand: (brandId: string) => void;
	onGoBack: () => void;
	onFinish: () => void;
	selectedCategoryIds: string[];
	isNeedRandomBrands: boolean;
};

const BrandSelect = ({ selectedBrandIds, onClickBrand, onGoBack, onFinish, selectedCategoryIds, isNeedRandomBrands }: Props) => {
	const [brands, setBrands] = useState<BrandType[]>([]);
	const { getBrands } = useOnboardingData();
	const { models, isLoading, error } = getBrands(selectedCategoryIds, isNeedRandomBrands);

	useEffect(() => {
		if (!isLoading && !error) {
			setBrands(models.findAll('affiliateBrand'));
		}
	}, [isLoading, error]);

	return (
		<Styled.Wrapper>
			<Styled.OptionsWrapper>
				{isLoading && <LoadingSpinner position='center' />}
				{(!isLoading || brands.length > 0) &&
					brands.map((brand) => {
						return (
							<Styled.Option
								data-testid={`onboarding-brand-${brand.name}`}
								key={brand.id}
								className={classNames({ selected: selectedBrandIds.includes(brand.id) })}
								onClick={() => {
									onClickBrand(brand.id);
								}}
							>
								<Checkbox
									checked={selectedBrandIds.includes(brand.id)}
									onChange={(e) => {
										e.stopPropagation();
										onClickBrand(brand.id);
									}}
								/>
								{brand.name}
							</Styled.Option>
						);
					})}
			</Styled.OptionsWrapper>
			<Styled.Divider />

			<Styled.Button onClick={onFinish} className='finish-button' disabled={isEmpty(selectedBrandIds)}>
				Finish
			</Styled.Button>
			<Styled.Button onClick={onGoBack}>Back</Styled.Button>
		</Styled.Wrapper>
	);
};

export default BrandSelect;
