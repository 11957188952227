import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgDlFileInvoice(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.66 0.04002L18 5.36268V5.67284C18 5.68284 17.9975 5.69034 17.995 5.69784L17.995 5.69785C17.9925 5.70535 17.99 5.71286 17.99 5.72286C18 5.74287 18 5.77289 18 5.77289V19.0495C18 19.5698 17.55 20 17 20H8.08L2.95 19.97C2.43 19.97 2 19.5498 2 19.0395V0.930465C2 0.42021 2.43 0 2.95 0L12.35 0.04002H12.66ZM15.54 5.02251L13 2.49125V5.02251H15.54ZM16.5 18.5193V10.4852V10.4752V6.51326H12.35C11.88 6.51326 11.5 6.13307 11.5 5.66283V1.53077L3.5 1.49075V18.4892L8.36 18.5193H16.5ZM14.6036 10.1111H5.49975C5.21989 10.1111 5 9.86667 5 9.55556C5 9.24444 5.21989 9 5.49975 9H14.6036C14.8834 9 15.1033 9.24444 15.1033 9.55556C15.1033 9.86667 14.8834 10.1111 14.6036 10.1111ZM9.49026 11.6445H5.49975C5.21989 11.6445 5 11.889 5 12.2001C5 12.5112 5.21989 12.7556 5.49975 12.7556H9.50025C9.78011 12.7556 10 12.5112 10 12.2001C10 11.889 9.78011 11.6445 9.50025 11.6445H9.49026ZM11.4998 11.6445H13.5487C13.8286 11.6445 14.0485 11.889 14.0485 12.2001C14.0485 12.5112 13.8286 12.7556 13.5487 12.7556H11.4998C11.2199 12.7556 11 12.5112 11 12.2001C11 11.889 11.2199 11.6445 11.4998 11.6445Z'
				fill='#333333'
			/>
			<path d='M5.5 16C5.77614 16 6 15.7761 6 15.5C6 15.2239 5.77614 15 5.5 15C5.22386 15 5 15.2239 5 15.5C5 15.7761 5.22386 16 5.5 16Z' fill='#333333' />
			<path
				d='M11.5 16C11.7761 16 12 15.7761 12 15.5C12 15.2239 11.7761 15 11.5 15C11.2239 15 11 15.2239 11 15.5C11 15.7761 11.2239 16 11.5 16Z'
				fill='#333333'
			/>
			<path
				d='M13.5 16C13.7761 16 14 15.7761 14 15.5C14 15.2239 13.7761 15 13.5 15C13.2239 15 13 15.2239 13 15.5C13 15.7761 13.2239 16 13.5 16Z'
				fill='#333333'
			/>
			<path d='M7.5 16C7.77614 16 8 15.7761 8 15.5C8 15.2239 7.77614 15 7.5 15C7.22386 15 7 15.2239 7 15.5C7 15.7761 7.22386 16 7.5 16Z' fill='#333333' />
			<path d='M9.5 16C9.77614 16 10 15.7761 10 15.5C10 15.2239 9.77614 15 9.5 15C9.22386 15 9 15.2239 9 15.5C9 15.7761 9.22386 16 9.5 16Z' fill='#333333' />
		</svg>
	);
}
