import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgSend(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.956801 2.04906C0.70023 1.94819 0.407266 2.00567 0.209755 2.19562C0.0122446 2.38557 -0.0525849 2.6722 0.0444429 2.9265L2.74326 10L0.0444429 17.0736C-0.0525849 17.3279 0.0122446 17.6145 0.209755 17.8044C0.407266 17.9944 0.70023 18.0519 0.956801 17.951L19.5614 10.6367C19.8262 10.5326 19.9999 10.2804 19.9999 10C19.9999 9.71964 19.8262 9.4675 19.5614 9.36336L0.956801 2.04906ZM3.97185 9.31428L1.9058 3.89924L17.4237 10L1.9058 16.1008L3.97188 10.6857H7.04991C7.45901 10.6857 7.79066 10.3787 7.79066 9.99999C7.79066 9.62128 7.45901 9.31428 7.04991 9.31428H3.97185Z'
				fill='#333333'
			/>
		</svg>
	);
}
