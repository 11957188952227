import classNames from 'classnames';
import { InputHTMLAttributes, memo, forwardRef } from 'react';

import Input from 'components/Forms/Input';
import Icon from 'components/Icon';

import Styled from './SearchInput.style';

interface ISearchInput extends InputHTMLAttributes<HTMLInputElement> {
	onReset: () => void;
	autoFocus?: boolean;
	className?: string;
}

const SearchInput = forwardRef<HTMLInputElement, ISearchInput>((props, ref) => {
	return (
		<Styled.SearchInputWrapper data-testid='search-input-wrapper' className={classNames({ focus: props.autoFocus }, props.className)}>
			<Input
				{...props}
				ref={ref}
				data-testid='search-input'
				type='text'
				contentBefore={<Icon testId='search-icon' name='search' size='20' />}
				contentAfter={
					props.value ? (
						<Styled.IconContainer onClick={props.onReset}>
							<div data-testid='cancel-icon'>
								<Icon name='cancel-circle' size='20' />
							</div>
						</Styled.IconContainer>
					) : (
						<></>
					)
				}
			/>
		</Styled.SearchInputWrapper>
	);
});

export default memo(SearchInput);
