import { isNil } from 'lodash';
import { Navigate } from 'react-router-dom';

type RedirectRouteProps = {
	to?: string;
};

/**
 * Redirect Component
 * Redirect from a path to another path with or without the organizationSlug.
 * The organizationSlug is deprecated and it will be removed in the near future.
 * @param to
 * @returns {JSX.Element}
 */
const RedirectRoute = ({ to }: RedirectRouteProps): JSX.Element => {
	if (!isNil(to)) {
		return <Navigate to={to} replace />;
	}

	return <Navigate to='/' replace />;
};

export default RedirectRoute;
