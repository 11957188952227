import styled from 'styled-components';

import colors from 'styles/theme/colors';

const Wrapper = styled.div``;

const Content = styled.div`
	padding-bottom: 48px;
`;

const CampaignTotalWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`;

const CampaignTotalLabel = styled.span`
	font-size: 23.04px;
	font-weight: 700;
`;

const CampaignTotalAmount = styled.span`
	font-size: 23.04px;
	font-weight: 700;
	text-align: right;
	margin-left: auto;

	span {
	}

	.currency {
		margin-right: 4px;
		line-height: 1;
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
	}
`;

const ModalInner = styled.div`
	text-align: center;
`;

const ButtonGroup = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	button {
		width: 150px;
		margin-left: auto;
		margin-right: auto;

		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
`;

const ModalContent = styled.div`
	margin-bottom: 32px;
`;

const NoCommissionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const WarningText = styled.p`
	line-height: 1.5;
	margin-bottom: 16px !important;
`;

const CheckboxWrapper = styled.div`
	width: fit-content;
	padding: 1rem 0;
	label {
		line-height: 1.5;
		font-size: 1rem;
		color: ${colors.steel};
	}
`;

const Styled = {
	CheckboxWrapper,
	WarningText,
	NoCommissionWrapper,
	Wrapper,
	Content,
	CampaignTotalWrapper,
	CampaignTotalLabel,
	CampaignTotalAmount,
	ModalInner,
	ButtonGroup,
	ModalContent,
};

export default Styled;
