import { isNil } from 'lodash';
import { useState } from 'react';

import { Number } from 'components/Chart';
import ChartCard from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard';
import Styled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { ChartCardProps } from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/types';
import ExplanationAccordition from 'components/DataLibrary/Graphs/GraphComponents/ExplanationAccordion';
import { TotalOutput } from 'components/DataLibrary/Graphs/GraphComponents/Total/types';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { formatNumber, formatPercent } from 'shared/helpers/Chart/chart-util';
import { formatAmount } from 'utils/formatters';
import { useAppSelector } from 'views//DataLibrary/hooks';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const Total = ({ chartId, fetchData, title, chartType, chartOnDashboard, isAmount, isPercent, chartExplanation }: ChartCardProps<TotalOutput>): JSX.Element => {
	const [displayRealValue, setDisplayRealValue] = useState<boolean>(false);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const manager = useInjection<StatisticsManager>(StatisticsManager);

	let somethingWentWrong = null;
	let value: number | undefined;
	let currency: string | undefined;
	let isLoading = true;

	if (!fetchData) {
		isLoading = false;
		value = 61000;
		if (isAmount) {
			currency = 'SEK';
		}
	} else {
		const { result, error } = fetchData(manager, dashboardFilter);
		if (error) {
			somethingWentWrong = error.message;
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			somethingWentWrong = null;
			value = result.value;
			if (isAmount) {
				currency = result.currency;
			}
		}
	}

	const formatValue = (value: number | undefined): string | number => {
		if (isNil(isPercent) && isNil(isAmount) && displayRealValue && value) {
			return value;
		} else if (isPercent && value) {
			return formatPercent(value);
		} else if (isAmount && value) {
			return formatAmount(parseInt(`${value}`), currency);
		} else if (typeof value === 'number') {
			return formatNumber(value, true);
		} else {
			return 'N/A';
		}
	};

	return (
		<ChartCard
			somethingWentWrong={somethingWentWrong}
			title={title}
			chartType={chartType}
			chartOnDashboard={chartOnDashboard}
			chartId={chartId}
			isLoading={isLoading}
		>
			<Styled.InnerWrapper onMouseEnter={() => (isAmount ? {} : setDisplayRealValue(true))} onMouseLeave={() => (isAmount ? {} : setDisplayRealValue(false))}>
				<Number isAmount={isAmount} value={formatValue(value)} withoutFormat={value ?? 0} displayRealValue={displayRealValue} size='lg' />
			</Styled.InnerWrapper>
			{chartExplanation && <ExplanationAccordition chartTitle={title} chartExplanation={chartExplanation} />}
		</ChartCard>
	);
};

export default Total;
