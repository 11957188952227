import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	& > button {
		padding: ${spacing[0].rem};
		border-radius: 4px;
		background-color: transparent;

		i {
			display: flex;
		}

		&:hover {
			background-color: ${colors.integratedInbox.chatSection.chatInput.sendable.background};

			path {
				fill: ${colors.integratedInbox.chatSection.chatInput.sendable.color};
			}
		}
	}
`;

const ModalInnerWrapper = styled.div``;

const ModalHeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0 0;
	& > div {
		cursor: pointer;
	}
`;

const AccordionInner = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing[0].px};

	max-height: 230px;
	${scrollbarY};
	overflow-x: hidden;
`;

const InfluencerItem = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${spacing[0].px};
	border-radius: 2px;

	& > figure {
		margin-left: 4px;
	}

	& > div {
		&.name {
			font-size: 0.875rem;
			font-weight: 500;
		}
	}
`;

const SeeAll = styled.div`
	cursor: pointer;
	font-size: 0.938rem;
	color: ${colors.integratedInbox.blastModal.color.seeAll};
`;

const MessageInputWrapper = styled.div`
	margin-top: 24px;
`;

const SelectedInfluencers = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	max-height: 200px;
	margin-bottom: 8px;
	margin-top: 4px;
	& > div {
		&.influencers {
			max-height: 200px;
			overflow-y: auto;
			display: flex;
			flex-wrap: wrap;
			column-gap: 4px;
		}
	}
`;

const SelectedInfluencerTag = styled.div`
	padding: 0.5rem;
	background-color: ${colors.integratedInbox.blastModal.search.influencerTag.background};
	border-radius: ${borderRadius.s};
	height: 30px;
	margin: 2px 0;
	display: flex;
	column-gap: 4px;
	align-items: center;

	font-weight: 500;
	font-size: 0.875rem;

	& > figure {
		margin-left: 0;
		margin-right: 4px;
	}

	& > div > i {
		display: flex;
		margin-left: 4px;
		cursor: pointer;
		margin-top: 2px;
	}
`;

const IconWrapper = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	.icon {
		margin-top: 4px;
	}
	svg {
		width: 20px;
		height: 20px;
	}
	&:hover {
		background-color: ${newColors.ash};
	}
`;

const SideDrawerContent = styled.div`
	margin-top: 40px;
	label {
		margin-bottom: 4px;
		color: ${newColors.steel};
		font-size: 0.875rem;
		line-height: 1.5;
	}
`;

const Styled = {
	SideDrawerContent,
	IconWrapper,
	SelectedInfluencerTag,
	SelectedInfluencers,
	Wrapper,
	ModalInnerWrapper,
	ModalHeaderWrapper,
	AccordionInner,
	InfluencerItem,
	SeeAll,
	MessageInputWrapper,
};

export default Styled;
