import { css } from 'styled-components';

import colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

export const CTALink = css`
	font-family: ${typography.BaseFontFamiliy};
	position: relative;
	color: ${colors.CTALinks.color};

	&:after {
		position: absolute;
		left: 0;
		bottom: -2px;
		content: '';
		height: 2px;
		width: 100%;
		background-color: ${colors.CTALinks.color};
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}
	&:visited {
		color: ${colors.CTALinks.color};
	}
	&:hover,
	&.selected {
		color: ${colors.CTALinks.color};

		&:after {
			opacity: 1;
		}
	}
`;
