import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgMail(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M19.0973 2H0.893277C0.404325 2 0 2.45056 0 2.99542V17.0046C0 17.5494 0.404325 18 0.893277 18H19.1067C19.5957 18 20 17.5494 20 17.0046V2.99542C20 2.45056 19.5957 2 19.1067 2H19.0973ZM18.5802 3.57171V3.93844L10.1363 9.43942C10.0517 9.49181 9.93888 9.49181 9.84485 9.43942L1.41044 3.93844V3.57171H18.5802ZM1.41044 16.4283V15.7158L5.54772 10.3405C5.71697 10.1205 5.69817 9.7852 5.5007 9.59659C5.30324 9.40799 5.01175 9.42895 4.8331 9.64899L1.41044 14.0917V5.76162L9.13963 10.8016C9.40291 10.9692 9.69441 11.0635 9.9953 11.0635C10.2962 11.0635 10.5877 10.9797 10.851 10.8016L18.5802 5.7721V14.1126L15.1575 9.66994C14.9882 9.4499 14.6874 9.42895 14.4899 9.61755C14.2924 9.80616 14.2736 10.1415 14.4429 10.3615L18.5802 15.7367V16.4492H1.41044V16.4283Z'
				fill='#333333'
			/>
		</svg>
	);
}
