import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgImpressions(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_8_108019)'>
				<path
					d='M19.07 5.1499H11.33C10.93 5.1499 10.6 5.4799 10.6 5.8799C10.6 6.2799 10.93 6.6099 11.33 6.6099H17.54L11.09 13.0699L8.49999 10.4799C8.20999 10.1899 7.74999 10.1899 7.46999 10.4799L0.209993 17.7499C-0.0800073 18.0399 -0.0800073 18.4999 0.209993 18.7899C0.349993 18.9299 0.539993 18.9999 0.729993 18.9999C0.919993 18.9999 1.09999 18.9299 1.24999 18.7899L7.98999 12.0399L10.58 14.6299C10.87 14.9199 11.33 14.9199 11.61 14.6299L18.53 7.6999V13.8399C18.53 14.2399 18.86 14.5699 19.26 14.5699C19.66 14.5699 19.99 14.2399 19.99 13.8399V6.0899C19.99 5.5799 19.57 5.1599 19.06 5.1599L19.07 5.1499Z'
					fill='#4D4B4C'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.9675 1C7.5775 1 9.5475 3.29309 9.9175 3.75271L9.9125 3.7577C10.0275 3.90258 10.0275 4.09742 9.9125 4.2423C9.5425 4.70691 7.5725 7 4.9625 7C2.3525 7 0.4375 4.69692 0.0825 4.2373C-0.0275 4.09242 -0.0275 3.90258 0.0825 3.7627C0.4425 3.29808 2.3575 1 4.9675 1ZM2.7575 2.29392C1.7675 2.86844 1.0425 3.66278 0.7625 3.9975C1.0425 4.33222 1.7675 5.12656 2.7575 5.70108C2.3375 5.24147 2.0875 4.64196 2.0875 3.9975C2.0875 3.35304 2.3375 2.75853 2.7575 2.29392ZM2.5525 3.9975C2.5525 5.24147 3.6525 6.25062 5.0025 6.25062C6.3525 6.25062 7.4525 5.24147 7.4525 3.9975C7.4525 2.75354 6.3525 1.74438 5.0025 1.74438C3.6525 1.74438 2.5525 2.75354 2.5525 3.9975ZM7.30267 5.64008C8.25748 5.07127 8.95849 4.32108 9.2375 3.9975C8.9625 3.67277 8.2525 2.9184 7.2975 2.34888C7.6825 2.8035 7.9175 3.37302 7.9175 3.9975C7.9175 4.61922 7.68953 5.18646 7.30267 5.64008ZM4.11234 3.99757C4.11234 3.50292 4.51239 3.1032 5.00746 3.1032C5.50252 3.1032 5.90257 3.50292 5.90257 3.99757C5.90257 4.49222 5.50252 4.89194 5.00746 4.89194C4.51239 4.89194 4.11234 4.49222 4.11234 3.99757Z'
					fill='#4D4B4C'
				/>
			</g>
			<defs>
				<clipPath id='clip0_8_108019'>
					<rect width='20' height='18' fill='white' transform='translate(0 1)' />
				</clipPath>
			</defs>
		</svg>
	);
}
