//
// Collabs palette
//
export const PALETTE = {
	// Whites
	transparent: 'transparent',
	white: '#FAFAFA',
	rawWhite: '#fff',
	eggWhite: '#FFFAF1',
	coldMorning: '#E5E5E5',

	// Greys
	antiFlashWhite: '#f2f2f2',
	argent: '#c1c1c1',
	taupeGrey: '#888888',
	davysGrey: '#5c5c5c',
	black: '#333333',
	mercury: '#E2E2E2',
	mercuryGrey: '#E3E3E3',
	gainsboro: '#E6E6E6',
	concrete: '#f2f2f2',
	aquaHaze: '#EDF3F5',
	silver: '#c5c5c5',
	wildSand: '#f5f5f5',
	emperor: '#4D4B4C',
	scorpion: '#5F5F5F',
	concret: '#F2F2F2',
	paleSky: '#6C757D',
	mineShaft: '#333333',
	backdropGrey: 'rgba(0, 0, 0, 0.6499999761581421)',
	orochimaru: '#d9d9d9',

	// Yellows
	earlyDawn: '#fcf8ea',
	butteryWhite: '#fcfce9',
	maize: '#fdc653',
	yellowRed: '#f5bd4',
	tigersEye: '#e3813e',
	bananaMania: '#fbe5b9',
	creamCan: '#F5C767',
	creamCanLight: 'rgba(245, 199, 103, 0.6)',
	coconutCream: '#f7f1da',
	citrineWhite: '#FCF8EA',
	earlyDawnYellow: '#FFF6E6',

	// Reds
	sunsetOrange: '#fd5258',
	amaranthRed: '#da202a',
	sunsetRed: '#ff474e',
	alizarinCrimson: '#da202a',
	hawaiianTan: '#a35018',
	bulgarianRose: '#420B08',
	burntSienna: '#EB5F5E',
	burntSiennaLight: 'rgba(235, 95, 94, 0.6)',
	negroni: '#FFDDBD',

	// Greens
	padua: '#C4EED2',
	middleBlueGreen: '#7de8cd',
	gOGreen: '#00ab60',
	seaGreen: '#26a248',
	feta: '#e9fcee',
	reachGreen: '#f6f6ed',
	spatialSpirit: '#C4EED2',
	donegalGreen: '#115704',
	fruitSalad: '#4ca867',
	fruitSaladLight: 'rgba(76, 168, 103, 0.6)',
	waterLeaf: '#97E5CE',
	waterLeafLight: 'rgba(151, 229, 206, 0.6)',

	// Blues
	azureishWhite: '#e1ecee',
	cultured: '#f1f9f7',
	skyBlue: '#5dd2e6',
	celadonBlue: '#0086a7',
	royalBlue: '#3a71e3',
	blueYonder: '#4766aa',
	blueRibbon: '#0071ee',
	malibu: '#7ab2fd',
	royalVessel: '#f6f1ff',
	swansDown: '#d6eaee',
	powderBlue: '#B4E0E9',
	toryBlue: '#104cb6',
	mystic: '#e3edef',
	malibuLight: '#80caff',
	blizzyBlueberry: '#104CB6',
	botticelli: '#D4E3EA',
	cornflowerBlue: '#edf3f5',
	aquaMarineBlue: '#7cd0e3',
	aquaMarineBlueLight: 'rgba(124, 208, 227, 0.6)',
	sanMarino: '#4D67A5',
	sanMarinoLight: 'rgba(77, 103, 165, 0.6)',
	astral: '#3885A4',
	astralLight: 'rgba(56, 133, 164, 0.6)',
	kulSharifBlue: '#FFF6E6',
	cornFlowerBlue: '#6195ED',
	mysticLight: '#E1ECEE',
	cornflowerBlueLight: '#7AB2FD',
	botticelliBlue: '#d2e6ea',
	lightPlacidBlue: '#E1EBEE',
	livid: '#6288CA',

	// Purples
	darkLavender: '#744c7e',
	frenchLilac: '#DBCEF0',
	selago: '#f8f5fd',
	magnolia: '#FCFAFF',
	magnoligaLight: '#FAF7FF',
	snuff: '#e2daf0',
	smokey: '#6E4F7B',
	smokeyLight: 'rgba(110, 79, 123, 0.6)',

	// Pinks
	merino: '#fbedeb',
	softPeach: '#feeae6',
	isabelline: '#f6eced',
	mistyRose: '#fee0e1',
	channel: '#F2C3C2',
	cavernPink: '#e6c2c1',

	// Peaches
	seashellPeach: '#fff8f3',
	negroniPeach: '#ffe2c7',
	bleachWhite: '#feebd9',
	antiqueWhite: '#f7ebdf',
	alabaster: '#f6efe4',
	dedication: '#ffe3c9',
	fakeJade: '#FDD2AA',
	wildMaple: '#FFE2C7',
	doeskin: '#FEF1E4',
	paleEgg: '#FFE7D0',
	beePollen: '#FEF1E5',
	rawSienna: '#D6854C',
	rawSiennaLight: 'rgba(214, 133, 76, 0.6)',
	serande: '#fff1e3',
};

const originalColors = {
	MineShaft: PALETTE.black,
	Scorpion: PALETTE.davysGrey,
	Concrete: PALETTE.antiFlashWhite,
	Gray: PALETTE.taupeGrey,
	GoldenTainoi: PALETTE.maize,
	TurquoiseBlue: PALETTE.skyBlue,
	Affair: PALETTE.darkLavender,
	DeepCerulean: PALETTE.celadonBlue,
	Jade: PALETTE.gOGreen,
	SanMarino: PALETTE.blueYonder,
	CaribbeanGreen: PALETTE.middleBlueGreen,
	DoveGray: '#707070',
	Mercury: '#E2E2E2',
	BlueRibbon: '#0071EE',
	Amethyst: '#AD50CF',
	GraySuit: '#CCC4CF',
	SwansDown: '#DDF2ED',
	Botticelli: '#D6E7E9',
	LinkWater: '#e8f2fa',
	ChileanHeath: '#ffffe5',
	PRICKLY_PURPLE: '#9d5fb4',
	GLACIAL_WATER_GREEN: '#ccead4',
	INKED_SILK: '#dadce3',
};

export default originalColors;
