import { Link as link } from 'react-router-dom';
import styled from 'styled-components';

import Dropdown from 'components/Dropdown';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';

const cm = colors.contentManagement;

const Wrapper = styled.div`
	background-color: ${cm.assignmentList.background};
	padding: 1.125rem;
	border-radius: ${borderRadius.m};
	margin-bottom: 34px;
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const AssignmentName = styled.div``;

const Name = styled.span`
	display: block;
	font-weight: 600;
	transition: font-weight 150ms ease-in-out;
`;

const Item = styled.div`
	display: flex;
	position: relative;
	padding: 1rem;
`;

const Icon = styled.div`
	margin-right: 18px;
	margin-top: auto;
	margin-bottom: auto;
`;

const StatusBadge = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 8px;
	margin-left: auto;
	background-color: ${cm.assignmentList.statusBadge.pending.background};
	border: 1px solid ${cm.assignmentList.statusBadge.pending.borderColor};
	color: ${cm.assignmentList.statusBadge.pending.color};
	padding: 0.5rem;
	border-radius: 2px;

	span {
		font-size: 0.875rem;
		font-weight: 700;
		line-height: 1;
	}

	&.approved {
		background-color: ${cm.assignmentList.statusBadge.approved.background};
		border-color: ${cm.assignmentList.statusBadge.approved.borderColor};
		color: ${cm.assignmentList.statusBadge.approved.color};
	}

	&.stats-uploaded {
		background-color: ${cm.assignmentList.statusBadge.statsUploaded.background};
		border-color: ${cm.assignmentList.statusBadge.statsUploaded.borderColor};
		color: ${cm.assignmentList.statusBadge.statsUploaded.color};
	}

	&.declined {
		background-color: ${cm.assignmentList.statusBadge.declined.background};
		border-color: ${cm.assignmentList.statusBadge.declined.borderColor};
		color: ${cm.assignmentList.statusBadge.declined.color};
	}
`;

const currentStateText = styled.span`
	display: block;
	font-size: 0.9375rem;
	line-height: 1.5;
	color: ${cm.assignmentList.statusBadge.currentStateText.color};

	&.approved {
		color: ${cm.assignmentList.statusBadge.currentStateText.approved.color};
	}
`;

const Link = styled(link)`
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	z-index: 2;
`;

const Status = styled.div`
	span {
		color: ${newColors.slate};
	}
`;

const CustomDropdown = styled(Dropdown)`
	.icon {
		path {
			fill: ${colors.black1};
		}
	}

	button:disabled {
		opacity: 0.4;
	}
`;

const ActionButtons = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	margin-left: auto;
	z-index: 3;

	> button {
		min-width: 124px;

		&:disabled {
			color: ${colors.white};

			svg path {
				fill: ${colors.white};
			}
		}
	}
`;

const Styled = {
	Wrapper,
	Item,
	Icon,
	Content,
	AssignmentName,
	StatusBadge,
	Name,
	currentStateText,
	Link,
	Status,
	CustomDropdown,
	ActionButtons,
};

export default Styled;
