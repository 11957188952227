import classNames from 'classnames';
import { JsonApiDocument } from 'json-api-models';
import { useState, useEffect } from 'react';

import { ListCampaignsQuery } from 'api-queries';
import useCampaignData from 'hooks/Chart/useCampaignData';
import useListData from 'hooks/Chart/useListData';
import { getErrorMessageOnPost, getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import CampaignManager from 'services/ApiManager/Campaign.manager';
import SegmentManager from 'services/ApiManager/Segment.manager';
import { isSuccessfulResponse } from 'services/Response.types';
import toast from 'services/Toast';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import CampaignListMenuItems from './CampaignListMenuItems';
import DefaultDropdownMenuItems from './DefaultDropdownMenuItems';
import Styled from './DiscoveryDropdown.style';
import FolderListMenuItems from './FolderListMenuItems';
import { DROPDOWN_OPTION, handleSuccessToast } from './helpers';

const DiscoveryDropdown = (props: {
	selectedItems: string[];
	onResetSelection?: () => void;
	size?: '16' | '24';
	isList?: boolean;
	helpText?: string;
	isOpen?: boolean;
	onClickClose?: () => void;
}) => {
	const [isInstanceOpen, setIsInstanceOpen] = useState(props.isOpen ?? false);
	const [isLoadingLists, setIsLoadingLists] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [selectedOption, setSelectedOption] = useState(DROPDOWN_OPTION.NULL);
	const [foldersAndLists, setFoldersAndLists] = useState<{ folders: JsonApiDocument; lists: JsonApiDocument } | null>(null);

	const manager = useInjection<CampaignManager>(CampaignManager);
	const queryBuilder = RequestQueryBuilder.create<ListCampaignsQuery>(['smallCoverPhoto', 'addInfluencers', 'createSegment'])
		.withInclude('segments:hateoas(false)')
		.withFilter('statuses', 'draft,active');

	const segmentManager = useInjection<SegmentManager>(SegmentManager);

	const { repository, result, isLoading } = manager.listCampaigns(queryBuilder, { keepPreviousData: true });

	const { setInfluencersToCampaign } = useCampaignData();

	const { getAllFoldersAndLists, addInfluencersToList } = useListData();

	const saveInfluencersHandler = (influencers: string[]) => {
		setIsSaving(true);
		return async (addInfluencersUrl: string, itemId: string, targetName?: string, segmentId?: string) => {
			try {
				let res;
				switch (selectedOption) {
					case DROPDOWN_OPTION.ADD_TO_CAMPAIGN:
						res = await setInfluencersToCampaign(addInfluencersUrl, influencers);
						if (isSuccessfulResponse(res)) {
							if (segmentId) {
								await segmentManager.addInfluencersTo(segmentId, { influencers });
								handleSuccessToast(influencers, targetName, itemId, 'segment', segmentId);
							} else {
								handleSuccessToast(influencers, targetName, itemId, selectedOption);
							}
						} else {
							toast.error(res !== 0 ? res.toString() : getSomethingWentWrongMessage());
						}
						break;
					case DROPDOWN_OPTION.ADD_TO_LIST:
						res = await addInfluencersToList(itemId, influencers);
						if (isSuccessfulResponse(res)) {
							handleSuccessToast(influencers, targetName, itemId, selectedOption);
						} else {
							toast.error(getSomethingWentWrongMessage());
						}
						break;
					default:
						toast.error(getSomethingWentWrongMessage());
						break;
				}
				if (props.onResetSelection) {
					props.onResetSelection();
				}
			} catch (error) {
				toast.error(
					segmentId && selectedOption === DROPDOWN_OPTION.ADD_TO_CAMPAIGN
						? getErrorMessageOnPost('adding influencer to segment')
						: getSomethingWentWrongMessage(),
				);
			} finally {
				setIsSaving(false);
			}
		};
	};

	const getAllInfluencerList = () => {
		setIsLoadingLists(true);
		getAllFoldersAndLists()
			.then((res) => {
				if (res) {
					// @ts-ignore
					setFoldersAndLists(res as { folders: JsonApiDocument; lists: JsonApiDocument });
					setSelectedOption(DROPDOWN_OPTION.ADD_TO_LIST);
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoadingLists(false);
			});
	};

	const close = () => {
		setIsInstanceOpen(false);
		props.onClickClose && props.onClickClose();
	};

	useEffect(() => {
		if (!isInstanceOpen) {
			setSelectedOption(DROPDOWN_OPTION.NULL);
		}
	}, [isInstanceOpen]);

	const renderMenuItems = () => {
		switch (selectedOption) {
			case DROPDOWN_OPTION.ADD_TO_CAMPAIGN:
				return (
					<CampaignListMenuItems
						repository={repository}
						items={result ?? []}
						isCancellable
						onCancel={() => setSelectedOption(DROPDOWN_OPTION.NULL)}
						onClick={(url: string, campaignId: string, campaignName: string, segmentId?: string) =>
							saveInfluencersHandler(props.selectedItems)(url, campaignId, campaignName, segmentId)
						}
						onClose={close}
						selectedItems={props.selectedItems}
						isSaving={isSaving}
						isLoadingCampaigns={isLoading}
					/>
				);
			case DROPDOWN_OPTION.ADD_TO_LIST:
				return (
					<FolderListMenuItems
						items={foldersAndLists}
						isCancellable
						onCancel={() => setSelectedOption(DROPDOWN_OPTION.NULL)}
						onClick={(url: string, listId: string, listName: string) => saveInfluencersHandler(props.selectedItems)(url, listId, listName)}
						onClose={close}
						updateFoldersAndLists={getAllInfluencerList}
						selectedItems={props.selectedItems}
						isSaving={isSaving}
					/>
				);
			default:
				return (
					<DefaultDropdownMenuItems
						onAddCampaign={() => setSelectedOption(DROPDOWN_OPTION.ADD_TO_CAMPAIGN)}
						onAddList={getAllInfluencerList}
						isLoadingLists={isLoadingLists}
						isLoadingCampaigns={isLoading}
						onClose={close}
					/>
				);
		}
	};

	return props.isList ? (
		renderMenuItems()
	) : (
		<Styled.CustomDropdown
			open={isInstanceOpen}
			setOpen={(value) => setIsInstanceOpen(value)}
			icon='add-new-folder'
			position={props.size ? 'left' : 'right'}
			className={classNames('display-circle', { lg: props.size })}
			tooltip={props.helpText}
		>
			<div onClick={(e) => e.stopPropagation()}>{renderMenuItems()}</div>
		</Styled.CustomDropdown>
	);
};

export default DiscoveryDropdown;
