import { Model } from 'json-api-models';

import { CAMPAIGN_STATUS } from 'components/IntegratedInbox/Components/CampaignList/types';
import ConversationItem from 'components/IntegratedInbox/influencer/ConversationList/ConversationItem';
import { IntegratedInboxCampaign } from 'components/IntegratedInbox/types';
import LoadingSpinner from 'components/LoadingSpinner';
import { conversationMetaData } from 'shared/Types/Campaign/ConversationMetaData';

import Styled from './ConversationList.style';

type Props = {
	displaySkeletonLoader?: boolean;
	campaignConversations: Array<{ campaign: IntegratedInboxCampaign; conversations: Model[] }>;
	onSelect: (campaign: IntegratedInboxCampaign) => void;
	onClick: () => void;
	isOpen: boolean;
	isDirectConversationListOpen: boolean;
	directConversationLength: number;
	isFetching: boolean;
	campaigns: IntegratedInboxCampaign[];
	selectedCampaign: IntegratedInboxCampaign | null;
	selectedCampaignStatus?: (status: CAMPAIGN_STATUS) => void;
};

const ConversationList = ({ displaySkeletonLoader, onSelect, isFetching, campaigns, selectedCampaign }: Props) => {
	return (
		<Styled.Wrapper data-testid='inbox-influencer-conversation-list'>
			{isFetching && !displaySkeletonLoader ? (
				<Styled.SpinnerWrapper>
					<LoadingSpinner />
				</Styled.SpinnerWrapper>
			) : (
				<>
					<Styled.ListWrapper>
						{campaigns.map((campaign) => {
							const isSelected = !!(selectedCampaign && selectedCampaign.id === campaign.id);
							return (
								<ConversationItem
									displaySkeletonLoader={displaySkeletonLoader}
									key={campaign.id}
									title={campaign.name}
									coverImage={campaign.coverImage}
									onClick={(e: React.MouseEvent<HTMLElement>) => {
										e.stopPropagation();
										onSelect(campaign);
									}}
									isSelected={isSelected}
									conversation={undefined}
									hasUnreadMessages={campaign.conversationMetaData?.unread > 0}
									conversationMetaData={campaign.conversationMetaData as unknown as conversationMetaData}
								/>
							);
						})}
					</Styled.ListWrapper>
				</>
			)}
		</Styled.Wrapper>
	);
};

export class CampaignConversation {
	id: string;
	shortId: string;
	title: string;
	coverImage: string;
	conversation: Model | undefined;
	hasUnreadMessages: boolean;
	latestMessageCreatedAt: string | undefined;
	campaign: Model;
	integratedInboxCampaign: IntegratedInboxCampaign;
	isRejected: boolean;

	constructor(campaign: IntegratedInboxCampaign, conversation?: Model | undefined) {
		this.id = campaign.id;
		this.shortId = campaign.shortId;
		this.title = campaign.name;
		this.coverImage = campaign.coverImage;
		this.conversation = conversation;
		this.hasUnreadMessages = this.conversation ? this.conversation.unreadMessages.length > 0 : false;
		this.latestMessageCreatedAt = this.conversation ? this.conversation.latestMessage.createdAt : undefined;
		this.campaign = campaign.campaign;
		this.integratedInboxCampaign = campaign;
		this.isRejected = campaign.campaign.campaignInstagramOwners[0].status === 'rejected';
	}
}

export default ConversationList;
