import axios, { AxiosResponse } from 'axios';
import { isString } from 'lodash';
import moment from 'moment';

import { KEY_TOKENS, KEY_DISCOVERY_API_TOKEN } from 'constants/localStorage-keys';
import { StatusCode } from 'services/Response.types';
import Client from 'shared/ApiClient';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

import { IDiscoveryAuthService, IDiscoveryTokenResponse, StoredDiscoveryToken } from './types';

class DiscoveryAuthService implements IDiscoveryAuthService {
	storage: BrowserStorage;

	constructor() {
		this.storage = new BrowserStorage(StorageType.LOCAL);
	}

	requestDiscoveryToken = async (): Promise<StatusCode> => {
		let result = 0;
		await Client('post', '/discoverytokens')
			.then((response: AxiosResponse<IDiscoveryTokenResponse>) => {
				result = StatusCode.OK;
				this.setDiscoveryToken(response.data.data.attributes.token, response.data.data.attributes.expiresIn);
			})
			.catch((error: AxiosResponse) => {
				if (axios.isAxiosError(error)) {
					result = error.status;
					throw Error(error.message);
				}

				console.error(error);
			});
		return result;
	};

	setDiscoveryToken = (token: string, expiresIn: number) => {
		const tokens = this.storage.getItem(KEY_TOKENS) || '';

		if (tokens !== '') {
			const parsedTokens = JSON.parse(tokens);
			parsedTokens[KEY_DISCOVERY_API_TOKEN] = {
				token: token,
				expiresAt: moment().add(expiresIn, 'second').toString(),
			};
			this.storage.setItem(KEY_TOKENS, JSON.stringify(parsedTokens));
		} else {
			const discoveryToken: { [key: string]: { token: string; expiresAt: string } } = {};
			discoveryToken[KEY_DISCOVERY_API_TOKEN] = {
				token: token,
				expiresAt: moment().add(expiresIn, 'second').toString(),
			};
			this.storage.setItem(KEY_TOKENS, JSON.stringify(discoveryToken));
		}
	};

	getDiscoveryToken(): StoredDiscoveryToken | null {
		const tokens = this.storage.getItem(KEY_TOKENS);

		if (!isString(tokens)) {
			return null;
		}
		const parsedTokens = JSON.parse(tokens);

		if (!Object.prototype.hasOwnProperty.call(parsedTokens, KEY_DISCOVERY_API_TOKEN)) {
			return null;
		}

		const token: StoredDiscoveryToken = parsedTokens[KEY_DISCOVERY_API_TOKEN];

		if (new Date() > new Date(token.expiresAt)) {
			this.removeDiscoveryToken();

			return null;
		}

		return token;
	}

	removeDiscoveryToken = () => {
		this.storage.removeItem(KEY_DISCOVERY_API_TOKEN);
		const tokens = this.storage.getItem(KEY_TOKENS) || '';
		if (tokens !== '') {
			const parsedTokens = JSON.parse(tokens);
			delete parsedTokens[KEY_DISCOVERY_API_TOKEN];
			this.storage.setItem(KEY_TOKENS, JSON.stringify(parsedTokens));
		}
	};
}

export default new DiscoveryAuthService();
