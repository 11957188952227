import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	gap: 24px;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
`;

const Styled = {
	Wrapper,
	Column,
};

export default Styled;
