import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgCollapse(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.49609 18.5V1.5H7.49609V18.5H1.49609ZM8.49609 18.5H18.4961V1.5H8.49609V18.5ZM-0.00390625 1C-0.00390625 0.447716 0.443809 0 0.996094 0H18.9961C19.5484 0 19.9961 0.447715 19.9961 1V19C19.9961 19.5523 19.5484 20 18.9961 20H0.996094C0.443809 20 -0.00390625 19.5523 -0.00390625 19V1ZM13.0008 6.20465C12.8382 5.998 12.5009 5.93786 12.2472 6.07033C11.9936 6.20279 11.9198 6.4777 12.0824 6.68434L14.795 10.1324L12.0936 13.302C11.9218 13.5036 11.9831 13.7806 12.2305 13.9206C12.4779 14.0606 12.8178 14.0106 12.9896 13.809L15.8987 10.3957C16.0244 10.2482 16.0288 10.0536 15.9098 9.90238L13.0008 6.20465Z'
			/>
		</svg>
	);
}
