import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from 'components/Button';
import colors from 'styles/variables/colors';
import { SocialProfileConnectorColors as SPCcolors } from 'styles/variables/colors/SocialProfileConnector';
import { PALETTE } from 'styles/variables/original-colors';
import { px, rem } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const ConnectSection = styled.div`
	display: flex;
	flex-direction: column;
	p {
		text-align: center;
		margin-top: 0;
		margin-bottom: ${px[3]}px;
	}
	button {
		width: fit-content;
		margin: auto;
	}
`;

const ConnectButton = styled(Button)`
	background: ${SPCcolors.connectButton.background};
	color: ${SPCcolors.connectButton.color};
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.938rem;
	border: 1px solid ${SPCcolors.connectButton.border};
`;

const CustomLink = styled(Link)`
	display: flex;
	color: ${SPCcolors.helpLink.color} !important;
	align-items: center;
	border-bottom: transparent !important;
	text-decoration: underline;
	margin: 32px 0;

	.icon {
		margin-right: ${px[2]}px;
		path {
			fill: ${SPCcolors.helpLink.color};
		}
	}
`;

const ToggleTitleWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: ${px[4]}px;
	margin-bottom: ${px[2]}px;

	h5 {
		font-size: 1rem;
		font-weight: 600;
		margin-bottom: 0;
	}
`;

const ConnectedUser = styled.div`
	background-color: ${PALETTE.aquaHaze};
	border-radius: 10px;
	padding: ${rem[2]}rem;

	&:not(:last-child) {
		margin-bottom: ${px[1]}px;
	}
`;

const NotConnectedPages = styled.div`
	border-top: 1px solid ${colors.settings.avatarBackground};
	border-bottom: 1px solid ${colors.settings.avatarBackground};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.5rem;
	cursor: pointer;
	p {
		font-size: 0.875rem;
		color: ${colors.black1};
		margin-top: 18px;
	}
`;

const Styled = {
	ConnectSection,
	ConnectButton,
	CustomLink,
	ToggleTitleWrapper,
	ConnectedUser,
	NotConnectedPages,
};

export default Styled;
