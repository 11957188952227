import { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { getProperDataFromResponse } from 'components/InfluencerDashboard/Components/InvitationBriefPage/helper';
import LoadingSpinner from 'components/LoadingSpinner';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';
import InfluencerService from 'services/Influencer';
import CampaignService from 'services/Statistics/Campaign';
import toast from 'services/Toast';
import { isInfluencer } from 'services/auth-service';
import BriefPage from 'views/BriefPage';
import LinkAlreadyUsed from 'views/BriefPage/LinkAlreadyUsed';

const LandingPageByInvite = () => {
	const [loading, setLoading] = useState(true);
	const [linkAlreadyUsed, setLinkAlreadyUsed] = useState(false);
	const [campaign, setCampaign] = useState<Campaign>();
	const [campaignInstagramOwner, setCampaignInstagramOwner] = useState<CampaignInstagramOwner>();

	const params = useParams();

	useEffect(() => {
		if (params.influencerId) {
			CampaignService.fetchInfluencerPreviewData(params.campaignId || '', params.influencerId)
				.then((res) => {
					const newResult = getProperDataFromResponse(res);
					setCampaign(newResult.campaign);
					setCampaignInstagramOwner(newResult.campaignInstagramOwner as CampaignInstagramOwner);
					setLoading(false);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			InfluencerService.getInvitedCampaign(params.inviteToken || '')
				.then((res) => {
					if (res) {
						const newResult = getProperDataFromResponse(res);
						setCampaign(newResult.campaign);
						setCampaignInstagramOwner(newResult.campaignInstagramOwner as CampaignInstagramOwner);
						setLoading(false);
					}
				})
				.catch((err) => {
					if (err.response && (err.response.status === 410 || err.response.status === 404)) {
						toast.error(err.response.data.errors[0].title);
						setLinkAlreadyUsed(true);
						setLoading(false);
					} else {
						console.error(err);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		}

		return () => {
			setLinkAlreadyUsed(false);
			setLoading(false);
			setCampaign(undefined);
			setCampaignInstagramOwner(undefined);
		};
	}, []);

	if (isInfluencer()) {
		return <Navigate to={`/influencer/dashboard`} />;
	}

	return (
		<>
			{loading ? (
				<div className='loading-center'>
					<LoadingSpinner position='center' />
				</div>
			) : linkAlreadyUsed ? (
				<LinkAlreadyUsed />
			) : !campaign || !campaignInstagramOwner ? (
				<Navigate to='/not-found' />
			) : (
				<BriefPage campaign={campaign} campaignInstagramOwner={campaignInstagramOwner} />
			)}
		</>
	);
};

export default LandingPageByInvite;
