import { Provider } from 'react-redux';

import PageHeader from 'components/PageHeader';
import usePermissions from 'hooks/usePermissions';
import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './DataLibrary.style';
import DataLibraryContainer from './DataLibraryContainer';
import { store } from './store';

/**
 * DataLibraryView
 * View for Data library
 * @returns {JSX.Element}
 */
const DataLibraryView = (): JSX.Element => {
	const { isBrandManager } = usePermissions();
	const user = useAppSelector((state) => state.user);

	return (
		<Provider store={store}>
			<Styled.Wrapper>
				<PageHeader headline='Data Library' showBreadcrumb={false} />
				<DataLibraryContainer user={user} isBrandManager={isBrandManager()} />
			</Styled.Wrapper>
		</Provider>
	);
};

export default DataLibraryView;
