import Styled from './PageHeader.style';

type PageHeaderProps = {
	heading: string | React.ReactNode;
	cssClass?: string;
	children?: React.ReactNode;
};

/**
 * PageHeader
 * Component for the page headers
 * @param {PageHeaderProps}
 * @returns {JSX.Element}
 */
const PageHeader = ({ heading, cssClass, children }: PageHeaderProps): JSX.Element => {
	return (
		<Styled.PageHeader className={cssClass}>
			<Styled.Inner className='page-header-inner'>
				<Styled.LeftContainer className='page-header-left-container'>
					<Styled.PageHeading>{heading}</Styled.PageHeading>
				</Styled.LeftContainer>
				{children && <Styled.RightContainer>{children}</Styled.RightContainer>}
			</Styled.Inner>
		</Styled.PageHeader>
	);
};

export default PageHeader;
