import { JsonApiDocument, JsonApiIdentifier, Store } from 'json-api-models';
import { JsonApiResource } from 'json-api-models/src/types';
import { filter } from 'lodash';
import useSWR from 'swr';

import { createClient } from 'shared/ApiClient/ApiClient';

const client = createClient();

/**
 * @deprecated use ApiManager instead
 */
export default function useModels(url: string | null) {
	const fetcher = async (url: string) => {
		const { data } = await client.get<JsonApiDocument>(url);

		return data;
	};

	const { data, error, mutate, isValidating } = useSWR(url, fetcher);
	const models = new Store();

	if (data && !error) {
		models.sync(data);
	}

	const loading = !data && !error && url !== null;

	return {
		models,
		mutation: {
			async add(resource: JsonApiResource) {
				await mutate({
					...data,
					data: [...(data!.data as JsonApiResource[]), resource],
				});
			},
			async remove({ id }: JsonApiIdentifier) {
				await mutate({
					...data,
					data: filter(data!.data as JsonApiResource[], (resource) => resource.id !== id),
				});
			},
			async refresh() {
				await mutate();
			},
		},
		error,
		loading,
		isFetching: isValidating || loading,
	};
}
