import styled from 'styled-components';

import { scrollbarX } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	display: flex;
	column-gap: ${spacing[1].px};

	padding-bottom: ${spacing[1].rem};
	overflow-x: auto;
	${scrollbarX}
`;

const InfleuncerItem = styled.div`
	height: 42px;
	padding: ${spacing[0].rem} ${spacing[2].rem} ${spacing[0].rem} ${spacing[1].rem};
	border-radius: 10px;
	border: 1px solid ${colors.onboarding.option.border};
	cursor: pointer;

	display: flex;
	column-gap: ${spacing[1].px};
	align-items: center;

	&.selected {
		background-color: ${colors.onboarding.option.selected.background};
		color: ${colors.onboarding.option.selected.color};
		border-color: ${colors.onboarding.option.selected.background};
	}

	&.error {
		border-color: ${colors.onboarding.option.error.border};
		border-width: 2px;
	}

	& > i {
		display: flex;
	}
`;

const InfluencerAvatarWrapper = styled.div`
	transform: scale(0.8);
`;

const Styled = {
	Wrapper,
	InfleuncerItem,
	InfluencerAvatarWrapper,
};

export default Styled;
