import { Model } from 'json-api-models';

import Avatar from 'components/Avatar';

import Styled from './SearchResultItem.style';

type Props = {
	influencer: Model;
	onClick: () => void;
};

const SearchResultItem = ({ influencer, onClick }: Props) => {
	return (
		<Styled.Wrapper onClick={onClick} data-testid='search-result-item'>
			<Avatar imageUrl={influencer.links.profilePictureUrl} name={influencer.username} size='sm' />
			<div className='name'>{influencer.username}</div>
		</Styled.Wrapper>
	);
};

export default SearchResultItem;
