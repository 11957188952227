import { InternalAxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';
import { isNil } from 'lodash';

import { GHOST_USER_UUID, KEY_COLLABS_API_TOKEN, KEY_TOKENS } from 'constants/localStorage-keys';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

const EXCEPTION_PATHS = ['/tools/og-metadata'];

@injectable()
export default class RequestDecoratorInterceptor {
	public requestHeadersConfig(config: InternalAxiosRequestConfig) {
		const storage = new BrowserStorage(StorageType.LOCAL);
		const sessionStorage = new BrowserStorage(StorageType.SESSION);
		const tokens = storage.getItem(KEY_TOKENS) || sessionStorage.getItem(KEY_TOKENS) || '';

		const ghostUserEmail = storage.getItem(GHOST_USER_UUID) || '';

		if (ghostUserEmail !== '') {
			config.headers!['X-Switch-User'] = `${ghostUserEmail}`;
		}

		// Check if we have a token and add it to the header during the calls
		if (tokens !== '') {
			const parsedToken = JSON.parse(tokens);
			if (parsedToken[KEY_COLLABS_API_TOKEN]) {
				config.headers!['X-Auth-Token'] = `${parsedToken[KEY_COLLABS_API_TOKEN]}`;
			}
		}

		return config;
	}

	public addExcludeToRequest(config: InternalAxiosRequestConfig) {
		if (config.params instanceof URLSearchParams) {
			if (config.params.has('exclude')) {
				return config;
			}

			config.params.set('exclude', 'defaultIncludes');
		} else {
			// Here params in an object
			const params: Record<string, string> = { ...config.params };

			if (!isNil(params.exclude)) {
				return config;
			}

			params.exclude = 'defaultIncludes';
			config.params = params;
		}

		return config;
	}

	public blockNonHateoas(config: InternalAxiosRequestConfig) {
		if (isNil(config.params) || config.method === 'delete' || EXCEPTION_PATHS.includes(config.url!)) {
			return config;
		}

		let include: string | undefined | null;

		if (config.params instanceof URLSearchParams) {
			include = config.params.get('include') ?? config.params.get('includes');
		} else {
			// Here params in an object
			include = config.params.include ?? config.params.includes;
		}

		const message = `---
The "include" parameter "${include}" of request to ${config.url} doesn't contains specification for hateoas.
Either add :hateoas(false) or specify the hateoas you need.
For more information, please consult https://github.com/collabsapp/webapp/issues/4876.\n---`;

		if (isNil(include)) {
			console.error(message);
			throw new Error('Missing "include" parameter. You must specify the root hateoas you need. Ie ":hateoas(false)"');
		}

		const includeArray: string[] = include?.split(',') ?? [];
		for (let i = 0; i < includeArray.length; i++) {
			if (!includeArray[i].includes(':hateoas(')) {
				console.error(message);
				throw new Error('Invalid "include" parameter "${include}". You must specify the hateoas you need.');
			}
		}

		return config;
	}
}
