import { Model } from 'json-api-models';
import { useEffect, useState } from 'react';

import SummaryView from 'components/ContentManagement/Components/ContentSection/Components/Summary';
import TabMenu from 'components/ContentManagement/Components/TabMenu';
import { TabItem } from 'components/ContentManagement/Components/TabMenu/TabMenu';
import Statistics from 'components/ContentManagement/Components/Views/Statistics';
import { CampaignItemType } from 'components/ContentManagement/types';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { AssignmentReviewType, AssignmentStatus } from 'shared/Types/Assignment';
import colors from 'styles/variables/colors';
import { ASSIGNMENT_TYPE } from 'types/AssignmentTypes';

import AssignmentForm from './Components/AssignmentForm';

type AssignmentProps = {
	type: ASSIGNMENT_TYPE;
	id: string;
	CIOAssignment: Model;
	selectedCampaign: CampaignItemType;
	selectedCIO: Model;
	CIOAS: Model[];
	review: Model;
	heading: string;
	refresh: () => void;
	CIOArefresh: () => Promise<void>;
};

/**
 * Assignment
 * Component to show tabs, correct form, stats and summary
 * @param {AssignmentProps}
 * @returns {JSX.Element}
 */
const Assignment = ({ CIOAssignment, selectedCampaign, selectedCIO, CIOAS, review, refresh, CIOArefresh }: AssignmentProps): JSX.Element => {
	const [activeTab, setActiveTab] = useState<TabItem>(TabItem.CONTENT);
	const [selectedTab, setSelectedTab] = useState<TabItem>(TabItem.CONTENT);
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
	const { assignment, attributes } = CIOAssignment;
	const { newFancyStatus: CampaignInstagramOwnerStatus } = attributes || {};

	const currentReview = review as unknown as AssignmentReviewType;

	const getActiveTab = () =>
		({
			[CampaignInstagramOwnerStatus.REVIEW_ACCEPTED]: TabItem.STATS,
			[CampaignInstagramOwnerStatus.STATS_UPLOADED]: TabItem.SUMMARY,
		})[CampaignInstagramOwnerStatus] || TabItem.CONTENT;

	useEffect(() => {
		setActiveTab(getActiveTab());
	}, [CIOAssignment.id]);

	const openModal = (index: TabItem) => {
		setSelectedTab(index);
		setIsWarningModalOpen(true);
	};

	useEffect(() => {
		return () => {
			setSelectedTab(TabItem.CONTENT);
			setActiveTab(TabItem.CONTENT);
			setUnsavedChanges(false);
			setIsWarningModalOpen(false);
		};
	}, []);

	return (
		<>
			<TabMenu
				activeItem={activeTab}
				onClickTab={(index: TabItem) => (unsavedChanges ? openModal(index) : setActiveTab(index))}
				isStatsVisible={assignment?.kind !== ASSIGNMENT_TYPE.AFFILIATE_TASK}
				isStatsDisabled={
					CampaignInstagramOwnerStatus === AssignmentStatus.WAITING_FOR_UPLOAD ||
					CampaignInstagramOwnerStatus === AssignmentStatus.IN_REVIEW ||
					CampaignInstagramOwnerStatus === AssignmentStatus.DECLINED
				}
				review={currentReview}
				CIOArefresh={CIOArefresh}
				refresh={refresh}
			/>
			{activeTab === TabItem.CONTENT && (
				<AssignmentForm
					assignmentType={assignment?.kind}
					selectedCampaign={selectedCampaign}
					CIOAssignment={CIOAssignment}
					CIOArefresh={CIOArefresh}
					campaignRefresh={refresh}
					goToStats={() => setActiveTab(1)}
				/>
			)}

			{activeTab === TabItem.STATS && assignment?.kind !== ASSIGNMENT_TYPE.AFFILIATE_TASK && (
				<Statistics
					active={true}
					review={review}
					CIOAssignment={CIOAssignment}
					selectedCampaign={selectedCampaign}
					assignmentType={assignment?.kind}
					assignment={CIOAssignment}
					selectedCIO={selectedCIO}
					refresh={CIOArefresh}
					influencer={CIOAssignment.influencer}
					getUnsavedChanges={(unsavedChanges) => setUnsavedChanges(unsavedChanges)}
				/>
			)}
			{activeTab === TabItem.SUMMARY && (
				<SummaryView isActive={true} activeCampaign={selectedCampaign} selectedCIO={selectedCIO} CIOAS={CIOAS} refresh={CIOArefresh} />
			)}
			<ConfirmModal
				IconBackgroundColor={colors.contentManagement.iconBackground}
				isModalOpen={isWarningModalOpen}
				toggleModal={() => setIsWarningModalOpen(!isWarningModalOpen)}
				action={() => {
					setUnsavedChanges(false), setIsWarningModalOpen(false), setActiveTab(selectedTab);
				}}
				title='Unsaved changes'
				isFetching={false}
				buttonText='Yes, leave'
				cancelButtonText='Cancel'
				icon='circle-cross'
			>
				<>
					<p>You have unsaved changes in the statistics form. They will get lost if you dont save them.</p>
				</>
			</ConfirmModal>
		</>
	);
};

export default Assignment;
