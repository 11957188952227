import { AxiosError } from 'axios';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConverImage } from 'components/Dashboard/Components/Campaigns/types';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import usePermissions from 'hooks/usePermissions';
import { ROLE_PROJECT_MANAGER } from 'hooks/usePermissions/types';
import CampaignsService from 'services/Campaign';
import toast from 'services/Toast';
import colors from 'styles/variables/colors';

import Styled from './CampaignCard.style';

export type ICampaignCardProps = {
	id: number;
	index: number;
	name: string;
	status: string;
	coverImage: ConverImage;
	/** @deprecated Build the URL yourself */
	campaignUrl: string;
	first: boolean;
	brand: string;
	refresh: () => void;
	refreshAll: () => void;
	refreshArchived: () => void;
};

/**
 * CampaignCard
 * @param {ICampaignCardProps} props
 * @returns  {JSX.Element}
 */
const CampaignCard = ({ name, campaignUrl, coverImage, first, id, brand, refresh, refreshAll, refreshArchived }: ICampaignCardProps): JSX.Element => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { isGranted } = usePermissions();

	const archiveCampaign = () => {
		setIsLoading(true);
		CampaignsService.archiveCampaign(id)
			.then(() => {
				toast.success('The campaign has been added to the archive');
				refresh();
				refreshAll();
				refreshArchived();
			})
			.catch((error: AxiosError) => {
				console.error(error);
				toast.error(getErrorMessageOnPost('archiving this campaign'));
			})
			.finally(() => {
				setIsLoading(false);
				setIsModalOpen(false);
			});
	};

	return (
		<Styled.CardWrapper data-testid='campaign-card' className={classNames({ first: first })} onClick={() => navigate(campaignUrl)}>
			<Styled.NameTagWrapper>
				<Styled.NameTag>
					<span>{name}</span>
				</Styled.NameTag>
				<Styled.Divider />
				<Dropdown icon='options' size='16'>
					<DropdownMenu>
						{isGranted(ROLE_PROJECT_MANAGER) && (
							<DropdownItem
								onClick={(e) => {
									e.stopPropagation();
									navigate(`${campaignUrl}/edit`);
								}}
							>
								Edit campaign
							</DropdownItem>
						)}
						<DropdownItem
							onClick={(e) => {
								e.stopPropagation();
								navigate(`${campaignUrl}/preview`);
							}}
						>
							View brief
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</Styled.NameTagWrapper>
			<Styled.Card sm={coverImage.sm} md={coverImage.md} lg={coverImage.lg}>
				<ConfirmModal
					IconBackgroundColor={colors.statusTag.archived.backgroundColor}
					icon='archive'
					isModalOpen={isModalOpen}
					toggleModal={() => setIsModalOpen(!isModalOpen)}
					title={`You are about to archive ${name}`}
					isFetching={isLoading}
					action={archiveCampaign}
					buttonText='Archive'
				/>
			</Styled.Card>
			<Styled.BrandTagWrapper>
				<Styled.BrandTag>
					<span>{brand}</span>
				</Styled.BrandTag>
			</Styled.BrandTagWrapper>
		</Styled.CardWrapper>
	);
};

export default CampaignCard;
