import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgLock(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.78271 6.78271C5.21045 6.78271 5.56519 6.42773 5.56519 6C5.56519 3.55835 7.55835 1.56519 10 1.56519C12.4417 1.56519 14.4348 3.55835 14.4348 6C14.4348 6.42773 14.7896 6.78271 15.2173 6.78271C15.6453 6.78271 16 6.42773 16 6C16 2.69214 13.3079 0 10 0C6.69214 0 4 2.69214 4 6C4 6.42773 4.35474 6.78271 4.78271 6.78271ZM18.1206 8H1.86963C1.38989 8 1 8.38428 1 8.86255V19.1375C1 19.6157 1.38989 20 1.86963 20H18.1304C18.6101 20 19 19.6157 19 19.1375V8.86255C19 8.38428 18.6101 8 18.1304 8H18.1206ZM17.5708 18.5845H2.41919V9.40625H17.5708V18.5845ZM10.0557 15.3201C9.62231 15.3201 9.2666 15 9.2666 14.6101V13.5701C9.1001 13.3999 9 13.1899 9 12.95C9 12.4199 9.46655 12 10.0557 12C10.6445 12 11.1111 12.4299 11.1111 12.95C11.1111 13.1899 11.0112 13.3999 10.8445 13.5701V14.6101C10.8445 15 10.4888 15.3201 10.0557 15.3201Z'
				fill='#333333'
			/>
		</svg>
	);
}
