import { SELECTED_PROFILE } from 'constants/localStorage-keys';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

const storage = new BrowserStorage(StorageType.LOCAL);

export const storeSelectedProfileId = (id: string) => {
	storage.setItem(SELECTED_PROFILE, id);
};

export const getSelectedProfileId = () => {
	return storage.getItem(SELECTED_PROFILE);
};
