/**
 * Generic styles for the brief page
 */

import styled, { DefaultThemeV2 } from 'styled-components';

import { RichText } from 'styles/utils/RichText';
type P = { theme: DefaultThemeV2 };

// Details
export const detailItem = styled.div`
	overflow-wrap: break-word;

	hr {
		border-top: 1px solid ${({ theme }: P) => theme.borders.secondary};
		margin: 24px 0;
	}

	ul,
	ol {
		padding-left: 1rem;
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style-type: decimal;
	}

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.5;
	}
`;

export const detailTitle = styled.h4`
	font-weight: ${({ theme }: P) => theme.fontWeights.bold};
	font-size: 2rem;
`;

export const detailText = styled.div`
	${RichText}
`;
