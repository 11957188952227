import styled from 'styled-components';

import Accordion from 'components/Accordion';
import colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

const CustomAccordion = styled(Accordion)`
	.accordion__body {
		margin-bottom: 40px;
	}
	.accordion__title {
		padding-right: 0;
	}
`;

const HelpLink = styled.div`
	cursor: pointer;
	color: ${colors.info};
	display: flex;
	align-items: center;
	font-size: 0.938rem;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	font-family: ${typography.BaseFontFamiliy};
	.icon {
		margin-left: 8px;
		path {
			fill: ${colors.info};
		}
	}
`;

const HelpText = styled.div`
	font-size: 0.938rem;
	line-height: 150%;
	padding: 1rem 2rem;
	background: ${colors.contentManagement.card.grayBackground};
	border-width: 1px 0px;
	border-style: dashed;
	border-color: ${colors.contentManagement.card.grayBorder};
	ol {
		padding-left: 0;
		margin-bottom: 8px;
	}
	p {
		font-size: 0.938rem;
	}
`;

const HelpLinkContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
`;

const HelpButton = styled.div`
	display: flex;
	align-items: center;
	font-family: ${typography.BaseFontFamiliy};
	cursor: pointer;
	color: ${colors.info};
	background-color: ${colors.contentManagement.card.background};
	width: fit-content;
	padding: 0.2rem 0.5rem;
	border-radius: 4px;
	.icon {
		margin-left: 4px;
		path {
			fill: ${colors.info};
		}
		circle {
			stroke: ${colors.info};
		}
	}
`;

const HelpTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
`;

const Styled = {
	CustomAccordion,
	HelpLink,
	HelpText,
	HelpButton,
	HelpLinkContainer,
	HelpTitle,
};

export default Styled;
