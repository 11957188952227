import { isNil, find } from 'lodash';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { VIEW_INBOX, VIEW_ADMIN_PRE_PAYMENTS } from 'constants/hateoas-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import useHaveUserPermissions from 'hooks/usePermissions/usePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import LoggedInLayout from 'layouts/logged-in';
import { pathInfluencerManagement } from 'routing/PathLookup';

function isAdminOrPM(entities) {
	if (isNil(entities)) {
		return false;
	}

	const roles = ['administrator', 'project_manager'];
	for (const role of roles) {
		if (find(entities, { role })) {
			return true;
		}
	}

	return false;
}

function isBrandManager(entities) {
	if (isNil(entities)) {
		return false;
	}

	const roles = ['brand_manager'];
	for (const role of roles) {
		if (find(entities, { role })) {
			return true;
		}
	}

	return false;
}

const OrganizationLayout = (props) => {
	const permissions = useAppSelector((state) => state.userPermissions);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch({ type: 'CONNECT_TO_MERCURE' });
	}, []);

	const sidebarMenuItems = () => {
		const [isEnabled] = useFeatureToggle();
		const { userCan } = useHaveUserPermissions();

		const showProjectManagerLinks = isAdminOrPM(permissions.entities);
		const isBM = isBrandManager(permissions.entities);
		return [
			{
				url: `/dashboard`,
				icon: 'home',
				title: 'Home',
			},
			{
				url: `/discovery`,
				title: 'Discovery',
				icon: 'search',
				condition: () => showProjectManagerLinks,
			},
			{
				url: pathInfluencerManagement(),
				title: 'Influencer management',
				icon: 'list',
				condition: () => showProjectManagerLinks || isBM,
			},
			{
				url: `/content-management`,
				icon: 'mobile-check',
				title: 'Content management',
			},
			{
				url: '/data-library',
				icon: 'traffic-menu',
				title: 'Data Library',
				condition: () => showProjectManagerLinks,
			},
			{
				url: `/inbox`,
				icon: 'message',
				title: 'Messages',
				condition: () => userCan(VIEW_INBOX),
			},
			{
				key: 'admin',
				url: '/admin/dashboard',
				icon: '',
				title: 'Admin',
				children: [
					{
						url: '/admin/dashboard',
						title: 'Home',
					},
					{
						url: '/admin/payments/unpaid',
						title: 'Unpaid Payments',
						condition: () => userCan(VIEW_ADMIN_PRE_PAYMENTS),
					},
					{
						url: '/admin/payments/paid',
						title: 'Paid Payments',
						condition: () => userCan(VIEW_ADMIN_PRE_PAYMENTS),
					},
					{
						url: '/admin/invoices',
						title: 'Invoices',
						condition: () => userCan(VIEW_ADMIN_PRE_PAYMENTS),
					},
					{
						url: '/admin/payout',
						title: 'Payout',
						condition: () => userCan(VIEW_ADMIN_PRE_PAYMENTS),
					},
				],
				condition: () => permissions.is_super_admin,
			},
		];
	};
	const logo = props.loading && props.data && props.data.publisher && props.data.publisher.logo;

	return (
		<LoggedInLayout sidebarMenuItems={sidebarMenuItems()} logo={logo} logoLoading={props.loading}>
			<Outlet />
		</LoggedInLayout>
	);
};

const OrganizationLayoutWithOrganization = (props) => {
	return <OrganizationLayout {...{ ...props, data: null, loading: false }} />;
};

export default OrganizationLayoutWithOrganization;
