import styled from 'styled-components';

import Grid from 'styles/grid/grid';
import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';
import { breakpoints, mediaQueries } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

import { IErrorProps } from './types';

const HideOnSmallDevice = styled.div`
	@media (max-width: ${breakpoints.lg}) {
		display: none;
	}
`;

const HideOnLargeDevice = styled.div`
	@media (min-width: ${breakpoints.lg}) {
		display: none;
	}
`;

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	margin-top: 5vh;
	padding-bottom: 2.75rem;
`;

const Container = styled.div<IErrorProps>`
	width: 100vw;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: space-between;
	background-color: ${colors.mainWhite};
`;

const GridContainer = styled(Grid.Container)`
	align-items: center;
`;

const Separator = styled.div`
	width: 100%;
	border-top: ${colors.black} solid 1px;
	margin-top: 2.75rem;
	@media (max-width: ${breakpoints.sm}) {
		margin-top: 40px;
	}
`;

const Image = styled.img<IErrorProps>`
	margin: 0 auto;
	margin-bottom: 40px;
	display: block;
	@media (max-width: ${breakpoints.lg}) {
	}
	${(props) =>
		props.mobile
			? `
		@media (max-width: ${breakpoints.lg}) {
			display: none;
		}
		`
			: `
		@media (min-width: ${breakpoints.lg}) {
			display: none;
		}

	`};
`;

const Title = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	line-height: 1.5;

	h2 {
		font-size: 3rem;
		font-weight: 500;
	}
	h5 {
		font-size: 1.4rem !important;
		font-weight: 700;
	}
	@media (max-width: ${breakpoints.sm}) {
		font-size: 2rem;
		line-height: 1.2;
	}
`;

const Description = styled.p`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1.125rem;
	letter-spacing: 0;
	line-height: 1.7;
	margin-top: 30px;
	a {
		color: ${colors.info};
	}
	@media (max-width: ${breakpoints.sm}) {
		font-size: 1rem;
		line-height: 1.45;
	}
`;

const LinkContainer = styled.div`
	display: flex;
	justify-content: center;
	font-size: 1.25rem;
	line-height: 1.4;
	letter-spacing: 0;
	padding-bottom: 4rem;
	a {
		margin: 33px 44px;
		color: ${colors.brandPrimary};
		border-bottom: none;
		@media (max-width: ${breakpoints.sm}) {
			line-height: 0;
		}
	}
	@media (max-width: ${breakpoints.md}) {
		flex-direction: column;
		text-align: center;
	}
`;

const Numbers = styled.div`
	font-size: 1.438rem;
	font-weight: 700;
	font-family: ${typography.BaseFontFamiliy};
`;

const Footer = styled.div`
	width: calc(100% - 48px);
	margin: 0 24px 0;
	border-top: 1px solid ${colors.black1};
	height: 73px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem 0;
	${mediaQueries.medium`
		flex-direction: row;
	`}
	div {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 0.5rem;
		${mediaQueries.medium`
			justify-content: flex-start;
			margin-top: 0;
		`}
	}
	a,
	p {
		font-size: 0.75rem;
		color: ${colors.mainBlack};
		text-decoration: none;
		border-bottom: none !important;
		height: 12px;
		margin-top: 0;
		min-width: 120px;
		${mediaQueries.medium`
			margin: 0 48px 0 0;
		`}
	}
	p {
		${mediaQueries.medium`
			margin-bottom: 5px;
		`}
	}
	${mediaQueries.medium`
		justify-content: initial;
	`}
`;

const TablesWrapper = styled.div`
	padding-bottom: ${spacing[4].rem};
	display: flex;
	flex-direction: column;
	gap: ${spacing[4].px};
`;

const List = styled.ul`
	margin-top: 32px;
	li {
		display: flex;
		align-items: center;
		gap: 16px;
		padding: 0.5rem 0;
		.icon path {
			fill: ${newColors.smoke};
		}
	}
`;

const Styled = {
	Footer,
	Numbers,
	LinkContainer,
	Description,
	Title,
	Image,
	HideOnSmallDevice,
	HideOnLargeDevice,
	Wrapper,
	Container,
	GridContainer,
	Separator,
	TablesWrapper,
	List,
};

export default Styled;
