import styled from 'styled-components';

import colors from 'styles/variables/colors';

const DataToCSV = styled.div`
	display: flex;
	justify-content: flex-end;

	& button {
		white-space: nowrap;
		& a {
			color: ${colors.mainBlack};
		}
		&:hover {
			a {
				color: ${colors.mainWhite};
			}
		}
	}
`;

const Styled = {
	DataToCSV,
};

export default Styled;
