interface DefaultQuery {
	include?: string;
}

class RequestQueryBuilder<T, IncludeType> {
	private params: URLSearchParams;

	private constructor(baseParams: URLSearchParams) {
		this.params = baseParams;
	}

	public withInclude(includeName: IncludeType, hateoas = ['false']): RequestQueryBuilder<T, IncludeType> {
		hateoas.sort(); // to improve SWR cache
		const include = (this.params.get('include') ?? '').split(',');
		include.push(`${includeName}:hateoas(${hateoas.join('|')})`);
		include.sort(); // to improve SWR cache

		// @ts-ignore
		return this.withFilter('include', include.join(','));
	}

	public withFilter(name: keyof T, value: string): RequestQueryBuilder<T, IncludeType> {
		const clonedParams = new URLSearchParams(this.params);
		clonedParams.set(<string>name, value);
		clonedParams.sort(); // to improve SWR cache

		return new RequestQueryBuilder(clonedParams);
	}

	public withoutFilter(name: keyof T): RequestQueryBuilder<T, IncludeType> {
		const clonedParams = new URLSearchParams(this.params);
		clonedParams.delete(<string>name);

		return new RequestQueryBuilder(clonedParams);
	}

	public toQuery(): T {
		const result = {};
		this.params.forEach((value, key) => {
			(<Record<string, string>>result)[key] = value;
		});

		return result as T;
	}

	public toHash(): string {
		return this.toString();
	}

	public toString(): string {
		return this.params.toString();
	}

	public static create<T>(rootHateoas = ['false']): RequestQueryBuilder<T, T extends DefaultQuery ? T['include'] : never> {
		const params = new URLSearchParams({
			include: `:hateoas(${rootHateoas.join('|')})`,
			exclude: 'defaultIncludes',
		});

		return new RequestQueryBuilder<T, T extends DefaultQuery ? T['include'] : never>(params);
	}
}

export default RequestQueryBuilder;
