import Styled from './Pill.styles';

export type Props = {
	title: string;
	className?: string;
};

const Pill = ({ title, className }: Props) => {
	return (
		<Styled.Pill data-testid='pill' className={className}>
			<div>{title}</div>
		</Styled.Pill>
	);
};

export default Pill;
