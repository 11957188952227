import { ChartData, ChartArea, ChartOptions } from 'chart.js';
import 'chart.js/auto'; // eslint-disable-line import/no-unassigned-import
import { useRef, useEffect, useState } from 'react';
import { Line as LineChart } from 'react-chartjs-2';

import { formatNumber } from 'shared/helpers/Chart/chart-util';
import typography from 'styles/variables/typography';

import { ILine } from './types';

/**
 *
 * @param props
 * @returns JSX.Element
 */

const Line = (props: ILine): JSX.Element => {
	const lineRef = useRef();
	const [lineData, setLineDate] = useState<ChartData<'line'>>({
		datasets: [],
	});
	const [backgroundColor, setBackgroundColor] = useState<string | CanvasGradient>('transparent');
	const [isMouseHover, setIsMouseHover] = useState(false);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const chart: any = lineRef.current;
		if (chart) {
			if (!props.isHoverable) {
				setBackgroundColor(createGradient(chart.ctx, chart.chartArea));
			} else {
				if (isMouseHover) {
					setBackgroundColor(createGradient(chart.ctx, chart.chartArea));
				} else {
					setBackgroundColor('transparent');
				}
			}
		}
	}, [isMouseHover]);

	const data = {
		labels: props.labels,
		datasets: props.datasets,
	};

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const chart: any = lineRef.current;

		if (!chart) {
			return;
		}

		if (data.datasets.length > 0) {
			const chartData = {
				...data,
				datasets: data.datasets.map((dataset) => ({
					...dataset,
					backgroundColor: backgroundColor,
					fill: true,
				})),
			};

			setLineDate(chartData);
		}
	}, [props.datasets, backgroundColor]);

	const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea) => {
		const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

		const colorWithOpacity = props.datasets[0]?.borderColor.split('');
		colorWithOpacity.push('60');

		gradient.addColorStop(0, 'white');
		gradient.addColorStop(1, colorWithOpacity.join(''));

		return gradient;
	};

	const options: ChartOptions<'line'> = {
		elements: {
			line: {
				borderWidth: props.borderWidth,
			},
			point: {
				radius: props.pointRadius,
			},
		},
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					font: {
						family: typography.BaseFontFamiliy,
						weight: 'bold',
					},
					callback: function (value: string | number) {
						return formatNumber(value);
					},
				},
			},
			x: {
				ticks: {
					maxTicksLimit: 15,
					font: {
						family: typography.BaseFontFamiliy,
					},
				},
			},
		},
		plugins: {
			datalabels: {
				display: false,
			},
			legend: {
				position: props.legendPosition,
				display: props.legendPosition ? true : false,
			},
			title: {
				display: false,
			},
		},
	};

	return (
		<LineChart
			height={props.chartHeight}
			onMouseEnter={() => setIsMouseHover(true)}
			onMouseLeave={() => setIsMouseHover(false)}
			ref={lineRef}
			data={lineData}
			options={options}
		/>
	);
};

export default Line;
