import { Model, Store } from 'json-api-models';

import { Network } from 'constants/socialMedia';
import DiscoveryService from 'services/DiscoveryApi';

import { CombinedInfluencerExtraData, FilterType } from './types';

export const getGender = (gender: string) => {
	switch (gender) {
		case 'female':
			return 'f';
		case 'male':
			return 'm';
		default:
			return '';
	}
};

const models = new Store();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapResult = (res: any) => {
	const result: {
		influencers: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
		totalResult: { totalResultCount: number | null; executionTimeMs: number | null } | null;
	} = {
		influencers: [],
		totalResult: null,
	};

	result.totalResult = res.data.attributes;
	models.sync(res);
	result.influencers = models.find('searchResult', res.data.id).influencers.map((influencer: Model) => {
		return {
			id: influencer.id,
			followersCount: influencer.followersCount,
			network: influencer.network,
			networkId: influencer.networkId,
			networkLinks: influencer.network_links,
			profileImageUrl: influencer.profileImageUrl,
			username: influencer.username,
			engagement: influencer.engagement,
			audienceBrief: influencer.audience,
			links: influencer.links,
			country: influencer.country,
		};
	});

	return result;
};

export const getOneInfluencerExtraData = async (influencerId: string) => {
	const models = new Store();
	let result: CombinedInfluencerExtraData = {} as CombinedInfluencerExtraData;
	await DiscoveryService.getInfluencerExtraData(influencerId)
		.then((res) => {
			models.sync(res);
			const influencer = models.find('influencer', influencerId);
			result = {
				id: influencer.id,
				username: influencer.username,
				profileImageUrl: influencer.profileImageUrl,
				followersCount: influencer.followersCount,
				network: influencer.network,
				networkId: influencer.networkId,
				audienceBrief: {
					age: influencer.audience.age,
					country: influencer.audience.country,
					gender: influencer.audience.gender,
				},
				networkLinks: influencer.network_links,
				engagement: influencer.engagement,
				age: influencer.extra.age,
				country: influencer.country ?? influencer.extra.country,
				gender: influencer.extra.gender,
				averageViews: influencer.extra.averageViews,
				audienceDetail: influencer.extra.audience,
				brandAffiliations: influencer.extra.brandAffiliations,
				engagementOrganic: influencer.extra.engagementOrganic,
				engagementPaid: influencer.extra.engagementPaid,
				paidPostsRatio: influencer.extra.paidPostsRatio,
				estimatedStoryViews: influencer.extra.estimatedStoryViews,
				related: influencer.related,
			} as CombinedInfluencerExtraData;
		})
		.catch((err) => {
			console.error(err);
		});
	return result;
};

export const numberOrNull = (value: string | null): number | null => {
	return value ? Number(value) : null;
};

export const formatFilter = (filter: FilterType) => {
	if (Object.values(filter).some((value) => value)) {
		return (
			Object.entries(filter)
				// eslint-disable-next-line unused-imports/no-unused-vars
				.filter(([_key, value]) => value)
				.map(([key, value]) => {
					if (key === 'minEI' || key === 'maxEI') {
						return `${key}=${(value as number) / 100}`;
					} else {
						return `${key}=${value}`;
					}
				})
				.join('&')
		);
	} else return null;
};

export const defaultFilter = {
	minAge: null,
	maxAge: null,
	genders: null,
	countries: null,
	minFollowers: null,
	maxFollowers: null,
	minEI: null,
	maxEI: null,
	brandAffiliations: null,
	excludedBrandAffiliations: null,
	hashtags: null,
	audienceAgeRanges: null,
	audienceGenders: null,
	audienceCountries: null,
	networks: Network.INSTAGRAM,
};

export const buildUrlQuery = (filter: FilterType, searchText: string | null): string => {
	const params = new URLSearchParams();

	if (searchText) params.append('q', searchText);

	Object.entries(filter).forEach(([key, value]) => {
		if (value) params.append(key, value);
	});

	return `?${params.toString()}`;
};

export const getReason = (url: string) => {
	return DiscoveryService.getInstagramPostAndDate(url);
};
