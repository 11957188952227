import { CountModel } from 'api-models';
import { CampaignTotalQuery, ReachDailyQuery, ReachTotalQuery } from 'api-queries';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import { ByAssignmentTypeOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByAssignmentType/types';
import { ByCampaignOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByCampaign/types';
import { OverTimeOutput } from 'components/DataLibrary/Graphs/GraphComponents/OverTime/types';
import { TotalOutput } from 'components/DataLibrary/Graphs/GraphComponents/Total/types';
import { INSTAGRAM_POST, INSTAGRAM_REEL, INSTAGRAM_STORY, TIKTOK_VIDEO } from 'constants/data-library.ts';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

export const getReachTotal = (manager: StatisticsManager, filter: DashboardFilters): { result: TotalOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<ReachTotalQuery>(filter);
	const { result, error } = manager.showReachTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = {
		value: result.attributes.count,
		currency: undefined,
	};

	return { result: output };
};

export const getReachTotalByPlatforms = (
	manager: StatisticsManager,
	filter: DashboardFilters,
): { result: ByAssignmentTypeOutput | undefined; error?: Error } => {
	const results = new Map<string, CountModel | undefined>();
	const errors = new Map<string, Error | undefined>();

	for (const type of [INSTAGRAM_POST, INSTAGRAM_STORY, INSTAGRAM_REEL, TIKTOK_VIDEO]) {
		const qb = convertFilterToQueryBuilder<ReachTotalQuery>(filter).withFilter('assignmentTypes', type);
		const { result, error } = manager.showReachTotal(qb);

		results.set(type, result);
		errors.set(type, error);
	}

	for (const [type, error] of errors) {
		if (results.get(type) === undefined || error) {
			return { result: undefined, error };
		}
	}

	const output: ByAssignmentTypeOutput = {
		total: 0,
		types: new Map<string, { label: string; value: number }>(),
	};

	for (const [type, result] of results) {
		output.total += result?.attributes.count || 0;
		output.types.set(type, {
			label: type,
			value: result?.attributes.count || 0,
		});
	}

	return { result: output };
};

export const getReachCounts = (manager: StatisticsManager, filter: DashboardFilters): { result: OverTimeOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<ReachDailyQuery>(filter);
	const { result, error } = manager.listReachDaily(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const output = result.map((node) => {
		return {
			date: node.attributes.date,
			count: node.attributes.count,
		};
	});

	return { result: output };
};

export const getReachAndEngagementPerCampaign = (
	manager: StatisticsManager,
	filter: DashboardFilters,
): { result: ByCampaignOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<CampaignTotalQuery>(filter);
	const { result, error } = manager.listCampaignTotal(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const campaigns = [];
	for (const country of result) {
		if (country.attributes.reach > 0) {
			campaigns.push({
				name: country.attributes.name ?? '[no name]',
				value: country.attributes.reach,
				valueLabel: 'Total Reach',
				secondValue: country.attributes.engagement,
				secondValueLabel: 'Engagement',
			});
		}
	}

	const output = {
		campaigns: campaigns,
	};

	return { result: output };
};
