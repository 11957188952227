import { useNavigate } from 'react-router-dom';

import CollabsLogo from 'assets/img/logo/collabs-logo-dark.svg';
import Icon from 'components/Icon';
import OnboardingContainer from 'components/Onboarding';

import Styled from './OnboardingView.style';

const OnboardingView = () => {
	const navigate = useNavigate();

	return (
		<Styled.Wrapper>
			<Styled.InnerWrapper>
				<Styled.Header>
					<Styled.LogoWrapper>
						<img src={CollabsLogo} alt='Collabs' />
					</Styled.LogoWrapper>
					<div>
						<Styled.CloseIconButton
							onClick={() => {
								navigate('/influencer/dashboard');
							}}
						>
							<Icon name='cancel-circle' size='20' />
						</Styled.CloseIconButton>
					</div>
				</Styled.Header>

				<OnboardingContainer />
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default OnboardingView;
