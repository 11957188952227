import Skeleton from 'react-loading-skeleton';
// eslint-disable-next-line import/no-unassigned-import
import 'react-loading-skeleton/dist/skeleton.css';

import Styled from './SkeletonAvatarWithMessage.style';

const SkeletonAvatarWithMessage = () => {
	return (
		<Styled.Wrapper>
			<Skeleton circle height='40px' width='40px' />
			<div className='w-60'>
				<div className='w-40'>
					<Skeleton height='10px' containerClassName='flex-1' />
				</div>
				<Skeleton height='15px' containerClassName='flex-1' />
			</div>
		</Styled.Wrapper>
	);
};
export default SkeletonAvatarWithMessage;
