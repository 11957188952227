import { inject, injectable } from 'inversify';

import ApiClientService from 'services/ApiClient/ServiceIdentifier';

import type ToolsApiClientInterface from 'services/ApiClient/ToolsApiClientInterface';

@injectable()
export default class ToolsService {
	constructor(@inject(ApiClientService.ToolsApiClientInterface) private apiClient: ToolsApiClientInterface) {}

	async log(message: string, data: Record<string, string> = {}): Promise<void> {
		const payload = {
			message: message,
			context: data,
		};

		// @ts-ignore
		await this.apiClient.log(payload, { include: ':hateoas(false)' });
	}
}
