import { Outlet } from 'react-router-dom';

const EmptyLayout = () => {
	return (
		<div style={{ minHeight: '100vh' }}>
			<div>
				<Outlet />
			</div>
		</div>
	);
};
export default EmptyLayout;
