import { css } from 'styled-components';

import colors from 'styles/variables/colors';

import { TextareaType } from './types';

export const TextareaField = css<TextareaType>`
	display: block;
	width: 100%;
	overflow: hidden;
	resize: both;
	line-height: 1.5;
	border: none;
	background-color: ${({ theme }) => theme.InputField.background};
	padding: 0;
	height: 0;
	transition:
		opacity 0.2s ease-in-out,
		min-height 0.2s ease-in-out,
		height 0.2s ease-in-out;
	outline: none;
	resize: none;
	&:focus:not(:disabled) {
		box-shadow: 0 0 3px 0 ${colors.info};
	}
`;

export const TextareaFieldContainer = css``;

export const InputFieldContainer = css<TextareaType>`
	cursor: ${(props) => (!props.disabled ? 'pointer' : 'default')};
	position: relative;

	&.selected {
		.textarea__heading {
			color: ${({ theme }) => theme.textarea.selected.headingColor};
		}

		.textarea__field {
			opacity: 1;
			height: 3rem;
		}
	}
`;
