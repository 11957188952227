import classNames from 'classnames';
import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react';

import Styled from './Input.style';
import { InputFieldProps } from './types';

const Input = forwardRef<HTMLInputElement, InputFieldProps>(
	({ scale = 'medium', isWarning, isError, disabled, readOnly, onFocus, onBlur, ...props }, externalRef) => {
		const [isFocused, setIsFocused] = useState(false);
		const internalRef = useRef<HTMLInputElement>(null);

		useImperativeHandle(externalRef, () => internalRef && internalRef.current!, [internalRef]);

		const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(true);
			onFocus?.(e);
		};

		const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(false);
			onBlur?.(e);
		};

		const handleContainerClick = () => {
			internalRef.current?.focus();
		};

		return (
			<Styled.InputContainer
				className={classNames(
					{
						'has-warning': isWarning,
						'has-error': isError,
						disabled: disabled,
						readonly: readOnly,
						'has-focus': isFocused,
					},
					'input-container',
				)}
				scale={scale}
				onClick={handleContainerClick}
			>
				{props.contentBefore && <Styled.ContentBefore>{props.contentBefore}</Styled.ContentBefore>}
				<Styled.Input
					data-testid='input-field'
					scale={scale}
					onFocus={handleFocus}
					onBlur={handleBlur}
					disabled={disabled}
					readOnly={readOnly}
					ref={internalRef}
					{...props}
				/>
				{props.contentAfter && <Styled.ContentAfter>{props.contentAfter}</Styled.ContentAfter>}
			</Styled.InputContainer>
		);
	},
);

export default Input;
