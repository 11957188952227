import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import colors from 'styles/variables/colors';

import type { ChartData, ChartOptions } from 'chart.js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CombinedBarWithLineChart = (props: { graphData: any }) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [data, setData] = useState<any>({
		datasets: [
			{
				labels: 'Unknown',
				data: [0],
			},
			{
				labels: 'Unknown',
				data: [0],
			},
		],
		labels: ['Unknown'],
	});

	const options = {
		plugins: {
			datalabels: {
				display: false,
			},
		},
		responsive: true,
		scales: {
			A: {
				type: 'linear',
				position: 'left',
				ticks: { beginAtZero: true },
				grid: { display: false },
			},
			B: {
				type: 'linear',
				position: 'right',
				ticks: { beginAtZero: true },
				grid: { display: false },
			},
		},
	};

	useEffect(() => {
		if (props.graphData?.length > 0) {
			const labels: Array<string> = [];
			const valueData: Array<number> = [];
			const valueDataLabel = props.graphData[0].valueLabel;
			const secondValueData: Array<number> = [];
			const secondValueDataLabel = props.graphData[0].secondValueLabel;

			props.graphData.forEach(
				(influencer: { instagramUsername: string; value: number; secondValue: number; firstLineValue: number; secondLineValue: number }) => {
					labels.push(influencer.instagramUsername);
					valueData.push(influencer.value);
					secondValueData.push(influencer.secondValue);
				},
			);

			setData({
				datasets: [
					{
						label: valueDataLabel,
						data: valueData,
						type: 'bar',
						backgroundColor: colors.dataLibrary.charts.color1,
						order: 1,
						yAxisID: 'A',
					},
					{
						label: secondValueDataLabel,
						data: secondValueData,
						type: 'bar',
						backgroundColor: colors.dataLibrary.charts.color2,
						order: 2,
						yAxisID: 'A',
					},
				],
				labels: labels,
			});
		}
	}, [props.graphData]);

	useEffect(() => {
		return () => {};
	}, []);

	return <Bar data={data as ChartData<'bar'>} width={200} height={200} options={options as ChartOptions<'bar'>} />;
};

export default CombinedBarWithLineChart;
