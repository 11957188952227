import styled from 'styled-components';

import Accordion from 'components/Accordion';
import colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

interface IPromoCodesProps {
	backgroundColor?: string;
}

const PromoCodesSection = styled.div<IPromoCodesProps>`
	margin: 24px 16px;
	background-color: ${(props) => props.backgroundColor || colors.transparent};
	border-radius: 4px;
	cursor: pointer;
`;

const PromoCodesTitle = styled.div`
	padding: 1rem 1rem 0;
	display: flex;
	align-items: center;
	span {
		font-weight: 700;
		margin-left: 8px;
	}
`;

const PromoCodeAccordion = styled(Accordion)`
	padding: 0.32rem 1rem;
	.accordion__header {
		width: 100%;
	}
	.accordion__title {
		font-family: ${typography.BaseFontFamiliy};
		font-weight: 400;
	}
	.accordion__body {
		width: 100%;
	}
	ul {
		width: 100%;
	}
	li {
		width: 100%;
		padding: 0.2rem 0.5rem;
		border-radius: 4px;
		cursor: pointer;
		height: 35px;
		&:hover {
			background-color: ${colors.mainBlack};
			color: ${colors.white};
			.icon path {
				fill: ${colors.white};
			}
		}
		div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 3px;
		}
	}
`;

export const Styled = {
	PromoCodeAccordion,
	PromoCodesSection,
	PromoCodesTitle,
};
