import { useState, useEffect } from 'react';

import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input/Input';
import Textarea from 'components/Forms/Textarea';

import Styled from './BlastChatInput.style';

/**
 * @todo test
 */
const BlastChatInput = (props: { getMessage: (subject: string | null, message: string) => void }) => {
	const [message, setMessage] = useState<string>('');
	const [subject, setSubject] = useState<string | null>(null);

	useEffect(() => {
		props.getMessage(subject, message);
	}, [message, subject]);

	return (
		<Styled.MessageWrapper>
			<Field label='Subject'>
				<Input placeholder='Optional' onChange={(e) => setSubject(e.target.value)} value={subject ?? undefined} data-testid='blast-message-subject' />
			</Field>
			<Field label='Message'>
				<Textarea
					rows={16}
					onChange={(e) => setMessage(e.target.value)}
					value={message}
					data-testid='blast-message-textarea'
					placeholder='Write your message here'
				/>
			</Field>
		</Styled.MessageWrapper>
	);
};

export default BlastChatInput;
