import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgMessageFilled(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M3.81488 19C3.49488 19 3.19488 18.83 3.03488 18.55C2.84488 18.21 2.88488 17.79 3.14488 17.49C3.68488 16.89 3.96488 16.16 4.11488 15.61H0.904883C0.404883 15.61 0.00488281 15.2 0.00488281 14.71V1.9C0.00488281 1.4 0.414883 1 0.904883 1H19.0949C19.5949 1 19.9949 1.41 19.9949 1.9V14.71C19.9949 15.21 19.5849 15.61 19.0949 15.61H8.73488C7.57488 18.03 5.04488 18.77 3.98488 18.98C3.92488 18.99 3.86488 19 3.81488 19Z'
				fill='#333333'
			/>
		</svg>
	);
}
