import { countries } from 'country-data';

export const getGenderLabel = (gender: string) => {
	switch (gender) {
		case 'female':
			return 'Woman';
		case 'male':
			return 'Man';
		default:
			return '';
	}
};

const getGender = (gender: string) => {
	switch (gender) {
		case 'female':
			return 'f';
		case 'male':
			return 'm';
		default:
			return '';
	}
};

export const getGenders = (value: { [key: string]: boolean } | null) => {
	if (value) {
		return Object.keys(value)
			.filter((genderKey) => genderKey !== 'unknown')
			.filter((genderKey) => value[genderKey])
			.map((gender) => {
				return getGender(gender);
			})
			.join(',');
	} else {
		return null;
	}
};

const rawCountriesData = countries.all;

export const getTheCountryData = () => {
	const availableCountries = ['SWE', 'NOR', 'DNK', 'FIN', 'NLD', 'GRC', 'ESP', 'PRT', 'FRA'];
	const countryData: { [key: string]: { name: string; selected: boolean; show: boolean } } = {};

	rawCountriesData.forEach((country) => {
		if (country.alpha3 !== '' && availableCountries.includes(country.alpha3)) {
			const name = country.name;
			const code = country.alpha3;
			countryData[code] = { name: name, selected: false, show: true };
		}
	});
	return countryData;
};

export const getObjectKeys = (value: { [key: string]: boolean }) => {
	return Object.keys(value)
		.filter((key) => value[key])
		.join(',');
};

export const updateAudienceAgeRanges = (ages: string[]) => {
	const defaultAges = {
		'13-17': false,
		'18-24': false,
		'25-34': false,
		'35-44': false,
		'45-54': false,
		'55-64': false,
		'65+': false,
	};

	ages.forEach((age: string) => {
		if (age === '65') {
			defaultAges['65+'] = true;
		} else {
			defaultAges[age] = true;
		}
	});

	return defaultAges;
};
