import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import Icon from 'components/Icon';

import Styled from './InfoBox.style';

export type InfoBoxProps = {
	setDisplayInfo: (display: boolean) => void;
	displayInfo: boolean;
	text: string;
	arrowPosition: string;
};
/**
 * InfoBox
 * Arrow can be moved with css right props
 * @returns {JSX.Element}
 */
const InfoBox = ({ setDisplayInfo, displayInfo, text, arrowPosition }: InfoBoxProps): JSX.Element => {
	const InfoIcon = useRef<HTMLDivElement>(null);
	const InfoBox = useRef<HTMLDivElement>(null);

	const handleClick = (e: MouseEvent) => {
		if (InfoIcon.current && InfoIcon.current.contains(e.target as Node)) {
			return;
		}
		if (InfoBox.current && InfoBox.current.contains(e.target as Node)) {
			return;
		}
		setDisplayInfo(false);
	};

	const handleEscapeKey = (e: KeyboardEvent): void => {
		if (e.key === 'Escape') {
			setDisplayInfo(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		document.addEventListener('keydown', handleEscapeKey);

		return () => {
			document.removeEventListener('mousedown', handleClick);
			document.removeEventListener('keydown', handleEscapeKey);
			setDisplayInfo(false);
		};
	}, []);

	return (
		<Styled.InfoBoxWrapper data-testid='info-box-wrapper'>
			<div onClick={() => setDisplayInfo(!displayInfo)} ref={InfoIcon} data-testid='info-icon'>
				<Icon name='info-circle' size='14' />
			</div>
			<Styled.InfoBox className={classNames({ display: displayInfo })} ref={InfoBox} data-testid='info-box'>
				<Styled.Close onClick={() => setDisplayInfo(false)} data-testid='close-button'>
					Close <Icon name='circle-cross' size='14' />
				</Styled.Close>
				<Styled.Arrow right={arrowPosition} />
				<p data-testid='info-text'>{text}</p>
			</Styled.InfoBox>
		</Styled.InfoBoxWrapper>
	);
};

export default InfoBox;
