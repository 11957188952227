import { useEffect, useState } from 'react';

import Icon from 'components/Icon';

import { Styled } from './PromoCodes.style';

/**
 * Campaign codes component.
 * @returns {JSX.Element}
 */
const PromoCodes = (props: { promoCodes: string[]; backgroundColor?: string }): JSX.Element => {
	const { promoCodes, backgroundColor } = props;
	const [copied, setCopied] = useState<number | null>(null);
	const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

	useEffect(() => {
		return () => {
			setCopied(null);
			setAccordionOpen(false);
		};
	}, []);

	return (
		<Styled.PromoCodesSection backgroundColor={backgroundColor} onClick={() => setAccordionOpen(!accordionOpen)}>
			<Styled.PromoCodesTitle>
				<Icon name='coupon' />
				<span>{promoCodes?.length > 1 ? 'Campaign codes' : 'Campaign code'}</span>
			</Styled.PromoCodesTitle>
			<Styled.PromoCodeAccordion
				open={accordionOpen}
				className='promo-codes'
				title={promoCodes?.length > 1 ? `${promoCodes?.length} codes` : `${promoCodes?.length} code`}
			>
				<ul>
					{promoCodes?.map((code: string, i: number) => {
						return (
							<li
								key={i}
								onClick={async (e) => {
									e.preventDefault();
									try {
										await navigator.clipboard.writeText(code);
										setCopied(i);
										setAccordionOpen(true);
										setTimeout(() => setCopied(null), 3000);
									} catch (e) {
										console.error('Unable to access clipboard: %O', e);
									}
								}}
							>
								<div>
									{code} {copied === i ? <span>Copied!</span> : <Icon name='copy' />}
								</div>
							</li>
						);
					})}
				</ul>
			</Styled.PromoCodeAccordion>
		</Styled.PromoCodesSection>
	);
};

export default PromoCodes;
