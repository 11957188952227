import { JsonApiDocument, Model, Store } from 'json-api-models';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { InfluencerListListModel } from 'api-models';
import MessageModal from 'components/Discovery/Components/MessageModal';
import PageNotFound from 'components/ErrorPages/PageNotFound';
import { ListContent } from 'components/Lists/Components';
import LoadingSpinner from 'components/LoadingSpinner';
import DiscoveryContext from 'contexts/Discovery/DiscoveryContext';
import ListsService from 'services/Lists';
import { ICollabsResponse } from 'services/Response.types';

import Styled from './InfluencerListContainer.style';

type InfluencerListContainer = {
	setListToShare: (list: InfluencerListListModel | null) => void;
	setSidebarIsOpen: (isOpen: boolean) => void;
	refetch?: boolean;
	setRefetch?: (refetch: boolean) => void;
};

const InfluencerListContainer = ({ setListToShare, setSidebarIsOpen, refetch, setRefetch }: InfluencerListContainer) => {
	const [openList, setOpenList] = useState<{ list: Model; listName: string; listItems: Model[]; isFetching: boolean; error: Error | null }>({
		list: {} as Model,
		listName: '',
		listItems: [],
		isFetching: true,
		error: null,
	});
	const [isLoading, setIsLoading] = useState(true);

	const navigate = useNavigate();
	const params = useParams();

	const fetchData = (isRefetch?: boolean) => {
		if (isRefetch) {
			return (listId: string) => updateParentList(listId);
		} else {
			setIsLoading(true);
			return (listId: string) => updateParentList(listId);
		}
	};

	const updateParentList = async (listId: string) => {
		const influencerModels = new Store();
		await ListsService.getList(listId, 'users,privileges,privileges.user')
			// @ts-ignore
			.then((listData: ICollabsResponse) => {
				if (listData) {
					influencerModels.sync(listData as JsonApiDocument);
				}
				setOpenList({
					list: influencerModels.find('list', listId),
					listName: listData.data.attributes.name,
					listItems: influencerModels.findAll('listItem'),
					isFetching: false,
					error: null,
				});
			})
			.catch((e: Error) => {
				console.error(e);
				setOpenList((state) => ({
					...state,
					error: e,
				}));
			})
			.finally(() => {
				setIsLoading(false);
				setRefetch && setRefetch(false);
			});
	};

	const { closeMessageModal, isMessageModalOpen } = useContext(DiscoveryContext);

	useEffect(() => {
		refetch && fetchData(true)(params.listId!);
	}, [refetch]);

	useEffect(() => {
		if (params.listId) {
			fetchData()(params.listId);
		}
	}, [params.listId]);

	if (openList.error) {
		return <PageNotFound />;
	}

	return (
		<Styled.Wrapper>
			{isLoading ? (
				<LoadingSpinner position='center' />
			) : (
				<>
					<ListContent
						setListToShare={setListToShare}
						setSidebarIsOpen={setSidebarIsOpen}
						list={openList.list}
						listItems={openList.listItems}
						listName={openList.listName}
						onClickBack={() => {
							navigate(-1);
						}}
						onRefetch={() => {
							if (params.listId) {
								fetchData(true)(params.listId);
							}
						}}
					/>
					<MessageModal open={isMessageModalOpen} onClose={closeMessageModal} isList />
				</>
			)}
		</Styled.Wrapper>
	);
};

export default InfluencerListContainer;
