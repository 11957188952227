import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import HeaderDropdown from 'components/Header/Components/HeaderDropdown';
import Icon from 'components/Icon';
import InfluencerAvatar from 'components/InfluencerAvatar';
import UserAvatar from 'components/Settings/MyAccount/UserAvatar';
import { ApiStatus, Network } from 'constants/socialMedia';
import useInjection from 'hooks/useInjection';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { setInfluencer } from 'reducers/UserReducers/UserSlice';
import { InfluencerType } from 'reducers/UserReducers/types';
import LogoutService from 'services/Authentication/Collabs-api/LogoutService';
import { getInitials } from 'shared/User/User.helpers';

import BlankState from './Components/BlankState';
import Styled from './UserMenu.style';
import { getSelectedProfileId, storeSelectedProfileId } from './Utils';
/**
 * User Menu
 * @returns {JSX.Element}
 */
const UserMenu = (): JSX.Element => {
	const userDispatch = useAppDispatch();
	const [userDropdownOpen, setUserDropdownOpen] = useState<boolean>(false);

	const user = useAppSelector((state) => state.user);
	const isInfluencer = user.permissions.isInfluencer;
	const [pulse, setPulse] = useState(true);

	const activateSelector = user.influencers?.length > 1;

	const navigate = useNavigate();
	const logoutService = useInjection<LogoutService>(LogoutService);

	const logOut = async () => {
		await logoutService.logout(userDispatch);
		navigate('/login');
	};

	// Update selected influencer in state
	const changeProfile = (influencer: InfluencerType) => {
		userDispatch(setInfluencer(influencer));
		storeSelectedProfileId(influencer.id);
		setUserDropdownOpen(false);
	};

	const setProfileById = () => {
		const influencerId: string | null = getSelectedProfileId();
		const selectedProfile = user.influencers.find((x) => x.id === influencerId);
		let influncerToStore = selectedProfile;
		if (!selectedProfile) {
			influncerToStore = user.influencer;
		}

		if (influncerToStore) {
			userDispatch(setInfluencer(influncerToStore));
			storeSelectedProfileId(influncerToStore.id);
		}
	};

	useEffect(() => {
		setProfileById();
		// Show pulse on login if user has accounts not connected.
		if (user.influencers.filter((influencer: InfluencerType) => influencer.apiStatus !== ApiStatus.CONNECTED).length > 0) {
			setPulse(true);
			setTimeout(() => {
				setPulse(false);
			}, 3000);
		}
	}, []);

	return (
		<>
			<HeaderDropdown
				isUserMenu
				actionButtonIcon={
					user.influencer.id ? (
						<InfluencerAvatar
							scale='md'
							userName={user.influencer.username}
							network={user.influencer.network as Network}
							displayNetwork
							profileImageUrl={user.influencer.links?.profilePictureUrl ?? ''}
							pulse={pulse}
							inHeader
						/>
					) : (
						<span data-testid='initials'>{getInitials(user.name || '')}</span>
					)
				}
				userDropdownOpen={userDropdownOpen}
				setUserDropdownOpen={setUserDropdownOpen}
			>
				<>
					<Styled.AccountMenu>
						<div data-testid='profiles-menu'>
							{!isInfluencer && (
								<Styled.UserAvatarWrapper>
									<UserAvatar fullName={user.name} displayRole />
								</Styled.UserAvatarWrapper>
							)}
							{isInfluencer && user.influencers?.length > 0
								? user.influencers.map((influencer: InfluencerType) => {
										return (
											<Styled.ProfileItem
												data-testid={`select-influencer-${influencer.id}`}
												key={influencer.id}
												data-id={influencer.id}
												className={classNames({ selected: influencer.id === user.influencer.id && activateSelector, pointer: activateSelector })}
												onClick={() => {
													activateSelector ? changeProfile(influencer) : {};
												}}
											>
												<Styled.SocialProfile>
													<InfluencerAvatar
														userName={influencer.username}
														network={influencer.network as Network}
														displayNetwork
														profileImageUrl={influencer.links?.profilePictureUrl ?? ''}
													/>
													<Styled.NameWrapper>
														<Styled.UserName>
															{influencer.username}
															{influencer.apiStatus === ApiStatus.CONNECTED && <Icon name='check-circle' size='18' />}
														</Styled.UserName>
														<Styled.ConnectionInfo>
															{influencer.apiStatus !== ApiStatus.CONNECTED && (
																<>
																	<Styled.NotConnected>Not connected</Styled.NotConnected>
																	<Link
																		to={influencer.network === Network.TIKTOK ? '/influencer/settings/tiktok' : '/influencer/settings/instagram'}
																		onClick={() => setUserDropdownOpen(false)}
																	>
																		Connect now
																	</Link>
																</>
															)}
														</Styled.ConnectionInfo>
													</Styled.NameWrapper>
												</Styled.SocialProfile>
											</Styled.ProfileItem>
										);
									})
								: isInfluencer && <BlankState to='/influencer/settings/instagram' onClick={() => setUserDropdownOpen(false)} />}
						</div>
					</Styled.AccountMenu>

					<Styled.ActionDropDownLinks>
						{isInfluencer && (
							<Styled.ActionDropdownMenuItem id='add-profile'>
								<Styled.MenuLink data-testid='add-social-account' to='/influencer/settings/profiles' onClick={() => setUserDropdownOpen(false)}>
									<Icon name='add-account' size='20' /> <span>Add social account</span>
								</Styled.MenuLink>
							</Styled.ActionDropdownMenuItem>
						)}
						<Styled.ActionDropdownMenuItem>
							<Styled.MenuLink
								data-testid='account-settings-link'
								to={isInfluencer ? '/influencer/settings/account' : '/settings/account'}
								onClick={() => setUserDropdownOpen(false)}
							>
								<Icon name='settings' size='20' /> <span>Go to settings</span>
							</Styled.MenuLink>
						</Styled.ActionDropdownMenuItem>

						<Styled.ActionDropdownMenuItem noBorder>
							<Styled.MenuLink data-testid='sign-out-link' to='' onClick={() => logOut()}>
								<span>Sign out</span>
							</Styled.MenuLink>
						</Styled.ActionDropdownMenuItem>
					</Styled.ActionDropDownLinks>
				</>
			</HeaderDropdown>
		</>
	);
};

export default UserMenu;
