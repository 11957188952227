import styled from 'styled-components';

import Modal from 'components/Modals/Modal';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { rem } from 'styles/variables/spacings';

const ImageWrapper = styled.div`
	img {
		display: block;
		margin: 0 auto;
		width: auto;
		height: auto;
		border-radius: 10px;
		background-size: cover;
		max-height: 80vh;
	}
`;

const CustomModal = styled(Modal)`
	display: flex;

	button {
		background: transparent;
		margin-top: -35px;
		display: inline-flex;
		justify-content: flex-end;
		align-items: end;
		color: ${colors.mainWhite};
		font-size: 0.75rem;
		margin-left: auto;
		border: 0;
		max-width: 75px;
		cursor: pointer;
	}
	.icon {
		margin-left: 8px;
		path {
			fill: ${colors.mainWhite};
		}
	}
	span {
		margin-bottom: 5px;
	}

	div {
		margin: auto;
		max-height: unset;
		width: fit-content;
		border-radius: 10px;
		border: none;
		justify-content: center;
	}

	> div:first-child {
		padding: 0 ${rem[2]}rem;
		max-width: 100%;
	}

	@media screen and (${devices.lg}) {
		> div:first-child {
			max-width: 80%;
		}
	}

	${ImageWrapper} {
		margin-top: 12px;
	}
`;

const ModalHeader = styled.div`
	display: flex;

	button {
		background: none;
		border: none;
		padding: 0;
		margin-left: auto;
		cursor: pointer;
	}
`;

const FullSizeImage = styled.img`
	width: 100%;
	background-size: cover;
	&.full-size-image {
		object-fit: unset;
	}
`;

const Iframe = styled.iframe`
	border: none;
	width: 100%;
	height: 100%;
	margin-top: 12px;
	border-radius: 8px;
	border-radius: 10px;
`;

const Styled = {
	CustomModal,
	ImageWrapper,
	FullSizeImage,
	ModalHeader,
	Iframe,
};

export default Styled;
