import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css'; // eslint-disable-line import/no-unassigned-import

import useWindowDimensions from 'hooks/useWindowDimensions';
import { breakpoints } from 'styles/variables/media-queries';

import './calendar.scss'; // eslint-disable-line import/no-unassigned-import

type CalendarProps = {
	disabled?: boolean;
	placeholder?: string;
	onSelectDateRange: (value: DateRange) => void;
	onSelectDate: (value?: DateRange) => void;
	oneTap: boolean;
	defaultValue?: DateRange | null;
	maxDate: Date;
	minDate: Date;
};

/**
 * Calendar component
 * @param {CalendarProps} props
 * @return {JSX.Element}
 */
const DateRangeCalendar = ({ disabled, placeholder, onSelectDateRange, onSelectDate, oneTap, defaultValue, maxDate, minDate }: CalendarProps): JSX.Element => {
	const { width } = useWindowDimensions();
	const isMobileView = width <= +breakpoints.lg.split('px')[0];
	const { allowedRange } = DateRangePicker;

	return (
		<DateRangePicker
			data-testid='calendar'
			value={defaultValue ? defaultValue : undefined}
			showOneCalendar={isMobileView}
			disabled={disabled}
			placeholder={placeholder}
			oneTap={oneTap}
			cleanable
			editable
			isoWeek
			size='lg'
			onOk={(value) => onSelectDateRange(value)}
			onChange={(value) => value && onSelectDate(value)}
			onClean={() => onSelectDate()}
			shouldDisableDate={allowedRange && allowedRange(minDate, maxDate)}
		/>
	);
};

export default DateRangeCalendar;
