import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgClick(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps} fill='none'>
			<rect width='0.365136' height='1.5258' transform='matrix(0.534346 -0.845266 0.884563 0.466421 4.5293 4.604)' fill='#333333' />
			<rect width='0.365136' height='1.92658' transform='matrix(0.534346 -0.845266 0.884563 0.466421 4.29492 3.65869)' fill='#333333' />
			<rect width='0.365136' height='2.3768' transform='matrix(0.534346 -0.845266 0.884563 0.466421 4.18555 2.77783)' fill='#333333' />
			<rect width='0.365136' height='2.07338' transform='matrix(0.534346 -0.845266 0.884563 0.466421 4.5752 2.16016)' fill='#333333' />
			<rect width='0.365136' height='1.08791' transform='matrix(0.534346 -0.845266 0.884563 0.466421 8.31641 4.13281)' fill='#333333' />
			<rect width='0.365136' height='1.73289' transform='matrix(0.534346 -0.845266 0.884563 0.466421 8.13574 3.21436)' fill='#333333' />
			<rect width='0.365136' height='2.14949' transform='matrix(0.534346 -0.845266 0.884563 0.466421 4.96582 1.54297)' fill='#333333' />
			<rect width='0.365136' height='4.71059' transform='matrix(0.534346 -0.845266 0.884563 0.466421 5.62305 1.06738)' fill='#333333' />
			<rect width='0.365136' height='3.15576' transform='matrix(0.534346 -0.845266 0.884563 0.466421 6.68652 0.804688)' fill='#333333' />
			<path
				d='M8.13945 6.22124L7.84945 5.39788C8.74945 5.08044 9.35945 4.22733 9.35945 3.27502C9.35945 2.03502 8.33945 1.02319 7.08945 1.02319C5.83945 1.02319 4.81945 2.03502 4.81945 3.27502C4.81945 4.21741 5.41945 5.0606 6.29945 5.38796L5.99945 6.2014C4.76945 5.755 3.93945 4.57453 3.93945 3.27502C3.93945 1.55887 5.34945 0.160156 7.07945 0.160156C8.80945 0.160156 10.2295 1.55887 10.2295 3.27502C10.2295 4.59437 9.38945 5.77484 8.12945 6.22124H8.13945Z'
				fill='#333333'
			/>
			<path
				d='M15.9994 9.71331C15.9994 9.76291 15.9994 9.80259 15.9994 9.81251V17.5104C15.9994 18.8793 14.7894 20.0003 13.2894 20.0003H10.3094C9.00937 20.0003 7.74937 19.663 6.65937 19.0281L1.14937 15.8141C0.539371 15.457 -0.450629 14.5046 0.229371 13.4432C0.819371 12.5207 1.94937 12.6595 2.45937 12.8579L5.57937 14.0682V3.46375C5.57937 2.74952 6.25937 2.18408 7.13937 2.18408C8.01937 2.18408 8.69937 2.72968 8.69937 3.46375V7.53093C9.51937 7.15397 10.2994 7.43173 10.7894 7.76901C11.2094 7.47141 11.6494 7.33253 12.1094 7.35237C12.7394 7.38213 13.2394 7.71941 13.5194 7.95748C14.2194 7.74917 14.7894 7.78885 15.2294 8.0666C15.9194 8.513 15.9894 9.38596 15.9894 9.70339L15.9994 9.71331ZM0.949371 14.1674C0.949371 14.6634 1.63937 15.0701 1.64937 15.0701L7.15937 18.2841C8.09937 18.8297 9.18937 19.1273 10.3094 19.1273H13.2894C14.2594 19.1273 15.0494 18.4032 15.0494 17.5104V9.79267C15.0594 9.58435 15.0194 8.99908 14.6894 8.79076C14.4094 8.6122 13.9294 8.74116 13.5794 8.88004L13.2394 9.009L13.0194 8.74116C13.0194 8.74116 12.5994 8.25508 12.0694 8.23524C11.7694 8.22532 11.4594 8.3642 11.1494 8.67172L10.7894 9.01892L10.4494 8.6618C10.3794 8.59236 9.71937 7.94757 8.99937 8.40388C8.75937 8.55268 8.45937 8.5626 8.19937 8.44356C7.92937 8.3146 7.76937 8.0666 7.76937 7.78885V3.46375C7.76937 3.19592 7.44937 3.05704 7.14937 3.05704C6.84937 3.05704 6.53937 3.19592 6.53937 3.46375V14.4451C6.53937 14.6534 6.42937 14.8419 6.23937 14.951C6.05937 15.0602 5.81937 15.08 5.61937 15.0006L2.09937 13.6416C2.02937 13.6118 1.35937 13.3837 1.04937 13.8598C0.989371 13.959 0.959371 14.0582 0.959371 14.1574L0.949371 14.1674Z'
				fill='#333333'
			/>
		</svg>
	);
}
