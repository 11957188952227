import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TableItem } from 'components/AdminPayout/Types';
import { sortItems } from 'components/AdminPayout/Utils';
import { formatAmount } from 'utils/formatters';

export type UsepayoutsToCsvType = {
	csvData: {
		formattedData: Array<TableItem>;
		csvHeaders: Array<string>;
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getCsvData: (event: any, done: any) => void;
	hasPayoutData: boolean;
};

export type CsvHeadersType = Array<keyof TableItem>;

export type CSVDataType = {
	formattedData: Array<TableItem>;
	csvHeaders: CsvHeadersType;
};

const useAdminPayoutToCSV = (isStoreLoading: boolean, payoutModels: Array<TableItem>): UsepayoutsToCsvType => {
	const [payoutData, setpayoutData] = useState<Array<TableItem> | []>([]);
	const [csvData, setCsvData] = useState<CSVDataType>({
		formattedData: [],
		csvHeaders: [],
	});

	const haspayoutData = useRef(false); //use this to prevent loops when modeldata is changing

	const csvHeaders: CsvHeadersType = useMemo(() => ['id', 'campaigns', 'currency', 'month', 'totalCommission', 'year'], []);

	const getCsvData = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(_event: any, done: any) => {
			if (!payoutData || payoutData.length === 0) {
				done(false);
				return;
			}

			try {
				const formattedData: Array<TableItem> = payoutModels
					.map((item) => ({
						id: item.id,
						campaigns: item.campaigns,
						totalCommission: formatAmount(Number(item.totalCommission), undefined, 'sv') || '0',
						currency: item.currency,
						month: item.month,
						year: item.year,
					}))
					.sort(sortItems);

				setCsvData({ csvHeaders, formattedData });
				done(true);
			} catch (error) {
				done(false);
			}
		},
		[payoutData],
	);

	useEffect(() => {
		if (!isStoreLoading) {
			if (payoutModels.length > 0 && haspayoutData.current === false) {
				setpayoutData(payoutModels);
				haspayoutData.current = true;
			}
		}
	}, [isStoreLoading, payoutModels, haspayoutData]);

	return {
		csvData,
		hasPayoutData: haspayoutData.current,
		getCsvData,
	};
};

export default useAdminPayoutToCSV;
