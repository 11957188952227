import { Model, Store } from 'json-api-models';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import AdminPrePayments from 'components/AdminPrePayments/AdminPrePayments';
import BasicLayout from 'components/BasicLayout/BasicLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import PageHeader from 'components/PageHeader/V2';
import Pill from 'components/Pill';
import SearchInput from 'components/SearchInput';
import UserService from 'services/User/User.service';
import { ClientUser, InfluencerUser } from 'shared/Types/User';
import InvoicesView from 'views/admin/Invoices';
import PayoutSummary from 'views/admin/Payout/Summary';

import Styled from './AdminContainer.style';
import InfluencerTable from './Components/Influencers/InfluencerTable';
import PublisherManagement from './Components/Publishers/PublisherManagement';
import FailedVatValidationTable from './Components/Users/FailedVatValidationTable';
import UserTable from './Components/Users/UserTable';

const url = {
	INSIGHTS: '/admin/dashboard',
	USERS: '/admin/users',
	INFLUENCERS: '/admin/influencers',
	CLIENTS: '/admin/clients',
	PUBLISHERS: '/admin/publishers',
	PAYMENTS: '/admin/payments',
	INVOICES: '/admin/invoices',
	PAYOUT: '/admin/payout',
};

const userIncludes = `publisherPrivileges,publisherPrivileges.publisher,clientPrivileges,clientPrivileges.client,brandPrivileges,brandPrivileges.brand,influencers:profilePictureUrl`;
/**
 * AdminContainer
 * @param {ISettingsContainer} props
 * @returns {JSX.Element}
 */
const AdminContainer = (): JSX.Element => {
	const [isSearching, setIsSearching] = useState(false);
	const [users, setUsers] = useState<ClientUser[]>([]);
	const [influencers, setInfluencers] = useState<InfluencerUser[]>([]);
	const [searchValue, setSearchValue] = useState<string>('');

	const { pathname } = useLocation();
	const { publisherId, userId, influencerUserId } = useParams();

	const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const debouncedResults = useMemo(() => {
		return debounce(changeHandler, 400);
	}, []);

	const search = () => {
		setIsSearching(true);
		UserService.searchForUsers(searchValue, userIncludes).then((res) => {
			const models = new Store();
			models.sync(res.data);
			const users = models.findAll('user');
			const influencerUsers = users.filter((user: InfluencerUser) => user.influencers.length > 0);
			const otherUsers = users.filter((user: Model) => user.influencers.length < 1);
			setUsers(otherUsers);
			setInfluencers(influencerUsers);
			setIsSearching(false);
		});
	};

	useEffect(() => {
		if (searchValue.length === 0) {
			setUsers([]);
			setInfluencers([]);
			setIsSearching(false);
		} else if (searchValue.length > 2) {
			search();
		}
	}, [searchValue]);

	useEffect(() => {
		return () => {
			setSearchValue('');
			setUsers([]);
			setInfluencers([]);
			setIsSearching(false);
			debouncedResults.cancel();
		};
	}, []);

	const Menu = [
		{
			title: 'Insights',
			icon: 'home',
			url: '/admin/dashboard',
			testId: 'admin-insights',
			disabled: false,
		},
		{
			title:
				isSearching && pathname === url.USERS ? (
					<Styled.MenuTitle>
						<div>Users</div> <LoadingSpinner size='sm' />
					</Styled.MenuTitle>
				) : (
					<Styled.MenuTitle>
						Users <Pill title={`${users.length}`} className='client' />
					</Styled.MenuTitle>
				),
			url: '/admin/users',
			icon: 'user',
			testId: 'admin-users',
			disabled: false,
		},
		{
			title:
				isSearching && pathname === url.INFLUENCERS ? (
					<Styled.MenuTitle>
						<div>Influencers</div> <LoadingSpinner size='sm' />
					</Styled.MenuTitle>
				) : (
					<Styled.MenuTitle>
						Influencers <Pill title={`${influencers.length}`} className='client' />
					</Styled.MenuTitle>
				),
			url: '/admin/influencers',
			icon: 'influencer',
			testId: 'admin-influencers',
			disabled: false,
		},
		{
			title: 'Publishers',
			icon: 'brand',
			url: '/admin/publishers',
			testId: 'admin-publishers',
			disabled: false,
		},
		{
			title: 'Payments',
			icon: 'credit-card',
			url: '/admin/payments',
			testId: 'admin-payments',
			disabled: false,
		},
		{
			title: 'Invoices',
			icon: 'other-file',
			url: '/admin/invoices',
			testId: 'admin-invoices',
			disabled: false,
		},
		{
			title: 'Payout',
			icon: 'money',
			url: '/admin/payout',
			testId: 'admin-invoices',
			disabled: false,
		},
	];

	const renderContent = (pathname: string) => {
		if (publisherId) return <PublisherManagement />;
		if (userId)
			return (
				<>
					<Styled.SearchWrapper>
						<SearchInput placeholder='Search for users' onChange={debouncedResults} onReset={() => {}} />
					</Styled.SearchWrapper>
					<UserTable data={users} search={search} />
				</>
			);
		if (influencerUserId)
			return (
				<>
					<Styled.SearchWrapper>
						<SearchInput placeholder='Search for influencers' onChange={debouncedResults} onReset={() => {}} />
					</Styled.SearchWrapper>
					<InfluencerTable data={influencers} search={search} />
				</>
			);

		switch (pathname) {
			case url.INSIGHTS:
				return (
					<>
						<FailedVatValidationTable />
					</>
				);
			case url.USERS:
				return (
					<>
						<Styled.SearchWrapper>
							<SearchInput placeholder='Search for users' onChange={debouncedResults} onReset={() => {}} />
						</Styled.SearchWrapper>
						<UserTable data={users} search={search} />
					</>
				);
			case url.INFLUENCERS:
				return (
					<>
						<Styled.SearchWrapper>
							<SearchInput placeholder='Search for influencers' onChange={debouncedResults} onReset={() => {}} />
						</Styled.SearchWrapper>
						<InfluencerTable data={influencers} search={search} />
					</>
				);
			case url.PUBLISHERS:
				return <PublisherManagement />;
			case url.PAYMENTS:
				return <AdminPrePayments />;
			case url.INVOICES:
				return <InvoicesView />;
			case url.PAYOUT:
				return <PayoutSummary />;
			default:
				return (
					<>
						<Styled.SearchWrapper>
							<SearchInput placeholder='Search for users' onChange={debouncedResults} onReset={() => {}} />
						</Styled.SearchWrapper>
						<UserTable data={users} search={search} />
					</>
				);
		}
	};

	return (
		<BasicLayout
			pageHeaderComponent={<PageHeader heading='Admin home' />}
			navigationMenu={Menu}
			mainContent={renderContent(pathname)}
			sidebarComponent={<></>}
		/>
	);
};

export default AdminContainer;
