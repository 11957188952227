import * as Yup from 'yup';

import { ExternalAccountType, PaymentLocalStorage, PineBucketFormValues } from 'components/Payment/types';
import { UserPaymentType, UserType } from 'reducers/UserReducers/types';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

export const formatUserCompanyData = (values: PineBucketFormValues, user: UserType) => {
	return {
		name: values.user.name.length > 0 ? values.user.name : `${user.firstName} ${user.lastName}`,
		companyName: values.user.companyName,
		email: values.user.email,
		address: values.user.address,
		city: values.user.city,
		country: values.country,
		region: 'region',
		postalCode: `${values.user.postalCode}`,
		vatId: values.vatId,
	};
};

export const isSweden = (country: string) => {
	return country === 'SWE';
};

export const formatBankAccountData = (values: PineBucketFormValues) => {
	return {
		currency: values.currency,
		country: values.country,
		type: isSweden(values.country) ? ExternalAccountType.BANK_ACCOUNT : ExternalAccountType.IBAN,
		identifier: isSweden(values.country)
			? { clearingNumber: values.clearingNumber, accountNumber: values.accountNumber, iban: null }
			: { iban: values.iban, clearingNumber: null, accountNumber: null },
		bic: isSweden(values.country) ? null : values.bic,
	};
};

export const Validation = Yup.object().shape({
	vatId: Yup.string().required('Required'),
	country: Yup.string().required('Required'),
	user: Yup.object().shape({
		postalCode: Yup.string().matches(/^[0-9]{1,10}$/, 'Only digits allowed'),
	}),
	clearingNumber: Yup.number()
		.typeError('Must be of type number')
		.when('country', ([country], schema) => {
			return isSweden(country) ? Yup.string().required('Clearing number is required') : schema;
		}),
	accountNumber: Yup.number()
		.typeError('Must be of type number')
		.when('country', ([country], schema) => {
			return isSweden(country) ? Yup.string().required('Account number is required') : schema;
		}),
	bic: Yup.string().when('country', ([country], schema) => {
		return !isSweden(country) ? Yup.string().required('BIC/Swift is required') : schema;
	}),
	iban: Yup.string()
		.max(34)
		.typeError('Should consist of maximum 34 characters')
		.when('country', ([country], schema) => {
			return !isSweden(country) ? Yup.string().required('IBAN is required') : schema;
		}),
});

export const mapParams = (param: string) => {
	switch (param) {
		case 'identifier':
			return 'Incorrect bank account or IBAN';
		case 'companyName':
			return 'Company name';
		case 'bic':
			return 'Incorrect BIC';
		case 'email':
			return 'Incorrect email format';
		case 'city':
			return 'City';
		default:
			return param;
	}
};

export const mapMessages = (message: string) => {
	switch (message) {
		case 'Invalid IBAN account.':
			return 'Invalid IBAN.';
		case 'This value is not a valid email address.':
			return 'It does not appear to be a valid email address.';
		default:
			return message;
	}
};

export const isCollabsPayoutUser = (user: UserType) => {
	if (user.payment.type === UserPaymentType.INDIVIDUAL) return false;
	if (user.payment.type === UserPaymentType.LEGACY_AGENT) return false;
	if (user.payment.type === null) return false;
	return true;
};

export const clearLocalStorageCollabsPayout = () => {
	const storage = new BrowserStorage(StorageType.LOCAL);
	return (
		storage.removeItem(PaymentLocalStorage.USER_VERIFIED),
		storage.removeItem(PaymentLocalStorage.ID_TO_VERIFY),
		storage.removeItem(PaymentLocalStorage.PINEBUCKET_TOKEN)
	);
};

export enum Views {
	AUTOMATIC_OPTION,
	MANUAL_OPTION,
	PAYOUT_FORM,
	SUCCESS,
	VERIFICATION_FAILURE,
	SWITCH_TO_MANUAL,
}
