import axios, { AxiosError, AxiosResponse, AxiosInstance } from 'axios';

import { ICollabsResponse } from 'services/Response.types';
import { createClient } from 'shared/ApiClient/ApiClient';

import { IInfluencerService } from './type';

/**
 * @deprecated use InfluencerManager instead. The InfluencerManager is part of our new ApiManager pattern.
 */
class InfluencerService implements IInfluencerService {
	private client: AxiosInstance;

	constructor() {
		this.client = createClient();
	}
	async getInvitedCampaign(inviteToken: string) {
		let result;

		await this.client
			.get(`/public/campaigns/${inviteToken}?includes=brand.client.publisher`)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = response.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});

		return result;
	}

	async joinCampaign(campaignId: string, influencerId: string) {
		const url = `/campaigns/${campaignId}/influencers/${influencerId}/join`;
		let result;
		await this.client
			.post(url)
			.then((response: AxiosResponse<ICollabsResponse>) => {
				result = response.data;
			})
			.catch((err: Error | AxiosError) => {
				if (axios.isAxiosError(err)) {
					result = err;
				}
			});

		return result;
	}
}

export default new InfluencerService();
