import classNames from 'classnames';
import { useContext } from 'react';

import { DarkButton, GhostButton } from 'components/Button';
import FilterDrawer from 'components/Discovery/Components/FilterDrawer';
import Styled from 'components/Discovery/DiscoveryContainer.style';
import Pill from 'components/Pill';
import { SideDrawer } from 'components/SideDrawer';
import DiscoveryContext from 'contexts/Discovery';

type FilterSectionProps = {
	setShowFilter: (showFilter: boolean) => void;
	showFilter: boolean;
};

const FilterSection = ({ setShowFilter, showFilter }: FilterSectionProps) => {
	const { filter, resetFilter, setHasSearched } = useContext(DiscoveryContext);

	const filterCount = filter !== null ? Object.entries(filter).filter(([key, value]) => value !== null && value !== '' && key !== 'networks').length : 0;

	return (
		<>
			<Styled.FilterButtonAndTag>
				<Styled.ClearFilterButtonContainer>
					<Styled.CustomButton
						onClick={() => {
							setShowFilter(!showFilter);
						}}
						className={classNames('filters', { 'filter-opened': showFilter })}
					>
						<Styled.CustomIcon name='filters' size='20' />
						Filter {filterCount > 0 ? <Pill className='discovery-filter' title={`${filterCount}`} /> : null}
					</Styled.CustomButton>
					{filterCount > 0 ? (
						<Styled.FilterSummaryWrapper>
							<Styled.ClearFilterButton onClick={resetFilter}>Clear filters</Styled.ClearFilterButton>
						</Styled.FilterSummaryWrapper>
					) : null}
				</Styled.ClearFilterButtonContainer>
			</Styled.FilterButtonAndTag>
			<SideDrawer
				sidebarIsOpen={showFilter}
				onClose={() => setShowFilter(false)}
				dataTestId='discovery-filters'
				title={`All Filters (${filterCount})`}
				isExpandable
				customButtons={
					<>
						<GhostButton size='sm' onClick={resetFilter}>
							Clear all filters
						</GhostButton>
						<DarkButton
							onClick={() => {
								setShowFilter(false), setHasSearched(true);
							}}
							size='sm'
						>
							View results
						</DarkButton>
					</>
				}
			>
				<FilterDrawer />
			</SideDrawer>
		</>
	);
};

export default FilterSection;
