import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgCollapseFilled(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path d='M8.33301 0H18.9997C19.552 0 19.9997 0.447715 19.9997 1V19C19.9997 19.5523 19.552 20 18.9997 20H8.33301V0Z' fill='#4D4B4C' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.5 1.5L1.5 18.5L18.5 18.5L18.5 1.5L1.5 1.5ZM1 0C0.447715 0 0 0.447716 0 1L0 19C0 19.5523 0.447716 20 1 20L19 20C19.5523 20 20 19.5523 20 19L20 1C20 0.447715 19.5523 0 19 0L1 0Z'
				fill='#4D4B4C'
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M8.5 0.5L8.5 19.5H7.5L7.5 0.5L8.5 0.5Z' fill='#4D4B4C' />
			<path
				className='inner-white'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.2512 6.07424C12.5048 5.94177 12.8421 6.00191 13.0047 6.20855L15.9138 9.90629C16.0327 10.0575 16.0283 10.2521 15.9026 10.3996L12.9935 13.8129C12.8217 14.0145 12.4818 14.0645 12.2344 13.9245C11.987 13.7845 11.9257 13.5075 12.0975 13.3059L14.7989 10.1363L12.0863 6.68825C11.9237 6.4816 11.9975 6.2067 12.2512 6.07424Z'
				fill='#FAFAFA'
			/>
		</svg>
	);
}
