/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { CreateNotificationPreferencePayload, CreateNotificationPayload, UpdateNotificationPayload } from 'api-payloads';
import { NotificationPreferenceCollectionResponse, NotificationCollectionResponse, NotificationResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import NotificationApiClientInterface from './NotificationApiClientInterface';

@injectable()
export default class NotificationApiClient implements NotificationApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List notification preference
	 */
	async listPreference(params: { include?: string }): Promise<NotificationPreferenceCollectionResponse> {
		const response = await this.client.get<NotificationPreferenceCollectionResponse>(`/notification-preference`, { params });
		return response.data;
	}

	/**
	 * Create notification preference
	 */
	async createPreference(payload: CreateNotificationPreferencePayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/notification-preference`, payload, { params });
		return response.data;
	}

	/**
	 * List notifications
	 */
	async listNotifications(params: { include?: string }): Promise<NotificationCollectionResponse> {
		const response = await this.client.get<NotificationCollectionResponse>(`/notifications`, { params });
		return response.data;
	}

	/**
	 * Create notification
	 */
	async create(payload: CreateNotificationPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/notifications`, payload, { params });
		return response.data;
	}

	/**
	 * Update notification
	 */
	async update(id: string, payload: UpdateNotificationPayload, params: { include?: string }): Promise<NotificationResponse> {
		const response = await this.client.patch<NotificationResponse>(`/notifications/${id}`, payload, { params });
		return response.data;
	}
}
