import { Player } from '@lottiefiles/react-lottie-player';
import classNames from 'classnames';
import { Model } from 'json-api-models';
import moment from 'moment';
import React, { useEffect, useMemo, useCallback, useReducer, memo } from 'react';
import { useLocation } from 'react-router-dom';

import NewNotificationsIcon from 'assets/animations/icon-new-notification.json'; // eslint-disable-line import/extensions
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import { useAppSelector } from 'hooks/useUserAppSelector';
import NotificationsService from 'services/Notifications/Notifications.service';

import Styled from './NotificationsMenu.style';
import { INotificationsMenuProps, Notification } from './types';

type State = {
	unreadNotifications: boolean;
	displayAnimation: boolean;
	userDropdownOpen: boolean;
};

type Action = {
	type: 'SET_UNREAD_NOTIFICATIONS' | 'SET_DISPLAY_ANIMATION' | 'SET_USER_DROPDOWN_OPEN';
	payload: boolean;
};

type NotificationItemProps = {
	item: Model;
	handleLinkClick: (item: Model) => void;
	displayIcon: (type: string) => string;
};

const showDateOrTime = (timestamp: string) => {
	const time = moment(timestamp);
	const today = moment();
	if (time.isSame(today, 'day')) {
		return time.format('HH:mm');
	}
	return time.format('YYYY-MM-DD');
};

const initialState = {
	unreadNotifications: false,
	displayAnimation: false,
	userDropdownOpen: false,
};

const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case 'SET_UNREAD_NOTIFICATIONS':
			return { ...state, unreadNotifications: action.payload };
		case 'SET_DISPLAY_ANIMATION':
			return { ...state, displayAnimation: action.payload };
		case 'SET_USER_DROPDOWN_OPEN':
			return { ...state, userDropdownOpen: action.payload };
		default:
			return state;
	}
};

/**
 * Notification dropdown
 * @param {INotificationsMenuProps} props
 * @returns {JSX.Element}
 */
const NotificationsMenu = (props: INotificationsMenuProps): JSX.Element => {
	const { isLoading } = props;
	const notifications = useAppSelector((state) => state.userNotifications.notifications);

	const [state, dispatch] = useReducer(reducer, initialState);

	const displayIcon = useCallback((type: string): string => {
		switch (type) {
			case 'campaign':
				return 'campaign';
			case 'influencer-list':
				return 'list';
			case 'influencer-folder':
				return 'list';
			case 'data-library':
				return 'message';
			default:
				return 'message';
		}
	}, []);

	const markAsRead = useCallback((notification: Model) => {
		if (notification.readAt) {
			return;
		}

		const readAt = moment().format('YYYY-MM-DD HH:mm');
		NotificationsService.updateNotificationAsRead(notification.id, readAt);
	}, []);

	const markAllAsRead = useCallback(
		(notifications: Model[]) => {
			notifications.forEach((notification) => markAsRead(notification));
		},
		[markAsRead],
	);

	const handleLinkClick = useCallback(
		(item: Model) => {
			markAsRead(item);
			dispatch({ type: 'SET_USER_DROPDOWN_OPEN', payload: false });
		},
		[markAsRead],
	);

	const notificationsToDisplayMemo = useMemo(() => {
		const filteredNotifications = notifications.filter((n: { readAt: string | null; createdAt: string }) =>
			n.readAt !== null ? moment().diff(moment(n.createdAt), 'days') <= 14 : n,
		);
		const unread = filteredNotifications.filter((notification: Notification) => notification.readAt === null);
		if (unread.length > 0) {
			dispatch({ type: 'SET_UNREAD_NOTIFICATIONS', payload: true });
		}
		return filteredNotifications;
	}, [notifications]);

	// Update unread notifications state
	useEffect(() => {
		if (notificationsToDisplayMemo.filter((notification: { readAt: string }) => !notification.readAt).length === 0) {
			dispatch({ type: 'SET_UNREAD_NOTIFICATIONS', payload: false });
		}
	}, [notificationsToDisplayMemo]);

	// Debounced event handlers for mouse events
	const handleMouseEnter = useCallback(() => {
		dispatch({ type: 'SET_DISPLAY_ANIMATION', payload: true });
	}, []);

	const handleMouseLeave = useCallback(() => {
		dispatch({ type: 'SET_DISPLAY_ANIMATION', payload: false });
	}, []);

	return (
		<Styled.customHeaderDropdown
			className='notifications'
			visible={state.unreadNotifications}
			actionButtonIcon={
				state.unreadNotifications ? (
					<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
						{state.displayAnimation ? (
							<Player src={NewNotificationsIcon} autoplay={true} style={{ height: '50px', width: '50px' }} />
						) : (
							<div style={{ marginTop: '5px' }}>
								<Icon name='notification' size='24' />
							</div>
						)}
						<Styled.NotificationCircle className={classNames({ visible: state.unreadNotifications })} />
					</div>
				) : (
					<Icon name='notification' size='24' />
				)
			}
			userDropdownOpen={state.userDropdownOpen}
			setUserDropdownOpen={(isOpen) => dispatch({ type: 'SET_USER_DROPDOWN_OPEN', payload: isOpen })}
		>
			{isLoading && (
				<Styled.NotificationContent>
					<Styled.SpinnerWrapper>
						<LoadingSpinner size='sm' />
					</Styled.SpinnerWrapper>
				</Styled.NotificationContent>
			)}

			{notificationsToDisplayMemo.length > 0 && (
				<Styled.NotificationList data-testid='notificationsMenuList'>
					{notificationsToDisplayMemo.map((item: Model) => (
						<NotificationItem key={item.id} item={item} handleLinkClick={handleLinkClick} displayIcon={displayIcon} />
					))}
				</Styled.NotificationList>
			)}

			{notificationsToDisplayMemo.length === 0 && (
				<Styled.NoNotificationRow>
					<Styled.NotificationIconContainer>
						<Styled.NewNotificationCircle />
						<Styled.NotificationIconWrapper>
							<Icon name='celebrate' />
						</Styled.NotificationIconWrapper>
					</Styled.NotificationIconContainer>
					<Styled.NotificationTitleContainer>
						No notifications right now<span>You deserve a break</span>
					</Styled.NotificationTitleContainer>
				</Styled.NoNotificationRow>
			)}

			{!isLoading && notificationsToDisplayMemo.length > 0 && state.unreadNotifications && (
				<Styled.OptionsWrapper>
					<Styled.NotificationOption onClick={() => markAllAsRead(notificationsToDisplayMemo)}>Mark all as read</Styled.NotificationOption>
				</Styled.OptionsWrapper>
			)}
		</Styled.customHeaderDropdown>
	);
};

const NotificationItem = memo(({ item, handleLinkClick, displayIcon }: NotificationItemProps) => {
	const location = useLocation();

	return (
		<li>
			<Styled.NotificationRow
				className={classNames({ unread: !item.readAt })}
				to={location.pathname === item.link ? '#' : item.link}
				onClick={() => handleLinkClick(item)}
				aria-label='Go to the page you have been notified about'
				aria-disabled={location.pathname === item.link ? 'true' : 'false'}
				data-testid='notification-item'
			>
				<Styled.NotificationIconContainer>
					<Styled.NewNotificationCircle className={classNames({ visible: !item.readAt })} />
					<Styled.NotificationIconWrapper>
						<Icon name={displayIcon(item.category)} size='20' />
					</Styled.NotificationIconWrapper>
				</Styled.NotificationIconContainer>
				<Styled.NotificationTitleContainer>{item.title}</Styled.NotificationTitleContainer>
				<Styled.NotificationDateContainer>{showDateOrTime(item.createdAt)}</Styled.NotificationDateContainer>
				<Styled.NotificationDescriptionContainer>{item.description}</Styled.NotificationDescriptionContainer>
			</Styled.NotificationRow>
		</li>
	);
});

export default NotificationsMenu;
