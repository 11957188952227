import { createClient } from 'shared/ApiClient/ApiClient';

const useBlastMessage = () => {
	const client = createClient();

	const createBlastDirectMessage = (influencerIds: string[], subject: string | null, message: string) => {
		return client.post('/conversations/blasts', { subject: subject, message: message, influencers: influencerIds });
	};

	return {
		createBlastDirectMessage,
	};
};

export default useBlastMessage;
