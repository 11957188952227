import Styled from 'components/DataLibrary/DataLibraryModal/DataLibraryModal.style';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import LoadingSpinner from 'components/LoadingSpinner';

type CreateDashboardBodyProps = {
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	isFetching: boolean;
	newDashboardName: string;
	title: string;
};

/**
 * Create dashboard
 * @returns {JSX.Element}
 */
const CreateDashboardBody = ({ handleChange, newDashboardName, isFetching, title }: CreateDashboardBodyProps): JSX.Element => (
	<Styled.Wrapper>
		<Field label={title}>
			<Input name='dasboard-name' type='text' value={newDashboardName} onChange={(e) => handleChange(e)} />
		</Field>
		{isFetching && (
			<Styled.SpinnerWrapper>
				<LoadingSpinner />
			</Styled.SpinnerWrapper>
		)}
	</Styled.Wrapper>
);

export default CreateDashboardBody;
