import classNames from 'classnames';
import { isNil, reverse } from 'lodash';
import { useState } from 'react';

import { StatisticsInfluencerWithStatisticsModel } from 'api-models';
import { getCompareFn } from 'components/DataLibrary/Graphs/GetCompareFn';
import { FakeData } from 'components/DataLibrary/Graphs/GraphComponents/ByInfluencer/FakeData';
import { ByInfluencerOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByInfluencer/types';
import ChartCard from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard';
import CardStyled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { ChartCardProps } from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/types';
import ExplanationAccordition from 'components/DataLibrary/Graphs/GraphComponents/ExplanationAccordion';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import toast from 'services/Toast';
import { useAppSelector } from 'views/DataLibrary/hooks';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

import Styled from './ByInfluencerTable.style';
import ByInfluencerTableItem from './ByInfluencerTableItem';

const SORT_TABLE_FIELDS = [
	{ property: 'username', label: 'Influencer' },
	{ property: 'assignments', label: 'Activations' },
	{ property: 'clicks', label: 'Clicks' },
	{ property: 'grossReach', label: 'Gross reach' },
	{ property: 'reachRate', label: 'Reach rate' },
	{ property: 'impressions', label: 'Impressions' },
	{ property: 'engagement', label: 'Engagement' },
	{ property: 'actualEngagementRate', label: 'Engagement Rate' },
];

enum SORT_ORDER {
	ASC,
	DESC,
}

/**
 * @returns {JSX.Element}
 */
const ByInfluencerTable = ({ fetchData, title, chartId, chartType, chartOnDashboard, chartExplanation }: ChartCardProps<ByInfluencerOutput>): JSX.Element => {
	const [sortProperty, setSortProperty] = useState('name');
	const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASC);
	const [showAll, setShowAll] = useState<boolean>(false);

	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const manager = useInjection<StatisticsManager>(StatisticsManager);

	let influencerList: StatisticsInfluencerWithStatisticsModel[] = [];
	let isLoading = true;

	if (!fetchData) {
		isLoading = false;
		influencerList = FakeData;
	} else {
		const { result, error } = fetchData(manager, dashboardFilter);
		if (error) {
			toast.error(`${error.message}`);
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			influencerList = result.influencers;
		}
	}

	const handleSortChange = (e: React.MouseEvent) => {
		const { dataset } = e.target as HTMLTableCellElement;
		const property = dataset.sortProperty;

		if (isNil(property)) {
			toast.error(`We can't sort on that data`);
			return;
		}

		if (property === sortProperty) {
			setSortOrder(sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC);
		} else {
			setSortProperty(property);
		}
	};

	const sortListHandler = (list: StatisticsInfluencerWithStatisticsModel[]) => {
		let sortedList = list.sort(getCompareFn(sortProperty));

		if (SORT_ORDER.DESC === sortOrder) {
			sortedList = reverse(sortedList);
		}

		return sortedList;
	};

	return (
		<ChartCard chartId={chartId} title={title} chartType={chartType} chartOnDashboard={chartOnDashboard} isLoading={isLoading}>
			<Styled.InnerCenteredWrapper>
				<Styled.ListTable className={classNames({ showAll: showAll })}>
					<Styled.ListTableHead>
						<tr>
							{SORT_TABLE_FIELDS.map(({ property, label }) => (
								<Styled.ListTableTh title={`Sort by ${label.toLowerCase()}`} key={property}>
									<Styled.TableHeaderInnerWrapper onClick={handleSortChange} data-sort-property={property}>
										<Styled.Label data-sort-property={property}>{label}</Styled.Label>
										<Styled.SortIconWrapper>
											<div
												data-sort-property={property}
												className={classNames('arrow-up', { isActive: property === sortProperty && sortOrder === SORT_ORDER.ASC })}
											/>
											<div
												data-sort-property={property}
												className={classNames('arrow-down', { isActive: property === sortProperty && sortOrder === SORT_ORDER.DESC })}
											/>
										</Styled.SortIconWrapper>
									</Styled.TableHeaderInnerWrapper>
								</Styled.ListTableTh>
							))}
						</tr>
					</Styled.ListTableHead>
					<Styled.ListTableBody data-testid='list-table-body'>
						{sortListHandler(showAll ? influencerList : influencerList.slice(0, 3)).map((influencer, i) => {
							return <ByInfluencerTableItem key={i} i={i} influencer={influencer} />;
						})}
					</Styled.ListTableBody>
				</Styled.ListTable>
			</Styled.InnerCenteredWrapper>
			{influencerList?.length > 3 && (
				<CardStyled.SeeAll
					onClick={() => {
						setShowAll(!showAll);
					}}
				>
					{showAll ? 'see less' : 'see all'}
				</CardStyled.SeeAll>
			)}
			{chartExplanation && <ExplanationAccordition chartTitle={title} chartExplanation={chartExplanation} />}
		</ChartCard>
	);
};

export default ByInfluencerTable;
