import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Input = styled.input``;

const SpinnerContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

const Delete = styled.div`
	display: flex;
	border-radius: ${borderRadius.s};
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 24px;
	height: 24px;
	margin-left: ${spacing[1].px};
	.icon {
		margin-top: 4px;
	}
	&:hover {
		background-color: ${colors.errorLight};
	}
`;

const FileWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: ${spacing[1].px};
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const ErrorMessage = styled.div`
	background-color: ${colors.errorLight};
	border-radius: ${borderRadius.s};
	padding: ${spacing[1].rem};
	color: ${colors.errorDarkRed};
	font-size: 0.75rem;
	display: flex;
	span {
		margin-top: 2px;
	}
	.icon {
		margin-right: 5px;
	}
`;

const Styled = {
	Wrapper,
	Input,
	SpinnerContainer,
	Delete,
	FileWrapper,
	ErrorMessage,
};

export default Styled;
