import { useNavigate } from 'react-router-dom';

import Styled from './EmptyState.style';

type ButtonType = 'link' | 'button';

export type EmptyStateProps = {
	image?: string;
	altText?: string;
	headline?: string;
	text?: React.ReactNode;
	url?: string;
	buttonText?: string;
	buttonType?: ButtonType;
	testId?: string;
};

const EmptyState = ({ image, altText, testId, headline, text, url, buttonText, buttonType }: EmptyStateProps) => {
	const navigate = useNavigate();

	const CTAButton = () => {
		if (buttonType === 'link') {
			return <Styled.CTALink to={url!}>{buttonText}</Styled.CTALink>;
		}

		return <Styled.CTAButton onClick={() => navigate(url!)}>{buttonText}</Styled.CTAButton>;
	};

	return (
		<Styled.EmptyState data-testid={testId ?? 'empty-state'}>
			{image && (
				<Styled.ImageContainer>
					<picture>
						<img src={image} alt={altText || 'No results'} />
					</picture>
				</Styled.ImageContainer>
			)}

			<Styled.TextContainer>
				{headline && <Styled.Headline>{headline}</Styled.Headline>}
				{text}
				{url && buttonText && CTAButton()}
			</Styled.TextContainer>
		</Styled.EmptyState>
	);
};

export default EmptyState;
