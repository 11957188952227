import Styled, { ContainerBar, FillerBar } from './Bar.style';

const Bar = (props: ContainerBar & FillerBar) => {
	return (
		<Styled.ContainerBar containerBg={props.containerBg}>
			<Styled.FillerBar percent={props.percent} fillerBg={props.fillerBg} />
		</Styled.ContainerBar>
	);
};

export default Bar;
