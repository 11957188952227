import styled from 'styled-components';

import colors from 'styles/theme/colors';

const InfoText = styled.p`
	margin-bottom: 16px;
	font-size: 1rem;
	color: ${colors.steel};
`;

export default {
	InfoText,
};
