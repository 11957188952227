import { injectable } from 'inversify';
import { Model } from 'json-api-models';
import { Dispatch } from 'redux';

import { receiveNotification } from 'reducers/UserReducers/UserNotificationsSlice';
import { setIdVerificationStatus } from 'reducers/UserReducers/UserSlice';

import MessageHandlerInterface from './MessageHandlerInterface';
import { MercureMessage, MercureNotification } from './types';

/**
 * Handle different types of Mercure messages.
 */
@injectable()
export class MessageHandler implements MessageHandlerInterface {
	public handle(message: MercureMessage, dispatch: Dispatch): void {
		switch (message.type) {
			case 'notification':
				dispatch(receiveNotification(message as MercureNotification));
				break;
			case 'user':
				dispatch(setIdVerificationStatus(message as Model)); // Change this when we update the /me request with new manager
				break;
			case 'newConversationMessage':
				// TODO refetch SWR for conversation and send a Toast.
				// (message as MercureInboxMessage);
				break;
		}
	}
}
