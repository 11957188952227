import styled from 'styled-components';

import Modal from 'components/Modals/Modal';
import { ModalHeader, ModalContent } from 'components/Modals/Modal/Modal.style';
import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const dl = colors.dataLibrary;
const sm = colors.shareModal;

const CustomModal = styled(Modal)`
	${ModalContent} {
		box-shadow: 0px 10px 20px 0px rgba(92, 104, 128, 0.16);
		border: 1px solid ${dl.modal.grayBorder};
		border-radius: 10px;
		overflow: hidden;
		height: 80vh;
	}
`;

const CloseContainer = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	.icon {
		margin-top: 4px;
	}
	&:hover {
		background: ${dl.button.cancelBackground};
	}
`;

const CustomModalHeader = styled(ModalHeader)`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 1rem 0.5rem 1rem;
	margin-bottom: 0;
`;

const CustomModalBody = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 312px;
	padding: 0rem 1rem;
	${scrollbarY};
	${mediaQueries.large`
		padding: 0rem 2rem 2rem 2rem;
		overflow-y: auto !important;
	`};
`;

const CustomModalFooter = styled.div`
	display: flex;
	width: calc(100% - 4rem);
	justify-content: space-between;
	height: 64px;
	padding: 1rem 0rem;
	border-top: 1px solid ${dl.modal.grayBorder};
	margin: 0rem 2rem 1rem 2rem;
	button {
		font-size: 1rem;
		line-height: 1;
		width: 100px;
		height: 46px;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		box-sizing: border-box;
		&.save {
			background-color: ${sm.button.disabledBackground};
			&.saveable {
				background-color: ${dl.blackHover};
				color: ${dl.white};
			}
			&:hover {
				background-color: ${dl.blackHover};
				color: ${dl.white};
			}
			&:disabled {
				background-color: ${sm.button.disabledBackground};
				color: ${sm.button.disabledText};
			}
		}
		&.cancel {
			background-color: transparent;
		}
		&:hover {
			background-color: ${dl.blackHover};
			color: ${dl.white};
		}
	}
`;

const ModalHeaderTitle = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0em;
	text-align: left;
`;

const Wrapper = styled.div`
	padding-top: 2rem;
	display: flex;
	flex-direction: column;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	${mediaQueries.large`
		min-height: 480px;
		flex-direction: row;
	`};
`;
const ContainerList = styled.div`
	width: 100%;
	height: 100%;
	padding: 0.2rem;
	background-color: ${dl.backgroundGray};
	border-radius: 4px;
	${scrollbarY};
	display: flex;
	flex-direction: column;
	&.hide {
		display: none;
	}
	&.mini {
		height: auto;
	}
	${mediaQueries.large`
		margin-left: 0.5rem;
		width: 33%;
		display: flex;
		padding: 1rem;
	`};
`;

const RightColumnContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	${scrollbarY};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const RightColumnContainerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	${mediaQueries.large`
		width: 66%;
		padding-left: 2rem;
	`};
`;

const Title = styled.h5`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1.1rem;
	font-weight: 700;
	letter-spacing: 0em;
	${mediaQueries.large`
		font-size: 1.438rem;
	`};
`;

const Styled = {
	Container,
	ContainerList,
	CustomModalHeader,
	ModalHeaderTitle,
	CustomModal,
	CustomModalFooter,
	CustomModalBody,
	CloseContainer,
	Wrapper,
	RightColumnContainer,
	Title,
	RightColumnContainerWrapper,
};

export default Styled;
