import _ from 'lodash';

import { LinksType, PermissionsType } from 'reducers/UserReducers/types';

const can =
	(links?: LinksType) =>
	(permissionsKey: string): boolean => {
		if (!_.isEmpty(links)) {
			return Object.prototype.hasOwnProperty.call(links, permissionsKey);
		}
		return false;
	};

const canAll =
	(links?: LinksType) =>
	(permissionsKey: string | string[]): boolean => {
		const arrayPermissions = Array.isArray(permissionsKey) ? permissionsKey : permissionsKey.split('|');

		if (!_.isEmpty(links)) {
			return arrayPermissions.every((permission) => Object.prototype.hasOwnProperty.call(links, permission));
		}
		return false;
	};

export const isSuperAdmin = (permissions: PermissionsType) => (): boolean => {
	return permissions.isSuperAdmin;
};

export const isImpersonating = (permissions: PermissionsType) => (): boolean => {
	return permissions.isImpersonating;
};

export const isInfluencer = (permissions: PermissionsType) => (): boolean => {
	return permissions.isInfluencer;
};

export const isAgent = (permissions: PermissionsType) => (): boolean => {
	return permissions.isAgent;
};

export const isPublisher = (permissions: PermissionsType) => (): boolean => {
	for (const id in permissions.entities) {
		const { kind, role } = permissions.entities[id];

		if ('publisher' === kind && ('administrator' === role || 'project_manager' === role)) {
			return true;
		}
	}
	return false;
};

export const isClient = (permissions: PermissionsType) => (): boolean => {
	if (isPublisher(permissions)()) return false;

	for (const id in permissions.entities) {
		const { kind, role } = permissions.entities[id];

		if ('client' === kind && 'administrator' === role) {
			return true;
		}
	}
	return false;
};

export const isBrandManager = (permissions: PermissionsType) => (): boolean => {
	if (isPublisher(permissions)() || isClient(permissions)()) return false;

	for (const id in permissions.entities) {
		// @ts-ignore
		// eslint-disable-next-line unused-imports/no-unused-vars
		const { kind, role } = permissions.entities[id];

		if ('brand_manager' === role) {
			return true;
		}
	}
	return false;
};

const Utils = {
	can,
	canAll,
	isSuperAdmin,
	isImpersonating,
	isInfluencer,
	isAgent,
	isPublisher,
	isBrandManager,
};

export default Utils;
