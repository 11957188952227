import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgDLActivations(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14 1C14 0.447715 14.4477 0 15 0H19C19.5523 0 20 0.447715 20 1V16C20 16.5523 19.5523 17 19 17H15C14.4477 17 14 16.5523 14 16V1ZM15.5 15.5V1.5H18.5V15.5H15.5Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1 4C0.447715 4 0 4.44772 0 5V16C0 16.5523 0.447715 17 1 17H5C5.55228 17 6 16.5523 6 16V5C6 4.44772 5.55228 4 5 4H1ZM1.5 5.5V15.5H4.5V5.5H1.5Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7 7C7 6.44771 7.44772 6 8 6H12C12.5523 6 13 6.44772 13 7V16C13 16.5523 12.5523 17 12 17H8C7.44772 17 7 16.5523 7 16V7ZM8.5 15.5V7.5H11.5V15.5H8.5Z'
				fill='#333333'
			/>
			<path
				d='M0.697674 18.5C0.312359 18.5 0 18.8358 0 19.25C0 19.6642 0.312359 20 0.697674 20H19.3023C19.6876 20 20 19.6642 20 19.25C20 18.8358 19.6876 18.5 19.3023 18.5H0.697674Z'
				fill='#333333'
			/>
			<path d='M14.8157 14.6509L15.0657 14.2179L18.3305 16.1028L18.0805 16.5358L14.8157 14.6509Z' fill='#333333' />
			<path d='M14.8201 8.85L15.0701 8.417L19.3835 10.9073L19.1335 11.3403L14.8201 8.85Z' fill='#333333' />
			<path d='M14.8157 2.65088L15.0657 2.21788L19.3017 4.66355L19.0517 5.09655L14.8157 2.65088Z' fill='#333333' />
			<path d='M14.3308 13.2158L14.5808 12.7828L18.8305 15.2364L18.5806 15.6694L14.3308 13.2158Z' fill='#333333' />
			<path d='M14.3308 7.32L14.5808 6.887L18.8305 9.3406L18.5806 9.77359L14.3308 7.32Z' fill='#333333' />
			<path d='M14.3308 1.21582L14.5808 0.782822L19.1327 3.41087L18.8827 3.84387L14.3308 1.21582Z' fill='#333333' />
			<path d='M14 11.8701L14.25 11.4371L19.3306 14.3704L19.0806 14.8034L14 11.8701Z' fill='#333333' />
			<path d='M14 5.87012L14.25 5.43712L19.3306 8.3704L19.0806 8.8034L14 5.87012Z' fill='#333333' />
			<path d='M15.3974 0.676888L15.6474 0.24389L19.3306 2.3704L19.0806 2.8034L15.3974 0.676888Z' fill='#333333' />
			<path d='M14.763 11.1562L15.013 10.7232L19.8306 13.5046L19.5806 13.9376L14.763 11.1562Z' fill='#333333' />
			<path d='M14.8087 5.18255L15.0587 4.74956L19.8306 7.50461L19.5806 7.9376L14.8087 5.18255Z' fill='#333333' />
			<path d='M17.3351 0.641158L17.5851 0.20816L19.8306 1.50461L19.5806 1.9376L17.3351 0.641158Z' fill='#333333' />
			<path d='M14.6663 9.94531L14.9163 9.51231L19.7036 12.2763L19.4537 12.7093L14.6663 9.94531Z' fill='#333333' />
			<path d='M14.6663 3.94531L14.9163 3.51231L19.6305 6.2341L19.3805 6.6671L14.6663 3.94531Z' fill='#333333' />
			<path d='M17.2427 5.43311L17.4927 5.00011L19.5775 6.20381L19.3275 6.63681L17.2427 5.43311Z' fill='#333333' />
			<path d='M0.815674 14.6509L1.06567 14.2179L4.3305 16.1028L4.0805 16.5358L0.815674 14.6509Z' fill='#333333' />
			<path d='M0.815674 8.85L1.06567 8.417L5.37907 10.9073L5.12907 11.3403L0.815674 8.85Z' fill='#333333' />
			<path d='M0.330811 13.2158L0.580802 12.7828L4.83055 15.2364L4.58056 15.6694L0.330811 13.2158Z' fill='#333333' />
			<path d='M0.330811 7.32L0.580802 6.887L4.99951 9.43815L4.74952 9.87114L0.330811 7.32Z' fill='#333333' />
			<path d='M0 11.8701L0.249991 11.4371L5.33058 14.3704L5.08059 14.8034L0 11.8701Z' fill='#333333' />
			<path d='M0 5.89L0.249991 5.457L5.33058 8.39028L5.08059 8.82328L0 5.89Z' fill='#333333' />
			<path d='M0.762939 11.1562L1.01293 10.7233L5.83049 13.5047L5.5805 13.9377L0.762939 11.1562Z' fill='#333333' />
			<path d='M0.808594 5.18262L1.05859 4.74962L5.83047 7.50467L5.58048 7.93767L0.808594 5.18262Z' fill='#333333' />
			<path d='M0.66626 9.94531L0.916251 9.51231L5.70365 12.2763L5.45365 12.7093L0.66626 9.94531Z' fill='#333333' />
			<path d='M2.11553 4.78205L2.36552 4.34905L5.63052 6.2341L5.38053 6.6671L2.11553 4.78205Z' fill='#333333' />
		</svg>
	);
}
