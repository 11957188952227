import originalColors, { PALETTE } from './original-colors';

const colors = {
	// Main
	brandPrimary: '#157ff1',
	brandAccent: '#6c63ff',
	brandAccentAlt: '#00c661',
	// Grayscale
	white: '#ffffff',
	black: '#3d3f41',
	black1: '#333',
	gray1: '#f6f9fa',
	gray2: '#e5eaf1',
	gray3: '#e7eaef',
	gray4: '#98a0a9',
	gray5: '#888a8d',
	gray6: '#595c5f',
	gray7: '#888',
	gray8: '#5c5c5c',
	gray9: '#E3E3E3',
	gray10: '#c5c5c5',
	gray11: '#C1C1C1',
	gray13: '#F2F2F2',
	gray14: '#eeeeee',
	gray15: '#cccccc',
	mainBlack: '#4D4B4C',
	mainWhite: '#FAFAFA',

	lightGray: PALETTE.argent,
	mediumGray: PALETTE.silver,
	skyBlue: PALETTE.cornflowerBlueLight,
	malibu: PALETTE.malibu,
	blueOnBlack: '#ACCFFE',

	// Borders
	borderColor: '#e5eaf1', //gray2
	borderColorOnBg: '#d9e0e9',
	borderGray: '#C5C5C5',
	borderAlmostLightGray: '#E3E3E3',
	// Brand
	brandColor1: '#54bca6', //Teal
	brandColor2: '#45cc6f', //Green
	brandColor3: '#54adcb', //Light blue
	brandColor4: '#626cdd', //Purple
	brandColor5: '#d5539a', //Pink
	brandColor6: '#d2ad4d', //yellow
	borderColorBg: '#d9e0e9',
	purpleLink: '#b0b0ff',
	mainPurple: '#5d70f8',
	mainPink: '#f126bc',
	mainGreen: '#6dc57b',
	mainYellow: '#eecf59',
	mainBlue: '#5085e5',
	mainTeal: '#49c8ae', //34CEAF
	lightTeal: '#e7fff2',
	lightPurple: '#ebedff',
	lightGreen: '#f1fef3',
	lightYellow: '#fcf6d5',
	lightBlue: '#f0fbfe',
	lightPink: '#f5eefd',

	pattensBlue: '#DEEBFF',
	transparent: 'transparent',

	// Toast
	tundora: '#414141',
	success: '#218f3f',
	info: '#104CB6',
	error: '#da202a',
	warning: '#f5bd4f',

	red: 'red',
	errorRed: PALETTE.sunsetRed,
	errorLight: '#F2C3C2',
	errorDarkRed: '#420b08',
	influencerBlue: '#104cb6',

	buttonGray: '#F2F2F2',
	secondaryGray: originalColors.Scorpion,

	porgressbarBackground: '#F2F2F2',
	progressbarColor: 'linear-gradient(89.99deg, rgba(111, 92, 164, 0.5) 7.19%, rgba(253, 118, 38, 0.5) 86.25%)',
	//
	// Body
	//
	body: {
		background: PALETTE.white,
		color: PALETTE.black,
	},

	statusTag: {
		draft: {
			backgroundColor: PALETTE.bananaMania,
			borderColor: PALETTE.hawaiianTan,
			color: PALETTE.hawaiianTan,
		},
		archived: {
			backgroundColor: PALETTE.azureishWhite,
			borderColor: PALETTE.toryBlue,
			color: PALETTE.toryBlue,
		},
		done: {
			backgroundColor: PALETTE.emperor,
			borderColor: PALETTE.emperor,
			color: PALETTE.white,
		},
		active: {
			borderColor: PALETTE.donegalGreen,
			backgroundColor: PALETTE.padua,
			color: PALETTE.donegalGreen,
		},
		danger: {
			borderColor: PALETTE.bulgarianRose,
			backgroundColor: PALETTE.channel,
			color: PALETTE.bulgarianRose,
		},
	},

	chart: {
		country: {
			first: {
				color: originalColors.PRICKLY_PURPLE,
				light: originalColors.GraySuit,
			},
			second: {
				color: originalColors.CaribbeanGreen,
				light: originalColors.SwansDown,
			},
			third: {
				color: originalColors.TurquoiseBlue,
				light: originalColors.Botticelli,
			},
			fourth: {
				color: originalColors.Jade,
				light: originalColors.GLACIAL_WATER_GREEN,
			},
			fifth: {
				color: originalColors.SanMarino,
				light: originalColors.INKED_SILK,
			},
		},
	},
	chartPrimary: originalColors.Affair,
	chartSecondary: originalColors.CaribbeanGreen,
	chartTertiary: originalColors.TurquoiseBlue,

	chartPrimaryLight: originalColors.GraySuit,
	chartSecondaryLight: originalColors.SwansDown,
	chartTeriaryLight: originalColors.Botticelli,

	//
	// LineChart colors
	//
	chartLineReach: originalColors.GoldenTainoi,
	chartLineImpressions: originalColors.TurquoiseBlue,
	chartLineTraffic: originalColors.Affair,
	chartLineAudience: originalColors.DeepCerulean,
	chartLineBudget: originalColors.Jade,
	chartLineEngagement: originalColors.SanMarino,

	// Admin

	admin: {
		darkPink: '#FFF1E3',
		danger: PALETTE.channel,
		lightPink: PALETTE.seashellPeach,
		mediumPink: PALETTE.negroniPeach,
		labelGray: PALETTE.davysGrey,
		tablePink: PALETTE.serande,
		active: PALETTE.donegalGreen,
	},

	//
	// DataLibrary
	//
	dataLibrary: {
		backgroundDashboard: PALETTE.royalVessel,
		background: PALETTE.royalVessel,
		sideNavHeading: {
			backgroundColorNone: PALETTE.transparent,
			backgroundColor: PALETTE.frenchLilac,
			hover: {
				backgroundColor: PALETTE.emperor,
				color: PALETTE.white,
			},
		},
		modal: {
			grayBorder: PALETTE.argent,
			footerBackground: PALETTE.antiFlashWhite,
			fontColor: PALETTE.davysGrey,
			dangerRed: PALETTE.channel,
			dangerRedBorder: PALETTE.sunsetRed,
		},
		input: {
			background: PALETTE.white,
			focus: PALETTE.royalBlue,
			placeholderText: PALETTE.argent,
		},
		list: {
			hoverBackground: PALETTE.azureishWhite,
			hoverIcon: PALETTE.mercuryGrey,
		},
		graph: {
			bar: {
				defaultContainer: PALETTE.emperor,
				defaultFiller: PALETTE.white,
			},
		},
		charts: {
			color1: PALETTE.smokey,
			color1Light: PALETTE.smokeyLight,
			color2: PALETTE.waterLeaf,
			color2Light: PALETTE.waterLeafLight,
			color3: PALETTE.aquaMarineBlue,
			color3Light: PALETTE.aquaMarineBlueLight,
			color4: PALETTE.fruitSalad,
			color4Light: PALETTE.fruitSaladLight,
			color5: PALETTE.sanMarino,
			color5Light: PALETTE.sanMarinoLight,
			color6: PALETTE.burntSienna,
			color6Light: PALETTE.burntSiennaLight,
			color7: PALETTE.astral,
			color7Light: PALETTE.astralLight,
			color8: PALETTE.creamCan,
			color8Light: PALETTE.creamCanLight,
			color9: PALETTE.rawSienna,
			color9Light: PALETTE.rawSiennaLight,
		},
		contentBody: PALETTE.magnoligaLight,
		contentHeaderBackgroundColor: PALETTE.magnolia,
		contentHeaderShadow: PALETTE.black,
		contentHeaderBorder: PALETTE.mercury,
		contentBackgroundColor: PALETTE.selago,
		graphPurple: PALETTE.smokey,
		lightBlue: PALETTE.aquaMarineBlue,
		darkPurple: PALETTE.frenchLilac,
		lightPurple: PALETTE.snuff,
		extralightPurple: PALETTE.royalVessel,
		black: PALETTE.black,
		blackHover: PALETTE.emperor,
		white: PALETTE.white,
		rawWhite: PALETTE.rawWhite,
		gray: PALETTE.silver,
		darkGray: PALETTE.taupeGrey,
		value: PALETTE.black,
		noData: PALETTE.argent,
		divider: PALETTE.argent,
		buttonColor: PALETTE.snuff,
		labelGray: PALETTE.davysGrey,
		dangerRed: PALETTE.channel,
		infoBlue: PALETTE.toryBlue,
		button: {
			cancelBackground: PALETTE.antiFlashWhite,
			createBackground: PALETTE.wildSand,
			createText: PALETTE.taupeGrey,
		},
		filter: {
			showButton: PALETTE.snuff,
		},
		backgroundGray: PALETTE.wildSand,
		darkHoverColor: PALETTE.emperor,
		previewIconColor: PALETTE.smokey,
		chartExplanationColor: PALETTE.toryBlue,
		csvFileUpload: {
			helpText: PALETTE.taupeGrey,
		},
		csvFileUploadModal: {
			importButtonBgHover: PALETTE.taupeGrey,
			cancelButtonBg: PALETTE.antiFlashWhite,
			dndArea: {
				helpText: PALETTE.davysGrey,
				background: PALETTE.antiFlashWhite,
				border: PALETTE.silver,
				buttonColor: PALETTE.davysGrey,
				fileAcceptBg: PALETTE.argent,
			},
			fileItem: {
				background: PALETTE.concrete,
			},
			errorMessage: {
				background: PALETTE.channel,
				color: PALETTE.bulgarianRose,
			},
			uploadingMessage: {
				loadingBackground: PALETTE.antiFlashWhite,
				completedBackgroud: PALETTE.padua,
				completedColor: PALETTE.donegalGreen,
			},
			iconButton: {
				hoverBg: PALETTE.argent,
			},
		},
	},

	shareModal: {
		gray: PALETTE.taupeGrey,
		black: PALETTE.black,
		white: PALETTE.white,
		button: {
			disabledBackground: PALETTE.wildSand,
			disabledText: PALETTE.argent,
		},
		infoBlue: PALETTE.toryBlue,
	},

	discovery: {
		background: PALETTE.alabaster,
		grayBackground: PALETTE.reachGreen,
		autoCompleteHover: PALETTE.antiFlashWhite,
		border: PALETTE.alabaster,
		tagBorder: PALETTE.black,
		tagBorderSmall: PALETTE.taupeGrey,
		inputFocus: PALETTE.royalBlue,
		link: PALETTE.royalBlue,
		iconHover: PALETTE.mercury,
		black: PALETTE.black,
		tableItemBackground: PALETTE.eggWhite,
		tableItemOddBackground: PALETTE.kulSharifBlue,
		gray: '#ccc',
		detailButtonActive: PALETTE.paleEgg,
		grayText: PALETTE.davysGrey,
		lookalikePillBackground: PALETTE.antiFlashWhite,
		lookalikeCancel: PALETTE.taupeGrey,
		platformTab: {
			background: PALETTE.alabaster,
			selected: {
				background: PALETTE.emperor,
				color: PALETTE.antiFlashWhite,
			},
		},
		label: {
			cannotContact: {
				background: PALETTE.lightPlacidBlue,
				color: PALETTE.livid,
			},
		},
		helpText: {
			background: PALETTE.emperor,
			color: PALETTE.antiFlashWhite,
		},
	},

	//
	// Label
	//
	labelColor: PALETTE.taupeGrey,

	//
	// Links
	//
	link: {
		color: PALETTE.black,
		borderColor: PALETTE.black,
		borderColorHover: PALETTE.mistyRose,
	},

	//
	// Integrated inbox
	//
	iIListColor: originalColors.MineShaft,
	iIListItemContentBorderColor: originalColors.Mercury,
	iIConversationsListItemHoverBackground: PALETTE.azureishWhite,
	iIConversationsListItemActiveBackground: PALETTE.azureishWhite,

	//
	// Buttons
	//
	button: {
		primary: {
			background: PALETTE.white,
			backgroundHover: PALETTE.emperor,
			colorHover: PALETTE.white,
			borderColor: PALETTE.black,
			color: PALETTE.black,
			disabled: {
				color: PALETTE.argent,
				borderColor: PALETTE.taupeGrey,
			},
		},
		sencondary: {
			borderColor: PALETTE.white,
			color: PALETTE.royalBlue,
			colorHover: PALETTE.royalBlue,
			backgroundHover: PALETTE.white,
			disabled: {
				color: PALETTE.argent,
			},
		},
		secondary: {
			background: PALETTE.concrete,
			borderColor: PALETTE.concrete,
			color: PALETTE.davysGrey,
			colorHover: PALETTE.white,
			backgroundHover: PALETTE.emperor,
			borderColorHover: PALETTE.emperor,
			disabled: {
				color: PALETTE.argent,
			},
		},
		dark: {
			background: PALETTE.emperor,
			color: PALETTE.white,
			disabled: {
				background: PALETTE.mercuryGrey,
				color: PALETTE.taupeGrey,
				borderColor: PALETTE.mercuryGrey,
			},
		},
		ghost: {
			background: PALETTE.transparent,
		},
		cancelBackground: PALETTE.antiFlashWhite,
		danger: {
			borderColor: PALETTE.sunsetRed,
			backgroundColor: PALETTE.channel,
			color: PALETTE.black,
		},
		decline: {
			color: PALETTE.cavernPink,
		},
	},

	form: {
		input: {
			backgroundColor: PALETTE.white,
			disabledBackgroundColor: PALETTE.antiFlashWhite,
			disabledBorder: PALETTE.taupeGrey,
			disabledColor: PALETTE.taupeGrey,
			border: PALETTE.black,
		},
		validation: {
			error: {
				color: PALETTE.alizarinCrimson,
			},
		},
		dropzone: {
			background: PALETTE.gainsboro,
			darkBackground: PALETTE.emperor,
			borderColor: PALETTE.silver,
			textColor: PALETTE.taupeGrey,
			hoverTextColor: PALETTE.white,
		},
		hoverSelectDropdown: PALETTE.antiFlashWhite,
	},

	//
	// Dropdowns
	//
	dropdown: {
		button: {
			backgroundHover: PALETTE.antiFlashWhite,
			notificationCircle: PALETTE.sunsetRed,
			notificationCircleBorder: PALETTE.mercury,
		},
		menu: {
			background: PALETTE.white,
			borderColor: PALETTE.black,
			boxShadowColor: '#00000029',
		},
		menuItem: {
			color: PALETTE.black,
			background: PALETTE.transparent,
			backgroundHover: PALETTE.antiFlashWhite,
			hoverColor: PALETTE.antiFlashWhite,
			dangerBackgroundHover: PALETTE.channel,
		},
		tooltip: {
			background: PALETTE.black,
			boxShadowColor: '#00000029',
			color: PALETTE.antiFlashWhite,
		},
	},

	//
	// User dropdown
	//
	userDropdown: {
		white: PALETTE.white,
		background: PALETTE.antiFlashWhite,
		border: PALETTE.argent,
		menuItemBorderColor: PALETTE.mercury,
		menuItemHover: PALETTE.silver,
		accountMenuListItemHoverColor: PALETTE.antiFlashWhite,
		secondaryGray: PALETTE.davysGrey,
	},

	//
	// Notification dropdown
	//
	notificationDropdown: {
		titleColor: PALETTE.black,
		createdAtColor: PALETTE.davysGrey,
		menuItem: {
			unreadBackgroundColor: PALETTE.azureishWhite,
			newNotificationCircle: PALETTE.malibu,
			newNotificationCircleBorder: PALETTE.eggWhite,
		},
	},

	//
	// Main navigation
	//
	MainNavigation: {
		background: PALETTE.alabaster,
		backgroundOpen: PALETTE.antiFlashWhite,
		color: PALETTE.black,
		colorHover: PALETTE.black,
		borderColor: PALETTE.black,
		menuLinkHoverBorderColor: PALETTE.black,
		hamburgerMenuColor: PALETTE.black,
	},

	//
	// Header
	//
	header: {
		background: PALETTE.alabaster,
		color: PALETTE.black,
	},

	//
	// Checkbox
	//
	checkbox: {
		border: PALETTE.taupeGrey,
		selectedBackground: PALETTE.black,
		checkMark: PALETTE.white,
	},

	//
	// Modal
	//
	modal: {
		background: PALETTE.white,
		borderColor: PALETTE.black,
		cancelBackground: PALETTE.antiFlashWhite,
		action: {
			border: PALETTE.argent,
			labelColor: PALETTE.davysGrey,
			cancelBackground: PALETTE.antiFlashWhite,
			darkColor: PALETTE.emperor,
			disabledBackground: PALETTE.wildSand,
			disabledText: PALETTE.argent,
			white: PALETTE.white,
		},
		iconBackground: {
			danger: PALETTE.channel,
			signIn: PALETTE.aquaHaze,
		},
	},

	modalV2: {
		background: PALETTE.orochimaru,
	},

	//
	// Input
	//
	input: {
		background: PALETTE.white,
		borderColor: PALETTE.taupeGrey,
		borderColorHover: PALETTE.black,
		borderColorDisabled: PALETTE.mercury,
		placeholder: PALETTE.taupeGrey,
		placeholderDisabled: PALETTE.argent,
		inputFocus: PALETTE.royalBlue,
	},

	//
	// Textarea
	//
	textarea: {
		background: 'transparent',
		headingColor: PALETTE.taupeGrey,
		borderBottomColor: PALETTE.argent,
		headingColorSelected: PALETTE.black,
	},

	//
	// Toggle Panel
	//
	togglePanel: {
		HeadingColor: PALETTE.taupeGrey,
		borderBottomColor: PALETTE.argent,
		active: {
			HeadingColor: PALETTE.black,
		},
		hover: {
			HeadingColor: PALETTE.black,
		},
	},

	//
	// Select
	//
	select: {
		placeholder: PALETTE.argent,
		indicator: PALETTE.black,
		controlBorderColor: PALETTE.black,
		optionBackground: PALETTE.antiFlashWhite,
		optionSelected: PALETTE.black,
		controlBoxshadow: PALETTE.black,
		menuBoxshadow: `${PALETTE.black}29`,
	},

	CTALinks: {
		color: PALETTE.blizzyBlueberry,
	},

	dashboard: {
		actionBar: {
			linkBorderColor: PALETTE.black,
		},
	},

	card: {
		background: PALETTE.white,
	},

	campaignCard: {
		themeBackground: {
			earlyDawn: PALETTE.earlyDawn,
			azureishWhite: PALETTE.azureishWhite,
			isabelline: PALETTE.isabelline,
			dedication: PALETTE.dedication,
		},
		backgroundColor: PALETTE.alabaster,
		borderColor: PALETTE.black,
		statsBadgeSpanColor: PALETTE.davysGrey,
	},

	campaignsCard: {
		headingColor: PALETTE.black,
		listItemBorderColor: originalColors.Mercury,
		listItemBackgroundHover: PALETTE.antiFlashWhite,
	},

	campaignsModal: {
		listItemBorderColor: originalColors.Mercury,
		listItemBackgroundHover: PALETTE.antiFlashWhite,
		linkColor: PALETTE.black,
	},

	integratedInboxCard: {
		listItemBorderColor: originalColors.Mercury,
		listItemBackgroundHover: PALETTE.antiFlashWhite,
		titleColor: PALETTE.black,
		subTitleColor: PALETTE.davysGrey,
		newMessageCircleBackground: '#3972e4', // outside of palette
		newMessageCircleBorderColor: PALETTE.white,
	},

	//
	// Create campaign
	//
	createCampaign: {
		selectType: {
			buttonBackground: PALETTE.white,
			buttonBorder: PALETTE.argent,
			campaignIconBackground: PALETTE.antiqueWhite,
			amCampaignIconBackground: PALETTE.azureishWhite,
		},
		background: PALETTE.reachGreen,
		widgetHoverBackground: PALETTE.dedication,
		subTextColor: PALETTE.taupeGrey,
		textareaBackground: PALETTE.transparent,
	},

	//
	// Avatars
	//
	avatar: {
		borderColor: PALETTE.black,
		backgroundColor: PALETTE.feta,
	},

	//
	// Settings view
	//
	settings: {
		background: PALETTE.white,
		border: '#E3E3E3',
		brandList: {
			backgroundHover: PALETTE.taupeGrey,
		},
		darkGray: PALETTE.emperor,
		labelGray: PALETTE.davysGrey,
		textGray: PALETTE.taupeGrey,
		buttonBorder: PALETTE.argent,
		facebookButton: PALETTE.blizzyBlueberry,
		avatarBackground: PALETTE.concret,
		cardBackground: PALETTE.mysticLight,
		green: PALETTE.fruitSalad,
		focusBlue: '#ACCFFE',
		slider: {
			checked: PALETTE.cornflowerBlue,
		},
	},

	// Campaign
	campaign: {
		background: PALETTE.reachGreen,
		darkYellow: PALETTE.coconutCream,
		blueLinkColor: PALETTE.toryBlue,
		darkGray: PALETTE.emperor,
		textGray: PALETTE.davysGrey,
		darkHover: PALETTE.emperor,
		subText: PALETTE.taupeGrey,
		briefSummaryBackground: PALETTE.black,
		dividerBorderColor: PALETTE.taupeGrey,
		statusTag: {
			draft: {
				backgroundColor: PALETTE.azureishWhite,
				borderColor: PALETTE.azureishWhite,
				color: PALETTE.toryBlue,
			},
			active: {
				borderColor: PALETTE.donegalGreen,
				background: PALETTE.spatialSpirit,
				color: PALETTE.donegalGreen,
			},
			archived: {
				background: PALETTE.azureishWhite,
			},
		},
		influencerList: {
			gray: PALETTE.mercuryGrey,
		},
	},

	//
	// Campaign overview
	//
	campaignOverview: {
		background: PALETTE.aquaHaze,
		campaignListItemBorder: PALETTE.powderBlue,
		campaignListItemDark: PALETTE.botticelli,
		campaignListItemLight: PALETTE.azureishWhite,
		transparent: PALETTE.transparent,
		black: PALETTE.emperor,
		iconHover: PALETTE.mercury,
		gray: '#ccc',
		white: PALETTE.white,
	},

	//
	// Form card
	//
	formCard: {
		background: PALETTE.white,
		borderColor: PALETTE.black,
		color: PALETTE.taupeGrey,
	},

	//
	// Add account card
	//
	addAccountCard: {
		accountTypeBorderColor: PALETTE.mercury,
	},

	//
	// Influencer tables
	//
	influencerList: {
		borderColor: PALETTE.mercury,
	},

	//
	// Content review
	//
	contentReview: {
		usernameColor: PALETTE.taupeGrey,
		dateColor: PALETTE.davysGrey,
		imageBackgroundColor: PALETTE.alabaster,
		textareaBorderColor: PALETTE.black,
		modalBorderTopColor: PALETTE.mercury,
		cancelButtonColor: PALETTE.taupeGrey,
		accordionTitleColor: PALETTE.black,
		commentListBorderColor: PALETTE.mercury,
		commentBackgroundColor: PALETTE.antiFlashWhite,
		commentDateColor: PALETTE.taupeGrey,
		statusBackgroundColor: PALETTE.black,
		statusColor: PALETTE.antiFlashWhite,
		sidebarItemActiveBackgroundColor: PALETTE.alabaster,
		sidebarItemColor: PALETTE.davysGrey,
		fullscreenButtonBackground: PALETTE.davysGrey,
	},

	//
	// Brief page
	//
	briefPage: {
		default: {
			background: PALETTE.royalVessel,
		},
		campaignInfo: {
			background: PALETTE.reachGreen,
		},
		campaignAssignments: {
			background: PALETTE.bleachWhite,
			dateInfo: PALETTE.reachGreen,
			divideLine: '#cccccc',
			detail: {
				title: PALETTE.taupeGrey,
			},
		},
		campaignCommissions: {
			background: PALETTE.bleachWhite,
			label: PALETTE.reachGreen,
			divideLine: '#cccccc',
			info: PALETTE.mercuryGrey,
		},
		joinCampaign: {
			ligthBlue: PALETTE.mysticLight,
			infoBlue: PALETTE.blizzyBlueberry,
			lightGreen: PALETTE.padua,
			joinButtonHover: PALETTE.cornflowerBlueLight,
		},
	},

	list: {
		item: {
			bottomBorder: PALETTE.mercuryGrey,
		},
	},

	//
	// Lists page
	//
	listsPage: {
		background: PALETTE.earlyDawn,
		tableHeadRowBorderColor: PALETTE.black,
		inputBorderBottom: PALETTE.black,
	},

	//
	// Influencer view
	//
	influencerView: {
		background: PALETTE.white,
		tdHoverBackground: PALETTE.azureishWhite,
		uploadContent: {
			grey: PALETTE.antiFlashWhite,
		},
		card: {
			backgroundColor: PALETTE.white,
			borderColor: PALETTE.silver,
			shadowColor: 'rgba(0, 0, 0, 0.16)',
			lightGray: PALETTE.taupeGrey,
		},
		invoiceDetail: {
			background: PALETTE.dedication,
		},
		pendingInvites: {
			campaignTrBorderColor: PALETTE.black,
			warningMessageBackground: PALETTE.black,
			warningMessageColor: PALETTE.white,
			warningMessageLinkColor: PALETTE.royalBlue,
		},
		grayText: PALETTE.paleSky,
		darkGray: PALETTE.mineShaft,
	},

	//
	// Influencer view
	//
	dateTag: {
		notPassedBackground: '#bff0cf',
		almostPassedBackground: '#ffe6b2',
		passedBackground: 'rgba(242, 195, 194, 0.8)',
		notPassedColor: '#115704',
		almostPassedColor: '#e3813e',
		passedColor: '#420b08',
	},

	//
	// Influencer Inbox
	//
	influencerInbox: {
		background: PALETTE.white,
		backLinkColor: PALETTE.royalBlue,
		campaignItem: {
			activeBackgroundColor: PALETTE.bleachWhite,
			createdAtColor: PALETTE.davysGrey,
			latestMessageTextColor: PALETTE.taupeGrey,
			latestMessageTextBorderColor: PALETTE.mercury,
			notificationCircleBackground: '#3972e4',
		},
		messageWindow: {
			headerBorderColor: PALETTE.mercury,
			campaignNameColor: PALETTE.black,
			startDateColor: PALETTE.taupeGrey,
			startConversationText: PALETTE.taupeGrey,
			messageTextBackground: PALETTE.concrete,
			messageTextReverseBackground: PALETTE.bleachWhite,
			messageTextContainerBorderColor: PALETTE.mercury,
		},
		newConversation: {
			campaignsListItem: {
				borderBottomColor: PALETTE.mercury,
				backgroundColor: PALETTE.bleachWhite,
				brandNameColor: PALETTE.taupeGrey,
			},
		},
		newMessages: {
			itemBackground: PALETTE.bleachWhite,
		},
	},

	//
	// Influencer Payment view
	//
	payment: {
		mediumGray: PALETTE.concret,
		iconBackground: PALETTE.padua,
		background: PALETTE.butteryWhite,
		table: {
			borderColor: PALETTE.emperor,
			borderLightColor: PALETTE.argent,
		},
		link: PALETTE.toryBlue,
		text: {
			sendingInvoice: PALETTE.fruitSalad,
			paid: PALETTE.donegalGreen,
			helpText: PALETTE.taupeGrey,
			receiptHelpText: PALETTE.davysGrey,
			statusText: PALETTE.hawaiianTan,
		},
		copy: {
			hoverBackground: PALETTE.mercuryGrey,
		},
		detailTable: {
			background: PALETTE.antiFlashWhite,
			lockIconBackround: PALETTE.padua,
		},
		receiptRow: {
			hoverBackround: PALETTE.mercuryGrey,
		},
		pinebucketWarning: {
			background: PALETTE.channel,
		},
		cardBackground: PALETTE.earlyDawnYellow,
		cardHr: PALETTE.paleEgg,
		cardLightBackground: PALETTE.eggWhite,
		grayText: PALETTE.taupeGrey,
		green: PALETTE.donegalGreen,
		hoverIcon: PALETTE.bleachWhite,
		tag: {
			backgroundColor: PALETTE.azureishWhite,
			borderColor: PALETTE.toryBlue,
			color: PALETTE.toryBlue,
		},
		warningDark: PALETTE.bulgarianRose,
		errorBackground: PALETTE.channel,
		statusTag: {
			background: PALETTE.cornflowerBlue,
			color: PALETTE.toryBlue,
		},
		hoverBackground: PALETTE.argent,
		lightGreen: PALETTE.padua,
		paymentOptionBlue: PALETTE.cornflowerBlue,
		lightBlue: PALETTE.aquaHaze,
		gray: PALETTE.argent,
		lightGray: PALETTE.silver,
	},

	//
	// Influencer Affiliate view
	//
	affiliate: {
		icon: {
			fill: PALETTE.taupeGrey,
			hoverBackground: PALETTE.argent,
			hoverFill: PALETTE.black,
		},
		background: PALETTE.butteryWhite,
		activeCampaigns: {
			table: {
				rowBackgroundColor: PALETTE.eggWhite,
				rowDarkBackgroundColor: PALETTE.earlyDawnYellow,
				hover: {
					row: {
						backgrundColor: PALETTE.emperor,
						color: PALETTE.white,
					},
				},
			},
		},
		earnings: {
			helpTextColor: PALETTE.taupeGrey,
			currentAmountColor: PALETTE.donegalGreen,
		},
	},

	// Login
	login: {
		text: PALETTE.taupeGrey,
		link: PALETTE.blizzyBlueberry,
		verifiedBackground: PALETTE.concret,
	},

	//
	// Three column layout
	//
	threeCol: {
		sideColumnBackground: PALETTE.aquaHaze,
		middleColumnBackground: PALETTE.white,
		sidebarHeadingBackground: PALETTE.azureishWhite,
	},

	//
	// Content Management (Influencer)
	//
	contentManagement: {
		gray: PALETTE.taupeGrey,
		iconBackground: PALETTE.aquaHaze,
		errorText: PALETTE.alizarinCrimson,
		infoBlue: PALETTE.blizzyBlueberry,
		grayText: PALETTE.davysGrey,
		background: PALETTE.aquaHaze,
		sidebar: {
			borderColor: PALETTE.swansDown,
		},
		sideNavHeading: {
			hover: {
				backgroundColor: PALETTE.emperor,
				color: PALETTE.white,
			},
		},
		campaignList: {
			listItem: {
				borderColor: PALETTE.azureishWhite,
				hover: {
					backgroundColor: PALETTE.emperor,
					borderColor: PALETTE.emperor,
					color: PALETTE.white,
				},
			},
		},
		helpText: PALETTE.taupeGrey,
		assignmentTypeList: {
			background: PALETTE.aquaHaze,
			reviewStatusLabelColor: PALETTE.toryBlue,
			AssignmentTypeLabelColor: PALETTE.black,

			listItem: {
				borderColor: PALETTE.azureishWhite,
				hover: {
					backgroundColor: PALETTE.emperor,
					borderColor: PALETTE.emperor,
					color: PALETTE.white,
				},
			},
		},
		briefInfo: {
			backgroundColor: PALETTE.mystic,
			borderBottomColor: PALETTE.swansDown,
			headingColor: PALETTE.davysGrey,
			briefButtonBackground: PALETTE.botticelli,
			CTAContainerBorderColor: PALETTE.silver,
			background: {
				color: PALETTE.taupeGrey,
			},
			tag: {
				borderColor: PALETTE.hawaiianTan,
				background: PALETTE.bananaMania,
				color: PALETTE.hawaiianTan,
			},
			accordion: {
				borderBottomColor: PALETTE.argent,
				headingColor: PALETTE.taupeGrey,
				selected: {
					headingColor: PALETTE.davysGrey,
				},
			},
		},
		contentSection: {
			backgroundColor: PALETTE.white,
		},
		wizard: {
			header: {
				borderBottomColor: PALETTE.swansDown,
			},
			steps: {
				borderBottomColor: PALETTE.transparent,
				circle: {
					backgroundColor: PALETTE.rawWhite,
					color: PALETTE.taupeGrey,
					borderColor: PALETTE.taupeGrey,
				},
				name: {
					color: PALETTE.taupeGrey,
				},
				done: {
					backgroundColor: PALETTE.emperor,
					borderColor: PALETTE.emperor,
					color: PALETTE.rawWhite,
					name: {
						color: PALETTE.black,
					},
				},
			},
		},
		card: {
			background: PALETTE.botticelli,
			borderColor: PALETTE.black,
			oddLabelColor: PALETTE.davysGrey,
			textareaBorderColor: PALETTE.taupeGrey,
			dividerColor: PALETTE.azureishWhite,
			indexColor: PALETTE.taupeGrey,
			grayBackground: PALETTE.concret,
			grayBorder: PALETTE.taupeGrey,
		},
		upload: {
			background: PALETTE.aquaHaze,
			dndAreaBorderColor: PALETTE.malibuLight,
			color: PALETTE.taupeGrey,
		},
		reviewStatusTag: {
			backgroundColor: PALETTE.azureishWhite,
			borderColor: PALETTE.azureishWhite,
			color: PALETTE.toryBlue,
		},
		pendingMedia: {
			backgroundColor: PALETTE.antiFlashWhite,
		},
		comments: {
			commentsButton: {
				background: PALETTE.azureishWhite,
				color: PALETTE.taupeGrey,
				hover: {
					background: PALETTE.black,
					color: PALETTE.rawWhite,
				},
			},
			user: {
				borderColor: PALETTE.black,
			},
			commentsWrapper: {
				backgroundColor: PALETTE.concret,
				borderColor: PALETTE.argent,
			},
			comment: {
				backgroundColor: PALETTE.botticelli,
			},
			textArea: {
				color: PALETTE.davysGrey,
				backgroundColor: PALETTE.concrete,
			},
			MessageBar: {
				backgroundColor: PALETTE.concrete,
			},
			startText: {
				color: PALETTE.black,
			},
			commenStatusText: {
				color: PALETTE.taupeGrey,
			},
			tabs: {
				tab: {
					background: PALETTE.antiFlashWhite,
					color: PALETTE.taupeGrey,
					hover: {
						background: PALETTE.emperor,
						color: PALETTE.concrete,
					},
				},
				MessageCount: {
					background: PALETTE.mercuryGrey,
					hover: {
						background: PALETTE.scorpion,
					},
				},
			},
		},
		post: {
			addMediaItemTextColor: PALETTE.taupeGrey,
			addMediaItemBorderColor: PALETTE.taupeGrey,
			addMediaItemBackground: PALETTE.antiFlashWhite,
			item: {
				background: PALETTE.white,
				borderColor: PALETTE.black,
				mediaBorderColor: PALETTE.swansDown,
			},
		},
		story: {
			originalLink: {
				background: PALETTE.wildSand,
				border: PALETTE.argent,
				iconBackground: PALETTE.mercuryGrey,
				color: PALETTE.taupeGrey,
				editModeIconBackground: PALETTE.azureishWhite,
				editModeBorder: PALETTE.black,
				eidtModeBackground: PALETTE.white,
				editModeColor: PALETTE.black,
			},
		},
		statistics: {
			connectInfoAlertBackground: '#C4EED2', // color variable will be added into next theme version
			color: '#072602', // color variable will be added into next theme version
			grayBorder: '#C4EED2', // color variable will be added into next theme version
		},
		Summary: {
			borderBottomColor: originalColors.Mercury,
		},
		assignmentList: {
			borderColor: PALETTE.black,
			background: PALETTE.antiFlashWhite,
			boxShadowColor: PALETTE.black,
			ContentBorderColor: PALETTE.black,
			statusBadge: {
				pending: {
					borderColor: PALETTE.hawaiianTan,
					color: PALETTE.hawaiianTan,
					background: PALETTE.bananaMania,
				},
				approved: {
					borderColor: PALETTE.transparent,
					color: PALETTE.blueRibbon,
					background: PALETTE.transparent,
				},
				statsUploaded: {
					borderColor: PALETTE.donegalGreen,
					color: PALETTE.donegalGreen,
					background: PALETTE.spatialSpirit,
				},
				declined: {
					borderColor: PALETTE.bulgarianRose,
					color: PALETTE.sunsetRed,
					background: PALETTE.channel,
				},
				currentStateText: {
					color: PALETTE.davysGrey,
					approved: {
						color: PALETTE.fruitSalad,
					},
				},
			},
		},
		user: {
			background: PALETTE.bleachWhite,
			border: PALETTE.wildMaple,
			navigationSection: {
				header: {
					background: PALETTE.wildMaple,
					hover: {
						background: PALETTE.emperor,
						color: PALETTE.white,
					},
				},
				listItem: {
					background: 'transparent',
					borderColor: PALETTE.fakeJade,
					hover: {
						background: PALETTE.fakeJade,
						borderColor: PALETTE.fakeJade,
						color: PALETTE.white,
					},
				},
			},
			contentSection: {
				background: PALETTE.doeskin,
				header: {
					recipient: {
						to: PALETTE.taupeGrey,
						color: PALETTE.black,
					},
				},
				date: {
					color: PALETTE.taupeGrey,
				},
				bubble: {
					avatar: {
						border: PALETTE.black,
					},
					right: {
						background: PALETTE.fakeJade,
					},
					left: {
						backgroud: PALETTE.paleEgg,
					},
				},
				chatInput: {
					background: PALETTE.white,
					border: PALETTE.wildMaple,
					color: PALETTE.davysGrey,
					sendable: {
						background: PALETTE.black,
						color: PALETTE.white,
					},
				},
			},
			detailSection: {
				influencerInfo: {
					background: PALETTE.wildMaple,
				},
				campaignInfo: {
					background: PALETTE.bleachWhite,
					card: {
						border: PALETTE.black,
						background: PALETTE.beePollen,
						dateColor: PALETTE.davysGrey,
					},
				},
			},
		},
		tabMenu: {
			color: PALETTE.toryBlue,
		},
		fileUploadProgress: {
			background: PALETTE.white,
			helptext: {
				color: PALETTE.taupeGrey,
				blueColor: PALETTE.royalBlue,
			},
			checkMarkCircle: {
				background: PALETTE.aquaHaze,
			},
			loadingContainer: {
				background: PALETTE.concret,
			},
		},
	},
	//
	// Influencer Dashboard
	//
	influencerDashboard: {
		background: PALETTE.white,
		invitation: {
			border: PALETTE.fakeJade,
			background: PALETTE.wildMaple,
			imageBorder: PALETTE.taupeGrey,
			toBriefButton: PALETTE.fakeJade,
			newTagBorder: PALETTE.blizzyBlueberry,
			newTagBackground: PALETTE.azureishWhite,
			fullTagBorder: PALETTE.bulgarianRose,
			fullTagBackground: PALETTE.channel,
		},
		campaign: {
			border: PALETTE.black,
			background: PALETTE.wildMaple,
			imageBorder: PALETTE.taupeGrey,
			blockBackground: PALETTE.doeskin,
			dotBorder: PALETTE.bulgarianRose,
			dotBackground: PALETTE.channel,
			darkButton: {
				background: PALETTE.black,
				color: PALETTE.antiFlashWhite,
			},
			assignmentDate: PALETTE.taupeGrey,
		},
		todo: {
			background: PALETTE.concrete,
			item: {
				background: PALETTE.wildMaple,
				evenBackground: PALETTE.paleEgg,
				border: PALETTE.bleachWhite,
				hoverBackground: PALETTE.black,
				hoverColor: PALETTE.antiFlashWhite,
			},
			tag: {
				late: {
					background: PALETTE.channel,
					color: PALETTE.bulgarianRose,
				},
				upcoming: {
					background: PALETTE.azureishWhite,
					color: PALETTE.blizzyBlueberry,
				},
				pending: {
					background: PALETTE.bananaMania,
					color: PALETTE.hawaiianTan,
				},
				publish: {
					background: PALETTE.spatialSpirit,
					color: PALETTE.donegalGreen,
				},
				done: {
					background: PALETTE.mercury,
					color: PALETTE.black,
				},
			},
		},
		noState: {
			background: PALETTE.wildMaple,
		},
	},
	//
	// Campaign invites
	//
	campaignInvite: {
		emptyState: {
			background: PALETTE.concret,
		},
	},

	//
	// New Integrated Inbox
	//
	integratedInbox: {
		lightBlue: PALETTE.aquaHaze,
		mediumBlue: PALETTE.azureishWhite,
		background: PALETTE.bleachWhite,
		border: PALETTE.wildMaple,
		campaignSection: {
			header: {
				background: PALETTE.wildMaple,
				hover: {
					background: PALETTE.black,
					color: PALETTE.white,
				},
				blast: {
					background: PALETTE.bleachWhite,
					hover: {
						background: PALETTE.black,
						color: PALETTE.antiFlashWhite,
					},
				},
			},
			listItem: {
				unreadMark: {
					background: PALETTE.royalBlue,
					border: PALETTE.wildMaple,
				},
				campaign: {
					background: PALETTE.bleachWhite,
					border: PALETTE.wildMaple,
				},
				influencer: {
					background: PALETTE.bleachWhite,
					messageColor: PALETTE.taupeGrey,
				},
				hover: {
					background: PALETTE.emperor,
					color: PALETTE.white,
				},
			},
		},
		chatSection: {
			background: PALETTE.doeskin,
			header: {
				recipient: {
					to: PALETTE.taupeGrey,
					color: PALETTE.black,
				},
			},
			date: {
				color: PALETTE.taupeGrey,
			},
			bubble: {
				avatar: {
					border: PALETTE.black,
				},
				right: {
					background: PALETTE.fakeJade,
				},
				left: {
					backgroud: PALETTE.paleEgg,
				},
				attachment: {
					background: PALETTE.transparent,
					border: PALETTE.negroni,
				},
				text: PALETTE.emperor,
				fileTypeLabel: PALETTE.taupeGrey,
				DownloadTimestamp: PALETTE.taupeGrey,
			},
			chatInput: {
				background: PALETTE.white,
				border: PALETTE.wildMaple,
				color: PALETTE.davysGrey,
				sendable: {
					background: PALETTE.black,
					color: PALETTE.white,
				},
			},
		},
		detailSection: {
			influencerInfo: {
				background: PALETTE.wildMaple,
			},
			campaignInfo: {
				background: PALETTE.bleachWhite,
				card: {
					border: PALETTE.black,
					background: PALETTE.beePollen,
					dateColor: PALETTE.davysGrey,
				},
			},
		},
		blastModal: {
			color: {
				seeAll: PALETTE.royalBlue,
				label: PALETTE.taupeGrey,
			},
			item: {
				hover: {
					background: PALETTE.black,
					color: PALETTE.white,
				},
			},
			search: {
				background: PALETTE.white,
				item: {
					hover: {
						background: PALETTE.azureishWhite,
					},
				},
				influencerTag: {
					border: PALETTE.black,
					background: PALETTE.antiFlashWhite,
				},
			},
			shortcut: {
				background: PALETTE.azureishWhite,
				color: PALETTE.blizzyBlueberry,
				border: PALETTE.argent,
			},
			input: {
				border: PALETTE.argent,
				boxShadowColor: '#3333331a',
			},
		},
	},

	//
	// Direct message modal
	//
	directMessageModal: {
		content: {
			subTextColor: PALETTE.taupeGrey,
			color: PALETTE.black,
		},
	},
	affiliateLinkGenerator: {
		helpText: {
			color: PALETTE.taupeGrey,
		},
		inputWrapper: {
			background: PALETTE.rawWhite,
			borderColor: PALETTE.taupeGrey,
			hover: {
				borderColor: PALETTE.black,
				inputFocus: PALETTE.royalBlue,
			},
		},
		linkContainer: {
			background: PALETTE.botticelli,
		},
		generateNewLink: {
			color: PALETTE.toryBlue,
		},
	},
	StoryList: {
		selected: {
			border: PALETTE.black,
			boxShadow: `#33333310`,
		},
		postedAt: PALETTE.taupeGrey,
	},
	// Signup
	signup: {
		background: '#FAFAFA',
		card: {
			background: '#F2F2F2',
		},
	},

	// Onboarding
	onboarding: {
		option: {
			border: PALETTE.mercuryGrey,
			selected: {
				background: PALETTE.emperor,
				color: PALETTE.antiFlashWhite,
			},
			error: {
				border: PALETTE.sunsetRed,
			},
		},
		divider: PALETTE.silver,
		button: {
			hover: {
				background: PALETTE.toryBlue,
				color: PALETTE.white,
			},
		},
	},

	// Affiliate Market place
	AddKeyword: {
		keywordBackground: PALETTE.antiFlashWhite,
		keywordColor: PALETTE.black,
		addButtonColor: PALETTE.toryBlue,
		addButtonBackgroundHover: PALETTE.antiFlashWhite,
		searchBoxBackground: PALETTE.white,
		searchBoxBoxShadow: 'rgba(92, 104, 128, 0.16)',
		searchInputBorder: PALETTE.black,
		searchInputBackground: PALETTE.antiFlashWhite,
		searchInputBoxShadow: PALETTE.royalBlue,
		suggestionItemBackgroundHover: PALETTE.argent,
		suggestionItemPlacehoderColor: PALETTE.taupeGrey,
	},
	AffiliateMarketplaceCreation: {
		topbarBackground: PALETTE.reachGreen,
		blockCardBackground: PALETTE.white,
		blockCardBorder: PALETTE.silver,
		inputLabelColor: PALETTE.davysGrey,
		inputBackground: PALETTE.concret,
		inputFocusedBackground: PALETTE.white,
		optionBoxBackground: PALETTE.concret,
		optionBoxTextColor: PALETTE.taupeGrey,
		optionAccentColor: PALETTE.toryBlue,
		textareaBorder: PALETTE.silver,
		textareaBackground: PALETTE.concret,
		helpText: PALETTE.taupeGrey,
		dividerLine: PALETTE.mercuryGrey,
	},
	AffiliateMarketplace: {
		rightContainerBackground: PALETTE.backdropGrey,
	},
	AffiliateCard: {
		border: PALETTE.argent,
		background: PALETTE.antiFlashWhite,
		bodyBackground: PALETTE.white,
		warning: PALETTE.hawaiianTan,
		detailsBackground: PALETTE.antiFlashWhite,
		dividerBackground: PALETTE.taupeGrey,
	},
	AffiliateList: {
		counterColor: PALETTE.taupeGrey,
		tab: {
			border: PALETTE.mercuryGrey,
			selectedBackground: PALETTE.emperor,
			selectedColor: PALETTE.white,
		},
	},
	AffiliateMarketplaceSidebar: {
		FilterContainerBackground: PALETTE.antiFlashWhite,
		activeItemBackground: PALETTE.argent,
		counterLabelColor: PALETTE.taupeGrey,
	},
	SavedAffiliateItems: {
		border: PALETTE.mercuryGrey,
	},
	AccordionV2: {
		background: PALETTE.antiFlashWhite,
		hoverBackground: PALETTE.argent,
		headerColor: PALETTE.davysGrey,
	},
	CampaignApplyButton: {
		helpText: PALETTE.taupeGrey,
	},
	CampaignDetailSidebar: {
		background: PALETTE.white,
		headerBorder: PALETTE.mercuryGrey,
		closeText: PALETTE.taupeGrey,
		hoverCloseText: PALETTE.emperor,
		hoverIconBackground: PALETTE.argent,
	},
	CreateProductTrackingLinkButton: {
		inputBackground: PALETTE.white,
	},
	DetailContent: {
		hoverIconBackground: PALETTE.argent,
		brandLinkBorder: PALETTE.argent,
		linkColor: PALETTE.toryBlue,
		helpText: PALETTE.taupeGrey,
		label: PALETTE.davysGrey,
		inputBackground: PALETTE.white,
		disabledButtonBackground: PALETTE.white,
		detailBoxBackground: PALETTE.antiFlashWhite,
		dividerBorder: PALETTE.mercuryGrey,
		detailBoxContentColor: PALETTE.davysGrey,
		infoText: PALETTE.emperor,
	},
	InfoBlock: {
		iconBackground: PALETTE.padua,
		background: PALETTE.antiFlashWhite,
		borderColor: PALETTE.taupeGrey,
		infoContentColor: PALETTE.emperor,
	},
	TableV2: {
		borderDotsColor: PALETTE.taupeGrey,
		thead: {
			color: PALETTE.emperor,
		},
		tbody: {
			background: PALETTE.white,
			evenBackground: PALETTE.concret,
			hoverColor: PALETTE.white,
			hoverBackground: PALETTE.emperor,
		},
	},
};

export default colors;
