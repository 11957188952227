import { useState } from 'react';

import { ByAgeOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByAge/types';
import ChartCard from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard';
import Styled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { ChartCardProps } from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/types';
import BarChart from 'components/DataLibrary/Graphs/GraphComponents/CustomBarChart/BarChart';
import ExplanationAccordition from 'components/DataLibrary/Graphs/GraphComponents/ExplanationAccordion';
import LoadingSpinner from 'components/LoadingSpinner';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { formatNumber } from 'shared/helpers/Chart/chart-util';
import { useAppSelector } from 'views//DataLibrary/hooks';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

import { FakeData } from './FakeData';

/**
 * @returns {JSX.Element}
 */
const ByAgeContainer = ({ chartId, fetchData, title, chartType, chartOnDashboard, displayBar, chartExplanation }: ChartCardProps<ByAgeOutput>): JSX.Element => {
	const [displayRealValue, setDisplayRealValue] = useState<boolean>(false);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const manager = useInjection<StatisticsManager>(StatisticsManager);

	let somethingWentWrong = null;
	let totalFollowers = 0;
	let ageData: { label: string; value: number }[] = [];
	let isLoading = true;

	if (!fetchData) {
		isLoading = false;
		totalFollowers = FakeData.total;
		ageData = FakeData.ages;
	} else {
		const { result, error } = fetchData(manager, dashboardFilter);
		if (error) {
			somethingWentWrong = error.message;
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			somethingWentWrong = null;
			totalFollowers = result.total;
			ageData = result.ages;
		}
	}

	return (
		<ChartCard
			somethingWentWrong={somethingWentWrong}
			title={title}
			chartType={chartType}
			chartId={chartId}
			chartOnDashboard={chartOnDashboard}
			isLoading={isLoading}
		>
			<Styled.InnerWrapper>
				<Styled.TextContainer>
					<Styled.SubTitle>Top performing ages</Styled.SubTitle>
					{isLoading ? (
						<LoadingSpinner size='sm' />
					) : (
						<Styled.Total
							onMouseEnter={() => {
								setDisplayRealValue(true);
							}}
							onMouseLeave={() => {
								setDisplayRealValue(false);
							}}
						>
							Total {displayRealValue ? totalFollowers : formatNumber(totalFollowers, true)}
						</Styled.Total>
					)}
				</Styled.TextContainer>
				{displayBar && <BarChart gridTemplateColumns={'2fr 6fr 1fr'} total={totalFollowers} items={ageData} sign='%' />}
			</Styled.InnerWrapper>
			{chartExplanation && <ExplanationAccordition chartTitle={title} chartExplanation={chartExplanation} />}
		</ChartCard>
	);
};

export default ByAgeContainer;
