import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { fontWeights } from 'styles/variables/font-weights';
import { devices } from 'styles/variables/media-queries';

const Tab = styled.div`
	padding: 1rem;
	max-height: 44px;
	min-width: 64px;
	border-radius: ${borderRadius.m};
	border: 1px solid ${newColors.ash};
	font-weight: ${fontWeights.semiBold};
	background-color: transparent;
	cursor: pointer;
	font-size: 0.875rem;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 8px;
	text-transform: capitalize;
	span {
		color: ${colors.gray10};
		font-weight: 500;
	}
	&.selected {
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
		border: 1px solid transparent;
		.icon path {
			fill: ${colors.mainWhite};
		}
	}
	&.disabled {
		opacity: 0.6;
		cursor: auto;
	}

	@media only screen and (${devices.md}) {
		width: fit-content;
	}
`;

const TabContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 24px;
	@media only screen and (${devices.md}) {
		flex-direction: row;
	}
	&.on-discovery {
		flex-direction: row;
		margin-bottom: 0;
		${Tab} {
			width: auto;
		}
	}
`;

const Styled = {
	Tab,
	TabContainer,
};

export default Styled;
