import styled from 'styled-components';

import { Button } from 'components/Button';
import { Flex } from 'styles/utils/UtilityClasses';
import colors from 'styles/variables/colors';
import { guttersWithRem as gutters } from 'styles/variables/gutter';
import { mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';
const { borderColor, shadowColor, backgroundColor, lightGray } = colors.influencerView.card;

const { h5, fontFamily: headingFF } = typography.headings;

const Wrapper = styled.section`
	${Flex};

	padding-top: 10vh;
	padding-bottom: 10rem;

	label {
		font-family: ${typography.label.fontFamily};
		font-size: ${typography.label.small.fontSize};
		line-height: ${typography.label.small.lineHeight};
	}

	.form-group {
		margin-bottom: ${gutters.l};
	}

	button[type='submit'],
	button.d-block {
		display: block;
		font-size: 1rem;
		width: 100%;
	}

	.gutter-l > div {
		margin-right: ${gutters.l};

		&:last-child {
			margin-right: 0;
		}
	}

	.my-l {
		margin-top: ${gutters.l};
		margin-bottom: ${gutters.l};
	}

	.validation-error {
		color: ${colors.error};
	}
	input[type='checkbox'] {
		display: inline;
		appearance: checkbox;
		width: auto;
	}

	fieldset {
		margin-bottom: 24px;
	}

	legend {
		font-size: 1rem;
		font-weight: 700;
	}

	.description-text {
		color: ${colors.gray7};
	}

	.label-chk {
		font-family: ${typography.BaseFontFamiliy};
		font-weight: 500;

		input {
			height: 16px;
			width: 16px;
			margin: 8px 8px 8px 0;
			vertical-align: bottom;
			position: relative;
			top: -1px;
			overflow: hidden;
		}
	}

	.info-ul {
		margin: 1rem 0;
		padding-left: 1.5rem;
	}
`;

const Card = styled.div`
	margin: 0 auto;
	max-width: 33.25rem;
	background: ${backgroundColor};
	border: 1px solid ${borderColor};
	box-shadow: 0 10px 20px ${shadowColor};
	border-radius: 10px;
	padding-bottom: 2.5rem;

	.muted {
		color: ${lightGray};
	}

	button {
		background-color: #4d4b4c;
		color: ${colors.white};

		&:hover:not(:disabled, .disabled) {
			background-color: #f5f5f5;
			color: ${colors.black1};
		}

		&:disabled {
			background-color: #f5f5f5;
			border-color: #f5f5f5;
		}

		div {
			margin-top: 0;
			margin-bottom: 0;
		}

		&.link--back {
			background-color: transparent;
			color: ${colors.black1};
			margin-top: 16px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.push-84 {
		margin-bottom: 84px;
	}
`;

const CardHeader = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem 1.5rem;
	margin-bottom: 13px;
	border-bottom: 1px solid #c5c5c5;

	.card-title {
		font-size: 0.875rem;
		font-weight: 500;
	}
`;

const CardBody = styled.div`
	padding: 13px 40px 0 40px;
`;

const Title = styled.h5`
	font-family: ${headingFF};
	font-weight: ${h5.fontWeight};
	font-size: ${h5.fontSize};
	line-height: ${h5.lineHeight};
`;

const InvalidLinkMessageContainer = styled.div`
	margin-top: 16px;
	padding-bottom: 2rem;
`;

const SubTitle = styled.div`
	margin-bottom: 24px;
	padding-bottom: 1rem;
	border-bottom: 1px solid #c5c5c5;

	span {
		font-weight: 500;
		font-size: 0.875rem;
	}
`;

const ConnectContainer = styled.div`
	padding-top: 2rem;
	margin-bottom: 32px;

	${mediaQueries.large`
		padding-top: 4rem;
	`};
`;

const ConnectButton = styled(Button)`
	justify-content: start;
	border-radius: 4px;
	height: 64px;
	font-family: ${typography.BaseFontFamiliy};
	font-weight: 600;
	width: 100%;
	margin-bottom: 12px;

	.social-icon {
		display: flex;
		align-items: center;
		flex: 0 0 3.5rem;
		justify-content: center;
		width: 56px;
		height: 56px;
		background-color: ${colors.black1};
		border-radius: 60px;
		padding: 0.75rem;
		transition: background-color 200ms ease-in-out;
	}

	.forward {
		margin-left: auto;

		svg path {
			transition: fill 200ms ease-in-out;
			fill: ${colors.white};
		}
	}

	&:hover:not(:disabled, .disabled, .inverted) {
		background-color: ${colors.black1};
		border-color: ${colors.black1};

		.social-icon {
			background-color: #4d4b4c;
		}

		.forward {
			svg path {
				fill: ${colors.black1};
			}
		}
	}

	&.inverted {
		background-color: #fafafa;
		color: #333;
		cursor: default;

		.social-icon {
			background-color: #fafafa;
		}

		.forward {
			svg {
				path:first-child {
					fill: ${colors.black1};
				}
			}
		}

		&:hover:not(:disabled, .disabled) {
			background-color: #fafafa;
			color: #333;
		}
	}
`;

const ButtonGroup = styled.div`
	display: flex;

	.skip {
		border: none;
		background-color: ${colors.transparent};
		color: #888;
		cursor: pointer;
	}

	.create-account {
		max-width: 184px;
		margin-left: auto;
		font-size: 1rem;

		&:disabled {
			background-color: #e3e3e3;
			color: #4d4b4c;
		}
	}
`;

const ContentSection = styled.div`
	margin-bottom: 2rem;

	p:last-child {
		margin-bottom: 0;
	}
`;

export default {
	Wrapper,
	Card,
	Title,
	InvalidLinkMessageContainer,
	SubTitle,
	ConnectContainer,
	ConnectButton,
	ButtonGroup,
	ContentSection,
	CardHeader,
	CardBody,
};
