import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgAddAccount(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.5 4.5C11.5 6.15685 10.1569 7.5 8.5 7.5C6.84315 7.5 5.5 6.15685 5.5 4.5C5.5 2.84315 6.84315 1.5 8.5 1.5C10.1569 1.5 11.5 2.84315 11.5 4.5ZM13 4.5C13 6.98528 10.9853 9 8.5 9C6.01472 9 4 6.98528 4 4.5C4 2.01472 6.01472 0 8.5 0C10.9853 0 13 2.01472 13 4.5ZM16.4208 15.5993H19.4887C19.775 15.5993 20 15.8144 20 16.0882C20 16.3621 19.775 16.5772 19.4887 16.5772H16.4208V19.511C16.4208 19.7848 16.1958 20 15.9094 20C15.6231 20 15.3981 19.7848 15.3981 19.511V16.5772H12.3302C12.0438 16.5772 11.8188 16.3621 11.8188 16.0882C11.8188 15.8144 12.0438 15.5993 12.3302 15.5993H15.3981V12.6655C15.3981 12.3917 15.6231 12.1765 15.9094 12.1765C16.1958 12.1765 16.4208 12.3917 16.4208 12.6655V15.5993Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.7305 14.2781C1.70826 15.5322 1.4397 17.0778 1.5138 18.1853L1.51546 18.2102V19.2332C1.51546 19.6466 1.17914 19.9817 0.764267 19.9817C0.349394 19.9817 0.0130735 19.6466 0.0130735 19.2332V18.2592C-0.0740588 16.8733 0.255816 14.9396 1.56431 13.3342C2.90387 11.6907 5.17977 10.5 8.76386 10.5C9.17873 10.5 9.51505 10.8351 9.51505 11.2486C9.51505 11.662 9.17873 11.9971 8.76386 11.9971C5.53713 11.9971 3.72976 13.0521 2.7305 14.2781Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.7638 11.9971C11.1427 11.9971 12.2936 12.428 13.3635 13.2063C13.6986 13.4501 14.1685 13.377 14.4131 13.0431C14.6577 12.7092 14.5844 12.2409 14.2493 11.9971C12.87 10.9937 11.3897 10.5 8.7638 10.5C8.34893 10.5 8.01261 10.8351 8.01261 11.2486C8.01261 11.662 8.34893 11.9971 8.7638 11.9971Z'
				fill='#333333'
			/>
		</svg>
	);
}
