import styled from 'styled-components';

import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';

const OptionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const HelpText = styled.div`
	font-size: 0.875rem;
	width: 270px;
	overflow-x: hidden;
	color: ${colors.slate};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	line-height: 1.5;
	margin-left: 8px;
`;

const OptionBox = styled.div`
	display: flex;
	border-radius: ${borderRadius.s};
	border: 1px solid ${colors.slate};
	background-color: ${colors.mist};
	padding: 0.5rem;
	cursor: pointer;
	&.displayCursor {
		input[type='radio'] {
			cursor: pointer;
		}
	}
	position: relative;
	flex: 1;
	align-items: center;
	column-gap: 4px;
	height: 80px;
	transition: height 200ms ease-in-out;
	&.selected {
		color: ${colors.white};
		background-color: ${colors.iceBlue};
		border: 1px solid ${colors.slate};
		min-height: 180px;
		${HelpText} {
			overflow: unset;
			white-space: pre-wrap;
		}
	}
	input[type='radio'] {
		appearance: auto !important;
		appearance: none;
		width: 20px;
		height: 20px;

		border: 1px solid ${colors.black} !important;
		border-radius: 50%;
		outline: none;
		&:checked {
			border: 1px solid ${colors.black} !important;
		}
		&:before {
			content: '';
			display: block;
			width: 60%;
			height: 60%;
			margin: 20% auto;
			border-radius: 50%;
			margin-top: 4px;
		}
		&:checked:before {
			background: ${colors.black};
		}
	}
	p {
		font-size: 0.938rem;
		margin: 0;
		color: ${colors.black};
	}
	label {
		cursor: pointer;
		padding-right: 25px;
		input[type='radio'] {
			box-sizing: border-box;
			padding: 0;
		}
	}
	div {
		&.content {
			margin-top: 8px;
		}
		p {
			margin-left: 8px;
		}
	}
`;

const MediaAgencyWrapper = styled.div`
	margin-top: 24px;
`;

export default {
	OptionsWrapper,
	OptionBox,
	HelpText,
	MediaAgencyWrapper,
};
