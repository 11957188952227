import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import Icon from 'components/Icon';

/**
 * @returns {JSX.Element}
 */
const InfluencerConnected = (): JSX.Element => {
	return (
		<Styled.ConnnectedMesssage>
			<div className='icon-div'>
				<Icon name='checkmark' size='12' />
			</div>
			<span>Social account connected</span>
		</Styled.ConnnectedMesssage>
	);
};

export default InfluencerConnected;
