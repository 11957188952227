import { KEY_REFERRAL_URL } from 'constants/localStorage-keys';
import { redirectToHome } from 'services/auth-service';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

class ReferralUrlService {
	storage: BrowserStorage;

	constructor() {
		this.storage = new BrowserStorage(StorageType.LOCAL);
	}

	set(url: string) {
		this.storage.setItem(KEY_REFERRAL_URL, url);
	}

	get() {
		return this.storage.getItem(KEY_REFERRAL_URL);
	}

	remove() {
		return this.storage.removeItem(KEY_REFERRAL_URL);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	redirect(navigate: any, url?: string, disableReferralUrl = false) {
		const referralUrl = this.get();

		if (!disableReferralUrl && referralUrl) {
			this.remove();
			location.replace(referralUrl);
		} else {
			url ? navigate(url) : redirectToHome({ history: navigate });
		}
	}
}

export default new ReferralUrlService();
