import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgDLCpe(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg fill='none' {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.0342 8.13566C14.3307 8.40744 13.5455 8.42941 12.6572 8.04731C11.5369 7.56536 10.5688 7.43651 9.66191 7.63495C8.75061 7.83435 7.84141 8.3772 6.86442 9.34961C5.03149 11.1739 2.97508 11.548 1.41142 11.8323C1.12387 11.8846 0.852979 11.9339 0.603209 11.9886C0.333813 12.0476 0.0687296 11.8717 0.0111285 11.5958C-0.0464726 11.3199 0.125221 11.0484 0.394617 10.9894C0.685378 10.9257 0.984824 10.8702 1.29071 10.8135C2.84852 10.5248 4.5734 10.2051 6.16917 8.61682C7.23173 7.55924 8.30169 6.88773 9.45349 6.63571C10.6096 6.38273 11.7886 6.5654 13.0435 7.10524C13.6991 7.38725 14.2202 7.35809 14.6821 7.17963C15.1687 6.9916 15.6216 6.62585 16.1034 6.16737C16.2377 6.03953 16.3773 5.90121 16.5205 5.7592C16.8622 5.42051 17.225 5.06087 17.5889 4.77255C18.1215 4.35057 18.7482 4 19.5012 4C19.7767 4 20 4.22874 20 4.5109C20 4.79306 19.7767 5.02179 19.5012 5.02179C19.0665 5.02179 18.6541 5.22042 18.1993 5.58073C17.8804 5.83347 17.5843 6.12725 17.2632 6.44582C17.1103 6.5975 16.9518 6.7548 16.7824 6.91596C16.2844 7.38984 15.7128 7.87345 15.0342 8.13566Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.952952 15.5C0.952952 15.7761 0.739626 16 0.476476 16C0.213326 16 0 15.7761 0 15.5C0 15.2239 0.213326 15 0.476476 15C0.739626 15 0.952952 15.2239 0.952952 15.5ZM8.57182 15.5C8.57182 15.7761 8.3585 16 8.09535 16C7.83219 16 7.61887 15.7761 7.61887 15.5C7.61887 15.2239 7.83219 15 8.09535 15C8.3585 15 8.57182 15.2239 8.57182 15.5ZM13.8092 16C14.0723 16 14.2857 15.7761 14.2857 15.5C14.2857 15.2239 14.0723 15 13.8092 15C13.546 15 13.3327 15.2239 13.3327 15.5C13.3327 15.7761 13.546 16 13.8092 16ZM4.76251 15.5C4.76251 15.7761 4.54919 16 4.28604 16C4.02289 16 3.80956 15.7761 3.80956 15.5C3.80956 15.2239 4.02289 15 4.28604 15C4.54919 15 4.76251 15.2239 4.76251 15.5ZM11.9042 16C12.1673 16 12.3806 15.7761 12.3806 15.5C12.3806 15.2239 12.1673 15 11.9042 15C11.641 15 11.4277 15.2239 11.4277 15.5C11.4277 15.7761 11.641 16 11.9042 16ZM18.0952 15.5C18.0952 15.7761 17.8819 16 17.6187 16C17.3556 16 17.1423 15.7761 17.1423 15.5C17.1423 15.2239 17.3556 15 17.6187 15C17.8819 15 18.0952 15.2239 18.0952 15.5ZM2.38126 16C2.64441 16 2.85773 15.7761 2.85773 15.5C2.85773 15.2239 2.64441 15 2.38126 15C2.11811 15 1.90478 15.2239 1.90478 15.5C1.90478 15.7761 2.11811 16 2.38126 16ZM10.4764 15.5C10.4764 15.7761 10.263 16 9.99988 16C9.73672 16 9.5234 15.7761 9.5234 15.5C9.5234 15.2239 9.73672 15 9.99988 15C10.263 15 10.4764 15.2239 10.4764 15.5ZM15.714 16C15.9771 16 16.1904 15.7761 16.1904 15.5C16.1904 15.2239 15.9771 15 15.714 15C15.4508 15 15.2375 15.2239 15.2375 15.5C15.2375 15.7761 15.4508 16 15.714 16ZM6.66729 15.5C6.66729 15.7761 6.45397 16 6.19082 16C5.92767 16 5.71434 15.7761 5.71434 15.5C5.71434 15.2239 5.92767 15 6.19082 15C6.45397 15 6.66729 15.2239 6.66729 15.5ZM19.5235 16C19.7867 16 20 15.7761 20 15.5C20 15.2239 19.7867 15 19.5235 15C19.2604 15 19.047 15.2239 19.047 15.5C19.047 15.7761 19.2604 16 19.5235 16Z'
				fill='#333333'
			/>
		</svg>
	);
}
