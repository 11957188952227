import { memo, useMemo } from 'react';
import { components, DropdownIndicatorProps, GroupBase, Props as SelectProps } from 'react-select';

import Icon from 'components/Icon';
import { Scale } from 'styles/theme/types';

import { SelectField } from './SelectMenu.style';

const DEFAULT_SCALE: Scale = 'medium';

interface SelectMenuProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>> extends Omit<SelectProps<Option, IsMulti, Group>, 'components'> {
	scale?: Scale;
}

const DropdownIndicator = memo((props: JSX.IntrinsicAttributes & DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>) => {
	return components.DropdownIndicator ? (
		<components.DropdownIndicator {...props} data-testid='dropdown-indicator'>
			<Icon testId={props.selectProps.menuIsOpen ? 'open' : 'closed'} size='16' name={props.selectProps.menuIsOpen ? 'chevron-up' : 'chevron-down'} />
		</components.DropdownIndicator>
	) : null;
});

DropdownIndicator.displayName = 'DropdownIndicator';

/**
 * SelectMenu
 * @param props
 * @returns {JSX.Element}
 */
const SelectMenu = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
	scale = DEFAULT_SCALE,
	isSearchable = true,
	...props
}: SelectMenuProps<Option, IsMulti, Group>): JSX.Element => {
	const selectComponents = useMemo(
		() => ({
			DropdownIndicator,
		}),
		[],
	);

	return <SelectField {...props} isSearchable={isSearchable} components={selectComponents} scale={scale} />;
};

export default memo(SelectMenu);
