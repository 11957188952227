import { forwardRef, MouseEventHandler, ChangeEventHandler, SyntheticEvent } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // eslint-disable-line import/no-unassigned-import

import Input from 'components/Forms/Input';
import Icon from 'components/Icon';

import Styled from './Datepicker.style';

type DatepickerProps = ReactDatePickerProps;

interface CustomInputProps {
	onClick: MouseEventHandler<HTMLDivElement>;
	value: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(({ onClick, value, onChange }, ref) => (
	<Styled.CustomInput onClick={onClick}>
		<Input ref={ref} value={value} onChange={onChange} contentAfter={<Icon name='calendar' />} />
	</Styled.CustomInput>
));

const Datepicker = (props: DatepickerProps) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChange = (date: Date | null, event: SyntheticEvent<any, Event> | undefined) => {
		if (props.onChange) {
			props.onChange(date, event);
		}
	};

	return <DatePicker {...props} onChange={handleChange} customInput={<CustomInput onClick={() => {}} value='' onChange={() => {}} />} />;
};

export default Datepicker;
