import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgAssignmentReel(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.5 6.75V18.5H18.5V6.75H1.5ZM1.5 5.25H6.879L4.78949 1.5H1.5V5.25ZM12.8494 5.25H8.59614L6.50663 1.5H10.7631L12.8494 5.25ZM14.5659 5.25H18.5V1.5H12.4796L14.5659 5.25ZM1 0C0.447715 0 0 0.447716 0 1V19C0 19.5523 0.447716 20 1 20H19C19.5523 20 20 19.5523 20 19V1C20 0.447715 19.5523 0 19 0H1ZM8.82 8.12387C8.67333 7.99168 8.46 7.96385 8.28667 8.04734C8.11333 8.13083 8 8.31869 8 8.52046V15.4782C8 15.6799 8.11333 15.8608 8.28667 15.9513C8.35333 15.9861 8.42667 16 8.5 16C8.61333 16 8.72667 15.9583 8.82 15.8817L12.82 12.4585C12.9333 12.3611 13 12.215 13 12.0619C13 11.9089 12.9333 11.7628 12.82 11.6584L8.82 8.12387ZM9 14.3789V9.64761L11.72 12.048L9 14.3789Z'
				fill='#333333'
			/>
		</svg>
	);
}
