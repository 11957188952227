import { isEmpty, isNil } from 'lodash';

import { DELETE } from 'constants/hateoas-keys';
import { createClient } from 'shared/ApiClient/ApiClient';

import { PaidMediaStatistic } from './types';

export const removePaidMediaData = async (paidMediaList: PaidMediaStatistic[]) => {
	const client = createClient();
	if (!isEmpty(paidMediaList)) {
		const deleteLinks = paidMediaList
			.filter((paidMedia) => !isNil(paidMedia.links[DELETE]))
			.map((paidMedia) => {
				return paidMedia.links[DELETE];
			});

		if (!isEmpty(deleteLinks)) {
			await Promise.all(deleteLinks.map((deleteLink) => client.delete(deleteLink)));
		}
	}
};
