import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 21 20',
};

export function SvgAi(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M12.2962 4.42519C12.5523 3.85827 13.3574 3.85827 13.6134 4.42519L15.6529 8.94081C15.7253 9.10114 15.8537 9.22954 16.014 9.30195L20.5296 11.3414C21.0966 11.5975 21.0966 12.4025 20.5296 12.6586L16.014 14.698C15.8537 14.7705 15.7253 14.8989 15.6529 15.0592L13.6134 19.5748C13.3574 20.1417 12.5523 20.1417 12.2963 19.5748L10.2568 15.0592C10.1844 14.8989 10.056 14.7705 9.89565 14.698L5.38003 12.6586C4.8131 12.4025 4.8131 11.5975 5.38003 11.3414L9.89565 9.30195C10.056 9.22954 10.1844 9.10114 10.2568 8.94081L12.2962 4.42519Z'
				fill='#CDAAFA'
			/>
			<path
				d='M8.79019 0.106298C8.8542 -0.0354327 9.05547 -0.0354327 9.11948 0.106298L9.62935 1.2352C9.64745 1.27528 9.67955 1.30739 9.71963 1.32549L10.8485 1.83535C10.9903 1.89937 10.9903 2.10063 10.8485 2.16465L9.71963 2.67451C9.67955 2.69261 9.64745 2.72472 9.62935 2.7648L9.11948 3.8937C9.05547 4.03543 8.8542 4.03543 8.79019 3.8937L8.28032 2.7648C8.26222 2.72472 8.23012 2.69261 8.19004 2.67451L7.06113 2.16465C6.9194 2.10063 6.9194 1.89937 7.06113 1.83535L8.19004 1.32549C8.23012 1.30739 8.26222 1.27528 8.28032 1.2352L8.79019 0.106298Z'
				fill='#CDAAFA'
			/>
			<path
				d='M4.62554 2.2126C4.75357 1.92913 5.1561 1.92913 5.28413 2.2126L6.30386 4.47041C6.34006 4.55057 6.40427 4.61477 6.48443 4.65098L8.74224 5.67071C9.0257 5.79873 9.0257 6.20127 8.74224 6.32929L6.48443 7.34902C6.40427 7.38523 6.34006 7.44943 6.30386 7.52959L5.28413 9.7874C5.1561 10.0709 4.75357 10.0709 4.62554 9.7874L3.60581 7.52959C3.56961 7.44943 3.5054 7.38523 3.42524 7.34902L1.16743 6.32929C0.883969 6.20127 0.883969 5.79873 1.16743 5.67071L3.42524 4.65098C3.5054 4.61477 3.56961 4.55057 3.60581 4.47041L4.62554 2.2126Z'
				fill='#CDAAFA'
			/>
		</svg>
	);
}
