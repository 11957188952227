import styled from 'styled-components';

import { CTALink } from 'styles/utils/CTALink';
import { mediaQueries } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	.deauthorize-card {
		max-width: 900px;
		text-align: center;
	}
`;

const CardText = styled.div`
	max-width: 500px;
	margin: 0 auto;
`;

const StatusText = styled.p`
	font-weight: 700;
`;

const Image = styled.img`
	margin: 0 auto;
	margin-bottom: 40px;
	display: block;
`;

const Content = styled.div`
	text-align: center;

	a {
		${CTALink};
	}
`;

const ButtonGroup = styled.div`
	display: flex;
	gap: 32px;
	justify-content: center;
	margin-bottom: 32px;
`;

const InnerContent = styled.div`
	padding-top: 1rem;
	margin-bottom: 52px;
`;

const Heading = styled.h1`
	font-size: 22px;
	line-height: 1.1;
	margin-bottom: 16px;

	${mediaQueries.medium`
		font-size: 24px;
		line-height: 1.1;
	`};

	${mediaQueries.large`
		font-size: 34px;
		line-height: 1.4;
	`};
`;

export const Styled = {
	Wrapper,
	CardText,
	StatusText,
	Image,
	Content,
	ButtonGroup,
	InnerContent,
	Heading,
};
