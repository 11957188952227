import { KEY_MERCURE_TOKEN, KEY_TOKENS } from 'constants/localStorage-keys';
import BrowserStorage from 'shared/helpers/BrowserStorage/BrowserStorage';

import { TokenData } from './types';

export class TokenStorage {
	private storage: BrowserStorage;

	constructor(storage: BrowserStorage) {
		this.storage = storage;
	}

	getTokens(): TokenData {
		const tokensStr = this.storage.getItem(KEY_TOKENS);
		if (!tokensStr) {
			return { c_token: '', m_token: null, d_token: { token: '', expiresAt: '' } };
		}

		try {
			const tokens = JSON.parse(tokensStr);
			if (typeof tokens === 'string') {
				return JSON.parse(tokens) as TokenData;
			}
			return tokens as TokenData;
		} catch (error) {
			console.error('Failed to parse tokens:', error);
			return { c_token: '', m_token: null, d_token: { token: '', expiresAt: '' } };
		}
	}

	setTokens(tokens: TokenData): void {
		try {
			this.storage.setItem(KEY_TOKENS, JSON.stringify(tokens));
		} catch (error) {
			console.error('Failed to set tokens:', error);
		}
	}

	updateToken<K extends keyof TokenData>(key: K, value: TokenData[K]): void {
		const tokens = this.getTokens();
		tokens[key] = value;
		this.setTokens(tokens);
	}

	clearMercureToken(): void {
		this.updateToken(KEY_MERCURE_TOKEN, null);
	}

	setMercureToken(token: string): void {
		this.updateToken(KEY_MERCURE_TOKEN, token);
	}

	getMercureToken(): string | null {
		const tokens = this.getTokens();
		return tokens.m_token;
	}
}
