import styled from 'styled-components';

const Icon = styled.i`
	width: auto;
	height: auto;
	line-height: 1;
	font-size: inherit;
`;

const Styled = {
	Icon,
};

export default Styled;
