import styled from 'styled-components';

import { detailText } from 'components/NewBriefPage/NewBreifPage.style';
import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const jc = colors.briefPage.joinCampaign;

const List = styled.ul`
	list-style: none;
	${scrollbarY};
	li {
		padding: 0.3rem 0;
	}

	margin-bottom: 1rem;
`;

const Container = styled.div`
	&.gdpr-container {
		margin-bottom: 4rem;
	}
`;

const Link = styled.div`
	display: flex;
	cursor: pointer;
	.icon {
		margin-right: 5px;
	}
	p {
		color: ${colors.black};
		border-bottom: 1px solid ${colors.black};
	}
`;

const PdfDownload = styled.a`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	padding: 0.5rem 1rem 0.5rem 0.5rem;
	border-radius: ${borderRadius.s};
	cursor: pointer;
	transition: background-color 200ms fade-in fade-out;

	border-bottom: none !important;
	.icon path {
		fill: ${jc.infoBlue};
	}

	& > div {
		display: flex;
		align-items: center;
		column-gap: 0.5rem;

		& > span {
			color: ${jc.infoBlue};
			font-size: 0.875rem;
		}
	}

	&:hover {
		background-color: ${jc.ligthBlue};
	}
`;

const AgreementDownload = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 0.5rem 1rem 0.5rem 0.5rem;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 200ms fade-in fade-out;

	border-bottom: none !important;
	.icon path {
		fill: ${jc.infoBlue};
	}

	& > div {
		display: flex;
		align-items: center;
		column-gap: 8px;
		margin-right: ${spacing[1].px};
		& > span {
			color: ${jc.infoBlue};
			font-size: 0.875rem;
		}
	}

	&:hover {
		background-color: ${jc.ligthBlue};
	}
`;

const TacContainer = styled.div`
	margin: ${spacing[4].px} 0;
	background-color: ${newColors.iceBlue};
	padding: 1rem;
	border-radius: ${borderRadius.s};
	h6 {
		font-size: 0.875rem;
		margin-bottom: 16px;
	}
`;

const Delete = styled.div`
	display: flex;
	border-radius: ${borderRadius.s};
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 24px;
	height: 24px;
	margin-left: ${spacing[1].px};
	.icon {
		margin-top: 4px;
	}
	&:hover {
		background-color: ${colors.errorLight};
	}
`;

const FileWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: ${spacing[1].px};
`;

const DetailText = styled(detailText)``;

const Styled = {
	DetailText,
	Delete,
	List,
	Container,
	Link,
	PdfDownload,
	AgreementDownload,
	TacContainer,
	FileWrapper,
};

export default Styled;
