import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { DarkButton } from 'components/Button';
import TrackingLink from 'components/ContentManagement/Components/TrackingLink';
import LoadingSpinner from 'components/LoadingSpinner';
import { isMobile } from 'shared/utils/navigator';

import Styled from './AffiliateLinkGenerator.style';

type AffiliateLinkGeneratorProps = {
	headline?: string;
	isLoading?: boolean;
	errorMessages?: Array<{ source: { message: string } }>;
	url: string;
	onClickSubmit: (value: string) => void;
	showLink: boolean;
};

/**
 * AffiliateLinkGenerator
 * Component to show the tracking/affiliate link for the influencer
 * @param {string} url
 * @returns {JSX.Element}
 */
const AffiliateLinkGenerator = ({ headline, onClickSubmit, errorMessages, isLoading, url, showLink }: AffiliateLinkGeneratorProps): JSX.Element => {
	const [value, setValue] = useState<string>(url);
	const [isLinkVisible, setIsLinkVisible] = useState<boolean>(showLink);
	const [errors, setErrors] = useState<Array<{ source: { message: string } }>>([]);

	const inputRef = useRef<HTMLInputElement>(null);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.currentTarget.value);
		setErrors([]);
		setIsLinkVisible(false);
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (value) {
			e.code === 'Enter' && onSubmit();
		}
	};

	const onSubmit = () => {
		onClickSubmit(value);
	};

	const onClickGenerateNewLink = () => {
		setValue('');
		setIsLinkVisible(false);
	};

	useEffect(() => {
		if (errorMessages !== undefined) {
			setErrors(errorMessages);
		}
	}, [errorMessages]);

	useEffect(() => {
		setIsLinkVisible(showLink);
	}, [showLink]);

	useEffect(() => {
		setValue(url);
	}, [url]);

	useEffect(() => {
		inputRef.current?.focus();

		return () => {
			setValue('');
			setErrors([]);
			setIsLinkVisible(false);
		};
	}, []);

	return (
		<Styled.Wrapper>
			{headline && <Styled.Headline className='tracking-link__headline'>{headline}</Styled.Headline>}
			<Styled.Inner>
				<p className='help-text'>Add a product link and generate unlimited affiliate links, even for the same product.</p>
				<Styled.LinkContainer className={classNames({ 'pad-4': !isLinkVisible })}>
					{!isLoading && isLinkVisible && <TrackingLink headline={''} url={url} />}
					{!isLoading && !isLinkVisible && (
						<Styled.InputContainer>
							<Styled.InputGroup>
								<Styled.InputWrapper>
									<Styled.Input
										autoFocus={!isMobile() && value === ''}
										ref={inputRef}
										data-testid='gal'
										className={classNames({ error: errors.length > 0 })}
										value={value}
										disabled={isLoading}
										onKeyDown={onKeyDown}
										onChange={onChange}
										placeholder='Add URL to generate the affiliate link'
										type='url'
									/>
									<Styled.IconContainer onClick={() => setValue('')} className={classNames({ visible: value !== '' })}>
										<Styled.CustomIcon name='cancel-circle' size='16' />
									</Styled.IconContainer>
								</Styled.InputWrapper>
							</Styled.InputGroup>
						</Styled.InputContainer>
					)}
					{isLoading && <LoadingSpinner position='center' />}
				</Styled.LinkContainer>

				{errors && errors.length > 0 && (
					<Styled.Errors>
						{errors.map((error, index: number) => {
							return (
								<span key={index} className='error-message'>
									{error.source.message}
								</span>
							);
						})}
					</Styled.Errors>
				)}

				{!isLoading && isLinkVisible && <Styled.GenerateNewButton onClick={onClickGenerateNewLink}>Generate new link</Styled.GenerateNewButton>}
				{!isLoading && !isLinkVisible && (
					<DarkButton disabled={value === ''} size='sm' onClick={onSubmit}>
						Generate link
					</DarkButton>
				)}
			</Styled.Inner>
		</Styled.Wrapper>
	);
};

export default AffiliateLinkGenerator;
