import DOMPurify from 'dompurify';

import { DetailItem } from 'components/CreateCampaign/types';
import { Campaign } from 'components/NewBriefPage/types';
import { newLinesToParagraphs } from 'utils/formatters';

import Styled from './CampaignInfo.style';

type CampaignInfoProps = {
	campaign: Campaign;
	id: string;
};

/**
 * @returns JSX.Element
 */
const CampaignInfo = ({ id, campaign }: CampaignInfoProps): JSX.Element => {
	return (
		<Styled.Wrapper id={id}>
			<div>
				{campaign.details?.length ? (
					<>
						{campaign.details.map((detail: DetailItem) => {
							return (
								<Styled.DetailItem key={detail.title}>
									<Styled.DetailTitle>{detail.title}</Styled.DetailTitle>
									<Styled.DetailText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail.content) }} />
									<hr />
								</Styled.DetailItem>
							);
						})}
					</>
				) : (
					<>
						<Styled.DetailItem>
							<Styled.DetailTitle>Background</Styled.DetailTitle>
							<Styled.DetailText>{newLinesToParagraphs(campaign.background)}</Styled.DetailText>
							<hr />
						</Styled.DetailItem>
						<Styled.DetailItem>
							<Styled.DetailTitle>Purpose</Styled.DetailTitle>
							<Styled.DetailText>{newLinesToParagraphs(campaign.purpose)}</Styled.DetailText>
							<hr />
						</Styled.DetailItem>
						<Styled.DetailItem>
							<Styled.DetailTitle>Terms & Conditions</Styled.DetailTitle>
							<Styled.DetailText>
								<ul>
									{campaign.guidelines?.map((guideline: string, index: number) => {
										return <li key={index}>{guideline}</li>;
									})}
								</ul>
							</Styled.DetailText>
							<hr />
						</Styled.DetailItem>
					</>
				)}
			</div>
		</Styled.Wrapper>
	);
};

export default CampaignInfo;
