import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgDrag(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.5 5C3.125 5 2 3.875 2 2.5C2 1.125 3.125 0 4.5 0C5.875 0 7 1.125 7 2.5C7 3.875 5.875 5 4.5 5ZM4.5 12.4999C3.125 12.4999 2 11.3749 2 9.99995C2 8.62495 3.125 7.49995 4.5 7.49995C5.875 7.49995 7 8.62495 7 9.99995C7 11.3749 5.875 12.4999 4.5 12.4999ZM2 17.4999C2 18.8749 3.125 19.9999 4.5 19.9999C5.875 19.9999 7 18.8749 7 17.4999C7 16.1249 5.875 14.9999 4.5 14.9999C3.125 14.9999 2 16.1249 2 17.4999ZM12.9999 17.5C12.9999 18.875 14.1249 20 15.4999 20C16.8749 20 17.9999 18.875 17.9999 17.5C17.9999 16.125 16.8749 15 15.4999 15C14.1249 15 12.9999 16.125 12.9999 17.5ZM12.9999 10C12.9999 11.375 14.1249 12.5 15.4999 12.5C16.8749 12.5 17.9999 11.375 17.9999 10C17.9999 8.625 16.8749 7.5 15.4999 7.5C14.1249 7.5 12.9999 8.625 12.9999 10ZM12.9999 2.5C12.9999 3.875 14.1249 5 15.4999 5C16.8749 5 17.9999 3.875 17.9999 2.5C17.9999 1.125 16.8749 0 15.4999 0C14.1249 0 12.9999 1.125 12.9999 2.5Z'
				fill='#333333'
			/>
		</svg>
	);
}
