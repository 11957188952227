import { inject, injectable } from 'inversify';

import { PublisherModel } from 'api-models';
import {
	CreateInvitationToUserPayload,
	CreatePublisherInvitePayload,
	UpdateBrandLogoPayload,
	UpdatePublisherPayload,
	UpdatePublisherPrivilegePayload,
} from 'api-payloads';
import {
	CreatePublisherQuery,
	ListPublisherPrivilegesQuery,
	ListPublishersQuery,
	UpdatePublisherLogoQuery,
	UpdatePublisherPrivilegeQuery,
	UpdatePublisherQuery,
	ViewPublisherQuery,
} from 'api-queries';
import { ClientCollectionResponse, PublisherPrivilegeResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, UpdateConfig } from './types';

import type PublisherApiClientInterface from 'services/ApiClient/PublisherApiClientInterface';

@injectable()
class PublisherManager extends ApiManager<ModelRepository<PublisherModel>> {
	private readonly client: PublisherApiClientInterface;

	public constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.PublisherApiClientInterface) client: PublisherApiClientInterface,
	) {
		super(cacheManager, new ModelRepository<PublisherModel>('publisher'));
		this.client = client;
	}

	public listUsersAndInvitations(id: string) {
		const key = `listUsersAndInvitations::${id}`;
		const fetcher = () => {
			return Promise.all([
				this.client.listInvitationsTo(id, { include: ':hateoas(false)' }),
				this.client.listPrivileges(id, RequestQueryBuilder.create<ListPublisherPrivilegesQuery>().withInclude('user').toQuery()),
			]);
		};

		return this.swr(key, fetcher, { multipleApiResponses: true });
	}

	public inviteUsers(publisherId: string, payload: CreateInvitationToUserPayload): Promise<void> {
		return this.client.createInvitationToUser(publisherId, payload, { include: ':hateoas(false)' }).then(() => {
			this.refetch('publisher', publisherId);
		});
	}

	public deleteInvite(publisherId: string, publisherInviteId: string): Promise<void> {
		return this.client.deleteInvite(publisherId, publisherInviteId).then(() => {
			this.refetch('publisher', publisherId);
		});
	}

	public deleteUser(publisherId: string, userId: string): Promise<void> {
		return this.client.deletePrivilege(publisherId, userId).then(() => {
			this.refetch('publisher', publisherId);
		});
	}

	public listPublishers(queryBuilder = RequestQueryBuilder.create<ListPublishersQuery>()) {
		const key = `listPublishers::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listPublishers(queryBuilder.toQuery());

		return this.swr<ClientCollectionResponse>(key, fetcher);
	}

	public createInvite(
		payload: CreatePublisherInvitePayload,
		queryBuilder = RequestQueryBuilder.create<CreatePublisherQuery>(),
		config: CreateConfig<PublisherModel> = {},
	): Promise<PublisherModel> {
		return this.createModel<PublisherModel>(this.client.createInvite(payload, queryBuilder.toQuery()), config);
	}

	public view(id: string, queryBuilder = RequestQueryBuilder.create<ViewPublisherQuery>()) {
		const fetcher = () => this.client.view(id, queryBuilder.toQuery());

		return this.swr<ClientCollectionResponse>(id, fetcher);
	}

	public update(
		id: string,
		payload: UpdatePublisherPayload,
		queryBuilder = RequestQueryBuilder.create<UpdatePublisherQuery>(),
		config: UpdateConfig<PublisherModel> = {},
	): Promise<PublisherModel> {
		return this.updateModel<PublisherModel>(this.client.update(id, payload, queryBuilder.toQuery()), config);
	}

	public updateUserPrivilege(
		publisherId: string,
		userId: string,
		payload: UpdatePublisherPrivilegePayload,
		queryBuilder = RequestQueryBuilder.create<UpdatePublisherPrivilegeQuery>(),
	): Promise<PublisherPrivilegeResponse> {
		return this.client.updateUserPrivilege(publisherId, userId, payload, queryBuilder.toQuery());
	}

	public uploadLogo(publisherId: string, payload: UpdateBrandLogoPayload): Promise<string | undefined> {
		const qb = RequestQueryBuilder.create<UpdatePublisherLogoQuery>();

		return this.client.updateLogo(publisherId, payload, qb.toQuery()).then((response) => {
			this.refetch('publisher', publisherId);

			return response.data.links?.logo;
		});
	}

	public deleteLogo(publisherId: string): Promise<void> {
		return this.client.deleteLogo(publisherId);
	}
}

export default PublisherManager;
