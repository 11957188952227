import Modal from 'components/Modals/Modal';
import LoginForm from 'views/login/components/LoginForm';

import Styled from './LoginModal.style';

const LoginModal = (props: { isOpen: boolean; onClose: () => void }) => {
	return (
		<Modal open={props.isOpen} handleClose={props.onClose} size='xs'>
			<Styled.Wrapper data-testid='login-form-modal' aria-hidden={props.isOpen ? 'false' : 'true'}>
				<LoginForm />
			</Styled.Wrapper>
		</Modal>
	);
};

export default LoginModal;
