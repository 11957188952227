import { Model } from 'json-api-models';

export class CampaignInfluencerDetail {
	targetInfluencer: Model;
	commission: string | null;
	assignments: Model[];
	products: Model[];
	promoCodes: Model[];

	constructor(campaignInstagramOwnerModel: Model) {
		this.targetInfluencer = campaignInstagramOwnerModel;
		this.commission = this.summarizeCommission(campaignInstagramOwnerModel.campaignInstagramOwnerCommissions);
		this.assignments = campaignInstagramOwnerModel.campaignInstagramOwnerAssignments;
		this.products = campaignInstagramOwnerModel.campaignInstagramOwnerProducts;
	}

	/**
	 * It checks the number of currencies and calculates the total amount per currency.
	 */
	summarizeCommission(campaignInstagramOwnerCommissions: Model[]) {
		if (campaignInstagramOwnerCommissions && campaignInstagramOwnerCommissions.length > 0) {
			const commissionModels = campaignInstagramOwnerCommissions.map((campaignInstagramOwnerCommission) => {
				return campaignInstagramOwnerCommission.commission;
			});

			const uniqueCurrencies = commissionModels
				.map((commission) => {
					return commission.fixedAmountCurrency;
				})
				.filter((currency, index, arr) => arr.indexOf(currency) === index);

			if (uniqueCurrencies.length === 1) {
				const totalAmount = commissionModels
					.map((commission) => {
						return commission.fixedAmount;
					})
					.reduce((prev, current) => prev + current, 0);

				return `${totalAmount > 0 ? (totalAmount / 100).toLocaleString('en-US') : 0} ${uniqueCurrencies[0]} `;
			} else {
				const totalAmountPerCurrencyArr: Array<{ currency: string; totalAmount: number }> = [];
				uniqueCurrencies.forEach((currency) => {
					const filteredCommission = commissionModels
						.filter((commission: Model) => commission.fixedAmountCurrency === currency)
						.map((commission: Model) => commission.fixedAmount);
					totalAmountPerCurrencyArr.push({
						currency: currency,
						totalAmount: filteredCommission.reduce((prev, current) => prev + current, 0),
					});
				});
				return totalAmountPerCurrencyArr
					.map((totalAmount) => {
						return `${totalAmount.totalAmount > 0 ? totalAmount.totalAmount / 100 : 0} ${totalAmount.currency}`;
					})
					.join(' ');
			}
		} else {
			return null;
		}
	}
}
