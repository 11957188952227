import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';
// https://www.codemzy.com/blog/fix-chunkloaderror-react
// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function (componentImport: Function) {
	const storage = new BrowserStorage(StorageType.SESSION);
	return new Promise((resolve, reject) => {
		// check if the window has already been refreshed
		const hasRefreshed = JSON.parse(storage.getItem('retry-lazy-refreshed') || 'false');
		// try to import the component
		componentImport()
			.then((component: Object) => {
				storage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
				resolve(component);
			})
			.catch((error: unknown) => {
				if (!hasRefreshed) {
					// not been refreshed yet
					storage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
					return window.location.reload(); // refresh the page
				}
				reject(error); // Default error behaviour as already tried refresh
			});
	});
};
