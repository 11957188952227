import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	padding: ${spacing[4].rem} ${spacing[2].rem};
	background-color: ${colors.buttonGray};
	border-radius: ${({ theme }) => theme.radius.default};
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: 100%;

	h5 {
		margin-top: ${spacing[3].px};
		font-weight: ${({ theme }) => theme.fontWeights.bold};
		font-size: 1rem;
		margin: ${spacing[3].px};
	}

	p {
		font-size: 1rem;
		margin: 0;
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
