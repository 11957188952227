import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/Icon';
import { Filter } from 'components/InfluencerManagement/types';
import usePermissions from 'hooks/usePermissions';

import Styled from './NavBar.style';

type NavBarProps = {
	setSelectedFilter: (selectedFilter: Filter) => void;
	selectedFilter: Filter;
};
/**
 * Navbar with filters.
 * @returns {JSX.Element}
 */
const NavBar = ({ setSelectedFilter, selectedFilter }: NavBarProps): JSX.Element => {
	const navigate = useNavigate();
	const { isBrandManager } = usePermissions();

	return (
		<Styled.NavigationWrapper>
			<Styled.Navigation>
				<li
					onClick={() => {
						setSelectedFilter(Filter.ALL);
					}}
					className={classNames({ selected: selectedFilter === Filter.ALL })}
				>
					<Icon name='all' />
					<p>All</p>
				</li>
				{!isBrandManager() && (
					<li
						onClick={() => {
							setSelectedFilter(Filter.PRIVATE);
						}}
						className={classNames({ selected: selectedFilter === Filter.PRIVATE })}
					>
						<Icon name='user' />
						<p>My private folders & lists</p>
					</li>
				)}
				<li
					onClick={() => {
						setSelectedFilter(Filter.SHARED);
					}}
					className={classNames({ selected: selectedFilter === Filter.SHARED })}
				>
					<Icon name='item-shared' />
					<p>Shared by me</p>
				</li>
				<li
					onClick={() => {
						setSelectedFilter(Filter.SHARED_WITH_ME);
					}}
					className={classNames({ selected: selectedFilter === Filter.SHARED_WITH_ME })}
				>
					<Icon name='item-shared-with-me' />
					<p>Shared with me</p>
				</li>
				<hr />
				{!isBrandManager() && (
					<li onClick={() => navigate('/discovery', { state: { previousUrl: location.pathname } })} data-testid='discovery-link'>
						<Icon name='add-account' />
						<p>Go to Discovery</p>
					</li>
				)}
			</Styled.Navigation>
		</Styled.NavigationWrapper>
	);
};

export default NavBar;
