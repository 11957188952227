export const FakeData2 = [
	{
		label: 'Agricultural Sales Representative',
		value: 20,
	},
	{
		label: 'Electrical Parts Reconditioner',
		value: 10,
	},
	{
		label: 'Obstetrician',
		value: 18,
	},
];
