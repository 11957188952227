import styled from 'styled-components';

import Icon from 'components/Icon';

const CustomIcon = styled(Icon)`
	line-height: 0;
	cursor: pointer;
	display: flex;
`;

const BackDiv = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 12px;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
`;

const Title = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const Styled = {
	CustomIcon,
	BackDiv,
	Title,
};

export default Styled;
