import styled from 'styled-components';

import Icon from 'components/Icon';
import { Td, Tr } from 'styles/table';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { spacing } from 'styles/variables/spacings';

interface IOpenFolderProps {
	openFolder?: boolean;
}

export const ListTableTr = styled.tr<IOpenFolderProps>`
	${Tr};
	background-color: transparent;
	${(props) =>
		props.openFolder &&
		`
		background-color: #f2f2f2;

		.icon {
			& > i {
				&:hover {
					fill: ${colors.mainBlack};
				}
			}
		}
	`};
`;

export const ListTableTd = styled.td`
	${Td};
`;

export const StyleOpenFolder = styled.div<IOpenFolderProps>`
	display: flex;
	align-items: center;

	.icon {
		line-height: 0;
	}

	.edit {
		opacity: 0;
		transition:
			visibility 0s,
			opacity 0.5s linear;

		&.visible {
			opacity: 1;
			visibility: 1;
		}
	}

	${(props) =>
		props.openFolder &&
		`
	& > .icon {
		svg {
			path, polyline {
				fill: ${colors.mainBlack};
			}
		}
	}

	.checkbox--lists {
		div {
			border-color: ${colors.buttonGray};
			color: ${colors.buttonGray};
		}

		svg {
			stroke: ${colors.buttonGray};
			fill: none;
		}
	}
	`};
`;

export const ListTableItem = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	min-height: 50px;

	.push-right {
		margin-left: auto;
	}
`;

export const ListTableItemName = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	font-size: 1.25rem;
	font-weight: 700;
	width: 100%;

	.icon {
		line-height: 0;
	}

	& > span {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		padding-top: 0.1875rem;

		&.opened {
			max-width: 192px;
		}
	}
`;

export const CustomIcon = styled(Icon)`
	display: flex;
	i {
		line-height: 0;
	}

	&.pointer {
		cursor: pointer;
	}
`;

export const AvatarsContainer = styled.div`
	display: flex;
	align-items: center;
`;
export const AvatarsStyleWrapper = styled.div`
	margin-right: -32px;
	&:last-child {
		margin-right: 0;
	}
`;
export const InfluencerCount = styled.div`
	margin-left: ${spacing[4].px};
`;

const IconButtonsWrapper = styled.div`
	display: flex;
	column-gap: ${guttersWithRem.s};
	margin-right: ${guttersWithRem.m};
`;

const Styled = {
	ListTableTr,
	ListTableTd,
	StyleOpenFolder,
	ListTableItem,
	ListTableItemName,
	CustomIcon,
	AvatarsContainer,
	AvatarsStyleWrapper,
	InfluencerCount,
	IconButtonsWrapper,
};

export default Styled;
