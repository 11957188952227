import Icon from 'components/Icon';

import Styled from './TwoColumnModal.style';

type TwoColumnModalProps = {
	isModalOpen: boolean;
	toggleModal: () => void;
	children: JSX.Element;
	footer?: JSX.Element | boolean;
	title?: string;
};
/**
 * Use the LeftColumn and RightColumn components as children
 * @returns {JSX.Element}
 */
const TwoColumnModal = ({ isModalOpen, toggleModal, children, footer, title }: TwoColumnModalProps): JSX.Element => {
	return (
		<Styled.CustomModal size={'lg'} open={isModalOpen} handleClose={toggleModal}>
			<Styled.CustomModalHeader>
				<Styled.ModalHeaderTitle>{title}</Styled.ModalHeaderTitle>
				<Styled.CloseContainer onClick={toggleModal} data-testid='close-icon'>
					<Icon name='cross' size='16' />
				</Styled.CloseContainer>
			</Styled.CustomModalHeader>
			<Styled.CustomModalBody>
				<Styled.Container>{children}</Styled.Container>
			</Styled.CustomModalBody>
			{footer && <Styled.CustomModalFooter>{footer}</Styled.CustomModalFooter>}
		</Styled.CustomModal>
	);
};

export default TwoColumnModal;
