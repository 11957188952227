import moment from 'moment';
import { useEffect, useState } from 'react';

import { LinkButton } from 'components/Button';
import useContentManagementData from 'components/ContentManagement/hooks/contentManagementData';
import Icon from 'components/Icon';
import ConfirmModal from 'components/Modals/ConfirmModal';
import Tabs from 'components/Tabs/Tabs';
import { ENABLE_FOR_CLIENT } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import usePermissions from 'hooks/usePermissions';
import toast from 'services/Toast';
import { AssignmentReviewType } from 'shared/Types/Assignment';

import Styled from './TabMenu.style';

export type TabMenuProps = {
	activeItem: TabItem;
	isStatsVisible: boolean;
	isStatsDisabled: boolean;
	review?: AssignmentReviewType;
	onClickTab: (activeItem: TabItem) => void;
	refresh: () => void;
	CIOArefresh: () => Promise<void>;
};

export enum TabItem {
	CONTENT = 'Content',
	STATS = 'Stats',
	SUMMARY = 'Summary',
}

/**
 * TabMenu
 * @param {TabMenuProps} props
 * @returns {JSX.Element}
 */
const TabMenu = ({ activeItem = TabItem.CONTENT, isStatsVisible, isStatsDisabled, onClickTab, review, refresh, CIOArefresh }: TabMenuProps): JSX.Element => {
	const { userCan } = useFeaturePermissions(review?.links || {});
	const { isSuperAdmin, isPublisher, isInfluencer } = usePermissions();

	const CAN_ENABLE_FOR_CLIENT = userCan(ENABLE_FOR_CLIENT);

	const { postEnableToClient } = useContentManagementData();

	const [shareConfirm, setShareConfirm] = useState<boolean>(false);
	const [shareDisabled, setShareDisabled] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onClickShare = () => {
		if (!review?.links[ENABLE_FOR_CLIENT]) {
			return;
		}

		setIsLoading(!isLoading);
		postEnableToClient(review?.links[ENABLE_FOR_CLIENT])
			.then(() => {
				return Promise.all([refresh(), CIOArefresh()]);
			})
			.then(() => {
				toast.success('Content is shared with your clients');
			})
			.catch((e) => {
				toast.error(getSomethingWentWrongMessage());
				console.error(e);
				setShareDisabled(true);
			})
			.finally(() => {
				setShareConfirm(!shareConfirm);
				setIsLoading(!shareConfirm);
			});
	};

	const handleClose = () => setShareConfirm(!shareConfirm);

	useEffect(() => {
		setShareDisabled(Boolean(review?.enabledForClientAt) || Boolean(review?.approvedByClientAt));
	}, [review]);

	return (
		<Styled.Wrapper>
			<Styled.NavigationWrapper>
				<Tabs
					selectedTab={activeItem}
					setSelectedTab={(tab: string) => onClickTab(tab as TabItem)}
					tabs={[
						{
							title: TabItem.CONTENT,
							testId: 'cm-content-tab',
						},
						{
							title: TabItem.STATS,
							disabled: isStatsDisabled,
							testId: 'cm-stats-tab',
							omit: !isStatsVisible,
						},
						{
							title: TabItem.SUMMARY,
							testId: 'cm-summary-tab',
						},
					]}
				/>
				<Styled.ShareAssignment>
					<Styled.ShareInner>
						{CAN_ENABLE_FOR_CLIENT && !isSuperAdmin() && isPublisher() ? (
							<LinkButton size='sm' onClick={handleClose} disabled={shareDisabled} data-testid='share'>
								Share <Icon name='share' size='22' />
							</LinkButton>
						) : (
							<></>
						)}
					</Styled.ShareInner>
				</Styled.ShareAssignment>
			</Styled.NavigationWrapper>
			{!isInfluencer() && (
				<Styled.ShareTimestamps data-testid='cm-share-timestamp'>
					{review?.enabledForClientAt && review?.approvedByClientAt === null && (
						<Styled.ShareTime>
							Shared: <span className='timestamp'>{moment(review.enabledForClientAt).format('D MMM')}</span>
						</Styled.ShareTime>
					)}
					{review?.approvedByClientAt && (
						<Styled.ShareTime>
							Client approved: <span className='timestamp'>{moment(review?.approvedByClientAt).format('D MMM')}</span>
						</Styled.ShareTime>
					)}
				</Styled.ShareTimestamps>
			)}

			<ConfirmModal
				isModalOpen={shareConfirm}
				toggleModal={() => handleClose()}
				action={() => onClickShare()}
				title='Share content'
				isFetching={isLoading}
				buttonText='Share'
			>
				<p>Send the assignment to your clients for approval.</p>
			</ConfirmModal>
		</Styled.Wrapper>
	);
};
export default TabMenu;
