import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
`;

const Styled = {
	Wrapper,
};

export default Styled;
