import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgBlast(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_1647_38862)'>
				<path
					d='M20 9.37C20 8.31 19.22 7.42 18.2 7.23C18.15 5.64 18.06 3.56 17.97 2.41C17.82 0.47 16.96 0.09 16.7 0.02L16.51 0C15.79 0 15.29 0.73 15.06 1.15C12.86 4.89 9.26 5.45 7.81 5.52H2.44C1.1 5.52 0 6.59 0 7.91V10.06C0 11.07 0.65 11.93 1.55 12.28C1.93 12.63 2.67 14.97 3.32 18.65C3.5 19.67 4.25 20 4.83 20C4.88 20 4.93 20 4.98 20H6.33C7.08 20 7.61 19.82 7.96 19.45C8.25 19.13 8.38 18.72 8.33 18.21C8.26 17.5 7.63 16.78 7.3 16.44C7.14 15.85 7.05 13.98 7.06 12.45H7.86C9.09 12.45 12.86 12.85 15.05 17.09C15.19 17.37 15.7 18.29 16.53 18.29H16.62L16.71 18.27C16.97 18.2 17.84 17.83 17.99 15.89C18.07 14.85 18.15 13.04 18.2 11.53C19.23 11.34 20.01 10.45 20.01 9.39L20 9.37ZM1.45 7.91C1.45 7.38 1.89 6.94 2.44 6.94H6.4V11.03H2.44C1.89 11.03 1.45 10.59 1.45 10.06V7.91ZM1.57 12.28C1.57 12.28 1.63 12.3 1.65 12.31C1.65 12.31 1.59 12.29 1.57 12.28ZM5.99 17.09L6.05 17.22L6.16 17.32C6.52 17.66 6.87 18.15 6.89 18.33C6.89 18.43 6.89 18.47 6.89 18.48C6.87 18.49 6.74 18.57 6.33 18.57H4.87H4.81C4.81 18.57 4.77 18.51 4.75 18.4C4.2 15.27 3.69 13.48 3.26 12.44H5.6C5.6 13.36 5.61 16.2 5.99 17.08V17.09ZM16.78 9.39C16.78 10.62 16.64 14.19 16.52 15.77C16.49 16.13 16.44 16.39 16.38 16.56C16.36 16.53 16.34 16.49 16.32 16.46C13.8 11.58 9.38 11.05 7.84 11.02V6.94C9.56 6.86 13.75 6.21 16.32 1.85C16.34 1.81 16.36 1.78 16.39 1.74C16.45 1.92 16.5 2.17 16.53 2.53C16.65 4.1 16.79 7.67 16.79 8.91V9.41L16.78 9.39ZM18.21 10.52C18.22 10.07 18.23 9.67 18.23 9.39V8.89C18.23 8.71 18.23 8.48 18.23 8.23C18.69 8.41 19.04 8.85 19.04 9.37C19.04 9.89 18.7 10.34 18.22 10.52H18.21Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1647_38862'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
