import styled from 'styled-components';

import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	width: 100%;
	overflow-y: hidden;
	overflow-x: auto;
	display: flex;
	align-items: center;
	& > div {
		&.doughnut {
			max-width: 140px;
			max-height: 140px;
		}

		&.labels {
		}
	}
`;

const ColorDot = styled.div<{ color: string }>`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: ${(props) => props.color};
`;

const TableRow = styled.tr`
	font-family: ${typography.BaseFontFamiliy};
	& > td {
		padding: 0.25rem;
	}
`;

const Styled = {
	Wrapper,
	ColorDot,
	TableRow,
};

export default Styled;
