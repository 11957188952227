import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgDLContent(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg fill='none' {...svgProps}>
			<path
				d='M1 12V18C1 18.5523 1.44772 19 2 19H20C20.5523 19 21 18.5523 21 18V7M1 12L7 12.5L12 9L16.5 10.5L21 7M1 12V6L5 8L8.5 4H16.5L21 1V7'
				stroke='#333333'
				strokeLinecap='round'
			/>
			<circle cx='3.5' cy='7.5' r='0.5' fill='#333333' />
			<circle cx='3.5' cy='11.5' r='0.5' fill='#333333' />
			<circle cx='1.5' cy='7.5' r='0.5' fill='#333333' />
			<circle cx='1.5' cy='11.5' r='0.5' fill='#333333' />
			<circle cx='2.5' cy='9.5' r='0.5' fill='#333333' />
			<circle cx='5.5' cy='7.5' r='0.5' fill='#333333' />
			<circle cx='5.5' cy='11.5' r='0.5' fill='#333333' />
			<circle cx='4.5' cy='9.5' r='0.5' fill='#333333' />
			<circle cx='10.5' cy='5.5' r='0.5' fill='#333333' />
			<circle cx='7.5' cy='7.5' r='0.5' fill='#333333' />
			<circle cx='7.5' cy='11.5' r='0.5' fill='#333333' />
			<circle cx='11.5' cy='7.5' r='0.5' fill='#333333' />
			<circle cx='17.5' cy='7.5' r='0.5' fill='#333333' />
			<circle cx='6.5' cy='9.5' r='0.5' fill='#333333' />
			<circle cx='10.5' cy='9.5' r='0.5' fill='#333333' />
			<circle cx='8.5' cy='5.5' r='0.5' fill='#333333' />
			<circle cx='12.5' cy='5.5' r='0.5' fill='#333333' />
			<circle cx='18.5' cy='5.5' r='0.5' fill='#333333' />
			<circle cx='14.5' cy='5.5' r='0.5' fill='#333333' />
			<circle cx='20.5' cy='5.5' r='0.5' fill='#333333' />
			<circle cx='16.5' cy='5.5' r='0.5' fill='#333333' />
			<circle cx='9.5' cy='7.5' r='0.5' fill='#333333' />
			<circle cx='20.5' cy='1.5' r='0.5' fill='#333333' />
			<circle cx='13.5' cy='7.5' r='0.5' fill='#333333' />
			<circle cx='19.5' cy='7.5' r='0.5' fill='#333333' />
			<circle cx='15.5' cy='7.5' r='0.5' fill='#333333' />
			<circle cx='8.5' cy='9.5' r='0.5' fill='#333333' />
			<circle cx='17.5' cy='3.5' r='0.5' fill='#333333' />
			<circle cx='19.5' cy='3.5' r='0.5' fill='#333333' />
			<circle cx='14.5' cy='9.5' r='0.5' fill='#333333' />
			<circle cx='16.5' cy='9.5' r='0.5' fill='#333333' />
		</svg>
	);
}
