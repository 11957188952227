import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { ClientModel } from 'api-models';
import { getClientOptions } from 'components/DataLibrary/AllRequests/DataFilterOptions';
import FilterSectionAccordionItem from 'components/DataLibrary/FilterSection/FilterSectionAccordionItem';
import { FilterOptionProps } from 'components/DataLibrary/FilterSection/types';
import useInjection from 'hooks/useInjection';
import ClientManager from 'services/ApiManager/Client.manager';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboardFilterClients } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const ClientOptions = (props: FilterOptionProps): JSX.Element => {
	const [clearThisFilter, setClearThisFilter] = useState<boolean>(false);
	const [selectedClients, setSelectedClients] = useState<Array<string>>([]);

	const manager = useInjection<ClientManager>(ClientManager);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dispatch = useAppDispatch();

	let clients: ClientModel[] = [];
	let isSearching = true;

	if (dashboard.id) {
		const { result, error } = getClientOptions(manager, dashboardFilter);
		if (error) {
			console.error(error.message);
		}
		isSearching = !result;
		if (result) {
			clients = result;
		}
	}

	const onSelect = (id: string) => {
		props.getClearFilter(false);
		setClearThisFilter(false);
		if (selectedClients.includes(id)) {
			const update = selectedClients.filter((i) => i !== id);
			setSelectedClients(update);
		} else {
			setSelectedClients([...selectedClients, id]);
		}
	};

	useEffect(() => {
		dispatch(setDashboardFilterClients(selectedClients));
	}, [selectedClients]);

	const checkDashboardFilter = () => {
		if (dashboard.metaData.dashboardFilter) {
			const savedClients = dashboard.metaData.dashboardFilter.clients ?? [];
			if (!isEqual(savedClients, selectedClients)) {
				setSelectedClients(savedClients);
			}
		} else {
			setSelectedClients([]);
		}
	};

	useEffect(() => {
		checkDashboardFilter();
	}, [props.dashboardId, dashboard]);

	useEffect(() => {
		if (clearThisFilter || props.clearFilter) {
			setSelectedClients([]);
		}
	}, [props.clearFilter, clearThisFilter]);

	useEffect(() => {
		checkDashboardFilter();
		return () => {
			setSelectedClients([]);
			setClearThisFilter(false);
		};
	}, []);

	return (
		<FilterSectionAccordionItem
			setClearThisFilter={setClearThisFilter}
			title={<>Client {selectedClients?.length > 0 && <>({selectedClients?.length})</>} </>}
			label='Search for clients'
			placeholder='Ex Nike'
			data={clients.map((item) => ({
				id: item.id,
				name: item.attributes.name,
			}))}
			selectedData={selectedClients}
			onSelect={onSelect}
			isSearching={isSearching}
		/>
	);
};
export default ClientOptions;
