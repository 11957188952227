import colors from 'styles/theme/colors';
export type ThreeColTheme = {
	sidebarBackground: string;
	mainBackground: string;
	border: string;
};

export const light: ThreeColTheme = {
	sidebarBackground: colors.mist,
	mainBackground: colors.snow,
	border: colors.silver,
};

export const dark: ThreeColTheme = {
	sidebarBackground: colors.mist,
	mainBackground: colors.snow,
	border: colors.silver,
};
