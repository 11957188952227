import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgListInfluencers(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 2.49023C7.3418 2.07602 7.67758 1.74023 8.0918 1.74023H19.0918C19.506 1.74023 19.8418 2.07602 19.8418 2.49023C19.8418 2.90445 19.506 3.24023 19.0918 3.24023H8.0918C7.67758 3.24023 7.3418 2.90445 7.3418 2.49023Z'
				fill='currentColor'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 9.99023C7.3418 9.57602 7.67758 9.24023 8.0918 9.24023H19.0918C19.506 9.24023 19.8418 9.57602 19.8418 9.99023C19.8418 10.4044 19.506 10.7402 19.0918 10.7402H8.0918C7.67758 10.7402 7.3418 10.4044 7.3418 9.99023Z'
				fill='currentColor'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.3418 17.4902C7.3418 17.076 7.67758 16.7402 8.0918 16.7402H16.0918C16.506 16.7402 16.8418 17.076 16.8418 17.4902C16.8418 17.9044 16.506 18.2402 16.0918 18.2402H8.0918C7.67758 18.2402 7.3418 17.9044 7.3418 17.4902Z'
				fill='currentColor'
			/>
			<rect x='0.75' y='0.75' width='3.5' height='3.5' rx='1.75' stroke='currentColor' strokeWidth='1.5' />
			<rect x='0.75' y='8.25' width='3.5' height='3.5' rx='1.75' stroke='currentColor' strokeWidth='1.5' />
			<rect x='0.75' y='15.75' width='3.5' height='3.5' rx='1.75' stroke='currentColor' strokeWidth='1.5' />
		</svg>
	);
}
