import { useEffect, useState } from 'react';

import LoadingSpinner from 'components/LoadingSpinner';
import usePermissions from 'hooks/usePermissions';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { AssignmentStatus } from 'shared/Types/Assignment';
import { breakpoints } from 'styles/variables/media-queries';

import Styled from './Topbar.style';
import { ReviewButtonProps, ReviewSectionProps } from './types';

const ReviewButton = ({ label, onClick, isLoading, disabled, dataTestId }: ReviewButtonProps) => (
	<Styled.CustomSubmitButton onClick={onClick} disabled={disabled} data-testid={dataTestId} type='submit'>
		{isLoading ? <LoadingSpinner size='sm' /> : <>{label}</>}
	</Styled.CustomSubmitButton>
);

const ReviewSection = ({
	errors,
	onClickRequestChanges,
	onClickApprove,
	onClickClientApprove,
	isEditing,
	canRequestChanges,
	canApprove,
	canClientApprove,
	isClientApproveLoading,
	title,
	hideErrors = false,
	status,
}: ReviewSectionProps): JSX.Element => {
	const { isSuperAdmin, isPublisher } = usePermissions();
	const { width } = useWindowDimensions();
	const isMobileView = width <= +breakpoints.lg.split('px')[0];
	const [displayAllButtons, setDisplayAllButtons] = useState(false);

	const displayErrors = () => {
		return (
			errors &&
			Object.entries(errors).map(([key, value], i) => {
				const element = document.getElementsByName(key)[0] || document.getElementsByClassName('error')[0];

				if (typeof value === 'string' || (Array.isArray(value) && value.length > 0)) {
					return (
						<Styled.ErrorMessage
							key={i}
							onClick={() => {
								//@ts-ignore
								element?.focus({ focusVisible: true });
								element?.scrollIntoView({ block: 'start', behavior: 'smooth' });
							}}
						>
							{typeof value === 'string' ? value : value[0]?.text ?? 'Missing required fields'}
						</Styled.ErrorMessage>
					);
				}
				return null;
			})
		);
	};

	useEffect(() => {
		if (errors && Object.keys(errors).length > 0) {
			const element = document.getElementsByName(Object.keys(errors)[0])[0];
			if (element) {
				//@ts-ignore
				element.focus({ focusVisible: true });
				element.scrollIntoView({ block: 'start', behavior: 'smooth' });
			}
		}
	}, [errors]);

	const renderButtons = () => (
		<>
			{canRequestChanges && !isEditing && <ReviewButton label='Request changes' onClick={onClickRequestChanges} dataTestId='cm-request-changes' />}
			{canApprove && !isEditing && <ReviewButton label='Approve for posting' onClick={onClickApprove} dataTestId='cm-approve' />}
			{canClientApprove && !isSuperAdmin() && !isPublisher() && (
				<ReviewButton label='Approve for posting' onClick={onClickClientApprove} isLoading={isClientApproveLoading} dataTestId='cm-client-approve' />
			)}
		</>
	);

	const renderMobileView = () => (
		<Styled.SubmitSection>
			<Styled.DFlexW100 className='min-height'>
				<Styled.DFlexColumn className='w-100'>
					<Styled.AssignmentName>{title}</Styled.AssignmentName>
					<Styled.FileText>{errors && Object.entries(errors).length > 0 && displayErrors()}</Styled.FileText>
					<Styled.InfoText>{status === AssignmentStatus.APPROVED ? status : displayAllButtons ? 'Add your review' : 'Content review'}</Styled.InfoText>
				</Styled.DFlexColumn>
				{!displayAllButtons && (
					<Styled.ReviewButton onClick={() => setDisplayAllButtons(true)} data-testid='cm-show-buttons'>
						<span>Review</span>
					</Styled.ReviewButton>
				)}
			</Styled.DFlexW100>
			{displayAllButtons && (
				<>
					<hr />
					<Styled.ButtonContainer>
						{renderButtons()}
						<Styled.CloseButton onClick={() => setDisplayAllButtons(false)}>Close</Styled.CloseButton>
					</Styled.ButtonContainer>
				</>
			)}
		</Styled.SubmitSection>
	);

	const renderDesktopView = () => (
		<Styled.DFlexColumn>
			<div className='buttons-wrapper'>{renderButtons()}</div>
			<div>{!hideErrors && displayErrors()}</div>
		</Styled.DFlexColumn>
	);

	return isMobileView ? renderMobileView() : renderDesktopView();
};

export default ReviewSection;
