import { AxiosError } from 'axios';
import { JsonApiDocument, Model, Store } from 'json-api-models';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { CAMPAIGN_STATUS } from 'components/IntegratedInbox/Components/CampaignList/types';
import { VIEW_INBOX } from 'constants/hateoas-keys';
import { getErrorMessageOnFetch, getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import usePermissions from 'hooks/usePermissions';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { getHomePath } from 'reducers/UserReducers/helpers';
import IntegratedInboxService from 'services/IntegratedInbox/IntegratedInbox.service';
import { StatusCode } from 'services/Response.types';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';
import Grid from 'styles/grid/grid';

import BlastMessageModal from './Components/BlastMessageModal';
import CampaignSection from './Components/CampaignSection';
import ChatSection from './Components/ChatSection';
import { DirectConversationData } from './Components/DirectConversationList/DirectConversationData';
import InfluencerDetailSection from './Components/InfluencerDetailSection';
import { InfluencerConversation } from './Components/InfluencerList/InfluencerList';
import Styled from './IntegratedInboxContainer.style';
import useIntegratedInboxData, { usePresignUrl, useUploadFiles } from './hooks';
import { FileItem, IntegratedInboxCampaign, PresignObject, TabKey } from './types';

/**
 * IntegratedInbox container component for campaign manager.
 * on the left side renders campaign list first but if the user clicks one campaign it will render influencer list.
 * on the right side renders campaign details such as assignments, commission, and products data for the selected influencer so it will render when the user selects one specific influencer from the left side.
 */
const IntegratedInboxContainer = () => {
	const [isFetchingInfluencers, setIsFetchingInfluencers] = useState<boolean>(true);
	const [isFetchingConversations, setIsFetchingConversations] = useState<boolean>(false);
	const [isLoadingNext, setIsLoadingNext] = useState(false);

	const [isBlastOpen, setIsBlastOpen] = useState<boolean>(false);

	const [campaigns, setCampaigns] = useState<IntegratedInboxCampaign[]>([]);
	const [selectedCampaign, setSelectedCampaign] = useState<IntegratedInboxCampaign | null>(null);
	const [selectedCampaignInfluencers, setSelectedCamapignInfluencers] = useState<Model[]>([]);
	const [selectedCampaignConversations, setSelectedCampaignConversations] = useState<Model[]>([]);

	const [selectedInfluencer, setSelectedInfluencer] = useState<Model | null>(null);
	const [selectedConversation, setSelectedConveration] = useState<Model | null>(null);
	const [selectedTab, setSelectedTab] = useState<TabKey>(TabKey.JOINED);

	const [directConversations, setDirectConversations] = useState<Model[]>([]);
	const [selectedDirectConversation, setSelectedDirectConversation] = useState<DirectConversationData | null>(null);

	const [campaignStatus, setCampaignStatus] = useState<string>(CAMPAIGN_STATUS.active);

	const [messages, setMessages] = useState<Model[]>([]);
	const [tempUserMessageValue, setTempUserMessageValue] = useState<{ message: string; files: Array<FileItem> }>({ message: '', files: [] });
	const [publisher, setPublisher] = useState<Model>();

	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	const offsetRef = useRef(0);

	const user = useAppSelector((state) => state.user);
	const userIsInfluencer = user.permissions.isInfluencer;

	const navigate = useNavigate();
	const { pathname } = useLocation();
	const params = useParams();

	const { getCampaigns, getAllConversations, searchProfiles } = useIntegratedInboxData();
	const { getPresignUrls } = usePresignUrl();
	const { uploadAndPollFiles, progress } = useUploadFiles();

	const { campaignsStore, loading: campaignsLoading, mutation: campaignMutation } = getCampaigns(':hateoas(false):hateoas(smallCoverPhoto)', campaignStatus);
	const { conversationsStore, loading: conversationsLoading } = getAllConversations(null !== selectedCampaign);

	const Client = createClient();
	const conversationStore = new Store();
	const conversationMessageStore = new Store();
	const homePath = useAppSelector(getHomePath);
	const { userCan } = usePermissions();

	const selectDirectConveresationHandler = (targetDirectConversation: DirectConversationData) => {
		setSelectedDirectConversation(targetDirectConversation);
		fetchConversationMessages(targetDirectConversation.id);
	};

	const fetchDirectConversations = () => {
		const allConversations = conversationsStore.findAll('conversation');
		const newDirectConversations = allConversations.filter((conversation: Model) => conversation.campaign === null);
		newDirectConversations.sort(
			(a, b) =>
				new Date(b.conversationMetaData?.latestMessageDateTime ?? '').getTime() - new Date(a.conversationMetaData?.latestMessageDateTime ?? '').getTime(),
		);
		setDirectConversations(newDirectConversations);
	};

	/**
	 * It will get campaigns and sort by presence or absence of existing conversations.
	 * if the URL includes campaignId, it will find the target campaign, set selectedCampaign state and get influencer and conversation data of the target campaign
	 */
	const fetchCampaigns = (campaignIdParam?: string) => {
		const getSortedCamapigns = () => {
			const campaignModels = campaignsStore.findAll('campaign');
			const newCampaigns = campaignModels.map((campaignModel) => {
				return new IntegratedInboxCampaign(campaignModel);
			});

			const campaignsWithConversation = newCampaigns.filter((campaign) => campaign.conversationMetaData?.latestMessageDateTime !== null);
			campaignsWithConversation.sort(
				(a, b) =>
					new Date(b.conversationMetaData?.latestMessageDateTime ?? '').getTime() - new Date(a.conversationMetaData?.latestMessageDateTime ?? '').getTime(),
			);
			const campaignsWithoutConversation = newCampaigns.filter((campaign) => campaign.conversationMetaData?.latestMessageDateTime === null);
			campaignsWithoutConversation.sort((a, b) => a.name.localeCompare(b.name));
			return [...campaignsWithConversation, ...campaignsWithoutConversation];
		};

		const sortedCampaigns = getSortedCamapigns();
		setCampaigns(sortedCampaigns);

		if (campaignIdParam) {
			const targetCampaign = sortedCampaigns.find((campaign) => campaign.id === campaignIdParam || campaign.shortId === campaignIdParam);

			if (targetCampaign) {
				setSelectedCampaign(targetCampaign);
				fetchInfluencers(campaignIdParam, true);
				fetchConversations(campaignIdParam);
			}
		}
	};

	const selectOneCampaign = (targetCampaign: IntegratedInboxCampaign) => {
		const campaignId = targetCampaign.shortId;

		if (!campaignId) return;

		setSelectedCampaign(targetCampaign);
		fetchInfluencers(campaignId, true);
		setSelectedDirectConversation(null);
		setMessages([]);

		if (params.campaignId) {
			const newPath = pathname.split('/');
			newPath.splice(-1, 1, campaignId);
			navigate(newPath.join('/'));
		} else {
			navigate(`/inbox/${campaignId}`);
		}
	};

	const fetchInfluencers = async (campaignId: string, joined?: boolean) => {
		try {
			setIsFetchingInfluencers(true);
			offsetRef.current = 0;

			const res = await IntegratedInboxService.getCampaignInfluencers(campaignId, joined);

			const influencerStore = new Store();
			influencerStore.sync(res);

			const originalOrderIds = res.data.map((item: { id: string }) => item.id);

			await fetchConversations(campaignId);

			// Find all influencers and sort by the original order of IDs
			const influencers = influencerStore.findAll('campaignInstagramOwner');
			const sortedInfluencers = originalOrderIds.map((id: string) => influencers.find((influencer) => influencer.id === id));

			setSelectedCamapignInfluencers(sortedInfluencers);
		} catch (err: unknown) {
			if (err instanceof AxiosError) {
				toast.error(`${err.message}`);
				console.error(err);
			} else {
				console.error('An unexpected error occurred:', err);
			}
		} finally {
			setIsFetchingInfluencers(false);
		}
	};

	const fetchConversations = async (campaignId: string, showSpinner = true) => {
		setIsFetchingConversations(showSpinner);
		await IntegratedInboxService.getCampaignConversations(campaignId)
			.then((res) => {
				conversationStore.sync(res);
				setSelectedCampaignConversations(conversationStore.findAll('conversation'));
				setMessages(conversationMessageStore.findAll('conversationMessage'));
			})
			.catch((err: AxiosError) => {
				console.error(err);
				toast.error(`${err.message}`);
			})
			.finally(() => {
				setIsFetchingConversations(false);
			});
	};

	const selectOneInfluencer = (influencerConvesation: InfluencerConversation) => {
		setSelectedInfluencer(influencerConvesation.influencer);
		if (influencerConvesation.conversation) {
			const conversationId = influencerConvesation.conversation.id;
			setSelectedConveration(influencerConvesation.conversation);
			fetchConversationMessages(conversationId);
			if (params.conversationId) {
				const newPath = pathname.split('/');
				newPath.splice(-1, 1, conversationId);
				navigate(newPath.join('/'));
			} else {
				navigate(`${pathname}/${conversationId}`);
			}
		} else {
			setMessages([]);
			if (params.conversationId) {
				setSelectedConveration(null);
				const newPath = pathname.split('/');
				newPath.splice(-1, 1);
				navigate(newPath.join('/'));
			}
		}
	};

	const fetchConversationMessages = (conversationId: string) => {
		setIsFetchingConversations(true);
		IntegratedInboxService.getConversationMessages(
			conversationId,
			'user.publisher,influencer,files,campaign,createdBy,latestMessage,messages,unreadMessages,users',
		)
			.then((res) => {
				conversationMessageStore.sync(res);
				const publishers = conversationMessageStore.findAll('publisher'); // Quick fix for publisher relation on user not working
				setMessages(conversationMessageStore.findAll('conversationMessage'));
				publishers.length > 0 && setPublisher(publishers[0]);
				setTempUserMessageValue({ message: '', files: [] });
			})
			.catch((err: AxiosError) => {
				console.error(err);
				toast.error(getErrorMessageOnFetch('Conversations'));
			})
			.finally(() => {
				setIsFetchingConversations(false);
			});
	};

	const goBackHandler = () => {
		setSelectedInfluencer(null);
		setSelectedConveration(null);
		setSelectedCampaign(null);
		setSelectedCamapignInfluencers([]);
		setSelectedCampaignConversations([]);
		setSelectedInfluencer(null);
		setSelectedDirectConversation(null);
		setMessages([]);
		navigate('/inbox');
	};

	const sendMessage = async (conversationId: string, message: string, files?: Array<File>, influencerId?: string) => {
		setIsDisabled(true);
		setTempUserMessageValue({
			message: message,
			files: [],
		});

		const keys: Array<string> = [];
		let data: { [key: string]: string } = {
			message: message,
		};

		if (influencerId) {
			data = {
				...data,
				influencer: influencerId,
			};
		}

		if (files && files.length > 0) {
			return await getPresignUrls(files).then((response) => {
				const _files: Array<FileItem> = [];
				// Set temp message
				response.map((response: PresignObject) => {
					_files.push({
						id: _.uniqueId(),
						uuid: response.key,
						originalName: response.file.name,
						isLoading: true,
						extension: response.file.name.split('.')[1],
						createdAt: new Date().toDateString(),
						links: {
							createDownload: '',
						},
					});
					setTempUserMessageValue((prev) => ({
						...prev,
						files: _files,
					}));
				});

				// Upload files and poll
				uploadAndPollFiles(response).then(async (ids) => {
					return await Client.post(`/conversations/${conversationId}`, {
						message: data.message,
						influencer: data.influencer,
						files: ids,
					})
						.then((res) => {
							if (res.status === StatusCode.OK) {
								fetchConversationMessages(conversationId);
								return true;
							} else {
								return false;
							}
						})
						.catch((e) => {
							toast.error(getErrorMessageOnPost('uploading your attachment(s)'));
							console.error(e);
						});
				});
				return true;
			});
		}

		return await Client.post(`/conversations/${conversationId}`, {
			message: data.message || null,
			influencer: data.influencer,
			files: keys,
		}).then((res) => {
			if (res.status === StatusCode.OK) {
				fetchConversationMessages(conversationId);
				campaignMutation.refresh().then(() => {
					fetchCampaigns();
					if (selectedCampaign) {
						const joined = selectedTab === TabKey.JOINED;
						fetchInfluencers(selectedCampaign.shortId, joined);
					}
				});
				return true;
			} else {
				return false;
			}
		});
	};

	/**
	 * if it doesn't have an existing converesation with the selected Influencer this function will be called when the user presses 'enter'key or clicks 'send' button.
	 * When it succeeds to send, it will get conversation data of the campaign from the server and add conversationId in the browser URL through selectOneInfluencer function
	 */
	const startNewConversation = async (createConversationData: {
		message: string;
		userIds?: string[];
		influencerId: string;
		campaignId: string;
		files?: Array<File>;
	}) => {
		if (createConversationData.files && createConversationData.files.length > 0) {
			return await getPresignUrls(createConversationData.files).then((response) => {
				// Upload files and poll
				uploadAndPollFiles(response).then(async (ids) => {
					return await IntegratedInboxService.createNewConversation({
						message: createConversationData.message,
						userIds: createConversationData.userIds,
						influencerId: createConversationData.influencerId,
						campaignId: createConversationData.campaignId,
						files: ids,
					}).then((res) => {
						if (res.status === StatusCode.OK) {
							const conversationId = res.data.data.id;
							IntegratedInboxService.getCampaignConversations(createConversationData.campaignId).then((res: JsonApiDocument) => {
								conversationStore.sync(res);
								const conversations = conversationStore.findAll('conversation');
								setSelectedCampaignConversations(conversations);
								fetchTargetConversation(conversations, conversationId);
							});
							return true;
						} else {
							return false;
						}
					});
				});
				return true;
			});
		}

		return await IntegratedInboxService.createNewConversation({
			message: createConversationData.message,
			userIds: createConversationData.userIds,
			influencerId: createConversationData.influencerId,
			campaignId: createConversationData.campaignId,
		}).then((res) => {
			if (res.status === StatusCode.OK) {
				const conversationId = res.data.data.id;
				IntegratedInboxService.getCampaignConversations(createConversationData.campaignId).then((res: JsonApiDocument) => {
					conversationStore.sync(res);
					const conversations = conversationStore.findAll('conversation');
					setSelectedCampaignConversations(conversations);
					fetchTargetConversation(conversations, conversationId);
				});
				return true;
			} else {
				return false;
			}
		});
	};

	const fetchTargetConversation = (conversations: Model[], conversationId: string) => {
		const influencerWithConversation = selectedCampaignInfluencers
			.map((influencer) => {
				return new InfluencerConversation(influencer, conversations);
			})
			.filter((influencer) => influencer.conversation);

		if (influencerWithConversation.length > 0) {
			const targetInfluencer = influencerWithConversation.find((influencer) => influencer.conversation && influencer.conversation.id === conversationId);

			if (targetInfluencer) {
				selectOneInfluencer(targetInfluencer);
			}
		}
	};

	const markMessageAsRead = async (conversationMessageId: string) => {
		await Client.post(`/conversations/${params.conversationId}/messages/${conversationMessageId}/reads`);
	};

	const selectedCampaignStatus = (campaignStatus: CAMPAIGN_STATUS) => {
		setCampaignStatus(campaignStatus);
	};

	const onSearch = async (campaignId: string, query: string, joined: boolean) => {
		setIsFetchingInfluencers(true);
		try {
			const result = await searchProfiles(campaignId, query, joined);
			const data = new Store();
			data.sync(result);
			setSelectedCamapignInfluencers(data.findAll('campaignInstagramOwner'));

			if (result) {
				fetchConversations(campaignId);
			}
		} catch (error) {
			toast.error('Cannot fecth influnecers at the moment');
		} finally {
			setIsFetchingInfluencers(false);
		}
	};

	const onClickTabs = useCallback((selected: TabKey, campaignId: string) => {
		const joined = selected === TabKey.JOINED;
		setSelectedTab(TabKey.JOINED);
		setSelectedInfluencer(null);
		navigate(`/inbox/${campaignId}`);
		if (campaignId) {
			fetchInfluencers(campaignId, joined);
		}
	}, []);

	const onNext = (selected: TabKey, query: string, campaginId: string) => {
		setIsLoadingNext(true);
		const joined = selected === TabKey.JOINED;

		const currentOffset = offsetRef.current;
		const newOffset = currentOffset + 20;

		IntegratedInboxService.getCampaignInfluencers(campaginId, joined, newOffset, query)
			.then((res) => {
				const influencerStore = new Store();
				influencerStore.sync(res);
				const newInfluencers = influencerStore.findAll('campaignInstagramOwner');

				setSelectedCamapignInfluencers((prevInfluencers) => {
					const uniqueNewInfluencers = newInfluencers.filter((influencer) => !prevInfluencers.some((prev) => prev.id === influencer.id));
					return [...prevInfluencers, ...uniqueNewInfluencers];
				});

				offsetRef.current = newOffset;

				return params.campaignId!;
			})
			.then((campaignId) => {
				fetchConversations(campaignId);
			})
			.catch((err: AxiosError) => {
				toast.error(`${err.message}`);
				console.error(err);
			})
			.finally(() => {
				setIsLoadingNext(false);
			});
	};

	useEffect(() => {
		if (!conversationsLoading) {
			fetchDirectConversations();
		}
	}, [conversationsLoading]);

	useEffect(() => {
		if (!campaignsLoading) {
			fetchCampaigns(params.campaignId);
		}
	}, [campaignsLoading]);

	useEffect(() => {
		if (selectedCampaignConversations && params.conversationId) {
			fetchTargetConversation(selectedCampaignConversations, params.conversationId);
		}
	}, [selectedCampaignConversations, params.conversationId]);

	useEffect(() => {
		if (!conversationsLoading && pathname.includes('direct') && params.conversationId) {
			const targetDirectConversation = directConversations.find((directConversation) => directConversation.id === params.conversationId);
			if (targetDirectConversation !== undefined) {
				selectDirectConveresationHandler(new DirectConversationData(targetDirectConversation));
			} else {
				navigate('/inbox');
			}
		}
	}, [pathname, params.conversationId, conversationsLoading]);

	useEffect(() => {
		if (!isBlastOpen) {
			fetchCampaigns(params.campaignId);
		}
	}, [isBlastOpen]);

	useEffect(() => {
		if (selectedDirectConversation) {
			navigate(`/inbox/direct/${selectedDirectConversation.id}`);
		}
	}, [selectedDirectConversation]);

	useEffect(() => {
		if (!campaignsLoading) {
			fetchCampaigns(params.campaignId);
		}
	}, [campaignStatus]);

	if (!userCan(VIEW_INBOX)) {
		return <Navigate to={homePath} />;
	}

	return (
		<Styled.Wrapper>
			<Grid.Container className='three-col-layout integrated-inbox' gap='24'>
				<CampaignSection
					directConversations={directConversations}
					selectedDirectConversation={selectedDirectConversation}
					onSelectDirectConversation={selectDirectConveresationHandler}
					campaigns={campaigns}
					onSelectCampaign={selectOneCampaign}
					selectedCampaign={selectedCampaign}
					onSearch={onSearch}
					onReset={goBackHandler}
					onClickTabs={onClickTabs}
					selectedCampaignInfluencers={selectedCampaignInfluencers}
					selectedCampaignConversations={selectedCampaignConversations}
					isFetchingCampaigns={campaignsLoading}
					isFetchingConversations={isFetchingConversations}
					isFetchingInfluencers={isFetchingInfluencers}
					onSelectInfluencer={selectOneInfluencer}
					selectedInfluencer={selectedInfluencer}
					onOpenBlast={() => setIsBlastOpen(true)}
					selectedCampaignStatus={selectedCampaignStatus}
					next={onNext}
					isLoadingNext={isLoadingNext}
				/>
				<ChatSection
					publisher={publisher}
					campaign={selectedCampaign}
					campaignInstagramOwner={selectedInfluencer}
					messages={messages}
					selectedDirectConversation={selectedDirectConversation}
					isDirectConversation={selectedDirectConversation !== null}
					noCampaigns={campaigns.length === 0}
					isNoConversation={selectedConversation === null}
					isInfluencer={userIsInfluencer}
					isDisabled={isDisabled}
					onSend={(conversationId: string, message: string, files?: Array<File>, influencerId?: string) =>
						sendMessage(conversationId, message, files, influencerId)
					}
					onRead={(conversationMessageId: string) => markMessageAsRead(conversationMessageId)}
					onStartChat={(createConversationData: { message: string; userIds?: string[]; influencerId: string; campaignId: string; files?: Array<File> }) =>
						startNewConversation(createConversationData)
					}
					onBack={goBackHandler}
					tempUserMessageValue={tempUserMessageValue}
					isFetchingCampaigns={campaignsLoading}
					fileUploadProgress={progress}
				/>
				<InfluencerDetailSection
					selectedCampaignInfluencers={selectedCampaignInfluencers}
					campaignInstagramOwner={selectedInfluencer}
					selectedCampaign={selectedCampaign}
					campaignName={selectedCampaign?.name}
				/>
			</Grid.Container>
			<BlastMessageModal
				open={isBlastOpen}
				onClose={() => setIsBlastOpen(false)}
				selectedCampaign={selectedCampaign}
				selectedCampaignInfluencers={selectedCampaignInfluencers}
				selectedCampaignConversations={selectedCampaignConversations}
				getConversationMessages={IntegratedInboxService.getConversationMessages}
			/>
		</Styled.Wrapper>
	);
};

export default IntegratedInboxContainer;
