import styled from 'styled-components';

import boxShadow from 'styles/variables/box-shadows';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { mediaQueries } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	display: flex;
	position: relative;
	height: 56px;
	align-items: center;
	justify-content: center;
	transition: box-shadow 200ms ease-in-out;
	padding: 0 ${guttersWithRem.m};

	&.active-campaign {
		box-shadow: ${boxShadow.small};
	}

	h2 {
		font-size: 1.25rem;
		margin-bottom: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 390px;
		text-align: center;
		cursor: pointer;

		${mediaQueries.xl`
				width: 730px;
		`};
	}
`;

const HeaderInner = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	& > div {
		width: 33.33%;
	}

	&.is-influencer {
		& > div {
			width: 100%;
		}
	}
`;

const HeaderMobileInner = styled.div`
	width: 100%;
	display: flex;
	align-items: center;

	& > div > i {
		display: flex;
	}
`;

const Recipient = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	column-gap: ${guttersWithRem.xs};

	& > figure {
		margin: 0;
	}

	figcaption {
		margin-top: 0;
	}

	& > span {
		&.to {
			color: ${colors.integratedInbox.chatSection.header.recipient.to};
			font-weight: 500;
		}

		&.recipient {
			color: ${colors.integratedInbox.chatSection.header.recipient.color};
			font-weight: 500;
		}
	}
`;

const CampaignName = styled.div`
	display: flex;
	justify-content: center;

	& > span {
		font-weight: 700;

		display: inline-block;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}
`;

const Styled = {
	Wrapper,
	HeaderInner,
	HeaderMobileInner,
	Recipient,
	CampaignName,
};

export default Styled;
