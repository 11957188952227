import styled from 'styled-components';

import Column from 'styles/grid/grid';
import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';

const dl = colors.dataLibrary;

const StyledColumn = styled(Column.Column)`
	height: 100vh;
	position: relative;
`;

const DashboardContainer = styled.div`
	height: calc(100% - 72px);
	overflow: auto;
	${scrollbarY};
`;

const DashboardNav = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-right: 0.5rem;
	position: sticky;
	top: 0px;
`;

const IconContainer = styled.div`
	padding: 1rem 0.5rem;
`;

const ContentContainer = styled.div`
	padding: 1rem;
	${mediaQueries.medium`
		padding: 3rem 7rem;
	`};
`;

const Invitation = styled.div`
	padding: 2rem;
	width: 70%;
`;

const InvitationTitle = styled.div`
	font-weight: 500;
	font-size: 1.2rem;
	margin-bottom: 0.5rem;
`;

const InvitationContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px dotted ${dl.darkPurple};
	div {
		padding-bottom: 2rem;
		flex-direction: column;
		display: flex;
	}
`;

const LinkTitle = styled.div`
	font-size: 0.938rem;
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 2rem;
`;

const Styled = {
	DashboardNav,
	DashboardContainer,
	IconContainer,
	ContentContainer,
	Invitation,
	InvitationTitle,
	InvitationContent,
	StyledColumn,
	LinkTitle,
	ButtonContainer,
};

export default Styled;
