import styled from 'styled-components';

import { Button, GhostButton } from 'components/Button';
import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';

const dl = colors.dataLibrary;

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding-bottom: 1rem;
	padding-right: 1rem;
	position: absolute;
	right: 0;
	top: 1rem;
`;

const CreateButton = styled(GhostButton)`
	border: 2px solid transparent;
	background-color: transparent;
	font-size: 1.25rem;
	display: flex;
	align-items: center;
	padding: 1rem;
	height: 44px;
	gap: 16px;

	.icon {
		line-height: 0;
	}
`;

const FilterButton = styled(Button)`
	background-color: ${newColors.mist};
	border: transparent;
	gap: 16px;

	.icon {
		line-height: 0;
	}

	&:hover,
	&.showFilter {
		cursor: pointer;
		background-color: ${dl.blackHover} !important;
		color: ${dl.white} !important;
		.icon path {
			fill: ${dl.white} !important;
		}
	}
`;

const ClearButton = styled(GhostButton)`
	margin-left: 1rem;
`;

const NotificationContainer = styled.div`
	position: relative;
`;

const Styled = {
	Wrapper,
	FilterButton,
	CreateButton,
	ClearButton,
	NotificationContainer,
};

export default Styled;
