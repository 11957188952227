import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
	useTable,
	useSortBy,
	useRowSelect,
	HeaderGroup,
	Row,
	ColumnInstance as OriginalColumnInstance,
	useFilters,
	useGlobalFilter,
	TableState,
	ActionType,
	TableInstance,
} from 'react-table';

import IndeterminateCheckbox from './Components/IndeterminateCheckbox';
import Styled from './Table.style';
import { caseInsensitiveSort } from './Utils';

export type GenericData = Record<string, unknown> & {
	disabled?: boolean;
};

export interface Column<D extends GenericData = GenericData> {
	Header: string | (() => JSX.Element);
	accessor: keyof D;
	Cell?: ({ row }: { row: Row<D> }) => ReactNode;
}

interface CustomColumnInstance<D extends GenericData = GenericData> extends OriginalColumnInstance<D> {
	isSorted: boolean;
	isSortedDesc: boolean;
	canSort: boolean;
	width?: number;
	omit?: boolean;
	minWidth?: number;
	maxWidth?: number;
	getSortByToggleProps: (props?: unknown) => undefined;
}

export type TableProps<D extends GenericData = GenericData> = {
	columns: Column<D>[];
	data: D[];
	showSearch?: boolean;
	placeholder?: string;
	showCheckboxes?: boolean;
	disableCheckboxes?: boolean;
	enableSorting?: boolean;
	disableSearch?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSelectedRowsChange?: (selectedRows: D[], state?: any) => void;
	enableRowSelection?: (row: Row<D>) => void;
	className?: string;
	getSelectedRow?: (selectedRow: D) => void;
	dataTestId?: string;
	reducer?: ((newState: TableState<D>, action: ActionType, previousState: TableState<D>, instance?: TableInstance<D> | undefined) => TableState<D>) | undefined;
};

/**
 * TableComponent
 * @param TableProps
 * @deprecated Use V3 instead of this one.
 * @returns {JSX.Element}
 */
const TableComponent = <DataType extends GenericData = GenericData>({
	columns,
	data,
	placeholder,
	showSearch = false,
	disableSearch = false,
	showCheckboxes = false,
	disableCheckboxes = false,
	enableSorting = false,
	onSelectedRowsChange,
	className,
	getSelectedRow,
	dataTestId,
	reducer,
}: TableProps<DataType>): JSX.Element => {
	const [filterInput, setFilterInput] = useState<string | undefined>('');

	const memoColumns = useMemo(() => columns, [columns]);
	const memoData = useMemo(() => data, [data]);

	const defaultColumnConfig = useMemo(
		() => ({
			minWidth: 30,
			width: 150,
			maxWidth: 200,
			sortType: caseInsensitiveSort,
		}),
		[],
	);

	const tableInstance = useTable<DataType>(
		{ columns: memoColumns, data: memoData, defaultColumn: defaultColumnConfig, stateReducer: reducer },
		useGlobalFilter,
		useFilters,
		enableSorting
			? useSortBy
			: (hooks) => {
					hooks;
				},
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => {
				const visibleColumns = [...columns.filter((column) => !column.omit)];
				if (showCheckboxes) {
					visibleColumns.unshift({
						id: 'selection',
						Header: ({ getToggleAllRowsSelectedProps }) => {
							const props = getToggleAllRowsSelectedProps();
							const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
								const shouldSelectAll = e.target.checked;
								if (shouldSelectAll) {
									tableInstance.toggleAllRowsSelected(false);
									tableInstance.rows.forEach((row: { original: { disabled?: boolean }; id?: string | number }) => {
										if (!row.original.disabled) {
											tableInstance.toggleRowSelected(row.id, true);
										}
									});
								} else {
									tableInstance.toggleAllRowsSelected(false);
								}
							};

							// Calculate the indeterminate state
							const allRows = tableInstance.rows.filter((row: { original: { disabled?: boolean } }) => !row.original.disabled);
							const selectedRows = tableInstance.selectedFlatRows.filter((row: { original: { disabled?: boolean } }) => !row.original.disabled);
							const isIndeterminate = selectedRows.length > 0 && selectedRows.length < allRows.length;
							const isChecked = allRows.length > 0 && selectedRows.length === allRows.length;

							return <IndeterminateCheckbox {...props} indeterminate={isIndeterminate} checked={isChecked} onChange={onChange} disabled={disableCheckboxes} />;
						},
						Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original.disabled || disableCheckboxes} />,
						isVisible: false,
						render: () => null,
						totalLeft: 0,
						totalWidth: 0,
						width: 30,
						depth: 0,
						getHeaderProps: (propGetter) => {
							return { key: 'selection-header', propGetter };
						},
						getFooterProps: (propGetter) => {
							return { key: 'selection-footer', propGetter };
						},
						toggleHidden: () => {},
						getToggleHiddenProps: () => ({}),
					});
				}
				return visibleColumns;
			});
		},
	) as any; // eslint-disable-line @typescript-eslint/no-explicit-any

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, selectedFlatRows, state } = tableInstance;

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value || '';
		setGlobalFilter(value);
		setFilterInput(value);
	};

	useEffect(() => {
		if (onSelectedRowsChange && rows.length) {
			onSelectedRowsChange(
				selectedFlatRows.map((row: { original: GenericData }) => row.original),
				state,
			);
		}
	}, [selectedFlatRows, onSelectedRowsChange, rows]);

	useEffect(() => {
		if (rows.length) {
			tableInstance.dispatch({ type: 'initializeSelection', data: { rows: rows } });
		}
	}, [rows]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getSelectedRowValues = (selectedRow: { [x: string]: any }) => {
		getSelectedRow && getSelectedRow(selectedRow.original);
	};

	return (
		<Styled.TableContainer className={className} data-testid={dataTestId}>
			{showSearch && (
				<div className='table-search'>
					<Styled.StyledInput value={filterInput} onChange={handleFilterChange} placeholder={placeholder ?? 'Search...'} disabled={disableSearch} />
				</div>
			)}
			<Styled.StyledTable {...getTableProps()}>
				<Styled.StyledThead>
					{headerGroups.map((headerGroup: HeaderGroup<GenericData>, headerGroupIndex: number) => (
						<tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
							{headerGroup.headers.map((column, columnIndex) => {
								const customColumn = column as unknown as CustomColumnInstance<GenericData>;

								const sortByText = typeof customColumn.Header === 'string' ? `Sort by ${customColumn.Header?.toString().toLowerCase()}` : '';
								return (
									<Styled.StyledTh
										{...customColumn.getHeaderProps(enableSorting ? customColumn.getSortByToggleProps() : undefined)}
										title={sortByText}
										styles={{ width: column.width, minWidth: column.minWidth, maxWidth: column.maxWidth }}
										key={columnIndex}
									>
										<Styled.ThInnerWraper>
											{customColumn.render('Header')}
											{enableSorting && customColumn.canSort && (
												<span>
													<Styled.ArrowButton className={customColumn.isSorted ? (customColumn.isSortedDesc ? 'sort-desc' : 'sort-asc') : ''} type='button' />
												</span>
											)}
										</Styled.ThInnerWraper>
									</Styled.StyledTh>
								);
							})}
						</tr>
					))}
				</Styled.StyledThead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row: Row<GenericData>, index: number) => {
						prepareRow(row);
						return (
							<Styled.StyledTr
								{...row.getRowProps()}
								key={index}
								onClick={() => !row.original.disabled && getSelectedRowValues(row)}
								style={{ opacity: row.original.disabled ? 0.5 : 1, pointerEvents: row.original.disabled ? 'none' : 'auto' }}
							>
								{row.cells.map((cell, index: number) => {
									return (
										<Styled.StyledTd styles={{ width: cell.column.width, minWidth: cell.column.minWidth, maxWidth: cell.column.maxWidth }} key={index}>
											{cell.render('Cell')}
										</Styled.StyledTd>
									);
								})}
							</Styled.StyledTr>
						);
					})}
				</tbody>
			</Styled.StyledTable>
			{data.length === 0 && (
				<Styled.EmptyMessageContainer>
					<Styled.EmptyMessage>No data</Styled.EmptyMessage>
				</Styled.EmptyMessageContainer>
			)}
		</Styled.TableContainer>
	);
};

export default TableComponent;
