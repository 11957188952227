import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgChanges(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps} fill='none'>
			<g clipPath='url(#clip0_1997_27461)'>
				<path
					d='M3.23994 10.15C3.43994 10.15 3.68994 10.05 3.82994 9.9L6.26994 7.46C6.55994 7.17 6.55994 6.69 6.26994 6.4C5.97994 6.11 5.49994 6.11 5.20994 6.4L3.98994 7.62V1.5H15.9899V6.35C15.9899 6.76 16.3299 7.1 16.7399 7.1C17.1499 7.1 17.4899 6.76 17.4899 6.35V0.95C17.4899 0.43 17.0699 0 16.5499 0H3.43994C2.91994 0 2.49994 0.43 2.49994 0.95V7.63L1.27994 6.41C0.989941 6.12 0.509941 6.12 0.219941 6.41C-0.0700586 6.7 -0.0700586 7.18 0.219941 7.47L2.64994 9.9C2.79994 10.06 3.01994 10.15 3.23994 10.15Z'
					fill='#333333'
				/>
				<path
					d='M19.78 12.5301L17.35 10.1001C17.2 9.9401 16.98 9.8501 16.76 9.8501C16.56 9.8501 16.31 9.9501 16.17 10.1001L13.73 12.5401C13.44 12.8301 13.44 13.3101 13.73 13.6001C14.02 13.8901 14.5 13.8901 14.79 13.6001L16.01 12.3801V18.5101H4V13.6601C4 13.2501 3.66 12.9101 3.25 12.9101C2.84 12.9101 2.5 13.2501 2.5 13.6601V19.0601C2.5 19.5801 2.92 20.0101 3.44 20.0101H16.55C17.07 20.0101 17.49 19.5801 17.49 19.0601V12.3801L18.71 13.6001C19 13.8901 19.48 13.8901 19.77 13.6001C20.06 13.3101 20.06 12.8301 19.77 12.5401L19.78 12.5301Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1997_27461'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
