import styled from 'styled-components';

import Accordion from 'components/Accordion';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import colors from 'styles/theme/colors';
import _colors from 'styles/variables/colors';

const dl = _colors.dataLibrary;

const ContentContainerHeader = styled.div`
	z-index: 1;
	height: 56px;
	width: 100%;
	border-bottom: 1px solid ${colors.ash};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1rem;
`;

const IconsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: auto;
	align-items: center;
	.icon {
		margin: 0.5rem;
	}
`;

const CreateNewDashboardContainer = styled.div`
	height: 52px;
	width: 52px;
	padding: 0.5rem;
	cursor: pointer;
	border-radius: 4px;
	transition: background-color 0.2s ease-in-out;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	.icon {
		margin-top: 1rem;
	}
	&:hover {
		background-color: ${_colors.dropdown.menuItem.backgroundHover};
	}
`;

const IconMargin = styled.div`
	margin-right: 5px;
`;

const SpinnerWrapper = styled.div`
	justify-content: center;
	width: 100%;
	display: flex;
	padding-top: 2rem;
`;

const CustomDropdownMenu = styled(DropdownMenu)`
	transform: translate3d(0px, 55px, 0px);
`;

const CustomDropdownItem = styled(DropdownItem)`
	.icon {
		margin-right: 1rem;
	}
	div {
		margin-right: 10px;
	}
	&.disabled {
		cursor: not-allowed;
	}
	&:hover {
		&.danger {
			background-color: ${dl.dangerRed};
		}
	}
`;

const Tooltip = styled.div`
	position: absolute;
	background-color: ${_colors.dropdown.tooltip.background};
	box-shadow: 0 3px 6px ${_colors.dropdown.tooltip.boxShadowColor};
	border-radius: 4px;
	padding: 0.25rem 0.5rem;
	opacity: 0;
	transition: opacity 0.2s linear;
	margin-left: auto;
	margin-right: auto;
	margin-top: 4px;
	white-space: nowrap;
	top: 100%;
	right: 0;

	&.visible {
		opacity: 1;
	}
`;

const TooltipText = styled.span`
	color: ${_colors.dropdown.tooltip.color};
	font-size: 0.75rem;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const CustomAccordion = styled(Accordion)`
	.accordion__header {
		font-size: 1rem;
		letter-spacing: 0em;
		border-radius: 4px;
		padding: 0.6rem;
		transition: all 200ms ease-in-out;
		font-weight: 700;
		&:hover {
			background: ${dl.darkHoverColor};
			color: ${dl.white};
			.icon path {
				stroke: ${dl.white};
			}
		}
	}
`;

const AccordionList = styled.ul`
	list-style: none;
`;

const AccordionListItem = styled.ul`
	cursor: pointer;
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 0em;
	padding: 0.6rem;
	padding-top: 0.8rem;
	border-radius: 4px;
	&.selected {
		background: ${dl.darkHoverColor};
		color: ${dl.white};
	}
	a {
		color: ${dl.black};
	}
	&:hover {
		background: #5c5c5c;
		color: ${dl.white};
	}
`;

const GraphTitle = styled.div`
	margin-top: 15px;
`;

const GraphList = styled.ul`
	height: 100%;
	list-style: none;
	li {
		padding: 1rem 0.5rem 1rem 0rem;
	}
`;

const GraphListWrapper = styled.div`
	margin-top: 40px;
`;

const Styled = {
	GraphListWrapper,
	ContentContainerHeader,
	CreateNewDashboardContainer,
	IconsContainer,
	IconMargin,
	SpinnerWrapper,
	CustomDropdownMenu,
	CustomDropdownItem,
	Tooltip,
	TooltipText,
	ButtonContainer,
	GraphList,
	GraphTitle,
	CustomAccordion,
	AccordionList,
	AccordionListItem,
};

export default Styled;
