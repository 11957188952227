import styled from 'styled-components';

import Modal from 'components/Modals/Modal';
import { ModalHeader, ModalContent } from 'components/Modals/Modal/Modal.style';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const a = colors.modal.action;

const CloseContainer = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	.icon {
		display: flex;
	}
	&:hover {
		background: ${a.cancelBackground};
	}
`;

const CustomModalHeader = styled(ModalHeader)`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 2rem 1rem 2rem;
	margin-bottom: 0;
	border-bottom: 1px solid ${a.border};
`;

const CustomModalBody = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 312px;
	padding: 2rem;
`;

const CustomModalFooter = styled.div`
	display: flex;
	width: calc(100% - 4rem);
	justify-content: space-between;
	height: 64px;
	padding: 1rem 0rem;
	border-top: 1px solid ${a.border};
	margin: 0rem 2rem 1rem 2rem;
	button {
		font-size: 1rem;
		line-height: 1;
		min-width: 100px;
		width: auto;
		height: 46px;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		box-sizing: border-box;
		&.save {
			background-color: ${a.cancelBackground};
			&.saveable {
				background-color: ${a.darkColor};
				color: ${a.white};
			}
			&:hover {
				background-color: ${a.darkColor};
				color: ${a.white};
			}
			&:disabled {
				background-color: ${a.disabledBackground};
				color: ${a.disabledText};
			}
		}
		&.cancel {
			background-color: transparent;
		}
		&:hover {
			background-color: ${a.darkColor};
			color: ${a.white};
		}
	}
`;

const ModalHeaderTitle = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0em;
	text-align: left;
	padding-top: 0.5rem;
`;

const SpinnerWrapper = styled.div`
	justify-content: center;
	width: 100%;
	display: flex;
	padding-top: 2rem;
`;

const CustomModal = styled(Modal)`
	${ModalContent} {
		box-shadow: 0px 10px 20px 0px rgba(92, 104, 128, 0.16);
		border: 1px solid ${a.border};
		border-radius: 10px;
		overflow: hidden;
		min-height: 380px;
	}

	&.upload-csv-file-modal {
		${CustomModalHeader} {
			border-bottom: none;
			padding-bottom: 0;
		}

		${CustomModalBody} {
			padding: 0 ${spacing[4].rem};
		}

		${CustomModalFooter} {
			button {
				height: 36px;
				padding-right: ${spacing[3].rem};
				padding-left: ${spacing[3].rem};

				&.save {
					&:hover:not([disabled]) {
						background-color: ${colors.dataLibrary.csvFileUploadModal.importButtonBgHover};
					}
					&:disabled {
						background-color: ${colors.dataLibrary.csvFileUploadModal.importButtonBgHover};
					}
				}

				&.cancel {
					background-color: ${colors.dataLibrary.csvFileUploadModal.cancelButtonBg};

					&:hover {
						background-color: ${a.darkColor};
					}
				}
			}
		}
	}

	&.close-button-only {
		${CustomModalFooter} {
			justify-content: flex-end;
			button {
				&.save {
					display: none;
				}

				&.cancel {
					background-color: ${a.darkColor};
					color: ${a.white};

					&:hover {
						background-color: ${colors.dataLibrary.csvFileUploadModal.importButtonBgHover};
					}
				}
			}
		}
	}
`;

const Styled = {
	CustomModalHeader,
	ModalHeaderTitle,
	CustomModal,
	CustomModalFooter,
	CustomModalBody,
	CloseContainer,
	SpinnerWrapper,
};

export default Styled;
