import styled from 'styled-components';

import fontSize from 'styles/variables/font-size';
import { devices } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	position: relative;
	margin: 0 16px;
	display: flex;
	flex-direction: column;
`;

const WrapperInner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	@media screen and (${devices.lg}) {
		flex-direction: row;
	}
`;

const LeftContainer = styled.div`
	width: 100%;
	@media screen and (${devices.lg}) {
		max-width: 344px;
		position: relative;
	}
`;

const MainContainer = styled.div`
	width: 100%;
	padding-bottom: 5vh;
	@media screen and (${devices.lg}) {
		max-width: calc(100% - 320px);
		position: relative;
		padding-bottom: 0;
	}
`;

const MainContainerInner = styled.div`
	height: 80vh;
	padding: 1rem;
	h5 {
		font-size: ${fontSize.m};
	}
	@media screen and (${devices.lg}) {
		padding: 0;
		overflow: auto;
		padding-right: 1rem;
		overflow-y: auto;
		height: unset;
	}
`;

const Styled = {
	Wrapper,
	WrapperInner,
	LeftContainer,
	MainContainer,
	MainContainerInner,
};

export default Styled;
