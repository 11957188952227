import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgHeartLiked(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M19.3763 3.64595C18.4837 1.69026 16.6798 0.343416 14.5412 0.0574425C12.8768 -0.173182 11.2496 0.297293 9.98508 1.34894C9.3528 0.823116 8.71123 0.472567 8.00456 0.260393C6.32158 -0.24698 4.48053 0.0205431 2.94633 0.998389C1.33773 2.03159 0.277735 3.69208 0.0545771 5.57397C-0.177879 7.52044 0.389313 9.29163 1.83984 11.1551C3.5972 13.4152 5.85667 15.1034 8.04175 16.727C8.45088 17.0314 8.86 17.3358 9.25982 17.6402L9.52947 17.8524C9.65965 17.9446 9.80842 18 9.95719 18C10.106 18 10.264 17.9539 10.3849 17.8524L10.6546 17.6402C11.1195 17.2805 11.5844 16.9299 12.0493 16.5794C13.0535 15.8137 14.1972 14.9558 15.2758 14.0609C16.9681 12.668 18.1582 11.3303 19.0137 9.86358C20.1945 7.83409 20.3247 5.74002 19.3763 3.65518V3.64595Z'
				fill='#333333'
			/>
		</svg>
	);
}
