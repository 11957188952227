import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgEmailMissing(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M14.0872 11.2832L15.5721 12.7681L16.9934 11.3468L17.7359 12.0893L16.3146 13.5106L17.7217 14.9177L16.9793 15.6602L15.5721 14.253L14.0943 15.7309L13.3518 14.9884L14.8296 13.5106L13.3447 12.0256L14.0872 11.2832Z'
				fill='#FF474E'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11 13.5001C11 11.02 13.02 9 15.5 9C17.98 9 20 11.02 20 13.5001C20 15.9801 17.98 18.0001 15.5 18.0001C13.02 18.0001 11 15.9801 11 13.5001ZM12.05 13.5001C12.05 15.4001 13.6 16.9501 15.5 16.9501C17.4 16.9501 18.95 15.4001 18.95 13.5001C18.95 11.6 17.4 10.05 15.5 10.05C13.6 10.05 12.05 11.6 12.05 13.5001Z'
				fill='#FF474E'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.70513 2C0.763413 2 0 2.75648 0 3.68966V14.3103C0 15.2435 0.763412 16 1.70513 16H8.52564C8.92923 16 9.25641 15.6758 9.25641 15.2759C9.25641 14.8759 8.92923 14.5517 8.52564 14.5517H1.70513C1.5706 14.5517 1.46154 14.4437 1.46154 14.3103V13.5913C1.48539 13.5726 1.50837 13.5523 1.53033 13.5303L5.03033 10.0303C5.32322 9.73744 5.32322 9.26256 5.03033 8.96967C4.73744 8.67678 4.26256 8.67678 3.96967 8.96967L1.46154 11.4778V5.06919L9.66463 9.17074C9.87578 9.27631 10.1243 9.27631 10.3355 9.17074L18.5385 5.06924V7.47701C18.5385 7.87694 18.8656 8.20115 19.2692 8.20115C19.6728 8.20115 20 7.87694 20 7.47701V3.68966C20 2.75649 19.2366 2 18.2949 2H1.70513ZM18.389 3.46694C18.36 3.45492 18.3282 3.44828 18.2949 3.44828H1.70513C1.6718 3.44828 1.64003 3.45491 1.61109 3.46692L10 7.66139L18.389 3.46694Z'
				fill='#333333'
			/>
		</svg>
	);
}
