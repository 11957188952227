import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgCrop(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_6037_18023)'>
				<path
					d='M19.26 16.6H4.46L16.2 4.86V14.9H17.7V3.36L19.77 1.29C20.06 1 20.06 0.52 19.77 0.23C19.48 -0.06 19 -0.06 18.71 0.23L16.62 2.32H5.12V3.82H15.12L3.4 15.53V0.74C3.4 0.33 3.07 0 2.66 0C2.25 0 1.92 0.33 1.92 0.74V2.32H0.75C0.34 2.32 0 2.66 0 3.07C0 3.48 0.34 3.82 0.75 3.82H1.93V17.12C1.93 17.65 2.36 18.07 2.88 18.07H16.2V19.25C16.2 19.66 16.54 20 16.95 20C17.36 20 17.7 19.66 17.7 19.25V18.07H19.26C19.67 18.07 20 17.74 20 17.33C20 16.92 19.67 16.59 19.26 16.59V16.6Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_6037_18023'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
