import styled from 'styled-components';

import Accordion from 'components/Accordion';
import Icon from 'components/Icon';
import { detailItem, detailText, detailTitle } from 'components/NewBriefPage/NewBreifPage.style';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	margin-top: ${spacing[4].px};

	background-color: ${colors.mainWhite};
`;

const HeaderSubText = styled.div`
	margin-bottom: ${guttersWithRem.m};
`;

const HeaderText = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 2rem;
	font-weight: 500;
	margin-bottom: 4rem;
`;

const CampaignInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${guttersWithRem.m};
	max-width: 40rem;
	margin: 0 auto;
	margin-bottom: 2.5rem;
`;

const CustomAccordion = styled(Accordion)`
	& > div {
		text-align: left;
		padding: 0 2rem;
		max-height: unset;

		& > p {
			overflow-wrap: break-word;
		}

		& > div {
			margin-bottom: 1.5rem;
		}
	}
`;

const CustomIcon = styled(Icon)``;

const JoinNowLink = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.s};

	cursor: pointer;
	color: ${colors.button.sencondary.color};
	font-weight: 400;

	${CustomIcon} {
		fill: ${colors.button.sencondary.color};
	}
`;

const HashtagAndMentionWrapper = styled.div`
	display: flex;
	flex-flow: wrap;
	gap: ${guttersWithRem.xs};
	div {
		padding: ${spacing[2].rem} 0;
	}
`;

const DetailItem = styled(detailItem)``;
const DetailTitle = styled(detailTitle)``;
const DetailText = styled(detailText)``;

const Styled = {
	Wrapper,
	HeaderSubText,
	HeaderText,
	CampaignInfoWrapper,
	CustomAccordion,
	JoinNowLink,
	CustomIcon,
	HashtagAndMentionWrapper,
	DetailTitle,
	DetailText,
	DetailItem,
};

export default Styled;
