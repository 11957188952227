import classNames from 'classnames';

import Styled from './Progressbar.style';

type ProgressBarProps = {
	percentage: number;
	text?: string;
	hidePercentageLabel?: boolean;
	progressColor?: string;
	borderColor?: string;
	backgroundColor?: string;
	height?: number;
	radius?: number;
};

/**
 * ProgressBar
 * @param {ProgressBarProps} props
 * @returns {JSX.Element}
 */
const ProgressBar = ({ percentage, text, hidePercentageLabel, progressColor, borderColor, backgroundColor, height, radius }: ProgressBarProps): JSX.Element => {
	return (
		<Styled.Wrapper className='progress-bar' data-testid='progress-bar'>
			<Styled.PercentageLabel className={classNames({ hide: hidePercentageLabel })}>{percentage}%</Styled.PercentageLabel>
			<Styled.Progress borderColor={borderColor} backgroundColor={backgroundColor} height={height} radius={radius}>
				<Styled.Bar percentage={percentage} progressColor={progressColor} role='progressbar' radius={radius} />
			</Styled.Progress>
			{text !== undefined ? <Styled.ProgressText>{text}</Styled.ProgressText> : null}
		</Styled.Wrapper>
	);
};

export default ProgressBar;
