import { inject, injectable } from 'inversify';

import { ListBrandsQuery, ListClientsQuery } from 'api-queries';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ResourceManager from 'utils/Repository/ResourceManager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';

import type BrandApiClientInterface from 'services/ApiClient/BrandApiClientInterface';
import type ClientApiClientInterface from 'services/ApiClient/ClientApiClientInterface';

@injectable()
class OrganizationManager extends ApiManager {
	private readonly brandClient: BrandApiClientInterface;
	private readonly clientClient: ClientApiClientInterface;

	constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.BrandApiClientInterface) brandClient: BrandApiClientInterface,
		@inject(ApiClientService.ClientApiClientInterface) clientClient: ClientApiClientInterface,
	) {
		super(cacheManager, new ResourceManager());
		this.brandClient = brandClient;
		this.clientClient = clientClient;
	}

	public listClientsAndBrands(clientQuery = RequestQueryBuilder.create<ListClientsQuery>(), brandQuery = RequestQueryBuilder.create<ListBrandsQuery>()) {
		const key = `listClientsAndBrands::${clientQuery.toHash()}`;
		const fetcher = () => {
			return Promise.all([this.brandClient.listBrands(brandQuery.toQuery()), this.clientClient.listClients(clientQuery.toQuery())]);
		};

		return this.swr(key, fetcher, { multipleApiResponses: true });
	}
}

export default OrganizationManager;
