import React from 'react';
import { toast as sonner } from 'sonner';
import styled from 'styled-components';

import LoadingSpinner from 'components/LoadingSpinner';
import colors from 'styles/theme/colors';

type ToastMessage = string | React.ReactNode | JSX.Element;
type ToastId = string | number;

type ToastPromiseOptions<SuccessData> = {
	loading: ToastMessage;
	success: ToastMessage | ((data: SuccessData) => ToastMessage);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: ToastMessage | ((error: any) => ToastMessage);
	custom: ToastMessage;
};

type CustomToastWithLinkProps = {
	message: string;
	linkText: string;
	linkUrl: string;
};

const ToastContainer = styled.div`
	display: flex;
	color: ${colors.white};
	align-items: center;
	gap: 8px;
`;

const Message = styled.p`
	color: ${colors.white};
`;

const Link = styled.a`
	color: ${colors.white};
	border-bottom: 2px solid ${colors.white};
	max-width: 174px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: all 0.2s ease;
	&:hover {
		color: ${colors.blueOnBlack};
		border-bottom: 2px solid ${colors.blueOnBlack};
	}
`;

const CustomToastWithLink: React.FC<CustomToastWithLinkProps> = ({ message, linkText, linkUrl }) => {
	return (
		<ToastContainer data-testid='success-with-link-toast'>
			<Message>{message}</Message>
			<Link href={linkUrl}>{linkText}</Link>
		</ToastContainer>
	);
};

export const sonnerToasterOptions = {
	unstyled: true,
	closeButton: true,
	duration: 7_000,
	classNames: {
		toast: 'cb-toast',
		error: 'cb-toast--error',
		success: 'cb-toast--success',
		loading: 'cb-toast--loading',
		closeButton: 'cb-toast__close',
	},
};

export const sonnerToasterIcons = {
	loading: <LoadingSpinner size='sm' />,
};

const escapeHtml = function (input: string): string {
	return input.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&#39;').replace(/"/g, '&#34;');
};

/**
 * This is an abstraction over our toast library
 */
export default {
	success(message: ToastMessage): ToastId {
		if (typeof message === 'string') {
			message = escapeHtml(message);
		}
		return sonner.success(message);
	},

	error(message: ToastMessage): ToastId {
		if (typeof message === 'string') {
			message = escapeHtml(message);
		}
		return sonner.error(message);
	},

	promise<SuccessData>(promise: Promise<SuccessData>, options: ToastPromiseOptions<SuccessData>): ToastId {
		return sonner.promise<SuccessData>(promise, options);
	},

	successWithLink(message: string, linkText: string, linkUrl: string): ToastId {
		const component = <CustomToastWithLink message={message} linkText={linkText} linkUrl={linkUrl} />;
		return sonner(component, { duration: 8000 });
	},
};
