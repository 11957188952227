import styled from 'styled-components';

const Wrapper = styled.div``;

const CenteredInnerWrapper = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Styled = {
	Wrapper,
	CenteredInnerWrapper,
};

export default Styled;
