import { useEffect } from 'react';

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string) => {
	const handleLineClasses = (element: HTMLTextAreaElement): void => {
		const messageBar = document.querySelector('.msg-bar'); // parent that holds textbox and controls
		const lines = element.value.split(/\r*\n/).length;

		if (messageBar) {
			if (lines > 1 || element.value.length >= 78) {
				if (messageBar) {
					messageBar.classList.add('lines');
				}
			} else {
				if (messageBar) {
					messageBar.classList.remove('lines');
				}
			}
		}
	};

	useEffect(() => {
		if (textAreaRef) {
			handleLineClasses(textAreaRef);
			// We need to reset the height momentarily to get the correct scrollHeight for the textarea
			textAreaRef.style.height = '0px';
			const scrollHeight = textAreaRef.scrollHeight;

			// We then set the height directly, outside of the render loop
			// Trying to set this with state or a ref will product an incorrect value.
			textAreaRef.style.height = scrollHeight + 'px';
		}
	}, [textAreaRef, value]);
};

export default useAutosizeTextArea;
