import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Div = styled.div`
	border-radius: 4px;
	padding: ${spacing[1].rem};
	padding-top: 12px;

	background-color: ${colors.statusTag.active.backgroundColor};

	&.error {
		background-color: ${colors.statusTag.danger.backgroundColor};
		p {
			margin: 0;
			color: ${colors.statusTag.danger.color};
		}
		.icon path {
			fill: ${colors.statusTag.danger.color};
		}
	}

	.icon {
		margin-right: ${spacing[1].px};
	}

	display: flex;
	align-items: center;
	p {
		margin-bottom: 0;
		font-size: 0.75rem;
		color: ${colors.statusTag.active.color};
	}
`;

const Styled = {
	Div,
};
export default Styled;
