import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	width: 100%;
	line-height: 1;
	margin-bottom: 24px;
	&.showCursor {
		cursor: pointer;
	}
`;

const Label = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xs};

	margin-bottom: ${guttersWithRem.s};
	font-weight: 700;

	& > i {
		display: flex;
	}

	& > div {
		text-transform: capitalize;
	}
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > div > i {
		display: flex;
	}
`;

const Details = styled.div`
	padding-top: ${guttersWithRem.xs};
	display: none;
	flex-direction: column;
	row-gap: ${guttersWithRem.xs};

	&.visible {
		display: flex;
	}
`;

const DetailItemWrapper = styled.div`
	width: 100%;
	padding: ${guttersWithRem.m};
	border-radius: ${borderRadius.s};
	background-color: ${colors.mainWhite};
	&.hideCursor {
		cursor: default;
	}
	&:hover {
		&.hideCursor {
			background-color: ${colors.mainWhite};
			color: ${colors.mainBlack};
			div {
				&.date {
					color: ${colors.mainBlack} !important;
				}
				&.value {
					color: ${colors.mainBlack} !important;
				}
			}
		}
		background-color: ${newColors.ash};
	}
	margin: 4px 0;
	& > div {
		&.title {
			display: flex;
			gap: 8px;
			margin-bottom: 8px;
			align-items: center;
			font-weight: 500;
		}

		&.date,
		&.value {
			font-size: 0.875rem;
			margin-top: 8px;
		}
		&.onHover {
			opacity: 0;
			color: ${newColors.oceanBlue};
			transition: opacity 200ms;
		}
	}
`;

const ProductDetailInnerWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	& > div {
		&.title {
			font-weight: 500;
		}
	}
	& > div {
		&.value {
			margin-top: 16px;
		}
	}
`;

const IconWrapper = styled.div`
	cursor: pointer;
	& i {
		line-height: 0;
	}
`;

const PromoCodeList = styled.ul`
	width: 100%;
	margin-bottom: 0;
	list-style: none;
	li {
		width: 100%;
		padding: 0.2rem 0.5rem;
		border-radius: ${borderRadius.s};
		cursor: pointer;
		height: 35px;
		&:hover {
			background-color: ${colors.mainBlack};
			color: ${colors.white};
			.icon path {
				fill: ${colors.white};
			}
		}
		div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 5px;
		}
	}
`;

const Styled = {
	PromoCodeList,
	Wrapper,
	Label,
	Content,
	Details,
	DetailItemWrapper,
	ProductDetailInnerWrapper,
	IconWrapper,
};

export default Styled;
