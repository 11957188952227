import classNames from 'classnames';
import { useState } from 'react';

import Avatar from 'components/Avatar';
import Pill from 'components/Pill';

import Styled from './CampaignItem.style';

type CampaignItemType = {
	id: string;
	name: string;
	isSelected: boolean;
	coverImage?: string;
	onClick: () => void;
	cursor?: number;
	i: number;
	archivedAt?: string | null;
};

/**
 * CampaignItem
 * @param {CampaignItemType} props
 * @returns  {JSX.Element}
 */
const CampaignItem = (props: CampaignItemType) => {
	const [displayFullName, setDisplayFullName] = useState(false);

	return (
		<>
			<Styled.ListItemContainer onClick={props.onClick} data-testid={`c-${props.id}`}>
				<Styled.Wrapper className={classNames({ selected: props.isSelected || props.i === props.cursor })}>
					<Avatar imageUrl={props.coverImage} name={props.name} />
					<Styled.TextContainer onMouseEnter={() => setDisplayFullName(true)} onMouseLeave={() => setDisplayFullName(false)}>
						{displayFullName ? <Styled.FullNameText>{props.name}</Styled.FullNameText> : <Styled.TruncatedText>{props.name}</Styled.TruncatedText>}
						{props.archivedAt && <Pill title='archived' className='archived' />}
					</Styled.TextContainer>
				</Styled.Wrapper>
			</Styled.ListItemContainer>
			<Styled.Divider />
		</>
	);
};

export default CampaignItem;
