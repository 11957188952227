import { Model } from 'json-api-models';
import { useContext } from 'react';

import Icon from 'components/Icon';
import DiscoveryContext from 'contexts/Discovery';
import { InfluencerListItemType } from 'contexts/Discovery/types';

import Styled from './PlatformTab.styled';

const PlatformTab = (props: { defaultInfluencerData: InfluencerListItemType; relatedAccounts: Model[]; onSelectPlatform: (id: string) => void }) => {
	const { changeSearchValueHandler, updateNetworkHandler } = useContext(DiscoveryContext);

	return (
		<Styled.Wrapper>
			<p>{props.relatedAccounts.length > 1 ? 'Related accounts' : 'Related account'}</p>
			<Styled.InnerWrapper>
				{props.relatedAccounts.map((account) => (
					<Styled.PlatformItem
						key={account.username}
						onClick={() => {
							updateNetworkHandler(account.network);
							changeSearchValueHandler({ text: `@${account.username}` });
						}}
					>
						<Icon name={account.network} size='16' />
						<span>{account.username}</span>
						<Icon name='chevron-right' size='16' />
					</Styled.PlatformItem>
				))}
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default PlatformTab;
