import axios, { AxiosInstance } from 'axios';
import { inject, injectable } from 'inversify';
import { isNil } from 'lodash';

import GenericApiClientInterface, { DefaultParams } from './GenericApiClientInterface';
import ServiceIdentifier from './ServiceIdentifier';
import { ApiResponse } from './types';

/**
 * This client is good to use if you have Hateoas links you want to follow.
 */
@injectable()
class GenericApiClient<ResponseType = ApiResponse> implements GenericApiClientInterface {
	private client: AxiosInstance;
	private factory: () => AxiosInstance;
	private useDefaultParams: boolean;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClientLegacy) factory: () => AxiosInstance, useDefaultParams = true) {
		this.factory = factory;
		this.client = factory();
		this.useDefaultParams = useDefaultParams;
	}

	/**
	 * This method is used to create a new instance of the client with the default params turned off.
	 */
	noDefaultParams(): GenericApiClient<ApiResponse> {
		return new GenericApiClient<ApiResponse>(this.factory, false);
	}

	/**
	 * This method is used to create a new instance of the client that will do request to the external API.
	 */
	external<T = never>(): GenericApiClient<T> {
		const clone = new GenericApiClient<never>(this.factory, false);
		// Create a new pure client without any interceptors
		clone.client = axios.create();

		return clone;
	}

	addDefaultParams(params: DefaultParams) {
		if (!this.useDefaultParams) {
			return params;
		}

		if (isNil(params.exclude)) {
			params.exclude = 'defaultIncludes';
		}
		if (isNil(params.include)) {
			params.include = ':hateoas(false)';
		}

		return params;
	}

	get(url: string, params: DefaultParams = {}) {
		params = this.addDefaultParams(params);
		return this.client.get<ResponseType>(url, { params });
	}

	/* eslint-disable @typescript-eslint/no-explicit-any */
	patch(url: string, payload?: any, params: DefaultParams = {}) {
		params = this.addDefaultParams(params);
		return this.client.patch<ResponseType>(url, payload, { params });
	}

	/* eslint-disable @typescript-eslint/no-explicit-any */
	post(url: string, payload?: any, params: DefaultParams = {}) {
		params = this.addDefaultParams(params);
		return this.client.post<ResponseType>(url, payload, { params });
	}

	/* eslint-disable @typescript-eslint/no-explicit-any */
	put(url: string, payload?: any, params: DefaultParams = {}) {
		params = this.addDefaultParams(params);
		return this.client.put<ResponseType>(url, payload, { params });
	}

	delete(url: string) {
		return this.client.delete<void>(url);
	}
}

export default GenericApiClient;
