import Icon from 'components/Icon';
import ConfirmModal from 'components/Modals/ConfirmModal';

import Styled from './DeleteModal.style';
import { DeleteModalProps } from './types';

/**
 * Delete modal component
 * @returns {JSX.Element}
 */
const DeleteModal = ({
	iconReplacement,
	isModalOpen,
	toggleModal,
	title,
	isFetching,
	action,
	children,
	buttonText,
	cancelText,
	id,
}: DeleteModalProps): JSX.Element => {
	return (
		<ConfirmModal
			isModalOpen={isModalOpen}
			confirmButtonAsDanger
			buttonText={buttonText ?? 'Delete'}
			cancelButtonText={cancelText}
			action={action}
			isFetching={isFetching}
			testId={id ? `delete-modal-${id}` : 'delete-modal'}
			toggleModal={toggleModal}
			title={title ?? ''}
			iconReplacement={
				iconReplacement ?? (
					<Styled.ModalDeleteIconContainer>
						<Icon name='trash-bin' size='18' />
					</Styled.ModalDeleteIconContainer>
				)
			}
		>
			<Styled.Content>{children}</Styled.Content>
		</ConfirmModal>
	);
};

export default DeleteModal;
