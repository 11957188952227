import { AxiosError } from 'axios';
import { inject, injectable } from 'inversify';

import InternalHttpClientFactory from 'shared/ApiClient/InternalHttpClientFactory';

@injectable()
export default class LogErrorsInterceptor {
	public constructor(@inject(InternalHttpClientFactory) private httpFactory: InternalHttpClientFactory) {}

	/**
	 * We use a new HTTP client to log errors. This is to avoid circular dependencies.
	 * @param error
	 */
	public onError(error: AxiosError) {
		const context = {
			message: error.message,
			response: `${error.response?.data ?? ''}`,
			http_code: `${error.response?.status ?? ''}`,
			url: error.request?.responseURL,
		};

		const payload = {
			message: 'Frontend_RequestError',
			context: context,
		};

		this.httpFactory.createCollabsApiClient().post<void>(`/tools/log`, payload, { params: { include: ':hateoas(false)' } });

		return Promise.reject(error);
	}
}
