import { createContext, useState } from 'react';

export interface ThemeContextProps {
	children: JSX.Element;
}

export interface IThemeContext {
	currentTheme: string;
	setTheme: (themeName: string) => void;
	clearTheme: () => void;
}

const ThemeContext = createContext<IThemeContext>({
	currentTheme: '',
	// eslint-disable-next-line unused-imports/no-unused-vars
	setTheme: (_themeName: string) => {},
	clearTheme: () => {},
});

export const ThemeContextProvider: React.FC<ThemeContextProps> = ({ children }) => {
	const [_theme, _setTheme] = useState<string>('default');

	const setTheme = (themeName: string) => {
		_setTheme(themeName);
	};

	const clearTheme = () => {
		_setTheme('default');
	};

	const context = {
		currentTheme: _theme,
		setTheme: setTheme,
		clearTheme: clearTheme,
	};

	const ThemeContextProps = {
		value: context,
	};
	return <ThemeContext.Provider {...ThemeContextProps}>{children}</ThemeContext.Provider>;
};

export default ThemeContext;
