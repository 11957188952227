import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactCropperElement } from 'react-cropper';
import { useParams } from 'react-router-dom';

import { CropDimensionsType, KIND, RouteParams } from 'components/EditableImage/types/types';
import useImageUpload from 'hooks/useImageUpload/useImageUpload';
import toast from 'services/Toast';

const useEditableImage = (originalImage: string, kind: KIND, getBriefData?: () => void, sortOrder?: number, totalFiles?: number) => {
	const { campaignId } = useParams<RouteParams>();
	const [image, setImage] = useState<string | undefined>();
	const [sort, setSort] = useState<number | undefined>(sortOrder);

	const [file, setFile] = useState<File | undefined>();
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [shouldCrop, setShouldCrop] = useState<boolean>(false);
	const [cropDimensions, setCropDimensions] = useState<CropDimensionsType | null>(null);
	const [isReplacedImage, setIsReplacedImage] = useState<boolean>(false);

	const { uploadAndPollImage, isLoading } = useImageUpload(kind, totalFiles);

	const cropperRef = useRef<ReactCropperElement>(null);

	useEffect(() => {
		setSort(sortOrder);
	}, [sortOrder]);

	//CROPPING
	const useCrop = () => {
		if (cropperRef.current) {
			const croppedDataUrl = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
			const cropData = cropperRef.current.cropper.getData(true);

			setCropDimensions({ x: cropData.x, y: cropData.y, cropWidth: cropData.width, cropHeight: cropData.height });
			setImage(croppedDataUrl);
			setIsReplacedImage(true);
			setShouldCrop(false);
		}
	};

	const resetCroppedImage = () => {
		if (cropperRef.current) {
			cropperRef.current.cropper.reset();
		}
	};

	const resetCrop = (resetImage = false) => {
		if (cropperRef.current) {
			cropperRef.current.cropper.reset();
			resetImage && setImage(originalImage);
			setShouldCrop(false);
		}
	};

	//ADDING IMAGE
	const onDrop = useCallback((acceptedFiles: Array<File>) => {
		if (acceptedFiles.length > 0) {
			const file = acceptedFiles[0];
			setFile(acceptedFiles[0]);
			const reader = new FileReader();

			reader.onload = () => {
				setCropDimensions(null);
				setImage(reader.result as string);
				setIsReplacedImage(true);
				setIsEditMode(true);
			};

			reader.onerror = (error) => {
				console.error('Error readig file on drop: ', error);
			};

			reader.readAsDataURL(file);
		}
	}, []);

	//SAVE IMAGE
	const postCropDimensionsAndImage = async () => {
		if (campaignId && file) {
			try {
				const responseUrl = await uploadAndPollImage(file, cropDimensions, campaignId, sort);
				if (responseUrl) {
					getBriefData && getBriefData();
					// Timeout to give backend some time to generate the different version of the image, this will be removed once we use mercure
					setIsEditMode(false);
					setShouldCrop(false);
					setCropDimensions(null);
					toast.success('Image uploaded');
					return responseUrl;
				}
			} catch (error) {
				toast.error('Failed to save image');
				setIsReplacedImage(false);
				setFile(undefined);
			}
		}
	};

	//Modal if trying to navigate away before loading finishes
	const handleBeforeUnload = (event: BeforeUnloadEvent) => {
		if (isLoading) {
			const confirmationMessage = 'Are you sure you want to leave?';
			event.returnValue = confirmationMessage;
			return confirmationMessage;
		}
	};

	async function getImageFileFromUrl(imageUrl: string): Promise<File | undefined> {
		try {
			const response = await fetch(imageUrl);
			const blob = await response.blob();

			// Extracting file name from the URL
			const fileName = imageUrl.split('/').pop() || 'image.jpg';

			// Creating a File object
			return new File([blob], fileName, { type: blob.type });
		} catch (error) {
			console.error('Error creating File from image URL:', error);
			return undefined;
		}
	}

	const convertOrgImageToFile = (originalImage: string) => {
		if (originalImage) {
			getImageFileFromUrl(originalImage)
				.then((file) => setFile(file))
				.catch((error) => console.error('error converting image url to file: ', error));
		}
	};

	useEffect(() => {
		convertOrgImageToFile(originalImage);
	}, []);

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [handleBeforeUnload]);

	useEffect(() => {
		convertOrgImageToFile(originalImage);
		setImage(originalImage);
	}, [originalImage]);

	return {
		cropState: { resetCroppedImage, cropperRef, shouldCrop, setShouldCrop, resetCrop, cropDimensions },
		imageState: { image, setImage, isReplacedImage, setIsReplacedImage },
		editState: { isEditMode, setIsEditMode },
		briefFunctions: { onDrop, useCrop, postCropDimensionsAndImage },
		isLoading: isLoading,
	};
};

export default useEditableImage;
