import styled from 'styled-components';

import typography from 'styles/variables/typography';

interface Wrapper {
	width?: string;
	height?: string;
}

const Wrapper = styled.div<Wrapper>`
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || 'auto'};

	display: flex;
	flex-direction: column;
	padding: 0.625rem 0;
`;

const FlexItem = styled.div`
	margin-bottom: 40px;
	width: 100%;
`;

const CenteredWrapper = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	flex: 1;
	display: flex;
	justify-content: center;
`;

const Styled = {
	Wrapper,
	CenteredWrapper,
	FlexItem,
};

export default Styled;
