import classNames from 'classnames';
import { Model, Store } from 'json-api-models';
import { useState, useEffect } from 'react';

import IconButton from 'components/Discovery/Components/IconButton';
import { useListItemData } from 'components/Discovery/Components/InfluencerListItem/hooks';
import Pill from 'components/Pill';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { isSuccessfulResponse } from 'services/Response.types';
import toast from 'services/Toast';

import Styled from './InfluencerListItemComments.style';

/**
 */
const InfluencerListItemReactions = (props: { listItem: Model }) => {
	const { listItem } = props;
	const { reactOnListItem, deleteListItemReaction } = useListItemData();

	const [likes, setLikes] = useState<Model[]>([]);
	const [dislikes, setDislikes] = useState<Model[]>([]);
	const [userHasLikedThisReaction, setUserHasLikedThisReaction] = useState<Model | null>(null);
	const [userHasLikedThisBoolean, setUserHasLikedThisBoolean] = useState<boolean>(false);
	const [userHasDislikedThisBoolean, setUserHasDislikedThisBoolean] = useState<boolean>(false);

	const [userHasDislikedThisReaction, setUserHasDislikedThisReaction] = useState<Model | null>(null);

	const user = useAppSelector((state) => state.user);

	const sendReaction = (reaction: string) => {
		const models = new Store();

		switch (reaction) {
			case 'like': {
				if (userHasLikedThisReaction) {
					// Remove like
					setUserHasLikedThisReaction(null);
					setUserHasLikedThisBoolean(false);
					const reactionsAfterDelete = likes.filter((remove: Model) => remove.id !== userHasLikedThisReaction.id);
					setLikes(reactionsAfterDelete);
					return deleteListItemReaction(userHasLikedThisReaction.links.delete).then((res) => {
						if (res && isSuccessfulResponse(res.status ?? 0)) {
							toast.success('Removed like');
						}
					});
				} else if (userHasDislikedThisReaction) {
					// Add like and remove dislike
					setUserHasDislikedThisReaction(null);
					setUserHasLikedThisBoolean(true);
					setUserHasDislikedThisBoolean(false);
					const reactionsAfterDelete = dislikes.filter((remove: Model) => remove.id !== userHasDislikedThisReaction.id);
					setDislikes(reactionsAfterDelete);
					return deleteListItemReaction(userHasDislikedThisReaction.links.delete).then(() => {
						return reactOnListItem(listItem.links.addReaction, 'like').then((res) => {
							if (res && res.status === 200) {
								toast.success('You like this');
								models.sync(res.data);
								const reaction = models.find('listItemReaction', res.data.data.id);
								setUserHasLikedThisReaction(reaction);
								setLikes([reaction, ...likes]);
							}
						});
					});
				} else {
					// Add like
					setUserHasLikedThisBoolean(true);
					return reactOnListItem(listItem.links.addReaction, 'like').then((res) => {
						if (res && res.status === 200) {
							toast.success('You liked this');
							models.sync(res.data);
							const reaction = models.find('listItemReaction', res.data.data.id);
							setUserHasLikedThisReaction(reaction);
							setLikes([reaction, ...likes]);
						}
					});
				}
			}
			case 'dislike': {
				if (userHasDislikedThisReaction) {
					// Remove dislike
					setUserHasDislikedThisReaction(null);
					setUserHasDislikedThisBoolean(false);
					const reactionsAfterDelete = dislikes.filter((remove: Model) => remove.id !== userHasDislikedThisReaction.id);
					setDislikes(reactionsAfterDelete);
					return deleteListItemReaction(userHasDislikedThisReaction.links.delete).then((res) => {
						if (res && isSuccessfulResponse(res.status ?? 0)) {
							toast.success('Dislike removed');
						}
					});
				} else if (userHasLikedThisReaction) {
					// Remove like and add dislike
					setUserHasLikedThisReaction(null);
					setUserHasLikedThisBoolean(false);
					setUserHasDislikedThisBoolean(true);
					setLikes((prev) => {
						return prev.filter((item) => item.id !== userHasLikedThisReaction.id);
					});
					return deleteListItemReaction(userHasLikedThisReaction.links.delete).then(() => {
						return reactOnListItem(listItem.links.addReaction, 'dislike').then((res) => {
							if (res && res.status === 200) {
								toast.success('You disliked this');
								models.sync(res.data);
								const reaction = models.find('listItemReaction', res.data.data.id);
								setUserHasDislikedThisReaction(reaction);
								setDislikes([reaction, ...dislikes]);
							}
						});
					});
				} else {
					// Add dislike
					setUserHasDislikedThisBoolean(true);
					return reactOnListItem(listItem.links.addReaction, 'dislike').then((res) => {
						if (res && res.status === 200) {
							toast.success('You disliked this');
							models.sync(res.data);
							const reaction = models.find('listItemReaction', res.data.data.id);
							setUserHasDislikedThisReaction(reaction);
							setDislikes([reaction, ...dislikes]);
						}
					});
				}
			}
			default:
				return null;
		}
	};

	useEffect(() => {
		if (listItem.reactions) {
			// Filter likes and dislikes on every item. Set Reaction in state to use delete link on click.
			// eslint-disable-next-line prettier/prettier
			const influencerLikes = listItem.reactions?.filter((reaction: Model) => reaction.reaction === 'like');
			const hasLikedThis = influencerLikes.filter((reaction: Model) => reaction.createdBy.id === user.id);
			setLikes(influencerLikes);
			if (hasLikedThis.length > 0) {
				setUserHasLikedThisReaction(hasLikedThis[0]);
				setUserHasLikedThisBoolean(true);
			}

			const commentDislikes = listItem.reactions?.filter((reaction: Model) => reaction.reaction === 'dislike');
			const hasDislikedThis = commentDislikes.filter((reaction: Model) => reaction.createdBy.id === user.id);
			if (hasDislikedThis.length > 0) {
				setUserHasDislikedThisReaction(hasDislikedThis[0]);
				setUserHasDislikedThisBoolean(true);
			}
			setDislikes(commentDislikes);
		}
	}, []);

	return (
		<Styled.ReactionsWrapper>
			<Styled.ReactionsIconWrapper>
				<IconButton
					className={classNames('like', 'influencer-list-item')}
					iconName={userHasLikedThisReaction || userHasLikedThisBoolean ? 'thumbs-up-filled' : 'thumbs-up'}
					iconSize='20'
					onClick={(e) => {
						e.stopPropagation();
						sendReaction('like');
					}}
					helpText='Like'
				/>
				{likes.length > 0 && <Pill className='icons' title={`${likes?.length}`} />}
			</Styled.ReactionsIconWrapper>

			<Styled.ReactionsIconWrapper>
				<IconButton
					className={classNames('dislike', 'influencer-list-item')}
					iconName={userHasDislikedThisReaction || userHasDislikedThisBoolean ? 'thumbs-down-filled' : 'thumbs-down'}
					iconSize='20'
					onClick={(e) => {
						e.stopPropagation();
						sendReaction('dislike');
					}}
					helpText='Dislike'
				/>
				{dislikes.length > 0 && <Pill className='icons' title={`${dislikes?.length}`} />}
			</Styled.ReactionsIconWrapper>
		</Styled.ReactionsWrapper>
	);
};

export default InfluencerListItemReactions;
