import usePermissions from 'hooks/usePermissions';
import { ROLE_ADMINISTRATOR, ROLE_BRAND_MANAGER, ROLE_DATA_ANALYST, ROLE_PROJECT_MANAGER } from 'hooks/usePermissions/types';

export const useUserFriendlyRoleName = () => {
	const { isAgent, isSuperAdmin, isInfluencer, isGranted } = usePermissions();

	if (isInfluencer()) {
		return 'Influencer';
	}
	if (isAgent()) {
		return 'Agent';
	}
	if (isGranted(ROLE_ADMINISTRATOR)) {
		return 'Manager';
	}
	if (isGranted(ROLE_PROJECT_MANAGER)) {
		return 'Editor';
	}
	if (isGranted(ROLE_BRAND_MANAGER)) {
		return 'Viewer';
	}
	if (isGranted(ROLE_DATA_ANALYST)) {
		return 'Data analyst';
	}
	if (isSuperAdmin()) {
		return 'Super admin';
	}

	return '';
};
