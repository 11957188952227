/**
 * Creates a new object by omitting specified keys from the original object.
 * This utility function is useful for removing properties from an object
 * before passing it to functions or saving it, especially when dealing with
 * objects that need to be serialized without certain keys.
 *
 * @param obj - The original object from which keys are to be omitted.
 * @param keysToOmit - An array of keys that should be omitted from the original object.
 * @returns A new object that is a shallow copy of the original, minus the specified keys.
 *
 * @template T - The type of the original object.
 * @template K - The type of the keys to be omitted, constrained to the keys of T.
 */
export function omit<T extends object, K extends keyof T>(obj: T, keysToOmit: K[]): Omit<T, K> {
	const clone = Object.assign({}, obj);
	keysToOmit.forEach((key) => delete clone[key]);
	return clone;
}
