import Skeleton from 'react-loading-skeleton';
// eslint-disable-next-line import/no-unassigned-import
import 'react-loading-skeleton/dist/skeleton.css';

import Styled from './SkeletonAvatarWithMessage.style';

const SkeletonEmptyState = () => {
	return (
		<Styled.EmptyStateWrapper>
			<div>
				<div className='image-wrapper'>
					<Skeleton width='200px' height='100px' />
				</div>
				<div className='mb-8'>
					<Skeleton height='15px' containerClassName='flex-1' />
				</div>
				<Skeleton height='10px' count={2} containerClassName='flex-1' />
			</div>
		</Styled.EmptyStateWrapper>
	);
};

export default SkeletonEmptyState;
