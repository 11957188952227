import styled from 'styled-components';

import colors from 'styles/variables/colors';

const EditSlider = styled.div`
	width: 100%;
	display: flex;
	overflow: hidden;
	justify-content: flex-end;
`;

const ConfirmCancel = styled.div<{ editMode: boolean }>`
	display: flex;
	position: relative;
	width: auto;
	color: ${colors.info};
	height: fit-content;
	transition: transform 0.2s ease-in-out;
	font-size: 0.875rem;
	&.done {
		&:hover {
			font-weight: 600;
		}
		transform: ${({ editMode }) => (editMode ? 'translateX(0)' : 'translateX(50px)')};
		cursor: pointer;
		width: 36px;
	}
	&.edit {
		margin-right: -30px;
		&:hover {
			font-weight: ${({ editMode }) => !editMode && 600};
		}
		float: right;
		transform: ${({ editMode }) => (editMode ? 'translateX(-40px)' : 'translateX(0)')};
		color: ${({ editMode }) => (editMode ? colors.gray8 : colors.info)};
		cursor: ${({ editMode }) => !editMode && 'pointer'};
	}
`;

export default {
	EditSlider,
	ConfirmCancel,
};
