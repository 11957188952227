import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgDownload(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M10.6703 14.5101L16.2824 8.9001C16.5725 8.6101 16.5725 8.1301 16.2824 7.8401C15.9923 7.5501 15.5121 7.5501 15.222 7.8401L10.7503 12.3101L10.7503 0.750097C10.7503 0.340097 10.4102 9.67424e-05 10 9.67065e-05C9.78992 9.66882e-05 9.60985 0.0800969 9.4698 0.220097C9.32974 0.360097 9.24971 0.540097 9.24971 0.750097L9.24971 12.3201L4.77799 7.8501C4.48788 7.5601 4.0077 7.5601 3.71758 7.8501C3.42747 8.1401 3.42747 8.6201 3.71758 8.9101L9.32974 14.5101C9.69988 14.8801 10.3001 14.8801 10.6703 14.5101Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2 19.2476C2 18.8333 2.307 18.4976 2.68571 18.4976H17.3143C17.693 18.4976 18 18.8333 18 19.2476C18 19.6618 17.693 19.9976 17.3143 19.9976H2.68571C2.307 19.9976 2 19.6618 2 19.2476Z'
				fill='#333333'
			/>
		</svg>
	);
}
