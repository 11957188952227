import { StatisticsInfluencerWithStatisticsModel } from 'api-models';
import img1 from 'assets/img/influencers/login1.jpg';
import img2 from 'assets/img/influencers/login2.jpg';
import img3 from 'assets/img/influencers/login3.jpg';
import img4 from 'assets/img/influencers/login4.jpg';
import img5 from 'assets/img/influencers/login5.jpg';
import { PaidMediaStatistic } from 'components/DataLibrary/Graphs/GraphComponents/PaidMedia/types';

export const FakeData: StatisticsInfluencerWithStatisticsModel[] = [
	{
		id: '1',
		type: 'influencerWithStatistics',
		links: {
			profileImage: img2,
		},
		attributes: {
			mediaObjects: 9,
			conversionsWithSaleAmount: 12300,
			averageEngagement: 23,
			shares: 2,
			saves: 7,
			ownerId: '2',
			networkId: '1',
			name: 'foo',
			averageReach: 0,
			username: 'rickie_morissette',
			followers: 810999,
			assignments: 2,
			reach: 927699,
			grossReach: 123123,
			averageReachRate: 123123,
			impressions: 918985,
			comments: 5,
			likes: 45,
			engagement: 50,
			paymentAmount: 0,
			estimatedUniqueReach: 609148,
			reachRate: 1.1438966015987688,
			engagementRate: 0.0000616523571545711,
			actualEngagementRate: 0.0000616523571545711,
			conversions: 6,
			conversionSaleAmount: 4,
			cpe: 0,
			cpr: 0,
			clicks: 345677,
			currency: '',
		},
	},
	{
		id: '2',
		type: 'influencerWithStatistics',
		links: {
			profileImage: img3,
		},
		attributes: {
			mediaObjects: 9,
			conversionsWithSaleAmount: 12300,
			averageEngagement: 23,
			shares: 2,
			saves: 7,
			ownerId: '2',
			networkId: '1',
			name: 'foo',
			averageReach: 0,
			username: 'rubie_quitzon',
			followers: 779239,
			assignments: 2,
			reach: 228858,
			grossReach: 123123,
			averageReachRate: 123123,
			impressions: 554358,
			comments: 5,
			likes: 45,
			engagement: 50,
			paymentAmount: 0,
			estimatedUniqueReach: 204292,
			reachRate: 0.29369423244986453,
			engagementRate: 0.00006416516627119536,
			actualEngagementRate: 0.0000616523571545711,
			conversions: 12,
			conversionSaleAmount: 11,
			cpe: 0,
			cpr: 0,
			clicks: 245677,
			currency: '',
		},
	},
	{
		id: '3',
		type: 'influencerWithStatistics',
		links: {
			profileImage: img4,
		},
		attributes: {
			mediaObjects: 9,
			conversionsWithSaleAmount: 12300,
			averageEngagement: 23,
			shares: 2,
			saves: 7,
			ownerId: '2',
			networkId: '1',
			name: 'foo',
			averageReach: 0,
			username: 'shane_hermann',
			followers: 25003,
			assignments: 2,
			reach: 1319538,
			grossReach: 123123,
			averageReachRate: 123123,
			impressions: 1030839,
			comments: 5,
			likes: 45,
			engagement: 50,
			paymentAmount: 0,
			estimatedUniqueReach: 792439,
			reachRate: 52.77518697756269,
			engagementRate: 0.0019997600287965443,
			actualEngagementRate: 0.0000616523571545711,
			conversions: 21,
			conversionSaleAmount: 18,
			cpe: 0,
			cpr: 0,
			clicks: 765677,
			currency: '',
		},
	},
	{
		id: '4',
		type: 'influencerWithStatistics',
		links: {
			profileImage: img5,
		},
		attributes: {
			mediaObjects: 9,
			conversionsWithSaleAmount: 12300,
			averageEngagement: 23,
			shares: 2,
			saves: 7,
			ownerId: '2',
			networkId: '1',
			name: 'foo',
			averageReach: 0,
			username: 'simeon_conroy',
			followers: 521224,
			assignments: 2,
			reach: 689720,
			grossReach: 123123,
			averageReachRate: 123123,
			impressions: 402095,
			comments: 5,
			likes: 45,
			engagement: 50,
			paymentAmount: 0,
			estimatedUniqueReach: 399405,
			reachRate: 1.323269841757095,
			engagementRate: 0.00009592804629103801,
			actualEngagementRate: 0.0000616523571545711,
			conversions: 43,
			conversionSaleAmount: 35,
			cpe: 0,
			cpr: 0,
			clicks: 123657,
			currency: '',
		},
	},
	{
		id: '5',
		type: 'influencerWithStatistics',
		links: {
			profileImage: img1,
		},
		attributes: {
			mediaObjects: 9,
			conversionsWithSaleAmount: 12300,
			averageEngagement: 23,
			shares: 2,
			saves: 7,
			ownerId: '2',
			networkId: '1',
			name: 'foo',
			averageReach: 0,
			username: 'zoe_barrows',
			followers: 703678,
			assignments: 2,
			reach: 1050729,
			grossReach: 123123,
			averageReachRate: 123123,
			impressions: 1342391,
			comments: 5,
			likes: 45,
			engagement: 50,
			paymentAmount: 0,
			estimatedUniqueReach: 663026,
			reachRate: 1.4931957514658694,
			engagementRate: 0.00007105522696460597,
			actualEngagementRate: 0.0000616523571545711,
			conversions: 32,
			conversionSaleAmount: 26,
			cpe: 0,
			cpr: 0,
			clicks: 123207,
			currency: '',
		},
	},
];

export const PaidMediaFakeData: PaidMediaStatistic[] = [
	{
		ctr: 2,
		platform: 'Instagram',
		amountSpent: 9395,
		checkoutsInitiated: 9945,
		clicks: 3955,
		costPerPurchase: 8335,
		cpc: 6171,
		cpm: 8983,
		createdAt: '2023-04-06T15:13:58+02:00',
		currency: 'SEK',
		frequency: 1103,
		impressions: 9833,
		name: 'Drake Fritsch',
		conversions: 8240,
		reach: 6704,
		registrationsCompleted: 3508,
		reportingEnds: '2023-04-06T15:13:58+02:00',
		reportingStarts: '2023-03-27T15:13:58+02:00',
		links: {},
	},
];
