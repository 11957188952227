/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { UpdateAssignmentPayload, CreateAssignmentGroupPayload, UpdateAssignmentGroupPayload, CreateAssignmentPayload } from 'api-payloads';
import {
	ListAssignmentsQuery,
	ViewAssignmentQuery,
	UpdateAssignmentQuery,
	CreateAssignmentGroupQuery,
	UpdateAssignmentGroupQuery,
	ApproveCampaignInstagramOwnerAssignmentQuery,
	DisapproveCampaignInstagramOwnerAssignmentQuery,
	ListCampaignInstagramOwnerAssignmentsForInfluencerQuery,
	CreateAssignmentQuery,
	ListCampaignInstagramOwnerAssignmentsQuery,
} from 'api-queries';
import {
	AssignmentCollectionResponse,
	AssignmentResponse,
	AssignmentGroupsResponse,
	AssignmentCampaignInstagramOwnerAssignmentResponse,
	AssignmentCampaignInstagramOwnerAssignmentCollectionResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import AssignmentApiClientInterface from './AssignmentApiClientInterface';

@injectable()
export default class AssignmentApiClient implements AssignmentApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List assignments.
	 *
	 * An assignment is a job/task available for one or more influencers.
	 */
	async listAssignments(params: ListAssignmentsQuery): Promise<AssignmentCollectionResponse> {
		const response = await this.client.get<AssignmentCollectionResponse>(`/assignments`, { params });
		return response.data;
	}

	/**
	 * View assignment
	 */
	async view(campaignId: string, assignmentId: string, params: ViewAssignmentQuery): Promise<AssignmentResponse> {
		const response = await this.client.get<AssignmentResponse>(`/campaigns/${campaignId}/assignments/${assignmentId}`, { params });
		return response.data;
	}

	/**
	 * Delete assignment
	 */
	async delete(campaignId: string, assignmentId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/assignments/${assignmentId}`);
		return response.data;
	}

	/**
	 * Update assignment
	 */
	async update(campaignId: string, assignmentId: string, payload: UpdateAssignmentPayload, params: UpdateAssignmentQuery): Promise<AssignmentResponse> {
		const response = await this.client.patch<AssignmentResponse>(`/campaigns/${campaignId}/assignments/${assignmentId}`, payload, { params });
		return response.data;
	}

	/**
	 * Create assignment group
	 */
	async createGroup(
		campaignId: string,
		assignmentId: string,
		payload: CreateAssignmentGroupPayload,
		params: CreateAssignmentGroupQuery,
	): Promise<AssignmentGroupsResponse> {
		const response = await this.client.post<AssignmentGroupsResponse>(`/campaigns/${campaignId}/assignments/${assignmentId}/groups`, payload, { params });
		return response.data;
	}

	/**
	 * Delete assignment group
	 */
	async deleteGroup(campaignId: string, assignmentId: string, groupId: string): Promise<void> {
		const response = await this.client.delete<void>(`/campaigns/${campaignId}/assignments/${assignmentId}/groups/${groupId}`);
		return response.data;
	}

	/**
	 * Update assignment group
	 */
	async updateGroup(
		campaignId: string,
		assignmentId: string,
		groupId: string,
		payload: UpdateAssignmentGroupPayload,
		params: UpdateAssignmentGroupQuery,
	): Promise<AssignmentGroupsResponse> {
		const response = await this.client.patch<AssignmentGroupsResponse>(`/campaigns/${campaignId}/assignments/${assignmentId}/groups/${groupId}`, payload, {
			params,
		});
		return response.data;
	}

	/**
	 * Approve campaign instagram owner assignment
	 */
	async approveCampaignInstagramOwner(
		campaignId: string,
		assignmentId: string,
		influencerId: string,
		params: ApproveCampaignInstagramOwnerAssignmentQuery,
	): Promise<AssignmentCampaignInstagramOwnerAssignmentResponse> {
		const response = await this.client.post<AssignmentCampaignInstagramOwnerAssignmentResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/influencers/${influencerId}/approve`,
			undefined,
			{ params },
		);
		return response.data;
	}

	/**
	 * Disapprove campaign instagram owner assignment
	 */
	async disapproveCampaignInstagramOwner(
		campaignId: string,
		assignmentId: string,
		influencerId: string,
		params: DisapproveCampaignInstagramOwnerAssignmentQuery,
	): Promise<AssignmentCampaignInstagramOwnerAssignmentResponse> {
		const response = await this.client.post<AssignmentCampaignInstagramOwnerAssignmentResponse>(
			`/campaigns/${campaignId}/assignments/${assignmentId}/influencers/${influencerId}/disapprove`,
			undefined,
			{ params },
		);
		return response.data;
	}

	/**
	 * List campaign instagram owner assignments for influencer
	 */
	async listCampaignInstagramOwnerAssignmentsForInfluencer(
		campaignId: string,
		influencerId: string,
		params: ListCampaignInstagramOwnerAssignmentsForInfluencerQuery,
	): Promise<AssignmentCampaignInstagramOwnerAssignmentCollectionResponse> {
		const response = await this.client.get<AssignmentCampaignInstagramOwnerAssignmentCollectionResponse>(
			`/campaigns/${campaignId}/influencers/${influencerId}/campaign-instagram-owner-assignments`,
			{ params },
		);
		return response.data;
	}

	/**
	 * Create assignment
	 */
	async create(id: string, payload: CreateAssignmentPayload, params: CreateAssignmentQuery): Promise<AssignmentResponse> {
		const response = await this.client.post<AssignmentResponse>(`/campaigns/${id}/assignments`, payload, { params });
		return response.data;
	}

	/**
	 * List campaign instagram owner assignments
	 */
	async listCampaignInstagramOwnerAssignments(
		id: string,
		params: ListCampaignInstagramOwnerAssignmentsQuery,
	): Promise<AssignmentCampaignInstagramOwnerAssignmentCollectionResponse> {
		const response = await this.client.get<AssignmentCampaignInstagramOwnerAssignmentCollectionResponse>(
			`/campaigns/${id}/campaign-instagram-owner-assignments`,
			{ params },
		);
		return response.data;
	}
}
