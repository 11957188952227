import classNames from 'classnames';
import { useContext, useState } from 'react';

import Accordion from 'components/Accordion';
import Styled from 'components/Discovery/Components/FilterDrawer/FilterDrawer.style';
import Input from 'components/Discovery/Components/Input';
import DiscoveryContext from 'contexts/Discovery';

const FollowersFilter = () => {
	const setFilter = useContext(DiscoveryContext).setFilter;
	const filter = useContext(DiscoveryContext).filter;

	// Initialize the state with values from the filter
	const [minFollowers, setMinFollowers] = useState<number | null>(filter?.minFollowers ?? null);
	const [maxFollowers, setMaxFollowers] = useState<number | null>(filter?.maxFollowers ?? null);

	const updateFilter = () => {
		setFilter({
			...filter,
			minFollowers: minFollowers,
			maxFollowers: maxFollowers,
		});
	};

	return (
		<Accordion title='Number of followers' className={classNames({ active: filter?.maxFollowers || filter?.minFollowers })}>
			<Styled.FollowersInputsWrapper>
				<Input
					label='min'
					type='text'
					pattern='[0-9]*'
					value={isNaN(Number(minFollowers)) ? '' : Number(minFollowers)}
					onChange={(e) => {
						setMinFollowers(+e.target.value);
					}}
					onBlur={updateFilter}
				/>
				<Styled.BetweenMinMaxDiv>-</Styled.BetweenMinMaxDiv>
				<Input
					label='max'
					type='text'
					pattern='[0-9]*'
					value={isNaN(Number(maxFollowers)) ? '' : Number(maxFollowers)}
					onChange={(e) => {
						setMaxFollowers(+e.target.value);
					}}
					onBlur={updateFilter}
				/>
			</Styled.FollowersInputsWrapper>
		</Accordion>
	);
};

export default FollowersFilter;
