import CampaignCard from 'components/Dashboard/Components/CampaignCard';

import { CampaignCardType } from './types';

/**
 * Holds the campaign cards for each section
 *
 * @returns {JSX.Element}
 */

const CampaignSection = ({
	campaigns,
	firstCard = false,
	refresh,
	refreshAll,
	refreshArchived,
	startIndex,
}: {
	campaigns: Array<CampaignCardType>;
	firstCard?: boolean;
	refresh: () => void;
	refreshAll: () => void;
	refreshArchived: () => void;
	startIndex: number;
}): JSX.Element => (
	<>
		{campaigns.map((item: CampaignCardType, index: number) => (
			<CampaignCard
				first={firstCard}
				key={item.id}
				index={startIndex + index}
				{...item}
				refresh={refresh}
				refreshAll={refreshAll}
				refreshArchived={refreshArchived}
			/>
		))}
	</>
);

export default CampaignSection;
