import Icon from 'components/Icon';
import Styled from 'components/NewBriefPage/Components/JoinModal/Assignment/Assignment.style';
import { CampaignInstagramOwner } from 'components/NewBriefPage/types';
import { formatAmount } from 'utils/formatters';

type CommissionProps = {
	campaignInstagramOwner: CampaignInstagramOwner;
	isAffiliate: boolean;
};
/**
 * @returns JSX.Element
 */
const Commission = ({ campaignInstagramOwner, isAffiliate }: CommissionProps): JSX.Element => {
	const compensation = campaignInstagramOwner.campaign.compensationsTotalValue;
	const currency = campaignInstagramOwner.campaign.currency;
	const products = campaignInstagramOwner.campaign.products;

	return (
		<Styled.Container>
			{compensation > 0 ||
				isAffiliate ||
				(products?.length > 0 && (
					<>
						<Styled.Title>Commission</Styled.Title>
						<Styled.List>
							{compensation > 0 ||
								(isAffiliate && (
									<li>
										<Styled.Task>
											<Icon name='money' />
											<span>
												{isAffiliate
													? 'Based on your performance'
													: compensation && formatAmount(compensation, currency)?.replace(',', '\xa0').replace('.00', '')}
											</span>
										</Styled.Task>
									</li>
								))}
							{products?.length > 0 &&
								products.map((product, i) => {
									return (
										<li key={i}>
											<Styled.Task>
												<Icon name='product' />
												<span>
													{product.quantity} {product.product?.name}
												</span>
											</Styled.Task>
										</li>
									);
								})}
						</Styled.List>
					</>
				))}
		</Styled.Container>
	);
};

export default Commission;
