import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgTrafficMenu(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps} fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.5 15.5V1.5H11.5V15.5H8.5ZM7 1C7 0.447715 7.44772 0 8 0H12C12.5523 0 13 0.447715 13 1V16C13 16.5523 12.5523 17 12 17H8C7.44772 17 7 16.5523 7 16V1ZM0.697674 18.5C0.312359 18.5 0 18.8358 0 19.25C0 19.6642 0.312359 20 0.697674 20H19.3023C19.6876 20 20 19.6642 20 19.25C20 18.8358 19.6876 18.5 19.3023 18.5H0.697674ZM1.5 5.5V15.5H4.5V5.5H1.5ZM1 4C0.447715 4 0 4.44772 0 5V16C0 16.5523 0.447715 17 1 17H5C5.55228 17 6 16.5523 6 16V5C6 4.44772 5.55228 4 5 4H1ZM15.5 15.5V5.5H18.5V15.5H15.5ZM14 5C14 4.44772 14.4477 4 15 4H19C19.5523 4 20 4.44772 20 5V16C20 16.5523 19.5523 17 19 17H15C14.4477 17 14 16.5523 14 16V5Z'
				fill='#4D4B4C'
			/>
		</svg>
	);
}
