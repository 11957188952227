import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgOtherFile(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M18 5.36268L12.66 0.04002H12.35L2.95 0C2.43 0 2 0.42021 2 0.930465V19.0395C2 19.5498 2.43 19.97 2.95 19.97L8.08 20H17C17.55 20 18 19.5698 18 19.0495V5.77289C18 5.77289 18 5.74287 17.99 5.72286C17.99 5.70285 18 5.69285 18 5.67284V5.36268ZM13 2.49125L15.54 5.02251H13V2.49125ZM16.5 10.4852V18.5193H8.36L3.5 18.4892V1.49075L11.5 1.53077V5.66283C11.5 6.13307 11.88 6.51326 12.35 6.51326H16.5V10.4752V10.4852Z'
				fill='#333333'
			/>
		</svg>
	);
}
