import { paths } from 'api-schema';
import { Relationship } from 'services/ApiClient/types';

type Response = paths['/campaigns/{id}/campaign-instagram-owner-assignments']['get']['responses']['200']['content']['application/vnd.api+json'];
type AssignmentResponse = paths['/campaigns/{campaignId}/assignments/{assignmentId}']['get']['responses']['200']['content']['application/vnd.api+json'];
type ReviewResponse =
	paths['/campaigns/{campaignId}/assignments/{assignmentId}/reviews/{assignmentReviewId}']['get']['responses']['200']['content']['application/vnd.api+json'];

export type CampaignInstagramOwnerAssignment = Response['data'][number];
export type Assignment = AssignmentResponse['data'];
export type Review = ReviewResponse['data'];

/**
 * CampaignInstagramOwnerProductType
 * Type for CampaignInstagramOwnerProduct
 */
export type CampaignInstagramOwnerProductType = {
	type: string;
	id: string;
	attributes: {
		createdAt: string;
	};
	relationships: {
		product: {
			data: {
				type: string;
				id: string;
			};
		};
		influencers: Record<string, Relationship | undefined>;
	};
};

/**
 * CampaignInstagramOnwerAssignment::newFancyStatus
 */
export enum AssignmentStatus {
	WAITING_FOR_UPLOAD = 'waiting_for_upload',
	IN_REVIEW = 'in_review',
	REVIEW_APPROVED = 'review_approved',
	STATISTICS_UPLOADED = 'statistics_uploaded',
	APPROVED = 'approved',
	DECLINED = 'declined',
	DISPUTED = 'disputed',
	COMPENSATED = 'compensated',
}

/**
 * AssignmentReview::newStatus
 */
export enum AssignmentReviewStatus {
	IN_REVIEW = 'in_review',
	APPROVED = 'approved',
	REJECTED = 'rejected',
	CHANGE_REQUESTED = 'change_requested',
}

/**
 * CampaignInstagramOwnerAssingnmentType
 * Type for CampaignInstagramOwnerAssingnment
 */
export type CampaignInstagramOwnerAssingnmentType = {
	id: string;
	campaignCode: string;
	createdAt: string;
	/**
	 * @deprecated
	 */
	status: string;
	newFancyStatus: AssignmentStatus;
	links: { [key: string]: string };
};

/**
 * AssignmentReviewType
 * Type for AssignmentReview
 */
export type AssignmentReviewType = {
	id: string;
	approvedByClientAt: string;
	createdAt: string;
	enabledForClientAt: string;
	influencerOnlyEditedText: string;
	newStatus: AssignmentReviewStatus;
	reviewedAt: string;
	/**
	 * Status
	 * @deprecated use status on CampaignInstagramOwnerAssingnment
	 * 2023-07-18: use newStatus property
	 */
	status: number;
	text: string;
	trackingUrl: string;
	updatedAt: string;
	url: string;
	waivedDeadline: string;
	links: { [key: string]: string };
};

export enum UserUploadStatus {
	INIT = 'initialized',
	PROCESSING = 'processing',
	UPLOAD_FAILED = 'upload_failed',
	PROCESS_FAILED = 'process_failed',
	ERROR = 'error',
	SUCCESS = 'success',
}

export type AssignmentReviewMediaType = {
	id: string;
	createdAt: string;
	influencerOnlyEditedText: string;
	originalFilename: string;
	sort: number;
	text: string;
	trackingUrl: string;
	url: string;
	type: string;
	uuid: string;
	vimeoId: string;
	vimeoPlaybackUrl: string;
	uploadStatus: UserUploadStatus;
	links: { [key: string]: string };
};

export type AssignmentReviewMediaPresignUrlType = {
	id: string;
	createdAt: string;
	filename: string;
	processedAt: string;
	productUrl: string;
	size: number;
	sort: number;
	text: string;
	type: string;
	uploadCompletedAt: string;
	url: string;
	trackingUrl: string;
	links: { [key: string]: string };
};

export type AssignmentType = {
	id: string;
	createdAt: string;
	cta: string;
	description: string;
	donts: string;
	dos: string;
	endAt: string;
	name: string;
	type: string; // @todo TYPE
	links: { [key: string]: string };
	hashtags: string[];
	mentions: string[];
};

export type AssignmentReviewComment = {
	id: string;
	createdAt: string;
	readAt: string;
	text: string;
	links: { [key: string]: string };
};

export type AssignmentReviewInternalComment = {
	id: string;
	createdAt: string;
	text: string;
	links: { [key: string]: string };
};

export const isContentApproved = (status: string) => {
	return status === AssignmentStatus.REVIEW_APPROVED;
};

export const isAssignmentApproved = (status: string) => {
	return status === AssignmentStatus.APPROVED;
};

export const isStatsUploaded = (status: string) => {
	return status === AssignmentStatus.STATISTICS_UPLOADED;
};

export const isChangeRequested = (status: string) => {
	return status === AssignmentReviewStatus.CHANGE_REQUESTED;
};

export const isAssignmentDisapproved = (status: string) => {
	return status === AssignmentStatus.DECLINED || status === AssignmentStatus.DISPUTED;
};

export const isAssignmentPending = (status: string) => {
	return status === AssignmentStatus.IN_REVIEW;
};
