import { Line } from 'components/Chart';
import { ILineData } from 'components/Chart/Line/types';
import ChartCard from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard';
import Styled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { ChartCardProps } from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/types';
import ExplanationAccordition from 'components/DataLibrary/Graphs/GraphComponents/ExplanationAccordion';
import { OverTimeOutput } from 'components/DataLibrary/Graphs/GraphComponents/OverTime/types';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { transformToLineChartData } from 'shared/helpers/Chart/chart-util';
import { useAppSelector } from 'views//DataLibrary/hooks';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

import { FakeData } from './FakeData';

const OverTime = ({ chartId, fetchData, title, chartType, chartOnDashboard, color, isHoverable, chartExplanation }: ChartCardProps<OverTimeOutput>) => {
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const manager = useInjection<StatisticsManager>(StatisticsManager);

	let somethingWentWrong = null;
	let lineData: { labels: string[]; datasets: ILineData[] } = { labels: [], datasets: [] };
	let isLoading = true;

	if (!fetchData) {
		isLoading = false;
		lineData = transformToLineChartData(FakeData.data, color ?? 'blue');
	} else {
		const { result, error } = fetchData(manager, dashboardFilter);
		if (error) {
			somethingWentWrong = error.message;
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			somethingWentWrong = null;
			lineData = transformToLineChartData(result, color ?? 'blue');
		}
	}

	return (
		<ChartCard
			somethingWentWrong={somethingWentWrong}
			chartId={chartId}
			title={title}
			chartType={chartType}
			chartOnDashboard={chartOnDashboard}
			isLoading={isLoading}
		>
			<Styled.InnerWrapper>
				<Line labels={lineData.labels} datasets={lineData.datasets} pointRadius={1.5} lineTension={0} borderWidth={1} isHoverable={isHoverable} />
			</Styled.InnerWrapper>
			{chartExplanation && <ExplanationAccordition chartTitle={title} chartExplanation={chartExplanation} />}
		</ChartCard>
	);
};

export default OverTime;
