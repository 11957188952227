import styled from 'styled-components';

import { devices } from 'styles/variables/media-queries';

const WrapperContainer = styled.div`
	display: flex;
	gap: 16px;
	flex-direction: column;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	@media only screen and (${devices.md}) {
		flex-direction: row;
		flex-wrap: wrap;
		flex: 1;
	}
`;

const FirstImage = styled.div`
	@media only screen and (${devices.md}) {
		min-width: 100%;
	}
	@media only screen and (${devices.lg}) {
		min-width: 600px;
	}
`;

const SecondSection = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 16px;
	@media only screen and (${devices.md}) {
		flex-wrap: wrap;
		flex-direction: row;
	}
`;

const Styled = {
	Wrapper,
	WrapperContainer,
	FirstImage,
	SecondSection,
};

export default Styled;
