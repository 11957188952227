import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgImage(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.899902 2H19.1001C19.6001 2 20 2.38989 20 2.86011V17.1499C20 17.6201 19.6001 18 19.1001 18H0.899902C0.399902 18 0 17.6201 0 17.1499V2.86011C0 2.37988 0.399902 2 0.899902 2ZM18.5701 13.1499V3.3501H18.5601H1.41992V10.45L4.67993 7.18994L6.38989 9.84009L8.55005 9.46997L10.27 11.1899H12.5701L15.1899 13.1499H18.5701ZM1.42993 11.8501V16.6499V16.6599H18.5901V14.1399H14.8799L12.26 12.1799H9.87988L8.22998 10.53L5.93994 10.9199L4.53003 8.75L1.42993 11.8501ZM17.0901 6.96997C17.0901 8.04004 16.22 8.90991 15.1499 8.90991C14.0801 8.90991 13.21 8.04004 13.21 6.96997C13.21 5.8999 14.0801 5.03003 15.1499 5.03003C16.22 5.03003 17.0901 5.8999 17.0901 6.96997ZM16.0901 6.95996C16.0901 6.43994 15.6699 6.02002 15.1499 6.02002C14.9692 6.02002 14.8008 6.07056 14.6577 6.15845C14.3889 6.32373 14.21 6.62061 14.21 6.95996C14.21 7.47998 14.6299 7.8999 15.1499 7.8999C15.6699 7.8999 16.0901 7.47998 16.0901 6.95996Z'
				fill='#333333'
			/>
		</svg>
	);
}
