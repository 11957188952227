import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from 'styles/variables/colors';

const StyledLink = styled(Link)`
	display: flex;
	width: fit-content;
	gap: 8px;
	padding: 0.438rem 1rem;
	border: solid 2px ${colors.mainBlack};
	background-color: ${colors.mainWhite};
	cursor: pointer;
	font-size: 1.25rem;
	color: ${colors.mainBlack};
	border-radius: 4px;
	transition:
		background-color 150ms ease-in-out,
		color 150ms ease-in-out,
		border-color 150ms ease-in-out;
	&:hover {
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
		.icon path {
			fill: ${colors.mainWhite};
		}
	}
	span {
		display: flex;
		gap: 8px;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
`;

const Styled = {
	StyledLink,
};

export default Styled;
