import styled from 'styled-components';

import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.m};
	padding-left: ${guttersWithRem.xxl};
	min-height: 80px;
	width: 100%;
	.flex-1 {
		flex: 1;
	}
	.w-40 {
		width: 40%;
	}
	.w-60 {
		width: 60%;
	}
`;

const EmptyStateWrapper = styled.div`
	width: 100%;
	padding: ${guttersWithRem.m};
	height: calc(100vh + -72px);
	display: flex;
	justify-content: center;
	padding-top: 3.5rem;
	div {
		width: 50%;
	}
	.flex-1 {
		flex: 1;
	}
	.image-wrapper {
		display: flex;
		justify-content: center;
		margin: auto;
		margin-bottom: 16px;
		width: 100%;
	}
	.mb-8 {
		width: 70%;
		margin: auto;
		margin-bottom: 8px;
	}
`;

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
`;

const Styled = {
	Wrapper,
	EmptyStateWrapper,
	FormWrapper,
};

export default Styled;
