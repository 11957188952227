import styled from 'styled-components';

import { ThreeColContainer } from 'styles/layout/three-col';

const Wrapper = styled(ThreeColContainer)`
	position: relative;

	margin: 0 16px;
	.integrated-inbox {
		.three-col {
			&:nth-child(3) {
				box-shadow: none;
			}
		}
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
