import { TotalAssignmentPerAssignmentTypeQuery, TotalAssignmentPerCampaignQuery, TotalAssignmentPerCountryQuery } from 'api-queries';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import { ByAssignmentTypeOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByAssignmentType/types';
import { ByCampaignOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByCampaign/types';
import { ByCountryOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByCountry/types';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

/**
 * "Media objects" is just fancy language for "posts" or "uploads". ie Assignments.
 */
export const getAssignmentsByCountry = (manager: StatisticsManager, filter: DashboardFilters): { result: ByCountryOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<TotalAssignmentPerCountryQuery>(filter);
	const { result, error } = manager.listAssignmentsCountries(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const countries = [];
	for (const country of result) {
		if (country.attributes.count > 0) {
			countries.push({
				label: country.attributes.name ?? '[no name]',
				value: country.attributes.count,
			});
		}
	}

	const total = result.reduce((prev: number, current) => prev + current.attributes.count, 0);
	const output = {
		currency: undefined,
		total: total,
		countries: countries,
	};

	return { result: output };
};

export const getAssignmentsByCampaign = (manager: StatisticsManager, filter: DashboardFilters): { result: ByCampaignOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<TotalAssignmentPerCampaignQuery>(filter);
	const { result, error } = manager.listAssignmentsCampaigns(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const campaigns = [];
	for (const country of result) {
		if (country.attributes.count > 0) {
			campaigns.push({
				label: country.attributes.name ?? '[no name]',
				value: country.attributes.count,
			});
		}
	}

	const total = result.reduce((prev: number, current) => prev + current.attributes.count, 0);
	const output = {
		currency: undefined,
		total: total,
		campaigns: campaigns,
	};

	return { result: output };
};

export const getAssignmentsByNetwork = (
	manager: StatisticsManager,
	filter: DashboardFilters,
): { result: ByAssignmentTypeOutput | undefined; error?: Error } => {
	const qb = convertFilterToQueryBuilder<TotalAssignmentPerAssignmentTypeQuery>(filter);
	const { result, error } = manager.listAssignmentsByType(qb);

	if (!result || error) {
		return { result: undefined, error };
	}

	const types = new Map<string, { label: string; value: number }>();
	for (const country of result) {
		if (country.attributes.count > 0) {
			// TODO verify the type is correct.. Probably it isn't.
			const label = country.attributes.name ?? '[no name]';
			types.set(label, {
				label: label,
				value: country.attributes.count,
			});
		}
	}

	const total = result.reduce((prev: number, current) => prev + current.attributes.count, 0);
	const output = {
		total: total,
		types: types,
	};

	return { result: output };
};
