import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import PageNotFound from 'components/ErrorPages/PageNotFound';
import LoadingSpinner from 'components/LoadingSpinner';
import OrganizationLayout from 'layouts/organization';
import AdminContainer from 'views/admin/AdminContainer';

import { LoadingContainer } from './CampaignRoutes';
import { ProtectedRoute } from './layout-routes/ProtectedRoute';

/**
 * Super Admin Routes
 * Routes for super admins
 * @returns {Array<RouteObject>}
 */

const routes: Array<RouteObject> = [
	{
		path: '',
		element: (
			<React.Suspense
				fallback={
					<LoadingContainer>
						<LoadingSpinner />
					</LoadingContainer>
				}
			>
				<ProtectedRoute>
					<OrganizationLayout />
				</ProtectedRoute>
			</React.Suspense>
		),
		children: [
			{ index: true, element: <Navigate to='dashboard' /> },
			{ path: 'insights', element: <AdminContainer /> },
			{ path: 'dashboard', element: <AdminContainer /> },
			{ path: 'users', element: <AdminContainer /> },
			{ path: 'users/:userId', element: <AdminContainer /> },
			{ path: 'influencers', element: <AdminContainer /> },
			{ path: 'influencers/:influencerUserId', element: <AdminContainer /> },
			{ path: 'clients', element: <AdminContainer /> },
			{ path: 'publishers', element: <AdminContainer /> },
			{ path: 'publishers/:publisherId', element: <AdminContainer /> },
			{ path: 'payments', element: <AdminContainer /> },
			{ path: 'invoices', element: <AdminContainer /> },
			{ path: 'payout', element: <AdminContainer /> },
		],
	},
	{ path: '*', element: <PageNotFound /> },
];

export default routes;
