/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { ListActiveCountriesQuery } from 'api-queries';
import { CountryCollectionResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import CountryApiClientInterface from './CountryApiClientInterface';

@injectable()
export default class CountryApiClient implements CountryApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List active countries
	 */
	async listActiveCountries(params: ListActiveCountriesQuery): Promise<CountryCollectionResponse> {
		const response = await this.client.get<CountryCollectionResponse>(`/countries/active`, { params });
		return response.data;
	}
}
