import { FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { GhostButton } from 'components/Button';
import Icon from 'components/Icon';
import InfoText from 'components/Settings/Components/InfoText/InfoText';
import InvitationList from 'components/Settings/Components/InvitationList/InvitationList';
import InviteTeamMembers from 'components/Settings/Components/InviteTeamMembers/InviteTeamMembers';
import SubTitle from 'components/Settings/Components/SubTitle/SubTitle';
import UsersWithRolesList from 'components/Settings/Components/UsersWithRolesList/UsersWithRolesList';
import { OrganizationHierarchyRole } from 'components/Settings/types';
import { SideDrawer } from 'components/SideDrawer';

import Styled from './InviteUsers.style';
import { InviteUsersProps } from './types';

const InviteUsers = ({
	drawerTitle,
	testId,
	inviteTitle,
	inviteFn,
	buttonText,
	companyName,
	roles,
	changeUserRole,
	invites,
	getUser,
	top,
	deleteInvite,
	availableRoles,
}: InviteUsersProps) => {
	const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);
	const location = useLocation();

	const formRef = useRef<
		| FormikProps<{
				email: string;
				role: OrganizationHierarchyRole;
		  }>
		| undefined
	>(undefined);

	const handleSubmit = () => {
		if (formRef.current) {
			formRef.current.handleSubmit();
			setSidebarIsOpen(false);
		}
	};

	useEffect(() => {
		if (location.state?.invite && !top) {
			setSidebarIsOpen(true);
		}
	}, [location]);

	return (
		<>
			{top ? (
				<GhostButton
					size='sm'
					onClick={() => {
						setSidebarIsOpen(true);
					}}
				>
					Invite
				</GhostButton>
			) : (
				<Styled.AddSection
					onClick={() => {
						setSidebarIsOpen(true);
					}}
				>
					<Styled.AddIcon role='button'>
						<Icon name='plus' size='12' />
					</Styled.AddIcon>
					<p>{buttonText}</p>
				</Styled.AddSection>
			)}
			<SideDrawer
				saveButtonText='Done'
				isExpandable
				title={drawerTitle}
				sidebarIsOpen={sidebarIsOpen}
				onClose={() => {
					setSidebarIsOpen(false);
					formRef.current?.handleReset();
				}}
				dataTestId={testId}
				onSave={handleSubmit}
			>
				<>
					<Styled.Title>Invite</Styled.Title>
					<InfoText text={`Add or manage who has access to ${companyName}.`} />
					<InviteTeamMembers
						availableRoles={availableRoles}
						formRef={formRef}
						text={inviteTitle}
						inviteFn={inviteFn}
						keepDrawerOpen={() => setSidebarIsOpen(true)}
					/>
					<Styled.ScrollDiv data-testid='invited-section'>
						{roles?.length > 0 && (
							<>
								<SubTitle text='People with access' />
								<UsersWithRolesList availableRoles={availableRoles} roles={roles} changeUserRole={changeUserRole} getUser={getUser} canEdit />
							</>
						)}
						{invites.length > 0 && <InvitationList invites={invites} deleteInvite={deleteInvite} canEdit />}
					</Styled.ScrollDiv>
				</>
			</SideDrawer>
		</>
	);
};

export default InviteUsers;
