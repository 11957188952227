import { CSSProperties } from 'react';
import styled from 'styled-components';

import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import { CTALink } from 'styles/utils/CTALink';
import _colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

const IconWrapper = styled.div`
	display: flex;
	width: 40px;
	height: 40px;
	margin-right: 8px;
`;

const CustomIcon = styled(Icon)`
	display: flex;
	align-items: center;

	&.detail-opener {
		margin-left: 8px;
	}

	&.show-detail {
		& > svg {
			transform: rotate(0deg);
		}
	}

	& > svg {
		border-radius: 4px;
		transform: rotate(-90deg);
	}
`;

const Wrapper = styled.tr`
	cursor: pointer;
	width: 100%;
	border: 1px solid #888;
	border-left: none;
	border-right: none;
	border-bottom: none;
	background-color: ${({ theme }) => theme.table.backgroundOdd};

	&:nth-child(even) {
		background-color: ${({ theme }) => theme.table.backgroundEven};
	}

	&.noData {
		opacity: 0.5;
	}

	svg {
		transition:
			background-color 0.2s ease-in-out,
			fill 0.2s ease-in-out;
	}

	&:hover {
		${CustomIcon} {
			& > svg {
				background-color: ${({ theme }) => theme.colors.primary};

				path {
					fill: ${({ theme }) => theme.colors.secondary};
				}
			}
		}
	}
`;

const AssignmentDetailsWrapper = styled.tr`
	cursor: pointer;
	width: 100%;
	border: 1px solid ${_colors.dataLibrary.lightPurple};
	border-left: none;
	border-right: none;
	border-bottom: none;

	&.dark {
		background-color: ${_colors.dataLibrary.contentHeaderBackgroundColor};
	}
	&.light {
		background-color: ${_colors.dataLibrary.rawWhite};
	}
	&:hover {
		${CustomIcon} {
			& > svg {
				background-color: ${_colors.dataLibrary.lightPurple};
			}
		}
	}
`;

const Td = styled.td<CSSProperties>`
	padding: 0.5rem;
	text-align: ${(props) => props.textAlign || 'left'};
	max-width: ${(props) => props.maxWidth};
	width: ${(props) => props.width};
	height: ${(props) => props.maxHeight || '50px'};
	vertical-align: middle;

	&.assignment-name {
		padding-left: 2rem;
	}
`;

const ExpendWrapper = styled(Wrapper)`
	border-top: none;

	& > ${Td} {
		padding-top: 0px;
	}
`;

const Div = styled.div<CSSProperties>`
	display: ${(props) => props.display};
	justify-content: ${(props) => props.justifyContent};
	align-items: ${(props) => props.alignItems};
	column-gap: ${(props) => props.columnGap};
	row-gap: ${(props) => props.rowGap};
	font-family: ${typography.list.fontFamily};
	overflow-x: ${(props) => props.overflowX};
	overflow-y: ${(props) => props.overflowY};
`;

const InfluencerName = styled(Div)`
	font-weight: ${typography.list.fontWeight};
	font-size: ${typography.list.fontSize};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 320px;
`;

const Data = styled(Div)`
	font-weight: ${typography.list.fontWeight};
	font-size: ${typography.list.medium.fontSize};
	white-space: nowrap;
`;

const IconContainer = styled.div`
	position: relative;
	max-height: 40px;
	margin-top: 5px;
	margin-right: 5px;
`;

const CustomAvatar = styled(Avatar)`
	margin: 0;
	margin-right: 15px;
	margin-bottom: 3px;
`;

const InfluencerSubText = styled(Div)`
	font-family: ${typography.list.accessory.fontFamilies};
	font-size: ${typography.list.small.fontSize};
	white-space: nowrap;
`;

const ToggleDiv = styled.div``;

const AssignmentNameWrapper = styled.div`
	display: flex;
	column-gap: 16px;
	align-items: center;

	& > a {
		&.no-underline {
			border-bottom: none;

			&:hover {
				border-bottom: none;
			}
		}
	}
`;

const LinkToContentManagement = styled.a`
	${CTALink};
	color: ${_colors.black};

	&:hover {
		color: ${_colors.black};
	}

	&::after {
		background-color: ${_colors.black};
		height: 1px;
	}
`;

const Styled = {
	Wrapper,
	ExpendWrapper,
	Td,
	Div,
	InfluencerName,
	InfluencerSubText,
	Data,
	IconContainer,
	CustomAvatar,
	IconWrapper,
	CustomIcon,
	AssignmentDetailsWrapper,
	ToggleDiv,
	LinkToContentManagement,
	AssignmentNameWrapper,
};

export default Styled;
