import { Model } from 'json-api-models';

import Affiliate from 'components/ContentManagement/Components/Views/Affiliate';
import Post from 'components/ContentManagement/Components/Views/Post';
import Story from 'components/ContentManagement/Components/Views/Story';
import { CIOAType, CampaignItemType, ReviewType } from 'components/ContentManagement/types';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { getLatestReview } from 'shared/utils/getLatestReview';
import { ASSIGNMENT_TYPE } from 'types/AssignmentTypes';

type AssignmentFormProps = {
	assignmentType: ASSIGNMENT_TYPE;
	CIOAssignment: Model;
	selectedCampaign: CampaignItemType;
	CIOArefresh: () => Promise<void>;
	goToStats: () => void;
	campaignRefresh: () => void;
};

const AssignmentForm = ({ assignmentType, CIOAssignment, selectedCampaign, CIOArefresh, goToStats, campaignRefresh }: AssignmentFormProps) => {
	const user = useAppSelector((state) => state.user);
	const LATEST_REVIEW = getLatestReview(CIOAssignment.reviews);

	const getForm = () => {
		switch (assignmentType) {
			case ASSIGNMENT_TYPE.INSTAGRAM_STORY:
				return (
					<Story
						id={CIOAssignment.id}
						selectedCampaign={{ hashtags: selectedCampaign.hashtags, mentions: selectedCampaign.mentions }}
						selectedCIOA={CIOAssignment as unknown as CIOAType}
						CIOArefresh={CIOArefresh}
						campaignRefresh={campaignRefresh}
						review={LATEST_REVIEW as unknown as ReviewType}
						goToStats={goToStats}
					/>
				);

			case ASSIGNMENT_TYPE.AFFILIATE_TASK:
				return <Affiliate id={CIOAssignment.id} activeCampaign={selectedCampaign} user={user} />;

			default:
				return (
					<Post
						id={CIOAssignment.id}
						selectedCampaign={{ hashtags: selectedCampaign.hashtags, mentions: selectedCampaign.mentions }}
						selectedCIOA={CIOAssignment as unknown as CIOAType}
						CIOArefresh={CIOArefresh}
						review={LATEST_REVIEW as unknown as ReviewType}
						goToStats={goToStats}
						campaignRefresh={campaignRefresh}
						assignmentType={assignmentType}
					/>
				);
		}
	};
	return getForm();
};

export default AssignmentForm;
