import { FormikErrors } from 'formik';
import { isEmpty } from 'lodash';

import Help from 'components/ContentManagement/Components/Help/Help';
import { CampaignInstagramOwnerStatusText } from 'components/ContentManagement/Utils';
import { CIOAType, PostMedia, ReviewType } from 'components/ContentManagement/types';
import Icon from 'components/Icon';
import usePermissions from 'hooks/usePermissions';
import toast from 'services/Toast';
import { AssignmentStatus, AssignmentReviewStatus } from 'shared/Types/Assignment';

import ReviewSection from './ReviewSection';
import SubmitSection from './SubmitSection';
import Styled from './Topbar.style';

export type TopbarProps = {
	heading: string;
	status: {
		reviewStatus: string | null;
		newFancyStatus: AssignmentStatus;
	};
	permissions: {
		canApprove: boolean;
		canRequestChanges: boolean;
		canEdit: boolean;
		canComment: boolean;
		canCreateReview: boolean;
		canClientApprove: boolean;
	};
	isEditing: boolean;
	loading: {
		isClientApproveLoading: boolean;
		isSaving: boolean;
		isUploadingFiles: boolean;
		isPosting?: boolean;
	};
	handlers: {
		onClickComments: () => void;
		onClickApprove: () => void;
		onClickEdit: () => void;
		onClickCancel: () => void;
		onClickSaveChanges: () => void;
		onClickRequestChanges: () => void;
		onClickClientApprove: () => void;
		onClickUploadStats: () => void;
		onClickOpenSendForReviewModal: () => void;
	};
	errors?: FormikErrors<unknown>;
	files?: number;
	CIOAssignment: CIOAType;
	latestReview: ReviewType;
	isValid?: boolean;
	medias: PostMedia[];
};

const ManagerTopbar = ({
	medias,
	heading,
	status,
	permissions,
	loading,
	handlers,
	errors,
	files,
	CIOAssignment,
	latestReview,
	isValid,
	isEditing,
}: TopbarProps): JSX.Element => {
	const { isInfluencer } = usePermissions();

	const isInReviewButNoMediasAndNoPendingUploads =
		!isEditing &&
		!loading.isPosting &&
		status.newFancyStatus === AssignmentStatus.IN_REVIEW &&
		isEmpty(latestReview?.medias) &&
		isEmpty(latestReview?.pendingUploads);

	const displaySuccessMessage =
		status.newFancyStatus === AssignmentStatus.IN_REVIEW && isInfluencer() && !loading.isUploadingFiles && !isEmpty(latestReview.medias);

	const assignmentIsReviewable = !isInfluencer() && !isEditing && status.newFancyStatus === AssignmentStatus.IN_REVIEW;

	const isDisplayHelpText = status.newFancyStatus === AssignmentStatus.REVIEW_APPROVED || status.newFancyStatus === AssignmentStatus.WAITING_FOR_UPLOAD;

	const assignmentDeclined = status.newFancyStatus === AssignmentStatus.DECLINED;

	const renderStatusText = () => (
		<Styled.StatusTag data-testid='cm-status-tag'>
			<div>
				{CampaignInstagramOwnerStatusText(
					status.reviewStatus && (status.reviewStatus === AssignmentReviewStatus.CHANGE_REQUESTED || status.reviewStatus === AssignmentReviewStatus.REJECTED)
						? status.reviewStatus
						: status.newFancyStatus,
				)}
			</div>
		</Styled.StatusTag>
	);

	const renderActionButtons = () => (
		<Styled.ActionsGroup>
			{permissions.canEdit && (
				<Styled.CustomActionButton size='sm' onClick={isEditing ? handlers.onClickCancel : handlers.onClickEdit} data-testid='cm-edit'>
					<span>{isEditing ? 'Cancel' : 'Edit'}</span>
				</Styled.CustomActionButton>
			)}
			{permissions.canCreateReview && !isEditing && !assignmentIsReviewable && (
				<SubmitSection
					submitAction={medias?.length > 0 ? handlers.onClickOpenSendForReviewModal : () => toast.error('Your need to upload at least one image or video')}
					isLoading={loading.isSaving}
					title={heading}
					files={files}
					errors={errors}
					buttonText='Submit'
					testId='cm-save-content'
					className='fit-content'
					isValid={isValid}
				/>
			)}
			{permissions.canEdit && isEditing && (
				<SubmitSection
					submitAction={handlers.onClickOpenSendForReviewModal}
					isLoading={loading.isSaving}
					title={heading}
					files={files}
					errors={errors}
					buttonText='Send update'
					className='fit-content'
					testId='cm-save-changes'
					isValid={isValid}
				/>
			)}
			{assignmentIsReviewable && (
				<ReviewSection
					errors={errors}
					title={heading}
					onClickRequestChanges={handlers.onClickRequestChanges}
					onClickApprove={handlers.onClickApprove}
					onClickClientApprove={handlers.onClickClientApprove}
					isEditing={isEditing}
					canRequestChanges={permissions.canRequestChanges}
					canApprove={permissions.canApprove}
					canClientApprove={permissions.canClientApprove}
					isClientApproveLoading={loading.isClientApproveLoading}
					hideErrors={permissions.canCreateReview}
					status={status.reviewStatus}
				/>
			)}
		</Styled.ActionsGroup>
	);

	return (
		<Styled.Wrapper>
			<Styled.HeadingWrapper>
				<Styled.Heading id={'heading'} data-testid='cm-assignment-heading'>
					<div className='name-status'>
						<h5>{heading}</h5>
						<Styled.StatusContainer>{renderStatusText()}</Styled.StatusContainer>
					</div>
					{permissions.canComment && (
						<Styled.CommentsButton onClick={handlers.onClickComments} data-testid='cm-comments' type='button'>
							<Icon name='message-empty' size='20' />
						</Styled.CommentsButton>
					)}
				</Styled.Heading>
			</Styled.HeadingWrapper>

			<Styled.ActionBar>{!assignmentDeclined && renderActionButtons()}</Styled.ActionBar>

			{isDisplayHelpText && <Help onContent={true} newFancyStatus={CIOAssignment.newFancyStatus} />}

			{isInReviewButNoMediasAndNoPendingUploads && (
				<Styled.CompletedWrapper className='error'>
					<Icon name='circle-cross' size='32' />
					<Styled.Title>Sorry, something went wrong!</Styled.Title>
					<p>We got an unexpected error while your video/picture uploading process.</p>
					<p>It happens when the file size is too large or upload multiple videos.</p>
					<p>Please, click the Edit button and retry upload.</p>
				</Styled.CompletedWrapper>
			)}

			{displaySuccessMessage && (
				<>
					<Styled.CompletedWrapper>
						<Icon name='check-circle' size='32' />
						<Styled.Title>Nice, assignment submitted!</Styled.Title>
						<p>No worries, you’ll be notified when it has been reviewed.</p>
					</Styled.CompletedWrapper>
					<hr />
				</>
			)}
		</Styled.Wrapper>
	);
};

export default ManagerTopbar;
