import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgCampaignBrief(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.66 0.04002L18 5.36268V5.67284C18 5.68284 17.9975 5.69034 17.995 5.69784L17.995 5.69785C17.9925 5.70535 17.99 5.71286 17.99 5.72286C18 5.74287 18 5.77289 18 5.77289V19.0495C18 19.5698 17.55 20 17 20H8.08L2.95 19.97C2.43 19.97 2 19.5498 2 19.0395V0.930465C2 0.42021 2.43 0 2.95 0L12.35 0.04002H12.66ZM15.54 5.02251L13 2.49125V5.02251H15.54ZM16.5 18.5193V10.4852V10.4752V6.51326H12.35C11.88 6.51326 11.5 6.13307 11.5 5.66283V1.53077L3.5 1.49075V18.4892L8.36 18.5193H16.5ZM14.6036 10.1111H5.49975C5.21989 10.1111 5 9.86667 5 9.55556C5 9.24444 5.21989 9 5.49975 9H14.6036C14.8834 9 15.1033 9.24444 15.1033 9.55556C15.1033 9.86667 14.8834 10.1111 14.6036 10.1111ZM9.49026 12.6445H5.49975C5.21989 12.6445 5 12.889 5 13.2001C5 13.5112 5.21989 13.7556 5.49975 13.7556H9.50025C9.78011 13.7556 10 13.5112 10 13.2001C10 12.889 9.78011 12.6445 9.50025 12.6445H9.49026ZM11.4998 12.6445H13.5487C13.8286 12.6445 14.0485 12.889 14.0485 13.2001C14.0485 13.5112 13.8286 13.7556 13.5487 13.7556H11.4998C11.2199 13.7556 11 13.5112 11 13.2001C11 12.889 11.2199 12.6445 11.4998 12.6445Z'
				fill='#333333'
			/>
		</svg>
	);
}
