import styled from 'styled-components';

const ModalInner = styled.div`
	text-align: center;
`;

const ButtonGroup = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	button {
		width: 150px;
		margin-left: auto;
		margin-right: auto;

		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
`;

const Content = styled.div`
	margin-bottom: 32px;
`;

const Styled = {
	ModalInner,
	ButtonGroup,
	Content,
};

export default Styled;
