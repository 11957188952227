import styled from 'styled-components';

import Icon from 'components/Icon';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const inputPadding = `${guttersWithRem.xxs} ${guttersWithRem.m}`;

const Wrapper = styled.div``;

const Inner = styled.div`
	p {
		margin-bottom: 0;
	}

	button {
		margin-left: auto;
	}

	.help-text {
		font-size: 0.875rem;
		color: ${colors.affiliateLinkGenerator.helpText.color};
		margin-bottom: 8px;
	}

	.url {
		display: inline-block;
		width: 80%;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}
`;

const Headline = styled.span`
	display: block;
	margin-bottom: 16px;
	font-size: 1.2rem;
	font-weight: 700;
`;

const Input = styled.input`
	width: 100%;
	min-height: 56px;
	border-radius: 0;
	border: none;
	&:focus {
		outline: none;
	}
	padding-top: 4px;
`;

const InputGroup = styled.div`
	width: 100%;
`;

const Errors = styled.div`
	.error-message {
		display: block;
		font-size: 0.875rem;
		color: ${colors.form.validation.error.color};
	}
`;

const InputWrapper = styled.div`
	width: 100%;
	max-height: 56px;
	font-family: ${typography.input.fontFamilies};
	font-size: ${typography.input.fontSize};
	line-height: ${typography.input.lineHeight};
	border: 1px solid ${colors.affiliateLinkGenerator.inputWrapper.borderColor};
	border-radius: 4px;
	background-color: ${colors.affiliateLinkGenerator.inputWrapper.background};
	overflow: hidden;
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xxs};
	padding: ${inputPadding};

	&:hover {
		border-color: ${colors.affiliateLinkGenerator.inputWrapper.hover.borderColor};
	}

	&:focus-within {
		border-color: ${colors.affiliateLinkGenerator.inputWrapper.hover.borderColor};
		box-shadow: 0 0 4px 0 ${colors.affiliateLinkGenerator.inputWrapper.hover.inputFocus};
	}

	&.error {
		border-color: ${colors.form.validation.error.color} !important;
		box-shadow: 0 0 0 1px ${colors.form.validation.error.color} !important;
	}
`;

const IconContainer = styled.div`
	display: flex;
	cursor: pointer;
	opacity: 0;
	transition: opacity 200ms ease-in-out;

	&.visible {
		opacity: 1;
	}
`;

const CustomIcon = styled(Icon)`
	line-height: 0;
`;

const InputContainer = styled.div``;

const LinkContainer = styled.div`
	border-radius: 4px;
	min-height: 64px;
	margin-bottom: 8px;

	&.pad-4 {
		padding: 0.25rem;
	}

	.cb-spinner {
		margin: 0;
		margin-left: auto;
		margin-right: auto;
	}
`;

const GenerateNewButton = styled.button`
	display: flex;
	position: relative;
	color: ${colors.affiliateLinkGenerator.generateNewLink.color};
	border: none;
	background: transparent;
	margin-left: auto;
	cursor: pointer;
	padding: 0;

	&:after {
		content: '';
		position: absolute;
		bottom: -2px;
		left: 0;
		width: 100%;
		height: 2px;
		background: transparent;
		transition: background 200ms ease-in-out;
	}

	&:hover,
	&:focus-within {
		&::after {
			background: ${colors.affiliateLinkGenerator.generateNewLink.color};
		}
	}
`;

const Styled = {
	Wrapper,
	Inner,
	Headline,
	Input,
	InputGroup,
	Errors,
	InputWrapper,
	IconContainer,
	CustomIcon,
	InputContainer,
	LinkContainer,
	GenerateNewButton,
};

export default Styled;
