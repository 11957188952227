import { ReactNode } from 'react';

import Accordion from 'components/Accordion';

import Styled from './ExplanationAccordion.style';

type Props = {
	className?: string;
	title?: string;
	chartTitle?: string;
	chartExplanation?: {
		explanation?: string;
		formula?: string | ReactNode;
	};
};

const ExplanationAccordition = ({ className, title, chartTitle, chartExplanation }: Props) => {
	return (
		<Styled.Wrapper className={className}>
			<Accordion title={title || 'Learn more about the graph'} open={false}>
				<Styled.InnerWrapper>
					{chartTitle && (
						<span className='title' data-testid='chart-title'>
							{chartTitle}
						</span>
					)}
					{chartExplanation?.explanation && (
						<span className='explanation' data-testid='chart-explanation'>
							{chartExplanation?.explanation}
						</span>
					)}
					{chartExplanation?.formula && (
						<span className='formula' data-testid='chart-formula'>
							{chartExplanation?.formula}
						</span>
					)}
				</Styled.InnerWrapper>
			</Accordion>
		</Styled.Wrapper>
	);
};

export default ExplanationAccordition;
