import { isEqual } from 'lodash';
import { useState } from 'react';

import { PublisherModel } from 'api-models';
import InlineEditButton from 'components/InlineEditButton/InlineEditButton';
import Pill from 'components/Pill';
import Styled from 'components/Settings/Components/EditClientName/EditClientName.style';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import toast from 'services/Toast';

type EditBrandNameProps = {
	publisher: PublisherModel;
	canEdit: boolean;
};

const EditPublisherName = ({ publisher, canEdit }: EditBrandNameProps) => {
	const manager = useInjection<PublisherManager>(PublisherManager);

	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [newName, setNewName] = useState<string>('');

	const updateClientName = () => {
		const placeholder = structuredClone(publisher);
		placeholder.attributes.name = newName;

		return manager
			.update(publisher.id, { name: newName }, undefined, {
				optimisticModel: placeholder,
			})
			.then(() => {
				setIsEditMode(false);
				!isEqual(publisher.attributes.name, newName) && toast.success(`Name updated`);
				setNewName('');
			})
			.catch(() => {
				toast.error(getErrorMessageOnPost('updating the name'));
			});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewName(e.target.value);
	};

	const handleOnKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			updateClientName();
		}
		if (e.key === 'Escape') {
			setIsEditMode(false);
			setNewName('');
		}
	};

	return (
		<Styled.ClientDetails>
			<Styled.ClientNameWrapper>
				<Styled.Name className='border-bottom'></Styled.Name>
				{!isEditMode ? (
					<>
						<Styled.Name className='client-name' data-testid='client-name-heading'>
							<h4>{publisher.attributes?.name} </h4>
							<span>
								<Pill title='My company' className='brand' />
							</span>
						</Styled.Name>
					</>
				) : (
					<Styled.InlineInput
						autoFocus={true}
						value={newName}
						onChange={(e) => handleChange(e)}
						onKeyDown={(e) => handleOnKeyDown(e)}
						data-testid='brand-name-input'
					/>
				)}
			</Styled.ClientNameWrapper>
			{canEdit && (
				<Styled.EditButtonWrapper>
					<InlineEditButton
						label='Edit name'
						editMode={isEditMode}
						setEditMode={() => {
							setIsEditMode(true);
							setNewName(publisher.attributes?.name);
						}}
						saveChanges={updateClientName}
					/>
				</Styled.EditButtonWrapper>
			)}
		</Styled.ClientDetails>
	);
};
export default EditPublisherName;
