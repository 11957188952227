import moment from 'moment';

import DownloadCsv from 'components/DownloadCsv/DownloadCsv';
import LoadingSpinner from 'components/LoadingSpinner';

import Styled from './AdminInvoices.styled';
import InvoicesTable from './components/InvoicesTable';
import useAdminInvoicesData from './hooks';
import { InvoiceItemType } from './types';

/**
 * AdminInvoices
 * Component to show the overall invoices for admins
 * @returns {JSX.Element}
 */
const AdminInvoices = (): JSX.Element => {
	const { getInvoices } = useAdminInvoicesData();
	const { models, isLoading, error } = getInvoices({});
	const prePayments = models.findAll('invoice') as unknown as Array<InvoiceItemType>;

	return (
		<Styled.AdminInvoices data-testid='admin-invoices'>
			<h4>Overall Invoices</h4>
			<DownloadCsv endpoint='/invoices' fileName={`Overall Invoices ${moment(new Date()).format('YYYY-MM-DD')}.csv`} />
			<Styled.MainContainerInner>
				{isLoading && <LoadingSpinner />}
				{!isLoading && error && <p>There was an error. Please try again later.</p>}
				{!isLoading && !error && <InvoicesTable items={prePayments} />}
			</Styled.MainContainerInner>
		</Styled.AdminInvoices>
	);
};

export default AdminInvoices;
