import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import CampaignItem from 'components/IntegratedInbox/Components/CampaignList/CampaignItem';
import { IntegratedInboxCampaign } from 'components/IntegratedInbox/types';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchInput from 'components/SearchInput';

import Styled from './CampaignList.style';

type Props = {
	campaigns: IntegratedInboxCampaign[];
	selectedCampaign: IntegratedInboxCampaign | null;
	onSelect: (campaign: IntegratedInboxCampaign) => void;
	isFetching: boolean;
};

const CampaignList = ({ campaigns, selectedCampaign, onSelect, isFetching }: Props) => {
	const [campaignsToList, setCampaignsToList] = useState<IntegratedInboxCampaign[]>(campaigns);
	const [searchValue, setSearchValue] = useState<string>('');
	const [cursor, setCursor] = useState(-1);

	const onSearchCampaignName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchValue(value);
		if (value?.length === 0) {
			setCampaignsToList(campaigns);
		} else {
			const filteredData = campaigns.filter((item: IntegratedInboxCampaign) => {
				return item.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
			});

			setCampaignsToList(filteredData);
		}
	};

	const keyboardNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (campaignsToList.length > 0) {
				setCursor((prev) => {
					return prev < campaignsToList.length - 1 ? prev + 1 : prev;
				});
			}
		}
		if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (campaignsToList.length > 0) {
				setCursor((prev) => {
					return prev > 0 ? prev - 1 : 0;
				});
			}
		}
		if (e.key === 'Escape') {
			setCursor(-1);
			setCampaignsToList(campaigns);
			setSearchValue('');
		}
		if (e.key === 'Enter') {
			onSelect(campaignsToList[cursor]);
		}
	};

	const onResetSearch = () => {
		setSearchValue('');
		setCampaignsToList(campaigns);
	};

	useEffect(() => {
		setCampaignsToList(campaigns);
	}, [campaigns]);

	return (
		<Styled.Wrapper data-testid='inbox-campaign-list-wrapper'>
			{isFetching ? (
				<Styled.SpinnerWrapper data-testid='loading-campaings'>
					<LoadingSpinner />
				</Styled.SpinnerWrapper>
			) : (
				<>
					<Styled.InputWrapper>
						<SearchInput
							onKeyDown={(e) => {
								keyboardNavigation(e);
							}}
							value={searchValue}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchCampaignName(e)}
							onClick={(e) => {
								e.stopPropagation();
							}}
							onReset={() => onResetSearch()}
							placeholder='Search for campaigns'
						/>
					</Styled.InputWrapper>
					<Styled.ListWrapper data-testid='inbox-list-wrapper'>
						{campaignsToList
							.filter((campaign) => campaign.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
							.map((campaign, index) => {
								const isSelected = selectedCampaign ? selectedCampaign.id === campaign.id : false;
								const hasUnreadMessages = campaign.conversationMetaData?.unread > 0;
								return (
									<CampaignItem
										index={index}
										cursor={cursor}
										data-testid={`campaign-${campaign.id}`}
										key={campaign.id}
										title={campaign.name}
										coverImage={campaign.coverImage}
										onClick={(e: React.MouseEvent) => {
											e.stopPropagation();
											onResetSearch();
											onSelect(campaign);
										}}
										isSelected={isSelected}
										hasUnreadMessages={hasUnreadMessages}
									/>
								);
							})}

						{searchValue && isEmpty(campaignsToList) && !isEmpty(campaigns) && (
							<Styled.NoResults>
								<Styled.NoResultsText data-testid='inbox-no-result'>
									Sorry, no match for <span className='value'>&apos;{searchValue}&apos;</span>
								</Styled.NoResultsText>
							</Styled.NoResults>
						)}
						{isEmpty(campaigns) && (
							<Styled.NoResults>
								<Styled.NoResultsText>You have no campaigns yet.</Styled.NoResultsText>
							</Styled.NoResults>
						)}
					</Styled.ListWrapper>
				</>
			)}
		</Styled.Wrapper>
	);
};

export default CampaignList;
