import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgHelp(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps} fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM11.756 9.41391C11.4854 9.72191 11.14 9.87591 10.72 9.87591H10.356C10.0014 9.87591 9.71205 9.98791 9.48805 10.2119C9.26405 10.4359 9.15205 10.7252 9.15205 11.0799V12.3959H10.272V11.3599C10.272 11.0799 10.4027 10.9399 10.664 10.9399H10.86C11.2147 10.9399 11.5414 10.8699 11.84 10.7299C12.148 10.5806 12.414 10.3846 12.638 10.1419C12.862 9.89924 13.0347 9.61457 13.156 9.28791C13.2867 8.95191 13.352 8.60191 13.352 8.23791V7.98591C13.352 7.58457 13.2727 7.20657 13.114 6.85191C12.9554 6.48791 12.736 6.17057 12.456 5.89991C12.176 5.61991 11.84 5.40057 11.448 5.24191C11.056 5.08324 10.6267 5.00391 10.16 5.00391C9.60938 5.00391 9.12405 5.09257 8.70405 5.26991C8.28405 5.44724 7.93405 5.68524 7.65405 5.98391C7.37405 6.28257 7.15938 6.62791 7.01005 7.01991C6.87005 7.41191 6.80005 7.82257 6.80005 8.25191V8.92391H7.97605V8.33591C7.97605 7.66391 8.16738 7.12257 8.55005 6.71191C8.93272 6.30124 9.46005 6.09591 10.132 6.09591C10.44 6.09591 10.72 6.15191 10.972 6.26391C11.224 6.36657 11.4387 6.51124 11.616 6.69791C11.7934 6.87524 11.9287 7.08524 12.022 7.32791C12.1247 7.57057 12.176 7.83191 12.176 8.11191V8.19591C12.176 8.69991 12.036 9.10591 11.756 9.41391ZM9.02605 13.5299C8.83938 13.7166 8.74605 13.9452 8.74605 14.2159C8.74605 14.4866 8.83938 14.7199 9.02605 14.9159C9.22205 15.1026 9.45538 15.1959 9.72605 15.1959C9.99672 15.1959 10.2254 15.1026 10.412 14.9159C10.608 14.7199 10.706 14.4866 10.706 14.2159C10.706 13.9452 10.608 13.7166 10.412 13.5299C10.2254 13.3339 9.99672 13.2359 9.72605 13.2359C9.45538 13.2359 9.22205 13.3339 9.02605 13.5299Z'
				fill='#333333'
			/>
		</svg>
	);
}
