import { NotificationModel } from 'api-models';

export const ServiceIdentifier = {
	MercureMessageHandler: Symbol('MercureMessageHandler'),
};

export type MercureMessage = {
	type: string;
	attributes: {};
};

export type MercureNotification = NotificationModel;
export type MercureInboxMessage = {
	type: 'newConversationMessage';
	attributes: {
		conversation: string;
		message: string;
	};
};

export type TokenData = {
	c_token: string;
	m_token: string | null;
	d_token: {
		token: string;
		expiresAt: string;
	};
};
