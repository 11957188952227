import { JsonApiDocument, JsonApiIdentifier, JsonApiResource, Store } from 'json-api-models';
import { filter } from 'lodash';
import useSWR, { mutate } from 'swr';

import { createClient } from 'shared/ApiClient/ApiClient';
import { AssignmentType } from 'shared/helpers/Assigment/types';

type StatisticsPayloadType = {
	postedAt: string;
	url?: string;
	reach: number;
	reachLastFrame?: number;
	impressions: number;
	comments?: number;
	likes?: number;
	saves?: number;
	shares?: number;
	stickerLinkClicks?: number;
	stickerTaps?: number;
	otherInteractions?: number;
};

function mapPayloadForStats(type: AssignmentType, payload: StatisticsPayloadType) {
	const data = {
		postedAt: new Date(payload.postedAt),
		reach: +payload.reach!,
		impressions: +payload.impressions!, // plays
	};

	switch (type) {
		case AssignmentType.TIKTOK_VIDEO:
			return {
				...data,
				url: payload.url,
				commentsCount: +payload.comments!,
				likeCount: +payload.likes!,
				saves: +payload.saves!,
				shares: +payload.shares!,
			};
		case AssignmentType.INSTAGRAM_STORY:
			return {
				...data,
				reachLastFrame: +payload.reachLastFrame!,
				stickerLinkClicks: +payload.stickerLinkClicks!,
				stickerTaps: +payload.stickerTaps!,
				otherInteractions: +payload.otherInteractions!,
			};

		default:
			return {
				...data,
				url: payload.url,
				commentsCount: +payload.comments!,
				likeCount: +payload.likes!,
				saves: +payload.saves!,
			};
	}
}

export function getCampaignUri(campaignId: string): string {
	return `/campaigns/${campaignId}?includes=commissions.campaignInstagramOwnerCommissions.commission,
	invites.campaignInstagramOwner.influencer.user,
	invites.campaignInstagramOwner.campaignInstagramOwnerCommissions.commission,
	invites.campaignInstagramOwner.campaignInstagramOwnerAssignments.instagramStories.files,
	invites.campaignInstagramOwner.campaignInstagramOwnerAssignments.assignment.reviews,
	assignments.groups.campaignInstagramOwnerAssignments.assignment,
	campaignInstagramOwners:context(contentReview)
	`;
}
export default function useContentManagementData() {
	const client = createClient();

	const fetcher = async (url: string) => {
		const { data } = await client.get<JsonApiDocument>(url, {});
		return data;
	};

	const getCampaigns = () => {
		const models = new Store();
		const url = `/campaigns`;

		const { data, error } = useSWR(url, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			mutation: {
				async add(resource: JsonApiResource) {
					await mutate({
						...data,
						data: [...(data!.data as JsonApiResource[]), resource],
					});
				},
				async addIncluded(resource: JsonApiResource) {
					await mutate({
						...data,
						included: [...(data!.included as JsonApiResource[]), resource],
					});
				},
				async remove({ id }: JsonApiIdentifier) {
					await mutate({
						...data,
						data: filter(data!.data as JsonApiResource[], (resource) => resource.id !== id),
					});
				},
				async refresh() {
					await mutate(url);
				},
			},
			error,
			loading: !data && !error,
		};
	};

	const getCampaign = (campaignId?: string) => {
		const models = new Store();
		const url = campaignId ? getCampaignUri(campaignId) : null;
		const { data, error } = useSWR(url, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			mutation: {
				async add(resource: JsonApiResource) {
					await mutate({
						...data,
						data: [...(data!.data as JsonApiResource[]), resource],
					});
				},
				async addIncluded(resource: JsonApiResource) {
					await mutate({
						...data,
						included: [...(data!.included as JsonApiResource[]), resource],
					});
				},
				async remove({ id }: JsonApiIdentifier) {
					await mutate({
						...data,
						data: filter(data!.data as JsonApiResource[], (resource) => resource.id !== id),
					});
				},
				async refresh() {
					await mutate(url);
				},
			},
			error,
			loading: campaignId ? !data && !error : false,
		};
	};

	const getAssignmentReviews = async () => {
		const url = `/assignment-reviews?includes=assignment,assignment.campaign,influencer,comments,comments.user,medias`;
		const response = await client.get<JsonApiDocument>(url);
		return response;
	};

	const createComment = async (url: string, message: string) => {
		const response = await client.post<JsonApiDocument>(url, {
			text: message,
		});
		return response;
	};

	const postReview = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response;
	};

	const patchEditStatistics = async (url: string, data: StatisticsPayloadType, type: AssignmentType) => {
		const payload = mapPayloadForStats(type, data);
		const response = await client.patch<JsonApiDocument>(url, payload);
		return response;
	};

	const postStatistics = async (url: string, data: StatisticsPayloadType, type: AssignmentType) => {
		const payload = mapPayloadForStats(type, data);
		const response = await client.post<JsonApiDocument>(url, payload);
		return response;
	};

	const patchMediaItem = async (
		url: string,
		props: {
			sort: number;
			text?: string;
			url?: string;
		},
	) => {
		const response = await client.patch<JsonApiDocument>(url, props);
		return response;
	};

	const patchReview = async (
		url: string,
		props: {
			text?: string;
			url?: string;
		},
	) => {
		const response = await client.patch<JsonApiDocument>(url, props);
		return response;
	};

	const disapproveReview = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response;
	};

	const uploadStatisticsScreenshot = async (url: string, file: File) => {
		const formData = new FormData();
		formData.set('file', file);

		const response = await client.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
		return response;
	};

	const deleteMedia = async (url: string) => {
		const response = await client.delete<JsonApiDocument>(url);
		return response;
	};

	const postEnableToClient = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response;
	};

	const postApproveAsClient = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response;
	};

	const getAffiliateLink = async (url: string) => {
		const response = await client.get<JsonApiDocument>(url);
		return response.data;
	};

	const postAffiliateLink = async (url: string, value: string) => {
		const response = await client.post<JsonApiDocument>(url, {
			url: value,
		});
		return response.data;
	};

	return {
		getCampaigns,
		getCampaign,
		createComment,
		getAssignmentReviews,
		postReview,
		patchEditStatistics,
		patchReview,
		patchMediaItem,
		postStatistics,
		disapproveReview,
		uploadStatisticsScreenshot,
		deleteMedia,
		postEnableToClient,
		postApproveAsClient,
		getAffiliateLink,
		postAffiliateLink,
	};
}
