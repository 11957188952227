import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Store } from 'json-api-models';
import { useRef, useState } from 'react';

import { CropDimensionsType, KIND } from 'components/EditableImage/types/types';
import { createClient } from 'shared/ApiClient/ApiClient';

export type PresignResponseObject = {
	key: string;
	url: string;
	userUpload: string;
	file: File | undefined;
};

enum StatusCodes {
	OK = 200,
	NOT_FOUND = 404,
}

const useImageUpload = (kind: KIND, totalFiles?: number) => {
	const client = createClient();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const num404errors = useRef<number>(0);

	const getPresignUrl = async (file: File, cropDimensions: CropDimensionsType | null, kind: KIND, campaignId: string, sortOrder?: number) => {
		let response: PresignResponseObject = {
			key: '',
			url: '',
			userUpload: '',
			file: undefined,
		};

		try {
			const presignUrlResponse = await client.post('/user-uploads/presign-urls', {
				filename: file.name,
				...cropDimensions,
				sortOrder: sortOrder,
				kind: kind,
				campaign: campaignId,
				private: false,
			});
			const model = new Store();
			model.sync(presignUrlResponse.data);
			const presignUrlData = model.findAll('presignUrl')[0];
			response = { key: presignUrlData.key, url: presignUrlData.url, userUpload: presignUrlData.links?.userUpload || '', file: file };
		} catch (error) {
			console.error('error getting presign url');
		}
		return response;
	};

	const INTERVAL_TIME = 500;
	const RETRIES = 20;

	//uploading and polling for image
	const uploadAndPollImage = async (file: File, cropDimensions: CropDimensionsType | null, campaignId: string, sortOrder?: number) => {
		try {
			setIsLoading(true);
			const presignUrlResponse = await getPresignUrl(file, cropDimensions, kind, campaignId, sortOrder);
			const uploadResponse = await client.put(presignUrlResponse.url, presignUrlResponse.file, {
				headers: {
					'Content-Type': presignUrlResponse.file?.type,
					'Cache-Control': 'max-age=31449600,immutable',
				},
			});
			return await pollForImage(client, presignUrlResponse, uploadResponse);
		} catch (error) {
			setIsLoading(false);
			throw error;
		}
	};

	const pollForImage = (client: AxiosInstance, presignUrlResponse: PresignResponseObject, uploadResponse: AxiosResponse) => {
		return new Promise<string>((resolve, reject) => {
			const pollInterval = setInterval(async () => {
				try {
					const userUploadResponse = await client.get(presignUrlResponse.userUpload);
					if (userUploadResponse.status === StatusCodes.OK) {
						clearInterval(pollInterval);
						resolve(presignUrlResponse.url);
						setIsLoading(false);
						if (uploadResponse.status === StatusCodes.OK) {
							resolve(presignUrlResponse.url);
							setIsLoading(false);
						} else {
							reject(uploadResponse.statusText);
						}
					} else {
						reject('Error polling');
					}
				} catch (error) {
					handleError(error, pollInterval, reject);
				}
			}, INTERVAL_TIME);
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleError = (error: unknown, pollInterval: string | number | NodeJS.Timeout | undefined, reject: { (reason?: any): void; (arg0: string): void }) => {
		if (axios.isAxiosError(error) && error.response?.status === StatusCodes.NOT_FOUND) {
			handleNotFoundError(error, pollInterval, reject);
		} else {
			clearInterval(pollInterval);
			setIsLoading(false);
			reject(`failed to upload due to: ${error}`);
		}
	};

	const handleNotFoundError = (error: unknown, pollInterval: string | number | NodeJS.Timeout | undefined, reject: (arg0: string) => void) => {
		num404errors.current += 1;
		if (num404errors.current >= RETRIES * (totalFiles ?? 1)) {
			num404errors.current = 0;
			clearInterval(pollInterval);
			setIsLoading(false);
			reject(`retried and failed due to: ${error}`);
		}
	};

	return {
		uploadAndPollImage,
		isLoading,
	};
};

export default useImageUpload;
