import styled from 'styled-components';

import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	font-size: ${typography.label.small.fontSize};
	margin-bottom: 5px;
`;

const Input = styled.input`
	width: 120px;
	text-align: center;

	font-family: ${typography.input.fontFamilies};

	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:focus {
		outline: none;
	}
`;

const Styled = {
	Wrapper,
	Label,
	Input,
};

export default Styled;
