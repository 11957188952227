import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgThumbsDownFilled(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M2.34891 3.71146C2.41751 3.41879 2.55471 3.13737 2.72621 2.90099C3.20642 2.23685 4.3269 1.00989 5.95045 1.00989H15.2687C15.2687 1.00989 18.6302 0.91984 17.0295 3.55386C17.0295 3.55386 19.1104 5.24234 17.1781 6.66066C17.1781 6.66066 18.9618 8.19155 17.0524 9.73369C17.0524 9.73369 18.1042 12.3339 15.3945 12.3339H9.06036C8.90029 12.3339 8.78596 12.4915 8.85456 12.6379L10.6725 16.8478C10.6725 16.8478 11.2556 18.1761 10.1351 18.8177C8.83169 19.5606 7.74551 17.9059 7.74551 17.9059L3.44652 11.3209C3.08065 10.7693 2.85198 10.3978 2.70334 10.1502C2.50897 9.835 2.37177 9.50856 2.29174 9.14835C2.06307 8.19155 1.74293 6.16538 2.34891 3.71146Z'
				fill='#333333'
			/>
		</svg>
	);
}
