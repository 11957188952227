import { Doughnut } from 'components/Chart';
import { ByGenderOutput } from 'components/DataLibrary/Graphs/GraphComponents/ByGender/types';
import ChartCard from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard';
import Styled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { ChartCardProps } from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/types';
import ExplanationAccordition from 'components/DataLibrary/Graphs/GraphComponents/ExplanationAccordion';
import useInjection from 'hooks/useInjection';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import { formatNumber } from 'shared/helpers/Chart/chart-util';
import colors from 'styles/variables/colors';
import { useAppSelector } from 'views/DataLibrary/hooks';
import { DashboardFilters } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const ByGender = ({ chartId, fetchData, title, chartType, chartOnDashboard, chartExplanation }: ChartCardProps<ByGenderOutput>): JSX.Element => {
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const manager = useInjection<StatisticsManager>(StatisticsManager);

	let somethingWentWrong = null;
	let innerText: number | string = 0;
	let datasets: { data: number[]; labels: string[]; total: number } = { total: 0, labels: ['Women', 'Men', 'Unknown'], data: [0, 0, 0] };
	let isLoading = true;

	if (!fetchData) {
		isLoading = false;
		datasets = {
			total: 31,
			labels: ['Women', 'Men', 'Unknown'],
			data: [17, 10, 4],
		};
		innerText = 30;
	} else {
		const { result, error } = fetchData(manager, dashboardFilter);
		if (error) {
			somethingWentWrong = error.message;
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			somethingWentWrong = null;
			datasets = result;
			if (result.total > 0) {
				innerText = formatNumber(result.total, true);
			} else {
				innerText = 0;
			}
		}
	}

	return (
		<ChartCard
			somethingWentWrong={somethingWentWrong}
			chartId={chartId}
			title={title}
			chartType={chartType}
			chartOnDashboard={chartOnDashboard}
			isLoading={isLoading}
		>
			<Styled.InnerCenteredWrapper>
				{datasets.total > 0 ? (
					<Doughnut
						labels={datasets.labels}
						outsideLabel={true}
						legendDisplay={false}
						backgroundColors={[colors.dataLibrary.charts.color1, colors.dataLibrary.charts.color2, colors.dataLibrary.charts.color3]}
						data={datasets.data}
						innerText={innerText}
						cutout='75%'
						spacing={0}
						borderWidth={0}
						innerFontSize='1em'
					/>
				) : (
					<div>N/A</div>
				)}
			</Styled.InnerCenteredWrapper>
			{chartExplanation && <ExplanationAccordition chartTitle={title} chartExplanation={chartExplanation} />}
		</ChartCard>
	);
};

export default ByGender;
