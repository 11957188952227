import { JsonApiDocument, Store } from 'json-api-models';
import { isNil, isEmpty } from 'lodash';
import useSWR from 'swr';

import { createClient } from 'shared/ApiClient/ApiClient';

export type AMCamapignParam = {
	campaignIds?: string[];
	influencerIds?: string[];
	from?: string;
	to?: string;
	paidPublisherInvoice?: boolean;
	paid?: boolean;
};

export default function useAdminPrePaymentsData() {
	const client = createClient();

	const fetcher = async (url: string) => {
		const { data } = await client.get<JsonApiDocument>(url, {});
		return data;
	};

	const updateFortnoxId = async (id: string, fortnoxId: string) => {
		const url = `/pre-payments/${id}`;

		const response = await client.patch<JsonApiDocument>(url, { fortnoxId });
		return response;
	};

	const getInvoices = ({ campaignIds, influencerIds, from, to, paidPublisherInvoice, paid }: AMCamapignParam) => {
		const models = new Store();

		const params = new URLSearchParams();
		if (!isNil(campaignIds) && !isEmpty(campaignIds)) {
			params.append('campaigns', campaignIds.join(','));
		}
		if (!isNil(influencerIds) && !isEmpty(influencerIds)) {
			params.append('influencers', influencerIds.join(','));
		}
		if (!isNil(from)) {
			params.append('from', encodeURIComponent(from));
		}
		if (!isNil(to)) {
			params.append('to', encodeURIComponent(to));
		}

		if (!isNil(paidPublisherInvoice)) {
			params.append('paidPublisherInvoice', paidPublisherInvoice);
		}
		if (!isNil(paid)) {
			params.append('paid', paid);
		}

		const url = `/pre-payments?includes=influencer.user,agreement,file,invoices`;

		const { data, error, mutate, isValidating } = useSWR(url, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			isLoading: !data && !error,
			error: error,
			refresh: () => mutate(),
			isValidating,
		};
	};

	const getUnpaidInvoices = () => {
		const models = new Store();

		const params = new URLSearchParams();

		params.append('paid', 'false');
		params.append('paidPublisherInvoice', 'false');

		const url = `/pre-payments?includes=influencer.user,agreement,file,invoices?${params}`;

		const { data, error, mutate, isValidating } = useSWR(url, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			isLoading: !data && !error,
			error: error,
			refresh: () => mutate(),
			isValidating,
		};
	};

	return {
		getInvoices,
		getUnpaidInvoices,
		updateFortnoxId,
	};
}
