import { isNil } from 'lodash';

// @ts-ignore
export const gtagEvent = (eventName: Gtag.EventNames | (string & {}), eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams) => {
	// @ts-ignore
	if (process.env.NODE_ENV === 'production' && !isNil(window.gtag)) {
		// @ts-ignore
		window.gtag('event', eventName, eventParams);
	}
};
