/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import {
	CreateInfluencerListFolderPayload,
	UpdateInfluencerListFolderPayload,
	AddUsersToListFolderPayload,
	CreateInfluencerListPayload,
	UpdateInfluencerListPayload,
	AddInfluencersToListPayload,
	AddUsersToListPayload,
} from 'api-payloads';
import {
	ListInfluencerListFoldersQuery,
	CreateInfluencerListFolderQuery,
	ViewInfluencerListFolderQuery,
	UpdateInfluencerListFolderQuery,
	ListInfluencerListsQuery,
	CreateInfluencerListQuery,
	ViewInfluencerListQuery,
	UpdateInfluencerListQuery,
} from 'api-queries';
import {
	InfluencerListFolderCollectionResponse,
	InfluencerListFolderResponse,
	InfluencerListListCollectionResponse,
	InfluencerListListResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import InfluencerListApiClientInterface from './InfluencerListApiClientInterface';

@injectable()
export default class InfluencerListApiClient implements InfluencerListApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List influencer list folders
	 */
	async listFolders(params: ListInfluencerListFoldersQuery): Promise<InfluencerListFolderCollectionResponse> {
		const response = await this.client.get<InfluencerListFolderCollectionResponse>(`/folders`, { params });
		return response.data;
	}

	/**
	 * Create influencer list folder
	 */
	async createFolder(payload: CreateInfluencerListFolderPayload, params: CreateInfluencerListFolderQuery): Promise<InfluencerListFolderResponse> {
		const response = await this.client.post<InfluencerListFolderResponse>(`/folders`, payload, { params });
		return response.data;
	}

	/**
	 * Delete influencer list folder privilege
	 */
	async deleteFolderPrivilege(folderId: string, userId: string): Promise<void> {
		const response = await this.client.delete<void>(`/folders/${folderId}/users/${userId}`);
		return response.data;
	}

	/**
	 * View influencer list folder
	 */
	async viewFolder(id: string, params: ViewInfluencerListFolderQuery): Promise<InfluencerListFolderResponse> {
		const response = await this.client.get<InfluencerListFolderResponse>(`/folders/${id}`, { params });
		return response.data;
	}

	/**
	 * Delete influencer list folder
	 */
	async deleteFolder(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/folders/${id}`);
		return response.data;
	}

	/**
	 * Update influencer list folder
	 */
	async updateFolder(id: string, payload: UpdateInfluencerListFolderPayload, params: UpdateInfluencerListFolderQuery): Promise<InfluencerListFolderResponse> {
		const response = await this.client.patch<InfluencerListFolderResponse>(`/folders/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * Add users to list folder
	 */
	async addUsersToListFolder(id: string, payload: AddUsersToListFolderPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/folders/${id}/users`, payload, { params });
		return response.data;
	}

	/**
	 * Delete influencer list item comment
	 */
	async deleteItemComment(itemId: string, commentId: string): Promise<void> {
		const response = await this.client.delete<void>(`/items/${itemId}/comments/${commentId}`);
		return response.data;
	}

	/**
	 * Delete influencer list item reaction
	 */
	async deleteItemReaction(itemId: string, reactionId: string): Promise<void> {
		const response = await this.client.delete<void>(`/items/${itemId}/reactions/${reactionId}`);
		return response.data;
	}

	/**
	 * List influencer lists
	 */
	async listInfluencerLists(params: ListInfluencerListsQuery): Promise<InfluencerListListCollectionResponse> {
		const response = await this.client.get<InfluencerListListCollectionResponse>(`/lists`, { params });
		return response.data;
	}

	/**
	 * Create influencer list
	 */
	async create(payload: CreateInfluencerListPayload, params: CreateInfluencerListQuery): Promise<InfluencerListListResponse> {
		const response = await this.client.post<InfluencerListListResponse>(`/lists`, payload, { params });
		return response.data;
	}

	/**
	 * View influencer list
	 */
	async view(id: string, params: ViewInfluencerListQuery): Promise<InfluencerListListResponse> {
		const response = await this.client.get<InfluencerListListResponse>(`/lists/${id}`, { params });
		return response.data;
	}

	/**
	 * Delete influencer list
	 */
	async delete(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/lists/${id}`);
		return response.data;
	}

	/**
	 * Update influencer list
	 */
	async update(id: string, payload: UpdateInfluencerListPayload, params: UpdateInfluencerListQuery): Promise<InfluencerListListResponse> {
		const response = await this.client.patch<InfluencerListListResponse>(`/lists/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * Add influencers to list
	 */
	async addInfluencersToList(id: string, payload: AddInfluencersToListPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/lists/${id}/influencers`, payload, { params });
		return response.data;
	}

	/**
	 * Add users to list
	 */
	async addUsersToList(id: string, payload: AddUsersToListPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/lists/${id}/users`, payload, { params });
		return response.data;
	}

	/**
	 * Delete influencer list item
	 */
	async deleteItem(listId: string, itemId: string): Promise<void> {
		const response = await this.client.delete<void>(`/lists/${listId}/items/${itemId}`);
		return response.data;
	}

	/**
	 * Delete influencer list privilege
	 */
	async deletePrivilege(listId: string, userId: string): Promise<void> {
		const response = await this.client.delete<void>(`/lists/${listId}/users/${userId}`);
		return response.data;
	}

	/**
	 * Delete shortcut
	 */
	async deleteShortcut(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/shortcut/${id}`);
		return response.data;
	}
}
