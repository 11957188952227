import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgMinus(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0 10C-1.20705e-08 9.44772 0.447715 9.00001 1 9.00001L19 9C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11L1 11C0.447715 11 1.20705e-08 10.5523 0 10Z'
				fill='#333333'
			/>
		</svg>
	);
}
