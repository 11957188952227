import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgAddCircle(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				d='M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.0003 3.59351C10.4145 3.59351 10.7503 3.92929 10.7503 4.34351V9.24927H15.6575C16.0717 9.24927 16.4075 9.58505 16.4075 9.99927C16.4075 10.4135 16.0717 10.7493 15.6575 10.7493H10.7503V15.6565C10.7503 16.0707 10.4145 16.4065 10.0003 16.4065C9.58604 16.4065 9.25026 16.0707 9.25026 15.6565V10.7493H4.34448C3.93027 10.7493 3.59448 10.4135 3.59448 9.99927C3.59448 9.58505 3.93027 9.24927 4.34448 9.24927H9.25026V4.34351C9.25026 3.92929 9.58604 3.59351 10.0003 3.59351Z'
				fill='#333'
			/>
		</svg>
	);
}
