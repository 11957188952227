import styled from 'styled-components';

import { DarkButton, GhostButton } from 'components/Button';
import Grid from 'styles/grid/grid';
import { CTALink } from 'styles/utils/CTALink';
import colors from 'styles/variables/colors';
import { devices, mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	width: 100%;
	height: 90vh;
	overflow: unset;
	label {
		color: ${colors.admin.labelGray};
		font-family: ${typography.BaseFontFamiliy};
		font-size: 0.938rem;
	}
	${mediaQueries.medium`
		overflow: hidden;
	`};
`;

const TabNav = styled.div``;

const Navigation = styled.ul`
	margin: 0;
	list-style: none;
`;

const NavigationItemContent = styled.div`
	width: 100%;
	border-bottom: 1px solid #888;
	padding-bottom: 1rem;
	padding-top: 0.4rem;
	padding-left: 0.5rem;
`;

const NavigationItem = styled.li`
	display: flex;
	align-items: center;
	font-family: ${typography.BaseFontFamiliy};
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	height: 64px;
	cursor: pointer;
	&.dark-pink {
		background-color: ${colors.admin.mediumPink};
	}
	&.selected {
		background-color: ${colors.settings.darkGray};
		color: ${colors.white};
		.icon path {
			fill: ${colors.white};
		}
		${NavigationItemContent} {
			border-bottom: 1px solid ${colors.settings.darkGray};
		}
	}
`;

const NavigationTitle = styled.h3`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 28px;
	margin-bottom: 0;
	margin-left: 1rem;
	cursor: pointer;
	padding: 1rem 0 0.5rem 0.5rem;
`;

const NavigationItemLink = styled.a`
	${CTALink};
`;

const TabContainer = styled.div`
	position: relative;
`;

const Tab = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
	width: 100vw;
	height: 80vh;
	${mediaQueries.medium`
			width: 100%;
			padding: 2rem 0;
	`};
	overflow-y: auto;
	h4 {
		font-size: 2.0625rem;
		font-weight: 700;
		line-height: 40px;
		letter-spacing: 0;
		text-align: left;
	}
	hr {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
	.form-group {
		margin-bottom: 1rem;
	}
`;

const TabContent = styled.div`
	margin: 0 auto;
`;

const FormCard = styled.div`
	position: relative;
	background-color: ${colors.formCard.background};
	padding: 2.5rem;
	border: 1px solid ${colors.formCard.borderColor};
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
	width: 100%;
	max-width: 43.75rem;

	& + & {
		margin-top: 2.5rem;
	}

	p {
		color: ${colors.formCard.color};
	}
`;

const IconBar = styled.div`
	margin-bottom: 2rem;
`;

const InputWrapper = styled.div`
	margin-bottom: 1.625rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	&.w-50 {
		width: 60%;
	}
`;

const InputGroup = styled.div`
	display: flex;
	width: auto;
	background-color: ${({ theme }) => theme.InputField.background};
	border: ${({ theme }) => theme.InputField.border};
	border-radius: 4px;
	&:hover {
		border: ${({ theme }) => theme.InputField.hover.border};
		box-shadow: 0 0 0 1px ${colors.borderGray};
	}

	input,
	select {
		border: 0 none;
		&:hover,
		&:focus {
			border: 0 none;
			box-shadow: none;
		}
	}

	&.phone-number {
		& > div {
			& > input,
			select {
				&:hover,
				&:focus {
					border: none;
					outline: none;
					box-shadow: none;
				}
			}
		}
	}
`;

const UploadInput = styled.input`
	background-color: transparent;
	border: none;
	margin-top: 1rem;
`;

const Label = styled.label`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
`;

const FormWrapper = styled.div`
	padding-top: 1.5rem;
`;

const ButtonGroup = styled.div`
	display: flex;
	margin-top: 0.875rem;
	button:last-child {
		margin-left: auto;
	}
`;

const LoadingOverlay = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.75);
	opacity: 0;
	visibility: hidden;
	transition:
		opacity 0.2s ease-in-out,
		visibility 0.2s ease-in-out;

	&.visible {
		opacity: 1;
		visibility: visible;
	}
`;

const Legend = styled.legend`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1.025rem;
`;

const ValidationMessageWrapper = styled.div`
	margin-top: 0.25rem;
`;

const ValidationMessageText = styled.span`
	font-size: 0.75rem;
	padding-left: 1rem;
	color: ${colors.form.validation.error.color};
`;

const LogotypeWrapper = styled.div`
	img {
		max-width: 150px;
	}
`;

const FormDescription = styled.div`
	margin-bottom: 1.5rem;
`;

const StyledGridContainer = styled(Grid.Container)`
	border-top: 1px solid #888;
	padding-bottom: 2rem;
	margin-top: 80px;
	width: 100vw;
	flex-direction: column;
	position: relative;
	${mediaQueries.medium`
		flex-direction: row;
	`};
`;

const StyledSideBar = styled(Grid.Column)`
	border-right: 1px solid #888;
	height: auto;
	margin-top: 100px;
	${mediaQueries.medium`
		height: 100vh;
		position: sticky;
		top: 165px;
		margin-top: 0;
	`};
`;

const IconContainer = styled.div`
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 0.5rem;
`;

const NavigationItemInnerContainer = styled.div`
	padding: 1rem 0 0 1rem;
	display: flex;
	align-items: center;
	width: 100%;
`;

const FormSubTitle = styled.div`
	margin-top: 3rem;
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1rem;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
`;

const TeamSection = styled.div`
	margin-top: 4rem;
	p {
		margin-bottom: 2rem;
	}
`;

const SpinnerWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100px;
`;

const AddNewButtons = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	margin-top: 1rem;
`;

const CreateWrapper = styled.div`
	margin-bottom: 1rem;
`;

const HelperText = styled.div`
	color: ${colors.red};
	font-size: 12px;
`;

const CreateButtons = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;
	margin-top: 2rem;
`;

const AlignButtonEnd = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 1rem;
	margin-top: 1rem;
`;

const DangerZone = styled.div`
	margin-top: 2rem;
`;

const CustomGhostButton = styled(GhostButton)`
	&:disabled {
		background-color: ${colors.shareModal.button.disabledBackground};
		color: ${colors.shareModal.button.disabledText};
		border: none;
		cursor: not-allowed;
		&:hover {
			color: ${colors.shareModal.button.disabledText} !important;
		}
	}
`;

const SubmitButton = styled(DarkButton)`
	&:disabled {
		background-color: ${colors.shareModal.button.disabledBackground};
		color: ${colors.shareModal.button.disabledText};
		border: none;
		cursor: not-allowed;
		&:hover {
			color: ${colors.shareModal.button.disabledText} !important;
		}
	}
`;

const Content = styled.div`
	h4 {
		margin-bottom: 2.5rem;
	}
	p {
		color: ${colors.gray5};
		margin-bottom: 1rem;
	}
	.form-group {
		margin-bottom: 2rem;
	}
`;

const ButtonsSpaceBetween = styled.div`
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: flex;
	justify-content: space-between;
`;

const ZipAndCity = styled.div`
	display: flex;
	.left {
		margin-right: 5px;
		width: 40%;
	}
	.right {
		margin-left: 5px;
		width: 60%;
	}
`;

const Cancel = styled.div`
	cursor: pointer;
	font-family: 'Space Mono';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 0.438rem 1rem;
	border: solid 2px ${colors.black1};
	&:hover {
		background-color: ${colors.button.primary.backgroundHover};
		color: ${colors.button.primary.colorHover};
	}
`;

const NameWrapper = styled.div`
	display: flex;
	.left {
		margin-right: 5px;
		width: 100%;
	}
	.right {
		margin-left: 5px;
		width: 100%;
	}
`;

const CustomHeader = styled.div`
	display: flex;

	align-items: center;
	h5 {
		font-size: 1.5rem;
		font-weight: 700;
		margin-bottom: 0;
	}
	.icon {
		margin-left: 10px;
	}
`;

const ActionSection = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: 8px;
`;

const SearchWrapper = styled.div`
	width: 100%;
	margin-bottom: 16px;
	@media screen and (${devices.lg}) {
		width: 30%;
		float: right;
	}
`;

const LoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	div {
		&.margin-right {
			margin-right: 10px;
		}
	}
`;

const HeaderSection = styled(Grid.Container)`
	width: 100%;
	padding: 1rem 2rem;
	position: fixed;
	top: 72px;
	left: 0;
	right: 0;
	z-index: 100;
`;

const MenuTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const Styled = {
	MenuTitle,
	HeaderSection,
	NameWrapper,
	Cancel,
	ZipAndCity,
	ButtonsSpaceBetween,
	SubmitButton,
	Content,
	CustomGhostButton,
	DangerZone,
	AlignButtonEnd,
	CreateButtons,
	HelperText,
	CreateWrapper,
	AddNewButtons,
	Wrapper,
	TabNav,
	Navigation,
	NavigationItem,
	NavigationItemLink,
	TabContainer,
	Tab,
	TabContent,
	IconBar,
	InputWrapper,
	InputGroup,
	UploadInput,
	Label,
	FormWrapper,
	ButtonGroup,
	FormCard,
	LoadingOverlay,
	Legend,
	ValidationMessageWrapper,
	ValidationMessageText,
	LogotypeWrapper,
	FormDescription,
	StyledGridContainer,
	StyledSideBar,
	NavigationTitle,
	IconContainer,
	NavigationItemContent,
	FormSubTitle,
	TeamSection,
	SpinnerWrapper,
	NavigationItemInnerContainer,
	CustomHeader,
	ActionSection,
	SearchWrapper,
	LoaderWrapper,
};

export default Styled;
