import { DefaultThemeV2, css } from 'styled-components';

/**
 * Global Toast styles
 * Override the Sonner style to our own styling
 */
const ToastStyles = css<{ theme: DefaultThemeV2 }>`
	.cb-toast {
		--cb-toast-background: ${(props) => props.theme.colors.toasts.background};
		--cb-toast-border-color: ${(props) => props.theme.colors.toasts.border};
		--cb-toast-icon-color: ${(props) => props.theme.colors.toasts.iconColor};
		--cb-toast-color: ${(props) => props.theme.colors.toasts.color};
		--cb-toast-button-color: ${(props) => props.theme.colors.toasts.buttonColor};
		--cb-toast-button-background: ${(props) => props.theme.colors.toasts.buttonBackground};

		// error
		--cb-toast-error-background: ${(props) => props.theme.colors.toasts.error.background};
		--cb-toast-error-border-color: ${(props) => props.theme.colors.toasts.error.border};
		--cb-toast-error-icon-color: ${(props) => props.theme.colors.toasts.error.iconColor};
		--cb-toast-error-color: ${(props) => props.theme.colors.toasts.error.color};
		--cb-toast-error-button-color: ${(props) => props.theme.colors.toasts.error.buttonColor};
		--cb-toast-error-button-background: ${(props) => props.theme.colors.toasts.error.buttonBackground};

		display: flex;
		align-items: center;
		gap: 6px;
		border: 1px solid transparent;
		padding: 1rem;
		border-radius: ${({ theme }) => theme.radius.default};
		min-width: 434px;
		column-gap: 1rem;
		background-color: var(--cb-toast-background);
		border: 1px solid var(--cb-toast-border-color);
		color: var(--cb-toast-color);

		[data-button] {
			transition: all 0.2s;
			background-color: var(--cb-toast-button-background);
			border: 1px solid var(--cb-toast-button-color);
			color: var(--cb-toast-button-color);
			padding: 0.5rem;
			word-break: normal;
			white-space: nowrap;
			border-radius: ${({ theme }) => theme.radius.small};
			:hover {
				background-color: var(--cb-toast-button-color);
				color: var(--cb-toast-button-background);
				border-color: var(--cb-toast-button-background);
				cursor: pointer;
			}
		}

		p {
			margin-bottom: 0;
		}

		svg line {
			stroke: var(--cb-toast-icon-color);
		}

		// Error toasts
		&--error {
			background-color: var(--cb-toast-error-background);
			border: 1px solid var(--cb-toast-error-border-color);
			color: var(--cb-toast-error-color);

			[data-button] {
				background-color: var(--cb-toast-error-button-background);
				border-color: var(--cb-toast-error-button-color);
				color: var(--cb-toast-error-button-color);
				:hover {
					background-color: var(--cb-toast-error-button-color);
					color: var(--cb-toast-error-button-background);
					border-color: 1px solid var(--cb-toast-error-button-background);
				}
			}

			svg line {
				stroke: var(--cb-toast-error-icon-color) !important;
			}
		}

		// Success toasts
		&--success {
			[data-icon] {
				display: none;
			}
		}
	}

	[data-sonner-toast] [data-close-button].cb-toast__close {
		top: 50%;
		right: 8px;
		left: auto;
		transform: translateY(-50%);
		background-color: transparent;
		border-color: transparent;

		svg {
			width: 21px;
			height: 21px;
		}

		&:hover {
			background-color: transparent;
			border: transparent;
		}
	}

	[data-sonner-toast] [data-icon] {
		height: 24px;
		width: 24px;
	}

	[data-sonner-toast] [data-content] {
		row-gap: 0.25rem;
	}

	// Only make title bold if there is a description
	[data-sonner-toast] [data-title]:has(+ [data-description]) {
		font-weight: bold;
	}

	[data-sonner-toast] [data-description] strong {
		font-weight: bold;
	}
`;

export default ToastStyles;
