import styled from 'styled-components';

import { SocialProfileConnectorColors as colors } from 'styles/variables/colors/SocialProfileConnector';
import { px, rem } from 'styles/variables/spacings';

const Li = styled.li`
	background-color: ${colors.influencerBrick.background};
	list-style: none;
	border-radius: 10px;

	&:not(:last-child) {
		margin-bottom: ${px[1]}px;
	}
`;

const Row = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
	padding: ${rem[2]}rem;

	.toggle-switch {
		margin-left: auto;
	}
`;

const ProfilePicture = styled.div`
	border-radius: 50px;
	width: 40px;
	height: 40px;
	overflow: hidden;
`;

const Username = styled.div`
	color: ${colors.influencerBrick.color};
	padding-top: 0.1875rem;
	font-weight: 700;
`;

const Styled = {
	Li,
	Row,
	ProfilePicture,
	Username,
};

export default Styled;
