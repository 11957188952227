import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgExternalLink(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g id='svg-link-external' clipPath='url(#clip0_1166_24264)'>
				<path
					id='Vector'
					d='M16.062 7.18001C15.6522 7.18001 15.3123 7.52001 15.3123 7.93001V18.5H1.49925V4.67001H12.034C12.4438 4.67001 12.7836 4.33001 12.7836 3.92001C12.7836 3.51001 12.4438 3.17001 12.034 3.17001H0.89955C0.3998 3.17001 0 3.59001 0 4.11001V19.07C0 19.59 0.409795 20.01 0.89955 20.01H15.902C16.4018 20.01 16.8016 19.59 16.8016 19.07V7.93001C16.8016 7.52001 16.4618 7.18001 16.052 7.18001H16.062Z'
					fill='#333'
				/>
				<path
					id='Vector_2'
					d='M19.0505 0H11.1245C10.7147 0 10.3748 0.34 10.3748 0.75C10.3748 1.16 10.7147 1.5 11.1245 1.5H17.4413L9.27537 9.67C8.98552 9.96 8.98552 10.44 9.27537 10.73C9.4253 10.88 9.61521 10.95 9.80511 10.95C9.99502 10.95 10.1849 10.88 10.3348 10.73L18.5008 2.56V8.89C18.5008 9.3 18.8406 9.64 19.2504 9.64C19.6602 9.64 20 9.3 20 8.89V0.95C20 0.43 19.5702 0 19.0505 0Z'
					fill='#333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1166_24264'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
