import { useNavigate } from 'react-router-dom';

import Icon from 'components/Icon';
import Pill from 'components/Pill';
import { getInitials } from 'shared/User/User.helpers';
import { PublisherType } from 'views/admin/Components/Publishers/Components/PublisherOptions/PublisherOptions';
import { PublisherItemCardProps } from 'views/admin/Components/Publishers/types';

import Styled from './PublisherItemCard.style';

const PublisherItemCard = ({ publisher, clients }: PublisherItemCardProps) => {
	const navigate = useNavigate();

	const calculateRest = (clients: number) => {
		return clients > 5 ? clients - 5 : 0;
	};

	const getType = (kind: PublisherType) => {
		switch (kind) {
			case PublisherType.AGENCY:
				return 'Agency';
			case PublisherType.MEDIA_AGENCY:
				return 'Media agency';
			case PublisherType.DIRECT_CLIENT:
				return 'Direct client';
		}
	};

	return (
		<Styled.ClientCard onClick={() => navigate(`/admin/publishers/${publisher.id}`)}>
			<Styled.ClientName>{publisher.attributes?.name && <Pill title={getType(publisher.attributes?.kind)} className='client' />}</Styled.ClientName>

			<Styled.ClientCardTop>
				{publisher.links?.logo ? (
					<Styled.ClientLogo logo={publisher.links?.logo} />
				) : (
					<Styled.ClientLogo>
						<Styled.AddIcon role='button'>
							<Icon name='plus' size='12' />
						</Styled.AddIcon>
						<span>Add logo</span>
					</Styled.ClientLogo>
				)}
				<p>{publisher.attributes?.name}</p>
			</Styled.ClientCardTop>
			<Styled.ContainerWrapper>
				<Styled.BrandContainer>
					{clients &&
						clients?.length > 0 &&
						clients.slice(0, 5).map((client, key) => {
							return (
								<Styled.Brand key={key} logo={client.links?.logo}>
									{!client.links?.logo ? <Styled.IconContainer>{getInitials(client.attributes.name)}</Styled.IconContainer> : <Styled.Content />}
								</Styled.Brand>
							);
						})}
				</Styled.BrandContainer>
				<Styled.CalculateRest>
					{clients && calculateRest(clients.length) > 0 && (
						<>
							<Icon name='plus' size='12' /> <span style={{ marginLeft: '3px' }}>{calculateRest(clients.length)}</span>
						</>
					)}
				</Styled.CalculateRest>
			</Styled.ContainerWrapper>
		</Styled.ClientCard>
	);
};

export default PublisherItemCard;
