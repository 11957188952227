import ListsService from 'services/Lists/ListItems.service';
import { ICollabsResponse } from 'services/Response.types';

export const useListItemData = () => {
	const commentOnListItem = (url: string, comment: string) => {
		return ListsService.addCommentOnListItem(url, comment).then((response: ICollabsResponse) => {
			if (!response.errors) {
				return response;
			}
		});
	};
	const updateListItemComment = (url: string, comment: string) => {
		return ListsService.updateListItemComment(url, comment).then((response: ICollabsResponse) => {
			if (!response.errors) {
				return response;
			}
		});
	};
	const deleteListItemComment = (url: string) => {
		return ListsService.deleteOnListItem(url).then((response: ICollabsResponse) => {
			if (!response.errors) {
				return response;
			}
		});
	};
	const reactOnListItem = (url: string, reaction: string) => {
		return ListsService.addReactionOnListItem(url, reaction).then((response: ICollabsResponse) => {
			if (!response.errors) {
				return response;
			}
		});
	};
	const deleteListItemReaction = (url: string) => {
		return ListsService.deleteOnListItem(url).then((response: ICollabsResponse) => {
			if (!response.errors) {
				return response;
			}
		});
	};

	return {
		commentOnListItem,
		updateListItemComment,
		deleteListItemComment,
		reactOnListItem,
		deleteListItemReaction,
	};
};
