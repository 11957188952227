import { BarItem, Bar } from 'components/Discovery/Components/BarItem';
import colors from 'styles/variables/colors';

import Styled from './CountrySummaryList.style';
import { ICountrySummaryList, CountryData } from './types';

type CountrySummaryItem = {
	followers: number;
	name: string;
};

const CountrySummaryList = (props: ICountrySummaryList) => {
	let filteredCountries: CountrySummaryItem[] = [];

	if (props.items.length) {
		filteredCountries = props.items.sort((a: CountrySummaryItem, b: CountrySummaryItem) => b.followers - a.followers);
	}

	const getColors = (index: number) => {
		const colorSet = { containerColor: colors.borderGray, fillerColor: colors.black };
		switch (index) {
			case 0:
				colorSet.containerColor = colors.chart.country.first.light;
				colorSet.fillerColor = colors.chart.country.first.color;
				break;
			case 1:
				colorSet.containerColor = colors.chart.country.second.light;
				colorSet.fillerColor = colors.chart.country.second.color;
				break;
			case 2:
				colorSet.containerColor = colors.chart.country.third.light;
				colorSet.fillerColor = colors.chart.country.third.color;
				break;
			case 3:
				colorSet.containerColor = colors.chart.country.fourth.light;
				colorSet.fillerColor = colors.chart.country.fourth.color;
				break;
			case 4:
				colorSet.containerColor = colors.chart.country.fifth.light;
				colorSet.fillerColor = colors.chart.country.fifth.color;
				break;
			default:
				colorSet.containerColor = colors.buttonGray;
				colorSet.fillerColor = colors.gray5;
		}

		return colorSet;
	};

	if (props.isTopThree) {
		filteredCountries = filteredCountries.filter((_, index: number) => index < 3);
	}

	filteredCountries = filteredCountries.map((item, index) => {
		return { ...item, ...getColors(index) };
	});

	return (
		<Styled.Wrapper>
			{filteredCountries.length > 0 ? (
				filteredCountries.map((country: CountryData) => {
					const percent = `${Math.min(100, Math.floor((country.followers / props.totalFollowers) * 100))}%`;
					let value = 0;
					if (props.sign === 'K') {
						value = Math.floor(country.followers / 1000);
					} else if (props.sign === '%') {
						value = Math.floor((country.followers / props.totalFollowers) * 100);
					} else {
						value = country.followers;
					}
					return (
						<Styled.FlexItem key={country.name}>
							<BarItem label={country.alpha3code || country.name} value={value < 1 ? '< 1' : value} sign={props.sign}>
								<Bar percent={percent} containerBg={country.containerColor} fillerBg={country.fillerColor} />
							</BarItem>
						</Styled.FlexItem>
					);
				})
			) : (
				<Styled.CenteredWrapper>
					<span>N/A</span>
				</Styled.CenteredWrapper>
			)}
		</Styled.Wrapper>
	);
};

export default CountrySummaryList;
