import { css } from 'styled-components';

type Breakpoints = {
	xs: string;
	sm: string;
	md: string;
	lg: string;
	xl: string;
	xxl: string;
};

export const breakpoints: Breakpoints = {
	xs: '0',
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1200px',
	xxl: '1400px',
};

/**
 * @deprecated This function will be removed, use mediaQueries instead of this one.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const media = Object.keys(breakpoints).reduce((acc: any, label: string) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	acc[label] = (...args: any) => css`
		@media (min-width: ${breakpoints[label as keyof typeof breakpoints]}) {
			${args}
		}
	`;
	return acc;
}, {});

const breakpointToMediaQuery = (size: keyof typeof breakpoints) => (templateStrings: TemplateStringsArray) => css`
	@media (min-width: ${breakpoints[size]}) {
		${templateStrings}
	}
`;

const breakpointToMaxMediaQuery = (size: keyof typeof breakpoints) => (templateStrings: TemplateStringsArray) => css`
	@media (max-width: ${breakpoints[size]}) {
		${templateStrings}
	}
`;

/**
 * @deprecated use devices or devicesMax
 */
export const mediaQueries = {
	small: breakpointToMediaQuery('sm'),
	medium: breakpointToMediaQuery('md'),
	large: breakpointToMediaQuery('lg'),
	xl: breakpointToMediaQuery('xl'),
	xxl: breakpointToMediaQuery('xxl'),
};

/**
 * @deprecated use devices or devicesMax
 */
export const mediaMaxQueries = {
	small: breakpointToMaxMediaQuery('sm'),
	medium: breakpointToMaxMediaQuery('md'),
	large: breakpointToMaxMediaQuery('lg'),
	xl: breakpointToMaxMediaQuery('xl'),
	xxl: breakpointToMaxMediaQuery('xxl'),
};

const _breakpoints: Breakpoints = {
	xs: '320px',
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1200px',
	xxl: '1400px',
};

export const devices = {
	xs: `min-width: ${_breakpoints.xs}`,
	sm: `min-width: ${_breakpoints.sm}`,
	md: `min-width: ${_breakpoints.md}`,
	lg: `min-width: ${_breakpoints.lg}`,
	xl: `min-width: ${_breakpoints.xl}`,
	xxl: `min-width: ${_breakpoints.xl}`,
};

export const devicesMax = {
	xs: `max-width: ${_breakpoints.xs}`,
	sm: `max-width: ${_breakpoints.sm}`,
	md: `max-width: ${_breakpoints.md}`,
	lg: `max-width: ${_breakpoints.lg}`,
	xl: `max-width: ${_breakpoints.xl}`,
	xxl: `max-width: ${_breakpoints.xl}`,
};
