import Icon from 'components/Icon';
import { ModalSize } from 'components/Modals/Modal/types';

import Styled from './DataLibraryModal.style';

type DataLibraryModalProps = {
	isModalOpen: boolean;
	toggleModal: () => void;
	children: JSX.Element;
	footer?: JSX.Element | boolean;
	size: ModalSize;
	title?: string;
};
/**
 * @returns {JSX.Element}
 */
const DataLibraryModal = ({ isModalOpen, toggleModal, children, footer, size, title }: DataLibraryModalProps): JSX.Element => {
	return (
		<Styled.CustomModal size={size} open={isModalOpen} handleClose={toggleModal}>
			<Styled.CustomModalHeader>
				<Styled.ModalHeaderTitle>{title}</Styled.ModalHeaderTitle>
				<Styled.CloseContainer onClick={toggleModal}>
					<Icon name='cross' size='14' />
				</Styled.CloseContainer>
			</Styled.CustomModalHeader>
			<Styled.CustomModalBody>{children}</Styled.CustomModalBody>
			{footer && <Styled.CustomModalFooter>{footer}</Styled.CustomModalFooter>}
		</Styled.CustomModal>
	);
};

export default DataLibraryModal;
