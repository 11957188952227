import classNames from 'classnames';

import Styled from './ExpandableContentRight.style';

export type ExpandableContentProps = {
	children: JSX.Element;
	onCancel: () => void;
	drawerIsExpanded: boolean;
};

/**
 * Expandable content component
 * @returns {JSX.Element}
 */
const ExpandableContentRight = ({ children, drawerIsExpanded }: ExpandableContentProps): JSX.Element => {
	return (
		<Styled.ContentWrapper data-testid='content-right' className={classNames({ show: drawerIsExpanded })}>
			<Styled.Divider />
			<Styled.Section>
				<Styled.Content>{children}</Styled.Content>
			</Styled.Section>
		</Styled.ContentWrapper>
	);
};

export default ExpandableContentRight;
