import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { isNil } from 'lodash';

import { TokenPayload } from 'api-payloads';
import { CurrentUserQuery } from 'api-queries';
import { CurrentUserResponse } from 'api-responses';
import { KEY_COLLABS_API_TOKEN, KEY_COLLABS_API_USER_OBJECT, KEY_REAUTHENTICATED, KEY_TOKENS, LINK_TO_SHARE } from 'constants/localStorage-keys';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import AuthenticationManager from 'services/ApiManager/Authentication.manager';
import TokenStorageRegistry from 'services/Authentication/TokenStorageRegistry';
import { StatusCode } from 'services/Response.types';
import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import { GlobalUserObject, Hateoas } from './types';

import type UserApiClientInterface from 'services/ApiClient/UserApiClientInterface';

/**
 * Collabs Authentication Service
 */
@injectable()
class CollabsAuthService {
	storage: BrowserStorage;
	sessionStorage: BrowserStorage;
	type: 'influencer' | 'publisher' | undefined;
	client: AxiosInstance;
	manager: AuthenticationManager;
	userApiClient: UserApiClientInterface;

	constructor(
		@inject(AuthenticationManager) manager: AuthenticationManager,
		@inject(ApiClientService.UserApiClientInterface) userApiClient: UserApiClientInterface,
		@inject(TokenStorageRegistry) storageRegistry: TokenStorageRegistry,
	) {
		this.manager = manager;
		this.userApiClient = userApiClient;
		this.storage = storageRegistry.create(StorageType.LOCAL);
		this.sessionStorage = storageRegistry.create(StorageType.SESSION);
	}

	/**
	 * Get the token from the Collabs API and stores it in the localStorage
	 */
	requestCollabsToken = async (credentials: TokenPayload): Promise<StatusCode> => {
		const token = await this.manager.createToken(credentials);

		this.setCollabsToken(token.attributes.token);
		this.setAuthenticationDate();

		return StatusCode.OK;
	};

	/**
	 * These are the default includes we use for the `me()` endpoint if we are getting data
	 * for the global user. Ie `dispatch(setUser(user));`
	 *
	 * ":*" means "include everything" for "user". This is used in our security checks
	 */
	getGlobalUserIncludes = (): string[] => {
		return [':*', 'instagramBusinessAccounts', 'activeDashboard', 'influencers:profilePictureUrl', 'publishers'];
	};

	me = async (includes: string[] = []): Promise<CurrentUserResponse> => {
		let qb = RequestQueryBuilder.create<CurrentUserQuery>();
		for (const include of includes) {
			const [first, second] = include.split(':', 2);
			const hateoas = second?.split(',') ?? undefined;

			qb = qb.withInclude(first, hateoas);
		}

		return await this.userApiClient.current(qb.toQuery());
	};

	agreeTermsAndCondition = async (type: 'publisher' | 'influencer') => {
		let result = 0;
		let promise = null;
		if (type === 'publisher') {
			promise = this.manager.acceptPublisherTermsOfConditions();
		} else {
			promise = this.manager.acceptInfluencerTermsOfConditions();
		}

		await promise
			.then(() => {
				result = StatusCode.OK;
			})
			.catch((error: AxiosResponse) => {
				if (axios.isAxiosError(error)) {
					result = error.status;
					throw Error(error.message);
				}

				console.error(error);
			});

		return result;
	};

	/**
	 * Set token in localStorage
	 * @param {string} token
	 */
	setCollabsToken = (token: string) => {
		const tokens = this.storage.getItem(KEY_TOKENS) || '';
		if (tokens !== '') {
			const parsedToken = JSON.parse(tokens);
			parsedToken[KEY_COLLABS_API_TOKEN] = token;
			this.storage.setItem(KEY_TOKENS, JSON.stringify(tokens));
		} else {
			const collabsToken: { [key: string]: string } = {};
			collabsToken[KEY_COLLABS_API_TOKEN] = token;
			this.storage.setItem(KEY_TOKENS, JSON.stringify(collabsToken));
		}
	};

	/**
	 * Update token in localStorage
	 * @param {string} token
	 */
	updateCollabsToken = (token: string) => {
		const tokens = this.storage.getItem(KEY_TOKENS);
		if (tokens) {
			const parsedToken = JSON.parse(tokens);
			parsedToken[KEY_COLLABS_API_TOKEN] = token;
			return this.storage.setItem(KEY_TOKENS, JSON.stringify(parsedToken));
		}
	};

	/**
	 * Set token in sessionStorage
	 * @param {string} token
	 */
	setDataLibraryTokenInSession = (token: string) => {
		const tokens = this.sessionStorage.getItem(KEY_TOKENS) || '';
		if (tokens !== '') {
			const parsedToken = JSON.parse(tokens);
			parsedToken[KEY_COLLABS_API_TOKEN] = token;
			this.sessionStorage.setItem(KEY_TOKENS, JSON.stringify(parsedToken));
		} else {
			const collabsToken: { [key: string]: string } = {};
			collabsToken[KEY_COLLABS_API_TOKEN] = token;
			this.sessionStorage.setItem(KEY_TOKENS, JSON.stringify(collabsToken));
		}
	};

	/**
	 * Set token in sessionStorage
	 * @param {string} token
	 */
	setLinkInInSession = (link: string) => {
		this.sessionStorage.setItem(LINK_TO_SHARE, link);
	};

	/**
	 * Get token from localStorage
	 */
	getCollabsToken = () => {
		return this.storage.getItem(KEY_TOKENS);
	};

	/**
	 * Get token from sessionStorage
	 */
	getDataLibraryToken = () => {
		return this.sessionStorage.getItem(KEY_TOKENS);
	};

	/**
	 * Get link from sessionStorage
	 */
	getLink = () => {
		return this.sessionStorage.getItem(LINK_TO_SHARE);
	};

	/**
	 * Remove token from localStorage
	 */
	removeCollabsToken = () => {
		this.storage.removeItem(KEY_TOKENS);
	};

	/**
	 * Remove token from sessionStorage
	 */
	removeDataLibraryToken = () => {
		this.sessionStorage.removeItem(KEY_TOKENS);
	};

	/**
	 * Remove link from sessionStorage
	 */
	removeLink = () => {
		this.sessionStorage.removeItem(LINK_TO_SHARE);
	};

	/**
	 * Set User object in localStorage
	 * @deprecated use the usePermission hook instead of this one
	 * @param {GlobalUserObject} object
	 */
	setCollabsUserObject = (object: GlobalUserObject) => {
		this.storage.setItem(KEY_COLLABS_API_USER_OBJECT, JSON.stringify(object));
	};

	/**
	 * Get User object from localStorage
	 */
	getCollabsUserObject = (): GlobalUserObject => {
		const loggedInUser = JSON.parse(this.storage.getItem(KEY_COLLABS_API_USER_OBJECT) || '{}');
		// To make sure we logout legacy sessions
		if (typeof loggedInUser.permissions === 'object' && loggedInUser.permissions !== null) {
			return loggedInUser;
		}

		this.panicLogout();
		return {} as GlobalUserObject;
	};

	/**
	 * Something is really wrong. Just logout the user and hope for the best.
	 *
	 * @deprecated Use LogoutService::panicLogout() instead
	 */
	panicLogout = (clear = true, keys = ['tokens', 'c_user', 'userIsVerified', 'idVerification', 'pinebucket']) => {
		if (clear) {
			this.clearAll();
		} else {
			keys.forEach((key) => localStorage.removeItem(key));
			this.sessionStorage.removeItem(KEY_TOKENS);
			this.sessionStorage.removeItem(LINK_TO_SHARE);
		}

		window.location.href = '/login';
	};

	isPublisher(): boolean {
		if (isNil(this.getCollabsUserObject())) {
			return false;
		}

		const { permissions } = this.getCollabsUserObject();
		if (isNil(permissions)) {
			return false;
		}

		for (const id in permissions.entities) {
			const { type, role } = permissions.entities[id];

			if ('publisher' === type && 'administrator' === role) {
				return true;
			}
		}
		return false;
	}

	/**
	 * hateoasPermission
	 * @deprecated use the usePermissions hook instead of this one
	 * @param {string} hateoasName
	 * @returns {string}
	 */
	hateoasPermission = (hateoasName: string) => {
		const userObject = this.getCollabsUserObject();

		if (userObject.permissions && userObject.permissions.hateoas === undefined) {
			return false;
		}

		return userObject.permissions && userObject.permissions.hateoas && userObject.permissions.hateoas[hateoasName as keyof Hateoas];
	};

	/**
	 * Remove token from localStorage
	 */
	clearAll = () => {
		this.storage.clear();
		this.sessionStorage.clear();
	};

	/**
	 * Set reauthenticate
	 */
	setAuthenticationDate = () => {
		this.storage.setItem(KEY_REAUTHENTICATED, JSON.stringify(new Date()));
	};
}

export default CollabsAuthService;
