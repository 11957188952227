import Styled from './UserListCard.style';
import { UserListCardProps } from './types';

const UserListCard = ({ text, title, children }: UserListCardProps) => {
	return (
		<Styled.StyledAccordion title={title} plusCloseIcon open>
			{text}
			<Styled.InviteWrapper>{children}</Styled.InviteWrapper>
		</Styled.StyledAccordion>
	);
};

export default UserListCard;
