import {
	FEATURE_FLAG_FACEBOOK_AUTH,
	FEATURE_FLAG_TIKTOK_CONNECTOR_IN_SETTINGS,
	FEATURE_FLAG_ONBOARDING_VIEW,
	FEATURE_FLAG_SIGNUP_REQUIRE_NETWORK,
	FEATURE_FLAG_FORCE_CONNECT,
	FEATURE_FLAG_CAMPAIGN_SIDEDRAWER,
	FEATURE_FLAG_SEGMENTS,
	FEATURE_FLAG_MEDIA_AGENCY_FEE,
	FEATURE_FLAG_CAMPAIGN_BUDGET,
	FEATURE_FLAG_INFLUENCER_TABLE_SORT,
} from 'constants/feature-flag-keys';

export const enabledFeatures = [
	{ name: FEATURE_FLAG_FACEBOOK_AUTH, enabled: process.env.VITE_APP_FEATURE_FACEBOOK_AUTH },
	{ name: FEATURE_FLAG_TIKTOK_CONNECTOR_IN_SETTINGS, enabled: process.env.VITE_APP_TIKTOK_CONNECTOR_IN_SETTINGS },
	{ name: FEATURE_FLAG_ONBOARDING_VIEW, enabled: process.env.VITE_APP_FEATURE_FLAG_ONBOARDING_VIEW },
	{ name: FEATURE_FLAG_SIGNUP_REQUIRE_NETWORK, enabled: process.env.VITE_APP_FEATURE_FLAG_SIGNUP_REQUIRE_NETWORK },
	{ name: FEATURE_FLAG_FORCE_CONNECT, enabled: process.env.VITE_APP_FEATURE_FLAG_FORCE_CONNECT },
	{ name: FEATURE_FLAG_CAMPAIGN_SIDEDRAWER, enabled: process.env.VITE_APP_FEATURE_FLAG_CAMPAIGN_SIDEDRAWER },
	{ name: FEATURE_FLAG_SEGMENTS, enabled: process.env.VITE_APP_FEATURE_FLAG_SEGMENTS },
	{ name: FEATURE_FLAG_MEDIA_AGENCY_FEE, enabled: process.env.VITE_APP_FEATURE_FLAG_MEDIA_AGENCY_FEE },
	{ name: FEATURE_FLAG_CAMPAIGN_BUDGET, enabled: process.env.VITE_APP_FEATURE_FLAG_CAMPAIGN_BUDGET },
	{ name: FEATURE_FLAG_INFLUENCER_TABLE_SORT, enabled: process.env.VITE_APP_FEATURE_FLAG_INFLUENCER_TABLE_SORT },
];
