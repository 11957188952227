export const FEATURE_FLAG_INTEGRATED_INBOX = 'IntegratedInbox';
export const FEATURE_FLAG_NEW_INTEGRATED_INBOX = 'integratedInbox';

export const FEATURE_FLAG_FACEBOOK_AUTH = 'FacebookAuth';

export const FEATURE_FLAG_DOWNLOAD_MEDIA = 'DownloadImage';

export const FEATURE_FLAG_TIKTOK_INTEGRATION = 'TikTokIntegration';

export const FEATURE_FLAG_TIKTOK_CONNECTOR_IN_SETTINGS = 'TikTokConnectorInSettings';
export const FEATURE_FLAG_ONBOARDING_VIEW = 'OnboardingView';
export const FEATURE_FLAG_NEW_CREATE_CAMPAIGN = 'NewCreateCampaign';

export const FEATURE_FLAG_SIGNUP_REQUIRE_NETWORK = 'SignupRequireNetwork';

export const FEATURE_FLAG_NEW_BRIEF_PAGE = 'NewBrief';

export const FEATURE_FLAG_FORCE_CONNECT = 'ForceConnect';

export const FEATURE_FLAG_CAMPAIGN_SIDEDRAWER = 'CampaignSideDrawer';
export const FEATURE_FLAG_SEGMENTS = 'Segments';

export const FEATURE_FLAG_MEDIA_AGENCY_FEE = 'MediaAgencyFee';

export const FEATURE_FLAG_CAMPAIGN_BUDGET = 'CampaignBudget';

export const FEATURE_FLAG_INFLUENCER_TABLE_SORT = 'InfluencerTableSort';
