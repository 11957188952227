import { CroppedImages } from 'components/NewBriefPage/types';

export enum KIND {
	CAMPAIGN_COVER_IMAGE = 'campaignCoverImage',
	CAMPAIGN_IMAGE = 'campaignImage',
}

export type EditableImageProps = {
	isFeatureEnabled: boolean;
	grantedForEditing: boolean;
	moodboardImageUrl?: string;
	coverImages?: CroppedImages;
	coverImage?: string;
	className?: string;
	cropperOptions: Cropper.Options;
	kind: KIND;
	deleteImage?: () => void;
	deleteImageWithToast?: () => void;
	isDeletingImage?: boolean;
	isUploading?: boolean;
	sortOrder?: number;
	total?: number;
	onlyOne?: boolean;
	getBriefData?: () => void;
	loadingBriefData?: boolean;
	isVisible?: boolean;
	onHide?: () => void;
	modalTestId?: string;
};

export type RouteParams = {
	campaignId: string;
};

export type CropDimensionsType = {
	x: number;
	y: number;
	cropWidth: number;
	cropHeight: number;
	[key: string]: number;
};
