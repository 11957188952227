/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { UpdateCampaignBudgetPayload, CreateCampaignBudgetPayload } from 'api-payloads';
import { CampaignBudgetResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import CampaignBudgetApiClientInterface from './CampaignBudgetApiClientInterface';

@injectable()
export default class CampaignBudgetApiClient implements CampaignBudgetApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Delete campaign budget
	 */
	async delete(id: string): Promise<CampaignBudgetResponse> {
		const response = await this.client.delete<CampaignBudgetResponse>(`/campaign-budgets/${id}`);
		return response.data;
	}

	/**
	 * Update campaign budget
	 */
	async update(id: string, payload: UpdateCampaignBudgetPayload, params: { include?: string }): Promise<CampaignBudgetResponse> {
		const response = await this.client.patch<CampaignBudgetResponse>(`/campaign-budgets/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * Create campaign budget
	 */
	async create(id: string, payload: CreateCampaignBudgetPayload, params: { include?: string }): Promise<CampaignBudgetResponse> {
		const response = await this.client.post<CampaignBudgetResponse>(`/campaigns/${id}/budgets`, payload, { params });
		return response.data;
	}
}
