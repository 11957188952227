import useSWR from 'swr';

import { CollectionResponse } from 'types/http';

import useHttpClient from './useHttpClient';

/**
 * @deprecated use an API manager instead
 */
export default function useRestCollection<T>(url: string) {
	const client = useHttpClient();
	const fetcher = async (url: string) => {
		const { data } = await client.get<CollectionResponse<T>>(url);

		return data;
	};

	const { data, error } = useSWR(url, fetcher);

	return {
		data,
		error,
		loading: !data && !error,
	};
}
