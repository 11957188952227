import { PALETTE } from 'styles/variables/original-colors';

export const SocialProfileConnectorColors = {
	influencerBrick: {
		background: PALETTE.concrete,
		color: PALETTE.black,
	},
	connectButton: {
		background: PALETTE.blizzyBlueberry,
		color: PALETTE.rawWhite,
		border: PALETTE.blizzyBlueberry,
	},
	helpLink: {
		color: PALETTE.blizzyBlueberry,
	},
	disconnect: {
		iconCircle: PALETTE.black,
	},
	SocialMediaPlatformButton: {
		border: PALETTE.argent,
	},
	SuccessMessage: {
		background: PALETTE.concret,
		borderColor: PALETTE.taupeGrey,
	},
};
