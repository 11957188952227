import { CSVLink } from 'react-csv';

import { GhostButton } from 'components/Button';

import Styled from './DataToCSV.styled';

export type DataToCSVProps = {
	shouldButtonBeEnabled: boolean;
	headers: Array<string>;
	data: Array<{}>;
	filename: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getCsvData: (event: any, done: any) => void;
};

/**
 * Wrapper for react-csv component
 * @returns {JSX.Element}
 */
const DataToCSV = (props: DataToCSVProps): JSX.Element => {
	const { shouldButtonBeEnabled, headers, data, filename, getCsvData } = props;

	return (
		<Styled.DataToCSV>
			<GhostButton size='sm' disabled={!shouldButtonBeEnabled}>
				{shouldButtonBeEnabled && (
					<CSVLink data={data} headers={headers} filename={filename} asyncOnClick={true} onClick={getCsvData}>
						Download CSV
					</CSVLink>
				)}
				{!shouldButtonBeEnabled && <span>No data...</span>}
			</GhostButton>
		</Styled.DataToCSV>
	);
};

export default DataToCSV;
