import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Store } from 'json-api-models';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { LoadingSpinner, LoginAsButton } from 'components';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import InfluencerAvatar from 'components/InfluencerAvatar';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { SideDrawer } from 'components/SideDrawer';
import Table from 'components/Table/V2/Table';
import { Network } from 'constants/socialMedia';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import { isSuccessfulResponse } from 'services/Response.types';
import toast from 'services/Toast';
import UserService from 'services/User';
import { InfluencerUser, UserInfluencerProfile } from 'shared/Types/User';
import Styled from 'views/admin/Components/Table.style';
import UserDetails from 'views/admin/Components/Users/UserDetails';
import { PaymentInfo } from 'views/admin/Components/Users/types';

import PaymentsTable from './PaymentsTable';

/**
 * @returns {JSX.Element}
 */
const InfluencerTable = (props: { data: InfluencerUser[]; search: () => void }): JSX.Element => {
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState<InfluencerUser>();
	const [paymentInfo, setPaymentInfo] = useState<Array<PaymentInfo>>([]);

	const navigate = useNavigate();

	const disableUser = () => {
		setIsLoading(true);
		selectedUser?.links?.disable &&
			UserService.disableUser(selectedUser?.links?.disable)
				.then((res) => {
					if (isSuccessfulResponse(res.status)) {
						setIsLoading(false);
						setIsModalOpen(false);
						toast.success('User disabled');
					}
				})
				.catch((error: AxiosError) => {
					console.error(error);
					toast.error(getErrorMessageOnPost('disabling the user'));
				});
	};

	const columns: Column<InfluencerUser>[] = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'instagramUsername',
				Cell: ({ row }) =>
					row.original.influencers?.map((influencer: UserInfluencerProfile, index) => (
						<Styled.AvatarWrapper key={index}>
							<InfluencerAvatar
								userName={influencer.username}
								network={influencer.network as Network}
								profileImageUrl={influencer.links?.profilePictureUrl ?? ''}
								displayNetwork
							/>
							<Styled.Title key={influencer.id} className={classNames({ disabled: row.original.disabledAt })}>
								{influencer.username}
								{row.original.disabledAt && <div>Disabled at {moment(row.original.disabledAt).format('YYYY-MM-DD')}</div>}
							</Styled.Title>
						</Styled.AvatarWrapper>
					)),
			},
			{
				Header: 'Email',
				accessor: 'email',
			},
			{
				Header: 'Payment type',
				accessor: 'kind',
			},
			{
				Header: '',
				accessor: 'id',
				width: 50,
				disableSortBy: true,
				Cell: ({ row }) => <LoginAsButton uuid={row.original.uuid} />,
			},
			{
				Header: '',
				accessor: 'uuid',
				width: 50,
				disableSortBy: true,
				Cell: ({ row }) =>
					!row.original.disabledAt && (
						<Dropdown icon='options' size='16'>
							<DropdownMenu>
								<DropdownItem
									onClick={() => {
										setIsModalOpen(true), setSelectedUser(row.original);
									}}
								>
									<Styled.Text>Disable User</Styled.Text>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					),
			},
		],
		[],
	);

	const handleSelectedRow = (values: InfluencerUser) => {
		setSelectedUser(values);
		navigate(`/admin/influencers/${values.id}`);
		setIsSideDrawerOpen(true);
	};

	useEffect(() => {
		setIsSideDrawerOpen(selectedUser ? true : false);
		if (selectedUser) {
			setIsLoading(true);
			UserService.getPayments(selectedUser.id)
				.then((res) => {
					const models = new Store();
					models.sync(res.data);
					const paymentData = models.findAll('userPayout');
					setPaymentInfo(paymentData);
				})
				.catch((error: AxiosError) => {
					console.error(error);
					toast.error(getErrorMessageOnPost('fetching the payment info'));
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [selectedUser]);

	return (
		<>
			<Table
				className='scroll-y admin-table'
				columns={columns}
				data={props.data}
				enableSorting
				getSelectedRow={(selectedRow) => handleSelectedRow(selectedRow)}
			/>
			<ConfirmModal
				isModalOpen={isModalOpen}
				toggleModal={() => {
					setIsModalOpen(!isModalOpen), setSelectedUser(undefined);
				}}
				title={'Disable user'}
				isFetching={isLoading}
				action={disableUser}
				buttonText={'Disable'}
				confirmButtonAsDanger
			>
				<p>Are you sure that you want to disable {selectedUser?.name}?</p>
			</ConfirmModal>
			{selectedUser && (
				<SideDrawer
					sidebarIsOpen={isSideDrawerOpen}
					onClose={() => {
						setIsSideDrawerOpen(false), navigate('/admin/influencers'), setIsExpanded(false);
					}}
					dataTestId={''}
					title={selectedUser?.name}
					isExpandable
					saveButtonText='Save'
					expandedContent={isLoading ? <LoadingSpinner /> : <PaymentsTable paymentInfo={paymentInfo} />}
					isExpanded={isExpanded}
					setNotExpanded={() => setIsExpanded(false)}
					onDone={() => setIsExpanded(false)}
					canOnlyCloseModal
				>
					<Styled.UserDetailsWrapper>
						<UserDetails item={selectedUser!} setIsExpanded={setIsExpanded} payments={paymentInfo} />
					</Styled.UserDetailsWrapper>
				</SideDrawer>
			)}
		</>
	);
};
export default InfluencerTable;
