import styled from 'styled-components';

import ChartCardStyled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { Table, TableHeader, TableBody, Th, Tr, Td } from 'styles/table';
import colors from 'styles/variables/colors';

const Wrapper = styled.div``;

const ListTable = styled.table`
	${Table};
	overflow: hidden;
	margin-bottom: 1rem;
`;

const ListTableHead = styled.thead`
	${TableHeader};
`;

const ListTableBody = styled.tbody`
	${TableBody};
`;

const ListTableTh = styled.th`
	${Th};
	white-space: break-spaces;
`;

const ListTableTr = styled.tr`
	${Tr};
`;

const ListTableTd = styled.td`
	${Td};
`;

const TableHeaderInnerWrapper = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
`;

const Label = styled.div`
	margin-right: 5px;
`;

const LabelWrapper = styled.p`
	display: flex;
	flex-direction: column;
	margin-bottom: 0;
	line-height: 1;
`;

const LabelTextWrapper = styled.span`
	&.text-sm {
		font-size: 0.85rem;
	}
	line-height: 1;
`;

const SortIconWrapper = styled.div`
	display: inline-flex;
	flex-direction: column;
	row-gap: 0.1rem;

	& > div {
		&.arrow-up {
			width: 0;
			height: 0;
			border-left: 5px solid ${colors.campaignOverview.transparent};
			border-right: 5px solid ${colors.campaignOverview.transparent};
			border-bottom: 5px solid ${colors.campaignOverview.gray};
		}

		&.arrow-down {
			width: 0;
			height: 0;
			border-left: 5px solid ${colors.campaignOverview.transparent};
			border-right: 5px solid ${colors.campaignOverview.transparent};
			border-top: 5px solid ${colors.campaignOverview.gray};
		}

		&.isActive {
			border-color: ${colors.campaignOverview.black};
			border-left: 5px solid ${colors.campaignOverview.transparent};
			border-right: 5px solid ${colors.campaignOverview.transparent};
		}
	}
`;

const InnerCenteredWrapper = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: auto;
`;

const CustomDropDownMenuItem = styled(ChartCardStyled.CustomDropDownMenuItem)``;

const IconMargin = styled(ChartCardStyled.IconMargin)``;

const Styled = {
	InnerCenteredWrapper,
	Wrapper,
	ListTable,
	ListTableHead,
	ListTableBody,
	ListTableTh,
	ListTableTr,
	ListTableTd,
	TableHeaderInnerWrapper,
	SortIconWrapper,
	Label,
	LabelWrapper,
	LabelTextWrapper,
	CustomDropDownMenuItem,
	IconMargin,
};

export default Styled;
