import styled, { DefaultThemeV2 } from 'styled-components';

import newColors from 'styles/theme/colors';
import boxShadow from 'styles/variables/box-shadows';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';

type P = { theme: DefaultThemeV2 };

const NameTagWrapper = styled.div`
	display: flex;
	background-color: #f2f2f2d9;
	backdrop-filter: blur(3px);
	border-radius: ${({ theme }) => theme.radius.small};
	padding: ${spacing[1].rem};
	position: absolute;
	top: 10px;
	left: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 3;
`;

const BrandTagWrapper = styled.div`
	display: flex;
	width: 100%;
	border-radius: ${({ theme }) => theme.radius.small};
	padding: ${spacing[1].rem} ${spacing[2].rem};
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 3;
`;

const NameTag = styled.div`
	display: flex;
	color: ${newColors.smoke};
	padding: ${spacing[1].rem};
	max-width: calc(100% - 42px);
	flex-grow: 1;
	align-items: center;

	span {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 0.875rem;
		font-weight: 600;
		line-height: 1.3;
	}
`;

const BrandTag = styled(NameTag)`
	color: ${({ theme }: P) => theme.colors.secondary};
	padding-top: ${spacing[2].rem};
`;

const Card = styled.div<{ sm: string; md: string; lg: string }>`
	flex: 1;
	background-color: ${colors.campaignCard.backgroundColor};
	overflow: hidden;
	background-image: image-set(url(${(p) => p.md}) 1x, url(${(p) => p.lg}) 2x);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border-radius: ${({ theme }) => theme.radius.default};
	padding: ${spacing[2].rem};
	flex-direction: row;
	width: 100%;
	min-height: 250px;
	height: 100%;
	transition: all 0.5s;
	filter: brightness(75%);
`;

const CardWrapper = styled.div`
	position: relative;
	flex: 1;
	overflow: hidden;
	border-radius: ${({ theme }) => theme.radius.default};
	min-height: 270px;

	&.first {
		height: 100%;
	}

	&:hover {
		box-shadow: ${boxShadow.medium};
		${Card} {
			cursor: pointer;
			transform: scale(1.2);
		}
	}
	@media only screen and (${devices.md}) {
		min-height: 270px;
		min-width: 350px;
	}
`;

const Divider = styled.div`
	width: 2px;
	background-color: ${newColors.dust};
	margin-right: 8px;
`;

const Styled = {
	Card,
	NameTag,
	NameTagWrapper,
	Divider,
	CardWrapper,
	BrandTagWrapper,
	BrandTag,
};

export default Styled;
