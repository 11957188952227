import { Field } from 'formik';
import styled from 'styled-components';

import { TextareaField, TextareaFieldContainer, InputFieldContainer } from 'styles/formElements/textarea';
import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

export type InputType = {
	disabled?: boolean;
};

const Textarea = styled(Field)<InputType>`
	${TextareaField};

	&.upload-content {
		overflow-y: scroll;
		${scrollbarY};
	}

	&.campaign-form {
		border-radius: 4px;
		padding: ${guttersWithRem.xxs};
		background-color: ${colors.createCampaign.textareaBackground};
		max-height: 400px;

		overflow-y: scroll;
		${scrollbarY};
	}
`;

const TextareaContainer = styled.div`
	${TextareaFieldContainer};
`;

const InputContainer = styled.div<InputType>`
	${InputFieldContainer};
`;

const ErrorMessage = styled.p`
	font-size: 0.75rem;
	color: ${colors.error};
	padding-top: 0;
`;

const Styled = {
	Textarea,
	InputContainer,
	TextareaContainer,
	ErrorMessage,
};

export default Styled;
