import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { BrandModel, ClientModel, PublisherModel } from 'api-models';
import CustomDropZone from 'components/Form/Elements/CustomDropZone/CustomDropZone';
import { DELETE_LOGO } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import toast from 'services/Toast';

type Props = {
	uploadFn: (file: File) => Promise<string | undefined>;
	deleteImageFn: () => void;
	item: PublisherModel | BrandModel | ClientModel;
	canEdit: boolean;
};

/**
 * @returns {JSX.Element}
 */
const LogoUpload = ({ uploadFn, deleteImageFn, item, canEdit }: Props): JSX.Element => {
	const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

	const { userCan } = useFeaturePermissions(item.links);

	const onDrop = useCallback((acceptedFiles: File[]) => {
		uploadFn &&
			uploadFn(acceptedFiles[0])
				.then((res) => {
					toast.success('Logo uploaded');
					setImageUrl(res);
				})
				.catch((e: AxiosError) => {
					console.error(e);
					toast.error(getErrorMessageOnPost('uploading the logo'));
				});
	}, []);

	useEffect(() => {
		if (item.links?.logo) {
			setImageUrl(item.links.logo);
		} else {
			setImageUrl(undefined);
		}
	}, [item]);

	return (
		<CustomDropZone
			deleteImage={deleteImageFn}
			remove={() => setImageUrl(undefined)}
			handleOnDrop={onDrop}
			imageUrl={imageUrl}
			title='Drop your logo here'
			isOnSettings
			canEdit={canEdit}
			canDelete={userCan(DELETE_LOGO)}
		/>
	);
};

export default LogoUpload;
