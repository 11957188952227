import { DefaultTheme, StyledComponent } from 'styled-components';

import Styled from './Button.style';
import { IButtonProps } from './types';

/**
 * Button Component
 * @param {JSX.Element} Component
 * @param {IButtonProps} props
 * @returns {JSX.Element}
 */
const ButtonMapping = (Component: StyledComponent<'button', DefaultTheme, IButtonProps, never>, props: IButtonProps) => {
	const { children, ...rest } = props;
	const button = (
		<Component ref={props.btnRef} {...rest}>
			{children}
		</Component>
	);
	return button;
};

export const Button = (props: IButtonProps) => ButtonMapping(Styled.Button, props);

export const SecondaryButton = (props: IButtonProps) => ButtonMapping(Styled.SecondaryButton, props);

// the new one
export const ButtonSecondary = (props: IButtonProps) => ButtonMapping(Styled.ButtonSecondary, props);

export const DarkButton = (props: IButtonProps) => ButtonMapping(Styled.DarkButton, props);

export const GhostButton = (props: IButtonProps) => ButtonMapping(Styled.GhostButton, props);

export const LinkButton = (props: IButtonProps) => ButtonMapping(Styled.LinkButton, props);

export const BlueButton = (props: IButtonProps) => ButtonMapping(Styled.BlueButton, props);
