import { useState } from 'react';

import { GhostButton } from 'components/Button';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import DeleteModal from 'components/Modals/DeleteModal';

import { DangerZoneProps } from './types';

const DangerZone = ({ infoText, isLoading, deleteModalTitle, deleteButtonText, action }: DangerZoneProps) => {
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

	return (
		<>
			<GhostButton onClick={() => setModalIsOpen(true)} size='sm'>
				{isLoading ? (
					<LoadingSpinner size='sm' />
				) : (
					<>
						<Icon name='trash-bin' size='20' />
						{deleteButtonText}
					</>
				)}
			</GhostButton>
			<DeleteModal toggleModal={() => setModalIsOpen(!modalIsOpen)} title={deleteModalTitle} isModalOpen={modalIsOpen} isFetching={isLoading} action={action}>
				<>
					<p>{infoText}</p>
				</>
			</DeleteModal>
		</>
	);
};

export default DangerZone;
