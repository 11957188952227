import styled from 'styled-components';

import { NavigationWrapper, Navigation } from 'components/Navigation/Navigation.style';
import colors from 'styles/variables/colors';
import { spacing } from 'styles/variables/spacings';

const Hr = styled.hr`
	border-top: 1px solid ${colors.borderAlmostLightGray};
	margin: ${spacing[3].px} 0;
`;

const Styled = {
	NavigationWrapper,
	Navigation,
	Hr,
};

export default Styled;
