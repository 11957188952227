import { filter, get, isNil, reverse, sortBy } from 'lodash';

import { InfluencerListItemType } from 'contexts/Discovery/types';

export enum SORT_ORDER {
	ASC,
	DESC,
}

export const SORT_TABLE_FIELDS = [
	{ property: 'followersCount', label: 'Followers' },
	{ property: 'engagement', label: 'Engagement' },
	{ property: 'audienceBrief.age.ratio', label: 'Audience age' },
	{ property: 'audienceBrief.country.ratio', label: 'Audience location' },
	{ property: 'audienceBrief.gender.ratio', label: 'Audience gender' },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortListHandler = (list: any[], sortProperty: string, sortOrder: SORT_ORDER) => {
	const validList = filter(list, sortProperty);
	const valueNullList = list.filter((item) => !validList.some((validItem) => validItem.id === item.id));

	let sortedList = sortBy(list, sortProperty);

	if (SORT_ORDER.DESC === sortOrder) {
		sortedList = filter(reverse(sortedList), sortProperty);
		sortedList = sortedList.concat(valueNullList);
	}

	return sortedList;
};

export const getGenderLabel = (gender: string) => {
	switch (gender) {
		case 'female':
			return 'WOMEN';
		case 'male':
			return 'MEN';
		default:
			return '';
	}
};

export const atLeastOneHasValue = (list: InfluencerListItemType[], property: string): boolean => {
	for (const item of list) {
		if (!isNil(get(item, property))) {
			return true;
		}
	}

	return false;
};
