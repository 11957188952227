import { UnknownAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';

import DIContainer from 'DependencyInjection.ts';
import MercureService from 'services/Mercure/mercure.service';

const mercureMiddleware =
	({ dispatch }: MiddlewareAPI) =>
	(next: Dispatch<UnknownAction>) =>
	(action: UnknownAction) => {
		switch (action.type) {
			case 'CONNECT_TO_MERCURE':
				DIContainer.get(MercureService).startSubscription(dispatch);
				break;
			case 'DISCONNECT_FROM_MERCURE':
				DIContainer.get(MercureService).stopSubscription();
				break;
			default:
				break;
		}

		return next(action);
	};

export default mercureMiddleware;
