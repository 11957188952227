import classNames from 'classnames';
import { FormikErrors } from 'formik';
import { useEffect } from 'react';

import { Scenario } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { breakpoints } from 'styles/variables/media-queries';

import Styled from './Topbar.style';

type SubmitSectionProps = {
	errors: FormikErrors<unknown> | undefined;
	title?: string;
	files?: number | undefined;
	storyFrames?: number | undefined;
	submitAction: () => void;
	isLoading: boolean;
	buttonText: string;
	buttonIcon?: string;
	testId?: string;
	buttonDisabled?: boolean;
	hideErrors?: boolean;
	className?: string;
	scenario?: Scenario;
	isValid?: boolean;
	isManualEditMode?: boolean;
};

const SubmitSection = (props: SubmitSectionProps) => {
	const { width } = useWindowDimensions();
	const isMobileView = width <= +breakpoints.lg.split('px')[0];

	const displayErrors = () => {
		return (
			props.errors &&
			Object.entries(props.errors).map(([key, value], i) => {
				const element = document.getElementsByName(key)[0];

				if (typeof value === 'string') {
					return (
						<Styled.ErrorMessage
							key={i}
							onClick={() => {
								//@ts-ignore
								element?.focus({ focusVisible: true }),
									element?.scrollIntoView({
										block: 'start',
										behavior: 'smooth',
									});
							}}
						>
							<Icon name='info-circle' size='12' />
							<span>{value}</span>
						</Styled.ErrorMessage>
					);
				}
				if (Array.isArray(value)) {
					// medias
					const element = document.getElementsByClassName('error')[0];
					return (
						<Styled.ErrorMessage
							key={i}
							onClick={() => {
								//@ts-ignore
								element?.focus({ focusVisible: true }),
									element?.scrollIntoView({
										block: 'start',
										behavior: 'smooth',
									});
							}}
						>
							<Icon name='info-circle' size='12' />
							<span>{value[0]?.text ?? 'Missing required fields'}</span>
						</Styled.ErrorMessage>
					);
				}
			})
		);
	};

	useEffect(() => {
		if (props.errors && Object.keys(props.errors).length > 0) {
			const element = document.getElementsByName(Object.keys(props.errors)[0])[0];
			if (element) {
				//@ts-ignore
				element.focus({ focusVisible: true });
				element.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				});
			}
		}
	}, [props.errors]);

	return isMobileView ? (
		<Styled.SubmitSection data-testid={props.testId}>
			<Styled.SubmitSectionContent>
				<div>
					<Styled.AssignmentName>{props.title}</Styled.AssignmentName>
					<Styled.FileText>
						{props.errors && Object.entries(props.errors).length > 0
							? displayErrors()
							: props.files
								? props.files > 0 && (
										<>
											{props.files} file{props.files && props.files > 1 ? 's' : ''} will be submitted
										</>
									)
								: props.storyFrames
									? props.storyFrames > 0 && (
											<>
												{props.storyFrames} frame{props.storyFrames && props.storyFrames > 1 ? 's' : ''} selected
											</>
										)
									: ''}
					</Styled.FileText>
				</div>
				{!props.isManualEditMode && (
					<>
						{props.isLoading ? (
							<LoadingSpinner size='sm' />
						) : (
							<Styled.SubmitButton
								type='submit'
								onClick={props.submitAction}
								disabled={props.buttonDisabled}
								className={classNames({ turnBlue: props.isValid }, props.className)}
							>
								<span>{props.buttonText}</span>
							</Styled.SubmitButton>
						)}
					</>
				)}
			</Styled.SubmitSectionContent>
		</Styled.SubmitSection>
	) : (
		<Styled.DFlexColumn>
			{!props.isManualEditMode && (
				<Styled.CustomSubmitButton onClick={props.submitAction} data-testid={props.testId} type='submit' disabled={props.buttonDisabled}>
					{props.isLoading ? (
						<LoadingSpinner size='sm' />
					) : (
						<>
							{props.buttonIcon && <Icon name={props.buttonIcon} size='16' />}
							<span>{props.buttonText}</span>
						</>
					)}
				</Styled.CustomSubmitButton>
			)}
			<div>{!props.hideErrors && displayErrors()}</div>
		</Styled.DFlexColumn>
	);
};

export default SubmitSection;
