import Icon from 'components/Icon';

import Styled from './Fullscreen.style';

type FullscreenModalProps = {
	isOpen: boolean;
	handleClose: () => void;
	file?: string | File;
	videoUrl?: string;
	dataTestId?: string;
};

const FullscreenModal = ({ isOpen, handleClose, file, videoUrl, dataTestId }: FullscreenModalProps) => {
	return (
		<Styled.CustomModal open={isOpen} handleClose={() => handleClose()} size='xs' dataTestId={dataTestId}>
			<button
				onClick={(e) => {
					handleClose(), e.stopPropagation();
				}}
				data-testid='close-button'
			>
				<span>Close</span>
				<Icon name='circle-cross' size='18' />
			</button>
			{videoUrl ? (
				<Styled.Iframe data-testid='story-video' src={videoUrl} allow='autoplay; fullscreen; picture-in-picture' allowFullScreen></Styled.Iframe>
			) : file ? (
				<Styled.ImageWrapper>
					<Styled.FullSizeImage src={typeof file === 'string' ? file : URL.createObjectURL(file)} className='full-size-image' alt='Full size image' />
				</Styled.ImageWrapper>
			) : null}
		</Styled.CustomModal>
	);
};

export default FullscreenModal;
