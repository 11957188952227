import classNames from 'classnames';
import { isEmpty } from 'lodash';

import Checkbox from 'components/Checkbox';
import LoadingSpinner from 'components/LoadingSpinner';

import Styled from './CollaborationType.style';

type Props = {
	collaborationTypes: string[];
	onClickCollaboration: (selectedCollaborationTypes: 'paid' | 'affiliate') => void;
	onSelect: () => void;
	isLoading: boolean;
};

const CollaborationType = ({ collaborationTypes, onSelect, isLoading, onClickCollaboration }: Props) => {
	const onTypeSelect = (selectedType: 'paid' | 'affiliate') => {
		onClickCollaboration(selectedType);
	};

	return (
		<Styled.Wrapper>
			<Styled.OptionsWrapper>
				{isLoading && <LoadingSpinner position='center' />}
				{!isLoading && (
					<>
						<Styled.Option
							data-testid='onboarding-paid-option'
							aria-checked={collaborationTypes.includes('paid')}
							onClick={() => {
								onTypeSelect('paid');
							}}
							className={classNames({ selected: collaborationTypes.includes('paid') })}
						>
							<Checkbox
								checked={collaborationTypes.includes('paid')}
								value='paid'
								onChange={(e) => {
									e.stopPropagation();
									onTypeSelect('paid');
								}}
							/>
							<div>
								<div className='title'>Paid collaboration</div>
								<div>Content creation & promotion in exchange for commission.</div>
							</div>
						</Styled.Option>

						<Styled.Option
							data-testid='onboarding-affiliate-option'
							aria-checked={collaborationTypes.includes('affiliate')}
							onClick={() => {
								onTypeSelect('affiliate');
							}}
							className={classNames({ selected: collaborationTypes.includes('affiliate') })}
						>
							<Checkbox
								checked={collaborationTypes.includes('affiliate')}
								value='affiliate'
								onChange={(e) => {
									e.stopPropagation();
									onTypeSelect('affiliate');
								}}
							/>
							<div>
								<div className='title'>Affiliate Program</div>
								<div>Our affiliate programs is an easy way to earn commission.</div>
							</div>
						</Styled.Option>
					</>
				)}
			</Styled.OptionsWrapper>
			<Styled.Divider />

			<Styled.Button onClick={onSelect} disabled={isEmpty(collaborationTypes)}>
				Select
			</Styled.Button>
		</Styled.Wrapper>
	);
};

export default CollaborationType;
