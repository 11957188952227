import styled from 'styled-components';

import { Button } from 'components/Button';
import BaseCard from 'components/Card';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import borderRadius from 'styles/variables/border-radius';
import boxShadow from 'styles/variables/box-shadows';
import colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

const dl = colors.dataLibrary;

const labelTypograph = typography.label;

const Card = styled(BaseCard)<{ border?: string }>`
	border: 1px solid transparent;
	border-radius: ${borderRadius.m};
	box-shadow: ${boxShadow.medium};

	&.inList {
		background: ${colors.mainWhite};
		&.existsOnDashboard {
			border: 2px solid transparent;
		}
	}
	&.somethingWentWrong {
		border: 2px solid ${dl.dangerRed};
	}
`;

const SeeAll = styled.button`
	border: none;
	background: none;
	color: ${colors.shareModal.infoBlue};
	font-size: 0.983rem;
	cursor: pointer;
	margin-top: 1rem;
`;

const Header = styled.div`
	width: 100%;
	padding: 1rem;
	text-align: center;
	display: flex;
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
`;

const Icon = styled.img`
	width: 20px;
	height: 20px;
`;

const Title = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.7;
	letter-spacing: 0px;
	color: #333;
	width: 100%;
	text-align: start;
`;

const CustomButton = styled(Button)`
	font-family: ${labelTypograph.fontFamily};
	font-size: 1rem;
	display: flex;
	justify-content: center;
	cursor: pointer;
	background: none;
	min-width: 100px;
	height: 36px;
	border: 2px solid ${dl.black};
	color: black;
	border-radius: 4px;
	div {
		display: flex;
		align-items: center;
		.icon {
			margin-right: 3px;
		}
	}
	&:hover {
		background: ${dl.blackHover} !important;
		color: ${dl.white}!important;
		.icon path {
			fill: ${dl.white}!important;
		}
	}
`;

const Container = styled.div`
	width: 100%;
	overflow: auto;
`;

const InnerCenteredWrapper = styled.div`
	padding: 0rem 1rem;
	cursor: default;

	canvas {
		max-height: 450px;
	}
`;

const InnerWrapper = styled.div`
	padding: 0rem 1rem;
	cursor: default;
	position: relative;
	canvas {
		width: 100% !important;
		height: 100% !important;
	}
`;

const DropdownContainer = styled.div`
	width: 20%;
	display: flex;
	justify-content: flex-end;
`;

const CustomDropDownMenu = styled(DropdownMenu)`
	transform: translate3d(0px, 35px, 0px) !important;
`;

const CustomDropDownMenuItem = styled(DropdownItem)`
	padding: 0.25rem 0.5rem;

	&:hover {
		&.danger {
			background-color: ${dl.dangerRed};
		}
	}
`;

const IconMargin = styled.div`
	margin-left: 5px;
`;

const Total = styled.div`
	font-weight: 600;
	font-size: 0.875rem;
	margin-bottom: 20px;
`;

const SubTitle = styled.div`
	font-style: 600;
	font-size: 0.875rem;
	margin-bottom: 16px;
`;

const TextContainer = styled.div`
	margin-bottom: 1rem;
`;

const Styled = {
	Card,
	Header,
	Icon,
	Title,
	CustomButton,
	Container,
	InnerCenteredWrapper,
	InnerWrapper,
	ButtonContainer,
	DropdownContainer,
	IconMargin,
	SeeAll,
	Total,
	SubTitle,
	TextContainer,
	CustomDropDownMenu,
	CustomDropDownMenuItem,
};

export default Styled;
