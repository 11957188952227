import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { BrandModel } from 'api-models';
import { getBrandOptions } from 'components/DataLibrary/AllRequests/DataFilterOptions';
import FilterSectionAccordionItem from 'components/DataLibrary/FilterSection/FilterSectionAccordionItem';
import { FilterOptionProps } from 'components/DataLibrary/FilterSection/types';
import useInjection from 'hooks/useInjection';
import BrandManager from 'services/ApiManager/Brand.manager';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboardFilterBrands } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const BrandOptions = (props: FilterOptionProps): JSX.Element => {
	const [selectedBrands, setSelectedBrands] = useState<Array<string>>([]);
	const [clearThisFilter, setClearThisFilter] = useState<boolean>(false);
	const manager = useInjection<BrandManager>(BrandManager);

	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);

	const dispatch = useAppDispatch();

	let brands: BrandModel[] = [];
	let isSearching = true;

	if (dashboard.id) {
		const { result, error } = getBrandOptions(manager, dashboardFilter);
		if (error) {
			console.error(error.message);
		}
		isSearching = !result;
		if (result) {
			brands = result;
		}
	}

	useEffect(() => {
		dispatch(setDashboardFilterBrands(selectedBrands));
	}, [selectedBrands]);

	const onSelect = (id: string) => {
		props.getClearFilter(false);
		setClearThisFilter(false);
		if (selectedBrands.includes(id)) {
			const update = selectedBrands.filter((i) => i !== id);
			setSelectedBrands(update);
		} else {
			setSelectedBrands([...selectedBrands, id]);
		}
	};

	const checkDashboardFilter = () => {
		if (dashboard.metaData.dashboardFilter) {
			const savedBrands = dashboard.metaData.dashboardFilter.brands ?? [];
			if (!isEqual(savedBrands, selectedBrands)) {
				setSelectedBrands(savedBrands);
			}
		} else {
			setSelectedBrands([]);
		}
	};

	useEffect(() => {
		checkDashboardFilter();
	}, [props.dashboardId, dashboard]);

	useEffect(() => {
		if (clearThisFilter || props.clearFilter) {
			setSelectedBrands([]);
		}
	}, [props.clearFilter, clearThisFilter]);

	useEffect(() => {
		checkDashboardFilter();
		return () => {
			setSelectedBrands([]);
			setClearThisFilter(false);
		};
	}, []);

	return (
		<FilterSectionAccordionItem
			setClearThisFilter={setClearThisFilter}
			title={<>Brand {selectedBrands?.length > 0 && <>({selectedBrands?.length})</>} </>}
			label={'Search for brands'}
			placeholder={'Ex Nike'}
			data={brands.map((item) => ({
				id: item.id,
				name: item.attributes.name,
			}))}
			selectedData={selectedBrands}
			onSelect={onSelect}
			isSearching={isSearching}
		/>
	);
};
export default BrandOptions;
