import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	justify-content: center;
	padding: 0 1rem;
`;

const Section = styled.section`
	display: flex;
	flex-direction: column;
	gap: 32px;
	position: relative;
	max-width: 33.25rem;
	width: 100%;
	margin-bottom: 16px;
	margin-left: auto;
	margin-right: auto;
`;

const LogotypeContainer = styled.div`
	width: 100%;
	padding-top: 2rem;

	img {
		max-width: 100px;
		display: block;
		margin: 0 auto;
	}
`;

const Inner = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	max-width: 992px;
	height: 100%;
	width: 100%;
	margin: 0 auto;
`;

const SubTitle = styled.h5`
	font-size: 1rem;
`;

const Styled = {
	SubTitle,
	Wrapper,
	Section,
	LogotypeContainer,
	Inner,
};

export default Styled;
