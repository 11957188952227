// In general, an "admin" is just a project manager with access to billing.
export const ROLE_ADMINISTRATOR = 'administrator';
// Project manager is the same as "Campaign manager"
export const ROLE_PROJECT_MANAGER = 'project_manager';
// In general, this is read only. It is used for customers of the project manager
export const ROLE_BRAND_MANAGER = 'brand_manager';
// Read only. Only access to Data Library
export const ROLE_DATA_ANALYST = 'data_analyst';

export type SecurityRole = typeof ROLE_ADMINISTRATOR | typeof ROLE_PROJECT_MANAGER | typeof ROLE_BRAND_MANAGER | typeof ROLE_DATA_ANALYST;
