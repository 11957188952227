/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import {
	CreateDashboardPayload,
	UpdatePaidMediaPayload,
	UpdateDashboardPayload,
	CreateDashboardUserPayload,
	CreateDashboardItemPayload,
	CreatePaidMediaPayload,
} from 'api-payloads';
import { ListDashboardsQuery, CreateDashboardQuery, ViewDashboardQuery, UpdateDashboardQuery, CreateDashboardItemQuery } from 'api-queries';
import { DashboardCollectionResponse, DashboardResponse, DashboardPaidMediaResponse, DashboardUserResponse, UserUploadPresignUrlResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import DashboardApiClientInterface from './DashboardApiClientInterface';

@injectable()
export default class DashboardApiClient implements DashboardApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List dashboards
	 */
	async listDashboards(params: ListDashboardsQuery): Promise<DashboardCollectionResponse> {
		const response = await this.client.get<DashboardCollectionResponse>(`/dashboards`, { params });
		return response.data;
	}

	/**
	 * Create dashboard
	 */
	async create(payload: CreateDashboardPayload, params: CreateDashboardQuery): Promise<DashboardResponse> {
		const response = await this.client.post<DashboardResponse>(`/dashboards`, payload, { params });
		return response.data;
	}

	/**
	 * Delete paid media
	 */
	async deletePaidMedia(dashboardId: string, id: string): Promise<void> {
		const response = await this.client.delete<void>(`/dashboards/${dashboardId}/paid-media/${id}`);
		return response.data;
	}

	/**
	 * Update paid media
	 */
	async updatePaidMedia(dashboardId: string, id: string, payload: UpdatePaidMediaPayload, params: { include?: string }): Promise<DashboardPaidMediaResponse> {
		const response = await this.client.patch<DashboardPaidMediaResponse>(`/dashboards/${dashboardId}/paid-media/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * View dashboard
	 */
	async view(id: string, params: ViewDashboardQuery): Promise<DashboardResponse> {
		const response = await this.client.get<DashboardResponse>(`/dashboards/${id}`, { params });
		return response.data;
	}

	/**
	 * Delete dashboard
	 */
	async delete(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/dashboards/${id}`);
		return response.data;
	}

	/**
	 * Update dashboard
	 */
	async update(id: string, payload: UpdateDashboardPayload, params: UpdateDashboardQuery): Promise<DashboardResponse> {
		const response = await this.client.patch<DashboardResponse>(`/dashboards/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * Create dashboard user
	 */
	async createUser(id: string, payload: CreateDashboardUserPayload, params: { include?: string }): Promise<DashboardUserResponse> {
		const response = await this.client.post<DashboardUserResponse>(`/dashboards/${id}/dashboard-users`, payload, { params });
		return response.data;
	}

	/**
	 * Create dashboard item
	 */
	async createItem(id: string, payload: CreateDashboardItemPayload, params: CreateDashboardItemQuery): Promise<DashboardResponse> {
		const response = await this.client.post<DashboardResponse>(`/dashboards/${id}/items`, payload, { params });
		return response.data;
	}

	/**
	 * Create paid media
	 */
	async createPaidMedia(id: string, payload: CreatePaidMediaPayload, params: { include?: string }): Promise<DashboardPaidMediaResponse> {
		const response = await this.client.post<DashboardPaidMediaResponse>(`/dashboards/${id}/paid-media`, payload, { params });
		return response.data;
	}

	/**
	 * Create paid media presign url
	 */
	async createPaidMediaPresignUrl(id: string, params: { include?: string }): Promise<UserUploadPresignUrlResponse> {
		const response = await this.client.post<UserUploadPresignUrlResponse>(`/dashboards/${id}/paid-media-presign-urls`, undefined, { params });
		return response.data;
	}
}
