import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import Styled from './Tooltip.style';

type TooltipProps = {
	text: string;
	children: React.ReactNode;
	align?: 'left' | 'right' | 'middle';
	position?: 'top' | 'bottom';
	transparent?: boolean;
	delay?: number;
};

/**
 * Tooltip
 * @param {TooltipProps} props
 * @returns {JSX.Element}
 */
const Tooltip = ({ text, align = 'left', position = 'top', transparent, delay = 300, children }: TooltipProps): JSX.Element => {
	const [tooltipWidth, setTooltipWidth] = useState<number>(0);
	const tooltipRef = useRef<HTMLDivElement>(null);
	const [showTooltip, setShowTooltip] = useState(false);
	const isMountedRef = useRef<boolean>(false);

	const handleMouseEnter = () => {
		setTimeout(() => {
			if (isMountedRef.current) {
				setShowTooltip(true);
			}
		}, delay);
	};

	const handleMouseLeave = () => {
		setShowTooltip(false);
	};

	useEffect(() => {
		if (tooltipRef.current) {
			setTooltipWidth(tooltipRef.current.offsetWidth);
		}
	}, []);

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	}, []);

	return (
		<Styled.TooltipContainer className='tooltip' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			{children}
			<Styled.TooltipText
				role='tooltip'
				data-testid='tooltip'
				className={classNames({
					show: showTooltip,
					right: align === 'right',
					left: align === 'left',
					middle: align === 'middle',
					transparent: transparent,
				})}
				width={tooltipWidth}
				align={align}
				position={position}
				ref={tooltipRef}
			>
				{text}
			</Styled.TooltipText>
		</Styled.TooltipContainer>
	);
};

export default Tooltip;
