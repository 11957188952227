import classNames from 'classnames';
import { Model } from 'json-api-models';
import moment from 'moment';

import Avatar from 'components/Avatar';
import { SkeletonAvatarWithMessage } from 'components/SkeletonLoaders';
import { conversationMetaData } from 'shared/Types/Campaign/ConversationMetaData';

import Styled from './ConversationItem.style';

type Props = {
	displaySkeletonLoader?: boolean;
	coverImage?: string;
	title: string;
	onClick: (e: React.MouseEvent<HTMLElement>) => void;
	isSelected: boolean;
	conversation: Model | undefined;
	hasUnreadMessages: boolean;
	conversationMetaData: conversationMetaData;
};

const ConversationItem = ({ displaySkeletonLoader, coverImage, title, onClick, isSelected, hasUnreadMessages, conversationMetaData }: Props) => {
	const latestMessageObj = conversationMetaData?.latestMessageDateTime ? new ConversationMetaData(conversationMetaData) : null;
	return (
		<Styled.ListItem
			onClick={(e) => onClick(e)}
			className={classNames({ selected: isSelected && !displaySkeletonLoader })}
			data-testid='inbox-influencer-conversation-item'
		>
			{displaySkeletonLoader ? (
				<SkeletonAvatarWithMessage />
			) : (
				<Styled.Wrapper>
					<Styled.ImageWrapper>
						<Avatar imageUrl={coverImage} name={title} />
						{hasUnreadMessages && !displaySkeletonLoader ? <Styled.UnreadMark /> : null}
					</Styled.ImageWrapper>
					{latestMessageObj ? (
						<Styled.LatestMessageWrapper>
							<Styled.LatestMessageHeader>
								<Styled.CampaignName className='has-messages'>
									<span>{title}</span>
								</Styled.CampaignName>
								<div className='date'>{latestMessageObj.createdAt}</div>
							</Styled.LatestMessageHeader>
							<Styled.LatestMessageContent>
								<span>{latestMessageObj.message}</span>
							</Styled.LatestMessageContent>
						</Styled.LatestMessageWrapper>
					) : (
						<Styled.CampaignName>
							<span>{title}</span>
						</Styled.CampaignName>
					)}
				</Styled.Wrapper>
			)}
		</Styled.ListItem>
	);
};

export class ConversationMetaData {
	sender: string;
	message: string;
	createdAt: string;
	isRead: boolean;

	constructor(conversationMetaData: conversationMetaData) {
		this.sender = conversationMetaData.latestMessageName ?? '';
		this.message = conversationMetaData.latestMessageContent ?? '';
		this.createdAt = conversationMetaData.latestMessageDateTime ? moment(conversationMetaData.latestMessageDateTime).format('YY-MM-DD') : '';
		this.isRead = !!conversationMetaData.unread;
	}
}

export class LatestMessage {
	sender: string;
	message: string;
	createdAt: string;
	isRead: boolean;

	constructor(latestMessage: Model) {
		this.sender = this.isUser(latestMessage) ? latestMessage.user?.firstName : latestMessage.influencer?.username;
		this.message = latestMessage.message;
		this.createdAt = moment(latestMessage.createdAt).format('YY-MM-DD');
		this.isRead = !!latestMessage.readAt;
	}

	isUser(latestMessage: Model) {
		return !!latestMessage.user;
	}
}

export default ConversationItem;
