import axios from 'axios';
import { PropsWithChildren, useState } from 'react';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';

type Props = {
	url: string;
	originalFilename: string;
	attributeName?: string;
};

const DownloadableUrl = ({ url, originalFilename, children, attributeName = 'link' }: PropsWithChildren<Props>) => {
	const client = createClient();

	const [isLoading, setLoading] = useState(false);

	return (
		<span
			onClick={async () => {
				setLoading(true);
				try {
					const { data } = await client.post(url);
					// Use a new instance here so we don't send excess headers
					const response = await axios.create().get(data.data.attributes[attributeName], { responseType: 'blob' });
					const href = URL.createObjectURL(response.data);

					const link = document.createElement('a');
					link.href = href;
					link.setAttribute('download', originalFilename);
					document.body.appendChild(link);
					link.click();

					document.body.removeChild(link);
					URL.revokeObjectURL(href);
				} catch (e) {
					console.error(e);
					toast.error(`Unable to download "${originalFilename}"`);
				}
				setLoading(false);
			}}
		>
			{isLoading ? <LoadingSpinner size='sm' /> : children}
		</span>
	);
};

export default DownloadableUrl;
