import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/Icon';

import Styled from './BrandItemCard.style';
import { BrandItemType } from './type';

const BrandItemCard = ({ brand, hide }: BrandItemType) => {
	const navigate = useNavigate();

	return (
		<Styled.BrandItem onClick={() => navigate(`/settings/brands/${brand.id}`)} className={classNames({ hide: hide })}>
			{brand.links?.logo ? (
				<Styled.Logo logo={brand.links?.logo} />
			) : (
				<>
					<Styled.IconContainer>
						<Icon name='plus' size='12' />
					</Styled.IconContainer>
					<Styled.LogoText> Add logo</Styled.LogoText>
				</>
			)}
			<p>{brand.attributes.name}</p>
		</Styled.BrandItem>
	);
};

export default BrandItemCard;
