import styled, { DefaultThemeV2 } from 'styled-components';

import colors from 'styles/theme/colors';
import { fontWeights } from 'styles/variables/font-weights';

type T = { theme: DefaultThemeV2 };

const AddSection = styled.div`
	cursor: pointer;
	display: flex;
	gap: 8px;
	align-items: center;
	p {
		margin-bottom: 0;
		color: ${colors.smoke};
		font-weight: ${fontWeights.bold};
	}
`;

const AddIcon = styled.div`
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${colors.ash};
	border-radius: ${(props: T) => props.theme.radius.small};
`;

const ScrollDiv = styled.div`
	width: 100%;
	max-height: 500px;
	overflow-y: auto;
	padding-bottom: 2rem;
	margin-top: 40px;
`;

const Title = styled.h2`
	padding-top: 2.5rem;
`;

export default {
	AddSection,
	AddIcon,
	ScrollDiv,
	Title,
};
