import { Row } from 'react-table';

/**
 * A sort function for react-table that sorts rows by a specific column without caring about uppercase or lowercase letters.
 * It's great for strings because it makes 'a' and 'A' equal, so your sort won't get messed up by capitalization.
 * If the column's not about strings, it just sorts things the normal way.
 *
 * @param {Row<D>} rowA - The first row being compared.
 * @param {Row<D>} rowB - The second row being compared.
 * @param {string} columnId - Which column we're looking at to sort.
 * @returns {number} - Gives back 1 if `rowA` should go after `rowB`, -1 if it should go before, or 0 if they're the same in sort terms.
 */
export const caseInsensitiveSort = <D extends Record<string, unknown>>(rowA: Row<D>, rowB: Row<D>, columnId: string): number => {
	let a = rowA.values[columnId];
	let b = rowB.values[columnId];

	if (typeof a === 'string' && typeof b === 'string') {
		a = a.toLowerCase();
		b = b.toLowerCase();
	}

	if (a > b) return 1;
	if (a < b) return -1;
	return 0;
};
