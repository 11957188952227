import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
	fill: 'none',
};

export function SvgComment(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_1647_38854)'>
				<path
					d='M3.75121 20C3.40405 20 3.08582 19.8089 2.92189 19.484C2.73867 19.1209 2.82546 18.6909 3.13404 18.4233C3.64513 17.9742 3.78978 16.8753 3.81871 16.216C1.41755 14.4959 0 11.8586 0 9.07788C0 4.07071 4.48409 0 10 0C15.7956 0 20 3.82226 20 9.08743C20 12.0497 18.6596 14.7062 16.3356 16.3784C13.9537 18.0889 10.9065 18.5858 7.86885 17.7735C6.86596 19.6178 5.34233 19.8089 4.3973 19.9236C4.23337 19.9427 4.07907 19.9618 3.93443 19.9904C3.87657 20 3.81871 20.0096 3.76085 20.0096L3.75121 20ZM10 1.43335C5.28447 1.43335 1.44648 4.86383 1.44648 9.08743C1.44648 11.5146 2.75796 13.8175 4.94696 15.2604L5.25554 15.4611L5.27483 15.8242C5.27483 16.0153 5.32305 17.3053 4.86017 18.4138C5.61234 18.2609 6.29701 17.9169 6.81774 16.6555L7.06847 16.0344L7.71456 16.2446C10.5014 17.1524 13.3365 16.7797 15.4966 15.2317C17.4735 13.8079 18.5632 11.6292 18.5632 9.09699C18.5632 4.59627 15.0434 1.4429 10.0096 1.4429L10 1.43335Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1647_38854'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
