import moment from 'moment';
import { useEffect, useState } from 'react';

import DataToCSV from 'components/DataToCSV';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchInput from 'components/SearchInput';
import Tabs from 'components/Tabs/Tabs';

import Styled from './AdminPrePayments.styled';
import InvoicesTable from './components/InvoicesTable';
import useAdminPrePaymentsData from './hooks/useAdminPrePaymentsData';
import usePrePaymentsToCSV from './hooks/usePrePaymentsToCSV';
import { PrePaymentItemType } from './types';

enum Tab {
	ALL = 'All',
	PAID = 'Paid',
	UNPAID = 'Unpaid',
	ERROR = 'Missing invoice',
}

/**
 * AdminPrePayments
 * Component to show the overall invoices for admins
 * @returns {JSX.Element}
 */
const AdminPrePayments = (): JSX.Element => {
	const [selectedTab, setSelectedTab] = useState(Tab.ALL);
	const [searchQuery, setSearchQuery] = useState('');

	const [currentPage, setCurrentPage] = useState(1);
	const rowsPerPage = 20;

	const { getInvoices } = useAdminPrePaymentsData();
	const { models, isLoading, error, refresh } = getInvoices({});

	const prePayments = models.findAll('prePayment') as unknown as Array<PrePaymentItemType>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const invoices = models.findAll('invoice') as unknown as Array<any>;

	const fileNameDateAndType = `${moment(new Date()).format('YYYY-MM-DD')}.csv`;

	const { csvData, getCsvData, hasPrepaymentData } = usePrePaymentsToCSV(isLoading, prePayments);
	const { formattedData, csvHeaders } = csvData;

	// Filter prePayments based on selected tab
	const filteredPayments = prePayments.filter((payment) => {
		const matchesSearchQuery = searchQuery
			? payment.influencer?.username?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
				payment.companyName?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
				payment.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
				payment.amount?.toString().includes(searchQuery) ||
				payment.fortnoxId?.toString().includes(searchQuery) ||
				payment.scheduledAt?.toString().includes(searchQuery)
			: true;

		if (selectedTab === Tab.ERROR) {
			return payment.invoices?.length === 0 && matchesSearchQuery;
		}
		if (selectedTab === Tab.PAID) {
			return payment.paidAt && matchesSearchQuery;
		}
		if (selectedTab === Tab.UNPAID) {
			return !payment.paidAt && matchesSearchQuery;
		}
		if (selectedTab === Tab.ALL) {
			return matchesSearchQuery;
		}
		return matchesSearchQuery;
	});

	// Calculate total pages for pagination
	const totalPages = Math.ceil(filteredPayments.length / rowsPerPage);

	// Slice the payments based on current page
	const paginatedPayments = filteredPayments.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

	const handleNextPage = () => {
		if (currentPage < totalPages) setCurrentPage(currentPage + 1);
	};

	const handlePreviousPage = () => {
		if (currentPage > 1) setCurrentPage(currentPage - 1);
	};

	useEffect(() => {
		setCurrentPage(1); // Reset pagination when search query changes
	}, [searchQuery]);

	return (
		<Styled.AdminPrePayments data-testid='admin-invoices'>
			<h4>Payments</h4>
			{isLoading ? (
				<LoadingSpinner />
			) : (
				<>
					{error && <p>There was an error. Please try again later.</p>}
					{!error && (
						<>
							<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
								<Tabs
									tabs={[
										{
											title: Tab.ALL,
											amount: prePayments.length,
										},
										{
											title: Tab.PAID,
											amount: prePayments.filter((payment) => payment.paidAt).length,
										},
										{
											title: Tab.UNPAID,
											amount: prePayments.filter((payment) => !payment.paidAt).length,
										},
										{
											title: Tab.ERROR,
											amount: prePayments.filter((payment) => payment.invoices?.length === 0).length,
										},
									]}
									selectedTab={selectedTab}
									setSelectedTab={(tab) => setSelectedTab(tab as Tab)}
								/>
								<div className='d-flex' style={{ width: '600px', gap: '16px' }}>
									<SearchInput onReset={() => setSearchQuery('')} onChange={(e) => setSearchQuery(e.target.value)} />
									{!isLoading && (
										<DataToCSV
											shouldButtonBeEnabled={hasPrepaymentData}
											data={formattedData}
											headers={csvHeaders}
											filename={fileNameDateAndType}
											getCsvData={getCsvData}
										/>
									)}
								</div>
							</div>

							<InvoicesTable
								items={paginatedPayments.filter((payment) =>
									selectedTab === Tab.ALL
										? payment
										: selectedTab === Tab.ERROR
											? payment.invoices?.length === 0
											: selectedTab === Tab.PAID
												? payment.paidAt
												: !payment.paidAt,
								)}
								invoices={invoices}
								refresh={refresh}
							/>
							<div style={{ display: 'flex', justifyContent: 'center', padding: '24px' }}>
								<Styled.PagNav className={currentPage === 1 ? 'disabled' : ''} onClick={handlePreviousPage}>
									Previous
								</Styled.PagNav>
								<span style={{ margin: '0 10px' }}>
									Page {currentPage} of {totalPages}
								</span>
								<Styled.PagNav className={currentPage === totalPages ? 'disabled' : ''} onClick={handleNextPage}>
									Next
								</Styled.PagNav>
							</div>
						</>
					)}
				</>
			)}
		</Styled.AdminPrePayments>
	);
};

export default AdminPrePayments;
