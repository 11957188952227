import { DefaultTheme } from 'styled-components';

import { light as lightTable } from 'components/Table/V2/Theme';
import { light as lightThreeCol } from 'styles/layout/three-col.theme';
import typography from 'styles/variables/typography';

import base from './base';
import { lightColors } from './colors';

const lightTheme: DefaultTheme = {
	...base,
	isDark: false,
	colors: lightColors,
	// Modal
	modal: {
		border: `1px solid ${lightColors.modal.border}`,
		borderRadius: '10px',
		content: {
			background: lightColors.modal.background,
		},
	},
	// Toggle Panel
	TogglePanel: {
		HeadingColor: lightColors.togglePanel.heading,
		borderBottom: `1px solid ${lightColors.togglePanel.borderBottom}`,
		active: {
			HeadingColor: lightColors.togglePanel.headingActive,
		},
		hover: {
			HeadingColor: lightColors.togglePanel.headingHover,
		},
	},
	// Form Elements
	InputField: {
		background: lightColors.input.background,
		border: `1px solid ${lightColors.input.border}`,
		borderRadius: '10px',
		placeholderColor: lightColors.input.placeholder,
		hover: {
			border: `1px solid ${lightColors.input.borderHover}`,
		},
		disabled: {
			borderColor: lightColors.input.borderDisabled,
			placeholderColor: lightColors.input.placeholderDisabled,
		},
	},
	textarea: {
		background: lightColors.textarea.background,
		borderBottom: `1px solid ${lightColors.textarea.borderBottom}`,
		headingColor: lightColors.textarea.heading,
		selected: {
			headingColor: lightColors.textarea.headingSelected,
		},
	},
	select: {
		fontFamily: typography.BaseFontFamiliy,
		fontSize: '1rem', // should not be here
		placeholderColor: lightColors.select.placeholder,
		indicatorColor: lightColors.select.indicator,
		controlBorder: `1px solid ${lightColors.select.controlBorder}`,
		optionBackground: lightColors.select.optionBackground,
		optionSelectedColor: lightColors.select.optionSelected,
		controlBoxshadowColor: lightColors.select.controlBorder,
		menuBoxshadowColor: lightColors.select.menuBoxshadow,
	},
	// Layouts
	threeCol: lightThreeCol,

	// Components
	table: lightTable,
	inbox: lightThreeCol,
};

export default lightTheme;
