import styled, { css } from 'styled-components';

import { SocialProfileConnectorColors as colors } from 'styles/variables/colors/SocialProfileConnector';
import { rem } from 'styles/variables/spacings';

const borderStyle = css`
	background-image: linear-gradient(to right, ${colors.SuccessMessage.borderColor} 13%, rgba(136, 136, 136, 0) 0%),
		linear-gradient(to right, ${colors.SuccessMessage.borderColor} 13%, rgba(136, 136, 136, 0) 0%);
	background-position: top, bottom;
	background-size: 8px 1px;
	background-repeat: repeat-x;
`;

const Wrapper = styled.div`
	background-color: ${colors.SuccessMessage.background};
	padding: ${rem[3]}rem ${rem[2]}rem;
	${borderStyle};

	& + .link-view {
		margin-top: 24px;
	}
`;

const Message = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;

const Styled = {
	Wrapper,
	Message,
};

export default Styled;
