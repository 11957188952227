/**
 * Default global font weights
 * This file keeps the font weights.
 *
 */
export const fontWeights = {
	thin: 100,
	medium: 400,
	semiBold: 500,
	bold: 700,
	superBold: 900,
};
