import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ContentManagementSliceState = {
	isProgressVisible: boolean;
};

export const initialState = {
	isProgressVisible: false,
};

const ContentManagementSlice = createSlice({
	name: 'contentManagement',
	initialState,
	reducers: {
		setIsProgressVisible(state, action: PayloadAction<boolean>) {
			state.isProgressVisible = action.payload;
		},
		resetContentManagementSlice() {
			return { isProgressVisible: false };
		},
	},
});

export const { setIsProgressVisible, resetContentManagementSlice } = ContentManagementSlice.actions;

export default ContentManagementSlice.reducer;
