import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgSignIn(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M20.0001 0.95L20.0001 19.05C20.0001 19.58 19.5698 20 19.0495 20L4.9506 20C4.42026 20 4 19.57 4 19.05L4 16.37C4 15.96 4.34021 15.62 4.75047 15.62C5.16073 15.62 5.50095 15.96 5.50095 16.37L5.50095 18.5L18.5091 18.5L18.5091 1.5L5.50094 1.5L5.50095 3.5C5.50095 3.91 5.16073 4.25 4.75047 4.25C4.34021 4.25 4 3.91 4 3.5L4 0.950001C4 0.42 4.43027 -1.88077e-08 4.9506 -4.15518e-08L19.0495 -6.5783e-07C19.5798 -6.81012e-07 20.0001 0.43 20.0001 0.95Z'
				fill='#333333'
			/>
			<path
				d='M15.7188 9.32974L10.0333 3.71758C9.73935 3.42747 9.25289 3.42747 8.95898 3.71758C8.66508 4.00769 8.66508 4.48788 8.95898 4.77799L13.4892 9.24971L0.760094 9.24971C0.344576 9.24971 -2.66197e-07 9.58984 -2.84125e-07 10C-2.93308e-07 10.2101 0.0810754 10.3901 0.22296 10.5302C0.364845 10.6703 0.547268 10.7503 0.760094 10.7503L13.4993 10.7503L8.96912 15.222C8.67521 15.5121 8.67521 15.9923 8.96912 16.2824C9.26302 16.5725 9.74948 16.5725 10.0434 16.2824L15.7188 10.6703C16.0937 10.3001 16.0937 9.69988 15.7188 9.32974Z'
				fill='#333333'
			/>
		</svg>
	);
}
