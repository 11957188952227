import classNames from 'classnames';
import { useContext, useState } from 'react';

import Accordion from 'components/Accordion';
import Styled from 'components/Discovery/Components/FilterDrawer/FilterDrawer.style';
import Input from 'components/Discovery/Components/Input';
import DiscoveryContext from 'contexts/Discovery';

const InfluencerAgeFilter = () => {
	const setFilter = useContext(DiscoveryContext).setFilter;
	const filter = useContext(DiscoveryContext).filter;

	const [influencerMinAge, setInfluencerMinAge] = useState<number | null>(filter?.minAge ?? null);
	const [influencerMaxAge, setInfluencerMaxAge] = useState<number | null>(filter?.maxAge ?? null);

	const updateFilter = () => {
		setFilter({
			...filter,
			minAge: influencerMinAge,
			maxAge: influencerMaxAge,
		});
	};

	return (
		<Accordion title='Influencer age' className={classNames({ active: filter?.minAge || filter?.maxAge })}>
			<Styled.FollowersInputsWrapper>
				<Input
					label='min'
					type='text'
					pattern='[0-9]*'
					value={isNaN(Number(influencerMinAge)) ? '' : Number(influencerMinAge)}
					onChange={(e) => {
						setInfluencerMinAge(+e.target.value);
					}}
					onBlur={updateFilter}
				/>
				<Styled.BetweenMinMaxDiv>-</Styled.BetweenMinMaxDiv>
				<Input
					label='max'
					type='text'
					pattern='[0-9]*'
					value={isNaN(Number(influencerMaxAge)) ? '' : Number(influencerMaxAge)}
					onChange={(e) => {
						setInfluencerMaxAge(+e.target.value);
					}}
					onBlur={updateFilter}
				/>
			</Styled.FollowersInputsWrapper>
		</Accordion>
	);
};

export default InfluencerAgeFilter;
