import styled from 'styled-components';

import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints, media } from 'styles/variables/media-queries';

/**
 * ============ GRID ============
 * Use case:
 * - Complex design layouts
 * - Gap between block elements
 * - Layout-first designs
 */

interface ICol {
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	xxl?: number;
}

interface IContainer {
	gap?: number | string;
	rowGap?: number | string;
}

const _css = {
	col: (column: number) => {
		return `grid-column: auto/span ${column};`;
	},
};

const Container = styled.div<IContainer>`
	@media (min-width: ${breakpoints.xs}) {
		display: grid;
		grid-gap: ${(props: IContainer) => (props.gap ? `${props.gap}px` : guttersWithRem.m)};
		grid-row-gap: ${(props: IContainer) => (props.gap ? `${props.rowGap}px` : null)};
		grid-template-columns: repeat(12, 1fr);
		align-items: start;
	}
`;

const Column = styled.div<ICol>`
	grid-column: auto/span ${(props: ICol) => props.xs || 12};
	width: 100%;

	${(p) =>
		p.sm &&
		media.sm`
			${_css.col(p.sm)}
		`};

	${(p) =>
		p.md &&
		media.md`
			${_css.col(p.md)}
		`};

	${(p) =>
		p.lg &&
		media.lg`
			${_css.col(p.lg)}
		`};

	${(p) =>
		p.xl &&
		media.xl`
			${_css.col(p.xl)}
		`};

	${(p) =>
		p.xxl &&
		media.xxl`
			${_css.col(p.xxl)}
		`};
`;

const Grid = {
	Container,
	Column,
};

export default Grid;
