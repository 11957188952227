import { useEffect, useRef, forwardRef } from 'react';

import Checkbox from 'components/Checkbox/V2';
import { CheckboxProps } from 'components/Checkbox/V2/Checkbox';
import Styled from 'components/Table/V2/Table.style';

/**
 * IndeterminateCheckbox
 * @returns {JSX.Element}
 */
const IndeterminateCheckbox = forwardRef<
	HTMLInputElement,
	CheckboxProps & { indeterminate?: boolean; selectedRows?: Array<unknown>; selectComponent?: JSX.Element }
>(({ indeterminate, selectedRows, selectComponent, ...rest }, ref): JSX.Element => {
	const defaultRef = useRef<HTMLInputElement>(null);
	const resolvedRef = ref ?? defaultRef;

	useEffect(() => {
		if (resolvedRef && 'current' in resolvedRef) {
			resolvedRef.current!.indeterminate = indeterminate ?? false;
		}
	}, [resolvedRef, indeterminate]);

	return (
		<Styled.SelectContainer>
			<Checkbox ref={resolvedRef} {...rest} />
			{selectedRows && selectedRows?.length > 0 ? selectComponent : null}
		</Styled.SelectContainer>
	);
});

export default IndeterminateCheckbox;
