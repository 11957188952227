import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgArchive(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<g clipPath='url(#clip0_2038_27799)'>
				<path
					d='M19.12 0H0.88C0.39 0 0 0.38 0 0.84V5.78C0 6.24 0.4 6.62 0.88 6.62H0.93V19.06C0.93 19.58 1.33 20 1.81 20H18.18C18.67 20 19.06 19.58 19.06 19.06V6.62H19.11C19.6 6.62 19.99 6.24 19.99 5.78V0.84C19.99 0.38 19.59 0 19.11 0H19.12ZM1.4 1.32H18.61V5.05H1.4V1.32ZM17.68 18.52H2.33V6.62H17.68V18.52Z'
					fill='#333333'
				/>
				<path
					d='M5.59995 13.46H14.53C14.89 13.46 15.18 13.15 15.18 12.76V10.16C15.18 9.76996 14.89 9.45996 14.53 9.45996H5.59995C5.23995 9.45996 4.94995 9.76996 4.94995 10.16V12.76C4.94995 13.15 5.23995 13.46 5.59995 13.46ZM5.87995 10.46H14.25V12.46H5.87995V10.46Z'
					fill='#333333'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2038_27799'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
