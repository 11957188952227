import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgThumbsDown(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M9.26768 19C8.23785 19 7.39039 18.1842 7.01493 17.6265L3.04579 11.6059C2.28415 10.4492 2.11251 10.1498 2.09105 10.0981C1.88723 9.71602 2.03742 9.2513 2.43433 9.05509C2.83124 8.85888 3.31398 9.00346 3.5178 9.38555C3.53925 9.42686 3.70016 9.71602 4.40817 10.7694L8.37731 16.79C8.37731 16.79 8.97804 17.6678 9.50369 17.3683C9.88987 17.1515 9.70751 16.6351 9.6646 16.5422L7.99112 12.7005C7.86239 12.401 7.89458 12.0499 8.07694 11.7814C8.27004 11.5129 8.58113 11.3477 8.92441 11.3477H14.7923C15.039 11.3477 15.4681 11.3167 15.6183 11.1102C15.7899 10.8726 15.6934 10.3253 15.5861 10.0258C15.4574 9.71602 15.5539 9.35457 15.8221 9.14803C16.1654 8.87953 16.337 8.61103 16.3478 8.35285C16.3478 7.99141 16.0367 7.62996 15.9187 7.51637C15.747 7.36146 15.6398 7.13427 15.6505 6.90707C15.6612 6.67988 15.7792 6.46301 15.9723 6.31843C16.4014 6.00862 16.4121 5.7711 16.4121 5.69881C16.4121 5.28573 15.9294 4.79004 15.8114 4.6971C15.511 4.44925 15.4359 4.03617 15.629 3.7057C15.9401 3.21001 15.9401 2.9415 15.8972 2.86921C15.7792 2.673 15.1463 2.52842 14.7172 2.54908H6.04946C4.49399 2.54908 3.52852 4.23238 3.5178 4.25303C3.30325 4.62481 2.82052 4.76938 2.4236 4.56284C2.03742 4.3563 1.88723 3.89159 2.09105 3.51982C2.14469 3.41655 3.5178 1.00003 6.03874 1.00003H14.6636C14.8352 1.00003 16.5838 0.979372 17.2489 2.08436C17.5707 2.61104 17.5814 3.24099 17.2703 3.95355C17.6136 4.3563 18.0105 4.98625 17.9998 5.7298C17.9998 6.07059 17.8925 6.52497 17.5171 6.98969C17.7423 7.34081 17.9462 7.80552 17.9354 8.36318C17.9354 8.78659 17.7852 9.37523 17.2167 9.9742C17.3347 10.4905 17.3991 11.3064 16.927 11.9673C16.6267 12.3907 16.0152 12.8864 14.7708 12.8864H9.80405L11.1343 15.9432C11.4132 16.5732 11.6063 17.9673 10.2975 18.7005C9.93278 18.9071 9.57878 18.9897 9.25696 18.9897L9.26768 19Z'
				fill='#333333'
			/>
		</svg>
	);
}
