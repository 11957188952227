import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Model } from 'json-api-models';
import _ from 'lodash';

import { IdVerificationStatus } from 'components/Payment/types';
import { API_STATUS_CONNECTED } from 'constants/influencer';
import { ApiStatus, Network } from 'constants/socialMedia';

import { setPermissions } from './UserPermissionsSlice';
import { InfluencerType, InstagramBusinessAccountType, PinebucketStatus, UserPaymentType, UserType } from './types';

export const initialState: UserType = {
	id: '',
	uuid: '',
	initials: '',
	title: null,
	name: '',
	profilePictureUrl: '',
	connectedToFacebook: false, // deprecate this
	instagramBusinessAccounts: [],
	hasActiveInstagramBusinessAccounts: false,
	hasBeenLinkedToInfluencers: false,
	needsEmailVerification: true,
	permissions: {
		entities: null,
		isAgent: false,
		isImpersonating: false,
		isInfluencer: false,
		isSuperAdmin: false,
	},
	links: null,
	hasCompletedProfile: false,
	influencers: [],
	influencer: {
		id: '',
		collabsId: '',
		followersCount: 0,
		username: '',
		apiStatus: '',
		network: '',
		isInstagramConnected: false,
		country: '',
		affiliatePreferenceRequired: false,
	},
	email: '',
	mobilePhoneNumber: null,
	address: null,
	address2: null,
	city: null,
	countryCode: null,
	postalCode: null,
	influencerTermsOfConditionsAcceptedAt: null,
	publisherTermsOfConditionsAcceptedAt: null,
	addressVerifiedAt: null,
	payment: {
		idVerificationStatus: IdVerificationStatus.NOT_VERIFIED,
		pinebucketStatus: PinebucketStatus.NOT_CONNECTED,
		type: null,
		vatNumber: null,
		vatNumberValid: false,
		vatNumberValidatedAt: null,
	},
	isLoggingOut: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapInfluencers = (influencers: Array<any>): Array<InfluencerType> => {
	const items = influencers.map((item) => {
		return {
			id: item.id,
			collabsId: item.collabsId,
			username: item.username,
			followersCount: item.followersCount,
			links: item.links,
			apiStatus: item.apiStatus,
			network: item.network,
			country: item.country,
			isInstagramConnected: item.network === Network.INSTAGRAM && item.apiStatus === API_STATUS_CONNECTED,
		} as InfluencerType;
	});

	return items;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapInstagramBusinessAccounts = (instagramBusinessAccounts: Array<any>): Array<InstagramBusinessAccountType> => {
	const items = instagramBusinessAccounts.map((item) => {
		return {
			id: item.id,
			inactivatedAt: item.inactivatedAt,
			name: item.name,
			profilePictureUrl: item.profilePictureUrl,
			username: item.username,
		} as InstagramBusinessAccountType;
	});

	return items;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasActiveBusinessAccounts = (instagramBusinessAccounts: Array<any>) => {
	const accounts = instagramBusinessAccounts;

	const activeAccounts = accounts && accounts.filter((a: InstagramBusinessAccountType) => a.inactivatedAt === null);
	return accounts && activeAccounts.length > 0;
};

const hasCompletedProfile = (user: Model) => {
	let isConnected = false;

	for (const influencer of user.influencers ?? []) {
		if (influencer.apiStatus === ApiStatus.CONNECTED) {
			isConnected = true;
			break;
		}
	}

	const hasSelectedPaymentOption = user.kind !== null;

	return !!(isConnected && hasSelectedPaymentOption && user.addressVerifiedAt);
};

const UserSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser(state, { payload }: PayloadAction<Model>) {
			state = {
				id: payload.id,
				uuid: payload.uuid,
				initials: payload.initials,
				title: payload.title,
				name: `${payload.firstName} ${payload.lastName}`,
				firstName: payload.firstName,
				lastName: payload.lastName,
				profilePictureUrl: payload.profilePictureUrl,
				connectedToFacebook: payload.connectedToFacebook,
				instagramBusinessAccounts: !_.isEmpty(payload.instagramBusinessAccounts) ? mapInstagramBusinessAccounts(payload.instagramBusinessAccounts) : [],
				hasActiveInstagramBusinessAccounts: !_.isEmpty(payload.instagramBusinessAccounts)
					? hasActiveBusinessAccounts(payload.instagramBusinessAccounts)
					: false,
				hasBeenLinkedToInfluencers: payload.hasBeenLinkedToInfluencers,
				permissions: {
					entities: payload.permissions.entities,
					isAgent: payload.permissions.is_agent,
					isImpersonating: payload.permissions.is_impersonating,
					isInfluencer: payload.permissions.is_influencer,
					isSuperAdmin: payload.permissions.is_super_admin,
				},
				links: payload.links,
				hasCompletedProfile: hasCompletedProfile(payload),
				influencers: !_.isEmpty(payload.influencers) ? mapInfluencers(payload.influencers) : [],
				influencer: {
					id: !_.isEmpty(payload.influencers) ? payload.influencers[0].id : '',
					apiStatus: !_.isEmpty(payload.influencers) ? payload.influencers[0].apiStatus : '',
					network: !_.isEmpty(payload.influencers) ? payload.influencers[0].network : '',
					username: !_.isEmpty(payload.influencers) ? payload.influencers[0].username : '',
					followersCount: !_.isEmpty(payload.influencers) ? payload.influencers[0].followersCount : '',
					collabsId: !_.isEmpty(payload.influencers) ? payload.influencers[0].collabsId : '',
					links: !_.isEmpty(payload.influencers) ? payload.influencers[0].links : '',
					isInstagramConnected: payload.network === Network.INSTAGRAM && payload.apiStatus === API_STATUS_CONNECTED,
					country: !_.isEmpty(payload.influencers) ? payload.influencers[0].country : '',
					affiliatePreferenceRequired: !_.isEmpty(payload.influencers) ? payload.influencers[0].affiliatePreferenceRequired : '',
				},
				email: payload.email,
				mobilePhoneNumber: payload.mobilePhoneNumber,
				address: payload.address,
				address2: payload.address2,
				city: payload.city,
				countryCode: payload.countryCode,
				postalCode: payload.postalCode,
				influencerTermsOfConditionsAcceptedAt: payload.influencerTermsOfConditionsAcceptedAt,
				publisherTermsOfConditionsAcceptedAt: payload.publisherTermsOfConditionsAcceptedAt,
				needsEmailVerification: payload.needsEmailVerification,
				addressVerifiedAt: payload.addressVerifiedAt,
				payment: {
					idVerificationStatus: payload.idVerificationStatus,
					pinebucketStatus: payload.pinebucketStatus,
					type: payload.kind,
					vatNumber: payload.vatNumber,
					vatNumberValid: payload.vatNumberValid,
					vatNumberValidatedAt: payload.vatNumberValidatedAt,
				},
				isLoggingOut: false,
			};
			return state;
		},
		setInfluencer(state, { payload }: PayloadAction<InfluencerType>) {
			state.influencer = {
				id: payload.id ?? '',
				apiStatus: payload.apiStatus ?? '',
				network: payload.network ?? '',
				username: payload.username ?? '',
				followersCount: payload.followersCount ?? '',
				collabsId: payload.collabsId ?? '',
				links: payload.links,
				isInstagramConnected: payload.network === Network.INSTAGRAM && payload.apiStatus === API_STATUS_CONNECTED,
				country: payload.country ?? '',
				affiliatePreferenceRequired: payload.affiliatePreferenceRequired,
			};
			return state;
		},
		setPaymentType(state, { payload }: PayloadAction<UserPaymentType>) {
			state.payment = {
				type: payload,
			};
			return state;
		},
		setIdVerificationStatus(state, { payload }: PayloadAction<Model>) {
			state.payment = {
				idVerificationStatus: payload.attributes.idVerificationStatus,
			};
			return state;
		},
		setIdVerificationStatusToInit(state) {
			state.payment = {
				idVerificationStatus: IdVerificationStatus.INITIALIZED,
			};
			return state;
		},
		clearUser(state) {
			state = { ...initialState, isLoggingOut: state.isLoggingOut };
			return state;
		},
		startLogout(state) {
			state.isLoggingOut = true;
		},
		completeLogout(state) {
			state = { ...initialState, isLoggingOut: false };
			return state;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(setPermissions, (state, { payload }) => {
			state.permissions = {
				entities: payload.permissions.entities,
				isAgent: payload.permissions.is_agent,
				isImpersonating: payload.permissions.is_impersonating,
				isInfluencer: payload.permissions.is_influencer,
				isSuperAdmin: payload.permissions.is_super_admin,
			};
		});
	},
});

export const { setUser, clearUser, setInfluencer, setPaymentType, startLogout, completeLogout, setIdVerificationStatus, setIdVerificationStatusToInit } =
	UserSlice.actions;

export default UserSlice.reducer;
