import { Scenario } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import Icon from 'components/Icon';

import Styled from './EmptyState.style';

/**
 * EmptyState
 * Component to show a empty state
 * @returns {JSX.Element}
 */
const EmptyState = (props: { setScenario?: (scenario: Scenario) => void; scenario?: Scenario | undefined }): JSX.Element => {
	return (
		<>
			<Styled.Wrapper>
				<Styled.Headline>
					<h6>Sorry, no Stories found within that date range.</h6>
				</Styled.Headline>
				<Styled.Text>
					<p>Please verify the date and try again.</p>
				</Styled.Text>
			</Styled.Wrapper>
			<hr />
			{props.scenario !== Scenario.MANUAL_UPLOAD_SAVE && (
				<Styled.ManualStatsBox onClick={() => props.setScenario && props.setScenario(Scenario.MANUAL_UPLOAD_SAVE)}>
					<Icon name='assignment-story' size='32' />
					<h6>Add statistics manually</h6>
					<p>Add your Story stats manually by including screenshots and numbers.</p>
				</Styled.ManualStatsBox>
			)}
		</>
	);
};

export default EmptyState;
