import { Link } from 'react-router-dom';

import Card from 'components/Card';

import Styled from './Form.style';

const NotValidResetToken = () => (
	<Styled.FormCard>
		<Card.Body>
			<Styled.FormWrapper>
				<Styled.Heading>Invalid reset password link</Styled.Heading>
				<p>
					Password reset token is no longer valid. Create a new at <Link to={'/login/forgot'}>Forgot password</Link>.
				</p>
			</Styled.FormWrapper>
		</Card.Body>
	</Styled.FormCard>
);

export default NotValidResetToken;
