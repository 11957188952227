import { RootState } from 'store/User';

export const currentUser = (state: RootState) => state.user;
export const selectUserPermissions = (state: RootState) => state.user.permissions;
export const isConnected = (state: RootState) => !!state.user.id;
export const getHomePath = (state: RootState) => {
	const user = state.user;

	if (!user?.id) {
		return '/login';
	}

	if (user.permissions?.isInfluencer) {
		return '/influencer/dashboard';
	}

	return `/dashboard`;
};
