/**
 * Gutters
 * Usege for paddings, margins and so on
 * @deprecated use ./spacing.ts
 */
export const gutters = {
	xxs: '4px',
	xs: '8px',
	s: '12px',
	m: '16px',
	l: '20px',
	xl: '24px',
	xxl: '32px',
	xxxl: '40px',
};

export const guttersWithRem = {
	xxs: '0.25rem',
	xs: '0.5rem',
	s: '0.75rem',
	m: '1rem',
	l: '1.25rem',
	xl: '1.5rem',
	xxl: '2rem',
	xxxl: '2.5rem',
};
