import styled from 'styled-components';

const LoadingOverlay = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: rgba(250, 250, 250, 0.75);
	opacity: 0;
	visibility: hidden;
	transition:
		opacity 200ms ease-in-out,
		visibility 200ms ease-in-out;

	&.visible {
		opacity: 1;
		visibility: visible;
	}

	.cb-spinner {
		margin-bottom: 16px;
	}

	span {
		font-weight: 700;
	}
`;

const Styled = {
	LoadingOverlay,
};

export default Styled;
