/**
 * Default global font sizes
 * This file keeps the font sizes.
 * NOTE: sm, md and lg is deprecated
 */
const fontSize = {
	sm: '10px',
	md: '12px',
	lg: '16px',
	s: '0.875rem',
	sMobile: '0.938rem',
	m: '1rem',
	l: '1.25rem',
	xl: '1.5rem',
};

export default fontSize;
