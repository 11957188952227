import styled from 'styled-components';

import { DarkButton } from 'components/Button';
import Grid from 'styles/grid/grid';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints } from 'styles/variables/media-queries';

const GridColumn = Grid.Column;
const Wrapper = styled(GridColumn)``;

const InnerWrapper = styled.div`
	width: 100%;
	height: auto;
	padding: ${guttersWithRem.m};

	border-radius: ${borderRadius.m};
	background-color: ${colors.gray13};

	position: relative;
`;

const OptionButtonWrapper = styled.div`
	position: absolute;
	right: 0;
	top: 0;

	& > div > div > button {
		&:hover,
		&.show {
			background-color: ${colors.influencerDashboard.invitation.border} !important;
		}
	}
`;

const InvitationContentWrapper = styled.div`
	display: flex;
	column-gap: ${guttersWithRem.m};
	overflow: hidden;
`;

const CoverImageWrapper = styled.div`
	width: 104px;
	height: 104px;

	& > img {
		width: 104px;
		height: 104px;
		object-fit: cover;
		border-radius: ${borderRadius.s};
	}
`;

const InvitationContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 8px;
`;

const Text = styled.div`
	line-height: 1.2;

	&.title {
		max-width: 250px;
		font-weight: 700;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@media (max-width: ${breakpoints.sm}) {
			max-width: 150px;
		}
	}

	&.brand {
	}

	&.commission-label {
	}

	&.commission-value {
		font-weight: 700;
	}
`;

const BottomContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	@media (max-width: ${breakpoints.sm}) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const GoToBriefButton = styled(DarkButton)`
	padding-left: ${guttersWithRem.m};
	padding-right: ${guttersWithRem.m};
	background-color: ${newColors.oceanBlue};
	border: 1px solid ${newColors.oceanBlue};
	&:hover:not(:disabled, .disabled) {
		color: ${colors.white};
		.icon path {
			fill: ${colors.white};
		}
	}
	margin-top: 16px;
	width: 100%;
`;

const DropdownItemWrapper = styled.div`
	font-size: 0.875rem;
	display: flex;
	column-gap: ${guttersWithRem.xs};
	align-items: center;

	& > span > span {
		&.gray {
			color: ${colors.influencerDashboard.invitation.imageBorder};
		}
	}
`;

const Styled = {
	Wrapper,
	InnerWrapper,
	OptionButtonWrapper,
	InvitationContentWrapper,
	CoverImageWrapper,
	InvitationContent,
	Text,
	BottomContent,
	GoToBriefButton,
	DropdownItemWrapper,
};

export default Styled;
