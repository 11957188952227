import { TableItem } from './Types';

export const sortItems = (a: TableItem, b: TableItem) => {
	// Sort years
	if (a.year > b.year) return -1;
	if (a.year < b.year) return 1;

	// If year are the same, sort month
	if (a.month < b.month) return -1;
	if (a.month > b.month) return 1;

	// If year and month are the same, return 0
	return 0;
};
