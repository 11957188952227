/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { UpdateInfluencerListItemCommentPayload, AddCommentOnInfluencerListItemPayload } from 'api-payloads';
import { UpdateInfluencerListItemCommentQuery, AddCommentOnInfluencerListItemQuery } from 'api-queries';
import { InfluencerListListItemCommentResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import InfluencerListItemCommentApiClientInterface from './InfluencerListItemCommentApiClientInterface';

@injectable()
export default class InfluencerListItemCommentApiClient implements InfluencerListItemCommentApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Update influencer list item comment
	 */
	async update(
		itemId: string,
		commentId: string,
		payload: UpdateInfluencerListItemCommentPayload,
		params: UpdateInfluencerListItemCommentQuery,
	): Promise<InfluencerListListItemCommentResponse> {
		const response = await this.client.patch<InfluencerListListItemCommentResponse>(`/items/${itemId}/comments/${commentId}`, payload, { params });
		return response.data;
	}

	/**
	 * Add comment on influencer list item
	 */
	async addCommentOnInfluencerListItem(
		listId: string,
		itemId: string,
		payload: AddCommentOnInfluencerListItemPayload,
		params: AddCommentOnInfluencerListItemQuery,
	): Promise<InfluencerListListItemCommentResponse> {
		const response = await this.client.post<InfluencerListListItemCommentResponse>(`/lists/${listId}/items/${itemId}/comment`, payload, { params });
		return response.data;
	}
}
