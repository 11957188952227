import classNames from 'classnames';
import { isNil } from 'lodash';
import { useState, useEffect, useRef, useMemo, RefObject } from 'react';

import Checkbox from 'components/Checkbox';
import LoadingSpinner from 'components/LoadingSpinner';
import { LeftColumn, RightColumn, TwoColumnModal } from 'components/Modals/TwoColumnModal';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';
import usePermissions from 'hooks/usePermissions';
import { ROLE_PROJECT_MANAGER } from 'hooks/usePermissions/types';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { breakpoints } from 'styles/variables/media-queries';

import CampaignInfo from './CampaignInfo/CampaignInfo';
import Styled from './JoinModal.style';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';

function useIsInViewport(ref: RefObject<HTMLDivElement>) {
	const [isIntersecting, setIsIntersecting] = useState(false);
	const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), []);

	useEffect(() => {
		!isNil(ref) && observer.observe(ref.current as HTMLDivElement);
		return () => {
			observer.disconnect();
		};
	}, [ref, observer]);

	return isIntersecting;
}

const JoinTitle = () => {
	return (
		<Styled.JoinTitle>
			<Styled.Text>Review and join campaign</Styled.Text>
		</Styled.JoinTitle>
	);
};

type JoinModalProps = {
	onPreview?: boolean;
	isModalOpen: boolean;
	toggleModal: (isModalOpen: boolean) => void;
	campaignInstagramOwner: CampaignInstagramOwner;
	campaign: Campaign;
	join: () => void;
	isLoading?: boolean;
};
/**
 * @returns JSX.Element
 */
const JoinModal = ({ isLoading, campaign, isModalOpen, toggleModal, campaignInstagramOwner, join, onPreview }: JoinModalProps): JSX.Element => {
	const [selectedNavId, setSelectedNavId] = useState<string>('campaign-info');
	const [userHasAgreed, setUserHasAgreed] = useState<boolean>(false);
	const [userHasAgreedCollabs, setUserHasAgreedCollabs] = useState<boolean>(false);

	const [isActive, setIsActive] = useState<boolean>(false);
	const [userHasRead, setUserHasRead] = useState<string[]>(['Campaign Info']);

	const CampaignInfoRef = useRef<HTMLDivElement | null>(null);
	const TermsRef = useRef<HTMLDivElement | null>(null);
	const agreeBoxRef = useRef<HTMLDivElement | null>(null);
	const isTermsRefViewport = useIsInViewport(TermsRef);
	const { isGranted } = usePermissions();
	const canEdit = isGranted(ROLE_PROJECT_MANAGER);

	const { width } = useWindowDimensions();
	const isMobileView = width <= +breakpoints.lg.split('px')[0];
	const Navigation = [
		{
			title: 'Campaign Info',
			id: 'campaign-info',
			component: <CampaignInfo onPreview={onPreview} campaignInfoRef={CampaignInfoRef} campaignInstagramOwner={campaignInstagramOwner} campaign={campaign} />,
		},
		{
			title: 'Terms & Conditions',
			id: 'terms',
			component: <TermsAndConditions termsRef={TermsRef} campaign={campaign} onPreview={onPreview} canEdit={canEdit} />,
		},
	];

	const scrollToTarget = (target: string) => {
		switch (target) {
			case 'campaign-info':
				CampaignInfoRef.current?.scrollIntoView({ behavior: 'smooth' });
				break;
			case 'terms':
				TermsRef.current?.scrollIntoView({ behavior: 'smooth' });
				break;
			default:
				CampaignInfoRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
	};

	useEffect(() => {
		if (isTermsRefViewport) {
			setUserHasRead((prev) => {
				return prev.some((readItem) => readItem === 'Terms & Conditions') ? prev : [...prev, 'Terms & Conditions'];
			});
		}
	}, [isTermsRefViewport]);

	useEffect(() => {
		return () => {
			setSelectedNavId('campaign-info');
			setUserHasAgreed(false);
			setUserHasRead([]);
		};
	}, []);

	useEffect(() => {
		if (isModalOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [isModalOpen]);

	const handleClickOutside = (event: MouseEvent): void => {
		if (agreeBoxRef.current && !agreeBoxRef.current.contains(event.target as Node)) {
			setIsActive(false);
		}
	};
	const handleEscapeKey = (e: KeyboardEvent): void => {
		if (agreeBoxRef.current && e.key === 'Escape') {
			setIsActive(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', handleClickOutside, true);
		document.addEventListener('keyup', handleEscapeKey, true);
		return () => {
			window.removeEventListener('click', handleClickOutside, true);
			document.addEventListener('keyup', handleEscapeKey, true);
		};
	}, []);

	return (
		<TwoColumnModal isModalOpen={isModalOpen} toggleModal={() => toggleModal(!isModalOpen)}>
			<>
				<LeftColumn className={classNames({ hide: isMobileView })}>
					<Styled.ListWithLinks>
						{Navigation.map((item, i) => {
							return (
								<li
									key={i}
									className={classNames({ selected: item.id === selectedNavId })}
									onClick={() => {
										scrollToTarget(item.id);
										setSelectedNavId(item.id);
										setUserHasRead(!userHasRead.includes(item.title) ? [...userHasRead, item.title] : userHasRead);
									}}
								>
									<Styled.CheckMarkContainer>
										{userHasRead.includes(item.title)}
										<span>{item.title}</span>
									</Styled.CheckMarkContainer>
								</li>
							);
						})}
					</Styled.ListWithLinks>
				</LeftColumn>
				<RightColumn title={<JoinTitle />}>
					<Styled.CampaignInfo className={classNames({ isMobile: isMobileView })}>
						{Navigation.map((nav, i) => {
							return (
								<li id={nav.id} key={i}>
									{nav.component}
								</li>
							);
						})}
					</Styled.CampaignInfo>
					<Styled.BottomPartContainer>
						<Styled.AgreeSection
							ref={agreeBoxRef}
							data-testid='agree-chk'
							className={classNames({ isActive: userHasAgreed && isActive })}
							onClick={() => {
								setUserHasAgreed(!userHasAgreed), setIsActive(true);
							}}
						>
							<Styled.CheckBoxContainer>
								<Checkbox readOnly checked={userHasAgreed} />
							</Styled.CheckBoxContainer>
							<Styled.AgreeSectionText>I have agreed to the above terms.</Styled.AgreeSectionText>
						</Styled.AgreeSection>
						<Styled.AgreeSection
							ref={agreeBoxRef}
							data-testid='agree-chk-collabs'
							className={classNames({ isActive: userHasAgreedCollabs && isActive })}
							onClick={() => {
								setUserHasAgreedCollabs(!userHasAgreedCollabs), setIsActive(true);
							}}
						>
							<Styled.CheckBoxContainer>
								<Checkbox readOnly checked={userHasAgreedCollabs} />
							</Styled.CheckBoxContainer>
							<Styled.AgreeSectionText>
								I agree to Collabs handling my{' '}
								<a href='https://www.collabs.se/terms-of-service' target='_blank' rel='noreferrer'>
									personal information
								</a>
								.
							</Styled.AgreeSectionText>
						</Styled.AgreeSection>
						<Styled.ButtonSection>
							<Styled.CancelButton
								size='sm'
								onClick={() => {
									setUserHasAgreed(false);
									setUserHasAgreedCollabs(false);
									toggleModal(false);
								}}
							>
								Cancel
							</Styled.CancelButton>
							<Styled.JoinButton size='sm' data-testid='join-campaign' disabled={!userHasAgreed || !userHasAgreedCollabs || onPreview} onClick={() => join()}>
								{isLoading ? <LoadingSpinner size={'sm'} /> : 'Join now'}
							</Styled.JoinButton>
						</Styled.ButtonSection>
					</Styled.BottomPartContainer>
				</RightColumn>
			</>
		</TwoColumnModal>
	);
};

export default JoinModal;
