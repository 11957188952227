import { Model } from 'json-api-models';

import StatusTag from 'components/StatusTag';

type Props = {
	items?: Model[];
};

const ClientInvoices = ({ items }: Props) => {
	return (
		<ul className='list-unstyled'>
			{items && items?.length > 0
				? items?.map(({ id, attributes }) => (
						<li className='list-inline-item' key={id}>
							<StatusTag status={attributes.paidAt ? 'paid' : 'unpaid'}>#{attributes.fortnoxId ?? 'No invoice number'}</StatusTag>
						</li>
					))
				: ''}
		</ul>
	);
};

export default ClientInvoices;
