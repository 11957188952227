import { useEffect } from 'react';

import { ModalHeader, ModalBody, ModalFooter } from './Modal.style';
import ModalPortal from './ModalPortal';
import { IModalProps } from './types';

/**
 * Modal
 * @param {IModalProps} props
 * @returns {JSX.Element}
 */
const Modal = (props: IModalProps) => {
	useEffect(() => {
		if (props.open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [props.open]);

	useEffect(() => {
		return () => {
			document.body.classList.remove('disable-scroll');
			document.body.style.overflow = 'unset';
		};
	}, []);

	return (
		<ModalPortal
			open={props.open}
			handleClose={props.handleClose}
			onShown={props.onShown}
			size={props.size}
			className={props.className}
			role={props.role}
			onAfterClose={props.onAfterClose}
			domNode={props.domNode}
			dataTestId={props.dataTestId}
		>
			{props.children}
		</ModalPortal>
	);
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
