/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { SearchQuery } from 'api-queries';
import { ApiInfluencerSearchResultCollectionResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import ApiApiClientInterface from './ApiApiClientInterface';

@injectable()
export default class ApiApiClient implements ApiApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Search
	 */
	async search(params: SearchQuery): Promise<ApiInfluencerSearchResultCollectionResponse> {
		const response = await this.client.get<ApiInfluencerSearchResultCollectionResponse>(`/api/search`, { params });
		return response.data;
	}
}
