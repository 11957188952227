import { Formik, Form } from 'formik';
import { useState } from 'react';

import { GhostButton } from 'components/Button';
import { Select } from 'components/Form/FormikElements';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import useInjection from 'hooks/useInjection';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import toast from 'services/Toast';
import { UserType } from 'shared/Types/User';
import Styled from 'views/admin/Components/Table.style';

import type GenericApiClientInterface from 'services/ApiClient/GenericApiClientInterface';

type Props = {
	editUrl: string;
	currentValue: UserType;
};
const UserKindEditor = ({ editUrl, currentValue }: Props) => {
	const [displayedValue, setDisplayedValue] = useState(currentValue);
	const [editMode, setEditMode] = useState(false);
	const client = useInjection<GenericApiClientInterface>(ApiClientService.GenericApiClientInterface);

	return (
		<>
			{editMode ? (
				<Formik
					initialValues={{ userType: displayedValue }}
					onSubmit={async ({ userType }) => {
						try {
							await client.patch(editUrl, { type: userType });
							// Avoid the need to refresh the whole list for one changed value.
							setDisplayedValue(userType);
						} catch (e) {
							toast.error('Sorry, we were unable to update user type');
						}
						setEditMode(false);
					}}
				>
					{({ isSubmitting, handleSubmit }) => (
						<Form>
							<Styled.InputWrapper className='d-flex justify-between align-center'>
								<Select
									name='userType'
									size='md'
									options={[
										{ label: 'Individual', value: 'individual' },
										{ label: 'Company', value: 'company' },
										{ label: 'Agent', value: 'agent' },
										{ label: 'Agent (Legacy)', value: 'legacy_agent' },
									]}
								/>
								<Styled.IconWrapper role='button' onClick={() => handleSubmit()}>
									{isSubmitting ? <LoadingSpinner size='sm' /> : <Icon name='checkmark' size='14' />}
								</Styled.IconWrapper>
								<Styled.IconWrapper
									role='button'
									onClick={() => {
										setEditMode(false);
									}}
								>
									<Icon name='cross' size='14' />
								</Styled.IconWrapper>
							</Styled.InputWrapper>
						</Form>
					)}
				</Formik>
			) : (
				<GhostButton
					onClick={() => {
						setEditMode(true);
					}}
					size='sm'
				>
					{displayedValue}
					<Icon name='pen' size='12' />
				</GhostButton>
			)}
		</>
	);
};

export default UserKindEditor;
