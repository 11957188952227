import { Scenario } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import Icon from 'components/Icon';

import Styled from './EmptyStatueAutoState.style';

type EmptyStateAutoTextProps = {
	setScenario: (scenario: Scenario) => void;
};
/**
 * Statistics for approved assignments
 * @returns {JSX.Element}
 */
const EmptyStateAutoText = ({ setScenario }: EmptyStateAutoTextProps) => {
	return (
		<Styled.ManualStatsBox>
			<Icon name='instagram' size='32' />
			<h6>Don&apos;t see your post?</h6>
			<p>
				It may not have been posted yet, or we might be unable to locate it. <br />
				<p className='link' onClick={() => setScenario(Scenario.MANUAL_UPLOAD_SAVE)}>
					Add your statistics manually.
				</p>
			</p>
		</Styled.ManualStatsBox>
	);
};

export default EmptyStateAutoText;
