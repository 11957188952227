import { AxiosInstance } from 'axios';
import { JsonApiDocument } from 'json-api-models';

import { createClient } from 'shared/ApiClient/ApiClient';

/**
 * @deprecated use InfluencerListManager instead. The InfluencerListManager is part of our new ApiManager pattern.
 */
class ListItemsService {
	ENDPOINT_URL: string;
	private client: AxiosInstance;

	constructor() {
		this.client = createClient();
	}

	async addReactionOnListItem(url: string, reaction: string) {
		const response = await this.client.post<JsonApiDocument>(`${url}`, { reaction: reaction });
		return response;
	}

	async updateListItemReaction(url: string, reaction: string) {
		const response = await this.client.patch<JsonApiDocument>(`${url}`, { reaction: reaction });
		return response;
	}

	async addCommentOnListItem(url: string, comment: string) {
		const response = await this.client.post<JsonApiDocument>(`${url}`, { comment: comment });
		return response;
	}

	async updateListItemComment(url: string, comment: string) {
		const response = await this.client.patch<JsonApiDocument>(`${url}`, { comment: comment });
		return response;
	}

	async deleteOnListItem(url: string) {
		const response = await this.client.delete<JsonApiDocument>(`${url}`);
		return response;
	}
}

export default new ListItemsService();
