import styled from 'styled-components';

import { spacing } from 'styles/variables/spacings';

const Wrapper = styled.div`
	max-width: 574px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 1.875rem;
	height: 100vh;
	width: 100%;

	h1 {
		margin-bottom: ${spacing[3].px};
	}

	p {
		margin-bottom: ${spacing[4].px};
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
