import Styled from './Footer.style';
import { IFooter } from './types';

const Footer = (props: IFooter) => {
	return (
		<Styled.Wrapper display={props.display} justifyContent={props.justifyContent} alignItems={props.alignItems} className='card__footer'>
			{props.children}
		</Styled.Wrapper>
	);
};

export default Footer;
