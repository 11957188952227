import { Form, Formik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { DarkButton } from 'components/Button';
import DropdownAndInput from 'components/Form/FormikElements/DropdownAndInput';
import InputText from 'components/Form/FormikElements/Text';
import LoadingSpinner from 'components/LoadingSpinner';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import PhoneNumber from 'models/PhoneNumber';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import { StatusCode } from 'services/Response.types';
import { createClient } from 'shared/ApiClient/ApiClient';
import { silentRefreshUser } from 'shared/User/User.helpers';
import errorHandler from 'utils/formik_error_handler';

import { PersonalInformationProps } from './types';

/**
 */
const PersonalInformation = ({ userId, isNewOrganization, onSubmit, incomplete }: PersonalInformationProps) => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const user = useAppSelector((state) => state.user);
	const [termsAccepted, setTermsAccepted] = useState(user.publisherTermsOfConditionsAcceptedAt !== null || user.influencerTermsOfConditionsAcceptedAt !== null);
	const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

	const { firstName, lastName } = user;
	const phoneNumber = parsePhoneNumber(user.mobilePhoneNumber ?? '');

	const INITIAL_VALUES = {
		firstname: firstName,
		surname: lastName,
		mobilePhoneNumber: phoneNumber?.nationalNumber ?? '',
		mobilePrefix: phoneNumber?.countryCallingCode ?? '+46',
		acceptTermsAndConditions: user.hasActiveInstagramBusinessAccounts || user.influencerTermsOfConditionsAcceptedAt,
	};

	return (
		<Formik
			onSubmit={async ({ firstname, surname, mobilePhoneNumber, mobilePrefix }, { setErrors }) => {
				try {
					const client = createClient();
					const fullName = `${firstname} ${surname}`;
					const mobileNumber = `${mobilePrefix}${mobilePhoneNumber}`;

					if (!isNewOrganization) {
						await client.patch(`/users/${userId || user.id}`, { name: fullName, firstName: firstname, lastName: surname });
						onSubmit();
					} else {
						if (termsAccepted) {
							await client
								.patch(`/users/${userId || user.id}`, { name: fullName, firstName: firstname, lastName: surname, mobilePhoneNumber: mobileNumber })
								.then((res) => {
									if (res.status === StatusCode.OK) {
										if (!user.publisherTermsOfConditionsAcceptedAt) {
											authService.agreeTermsAndCondition('publisher');
										} else {
											silentRefreshUser();
										}
										onSubmit();
									}
								})
								.catch((e) => {
									errorHandler(e, setErrors);
								});
						} else {
							setErrors({ acceptTermsAndConditions: 'You must accept terms and conditions.' });
						}
					}
				} catch (e) {
					errorHandler(e, setErrors);
				}
			}}
			validationSchema={Yup.object().shape({
				firstname: Yup.string().required('Required'),
				surname: Yup.string().required('Required'),
				mobilePhoneNumber: isNewOrganization ? Yup.string().required('Required') : Yup.string(),
			})}
			initialValues={INITIAL_VALUES}
			validateOnBlur={validateAfterSubmit}
			validateOnChange={validateAfterSubmit}
		>
			{({ isSubmitting, isValid, values }) => (
				<Form>
					<p className='muted'>{!incomplete && `Step 2 of ${isNewOrganization ? '3' : '2'} -`} Personal information</p>
					<fieldset>
						<InputText name='firstname' label='First name' required />
						<InputText name='surname' label='Surname' required />
						{isNewOrganization && (
							<DropdownAndInput
								label='Phone number'
								inputType='number'
								options={PhoneNumber.getPhonePrefixes().map((prefix) => ({ value: prefix, label: prefix }))}
								size=''
								selectName='mobilePrefix'
								selectId='mobilePrefix'
								selectValue={values.mobilePrefix}
								name='mobilePhoneNumber'
								id='mobilePhoneNumber'
								value={values.mobilePhoneNumber}
								required
								className='mb-24'
							/>
						)}
						{isNewOrganization && (
							<label className='label-chk'>
								<input type='checkbox' checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} />
								<span>
									I agree to the{' '}
									<a href='https://www.collabs.se/terms-of-service' target='_blank' rel='noreferrer'>
										Collabs Terms
									</a>
								</span>
							</label>
						)}
					</fieldset>

					<DarkButton
						type='submit'
						disabled={isSubmitting || !isValid || (isNewOrganization ? !termsAccepted : false)}
						onClick={() => setValidateAfterSubmit(!validateAfterSubmit)}
					>
						{isSubmitting ? <LoadingSpinner size='sm' position='center' /> : isNewOrganization ? 'Next step' : incomplete ? 'Update' : 'Create account'}
					</DarkButton>
					{!incomplete && (
						<p className='text-center my-l mb-0'>
							Have an account? <Link to='/login'>Log in</Link>
						</p>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default PersonalInformation;
