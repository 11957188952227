import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgArrowLargeRight(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				d='M19.7 9.28L13.66 3.24C13.34 2.92 12.83 2.92 12.52 3.24C12.2 3.56 12.2 4.07 12.52 4.38L17.39 9.25H0.75C0.34 9.25 0 9.59 0 10C0 10.41 0.34 10.75 0.75 10.75H17.39L12.52 15.62C12.2 15.94 12.2 16.45 12.52 16.76C12.68 16.92 12.88 17 13.09 17C13.3 17 13.5 16.92 13.66 16.76L19.7 10.72C20.1 10.32 20.1 9.67 19.7 9.27V9.28Z'
				fill='#333333'
			/>
		</svg>
	);
}
