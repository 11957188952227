import Styled from './BroughtToYouBy.style';

type BroughtToYouByProps = {
	logo?: string;
	name: string;
};

/**
 * Left side page navigation.
 * @returns {JSX.Element}
 */
const BroughtToYouBy = ({ logo, name }: BroughtToYouByProps): JSX.Element => {
	return (
		<Styled.Wrapper>
			<Styled.Border />
			<Styled.Title>Brought to you by:</Styled.Title>
			<Styled.Publisher>
				<img src={logo} alt={name} />
				<p>{name}</p>
			</Styled.Publisher>
		</Styled.Wrapper>
	);
};

export default BroughtToYouBy;
