import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import { fontWeights } from 'styles/variables/font-weights';
import { guttersWithRem } from 'styles/variables/gutter';
import { devices } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${guttersWithRem.m};
`;

const ModalHeader = styled.div`
	display: flex;

	button {
		background: none;
		border: none;
		padding: 0;
		margin-left: auto;
		cursor: pointer;
	}
`;

const PostItemWrapper = styled.div`
	max-height: 400px;
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
`;

const LinkToPost = styled.div<{ img: string }>`
	width: 100%;
	height: 300px;
	border-radius: ${borderRadius.m};
	background-color: ${newColors.mist};
	background: url(${(props) => props.img ?? ''});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@media screen and (${devices.lg}) {
		width: 220px;
	}
`;

const DateInfo = styled.div`
	font-size: ${typography.label.small.fontSize};
	color: ${newColors.slate};
	padding: 0 ${guttersWithRem.xs};
	text-decoration: none;
	.icon path {
		fill: ${newColors.slate};
	}
`;

const PostItem = styled.a`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;

	text-decoration: none;
	border: 2px solid transparent !important;
	&:hover {
		${LinkToPost} {
			border: 2px solid ${newColors.skyBlue};
		}
		.icon path {
			fill: ${newColors.black};
		}
		${DateInfo} {
			font-weight: ${fontWeights.semiBold};
			color: ${newColors.black};
		}
	}
	@media screen and (${devices.lg}) {
		width: 220px;
	}
`;

const Reason = styled.div`
	span {
		font-size: 0.9375rem;
	}
`;

const DateWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const Styled = {
	ContentWrapper,
	ModalHeader,
	PostItemWrapper,
	PostItem,
	DateInfo,
	LinkToPost,
	Reason,
	DateWrapper,
};

export default Styled;
