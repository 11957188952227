class InMemoryStorage implements Storage {
	private store: Map<string, string>;

	constructor() {
		this.store = new Map();
	}

	get length() {
		return this.store.size;
	}

	clear(): void {
		this.store.clear();
	}

	getItem(key: string): string | null {
		return this.store.get(key) ?? null;
	}

	key(index: number): string | null {
		return this.getItem(index.toString());
	}

	removeItem(key: string): void {
		this.store.delete(key);
	}

	setItem(key: string, value: string): void {
		this.store.set(key, value);
	}
}

export const instance = new InMemoryStorage();

export default InMemoryStorage;
