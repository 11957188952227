import { createContext, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Network } from 'constants/socialMedia';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import DiscoveryAuthService from 'services/Authentication/Discovery-api';
import DiscoveryService from 'services/DiscoveryApi';
import { StatusCode } from 'services/Response.types';
import toast from 'services/Toast';

import { buildUrlQuery, defaultFilter, formatFilter, getGender, mapResult, numberOrNull } from './helpers';
import { DiscoveryContextType, DiscoveryContextProps, FilterType, InfluencerListItemType } from './types';

const DiscoveryContext = createContext<DiscoveryContextType>({
	isLoading: false,
	isLoadingNext: false,
	searchHandler: () => ({ influencers: [] }),
	searchResult: null,
	nextSearchResult: null,

	changeSearchValueHandler: () => {},
	searchText: null,
	hasSearched: false,
	setHasSearched: (_hasSearched: boolean) => {},

	filter: defaultFilter,
	setFilter: () => {},
	resetFilter: () => {},

	updateBrandAffiliationsHandler: () => {},
	updateHashtagsHandler: () => {},

	updateCountryHandler: () => {},
	updateGenderHandler: () => {},
	updateAudienceAgeRangeHandler: () => {},
	updateNetworkHandler: (_network: Network) => {},

	page: null,
	setPage: () => {},
	isLastPage: false,
	setIsLastPage: () => {},

	isMessageModalOpen: false,
	messageTargetInfluencer: undefined,
	setMessageTargetInfluencer: (_influencer: InfluencerListItemType | undefined) => {},
	openMessageModal: (_influencer: InfluencerListItemType) => {},
	closeMessageModal: () => {},
});

export const DiscoveryContextProvider = ({ children }: DiscoveryContextProps) => {
	const { search: searchUrl, pathname: discoveryUrl } = useLocation();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingNext, setIsLoadingNext] = useState<boolean>(true);

	const [searchText, setSearchText] = useState<string | null>(null);
	const [isSearchTextReset, setIsSearchTextReset] = useState(false);

	const [filter, setFilter] = useState<FilterType>(defaultFilter);
	const [page, setPage] = useState<string>('0');

	const [searchResult, setSearchResult] = useState<InfluencerListItemType[] | null>(null);
	const [nextSearchResult, setNextSearchResult] = useState<InfluencerListItemType[] | null>(null);
	const [isLastPage, setIsLastPage] = useState<boolean>(false);
	const [hasSearched, setHasSearched] = useState(false); // Track if a search has been done

	const [isMessageModalOpen, setIsMessageModalOpen] = useState<boolean>(false);
	const [messageTargetInfluencer, setMessageTargetInfluencer] = useState<InfluencerListItemType | undefined>(undefined);

	const queryString = new URLSearchParams(searchUrl);

	useEffect(() => {
		parseUrlHandler();
	}, []);

	useEffect(() => {
		setSearchResult(null);
		urlChangeHandler();
		setPage('0');
	}, [searchText, filter]);

	const defaultNetwork = '?networks=instagram';
	const tiktokNetwork = '?networks=tiktok';

	useEffect(() => {
		if (searchUrl.length > 0) {
			if (searchUrl === defaultNetwork) {
				setHasSearched(false);
				return;
			}
			if (searchUrl === tiktokNetwork) {
				setHasSearched(false);
				return;
			}

			setHasSearched(true);
			searchHandler();
		} else {
			setHasSearched(false);
		}
	}, [searchUrl]);

	// If page is 0 it means it is the first request and searchResult should be set. If page is 1 or higher it means that the searchResult should get updated with the new influencers.
	useEffect(() => {
		if (page === '0') {
			setSearchResult(null);
		} else {
			searchUrl.length > 0 && searchHandler();
		}
	}, [page]);

	useEffect(() => {
		if (nextSearchResult?.length === 0) {
			setIsLastPage(true);
		} else {
			if (searchResult !== null && nextSearchResult !== null) {
				const filteredComingResult = nextSearchResult.filter(
					(comingResult) => !searchResult.some((currentResultItem) => currentResultItem.id === comingResult.id),
				);
				const newSearchResult = [...searchResult, ...filteredComingResult];
				setSearchResult(newSearchResult);
			} else {
				setSearchResult(nextSearchResult);
			}
		}
	}, [nextSearchResult]);

	useEffect(() => {
		if (searchUrl === '' && !isSearchTextReset) {
			setSearchText(null);
		}
	}, [isSearchTextReset]);

	useEffect(() => {
		if (searchText !== null) {
			setIsSearchTextReset(false);
		}
	}, [searchText]);

	const extractFilterFromParams = () => {
		return {
			minAge: numberOrNull(queryString.get('minAge')),
			maxAge: numberOrNull(queryString.get('maxAge')),
			genders: queryString.get('genders'),
			countries: queryString.get('countries'),
			minFollowers: numberOrNull(queryString.get('minFollowers')),
			maxFollowers: numberOrNull(queryString.get('maxFollowers')),
			minEI: numberOrNull(queryString.get('minEI')),
			maxEI: numberOrNull(queryString.get('maxEI')),
			brandAffiliations: queryString.get('brandAffiliations'),
			excludedBrandAffiliations: queryString.get('excludedBrandAffiliations'),
			hashtags: queryString.get('hashtags'),
			audienceAgeRanges: queryString.get('audienceAgeRanges'),
			audienceGenders: queryString.get('audienceGenders'),
			audienceCountries: queryString.get('audienceCountries'),
			networks: queryString.get('networks'),
		};
	};

	const extractQueryFromParams = () => {
		return queryString.get('q');
	};

	// Search handlers -----------------------------------
	const parseUrlHandler = () => {
		if (searchUrl) {
			setHasSearched(true);
			const querySearchText = extractQueryFromParams();

			// Extract other filter parameters
			const qFilter: FilterType = extractFilterFromParams();

			// Only update searchText if it has changed
			if (searchText !== querySearchText) {
				setSearchText(querySearchText);
			}

			// Only update the filter if it has actually changed
			if (JSON.stringify(filter) !== JSON.stringify(qFilter)) {
				setFilter(qFilter);
			}

			const newPage = queryString.get('page') ? queryString.get('page') : '0';
			if (newPage && newPage !== page) {
				setPage(newPage);
			}
		} else {
			setHasSearched(false);
		}
	};

	const urlChangeHandler = () => {
		const newUrl = buildUrlQuery(filter, searchText);

		if (searchUrl !== newUrl && newUrl.length > 1) navigate(`${discoveryUrl}${newUrl}`);
	};

	const changeSearchValueHandler = (param: { text: string | null }) => {
		setSearchText(param.text);
	};

	const cancel = useRef<AbortController | null>(null);

	const searchHandler = () => {
		if (cancel.current) {
			cancel.current.abort();
		}
		cancel.current = new AbortController();

		if (page === '0') {
			setIsLoading(true);
		} else {
			setIsLoadingNext(true);
		}

		const filterQueryParam: string | null = searchUrl ? formatFilter(extractFilterFromParams()) : null;

		setTimeout(() => {
			DiscoveryService.searchInfluencer(
				{
					searchWord: extractQueryFromParams(),
					filter: filterQueryParam,
				},
				page,
				cancel.current?.signal ?? null,
			)
				.then((res) => mapResult(res))
				.then(({ influencers }) => {
					setNextSearchResult(influencers);
				})
				.catch((err) => {
					if (err.name !== 'AbortError' && err.response) {
						// Handle errors other than abort errors
						if (err.response.status === StatusCode.UNAUTHORIZED) {
							DiscoveryAuthService.requestDiscoveryToken();
						}
						const error = err.response.data.errors[0];
						toast.error(error ? error.title : getSomethingWentWrongMessage());
					}
				})
				.finally(() => {
					setIsLoading(false);
					setIsLoadingNext(false);
				});
		}, 500);
	};

	// Filter handlers -----------------------------------

	const updateBrandAffiliationsHandler = (value: string, option: 'include' | 'exclude', action: 'add' | 'remove') => {
		const brandMentionKey = option === 'include' ? 'brandAffiliations' : 'excludedBrandAffiliations';

		setFilter((prev: FilterType) => {
			if (!prev) return { [brandMentionKey]: action === 'add' ? value : null };

			const currentValues = prev[brandMentionKey] ? prev[brandMentionKey]!.split(',') : [];
			let updatedValues;

			if (action === 'add') {
				// Add the new value, ensuring no duplicates
				updatedValues = [...new Set([...currentValues, value])].join(',');
			} else if (action === 'remove') {
				// Remove the value
				updatedValues = currentValues.filter((item) => item !== value).join(',') || null;
			}

			return { ...prev, [brandMentionKey]: updatedValues };
		});
	};

	const updateHashtagsHandler = (value: string, action: 'add' | 'remove') => {
		setFilter((prev: FilterType) => {
			if (!prev) {
				return { hashtags: action === 'add' ? value : null };
			}

			const currentHashtags = prev.hashtags ? prev.hashtags.split(',') : [];

			let newHashtags;
			if (action === 'add') {
				// Add the hashtag if it's not already in the list
				newHashtags = [...new Set([...currentHashtags, value])].join(',');
			} else if (action === 'remove') {
				// Remove the hashtag
				newHashtags = currentHashtags.filter((hashtag) => hashtag !== value).join(',') || null;
			}

			return { ...prev, hashtags: newHashtags };
		});
	};

	const updateGenderHandler = (value: string, option: string) => {
		const key = option;
		let newGenders: string | null = null;
		if (filter) {
			if (filter[key]) {
				const currentGenders = filter[key]!.split(',');
				if (currentGenders.some((gender: string) => gender === getGender(value))) {
					newGenders = currentGenders.filter((gender: string) => gender !== getGender(value)).join(',');
				} else {
					newGenders = currentGenders.concat([getGender(value)]).join(',');
				}
			} else {
				newGenders = getGender(value);
			}
		}

		setFilter((prev: FilterType) => {
			return {
				...prev,
				[key]: newGenders,
			};
		});
	};

	const updateCountryHandler = (value: string, option: string) => {
		const key = option;
		let newCountries: string | null = null;

		if (filter) {
			if (filter[key]) {
				const currentCountries = filter[key]!.split(',');

				// Check if the country already exists in the current list
				if (currentCountries.includes(value)) {
					// If the country exists, remove it
					newCountries = currentCountries.filter((country: string) => country !== value).join(',');
				} else {
					// If the country doesn't exist, add it
					newCountries = currentCountries.concat([value]).join(',');
				}
			} else {
				// If there are no current countries, start with the selected value
				newCountries = value;
			}
		}

		setFilter((prev: FilterType) => {
			return {
				...prev,
				[key]: newCountries,
			};
		});
	};

	const updateAudienceAgeRangeHandler = (value: string) => {
		let newAudienceAgeRange: string | null = null;

		// Normalize the value: treat '65+' as '65'
		const normalizedValue = value === '65+' ? '65' : value;

		if (filter) {
			if (filter.audienceAgeRanges) {
				const currentAgeRanges = filter.audienceAgeRanges.split(',');

				// Check if the normalized value (e.g. '65') is already in the current list
				if (currentAgeRanges.includes(normalizedValue)) {
					// If it exists, remove it from the list (deselect)
					newAudienceAgeRange = currentAgeRanges.filter((range) => range !== normalizedValue).join(',');
				} else {
					// If it doesn't exist, add it (select), and ensure uniqueness with Set
					newAudienceAgeRange = [...new Set(currentAgeRanges.concat(normalizedValue))].join(',');
				}
			} else {
				// If there are no current age ranges, start with the normalized value
				newAudienceAgeRange = normalizedValue;
			}
		}

		// Update the filter with the new audience age ranges
		setFilter((prev: FilterType) => {
			return {
				...prev,
				audienceAgeRanges: newAudienceAgeRange,
			};
		});
	};

	const updateNetworkHandler = (value: Network) => {
		setFilter((prev: FilterType) => {
			return {
				...prev,
				networks: value,
			};
		});
	};

	const resetFilter = () => {
		setHasSearched(false);
		setFilter(defaultFilter);
	};

	// Message handlers -----------------------------------
	const openMessageModalHandler = (targetInfluencer: InfluencerListItemType) => {
		setMessageTargetInfluencer(targetInfluencer);
		setIsMessageModalOpen(true);
	};

	const closeMessageModalHandler = () => {
		setMessageTargetInfluencer(undefined);
		setIsMessageModalOpen(false);
	};

	const context = {
		isLoading: isLoading,
		isLoadingNext: isLoadingNext,
		filter: filter,
		page: page,
		setPage: setPage,
		isLastPage: isLastPage,
		setIsLastPage: setIsLastPage,
		searchHandler: searchHandler,
		searchResult: searchResult,
		nextSearchResult: nextSearchResult,
		changeSearchValueHandler: changeSearchValueHandler,
		searchText: searchText,
		setFilter: setFilter,
		resetFilter: resetFilter,

		updateHashtagsHandler: updateHashtagsHandler,
		updateBrandAffiliationsHandler: updateBrandAffiliationsHandler,

		updateGenderHandler: updateGenderHandler,
		updateAudienceAgeRangeHandler: updateAudienceAgeRangeHandler,
		isMessageModalOpen: isMessageModalOpen,
		messageTargetInfluencer: messageTargetInfluencer,
		setMessageTargetInfluencer: setMessageTargetInfluencer,
		openMessageModal: openMessageModalHandler,
		closeMessageModal: closeMessageModalHandler,
		updateNetworkHandler: updateNetworkHandler,
		hasSearched: hasSearched,
		setHasSearched: setHasSearched,
		updateCountryHandler: updateCountryHandler,
	};

	const DiscoveryContextProps = {
		value: context,
	};

	return <DiscoveryContext.Provider {...DiscoveryContextProps}>{children}</DiscoveryContext.Provider>;
};

export default DiscoveryContext;
