import classNames from 'classnames';
import React, { useState } from 'react';

import Avatar from 'components/Avatar';
import Pill from 'components/Pill';

import Styled from './CampaignItem.style';

type Props = {
	coverImage?: string;
	title: string;
	onClick: (e: React.MouseEvent) => void;
	isSelected: boolean;
	hasUnreadMessages: boolean;
	index: number;
	cursor?: number;
	archivedAt?: string;
};

const CampaignItem = ({ coverImage, title, onClick, isSelected, hasUnreadMessages, index, cursor, archivedAt }: Props) => {
	const [displayFullName, setDisplayFullName] = useState(false);

	return (
		<Styled.ListItem onClick={onClick} data-testid='inbox-campaign-item'>
			<Styled.Wrapper className={classNames({ selected: isSelected || index === cursor })}>
				<Styled.ImageWrapper>
					<Avatar imageUrl={coverImage} name={title} />
					{hasUnreadMessages ? <Styled.UnreadMark /> : null}
				</Styled.ImageWrapper>
				<Styled.TextContainer onMouseEnter={() => setDisplayFullName(true)} onMouseLeave={() => setDisplayFullName(false)}>
					{displayFullName ? <Styled.FullNameText>{title}</Styled.FullNameText> : <Styled.TruncatedText>{title}</Styled.TruncatedText>}
					{archivedAt && <Pill title='archived' className='archived' />}
				</Styled.TextContainer>
			</Styled.Wrapper>
		</Styled.ListItem>
	);
};

export default CampaignItem;
