import axios from 'axios';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import useSWR from 'swr';

import refresh from 'assets/icons/svg-refresh.svg';

type ManifestResponse = {
	version: string;
};

// 1 Minutes
const REFRESH_INTERVAL = 60_000;

const VersionWatcher = () => {
	const [currentVersion, setCurrentVersion] = useState<string | undefined>();
	const { data } = useSWR('/manifest.json', axios.get<ManifestResponse>, {
		refreshInterval: REFRESH_INTERVAL,
	});

	const serverVersion = data?.data.version;

	useEffect(() => {
		if (isNil(serverVersion)) {
			return;
		}

		if (isNil(currentVersion)) {
			setCurrentVersion(serverVersion);
			return;
		}

		if (serverVersion !== currentVersion) {
			toast.info('A new version is available', {
				description: 'Refresh to get the latest version.',
				icon: <img src={refresh} alt='New version' />,
				action: {
					label: 'Refresh',
					onClick: () => location.reload(),
				},
				closeButton: false,
				dismissible: false,
				duration: REFRESH_INTERVAL - 2_000,
			});
		}
	}, [serverVersion, currentVersion]);

	return null;
};

export default VersionWatcher;
