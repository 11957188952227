import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgFlipVertical(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.6008 1.25L13.7892 4.55C14.0703 4.84 14.0703 5.32 13.7892 5.61C13.6439 5.76 13.4597 5.83 13.2756 5.83C13.0915 5.83 12.9073 5.76 12.762 5.61L10.7268 3.51V4.46825C10.7268 4.46874 10.7268 4.46923 10.7268 4.46973L10.7268 16.4897L12.762 14.3897C13.043 14.0997 13.5082 14.0997 13.7892 14.3897C14.0703 14.6797 14.0703 15.1597 13.7892 15.4497L10.5912 18.7497C10.5233 18.8197 10.4167 18.8797 10.3004 18.9297C10.2035 18.9697 10.1066 18.9997 10 18.9997H9.98062C9.88371 18.9997 9.79649 18.9797 9.70927 18.9397C9.59297 18.8897 9.48637 18.8297 9.40884 18.7497L6.21078 15.4497C5.92974 15.1597 5.92974 14.6797 6.21078 14.3897C6.35615 14.2397 6.54028 14.1697 6.72441 14.1697C6.90854 14.1697 7.09267 14.2397 7.23804 14.3897L9.27317 16.4897L9.27317 15.5306C9.27317 15.5304 9.27317 15.5302 9.27317 15.53L9.27317 4.46973L9.27317 3.51L7.23804 5.61C6.957 5.9 6.49182 5.9 6.21078 5.61C5.92974 5.32 5.92974 4.84 6.21078 4.55L9.41853 1.25C9.48637 1.18 9.59297 1.12 9.70927 1.07C9.80618 1.03 9.90309 1 10.0097 1H10.0291C10.126 1 10.2132 1.02 10.3004 1.06C10.4167 1.11 10.5233 1.17 10.6008 1.25Z'
				fill='#333333'
			/>
		</svg>
	);
}
